export * from './students';
export * from './schools';
export * from './staff';
export * from './districts';
export * from './config';
export * from './forms';
export * from './goals';
export * from './levels';
export * from './skillDatabanks';
export * from './skills';
export * from './games';
export * from './charts';
export * from './support';
export * from './brailleSheets';
export * from './specialist';
export * from './guardians';
export * from './groups';
export * from './profiles';
export * from './goalDatabanks';
export * from './posts';
export * from './notifications';
export * from './tutorials';
export * from './help';
export * from './speech';
export * from './buddyBooks';
export * from './books';
export * from './sliderListManagement';
export * from './sliderSeriesManagement';

export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_BOOKSHARE = 'FETCH_USER_BOOKSHARE';
export const CLEAR_USER_BOOKSHARE = 'CLEAR_USER_BOOKSHARE';
export const FETCH_ACCOUNT = 'FETCH_ACCOUNT';
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const FETCH_ACCOUNTS = 'FETCH_ACCOUNTS';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const FETCH_BILLING = 'FETCH_BILLING';
export const UPDATE_BILLING = 'UPDATE_BILLING';
export const HANDLE_ERROR = 'HANDLE_ERROR';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const RESET = 'RESET';
export const RESET_USER = 'RESET_USER';
export const RESET_ACCOUNT = 'RESET_ACCOUNT';
export const DOWNLOAD_BOOK_COMPLETE = 'DOWNLOAD_BOOK_COMPLETE';
export const DOWNLOAD_BOOK_FAIL = 'DOWNLOAD_BOOK_FAIL';
export const IMPORT_BOOKS_SUCCESS = "IMPORT_BOOKS_SUCCESS";
export const CLEAR_IMPORTED_BOOK = "CLEAR_IMPORTED_BOOK";
export const ROOT_URL = process.env.REACT_APP_ROOT_URL;
export const DEV_DB = process.env.REACT_APP_MONGO_URL;
export const GAME_URL = process.env.REACT_APP_GAME_URL;
export const FETCH_BUDDYBOOKS_SUBSCRIPTION = "FETCH_BUDDYBOOKS_SUBSCRIPTION";
export const UPDATE_LICENSE_AMOUNT = "UPDATE_LICENSE_AMOUNT";
export const SUSPEND_SUBSCRIPTION = "SUSPEND_SUBSCRIPTION";
export const CHECK_FOR_SUSPENSION = "CHECK_FOR_SUSPENSION";