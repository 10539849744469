export const FETCH_ALL_STUDENTS = 'FETCH_ALL_STUDENTS';
export const GET_STUDENT = 'GET_STUDENT';
export const GET_STAFF_STUDENTS = 'GET_STAFF_STUDENTS';
export const CREATE_STUDENT = 'CREATE_STUDENT';
export const PATCH_STUDENT = 'PATCH_STUDENT';
export const DELETE_STUDENT = 'DELETE_STUDENT';
export const GET_STUDENT_PROFILE = 'GET_STUDENT_PROFILE';
export const CREATE_STUDENT_PROFILE = 'CREATE_STUDENT_PROFILE';
export const PATCH_STUDENT_PROFILE = 'PATCH_STUDENT_PROFILE';
export const FETCH_DELETED_STUDENTS =  "FETCH_DELETED_STUDENTS";
export const REMOVE_DELETED_STUDENT = "REMOVE_DELETED_STUDENT";
export const REMOVE_STUDENT = "REMOVE_STUDENT";
export const GET_STUDENTS_STAFF = "GET_STUDENTS_STAFF";
export const FETCH_INFO = "FETCH_INFO";