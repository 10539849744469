import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import WizardToggleButton from '../WizardToggleButton';
import StudentWizardPage1 from './StudentWizardPage1';
import { createStudent, clearErrors } from '../../../../redux/actions';

class StudentWizardBase extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      open: false,
      page: 1
    }
  }

  openWizard = () => {
    this.setState({ open: true })
  };

  closeWizard = () => {
    this.setState({ open: false, page: 1 });
    this.props.clearErrors();
  };

  next = () => {
    this.setState({ page: this.state.page+1 });
  };

  back = () => {
    this.setState({ page: this.state.page-1 });
  };

  goToPage = (page) => {
    this.setState({ page });
  };

  render(){
    const { page, open } = this.state;
    return (
      <div>
        {
          page === 1 &&
          <StudentWizardPage1
            error={this.props.error}
            open={open}
            account={this.props.account}
            close={this.closeWizard}
            next={this.next}
            goToPage={this.goToPage}
            user={this.props.user}
            />
        }
        <WizardToggleButton
          openWizard={this.openWizard}
          type='student'
          text={<FormattedMessage id='Students.AddBtn'/>}
        />
      </div>
    )
  }
}

const mapState = ({ shared: { error, account,user } }) => {
  return {
    error,
    account,
    user
  }
};

const mapDispatch = (dispatch) => {
  return {
    createStudent: (student, ignoreWarning) => dispatch(createStudent(student, ignoreWarning)),
    clearErrors: () => dispatch(clearErrors())
  }
};

export default connect(mapState, mapDispatch)(StudentWizardBase);