import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Dialog,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  withStyles
} from '@material-ui/core';
import { Paper, Typography, Toolbar, Tooltip } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { CloudDownload, Close, CheckCircle, Search } from '@material-ui/icons';
import { fetchUserSkillDatabanks, createStaffSkillDatabank } from '../../../redux/actions';
import { stableSort, getSorting } from '../../../helpers';
import WizardToggleButton from '../Wizards/WizardToggleButton';
import styles from '../../../styles/importSkill.js';

const rows = [
  { id:'name', label: <FormattedMessage id='General.name'/> },
  { id:'description', label: <FormattedMessage id='General.description'/>},
  { id:'difficulty', label: <FormattedMessage id='General.difficulty'/>  },
  { id:'area', label: <FormattedMessage id='General.area'/>  },
];

class ImportSkill extends Component {

  state = {
    order: 'asc',
    orderBy: 'difficulty',
    selected: {},
    open: false,
    page: 0,
    rowsPerPage: 5,
    filteredData: [],
    searchValue: '',
    searchCategory: 'name',
    imported: []
  };

  componentDidMount() {
    this.props.fetchUserSkillDatabanks(false)
  };

  handleSearch = (searchValue, data) => {
    const _data = this.props.skillDatabanks;
    const searchableData = data ? data : _data;
    const filtered = searchableData.filter(item => {
      const searchableKeys = Object.keys(item).filter(key => [...rows].some(row => row.id === key));
      return searchableKeys.some(key => {
        if (item[key]){
          return item[key].toLowerCase().includes(searchValue.toLowerCase())
        }
        else {
          return false
        }
      })
    });
    this.setState({ filteredData: filtered, page: 0 })
  };

  handleSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  openWizard = () => {
    this.setState({ open: true })
  };

  closeWizard = () => {
    this.setState({ open: false });
  };

  importSkill = (databank) => {
    const skill = {...databank, id: null};
    this.props.createStaffSkillDatabank(skill, this.props.id);
    this.setState(state => ({ imported: [...state.imported, databank.id]}))
  };

  render(){
    const { classes, skillDatabanks } = this.props;
    const { orderBy, order, open, page, rowsPerPage, searchValue, filteredData } = this.state;
    const data = filteredData.length || searchValue ? filteredData : skillDatabanks;
    return (
      <>
        <Dialog open={open} maxWidth='lg' onClose={this.closeWizard}>
          <Paper className={ classes.list }>
            <Toolbar classes={{ root: classes.toolbar }}>
              <Typography noWrap className={ classes.header }>
                <strong><FormattedMessage id='Databanks.importSkill'/></strong>
              </Typography>
              <div className={classes.actions}>
                <TextField
                  variant='outlined'
                  id='table-search'
                  aria-label='search input'
                  type='search'
                  InputProps={{
                    inputProps: {
                      'aria-label':'search input'
                    },
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Search />
                      </InputAdornment>
                    ),
                    className: classes.searchBar,
                  }}
                  onChange={(ev) => {
                    this.handleSearch(ev.target.value);
                    this.setState({ searchValue: ev.target.value });
                  }}
                  value={this.state.searchValue}
                />
              </div>
              <IconButton onClick={this.closeWizard} className={classes.close}>
                <Close />
              </IconButton>
            </Toolbar>
            <Table>
              <TableHead>
                <TableRow>
                  { rows.length &&
                  rows.map(row => {
                    return (
                      <TableCell
                        key={row.id}
                        sortDirection={ orderBy === row.id ? order : false }
                      >
                        <Tooltip
                          title={<FormattedMessage id='General.sort'/>}
                          placement='bottom-start'
                          enterDelay={300}
                        >
                          <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={(ev) => this.handleSort(ev, row.id)}
                          >
                            {row.label}
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                    )
                  })
                  }
                  <TableCell key='status'>
                    <FormattedMessage id='Wizard.Braille.import'/>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(data, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(_data => {
                    return (
                      <TableRow
                        hover
                        key={_data.id}
                      >
                        {rows.length &&
                        rows.map((row, index) => {
                          return (
                            <TableCell
                              key={index}
                            >
                              {
                                _data[row.id] && _data[row.id] !== ' '
                                  ? _data[row.id]
                                  : 'N/A'
                              }
                            </TableCell>
                          )
                        })
                        }
                        <TableCell align='right'>
                          <IconButton onClick={() => this.importSkill(_data)}>
                            {
                              this.state.imported.includes(_data.id)
                                ? <CheckCircle />
                                : <CloudDownload />
                            }
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })
                }
                {
                  this.state.imported.length > 0
                  ? <TableRow>
                      <TableCell/>
                      <TableCell/>
                      <TableCell align='right'>
                        <Button color='primary' variant='contained' onClick={this.closeWizard}>
                          <FormattedMessage id='General.done'/>
                        </Button>
                      </TableCell>
                    </TableRow>
                  : <TableRow />
                }
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5,10,15]}
              component='div'
              count={skillDatabanks.length}
              labelRowsPerPage={<FormattedMessage id='Datatable.rowsPerPage'/>}
              rowsPerPage={this.state.rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Paper>
        </Dialog>
        <WizardToggleButton
          openWizard={this.openWizard}
          text={<FormattedMessage id='Databanks.importSkill'/>}
        />
    </>
    )
  }
}

const mapState = ({ platform: { skillDatabanks }}) => {
  return {
    skillDatabanks
  }
};

const mapDispatch = (dispatch, {history}) => {
  return {
    fetchUserSkillDatabanks: (includeSelf) => dispatch(fetchUserSkillDatabanks(includeSelf, history)),
    createStaffSkillDatabank: (skillDatabank, staffId) => dispatch(createStaffSkillDatabank(skillDatabank, staffId))
  }
};

export default connect(mapState, mapDispatch)(withStyles(styles)(ImportSkill));