import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import { Button, FormGroup, Typography, IconButton, TextField, MenuItem, Divider, withStyles } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { ROOT_URL } from '../../../../redux/constants';
import baseStyle from '../../../../styles/wordHunt';
import { fetchLevels } from '../../../../redux/actions';
import oblongeia from '../../../../assets/ScreenShots/oblongeia.png';
import triad from '../../../../assets/ScreenShots/triad.png';
import weldon from '../../../../assets/ScreenShots/weldon.png';


class WayfindingSkillDatabank extends Component {

  state = {
    gpsType: 'aStar',
    gpsDirectionType: 'block',
    selectedLevel: '',
    difficulty: 'Easy',
    startingCity: '',
    tested: false,
    error: {}
  };

  componentDidMount() {
    if (this.props.level) {
      const variables = JSON.parse(this.props.level.levelJson);
      this.setState({
        ...variables,
        difficulty: this.props.level.difficulty,
      })
    }
    if (this.props.levelTemplate) {
      this.setState({ selectedLevel: this.props.levelTemplate.id})
    }
  };

  close = () => {
    this.props.close();
  };

  handleChange = (ev) => {
    const value = ev.target.type === 'number' ? ev.target.value > 0 ? ev.target.value * 1 : '' : ev.target.value;
    this.setState({ [ev.target.name]: value })
  };

  handleLevelChange = (ev) => {
    const level = this.props.levels.find(level => level.id === ev.target.value);
    this.setState({ variables: JSON.parse(level.levelVariables), level, selectedLevel: ev.target.value, difficulty: JSON.parse(level.levelVariables).difficulty })
  };

  runTest = () => {
    const { tested, gpsDirectionType, gpsType, difficulty, level, variables } = this.state;
    const url = this.props.type === 'staff'
      ? `${ROOT_URL}/staff/${this.props.id}/objective-databank/test`
      : `${ROOT_URL}/admin/objective-databank/test`;
    if (tested) this.clearTest();
    axios.post(url, {
      levelVariables: JSON.stringify({
        ...variables,
        gpsType,
        gpsDirectionType
      }),
      difficulty,
      templateId: level.id
    }).catch(err => console.log(err));
    this.setState({ tested: true });
  };

  clearTest = () => {
    const url = this.props.type === 'staff'
      ? `${ROOT_URL}/staff/${this.props.id}/objective-databank/test`
      : `${ROOT_URL}/admin/objective-databank/test`;
    axios.delete(url)
      .catch(err => console.log(err));
    this.setState({ tested: false })
  };

  onSubmit = () => {
    const { gpsDirectionType, gpsType, difficulty, level, variables } = this.state;
    const levelJson = JSON.stringify({
      ...variables,
      gpsDirectionType,
      gpsType,
    });
    const skill = { levelJson, difficulty, levelTemplateId: level.id };
    this.props.onSubmit(skill);
  };

  changeGps = ({ target: { value }}) => {
    let gpsDirectionType, gpsType = value;
    switch (value){
      case 'aStar':
        gpsDirectionType = 'block';
        break;
      case 'crowFlies':
        gpsDirectionType = 'cardinal';
        break;
      case 'location':
        gpsDirectionType = 'cardinal';
        break;
      default:
        break;
    }
    this.setState({ gpsDirectionType, gpsType })
  };

  renderImage = () => {
    if (this.state.variables){
      switch (this.state.variables.startingCity){
        case 'Oblongeia':
          return <img width="320px" alt='screen shot of square city layout from game' src={oblongeia}/>;
        case 'Triad':
          return <img width="320px" alt='screen shot of l-shaped city layout from game' src={triad}/>;
        case 'Weldon':
          return <img width="320px" alt='screen shot of complex city layout from game' src={weldon}/>;
        default:
          break;
      }
    }
  };

  render() {
    const { classes, levels } = this.props;
    const { tested } = this.state;
    const gameId = this.props.selectedGame.id;
    const gameLevels = levels.filter(level => level.gameId === gameId);
    return (
      <>
        <Dialog
          maxWidth='md'
          fullWidth
          disableEscapeKeyDown
          open={this.props.open}
          onClose={this.props.close}
          aria-labelledby='form-dialog-title'
          PaperProps={{ classes: { root: classes.container } }}
        >
          <DialogTitle disableTypography={true} className={classes.title} id='form-dialog-title'>
            <Typography className={classes.titleText} variant='h4'>
              <FormattedMessage id='Wizard.skillDB.title'/>
            </Typography>
            <IconButton
              onClick={this.close}
              className={classes.exitIcon}
              aria-label="close dialog"
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography gutterBottom variant='h3'>
              Wayfinding
            </Typography>
            <Typography className={classes.description} variant='subtitle1'>
              Students will navigate around a medieval town of varying complexity. They will complete quests that ask them to deliver things to various buildings in the town, and can use their gold to buy armor, and equipment, or can store it at the bank to earn interest. Students will be given instructions on how to get to their destination based on the GPS type selected below:
            </Typography>
            <Divider className={classes.divider}/>
            <Typography gutterBottom variant='h6'><FormattedMessage id='Wizard.skillDB.levelSelect'/>:</Typography>
            <FormGroup>
              <TextField
                disabled={this.props.edit}
                select
                variant='outlined'
                label={<FormattedMessage id='General.levels' />}
                name='level'
                onChange={this.handleLevelChange}
                value={this.state.selectedLevel}
              >
                {
                  gameLevels.map(level => (<MenuItem key={level.id} value={level.id}> {level.name} </MenuItem>))
                }
              </TextField>
            </FormGroup>
            {
              this.state.selectedLevel &&
              <>
                <div className={classes.screenShot}>
                  {this.renderImage()}
                </div>
                <FormGroup className={classes.configureLine}>
                  <Typography variant='h6'>How should navigation be handled? </Typography>
                  <TextField
                    select
                    variant='outlined'
                    className={classes.configComponent}
                    name='shuffle'
                    value={this.state.gpsType}
                    onChange={this.changeGps}
                  >
                    <MenuItem value='aStar'> Step by Step </MenuItem>
                    <MenuItem value='crowFlies'> As the Crow Flies </MenuItem>
                    <MenuItem value='location'> Navigating using Memory and Audio </MenuItem>
                  </TextField>
                </FormGroup>
                <FormGroup className={classes.configureLine}>
                  <Typography variant='h6'><FormattedMessage id='General.difficulty' /> </Typography>
                  <TextField
                    select
                    variant='outlined'
                    className={classes.configComponent}
                    name='difficulty'
                    value={this.state.difficulty}
                    onChange={this.handleChange}
                  >
                    {
                      [
                        { label: <FormattedMessage id='General.easy' />, value: 'Easy' },
                        { label: <FormattedMessage id='General.medium' />, value: 'Medium' },
                        { label: <FormattedMessage id='General.hard' />, value: 'Hard' },
                      ].map(difficulty => (<MenuItem key={difficulty.value} value={difficulty.value}> {difficulty.label} </MenuItem>))
                    }
                  </TextField>
                </FormGroup>
              </>
            }
          </DialogContent>
          <DialogActions className={classes.buttons}>
            <Button id='back' onClick={this.props.back} color='secondary'>
              <FormattedMessage id='General.back'/>
            </Button>
            <Button
              onClick={this.runTest}
              variant={tested ? 'outlined' : 'text'}
              color={tested ? 'secondary' : 'primary'}
            >
              {tested ? <FormattedMessage id='General.retest'/> : <FormattedMessage id='General.test'/>}
            </Button>
            <Button
              disabled={Object.keys(this.state.error).length > 0}
              aria-label='go to final page of skill creator'
              onClick={this.onSubmit}
              color='primary'
            >
              <FormattedMessage id='General.next'/>
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

const  mapState = ({ platform: { levels }}) => {
  return {
    levels,
  }
};

const mapDispatch = (dispatch) => {
  return {
    fetchLevels: () => dispatch(fetchLevels())
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(WayfindingSkillDatabank));