import React from 'react'
import { withGoogleMap, GoogleMap, withScriptjs, InfoWindow, Marker } from "react-google-maps";
import Autocomplete from 'react-google-autocomplete';
import Geocode from "react-geocode";
import WizardToggleButton from '../../WizardToggleButton';
import {
    Button
} from '@material-ui/core';
import '../../../../../styles/maps.css';


Geocode.setApiKey("AIzaSyDKJvNTFa-5F_Rf_V8b8v2KpDZ-KFayz8o");


class Map extends React.Component{
    constructor( props ){
        super( props );
        this.state = {
            address: '',
            city: '',
            area: '',
            state: '',
            refs: {"map": undefined},
            markers: [],
            center: {},
            forceRefresh: false,
            originalPostion: {
                lat: this.props.center.lat,
                lng: this.props.center.lng
            },
            zoom: 18,
            mapPosition: {
                lat: this.props.center.lat,
                lng: this.props.center.lng
            }
        }
    }

    toggleDraggable = () => {
        var mapPosition = {
            lat: this.state.originalPostion.lat,
            lng: this.state.originalPostion.lng
        }
        this.setState({mapPosition: mapPosition, forceRefresh: true});
    }

    componentDidMount() {
        if(this.props.markers) {
            this.setState({markers: this.props.markers});
        }
        if(this.props.center) {
            this.setState({
                mapPosition: {
                    lat: this.props.center.lat,
                    lng: this.props.center.lng
                }})
        }
    }

    getPropsForSave = () => {
        return this.state;
    }

    onMapMounted = ref => {
        this.setState({refs: {"map": ref}})
    }

    onZoomChanged = () => {
        this.setState({zoom: this.state.refs.map.getZoom()});
    }

    onDragEnd = () => {
        var center = this.state.refs.map.getCenter();
        this.setState({mapPosition: {lat: center.lat(), lng: center.lng()}});
        console.log("Map Position", this.state.mapPosition);
    }

    shouldComponentUpdate(nextProps, nextState){
        var ret = nextState.markers.length !== this.state.markers.length;
        if(this.state.forceRefresh) {
            this.setState({forceRefresh: false});
            return true;
        }
        return ret;
    }
    /**

    /**
     * And function for city,state and address input
     * @param event
     */
    onChange = ( event ) => {
        this.setState({ [event.target.name]: event.target.value });
    };
    /**
     * This Event triggers when the marker window is closed
     *
     * @param event
     */
    onInfoWindowClose = ( event, id) => {
        var index = this.state.markers.findIndex(mark => mark.id===id);
        this.setState({markers: this.state.markers.filter((_, i) => i !== index)})
    };

    handleToggleClose = (id) => {
        var index = this.state.markers.findIndex(mark => mark.id===id);
        console.log(this.state.markers);
        this.setState({markers: this.state.markers.filter((_, i) => i !== index)})
        console.log(this.state.markers);
    };
    /**
     * When the user types an address in the search box
     * @param place
     */
    onPlaceSelected = ( place ) => {
            let latValue = place.geometry.location.lat(),
            lngValue = place.geometry.location.lng();
// Set these values in the state.
        this.setState({
            markers: [],
            originalPostion: {
                lat: latValue,
                lng: lngValue
            },
            mapPosition: {
                lat: latValue,
                lng: lngValue
            },
        })
        this.forceUpdate();
    };
    /**
     * When the marker is dragged you get the lat and long using the functions available from event object.
     * Use geocode to get the address, city, area and state from the lat and lng positions.
     * And then set those values in the state.
     *
     * @param event
     */
    onMarkerDragEnd = ( event, id ) => {
        var newMarkers = this.state.markers;
        var index = newMarkers.findIndex(mark => mark.id===id);
        var marker = newMarkers[index];
        let newLat = event.latLng.lat(),
            newLng = event.latLng.lng();
        marker.lat = newLat;
        marker.lng = newLng;
        newMarkers[index] = marker;
        this.setState({"markers": newMarkers});
    };

    addMarker = (ev) => {
        let newMarker = {"name": '', "lat": this.state.mapPosition.lat, "lng": this.state.mapPosition.lng, "id": Math.random()};
        this.setState({"markers": this.state.markers.concat([newMarker])}, () => console.log("Add State Finish", this.state));
    }

    updateMarker = (event, id) => {
        var newMarkers = this.state.markers;
        var index = newMarkers.findIndex(mark => mark.id===id);
        var marker = newMarkers[index];
        marker.name = event.target.value;
        newMarkers[index] = marker;
        this.setState({"markers": newMarkers});
    }

    render(){
        this.props.register(this.getPropsForSave);
        const AsyncMap = withScriptjs(
            withGoogleMap(
                props => (
                    <div>
                        {/* For Auto complete Search Box */}
                        <Autocomplete
                            style={{
                                width: '100%',
                                height: '40px',
                                paddingLeft: '16px',
                                marginTop: '2px',
                            }}
                            onPlaceSelected={ this.onPlaceSelected }
                            types={['address']}
                        />
                    <GoogleMap google={this.props.google}
                               defaultZoom={this.state.zoom}
                               ref={this.onMapMounted}
                               onZoomChanged={this.onZoomChanged}
                               onDragEnd={this.onDragEnd}
                               defaultCenter={{ lat: this.state.mapPosition.lat, lng: this.state.mapPosition.lng }}
                    >

                        {this.state.markers.map(marker => (
                            <Marker google={this.props.google}
                                    name={marker.name}
                                    draggable={true}
                                    onDragEnd={ (event) => { this.onMarkerDragEnd(event, marker.id) } }
                                    position={{ lat: marker.lat, lng: marker.lng }}
                            >
                                <InfoWindow
                                    onClose={(event) => {this.onInfoWindowClose(event, marker.id)}}
                                    onCloseClick={() => this.handleToggleClose(marker.id)}
                                    position={{ lat: ( marker.lat + 0.0018 ), lng: marker.lng }}
                                >
                                    <div>
                                        <MyInput value={marker.name} onchange={(event) => this.updateMarker(event, marker.id)}/>
                                    </div>
                                </InfoWindow>
                            </Marker>
                        ))}
                        {/*Marker*/}

                            {/* InfoWindow on top of marker */}

                    </GoogleMap>
                    </div>

                )
            )
        );
        let map;
        if( this.props.center.lat !== undefined ) {
            map = <div>
                <div className="skillBtns" style={{display:'grid', gridAutoFlow: 'column',
                    justifyContent: 'start',
                    gridColumnGap: '15px', marginLeft: 'auto', marginRight: 'auto'}}>
                <WizardToggleButton
                    openWizard={this.addMarker}
                    text="Add Pin"
                />
                {/*<WizardToggleButton*/}
                {/*    openWizard={this.toggleDraggable}*/}
                {/*    text={"Reset Center"}*/}
                {/*/>*/}
                </div>
                {/*<Button onClick={this.addMarker}  color='primary'>Add Pin</Button>*/}
                <AsyncMap
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDKJvNTFa-5F_Rf_V8b8v2KpDZ-KFayz8o&callback=initMap&libraries=places"
                    loadingElement={
                        <div style={{ height: `100%` }} />
                    }
                    containerElement={
                        <div style={{ height: this.props.height, width: this.props.width, align: 'center', marginLeft: 'auto', marginRight: 'auto' }} />
                    }
                    mapElement={
                        <div style={{ height: `100%` }} />
                    }
                />
            </div>
        } else {
            map = <div style={{height: this.props.height}} />
        }
        return( map )
    }
}

class MyInput extends React.Component {

    state = {
        value: ''
    };

    componentDidMount() {
            this.setState({
                value: this.props.value
            });
    }


    update = (e) => {
        this.props.onchange(e);
        this.setState({value: e.target.value});
    }

    render() {
        return (
            <input onChange={this.update} value={this.state.value} />
        );
    }
}
export default Map