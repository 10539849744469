import {
  ROOT_URL,
  CREATE_GUARDIAN,
  FETCH_ALL_GUARDIANS,
  DELETE_GUARDIAN,
  PATCH_GUARDIAN,
  GET_GUARDIAN,
  GET_STAFF_STUDENTS,
  HANDLE_ERROR,
} from '../../constants';
import axios from 'axios';

export const fetchStudentsGuardians = (id, history) => {
  const url = `${ROOT_URL}/students/${id}/guardians`;
  return (dispatch) => {
    axios
      .get(url)
      .then(response => {
        if (Array.isArray(response.data)){
          dispatch({
            type: FETCH_ALL_GUARDIANS,
            guardians: response.data
          })
        }
        else {
          history.push('/login');
          dispatch({
            type: HANDLE_ERROR,
            error: {type: 'sessionExp', msg: 'Your session has expired, please log back in'}
          });
        }
      })
      .catch(() => {
        dispatch({
          type: FETCH_ALL_GUARDIANS,
          guardians: []
        })
        // HAD issues with 500 errs being sent even when data was present, this was the fix
        // if (err.response.status === 500){
        //   if (Array.isArray(err.response.data)) {
        //     dispatch({
        //       type: FETCH_ALL_GUARDIANS,
        //       guardians: []
        //     })
        //   }
        //   else {
        //     history.push('/login');
        //     dispatch({
        //       type: HANDLE_ERROR,
        //       error: {type: 'sessionExp', msg: 'Your session has expired, please log back in'}
        //     });
        //   }
        // }
      })
  }
};

export const getStudentsForGuardian = (id, history) => {
  const url =`${ROOT_URL}/guardians/${id}/students`;
  return (dispatch) => {
    axios
      .get(url)
      .then( response => {
        if (Array.isArray(response.data)) {
          dispatch({
            type: GET_STAFF_STUDENTS,
            students: response.data
          })
        }
        else {
          history.push('/login');
          dispatch({
            type: HANDLE_ERROR,
            error: {type: 'sessionExp', msg: 'Your session has expired, please log back in'}
          });
        }
      })
  }
};

export const createGuardian = (guardian, students) => {
  const url = `${ROOT_URL}/guardians`;
  return (dispatch) => {
    axios
      .post(url, guardian)
      .then(response => {
        dispatch({
          type: CREATE_GUARDIAN,
          guardian: response.data
        });
        if (students) {
          addStudentsToGuardian(students, response.data)
        }
      })
      .catch(err => console.log(err))
  }
};

export const deleteGuardian = (guardian) => {
  const url = `${ROOT_URL}/guardians`;
  return (dispatch) => {
    axios
      .delete(url, { data: guardian })
      .then(() => {
        dispatch({
          type: DELETE_GUARDIAN,
          guardian
        })
      })
      .catch(err => console.log(err))
  }
};

export const patchGuardian = (guardian) => {
  const url = `${ROOT_URL}/guardians`;
  return (dispatch) => {
    axios
      .patch(url, guardian)
      .then(response => {
        dispatch({
          type: PATCH_GUARDIAN,
          guardian: response.data
        })
      })
      .catch(err => console.log(err))
  }
};

export const getGuardian = (id) => {
  const url = `${ROOT_URL}/guardians/${id}`;
  return (dispatch) => {
    axios
      .get(url)
      .then(response => {
        dispatch({
          type: GET_GUARDIAN,
          guardian: response.data
        })
      })
  }
};

export const addStudentsToGuardian = (students, guardian) => {
  const url = `${ROOT_URL}/guardians/${guardian.id}`;
  axios
    .post(url, students)
    .catch(err => console.log(err))
};