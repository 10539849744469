import {
  ADD_SKILL,
  DELETE_SKILL,
  ROOT_URL,
  PATCH_SKILL,
  GET_SKILLS_FOR_GOAL,
  CREATE_STAFF_SKILL_DATABANK
} from '../../constants';
import axios from 'axios';

export const addSkillToGoal = (goalId, skill, percentages) => {
  const url = `${ROOT_URL}/goals/${goalId}/skills-databank`;
  return (dispatch) => {
    axios
      .post(url, skill)
      .then((response) => {
        dispatch({
          type: ADD_SKILL,
          skill: response.data
        });
        dispatch({
          type: CREATE_STAFF_SKILL_DATABANK,
          skillDatabank: response.data
        })
        return response.data
      })
      .then((_skill) => {
        const skillDto = {..._skill, status:'Inactive', ...percentages};
        axios
          .patch(`${ROOT_URL}/goals/${goalId}/skills`, skillDto)
          .then(() => {
            dispatch({
              type: PATCH_SKILL,
              skill: skillDto
            })
        })
      })
      .catch(err => console.log(err))
  }
};

export const toggleSkill = (goalId, skill) => {
  const url = `${ROOT_URL}/goals/${goalId}/skills/${skill.id}`;
  return (dispatch) => {
   return axios
     .post(url)
     .then(response => {
       dispatch({
         type: PATCH_SKILL,
         skill: response.data
       })
     })
  }
};

export const patchSkill = (goalId, skill) => {
  const url = `${ROOT_URL}/goals/${goalId}/skills`;
  return (dispatch) => {
    axios
      .patch(url, skill)
      .then(() => {
        dispatch({
          type: PATCH_SKILL,
          skill
        })
      })
  }
};

export const deleteSkill = (goalId, skill) => {
  const url = `${ROOT_URL}/goals/${goalId}/skills`;
  return (dispatch) => {
    axios
      .delete(url, {data: skill})
      .then(()=> {
        dispatch({
          type: DELETE_SKILL,
          skill
        })
      })
      .catch(err => console.log(err))
  }
};

export const resetSkill = (goalId, skill) => {
  const url = `${ROOT_URL}/goals/${goalId}/skills/${skill.id}/reset`;
  return (dispatch) => {
    axios
      .post(url, {})
      .then((response) => {
        dispatch({
          type: PATCH_SKILL,
          skill: response.data
        })
      })
  }
};

export const orderSkills = (goalId, skills) => {
  const url = `${ROOT_URL}/goals/${goalId}/skills/order`;
  const orderedSkills = skills.map((skill, index) => {
    return {...skill, order: (index+1) * 10}
  });
  return (dispatch) => {
    axios
      .patch(url, orderedSkills)
      .then(response =>{
        dispatch({
          type: GET_SKILLS_FOR_GOAL,
          skills: response.data
        })
      })
  }
};