import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  DialogContentText,
  TextField,
  FormGroup,
  Typography,
  withStyles,
  MenuItem,
} from '@material-ui/core';
import { createGroup, searchProfiles, joinGroup } from '../../redux/actions';
import baseStyle from '../../styles/groupWizard'
import { FormattedMessage } from 'react-intl';

class AddGroup extends Component {

  state = {
    isPrivate: false,
    name: '',
    members: [],
    users: [],
  };

  handleChange = (ev) => {
    this.setState({ [ev.target.name]: ev.target.value})
  };

  handleSelect = (ev) => {
    this.setState({ users: ev })
  };

  search = (val) => {
    if (val) this.props.searchProfiles(val)
  };

  addMemberToGroup = (group) => {
    const newMembers = this.state.users.map(user => this.props.profiles.find(_user => user.value === _user.key));
    newMembers.forEach(member => this.props.joinGroup(group, member.key));
    this.setState(({ members }) => ({ members: [...newMembers, ...members], users: [] }))
  };

  submit = () => {
    const { name, description, isPrivate } = this.state;
    const group = {
      name,
      description,
      members: [this.props.profile],
      isPrivate,
      creatorId: this.props.profile.key,
    };
    this.props.createGroup(group)
      .then((group) => {
        this.addMemberToGroup(group);
      });
    this.props.close();
  };

  render() {
    const { classes, open, profiles } = this.props;
    const { members } = this.state;
    const searchable = [];
    members && profiles.forEach(user => members.some(member => user.key === member.key)
      ? null
      : searchable.push({ label: user.name, value: user.key })
    );
    return (
      <Dialog
        open={open}
        onClose={this.props.close}
        PaperProps={{classes:{ root: classes.dialog }}}
      >
        <DialogTitle disableTypography>
          <Typography variant='h4'><FormattedMessage id='AddGroup.title'/></Typography>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText className={classes.text}>
            <FormattedMessage id='AddGroup.text'/>
          </DialogContentText>
          <FormGroup className={classes.line}>
            <Typography variant='h6'><FormattedMessage id='AddGroup.name'/></Typography>
            <TextField
              variant='outlined'
              name='name'
              value={this.state.name}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup className={classes.line}>
            <Typography variant='h6'><FormattedMessage id='AddGroup.description'/></Typography>
            <TextField
              multiline
              variant='outlined'
              name='description'
              value={this.state.description}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup className={classes.line}>
            <div className={classes.inviteTitle}>
              <Typography variant='h6' className='text'><FormattedMessage id='AddGroup.invite'/></Typography>
            </div>
            <Select
              isMulti
              components={{
                DropdownIndicator: null,
              }}
              name='members'
              placeholder={<FormattedMessage id='AddGroup.members'/>}
              options={searchable}
              className={classes.select}
              onChange={this.handleSelect}
              value={this.state.users}
              onInputChange={this.search}
            />
          </FormGroup>
          <FormGroup className={classes.line}>
            <Typography variant='h6'><FormattedMessage id='AddGroup.privacy'/></Typography>
            <TextField
              select
              variant='outlined'
              name='isPrivate'
              value={this.state.isPrivate}
              onChange={this.handleChange}
            >
              <MenuItem value={false}><FormattedMessage id='Groups.public'/></MenuItem>
              <MenuItem value={true}><FormattedMessage id='Groups.private'/></MenuItem>
            </TextField>
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.submit} variant='contained' color='primary'><FormattedMessage id='General.submit'/></Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapState = ({ social: { profile, profiles }}) => {
  return {
    profile,
    profiles
  }
};

const mapDispatch = (dispatch) => {
  return {
    createGroup: (group) => dispatch(createGroup(group)),
    searchProfiles: (val) => dispatch(searchProfiles(val)),
    joinGroup: (group, profileKey) => dispatch(joinGroup(group, profileKey))
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(AddGroup));