import React, { Component } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, withStyles } from '@material-ui/core';
import { Group } from '@material-ui/icons';
import baseStyle from '../../styles/groups';
import { FormattedMessage } from 'react-intl';
import { getRandomColor } from '../../helpers';

class GroupList extends Component {

  renderType = (group) => {
    if (group.type === "GLOBAL") {
      return '';
    }
    else {
      return group.isPrivate
        ? <FormattedMessage id={'Groups.private'}/>
        : <FormattedMessage id={'Groups.public'}/>
    }

  };

  render() {
    const { classes, history } = this.props;
    const groups = this.props.groups.filter(group => group.type === 'GLOBAL' ? group.role === null : true);
    return (
      <List aria-label='groupList'>
        {
          groups.length > 0 && groups.map((group,index) => (
            <ListItem
              key={group.key+index}
              aria-label='item'
              className={classes.listItem}
              onClick={() => history.push(`/groups/${group.key}`)}
            >
              <ListItemIcon>
                <Group className={classes.icon} style={{ backgroundColor: getRandomColor(group.key)}}/>
              </ListItemIcon>
              <ListItemText
                primary={group.name}
                secondary={this.renderType(group)}
              />
            </ListItem>
          ))
        }
      </List>
    );
  }
}

export default withStyles(baseStyle)(GroupList);