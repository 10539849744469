import React, { Component } from 'react';
import classNames from 'classnames';
import { Card, Tooltip, withStyles } from '@material-ui/core';
import { brailleConversion } from './brailleUnicodeMap';
import baseStyle from '../../../../styles/brailleSheet';

class BrailleSheetWizardGrid extends Component {

  createGrid = () => {
    const { classes, grid } = this.props;
    let count = 0;
    const groups = grid.groups.reduce((groupObj, current) => {
      groupObj[current.id] = {text: current.text, cells: []};
      return groupObj
    }, []);
    return grid.cells.map((row, index) => {
      const fillerCells = grid.width - row.length;
      const cells = [];
      for (let i = fillerCells; i > 0; i--){
        count++;
        cells.push(
          <Card key={[count, index]} className={classes.cell} style={{ gridRow: index + 1 }}>
            <div className={classes.brailleChar}> </div>
            <div className={classes.standardChar}> </div>
          </Card>
        )
      }
      const brailleCells = row.row.map((cell, _index) => {
        return (
          <Tooltip key={_index} title={cell.group !== -1 ? groups[cell.group].text : ''}>
            <Card className={classNames(classes.cell, cell.group !== -1 ? classes.group : '')} style={{ gridRow: index + 1 }}>
              <div className={classes.brailleChar}>{brailleConversion(cell.text)}</div>
              <div className={classes.standardChar}>{cell.text}</div>
            </Card>
          </Tooltip>
        );
      });
      return [...brailleCells, ...cells]
    })
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        {
         this.createGrid()
        }
      </div>
    );
  }
}

export default withStyles(baseStyle)(BrailleSheetWizardGrid);