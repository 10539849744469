import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {
  AppBar,
  Button,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle, IconButton, InputAdornment,
  Paper, Tab, TablePagination, Tabs,
  TextField,
  Toolbar,
  Typography, withStyles
} from '@material-ui/core';
import { CheckCircle, Search, CheckCircleOutline } from '@material-ui/icons';
import { fetchMyGoals, fetchUserGoalDatabanks } from '../../../../redux/actions';
import { getRandomColor } from '../../../../helpers';
import styles from '../../../../styles/importSkill.js';

const rows = [
  { id:'name', label: <FormattedMessage id='General.name'/> },
  { id:'description', label: <FormattedMessage id='General.description'/>},
  { id:'difficulty', label: <FormattedMessage id='General.difficulty'/>  },
  { id:'area', label: <FormattedMessage id='General.area'/>  },
];

class RegistrationGoal extends Component {

  state = {
    rowsPerPage: 3,
    filteredData: [],
    searchValue: '',
    order: 'asc',
    orderBy: 'difficulty',
    page: 0,
    type: 0
  };

  componentDidMount() {
    this.props.fetchUserGoalDatabanks();
    this.props.fetchMyGoals();
    this.props.generateRandomGamerTag();
  };

  handleSearch = (searchValue, data) => {
    const _data = this.props.goalDatabanks;
    const searchableData = data ? data : _data;
    const filtered = searchableData.filter(item => {
      const searchableKeys = Object.keys(item).filter(key => [...rows].some(row => row.id === key));
      return searchableKeys.some(key => {
        if (item[key]){
          return item[key].toLowerCase().includes(searchValue.toLowerCase())
        }
        else {
          return false
        }
      })
    });
    this.setState({ filteredData: filtered, page: 0 })
  };

  handleSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleChangeTab = (ev, newValue) => {
    this.setState({ type: newValue, page: 0 });
  };

  render() {
    const { classes, goalDatabanks, myGoals, selected } = this.props;
    const { page, rowsPerPage, searchValue, filteredData } = this.state;
    const data = filteredData.length || searchValue ? filteredData : this.state.type === 0 ? myGoals : goalDatabanks;
    const items = data.length;
    return (
      <Dialog
        fullWidth
        maxWidth='lg'
        open={this.props.createGoal}
        className={classes.setup}
      >
        <DialogTitle>
          Add a Goal to Student
        </DialogTitle>
        <DialogContent>
          <Paper className={ classes.list }>
            <Toolbar classes={{ root: classes.toolbar }}>
              <Typography noWrap className={ classes.header }>
                <strong>Available Goals</strong>
              </Typography>
              <div className={classes.actions}>
                <TextField
                  variant='outlined'
                  id='table-search'
                  aria-label='search input'
                  type='search'
                  InputProps={{
                    inputProps: {
                      'aria-label':'search input'
                    },
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Search />
                      </InputAdornment>
                    ),
                    className: classes.searchBar,
                  }}
                  onChange={(ev) => {
                    this.handleSearch(ev.target.value);
                    this.setState({ searchValue: ev.target.value });
                  }}
                  value={this.state.searchValue}
                />
              </div>
            </Toolbar>
            <AppBar position='static' color='inherit'>
              <Tabs
                value={this.state.type}
                onChange={this.handleChangeTab}
                indicatorColor='primary'
                textColor='primary'
                variant='fullWidth'
              >
                <Tab label='From My Network' />
                <Tab label='My Databank' />
              </Tabs>
            </AppBar>
            {
              this.state.type === 1
                ? data && data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((goal, index) => (
                <Paper key={goal.key ? goal.key+index : goal.id+index} className={classes.post}>
                  <Typography className='name' variant='h4'>{goal.name}</Typography>
                  <Typography className='description' variant='h6'>{goal.description}</Typography>
                  <div className={classes.footer}>
                    <div className={classes.details}>
                      <div className={classes.text}>
                        <Typography className='text' variant='subtitle1'>
                          From my Databank
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <IconButton className='import' aria-label='import goal' disabled={selected.includes(goal.id)}
                              onClick={() => this.props.addGoal(goal, this.state.type)}>
                    {
                      selected.includes(goal.id)
                        ? <CheckCircle fontSize='large' color='primary'/>
                        : <CheckCircleOutline fontSize='large'/>
                    }
                  </IconButton>
                </Paper>
              ))
                : data && data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((goal, index) => (
                <Paper key={goal.key ? goal.key+index : goal.id+index} className={classes.post}>
                  <Typography className='name' variant='h4'>{goal.name}</Typography>
                  <Typography className='description' variant='h6'>{goal.description}</Typography>
                  <div style={{ backgroundColor: getRandomColor(goal.groupKey) }} className={classes.footer}>
                    <Typography className='text' variant='subtitle1'>
                      {`By ${(goal.author && goal.author.name) || 'Objective Ed'} in `} {goal.group && `${goal.group.name}`}
                    </Typography>
                  </div>
                  <IconButton className='import' aria-label='import goal' disabled={selected.includes(goal.key)}
                              onClick={() => this.props.addGoal(goal, this.state.type)}>
                    {
                      selected.includes(goal.key)
                        ? <CheckCircle fontSize='large' color='primary'/>
                        : <CheckCircleOutline fontSize='large' />
                    }
                  </IconButton>
                </Paper>
              ))
            }
            <TablePagination
              rowsPerPageOptions={[3]}
              component='div'
              count={items}
              labelRowsPerPage={<FormattedMessage id='Datatable.rowsPerPage'/>}
              rowsPerPage={this.state.rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.props.goalDialog(false)}
            color='secondary'
          >
            <FormattedMessage id='General.cancel' />
          </Button>
          <Button color='primary'>
            <FormattedMessage id='General.done' />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapState = ({ platform: { goalDatabanks }, social: { myGoals }}) => {
  return {
    goalDatabanks,
    myGoals,
  }
};

const mapDispatch = (dispatch) => {
  return {
    fetchUserGoalDatabanks: () => dispatch(fetchUserGoalDatabanks()),
    fetchMyGoals: () => dispatch(fetchMyGoals()),
  }
};

export default connect(mapState, mapDispatch)(withStyles(styles)(RegistrationGoal));