import { FETCH_MY_BRAILLE_SHEETS, CREATE_MY_BRAILLE_SHEET, ROOT_URL } from '../../constants';
import axios from 'axios';

export const shareBrailleSheet = (sheet, comment, group) => {
  const sharedSheet = { brailleSheetDto: sheet, comment, group };
  const url = `${ROOT_URL}/braille-sheets/${sheet.id}/share`;
  return (dispatch) => {
    axios
      .post(url, sharedSheet)
      .then(response => {
        console.log(response.data);
        dispatch({
          type: CREATE_MY_BRAILLE_SHEET,
          brailleSheet: response.data
        })
      })
      .catch(err => console.log(err))
  };
};

export const toggleBrailleSheetLike = (sheet, liked) => {
  const url = `${ROOT_URL}/braille-sheets/${sheet.key}/${liked ? 'unlike' : 'like'}`;
  axios
    .post(url)
    .catch(err => console.log(err))
};

export const fetchMyBrailleSheets = (game) => {
  const url = `${ROOT_URL}/profiles/all-sheets`;
  return (dispatch) => {
    axios
      .post(url, game)
      .then((response) => {
        dispatch({
          type: FETCH_MY_BRAILLE_SHEETS,
          brailleSheets: response.data
        })
      })
      .catch(err => console.log(err))
  }
};

export const fetchStaffBrailleSheetsForGame = (id, game) => {
  const url =`${ROOT_URL}/staff/${id}/braille-sheets/game`;
  return (dispatch) => {
    axios
      .post(url, game)
      .then((response) => {
        dispatch({
          type: FETCH_MY_BRAILLE_SHEETS,
          brailleSheets: response.data
        })
      })
  }
};