import React, { Component } from 'react';
import axios from 'axios';
import {
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  IconButton
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ROOT_URL } from '../../../../redux/constants';
import baseStyle from '../../../../styles/testDatabank';
import { Close } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

class TestLevel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templateId: '',
      levelVariables: '',
      open: false,
      tested: false
    };
    this.url = `${ROOT_URL}/admin/objective-databank/test`;
  }

  closeWizard = () => {
    this.setState({ open: false });
    if (this.state.tested) this.clearTest();
  };

  openWizard = () => {
    this.setState({ open: true });
  };

  runTest = () => {
    const { levelVariables, templateId } = this.state;
    axios.post(this.url, { levelVariables, templateId })
      .catch(err => console.log(err));
    this.setState({ tested: true });
  };

  clearTest = () => {
    axios.delete(this.url)
      .catch(err => console.log(err));
    this.setState({ tested: false })
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <Dialog
          disableEscapeKeyDown
          fullWidth={true}
          open={this.state.open}
          onClose={this.closeWizard}
          className={classes.content}
        >
          <DialogTitle className={classes.title} disableTypography={true}>
            <Typography className={classes.titleText} variant='h4'><FormattedMessage id='Level.testBtn'/> </Typography>
            <IconButton
              onClick={this.closeWizard}
              className={ classes.exitIcon }
              aria-label="close dialog"
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <TextField
              className={classes.id}
              variant='outlined'
              label='Level Template ID'
              value={this.state.templateId}
              onChange={(ev) => this.setState({ templateId: ev.target.value })}/>
            <br />
            <TextField
              multiline
              className={classes.variables}
              variant='outlined'
              label={<FormattedMessage id='General.levelVars'/>}
              value={this.state.levelVariables}
              onChange={(ev) => this.setState({ levelVariables: ev.target.value })}/>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeWizard} color='secondary'>
              <FormattedMessage id='General.cancel'/>
            </Button>
            <Button onClick={this.runTest} variant='outlined' color='primary'>
              <FormattedMessage id='General.test'/>
            </Button>
          </DialogActions>
        </Dialog>
        <Button
          variant='outlined'
          classes={{ root: classes.wizardButton }}
          id='test-skill-databank-toggle'
          aria-label='test skill databank'
          onClick={this.openWizard}
        >
          <FormattedMessage id='Level.testBtn'/>
        </Button>
      </>
    );
  }
}

export default withStyles(baseStyle)(TestLevel);