import {
  ROOT_URL,
  FETCH_BRAILLE_SHEETS,
  CREATE_BRAILLE_SHEET,
  DELETE_BRAILLE_SHEET,
  UPDATE_BRAILLE_SHEET,
  FETCH_QUESTIONS,
  FETCH_STAFF_BRAILLE_SHEEETS,
  CREATE_STAFF_BRAILLE_SHEET,
  DELETE_STAFF_BRAILLE_SHEET
} from '../../constants';
import { saveAs } from 'file-saver';
import axios from 'axios';

export const fetchBrailleSheets = (accountId) => {
  const url = `${ROOT_URL}/braille-sheet?accountId=${accountId}`;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: FETCH_BRAILLE_SHEETS,
          brailleSheets: response.data
        })
      })
      .catch(err => console.log(err))
  }
};

export const fetchStaffBrailleSheets = (id) => {
  const url =`${ROOT_URL}/staff/${id}/braille-sheets`;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: FETCH_STAFF_BRAILLE_SHEEETS,
          brailleSheets: response.data
        })
      })
  }
};

export const getBrailleSheetById = (id) => {
  const url = `${ROOT_URL}/braille-sheet/${id}`;
  return axios
    .get(url)
    .then(response => response.data)
    .catch(err => console.log(err))
};

export const createBrailleSheet = (sheet, account) => {
  const url = `${ROOT_URL}/braille-sheet?accountId=${account.id}`;
  return (dispatch) => {
    axios
      .post(url, sheet)
      .then((response) => {
        dispatch({
          type: CREATE_BRAILLE_SHEET,
          brailleSheet: response.data
        })
      })
      .catch(err => console.log(err))
  }
};

export const createStaffBrailleSheet = (sheet, staffId) => {
  const url = `${ROOT_URL}/staff/${staffId}/braille-sheets`;
  return (dispatch) => {
    return axios
      .post(url, sheet)
      .then((response) => {
        dispatch({
          type: CREATE_STAFF_BRAILLE_SHEET,
          brailleSheet: response.data
        });
        return response;
      })
      .catch(err => console.log(err))
  }
};

export const deleteBrailleSheet = (sheet, account) => {
  const url = `${ROOT_URL}/braille-sheet?accountId=${account.id}`;
  return (dispatch) => {
    axios
      .delete(url, {data: sheet})
      .then(() => {
        dispatch({
          type: DELETE_BRAILLE_SHEET,
          brailleSheet: sheet
        })
      })
      .catch(err => console.log(err))
  }
};

export const deleteStaffBrailleSheet = (sheet, staffId) => {
  const url = `${ROOT_URL}/staff/${staffId}/braille-sheets`;
  return (dispatch) => {
    axios
      .delete(url, {data: sheet})
      .then(() => {
        dispatch({
          type: DELETE_STAFF_BRAILLE_SHEET,
          brailleSheet: sheet
        })
      })
      .catch(err => console.log(err))
  }
};

export const patchBrailleSheet = (sheet, account) => {
  const url = `${ROOT_URL}/braille-sheet?accountId=${account.id}`;
  return (dispatch) => {
    axios
      .patch(url, sheet)
      .then(() => {
        dispatch({
          type: UPDATE_BRAILLE_SHEET,
          brailleSheet: sheet
        })
      })
  }
};

export const searchQuestions = (query) => {
  const url = `${ROOT_URL}/questions/search`;
  return (dispatch) => {
    axios
      .post(url, query)
      .then((response) => {
        dispatch({
          type: FETCH_QUESTIONS,
          questions: response.data
        })
      })
      .catch(err => console.log(err))
  }
};

export const addQuestions = (question) => {
  const url = `${ROOT_URL}/questions`;
  return axios.post(url, question)
    .catch((err) => console.log(err))
};

export const downloadSheet = (sheetId, name) => {
  const url = `${ROOT_URL}/braille-sheet/${sheetId}/file`;
  return axios
    .get(url, { responseType: 'blob' })
    .then((response) => {
      const fileName = name.split(' ').join('_');
      saveAs(response.data, `${fileName}.brf`)
    })
    .catch(err => console.log(err))
};