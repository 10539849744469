import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { ROOT_URL } from '../../../../redux/constants';
import { FormattedMessage } from 'react-intl';
import {
    Button,
    Typography,
    IconButton,
    FormGroup,
    TextField,
    MenuItem,
    Divider
} from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import { Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { fetchLevels } from '../../../../redux/actions';
import baseStyle from '../../../../styles/wizardBase';

class TempleExploreSkillDatabank extends Component {

    state = {
        tested: false,
        error: {},
        difficulty: 'Easy',
        directionType: '',
        step_count: '1',
        type:''
    };

    componentDidMount() {
        if (this.props.level.id) {
            const variables = JSON.parse(this.props.level.levelJson);
            this.setState({
                difficulty: this.props.level.difficulty,
                directionType: variables.directionType,
                step_count: variables.step_count,
                type: variables.type
            })
        }
    }

    close = () => {
        this.props.close();
        this.setState({
            tested: false,
            error: {},
            difficulty: 'Easy',
            directionType: '',
            step_count: '1',
            type:''
        })
    };

    runTest = () => {
        const {
            tested,
            directionType,
            step_count,
            type,
        } = this.state;
        const url = this.props.type === 'staff' ? `${ROOT_URL}/staff/${this.props.id}/objective-databank/test`: `${ROOT_URL}/admin/objective-databank/test`;
        if (tested) this.clearTest();
        axios.post(url, { levelVariables: JSON.stringify({
                directionType,
                step_count,
                type
            }), templateId: "4945601666409066827" })
            .catch(err => console.log(err));
        this.setState({ tested: true });
    };

    clearTest = () => {
        const url = this.props.type === 'staff' ? `${ROOT_URL}/staff/${this.props.id}/objective-databank/test`: `${ROOT_URL}/admin/objective-databank/test`;
        axios.delete(url)
            .catch(err => console.log(err));
        this.setState({ tested: false })
    };

    handleChange = (ev) => {
        const value = ev.target.type === 'number' ? ev.target.value > 0 ? ev.target.value * 1 : '' : ev.target.value;
        this.setState({ [ev.target.name]: value })
    };

    checkForErrors = (ev) => {
        if (!ev.target.value){
            this.setState({ error: {...this.state.error, [ev.target.name]: true }})
        }
        else {
            let error = this.state.error;
            delete error[ev.target.name];
            this.setState({ error })
        }
    };

    handleSubmit = (ev) => {
        ev.preventDefault();
        const {
            difficulty,
            directionType,
            step_count,
            type
        } = this.state;
        const levelJson = JSON.stringify({
            directionType,
            step_count,
            type
        });
        this.props.onSubmit({ levelTemplateId: "4945601666409066827", levelJson, difficulty });
    };

    render()  {
        const { classes, open, close, back, levels, game } = this.props;
        const { tested, error } = this.state;
        const disabled = Object.keys(error).length > 0 || !this.state.directionType || !this.state.type;
        return (
            <Dialog
                disableEscapeKeyDown
                fullWidth={true}
                open={open}
                onClose={close}
                aria-labelledby='skill-dialog-title'
                scroll='paper'
                PaperProps={{ classes:{ root: classes.container }}}
            >
                <DialogTitle disableTypography={true} className={ classes.title }>
                    <Typography className={ classes.titleText } variant='h4' id='skill-dialog-title'><FormattedMessage id='Wizard.skillDB.title'/></Typography>
                    <IconButton
                        onClick={this.close}
                        className={ classes.exitIcon }
                        aria-label="close dialog button"
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography gutterBottom variant='h3'>
                        <FormattedMessage id='Wizard.Temple.title'/>
                    </Typography>
                    <Typography className={classes.description} variant='subtitle1'>
                        <FormattedMessage id='Wizard.Temple.subtitle'/>
                    </Typography>
                    <Divider />
                        <div className={classes.level}>
                            <FormGroup className={classes.configureLine}>
                                <Typography className={classes.configTitle} variant='h6'><FormattedMessage id='Wizard.Temple.type'/>: </Typography>
                                <TextField
                                    select
                                    variant='outlined'
                                    className={classes.configComponent}
                                    name='type'
                                    error={error.type}
                                    helperText={error.type ? '* required' : ''}
                                    onBlur={this.checkForErrors}
                                    value={this.state.type}
                                    onChange={this.handleChange}>
                                    <MenuItem key={"four-way"} value={"four-way"}>{"Four Doors"}</MenuItem>
                                    <MenuItem key={"eight-way"} value={"eight-way"}>{"Eight Doors"}</MenuItem>
                                </TextField>
                            </FormGroup>
                            <FormGroup className={classes.configureLine}>
                                <Typography className={classes.configTitle} variant='h6'><FormattedMessage id='Wizard.Temple.directionType'/>: </Typography>
                                <TextField
                                    select
                                    variant='outlined'
                                    className={classes.configComponent}
                                    name='directionType'
                                    type='number'
                                    error={error.directionType}
                                    helperText={error.directionType ? '* required' : ''}
                                    onBlur={this.checkForErrors}
                                    value={this.state.directionType}
                                    onChange={this.handleChange}
                                >
                                    <MenuItem key={"cardinal"} value={"cardinal"}>{"Cardinal"}</MenuItem>
                                    <MenuItem key={"fixed-cardinal"} value={"fixed-cardinal"}>{"Cardinal With Fixed North"}</MenuItem>
                                    <MenuItem key={"clock"} value={"clock"}>{"Clock"}</MenuItem>
                                    <MenuItem key={"relative"} value={"relative"}>{"Relative"}</MenuItem>
                                </TextField>
                            </FormGroup>
                            <FormGroup className={classes.configureLine}>
                                <Typography className={classes.configTitle} variant='h6'><FormattedMessage id='Wizard.Temple.step'/>: </Typography>
                                <TextField
                                    select
                                    variant='outlined'
                                    className={classes.configComponent}
                                    name='step_count'
                                    error={error.step_count}
                                    helperText={error.step_count ? '* required' : ''}
                                    onBlur={this.checkForErrors}
                                    value={this.state.step_count}
                                    onChange={this.handleChange}
                                >
                                    <MenuItem key={"1"} value={"1"}>{"1"}</MenuItem>
                                    <MenuItem key={"2"} value={"2"}>{"2"}</MenuItem>
                                    <MenuItem key={"3"} value={"3"}>{"3"}</MenuItem>
                                </TextField>
                            </FormGroup>
                            <FormGroup className={classes.configureLine}>
                                <Typography className={classes.configTitle} variant='h6'><FormattedMessage id='General.difficulty' />: </Typography>
                                <TextField
                                    select
                                    variant='outlined'
                                    className={classes.configComponent}
                                    name='difficulty'
                                    value={this.state.difficulty}
                                    onChange={this.handleChange}
                                >
                                    {
                                        [
                                            { value: 'Easy', text: <FormattedMessage id='General.easy' /> },
                                            { value: 'Medium', text: <FormattedMessage id='General.medium' /> },
                                            { value: 'Hard', text: <FormattedMessage id='General.hard' /> }
                                        ].map(difficulty => (<MenuItem key={difficulty.value} value={difficulty.value}> {difficulty.text} </MenuItem>))
                                    }
                                </TextField>
                            </FormGroup>
                        </div>

                </DialogContent>
                <DialogActions className={classes.buttons}>
                    <Button onClick={back} color='secondary'>
                        <FormattedMessage id='General.back' />
                    </Button>
                    <Button
                        onClick={ this.runTest }
                        variant={ tested ? 'outlined' : 'text' }
                        color={ tested ? 'secondary' : 'primary' }
                    >
                        { tested ? <FormattedMessage id='General.retest' /> : <FormattedMessage id='General.test' /> }
                    </Button>
                    <Button disabled={disabled} onClick={this.handleSubmit} color='primary'>
                        <FormattedMessage id='General.next' />
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const  mapState = ({ platform: { levels }}) => {
    return {
        levels
    }
};

const mapDispatch = (dispatch) => {
    return {
        fetchLevels: () => dispatch(fetchLevels())
    }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(TempleExploreSkillDatabank));