import React, { Component } from 'react';
import Select from 'react-select';
import { Button, FormGroup, Typography } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@material-ui/core';
import { Close, Person, Mail } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../../styles/wizardBase';
import { FormattedMessage } from 'react-intl';

class StaffWizardPage2 extends Component {
  constructor(props){
    super(props);
    this.state = {
      checked: false
    }
  }

  close = () => {
    this.props.close();
  };

  submit = () => {
    this.props.submit();
  };

  render()  {
    const { classes } = this.props;
    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        open={this.props.open}
        onClose={this.props.close}
        aria-labelledby="form-dialog-title"
        PaperProps={{ classes:{ root: classes.container }}}
      >
        <DialogTitle disableTypography={true} className={ classes.title } id="form-dialog-title">
          <Typography className={ classes.titleText } variant='h4'><FormattedMessage id='Wizard.Staff.title'/></Typography>
          <IconButton
            onClick={this.close}
            className={ classes.exitIcon }
            aria-label="close dialog"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialog}>
          <DialogContentText>
            Looks like we found them!
          </DialogContentText>
          <FormGroup className={classes.personalInfo}>
            <Typography variant='h6'> <Person/> {this.props.user.firstName} {this.props.user.lastName}</Typography>
            <Typography gutterBottom variant='h6'> <Mail/> {this.props.user.email}</Typography>
          </FormGroup>
          <FormGroup>
            <Typography gutterBottom variant='subtitle1'>If this is who you would like to add please select the students you would like to assign this user: </Typography>
            <Select
              styles={{
                control: (styles) => ({...styles, minHeight: 56}),
                multiValueLabel: (styles) => ({
                  ...styles,
                  display: 'grid',
                  fontFamily: 'Roboto',
                  alignContent: 'center',
                  fontSize: '14px',
                  minHeight: 40,
                  fontWeight: 500
                }),
              }}
              isMulti
              menuPlacement='top'
              maxMenuHeight={180}
              placeholder=''
              options={this.props.students.map(student => ({ value: student.id, label: student.name }))}
              onChange={(ev) => this.props.handleChange({ target: { name: 'students', value: ev }})}
            />
          </FormGroup>
        </DialogContent>
        <DialogActions className={classes.buttons}>
          <Button onClick={this.props.back} color="secondary">
            <FormattedMessage id='General.back'/>
          </Button>
          <Button onClick={this.submit} color="primary">
            <FormattedMessage id='General.submit'/>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(baseStyle)(StaffWizardPage2);