import { HANDLE_ERROR, ROOT_URL } from '../../constants';
import moment from 'moment';
import axios from 'axios';
import { saveAs } from 'file-saver';


export const getSevenDayTime = (studentId) => {
    const url = `${ROOT_URL}/students/${studentId}/buddy-books/dashboard/time`;
    return axios
        .get(url)
        .then(response => {
            if (response.status !== 500) {
                return response.data;
            }
            else {
                return []
            }
        })
        .catch(err => console.log(err))
};

export const getWordsPerMinute = (studentId,startDate,endDate) => {
    const url = `${ROOT_URL}/students/${studentId}/buddy-books/dashboard/words-per-minute${(startDate && endDate) ? `?startDate=${startDate}&endDate=${endDate}` : "" }`;
    return axios
        .get(url)
        .then(response => {
            if (response.status !== 500) {
                return response.data;
            }
            else {
                return []
            }
        })
        .catch(err => console.log(err))
}

export const getAccuracy = (studentId,startDate,endDate) => {
    const url = `${ROOT_URL}/students/${studentId}/buddy-books/dashboard/accuracy${(startDate && endDate) ? `?startDate=${startDate}&endDate=${endDate}` : "" }`;
    return axios
        .get(url)
        .then(response => {
            if (response.status !== 500) {
                return response.data;
            }
            else {
                return []
            }
        })
        .catch(err => console.log(err))
}


export const getProgress = (studentId) => {
    const url = `${ROOT_URL}/students/${studentId}/buddy-books/dashboard/progress`;
    return axios
        .get(url)
        .then(response => {
            if (response.status !== 500) {
                return response.data;
            }
            else {
                return []
            }
        })
        .catch(err => console.log(err))
};

export const getPhonemes = (studentId) => {
    const url = `${ROOT_URL}/students/${studentId}/buddy-books/dashboard/phoneme`;
    return axios
        .get(url)
        .then(response => {
            if (response.status !== 500) {
                return response.data;
            }
            else {
                return []
            }
        })
        .catch(err => console.log(err))
};

export const getPhonemesSpecific = (studentId, phoneme) => {
    const url = `${ROOT_URL}/students/${studentId}/buddy-books/dashboard/phoneme/${phoneme}`;
    return axios.get(url)
        .then(response => {
            if (response.status !== 500) {
                console.log('analysis phoneme specific', response.data);
                return response.data;
            }
            else {
                return []
            }
        })
}

export const getReviewData = (studentId) => {
    const url = `${ROOT_URL}/students/${studentId}/buddy-books/dashboard/reviews`;
    return axios.get(url)
        .then(response => {
            if (response.status !== 500) {
                console.log('getReview response', response.data);
                return response.data;
            }
            else {
                return []
            }
        })
}

export const getReadingTime = (studentId) => {
    const url = `${ROOT_URL}/students/${studentId}/buddy-books/dashboard/time/v2`;
    return axios.get(url)
        .then(response => {
            if (response.status !== 500) {
                console.log('getReading response', response.data);
                return response.data;
            }
            else {
                return []
            }
        })
}
export const getWPMBooks = async (studentId, bookIds) => {
    let proms = bookIds.map(id => axios.get(`${ROOT_URL}/students/${studentId}/story/${id}`)
        .then(r => r.data));
    return Promise.all(proms).then(res => {
        return res;
    })
}