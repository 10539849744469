import { Avatar, Divider, IconButton, Paper, Popover, Toolbar, Tooltip, Typography, withStyles } from '@material-ui/core'
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import reviewStyle from '../../../styles/reviewPage.js';
import axios from 'axios';
import { Close, CloudDownload, PauseCircleOutline, PlayCircleFilledOutlined } from '@material-ui/icons';
import { ROOT_URL } from '../../../redux/constants/index.js';
import { saveAs } from 'file-saver';
import { getAIAudioFile } from '../../../redux/actions/index.js';
import ReviewText from './ReviewText.jsx';

let computerIndex = 0;
let skippedIndex = null;
let computerOffsets;
let phonemeComputerOffsets;
let firstComputerOffsets;
let secondComputerOffsets;
function ReviewModal({ classes, title, data, studentId, exitReview, type, }) {
  const [reviewModeFlag, setReviewModeFlag] = useState(false);
  const [reviewArray, setReviewArray] = useState([]);
  const [reviewTextList, setReviewTextList] = useState([]);
  const [firstReviewTextList, setFirstReviewTextList] = useState([]);
  const [secondReviewTextList, setSecondeviewTextList] = useState([]);
  const [textAnalysis, setTextAnalysis] = useState(null);
  const [wordScore, setWordScore] = useState(null);
  const [url, setUrl] = useState('');
  const [firstUrl, setFirstUrl] = useState('');
  const [secondUrl, setSecondUrl] = useState('');
  const [play, setPlay] = useState(false);
  const [playFirstAudio, setPlayFirstAudio] = useState(false);
  const [playSecondAudio, setPlaySecondAudio] = useState(false);
  const [highlightWordIndex, setHighlightWordIndex] = useState(null);
  const [changeTime, setChangeTime] = useState();
  const [playAudioType, setPlayAudioType] = useState(0);

  useEffect(() => {
    if (data) {
      getReviewUrls(data);
      let splitedText = data.text.split(" ");
      for (let i in splitedText) {
        if(splitedText[i].replace(/[^\p{L}\p{N}\s]/gu, '').trim()=="") {
          splitedText.splice(i,1);
        }
        else if (splitedText[i] == "" || splitedText[i] == ",")
          splitedText.splice(i, 1);
        }
      setReviewArray(splitedText);
    }
  }, [data]);

  useEffect(() => {
    var vid = document.getElementById("reviewAud");
    if (vid && changeTime) {
      let reviewText = playAudioType == 1 ? firstReviewTextList : playAudioType == 2 ? secondReviewTextList : reviewTextList;
      let firstWordOffset = reviewText && reviewText[0].Offset;

      if ((firstWordOffset / 10000) > 2000) {
        let startTime = parseFloat(firstWordOffset / 10000000);
        vid.currentTime = startTime - 2;
        setChangeTime(false);
      }
    }
  })
  const getReviewUrls = (data) => {
    if (data.firstAnalysis && data.secondAnalysis) {
      setReviewModeFlag(true);
      let firstWords = data.firstAnalysis.NBest[0] && data.firstAnalysis.NBest[0].Words;
      let secondWords = data.secondAnalysis.NBest[0] && data.secondAnalysis.NBest[0].Words;
      setFirstReviewTextList(firstWords);
      setSecondeviewTextList(secondWords);
      let review = true;
      let fileId = [data.firstFileId, data.secondFileId];

      if (fileId.indexOf(undefined) === -1) {
        var proms = fileId.map(fileId => getAIAudioFile(studentId, fileId, review))
        Promise.all(proms).then((responses) => {
          setFirstUrl(URL.createObjectURL(responses[0]));
          setSecondUrl(URL.createObjectURL(responses[1]));
          firstComputerOffsets = firstWords && firstWords.map((value) => {
            return value.Offset;
          });
          secondComputerOffsets = secondWords && secondWords.map((value) => {
            return value.Offset;
          });
        });
      }
    } else {
      setReviewModeFlag(false);
      let Words = data.analysis && data.analysis.NBest[0] && data.analysis.NBest[0].Words;
      setReviewTextList(Words);
      let review = true;
      data.fileId && getAIAudioFile(studentId, data.fileId, review)
        .then((response) => {
          setUrl(URL.createObjectURL(response));
          phonemeComputerOffsets = Words && Words.map((value) => {
            return value.Offset;
          });
        });
    }
  }

  const handleAudio = (playAudioValue) => {

    switch (playAudioValue) {
      case 'First':
        setChangeTime(!play);
        setPlayAudioType(1);
        setPlayFirstAudio(!playFirstAudio);
        break;

      case 'Second':
        setChangeTime(!play);
        setPlayAudioType(2);
        setPlaySecondAudio(!playSecondAudio);
        break;

      default: {
        setChangeTime(!play);
        setPlay(!play);
        break;
      }
    }
    setHighlightWordIndex(0);
    computerIndex = 0;
  }

  const pauseAudio = () => {
    setPlay(false);
    setPlayFirstAudio(false);
    setPlaySecondAudio(false);
    setHighlightWordIndex(null);
    computerIndex = 0;
  }

  const downloadAudio = (fileId) => {
    getAIAudioFile(studentId, fileId)
  }

  const iterateComputerAudio = (e) => {
    let { currentTime } = e.target;
    let oldTextIndex = computerIndex;

    if (playFirstAudio) {
      computerOffsets = firstComputerOffsets;
    } else if (playSecondAudio) {
      computerOffsets = secondComputerOffsets;
    } else {
      computerOffsets = phonemeComputerOffsets;
    }

    if (computerOffsets && computerOffsets.length) {
      while ((computerIndex < computerOffsets.length - 1) && ((currentTime * 1000) > (computerOffsets[computerIndex] / 10000))) {
        computerIndex++;
      }

      if ((oldTextIndex !== computerIndex) && ((computerIndex - oldTextIndex) === 1)) {
        setHighlightWordIndex(computerIndex);
      } else if ((computerIndex > oldTextIndex) && ((computerIndex - oldTextIndex) > 1)) {
        skippedIndex = computerIndex;

        setHighlightWordIndex(computerIndex - 1);
        setTimeout(() => {
          setHighlightWordIndex(skippedIndex);
        }, 200);
      }
    }
  }

  const reviewButtons = (buttonType, playToggle, fileId) => {
    return (
      <div className={classes.flexCenter}>
        <IconButton onClick={() => { handleAudio(buttonType) }} size="small" sx={{ ml: 2 }}>
          <Avatar sx={{ width: 32, height: 32 }}>
            {playToggle ?
              <Tooltip title='Pause Audio'>
                <PauseCircleOutline
                  style={{ cursor: "pointer", fontSize: 32 }}
                />
              </Tooltip>
              :
              <Tooltip title='Play Audio'>
                <PlayCircleFilledOutlined
                  style={{ cursor: "pointer", fontSize: 32 }}
                />
              </Tooltip>
            }
          </Avatar>
        </IconButton>
        <div className={classes.buttons}>
          <Tooltip title='Download'>
            <IconButton onClick={() => { downloadAudio(fileId) }}>
              <CloudDownload />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    )
  }

  return (
    <Paper className={classes.list}>
      {type !== "progressReport" && <Toolbar classes={{ root: classes.toolbar }}>
        <Typography noWrap className={classes.header}>
          <strong>{title}</strong>
        </Typography>
        <div>
          <IconButton
            onClick={() => { exitReview() }}
            className={classes.exitIcon}
            aria-label="close dialog"
          >
            <Close />
          </IconButton>
        </div>
      </Toolbar>}
      {reviewModeFlag ? (
        <>
          <ReviewText
            type='First Analysis'
            analysis={data.firstAnalysis.NBest[0]}
            reviewArray={reviewArray}
            reviewTextList={firstReviewTextList}
            highlightWordIndex={highlightWordIndex}
            play={playFirstAudio}
          />
          {
            type !== "progressReport" &&  reviewButtons('First', playFirstAudio, data.firstFileId)
          }
          <ReviewText
            type='Second Analysis'
            analysis={data.secondAnalysis.NBest[0]}
            data={data}
            reviewArray={reviewArray}
            reviewTextList={secondReviewTextList}
            highlightWordIndex={highlightWordIndex}
            play={playSecondAudio}
          />
          {
            type !== "progressReport" && reviewButtons('Second', playSecondAudio, data.secondFileId)
          }
        </>
      ) :
        <>
          <ReviewText
            type={null}
            reviewArray={reviewArray}
            reviewTextList={reviewTextList}
            data={data}
            highlightWordIndex={highlightWordIndex}
            url={url}
            play={play}
            pauseFunc={pauseAudio}
            computerOffsets={phonemeComputerOffsets}
            fileId={data.fileId}
          />
          {
            type !== "progressReport" && reviewButtons('', play, data.fileId)
          }
        </>
      }
      {(url.length > 0 || firstUrl.length > 0 || secondUrl.length > 0) && (play || playFirstAudio || playSecondAudio) && (
        <div style={{ display: 'none' }}>
          <audio
            id='reviewAud'
            controls
            autoPlay
            onEnded={() => { pauseAudio(); }}
            onTimeUpdate={(e) => {
              iterateComputerAudio(e);
            }}
          >
            <source src={playFirstAudio ? firstUrl : playSecondAudio ? secondUrl : url} type={"audio/mpeg"} />
          </audio>
        </div>
      )}

    </Paper>
  )
}

export default connect()(withStyles(reviewStyle)(ReviewModal));
