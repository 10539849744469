import React, { Component } from 'react';
import { connect } from 'react-redux';
import DataTable from '../Shared/DataTable';
import { FormattedMessage } from 'react-intl';

class GameList extends Component {

  render(){
    const rows = [
      { id: 'name', label: <FormattedMessage id='General.name'/>, searchable: true },
      { id: 'description', label: <FormattedMessage id='General.description'/> },
    ];
    return (
      <DataTable
        type={'game'}
        history={this.props.history}
        rows={rows}
        rowsPerPage={[5,10,15]}
        orderBy={'status'}
        data={this.props.games || []}
        formId={this.props.id}
        numCols={2}
        title={<FormattedMessage id='Sidebar.games'/>}
      />
    )
  }
}

export default connect(null)(GameList);