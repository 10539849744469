export default {
  lineGroup: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    minHeight: 0,
    gridColumnGap: 15,
    marginBottom: 20,
    justifyContent: 'center',
  },
  chartGap: {
    marginBottom: 20
  },
  expansion: {
    display: 'block'
  },
  rounded: {
    borderRadius: 4
  }
};