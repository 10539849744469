import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import { Button, FormGroup, Typography, IconButton, TextField, MenuItem, Divider, FormControlLabel, Switch, Checkbox, withStyles } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Cancel, Close } from '@material-ui/icons';
import { ROOT_URL } from '../../../../redux/constants';
import baseStyle from '../../../../styles/wordHunt';
import { fetchStaffBrailleSheets } from '../../../../redux/actions';
import { getBrailleSheetById } from '../../../../redux/actions';

class CodeBreakerSkillDatabank extends Component {

  state = {
    selected: '',
    selectedSheet: {},
    phrases: [{text: '', contractions: []}],
    maxGuesses: 1,
    numberOfRounds: 1,
    difficulty: 'Easy',
    tested: false,
    grid: [],
    contracted: {},
    contractionMap: {},
    groupMap: {},
    error: {},
    sheets: []
  };

  componentDidMount() {
    if (this.props.level.id) {
      const variables = JSON.parse(this.props.level.levelJson);
      this.setState({
        ...variables,
        phrases: variables.phrases,
        difficulty: this.props.level.difficulty,
        selected: variables.brailleSheet.id,
        selectedSheet: variables.brailleSheet,
        grid: JSON.parse(variables.brailleSheet.grid)
      })
    }
    getBrailleSheetById("-5274532154501163781")
        .then(sheet => this.setState({sheets: [...this.state.sheets,sheet]}));
    getBrailleSheetById("8539405908300465710")
        .then(sheet => this.setState({sheets: [...this.state.sheets,sheet], selectedSheet: sheet}));
    // this.props.fetchBrailleSheets(this.props.user.accountId);
    this.props.fetchStaffBrailleSheets(this.props.user.staffId);
  };

  close = () => {
    this.props.close();
  };

  handleChange = (ev) => {
    const value = ev.target.type === 'number' ? ev.target.value > 0 ? ev.target.value * 1 : '' : ev.target.value;
    this.setState({ [ev.target.name]: value })
  };

  handleSheetChange = (ev) => {
    const sheet = this.state.sheets.find(sheet => sheet.id === ev.target.value);
    const grid = sheet.grid ? JSON.parse(sheet.grid) : {};
    const contractionMap = {};
    const groupMap = {};
    grid.groups.forEach(group => {
      contractionMap[group.text] = group.isContraction;
      groupMap[group.text] = group.id
    });
    this.setState({ selected: ev.target.value, selectedSheet: sheet, grid, contractionMap, groupMap })
  };

  addPhrase = () => {
    this.setState({ phrases: [...this.state.phrases, {text: '', contractions: []}], error: {...this.state.error, phrase: 'empty' }})
  };

  changePhrase = (ev, index) => {
    delete this.state.error.phrase;
    const phrases = this.state.phrases;
    phrases[index].text = ev.target.value;
    this.setState({ phrases, error: this.state.error })
  };

  removePhrase = (index) => {
    let phrases = this.state.phrases;
    const contracted = this.state.contracted;
    if (phrases.length > 1) {
       phrases.splice(index, 1);
       if (contracted[index]){
         delete contracted[index]
       }
    }
    else {
      phrases = [{text: '', contractions: []}];
      if (contracted[index]){
        delete contracted[index]
      }
    }
    this.setState({ phrases, contracted })
  };

  checkPhraseLength = ({target: { value }}, index) => {
    const phraseWithoutPunc = value.replace(/[.,/!&;:]/g,"").trim();
    const phrase = phraseWithoutPunc.replace(/\s{2,}/g," ");
    if (phrase.split(' ').length > 9) {
      this.setState({ error: {...this.state.error, [`phrase${index}`]: '*Phrase must be under 10 words'}})
    }
    else if (!value){
      this.setState({ error: {...this.state.error, [`phrase${index}`]: '*Phrase must be at least 1 word long'}})
    }
    else if (this.state.error[`phrase${index}`]){
      delete this.state.error[`phrase${index}`];
      this.setState({ error: this.state.error })
    }
  };

  checkContractions = (index, phrase, recheck) => {
    if (this.state.contracted[index] && !recheck){
      const phrases = this.state.phrases;
      phrases[index].contractions = [];
      delete this.state.contracted[index];
      this.setState({ phrases })
    }
    else {
      const phrases = this.state.phrases;
      const words = phrase.text.split(' ');
      phrases[index].contractions = words.filter(word => !!this.state.contractionMap[word]);
      this.setState({ contracted: {...this.state.contracted, [index]: true}, phrases })
    }
  };

  changeContractionSelection = ({ target: { value }}) => {
    let groups = this.state.contractionMap;
    groups[value] = !this.state.contractionMap[value];
    this.setState({ contractionMap: groups })
  };

  runTest = () => {
    const { maxGuesses, numberOfRounds, selectedSheet, groupMap, contractionMap, tested } = this.state;
    const phrases = this.state.phrases.map(phrase => {
      const phraseWithoutPunc = phrase.text.replace(/[.,/!&;:]/g,"").trim();
      const _phrase = phraseWithoutPunc.replace(/\s{2,}/g," ");
      const words = _phrase.split(' ').map(word => {
        let groupIds = contractionMap[word]
          ? [groupMap[word]]
          : word.split('').map(letter => groupMap[letter]
                                                              ? groupMap[letter]
                                                              : groupMap[letter.toLowerCase()]
                                                                ? groupMap[letter.toLowerCase()]
                                                                : -1
          );
        return {groupIds}
      });
      return { text: phrase.text, words}
    });
    const url = this.props.type === 'staff'
      ? `${ROOT_URL}/staff/${this.props.id}/objective-databank/test`
      : `${ROOT_URL}/admin/objective-databank/test`;
    if (tested) this.clearTest();
    axios.post(url, {
      levelVariables: JSON.stringify({
        phrases,
        maxGuesses,
        numberOfRounds,
        brailleSheet: selectedSheet,
      }),
      templateId: '-6449910514503171838'
    }).catch(err => console.log(err));
    this.setState({ tested: true });
  };

  clearTest = () => {
    const url = this.props.type === 'staff'
      ? `${ROOT_URL}/staff/${this.props.id}/objective-databank/test`
      : `${ROOT_URL}/admin/objective-databank/test`;
    axios.delete(url)
      .catch(err => console.log(err));
    this.setState({ tested: false })
  };

  onSubmit = () => {
    const { maxGuesses, numberOfRounds, selectedSheet, difficulty, groupMap, contractionMap } = this.state;
    const phrases = this.state.phrases.map(phrase => {
      const phraseWithoutPunc = phrase.text.replace(/[.,/!&;:]/g,"").trim();
      const _phrase = phraseWithoutPunc.replace(/\s{2,}/g," ");
      const words = _phrase.split(' ').map(word => {
        let groupIds = contractionMap[word]
          ? [groupMap[word]]
          : word.split('').map(letter => groupMap[letter]);
        return {groupIds}
      });
      return { text: phrase.text, words}
    });
    const levelJson = JSON.stringify({
      phrases,
      maxGuesses,
      numberOfRounds,
      brailleSheet: selectedSheet,
    });
    const skill = { levelJson, difficulty, levelTemplateId: '-6449910514503171838' };
    this.props.onSubmit(skill, selectedSheet);
  };

  render() {
    const { classes, staffBrailleSheets } = this.props;
    const { tested, phrases } = this.state;
    const sheets = staffBrailleSheets.filter(sheet => sheet.games.some(game => game.id === '414'));
    return (
      <>
        <Dialog
          maxWidth='md'
          fullWidth
          disableEscapeKeyDown
          open={this.props.open}
          onClose={this.props.close}
          aria-labelledby='form-dialog-title'
          PaperProps={{ classes: { root: classes.container } }}
        >
          <DialogTitle disableTypography={true} className={classes.title} id='form-dialog-title'>
            <Typography className={classes.titleText} variant='h4'>
              <FormattedMessage id='Wizard.skillDB.title'/>
            </Typography>
            <IconButton
              onClick={this.close}
              className={classes.exitIcon}
              aria-label="close dialog"
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography gutterBottom variant='h3'>
              <FormattedMessage id='Wizard.Hangman.title'/>
            </Typography>
            <Typography className={classes.description} variant='subtitle1'>
              <FormattedMessage id='Wizard.Hangman.subtitle'/>
            </Typography>
            <Divider className={classes.divider}/>
            <Typography variant='h6'><FormattedMessage id='Wizard.BrailleQuiz.select'/>: </Typography>
            <FormGroup className={classes.configureLine}>
              <TextField
                select
                variant='outlined'
                label='Layout Braille Sheet'
                name='sheet'
                onChange={this.handleSheetChange}
                value={this.state.selected}
              >
                <MenuItem key={"-5274532154501163781"} value={"-5274532154501163781"}>Alphabet Layout: Code 36</MenuItem>
                <MenuItem key={"8539405908300465710"} value={"8539405908300465710"}>QWERTY Layout: Code 37</MenuItem>
              </TextField>
            </FormGroup>
            {
              this.state.selectedSheet.id &&
              <>
                <FormGroup className={classes.configureLine}>
                  <Typography variant='h6'><FormattedMessage id='Wizard.Hangman.numRounds'/>: </Typography>
                  <TextField
                    variant='outlined'
                    className={classes.configComponent}
                    name='numberOfRounds'
                    type='number'
                    value={this.state.numberOfRounds}
                    onChange={this.handleChange}
                  />
                </FormGroup>
                <FormGroup className={classes.configureLine}>
                  <Typography variant='h6'><FormattedMessage id='Wizard.Hangman.maxGuesses'/>: </Typography>
                  <TextField
                    variant='outlined'
                    className={classes.configComponent}
                    name='maxGuesses'
                    type='number'
                    value={this.state.maxGuesses}
                    onChange={this.handleChange}
                  />
                </FormGroup>
                <FormGroup className={classes.configureLine}>
                  <Typography variant='h6'>
                    <FormattedMessage id='General.difficulty'/>:
                  </Typography>
                  <TextField
                    select
                    variant='outlined'
                    id='difficulty-select'
                    name='difficulty'
                    value={this.state.difficulty}
                    onChange={this.handleChange}
                  >
                    {
                      [
                        { value: 'Easy', text: <FormattedMessage id='General.easy'/> },
                        { value: 'Medium', text: <FormattedMessage id='General.medium'/> },
                        { value: 'Hard', text: <FormattedMessage id='General.hard'/> }
                      ].map((option) => <MenuItem key={option.value} value={option.value}>{option.text}</MenuItem>)
                    }
                  </TextField>
                </FormGroup>
                <Typography className={classes.phraseTitle} variant='h6'>Phrases to use in game: </Typography>
                {
                  this.state.phrases.map((phrase,index) => (
                    <FormGroup key={`${index}`} className={classes.configureLine}>
                      <div className={classes.phrase}>
                        <IconButton className={classes.phraseBtn} onClick={() => this.removePhrase(index)}>
                          <Cancel color='secondary'/>
                        </IconButton>
                        <TextField
                          variant='outlined'
                          className={classes.configComponent}
                          name='phrases'
                          value={this.state.phrases[index].text}
                          error={!!this.state.error[`phrase${index}`]}
                          helperText={this.state.error[`phrase${index}`]}
                          onChange={(ev) => this.changePhrase(ev, index)}
                          onBlur={(ev) => !!this.state.contracted[index] ? this.checkContractions(index, phrase, true) : this.checkPhraseLength(ev, index)}
                        />
                        <FormControlLabel
                          control={<Switch checked={!!this.state.contracted[index]} onChange={() => this.checkContractions(index, phrase)} />}
                          label="Use Contractions"
                        />
                      </div>
                      {
                        <div className={classes.contractions}>
                          {
                            this.state.contracted[index] &&
                            <>
                              <Typography className={classes.contractionText} variant='subtitle1'>
                                { phrase.contractions.length > 0 ? 'Contractions to use:' : 'No matching contractions found in sheet' }
                              </Typography>
                              {
                                phrase.contractions.map((contraction, index) => (
                                  <FormControlLabel
                                    key={index}
                                    control={
                                      <Checkbox
                                        checked={this.state.contractionMap[contraction]}
                                        onChange={this.changeContractionSelection}
                                        value={contraction}
                                        inputProps={{
                                          'aria-label': `${contraction} checkbox`,
                                        }}
                                      />
                                    }
                                    label={contraction}
                                  />
                                ))
                              }
                            </>
                          }
                        </div>
                      }
                    </FormGroup>
                  ))
                }
                <div className={classes.questionBtns}>
                  <Button
                    variant='contained'
                    id='add-question'
                    color='primary'
                    classes={{ root: classes.addButton }}
                    onClick={this.addPhrase}
                  >
                    <FormattedMessage id='Wizard.Hangman.addPhrase'/>
                  </Button>
                </div>
              </>
            }
          </DialogContent>
          <DialogActions className={classes.buttons}>
            <Button id='back' onClick={this.props.back} color='secondary'>
              <FormattedMessage id='General.back'/>
            </Button>
            <Button
              onClick={this.runTest}
              variant={tested ? 'outlined' : 'text'}
              color={tested ? 'secondary' : 'primary'}
            >
              {tested ? <FormattedMessage id='General.retest'/> : <FormattedMessage id='General.test'/>}
            </Button>
            <Button
              disabled={!phrases[0].text || this.state.numberOfQuestionsPerGame > phrases.length || Object.entries(this.state.error).length > 0}
              aria-label='go to final page of skill creator'
              onClick={this.onSubmit}
              color='primary'
            >
              <FormattedMessage id='General.next'/>
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

const  mapState = ({ platform: { staffBrailleSheets },  shared: { user }}) => {
  return {
    staffBrailleSheets,
    user
  }
};

const mapDispatch = (dispatch) => {
  return {
    fetchStaffBrailleSheets: (staffId) => dispatch(fetchStaffBrailleSheets(staffId)),
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(CodeBreakerSkillDatabank));