import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import {
  Button,
  FormGroup,
  Typography,
  IconButton,
  TextField,
  Select,
  OutlinedInput,
  MenuItem,
  Divider,
  Paper,
  Checkbox
} from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle, withStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import BrailleSheet from '../BrailleSheets/BrailleSheetWizardGrid';
import BrailleSheetsImportQuestion from '../BrailleSheets/BrailleSheetsImportQuestion';
import ImportBrailleSheet from '../../Staff/ImportBrailleSheet';
import { ROOT_URL } from '../../../../redux/constants';
import {
  fetchStaffBrailleSheets,
  addQuestions,
  fetchStaffBrailleSheetsForGame,
  getBrailleSheetById,
  fetchMyBrailleSheets
} from '../../../../redux/actions';
import baseStyle from '../../../../styles/brailleSkill';

class BrailleQuizSkillDatabank extends Component {

  state={
    selected: '',
    selectedSheet: {},
    error: {},
    questions: [{ question: '', answers: [], acceptableAnswerIds: [] }],
    numberOfQuestionsPerGame: 1,
    shuffleQuestions: true,
    difficulty: 'Easy',
    maxSecondsPerQuestion: '30',
    aiDifficulty: 1,
    numberOfOpponents: 0,
    import: false,
    tested: false
  };

  componentDidMount() {
    // this.props.fetchStaffBrailleSheets(this.props.id);
    // this.props.fetchStaffBrailleSheetsForGame(this.props.id, this.props.game);
    this.props.fetchMyBrailleSheets(this.props.game);
    if (this.props.level.id) {
      const variables = JSON.parse(this.props.level.levelJson);
      this.setState({
        ...variables,
        questions: variables.questions.map(question => {
          const groups = JSON.parse(variables.brailleSheet.grid).groups;
          const answers = question.acceptableAnswerIds.map(answer => groups.find(group => group.id === answer).text);
          return { question: question.question, answers }
        }),
        difficulty: this.props.level.difficulty,
        selected: variables.brailleSheet.id,
        selectedSheet: variables.brailleSheet,
        grid: JSON.parse(variables.brailleSheet.grid)
      })
    }
  }

  close = () => {
    this.props.close();
  };

  addQuestion = () => {
    this.setState({ questions: [...this.state.questions, { question: '', answers: [], acceptableAnswerIds: [] }] })
  };

  importQuestion = (question) => {
    this.setState(state => ({ questions: [...state.questions, question] }));
  };

  toggleImport = () => {
    this.setState(state => ({ import: !state.import }));
  };

  removeQuestion = (idx) => {
    const questions = this.state.questions.filter((question, index) => index !== idx);
    this.setState({ questions })
  };

  handleChange = (ev) => {
    const value = ev.target.type === 'number' ? ev.target.value > 0 ? ev.target.value * 1 : '' : ev.target.value;
    this.setState({ [ev.target.name]: value })
  };

  handleSheetChange = (sheet, show) => {
    if (show) {
      getBrailleSheetById(sheet.brailleSheetId)
        .then((_sheet) => {
          const grid = JSON.parse(_sheet.grid);
          this.setState({ selectedSheet: _sheet, grid });
        });
    } else {
      if (sheet.key) {
        getBrailleSheetById(sheet.brailleSheetId)
          .then((_sheet) => {
            const selected = sheet.key;
            const grid = JSON.parse(_sheet.grid);
            this.setState({ selected, selectedSheet: _sheet, grid });
          });
      } else {
        const selectedSheet = sheet;
        const selected = sheet.id;
        const grid = JSON.parse(selectedSheet.grid);
        this.setState({ selected, selectedSheet, grid });
      }
    }
  };

  handleChangeQuestion = (ev, index) => {
    const value = ev.target.value;
    this.setState(prevState => (
      { questions: prevState.questions.map(
          obj => this.state.questions.indexOf(obj) === index ? {...obj, question: value } : obj
        )
      }
    ))
  };

  handleChangeAnswer = (ev, index) => {
    const value = ev.target.value;
    const acceptableAnswerIds = value.map(answer => this.state.grid.groups.find(group => group.text === answer).id);
    this.setState(prevState => (
      { questions: prevState.questions.map(
          obj => this.state.questions.indexOf(obj) === index ? {...obj, answers: value, acceptableAnswerIds } : obj
        )
      }
    ))
  };

  runTest = () => {
    const {
      questions,
      numberOfQuestionsPerGame,
      maxSecondsPerQuestion,
      numberOfOpponents,
      aiDifficulty,
      shuffleQuestions,
      selectedSheet,
      tested,
    } = this.state;
    const { groups } = this.state.grid;
    const url = this.props.type === 'staff'
      ? `${ROOT_URL}/staff/${this.props.id}/objective-databank/test`
      : `${ROOT_URL}/admin/objective-databank/test`;
    if (tested) this.clearTest();
    const shuffle = numberOfQuestionsPerGame < questions.length ? true : shuffleQuestions;
    const questionArr = questions.map(question => {
      if (question.acceptableAnswerIds){
        return { question: question.question, acceptableAnswerIds: question.acceptableAnswerIds }
      }
      else {
        const answerId = question.answers.map(answer => groups.find(group => group.text.toLowerCase() === answer.toLowerCase()).id);
        return { question: question.question, acceptableAnswerIds: answerId }
      }
    });
    axios.post(url, {
      levelVariables: JSON.stringify({
        questions: questionArr,
        numberOfQuestionsPerGame,
        maxSecondsPerQuestion,
        numberOfOpponents,
        aiDifficulty,
        shuffleQuestions: shuffle,
        brailleSheet: selectedSheet,
      }),
      templateId: '6073477090168919317'
    })
      .catch(err => console.log(err));
    this.setState({ tested: true });
  };

  clearTest = () => {
    const url = this.props.type === 'staff'
      ? `${ROOT_URL}/staff/${this.props.id}/objective-databank/test`
      : `${ROOT_URL}/admin/objective-databank/test`;
    axios.delete(url)
      .catch(err => console.log(err));
    this.setState({ tested: false })
  };

  onSubmit = (ev) => {
    ev.preventDefault();
    const { groups } = this.state.grid;
    const { questions, numberOfQuestionsPerGame, maxSecondsPerQuestion, numberOfOpponents, aiDifficulty, shuffleQuestions, selectedSheet, difficulty } = this.state;
    const questionArr = questions.map(question => {
      if (question.acceptableAnswerIds){
        return { question: question.question, acceptableAnswerIds: question.acceptableAnswerIds }
      }
      else {
        const answerId = question.answers.map(answer => groups.find(group => group.text.toLowerCase() === answer.toLowerCase()).id);
        return { question: question.question, acceptableAnswerIds: answerId }
      }
    });
    const questionJson = questions.map(_question => {
      const { grid } = this.state;
      const { acceptableAnswerIds, question, answers } = _question;
      const _answers = acceptableAnswerIds
        ? acceptableAnswerIds.map(answer => grid.groups[answer].text)
        : answers;
      return {question, answer: _answers.join(',')}
    });
    const shuffle = numberOfQuestionsPerGame < questions.length ? true : shuffleQuestions;
    const levelJson = JSON.stringify({
      questions: questionArr,
      numberOfQuestionsPerGame,
      shuffleQuestions: shuffle,
      brailleSheet: selectedSheet,
      maxSecondsPerQuestion,
      numberOfOpponents,
      aiDifficulty
    });
    const skill = { levelJson, difficulty, levelTemplateId: '6073477090168919317' };
    if (this.state.numberOfQuestionsPerGame > questions.length){
      this.setState({ error: {...this.state.error, tooManyQuestions: true }})
    }
    else {
      this.props.onSubmit(skill, selectedSheet);
    }
    addQuestions(questionJson);
  };

  checkForErrors = (ev, index) => {
    if (!ev.target.value){
      if (index !== null) {
        this.setState({ error: {...this.state.error, [ev.target.name]: {...this.state.error[ev.target.name], [index]: true }}})
      }
      else {
        this.setState({ error: {...this.state.error, [ev.target.name]: true }})
      }
    }
    else {
      let error = this.state.error;
      if (ev.target.name === 'numberOfQuestionsPerGame'){
        delete error.tooManyQuestions;
      }
      if (index !== null) {
        if (Object(error[ev.target.name]).length > 1){
          delete error[ev.target.name][index];
        }
        else {
          delete error[ev.target.name];
        }
      }
      else {
        delete error[ev.target.name];
      }
      this.setState({ error })
    }
  };

  render()  {
    const { classes, staffBrailleSheets } = this.props;
    const { selectedSheet, grid, tested, questions, error } = this.state;
    const empty = this.state.questions.some(question => question.question === '' || question.answers === []);
    const disabled = Object.keys(error).length > 0 || empty;
    return (
      <>
        <Dialog
          maxWidth='xl'
          disableEscapeKeyDown
          fullWidth={true}
          open={this.props.open}
          onClose={this.props.close}
          aria-labelledby='form-dialog-title'
          PaperProps={{classes:{ root: classes.container }}}
        >
          <DialogTitle disableTypography={true} className={classes.title} id='form-dialog-title'>
            <Typography className={classes.titleText} variant='h4'><FormattedMessage id='Wizard.skillDB.title'/> </Typography>
            <IconButton
              onClick={this.close}
              className={ classes.exitIcon }
              aria-label="close dialog"
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography gutterBottom variant='h3'>
              <FormattedMessage id='Wizard.BrailleQuiz.title'/>
            </Typography>
            <Typography className={classes.description} variant='subtitle1'>
              <FormattedMessage id='Wizard.BrailleQuiz.subtitle'/>
            </Typography>
            <Divider className={classes.divider}/>
            <Typography variant='h6'><FormattedMessage id='Wizard.BrailleQuiz.select' />: </Typography>
            <ImportBrailleSheet myBrailleSheets={this.props.myBrailleSheets} grid={this.state.grid} selected={this.state.selected} selectSheet={this.handleSheetChange} game={this.props.game} id={this.props.id} brailleSheets={staffBrailleSheets}/>
              {
                !this.state.selectedSheet.id || !this.state.selected
                ? <Typography align='center' variant='h6'><FormattedMessage id='Wizard.BrailleQuiz.empty'/></Typography>
                : <Paper className={classes.grid}>
                    <BrailleSheet sheet={selectedSheet} grid={grid}/>
                  </Paper>
              }
            {
              this.state.selectedSheet.id && this.state.selected &&
              <div className={classes.questions}>
                <FormGroup className={classes.brailleLine}>
                  <Typography className={classes.lineText} variant='h6'><FormattedMessage id='General.description'/>: </Typography>
                  <br />
                  <Typography className={classes.descriptionText} variant='subtitle2'>{selectedSheet.description}</Typography>
                </FormGroup>
                <FormGroup className={classes.brailleLine}>
                  <Typography className={classes.lineText} variant='h6'><FormattedMessage id='General.difficulty'/>: </Typography>
                  <TextField
                    select
                    variant='outlined'
                    id='difficulty-select'
                    name='difficulty'
                    value={this.state.difficulty}
                    onChange={this.handleChange}
                  >
                    {
                      [
                        { value: 'Easy', text: <FormattedMessage id='General.easy' /> },
                        { value: 'Medium', text: <FormattedMessage id='General.medium' /> },
                        { value: 'Hard', text: <FormattedMessage id='General.hard' /> }
                      ].map((option) => <MenuItem key={option.value} value={option.value}>{option.text}</MenuItem>)
                    }
                  </TextField>
                </FormGroup>
                <FormGroup className={classes.brailleLine}>
                  <Typography className={classes.lineText} variant='h6'><FormattedMessage id='Wizard.BrailleQuiz.numQuestions'/>: </Typography>
                  <TextField
                    error={error.tooManyQuestions || error.numberOfQuestionsPerGame}
                    helperText={
                      error.tooManyQuestions
                        ? <FormattedMessage id='Wizard.BrailleQuiz.error'/>
                        : error.numberOfQuestionsPerGame
                          ? '* required'
                          : ''
                      }
                    variant='outlined'
                    id='questions-per-game'
                    name='numberOfQuestionsPerGame'
                    type='number'
                    value={this.state.numberOfQuestionsPerGame}
                    onChange={this.handleChange}
                    onBlur={this.checkForErrors}
                  />
                </FormGroup>
                <FormGroup className={classes.brailleLine}>
                  <Typography className={classes.lineText} variant='h6'><FormattedMessage id='Wizard.BrailleQuiz.time'/></Typography>
                  <TextField
                    variant='outlined'
                    id='time-per-question'
                    name='maxSecondsPerQuestion'
                    type='number'
                    value={this.state.maxSecondsPerQuestion}
                    error={error.maxSecondsPerQuestion}
                    helperText={error.maxSecondsPerQuestion ? '* required' : ''}
                    onBlur={this.checkForErrors}
                    onChange={this.handleChange}
                  />
                </FormGroup>
                <FormGroup className={classes.brailleLine}>
                  <Typography className={classes.lineText} variant='h6'><FormattedMessage id='Wizard.BrailleQuiz.shuffle'/></Typography>
                  <TextField
                    disabled={this.state.numberOfQuestionsPerGame < questions.length}
                    select
                    variant='outlined'
                    id='shuffle-questions-select'
                    name='shuffleQuestions'
                    value={this.state.numberOfQuestionsPerGame > questions.length ? true : this.state.shuffleQuestions}
                    onChange={this.handleChange}
                  >
                    {
                      [
                        {text: <FormattedMessage id='General.yes'/>, value: true},
                        {text:  <FormattedMessage id='General.no'/>, value: false}
                        ].map((option) => (
                          <MenuItem key={option.value} value={option.value}>{option.text}</MenuItem>
                        ))
                    }
                  </TextField>
                </FormGroup>
                <FormGroup className={classes.brailleLine}>
                  <Typography className={classes.lineText} variant='h6'><FormattedMessage id='Wizard.BrailleQuiz.numOpponents'/></Typography>
                  <TextField
                    select
                    variant='outlined'
                    id='number-of-opponents'
                    name='numberOfOpponents'
                    value={this.state.numberOfOpponents}
                    onChange={this.handleChange}
                  >
                    {
                      [0, 1, 2, 3].map((option) => <MenuItem key={option} value={option}>{option}</MenuItem>)
                    }
                  </TextField>
                </FormGroup>
                <FormGroup className={classes.brailleLine}>
                  <Typography className={classes.lineText} variant='h6'><FormattedMessage id='Wizard.AudioMemory.opponent'/>: </Typography>
                  <TextField
                    select
                    variant='outlined'
                    id='opponent-difficulty-select'
                    name='aiDifficulty'
                    value={this.state.aiDifficulty}
                    onChange={this.handleChange}
                  >
                    {
                      [
                        {value: 1, text: <FormattedMessage id='General.easy' />},
                        {value: 5, text: <FormattedMessage id='General.medium' />},
                        {value: 9, text: <FormattedMessage id='General.hard' />}
                      ].map((option) => <MenuItem key={option.value} value={option.value}>{option.text}</MenuItem>)
                    }
                  </TextField>
                </FormGroup>
                {
                  questions.map((question, index) => {
                   return (
                    <div className={classes.question} key={index}>
                      <FormGroup className={classes.questionLine}>
                        <Typography className={classes.lineText} variant='h6'><FormattedMessage id='Wizard.BrailleQuiz.question' />: </Typography>
                        <TextField
                          multiline
                          variant='outlined'
                          id={`question-${index}`}
                          name='question'
                          error={error.question && error.question[index]}
                          helperText={error.question && error.question[index] ? '* required' : ''}
                          onBlur={(ev) => this.checkForErrors(ev, index)}
                          value={questions[index].question}
                          onChange={(ev) => this.handleChangeQuestion(ev, index)}
                        />
                      </FormGroup>
                      <FormGroup className={classes.answerLine}>
                        <Typography className={classes.lineText}  variant='h6'><FormattedMessage id='Wizard.BrailleQuiz.answer' />: </Typography>
                        <Select
                            multiple
                          input={<OutlinedInput labelWidth={0} id="select-multiple-checkbox" />}
                            renderValue={selected => selected.join(', ')}
                            id={`answers-${index}`}
                          name='answers'
                          error={error.answer && error.answer[index]}
                          onBlur={(ev) => this.checkForErrors(ev, index)}
                          value={questions[index].answers}
                          onChange={(ev) => this.handleChangeAnswer(ev, index)}
                        >
                          {
                            grid.groups.map(group => (
                              <MenuItem key={group.id} value={group.text}>
                                <Checkbox checked={questions[index].answers.indexOf(group.text) > -1} />{group.text.toUpperCase()}
                              </MenuItem>
                            ))
                          }
                        </Select>
                      </FormGroup>
                      <IconButton onClick={() => this.removeQuestion(index)} classes={{root:classes.delete}}>
                        <Close color='secondary'/>
                      </IconButton>
                    </div>
                   )
                  })
                }
                <div className={classes.questionBtns}>
                  <Button
                    variant='contained'
                    id='add-question'
                    color='primary'
                    classes={{ root: classes.addButton }}
                    onClick={this.addQuestion}
                  >
                    <FormattedMessage id='Wizard.BrailleQuiz.addQuestion'/>
                  </Button>
                  <Button
                    variant='contained'
                    id='import-question'
                    classes={{ root: classes.addButton }}
                    onClick={this.toggleImport}
                  >
                    <FormattedMessage id='Wizard.BrailleQuiz.import'/>
                  </Button>
                </div>
              </div>
            }
          </DialogContent>
          <DialogActions className={classes.buttons}>
            <Button id='back' onClick={this.props.back} color='secondary'>
              <FormattedMessage id='General.back'/>
            </Button>
            <Button
              onClick={ this.runTest }
              variant={ tested ? 'outlined' : 'text' }
              color={ tested ? 'secondary' : 'primary' }
            >
              { tested ? <FormattedMessage id='General.retest' /> : <FormattedMessage id='General.test' />}
            </Button>
            <Button
              disabled={disabled}
              id='submit'
              onClick={this.onSubmit}
              color='primary'>
              <FormattedMessage id='General.next'/>
            </Button>
          </DialogActions>
        </Dialog>
        {
          this.state.selectedSheet.id &&
          <BrailleSheetsImportQuestion
            open={this.state.import}
            toggleImport={this.toggleImport}
            importQuestion={this.importQuestion}
            groups={grid.groups}
          />
        }
      </>
    )
  }
}

const mapState = ({ platform: { staffBrailleSheets }, shared: { account }, social: { myBrailleSheets }}) => {
  return {
    staffBrailleSheets,
    account,
    myBrailleSheets,
  }
};

const mapDispatch = (dispatch) => {
  return {
    fetchStaffBrailleSheets: (staffId) => dispatch(fetchStaffBrailleSheets(staffId)),
    fetchStaffBrailleSheetsForGame: (staffId, game) => dispatch(fetchStaffBrailleSheetsForGame(staffId, game)),
    fetchMyBrailleSheets: (gameId) => dispatch(fetchMyBrailleSheets(gameId)),
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(BrailleQuizSkillDatabank));