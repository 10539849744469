import React, { Component } from 'react';
import { Card, Divider, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../styles/listChart';
import { ListAlt } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';

class SupportListChart extends Component {

  render(){
    const { classes, data, title } = this.props;
    const chart = data || [];
    return (
      <Card className={classes.listChartSupport}>
        <div className={classes.header}>
          <ListAlt className={classes.icon}/>
          <Typography className={classes.chartTitle} variant='h4'>{title}</Typography>
        </div>
        <Divider />
        {
          <div className={classes.list}>
            {
              chart.length <= 0
              ? <>
                  <Skeleton />
                  <Skeleton />
                </>
              : <>
                <div className={classes.listHeader}>
                  <div className={classes.label}> </div>
                  <div className={classes.value}><strong>Last 7</strong></div>
                  <div className={classes.value}><strong>Last 30</strong></div>
                </div>
                {
                  chart.map((item, index) => (
                    <div className={classes.listItemSupport} key={index}>
                      <div className={classes.label}>{ item.label }</div>
                      <div className={classes.value}>{ item.value7 }</div>
                      <div className={classes.value}>{ item.value30 }</div>
                    </div>
                  ))
                }
              </>
            }
          </div>
        }
      </Card>
    )
  }
}

export default withStyles(baseStyle)(SupportListChart);