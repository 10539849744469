import React, { Component } from 'react';
import { saveAs } from 'file-saver';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormGroup,
  IconButton, MenuItem,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core';
import { Close, Edit } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
import { downloadTerms } from '../../../../redux/actions';
import baseStyle from '../../../../styles/skillList.js';
import { states } from '../../../../helpers';

class AccountDetails extends Component {

  state = {
    error: ''
  };

  close = () => {
    this.props.closeAccount();
    this.setState({ error: '' })
  };

  downloadTerms = (id) => {
    downloadTerms(id)
      .then((response) => {
        saveAs(response.data)
      })
      .catch(err => {
      console.log({err});
      this.setState({error: 'Error downloading Terms of Use'})
    })
  };

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        open={this.props.open}
        onClose={this.close}
      >
        <DialogTitle className={classes.title} disableTypography={true}>
          <Typography className={classes.titleText} variant='h4'> {this.props.name} </Typography>
          <IconButton
            onClick={this.props.toggleEdit}
            className={classes.editIcon}
            aria-label='close dialog'
          >
            {
              this.props.edit
                ? <Close />
                : <Edit/>
            }
          </IconButton>
        </DialogTitle>
        {
          this.props.account &&
          this.props.edit
            ? <FormGroup className={classes.billingInfo}>
              <TextField
                className={classes.firstName}
                name='primaryEmail'
                label={<FormattedMessage id='General.primaryEmail'/>}
                value={this.props.account.primaryEmail}
                onChange={this.props.handleBillingEdit}
              />
              <TextField
                className={classes.firstName}
                name='secondaryEmail'
                label={<FormattedMessage id='General.secondaryEmail'/>}
                value={this.props.account.secondaryEmail}
                onChange={this.props.handleBillingEdit}
              />
              <TextField
                disabled
                className={classes.firstName}
                name='startDate'
                label={<FormattedMessage id='General.startDate'/>}
                value={`${this.props.account.startDate.monthValue}/${this.props.account.startDate.dayOfMonth}/${this.props.account.startDate.year}`}
              />
              <TextField
                disabled
                className={classes.firstName}
                name='rate'
                label={<FormattedMessage id='General.rate'/>}
                value={this.props.account.rate}
              />
              <TextField
                disabled
                className={classes.firstName}
                id='billing-cycle'
                label={<FormattedMessage id='General.cycle'/>}
                type='text'
                name='cycle'
                value={this.props.account.cycle}
              />
              <hr/>
              <TextField
                className={classes.firstName}
                name='street1'
                label={<FormattedMessage id='General.street1'/>}
                value={this.props.account.street1}
                onChange={this.props.handleBillingEdit}
              />
              <TextField
                className={classes.firstName}
                name='street2'
                label={<FormattedMessage id='General.street2'/>}
                value={this.props.account.street2}
                onChange={this.props.handleBillingEdit}
              />
              <FormGroup className={classes.addressLine}>
                <TextField
                  className={classes.firstName}
                  name='city'
                  label={<FormattedMessage id='General.city'/>}
                  value={this.props.account.city}
                  onChange={this.props.handleBillingEdit}
                />
                <TextField
                  select
                  classes={{ root: classes.firstName }}
                  id='state'
                  label={<FormattedMessage id='General.state'/>}
                  name='state'
                  value={this.props.account.state}
                  onChange={this.props.handleBillingEdit}
                >
                  {
                    states.map(state => <MenuItem key={state} value={state}> {state} </MenuItem>)
                  }
                </TextField>
                <TextField
                  className={classes.firstName}
                  name='postalCode'
                  label={<FormattedMessage id='General.postalCode'/>}
                  value={this.props.account.postalCode}
                  onChange={this.props.handleBillingEdit}
                />
                <TextField
                  select
                  classes={{ root: classes.firstName }}
                  id='country-name'
                  label={<FormattedMessage id='General.country'/>}
                  name='country'
                  value={this.props.account.country}
                  onChange={this.props.handleBillingEdit}
                >
                  <MenuItem key='US' value='United States'> United States </MenuItem>
                  <MenuItem key='CA' value='Canada'> Canada </MenuItem>
                </TextField>
              </FormGroup>
            </FormGroup>
            : <div className={classes.billingInfo}>
              <div className={classes.detailsLine}>
                <Typography variant='h6'><FormattedMessage id='General.primaryEmail'/>: </Typography>
                <Typography variant='subtitle1'>{this.props.account.primaryEmail}</Typography>
              </div>
              {
                this.props.account.startDate &&
                <div className={classes.detailsLine}>
                  <Typography variant='h6'>Account Created: </Typography>
                  <Typography variant='subtitle1'>{`${this.props.account.startDate.monthValue}/${this.props.account.startDate.dayOfMonth}/${this.props.account.startDate.year}`}</Typography>
                </div>
              }
              <div className={classes.detailsLine}>
                <Typography variant='h6'><FormattedMessage id='General.rate'/>: </Typography>
                <Typography variant='subtitle1'>{this.props.account.rate}</Typography>
              </div>
              <div className={classes.detailsLine}>
                <Typography variant='h6'><FormattedMessage id='General.cycle'/>: </Typography>
                <Typography variant='subtitle1'>{this.props.account.cycle}</Typography>
              </div>
              <hr/>
              <div className={classes.detailsLine}>
                <Typography variant='h6'>Billing Info: </Typography>
                <div className={classes.address}>
                  <Typography variant='subtitle1'>{this.props.account.street1}</Typography>
                  {
                    this.props.account.street2 &&
                    <Typography variant='subtitle1'>{this.props.account.street2}</Typography>
                  }
                  <Typography variant='subtitle1'>{`${this.props.account.city}, ${this.props.account.state} ${this.props.account.postalCode}`}</Typography>
                  <Typography variant='subtitle1'>{this.props.account.country}</Typography>
                </div>
                {
                  this.props.account.id &&
                  <Button className={classes.download} variant='contained' onClick={() => this.downloadTerms(this.props.id)}> Download Terms of Use </Button>
                }
                {
                  this.state.error &&
                  <Typography variant='caption' color='secondary' className={classes.termsError}> {this.state.error} </Typography>
                }
              </div>
            </div>
        }
        <DialogActions className={classes.buttons}>
          {
            this.props.edit
              ? <Button onClick={this.props.save} color='primary'>
                <FormattedMessage id='General.save'/>
              </Button>
              : <Button onClick={this.close} color='primary'>
                <FormattedMessage id='General.ok'/>
              </Button>
          }
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(baseStyle)(AccountDetails);