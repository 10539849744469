export default {
  container: {
    color: '#310073',
    overflow: 'visible',
  },
  root: {
    marginTop: '20px',
  },
  title: {
    backgroundColor: '#383871',
    marginBottom: 15,
  },
  dialogTitle: {
    marginBottom: 15
  },
  titleText: {
    float: 'left',
    color: '#fff'
  },
  exitIcon: {
    float: 'right',
    color: '#fff',
  },
  content: {
    overflow: 'visible',
  },
  select: {
    marginTop: '30px',
    marginBottom: '10px',
    position: 'fixed'
  },
  table: {
    marginTop: 20
  },
  error: {
    padding: 50
  }
}