export default {
  container: {
    color: '#0a0a0a',
  },
  title: {
    backgroundColor: '#383871',
    marginBottom: '15px',
  },
  titleText: {
    float: 'left',
    color: '#fff'
  },
  description: {
    marginBottom: 20
  },
  divider: {
    marginBottom: 20
  },
  exitIcon: {
    float: 'right',
    color: '#fff',
  },
  select: {
    marginBottom: 20
  },
  root: {
    marginTop: '20px',
    color: '#310073',
  },
  questions:{
    marginTop: 20
  },
  question: {
    border: '1px solid black',
    borderRadius: 5,
    paddingBottom: 10,
    display: 'grid',
    gridRowGap: '5px'
  },
  questionLine: {
    gridColumn: 1,
    display: 'grid',
    margin: '0 10px',
  },
  lastLine: {
    display: 'grid',
    margin: '0 10px',
    marginBottom: 20,
    gridAutoFlow: 'column',
    gridTemplateColumns: 'auto',
    gridColumnGap: '10px'
  },
  inlineText: {
    alignSelf: 'end',
    minWidth: 0,
    color: '#310073',
  },
  inlineSelect: {
    color: '#310073',
    alignSelf: 'end',
    minWidth: 0
  },
  letterInput: {
    width: 25,
    alignSelf: 'end',
    minWidth: 0
  },
  brailleLine: {
    display: 'grid',
    gridTemplateColumns: '3fr 5fr',
    marginBottom: 10
  },
  delete: {
    alignSelf: 'start',
    justifySelf: 'end',
    gridRow: 1,
    gridColumn: 1,
  },
  lineText: {
    color: '#310073',
    alignSelf: 'center',
    marginRight: 10
  },
  descriptionText: {
    gridColumn: 'span 2',
    marginBottom: 15
  },
  addButton: {
    textAlign: 'center',
    margin: '0 auto'
  },
  buttons: {
    justifySelf: 'end',
    marginRight: '20px',
    marginTop: '40px',
  },
  questionBtns: {
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'center',
    gridColumnGap: '20px',
    marginTop: 20
  },
  grid: {
    width: '50%',
    margin: '0 auto',
    padding: 15
  },
  phrase: {
    display: 'grid',
    gridColumnGap: 15,
    gridTemplateColumns: 'auto 1fr auto',
  },
  puzzle: {
    display: 'grid',
    gridColumnGap: 15,
    gridTemplateColumns: 'auto 1fr 1fr 1fr auto',
  },
  phraseTitle: {
    marginBottom: 10
  },
  phraseBtn: {
    alignSelf: 'center'
  },
  contractions: {
    display: 'grid',
    gridColumnGap: 10,
    gridAutoFlow: 'column',
    justifyContent: 'start',
    padding: 15
  },
  contractionText: {
    alignSelf: 'center'
  },
  configureLine: {
    marginBottom: 10
  },
  screenShot: {
    textAlign: 'center',
    margin: '15px 0'
  },
  error: {
    marginTop: 20
  }
}