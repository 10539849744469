import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, FormGroup, Typography, TextField, IconButton, MenuItem } from '@material-ui/core/index';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputAdornment } from '@material-ui/core/index';
import { Close } from '@material-ui/icons/index';
import { withStyles } from '@material-ui/core/styles/index';
import { states } from '../../../../helpers';
import baseStyle from '../../../../styles/wizardBase';

class AccountWizardPage2 extends Component {

  state = {
    terms: undefined
  };

  upload = (ev) => {
    const terms = this.props.handleFileChange(ev);
    this.setState({ terms })
  };

  render() {
    const { classes, billing: {
      primaryEmail,
      secondaryEmail,
      street1,
      street2,
      state,
      postalCode,
      country,
      city,
      rate,
      cycle
    }} = this.props;
    const incomplete =
      !primaryEmail
      || !street1
      || !state
      || !postalCode
      || !country
      || !city
      || !rate
      || !cycle
      || !this.state.terms;
    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        open={this.props.open}
        onClose={this.props.close}
        aria-labelledby='form-dialog-title'
        PaperProps={{ classes: { root: classes.container } }}
      >
        <DialogTitle disableTypography={true} className={classes.title} id='form-dialog-title'>
          <Typography className={classes.titleText} variant='h4'><FormattedMessage id='Wizard.Support.title'/></Typography>
          <IconButton
            onClick={this.props.close}
            className={classes.exitIcon}
            aria-label='close dialog'
          >
            <Close/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage id='Wizard.Account.billing'/> (<FormattedMessage id='General.required'/>)
          </DialogContentText>
          <FormGroup>
            <TextField
              classes={{ root: classes.firstName }}
              id='primary-email'
              label={<FormattedMessage id='General.primaryEmail'/>}
              type='text'
              name='primaryEmail'
              variant='outlined'
              value={primaryEmail}
              onChange={this.props.handleChange}
            />
            <TextField
              classes={{ root: classes.firstName }}
              id='secondary-email'
              label={<FormattedMessage id='General.secondaryEmail'/>}
              type='text'
              name='secondaryEmail'
              variant='outlined'
              value={secondaryEmail}
              onChange={this.props.handleChange}
            />
            <TextField
              classes={{ root: classes.firstName }}
              id='address-line-1'
              label={<FormattedMessage id='General.street1'/>}
              type='text'
              name='street1'
              variant='outlined'
              value={street1}
              onChange={this.props.handleChange}
            />
            <TextField
              classes={{ root: classes.firstName }}
              id='address-line-2'
              label={<FormattedMessage id='General.street2'/>}
              type='text'
              name='street2'
              variant='outlined'
              value={street2}
              onChange={this.props.handleChange}
            />
            <FormGroup className={classes.addressLine}>
              <TextField
                classes={{ root: classes.firstName }}
                id='city'
                label={<FormattedMessage id='General.city'/>}
                type='text'
                name='city'
                variant='outlined'
                value={city}
                onChange={this.props.handleChange}
              />
              <TextField
                select
                classes={{ root: classes.firstName }}
                id='state'
                label={<FormattedMessage id='General.state'/>}
                name='state'
                variant='outlined'
                value={state}
                onChange={this.props.handleChange}
              >
                {
                  states.map(state => <MenuItem key={state} value={state}> {state} </MenuItem>)
                }
              </TextField>
              <TextField
                classes={{ root: classes.firstName }}
                id='postal-code'
                label={<FormattedMessage id='General.postalCode'/>}
                type='text'
                name='postalCode'
                variant='outlined'
                value={postalCode}
                onChange={this.props.handleChange}
              />
            </FormGroup>
            <TextField
              select
              classes={{ root: classes.firstName }}
              id='country-name'
              label={<FormattedMessage id='General.country'/>}
              name='country'
              variant='outlined'
              value={country}
              onChange={this.props.handleChange}
            >
              <MenuItem key='US' value='United States'> United States </MenuItem>
              <MenuItem key='CA' value='Canada'> Canada </MenuItem>
            </TextField>
            <FormGroup className={classes.priceLine}>
              <TextField
                classes={{ root: classes.firstName }}
                id='rate'
                label={<FormattedMessage id='General.rate'/>}
                type='text'
                name='rate'
                variant='outlined'
                value={rate}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
                onChange={this.props.handleChange}
              />
              <TextField
                select
                classes={{ root: classes.firstName }}
                id='billing-cycle'
                label={<FormattedMessage id='General.cycle'/>}
                type='text'
                name='cycle'
                variant='outlined'
                value={cycle}
                onChange={this.props.handleChange}
              >
                {/*<MenuItem value='MONTHLY'> Monthly </MenuItem>*/}
                <MenuItem value='ANNUALLY'> Annually </MenuItem>
                <MenuItem value='BIENNIAL'> Biennial </MenuItem>
                <MenuItem value='TRIENNIAL'> Triennial </MenuItem>
              </TextField>
            </FormGroup>
            <input
              accept='application/pdf'
              className={classes.input}
              style={{ display: 'none' }}
              id="contained-button-file"
              multiple
              type="file"
              onChange={this.upload}
            />
            <label htmlFor="contained-button-file">
              <div className={classes.upload}>
                <Button aria-label='upload terms' variant="contained" component="span" className={classes.button}>
                  Upload Signed Terms of Use
                </Button>
                {
                  this.state.terms
                  ? <Typography align='center' variant='subtitle2' component='span'>File Uploaded: {this.state.terms.name}</Typography>
                  : <Typography variant='caption' component='span'>* File must be in PDF format</Typography>
                }
              </div>
            </label>
          </FormGroup>
        </DialogContent>
        <DialogActions className={classes.buttons}>
          <Button onClick={this.props.back} color='secondary'>
            <FormattedMessage id='General.back'/>
          </Button>
          <Button disabled={incomplete} onClick={() => this.props.submit('billable')} color='primary'>
            <FormattedMessage id='General.submit'/>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(baseStyle)(AccountWizardPage2);