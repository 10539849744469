import React, { Component } from 'react';
import { Card, Typography, Divider } from '@material-ui/core';
import { ListAlt } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../styles/listChart';

class ListChart extends Component {

  formatName = (name) => {
    return name
      .replace(/([A-Z0-9])/g, ` $1`)
      .replace(/^./, (str) => str.toUpperCase())
  };

  render(){
    const { classes, title, chart } = this.props;
    const data = chart ? chart.dataSet : this.props.data ? this.props.data : [];
    return (
      <Card className={classes.listChart}>
        <div className={classes.header}>
          <ListAlt className={classes.icon}/>
          <Typography className={classes.chartTitle} variant='h4'>{title}</Typography>
        </div>
        <Divider />
        <div className={classes.list}>
          {
            data.map((item, index) => (
              <div className={classes.listItem} key={index}>
                <div className={classes.key}>{this.formatName(item.label)}</div>
                <div className={classes.value}>{item.value <= 1 ? `${Math.round(item.value * 100)}%` : item.value }</div>
              </div>
            ))
          }
        </div>
      </Card>
    )
  }
}

export default withStyles(baseStyle)(ListChart);