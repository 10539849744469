export default {
  listChart: {
    gridRow: 1,
    minHeight: 0,
    height: '445px',
    fontSize: '24px'
  },
  list: {
    display: 'grid',
    justifySelf: 'center',
  },
  listItem: {
    display: 'grid',
    alignContent: 'center',
    gridTemplateColumns: '2fr 1fr',
  },
  key: {
    justifySelf: 'start',
    padding: "10px"
  },
  label: {
    padding: "10px",
    fontWeight: "bold"
  },
  value: {
    justifySelf: 'left',
    padding: "10px"
  },
  header: {
    backgroundColor: '#0087b6',
    display: 'grid',
    gridTemplateColumns: '1fr 7fr',
    padding: '15px'
  },
  icon: {
    padding: '5px',
    backgroundColor: '#310073',
    color: '#fff',
    justifySelf: 'center',
    alignSelf: 'center',
    borderRadius: '5px'
  },
  chartTitle: {
    color: '#fff',
    alignSelf: 'center',
    marginLeft: '10px'
  },
  listItemSupport: {
    display: 'grid',
    alignContent: 'center',
    gridTemplateColumns: '2fr 3fr',
  },
  listHeader: {
    marginBottom: 10,
    display: 'grid',
    alignContent: 'center',
    gridTemplateColumns: '2fr 1fr 1fr',
  },
  listChartSupport: {
    gridRow: 1,
    minHeight: 0,
    height: '245px',
    fontSize: '24px'
  },
}