import React, { Component } from 'react';
import { connect } from 'react-redux';
import GameList from '../../components/Student/GameList';
import { Divider, Typography } from '@material-ui/core/index';
import { InsertChartOutlined } from '@material-ui/icons/index';
import { getStudent, getStudentGames, fetchGames } from '../../../redux/actions';
import { withStyles } from '@material-ui/core/styles/index';
import baseStyle from '../../../styles/games.js';
import { FormattedMessage } from 'react-intl';
import EntertainmentGameList from '../../components/Student/EntertainmentGameList';
import EntertainmentGameWizardBase from '../../components/Wizards/Games/EntertainmentGameWizardBase';

class Games extends Component {

  componentDidMount() {
    this.props.getStudentGames(this.props.id);
    this.props.getStudent(this.props.id);
    this.props.fetchGames();
  }

  render() {
    const { classes, student } = this.props;
    const entertainmentGames = this.props.studentGames.filter(game => game.type === 'Entertainment');
    const games = this.props.studentGames.filter(game => game.type !== 'Entertainment');
    return (
      <section>
        <div className={classes.title}>
          <InsertChartOutlined className={classes.icon} fontSize='inherit'/>
          <Typography className={classes.text} variant='h3'>
            {student.firstName}'s <FormattedMessage id='Sidebar.games'/>
          </Typography>
        </div>
        <Divider />
        <br />
        <GameList games={games} history={this.props.history}/>
        <br/>
        <EntertainmentGameList title={<FormattedMessage id='Sidebar.games.entertainment'/>} id={this.props.student.id} games={entertainmentGames} history={this.props.history}/>
        <EntertainmentGameWizardBase />
      </section>
    )
  }
}

const mapState = ({ platform: { studentGames, student }}) => {
  return {
    studentGames,
    student
  }
};

const mapDispatch = (dispatch) => {
  return {
    getStudentGames: (studentId) => dispatch(getStudentGames(studentId)),
    getStudent: (studentId) => dispatch(getStudent(studentId)),
    fetchGames: () => dispatch(fetchGames())
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(Games));