import React, { Component } from 'react';
import { connect } from 'react-redux';
import DataTable from '../Shared/DataTable';
import { FormattedMessage } from 'react-intl';

class GoalList extends Component {

  render(){
    const rows = [
      { id: 'name', label: <FormattedMessage id='General.name'/>, searchable: true },
      { id: 'description', label: <FormattedMessage id='General.description'/> },
      { id: 'status', label: <FormattedMessage id='General.status'/>}
    ];

    return (
      <DataTable
        type={this.props.type}
        editable={this.props.editable}
        deletable={this.props.deletable}
        clickable={this.props.clickable}
        history={this.props.history}
        skill={this.props.skill}
        rows={rows}
        rowsPerPage={[5,10,15]}
        orderBy={'name'}
        data={this.props.goals}
        studentId={this.props.id}
        numCols={2}
        title={<FormattedMessage id='Goals.allGoals'/>}
      />
    )
  }
}

export default connect(null)(GoalList);