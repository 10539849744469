export default {
  barChartCard: {
    gridColumn: 'span 3',
    gridRow: 2,
  },
  barChart: {
    height: '340px',
    margin: '0 auto',
  },
  header: {
    backgroundColor: '#0087b6',
    display: 'grid',
    gridTemplateColumns: '48px 1fr',
    padding: '15px'
  },
  icon: {
    padding: '5px',
    backgroundColor: '#310073',
    color: '#fff',
    justifySelf: 'center',
    alignSelf: 'center',
    borderRadius: '5px'
  },
  chartTitle: {
    alignSelf: 'center',
    color: '#fff',
    marginLeft: '30px'
  },
  buttons: {
    display: 'grid',
    justifyContent: 'center',
    gridAutoFlow: 'column',
    marginTop: 10
  },
  empty: {
    marginTop: 120
  },
}