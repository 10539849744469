import React, { Component } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  TextField,
  withStyles,
  MenuItem,
  FormGroup,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import baseStyle from '../../../../styles/wizardBase';

class SoundSearchLevelTemplate extends Component {

  state={
    // link to vars: https://docs.google.com/document/d/1L0PR0sKVmpc-lMfNe03UGtAgaW2fc8aopb152PV7zus/edit
    collection: 0,
    lengthOfGame: 120,
    itemsInLeftList: 4,
    itemsInRightList: 4,
    numberOfBoards: 3,
  };

  close = () => {
    this.props.close();
  };

  onSubmit = () => {
    const level = { levelVariables: JSON.stringify({...this.state}) };
    this.props.onSubmit(level)
  };

  handleChange = (ev) => {
    const name = ev.target.name;
    const value = ev.target.type === 'number'
      ? ev.target.value * 1 > 0 ? ev.target.value * 1 : ''
      : ev.target.type === 'select'
        ? ev.target.value * 1
        : ev.target.value;
    this.setState({ [name]: value === 0 ? '' : value })
  };

  formatName = (name) => {
    return name
      .replace(/"/g, '')
      .replace(/([A-Z0-9])/g, ` $1`)
      .replace(/^./, (str) => str.toUpperCase())
  };

  render() {
    const { classes, open, close, back } = this.props;
    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        open={open}
        onClose={close}
        aria-labelledby='level-dialog-title'
        PaperProps={{ classes:{ root: classes.container }}}
      >
        <DialogTitle disableTypography={true} className={ classes.title } id='level-dialog-title'>
          <Typography className={ classes.titleText } variant='h4'>Sound Search Level Template</Typography>
          <IconButton
            onClick={this.close}
            className={ classes.exitIcon }
            aria-label="close dialog"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <Typography className={classes.subtitle} variant='h6'>Add Variables to a {this.props.game.name} Level:</Typography>
        <DialogContent>
          <FormGroup className={classes.root}>
            <div className={classes.configureLine}>
              <strong className={classes.configTitle}> Sound Collection: </strong>
              <TextField
                select
                variant='outlined'
                className={classes.configComponent}
                name='collection'
                type='select'
                value={this.state.collection}
                onChange={this.handleChange}
              >
                <MenuItem value={0}> Animals </MenuItem>
                <MenuItem value={1}> Instruments </MenuItem>
                <MenuItem value={2}> Vehicles </MenuItem>
                <MenuItem value={3}> Indoors </MenuItem>
                <MenuItem value={4}> Toys </MenuItem>
              </TextField>
            </div>
            <div className={classes.configureLine}>
              <strong className={classes.configTitle}> Length of Game: </strong>
              <TextField
                variant='outlined'
                className={classes.configComponent}
                name='lengthOfGame'
                type='number'
                value={this.state.lengthOfGame}
                onChange={this.handleChange}
              />
            </div>
            <div className={classes.configureLine}>
              <strong className={classes.configTitle}> Number of Words: </strong>
              <TextField
                variant='outlined'
                className={classes.configComponent}
                name='itemsInLeftList'
                type='number'
                value={this.state.itemsInLeftList}
                onChange={this.handleChange}
              />
            </div>
            <div className={classes.configureLine}>
              <strong className={classes.configTitle}> Number of Sounds: </strong>
              <TextField
                variant='outlined'
                className={classes.configComponent}
                name='itemsInRightList'
                type='number'
                value={this.state.itemsInRightList}
                onChange={this.handleChange}
              />
            </div>
            <div className={classes.configureLine}>
              <strong className={classes.configTitle}> Number of Games in One Round: </strong>
              <TextField
                variant='outlined'
                className={classes.configComponent}
                name='numberOfBoards'
                type='number'
                value={this.state.numberOfBoards}
                onChange={this.handleChange}
              />
            </div>
          </FormGroup>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button onClick={back} color='secondary'>
            Back
          </Button>
          <Button onClick={this.onSubmit} variant='contained' color='primary'>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(baseStyle)(SoundSearchLevelTemplate);