import {
  ROOT_URL,
  GET_GROUP,
  FETCH_ALL_GROUPS,
  FETCH_GROUPS_FOR_PROFILE,
  FETCH_POSTS,
  CREATE_GROUP,
  UPDATE_GROUP,
  JOIN_GROUP,
  CLEAR_GROUP
} from '../../constants';
import axios from 'axios';

export const fetchAllGroups = () => {
  const url = `${ROOT_URL}/groups`;
  return (dispatch) => {
    axios
      .get(url)
      .then(response => {
        dispatch({
          type: FETCH_ALL_GROUPS,
          groups: response.data
        })
      })
      .catch(err => console.log(err))
  }
};

export const fetchMyGroups = (profileKey) => {
  const url = `${ROOT_URL}/profiles/${profileKey}/groups`;
  return (dispatch) => {
    axios
      .get(url)
      .then(res => {
        dispatch({
          type: FETCH_GROUPS_FOR_PROFILE,
          groups: res.data
        })
      })
      .catch(err => console.log(err))
  }
};

export const getGroup = (groupId) => {
  const url = `${ROOT_URL}/groups/${groupId}`;
  return (dispatch) => {
    axios
      .get(url)
      .then(response => {
        dispatch({
          type: GET_GROUP,
          group: response.data
        });
        return response.data
      })
      .then(group => {
        if (group.key){
          dispatch({
            type: FETCH_POSTS,
            posts: group.posts
          })
        }
      })
      .catch(err => console.log(err))
  }
};

export const createGroup = (group) => {
  const url = `${ROOT_URL}/groups`;
  return (dispatch) =>  {
    return axios
      .post(url, group)
      .then(response => {
        dispatch({
          type: CREATE_GROUP,
          group: response.data
        });
        return response.data
      })
      .catch(err => console.log(err))
  }
};

export const updateGroup =  (group) => {
  const _group = {key: group.key, description: group.description};
  const url = `${ROOT_URL}/groups/${group.key}`;
  return (dispatch) => {
    axios
      .put(url, _group)
      .then( response => {
        dispatch({
          type: UPDATE_GROUP,
          group: {...group, description: response.data.description}
        })
      })
      .catch(err => console.log(err))
  }
};

export const joinGroup = (group, profileKey) => {
  const url = `${ROOT_URL}/profiles/${profileKey}/groups`;
  return (dispatch) => {
    axios
      .post(url, { key: group.key })
      .then(() => {
        dispatch({
           type: JOIN_GROUP,
           group
        })
      })
      .catch(err => console.log(err))
  }
};

export const acceptInvite = (group, profileKey) => {
  const url = `${ROOT_URL}/profiles/${profileKey}/groups/accept`;
  return (dispatch) => {
    axios
      .post(url, { key: group.key })
      .then(() => {
        dispatch({
          type: JOIN_GROUP,
          group
        })
      })
      .catch(err => console.log(err))
  }
};

export const changeMemberPrivileges = (groupKey, profileKey, role) => {
  const url = `${ROOT_URL}/groups/${groupKey}/profiles/${profileKey}?role=${role}`;
  return (dispatch) => {
    axios
      .put(url)
      .then((response) => {
        dispatch(getGroup(groupKey))
      })
      .catch(err => console.log(err))
  }
};

export const removeFromGroup = (group, profileKey) => {
  const url = `${ROOT_URL}/groups/${group.key}/profiles/${profileKey}`;
  axios
    .delete(url)
    .catch(err => console.log(err))
};

export const uploadGroupImage = (group, file) => {
  const url =`${ROOT_URL}/groups/${group.key}/image`;
  return (dispatch) => {
    file.toBlob(blob => {
      const data = new FormData();
      data.append("file", blob, "filename.png");
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };
      axios
        .post(url, data, config)
        .then(() => {
          dispatch(getGroup(group.key))
        })
        .catch(err => console.log(err))
    })
  }
};

export const clearGroup = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_GROUP
    })
  }
};