import React, { Component } from 'react';
import { connect } from 'react-redux';
import LevelList from '../../components/Student/LevelList';
import LevelWizard from '../../components/Wizards/Level/LevelWizardBase';
import TestDatabank from '../../components/Wizards/GameTesting/TestLevel';
import { fetchLevels } from '../../../redux/actions';
import { withStyles } from '@material-ui/core';
import baseStyle from '../../../styles/levels';

class Levels extends Component {

  componentDidMount() {
    this.props.fetchLevels();
  }

  render(){
    return (
      <>
        <LevelList myself={this.props.myself} history={this.props.history} levels={this.props.levels}/>
        <div className={this.props.classes.container}>
          <LevelWizard history={this.props.history} />
          <TestDatabank/>
        </div>
      </>
    )
  }
}

const mapState = ({ platform: { levels }}) => {
  return {
    levels
  }
};

const mapDispatch = (dispatch, {history}) => {
  return {
    fetchLevels: () => dispatch(fetchLevels(history))
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(Levels));