import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import AddPage from '../../components/Help/AddPage';
import AddGuide from '../../components/Help/AddGuide';
import AddQuestion from '../../components/Help/AddQuestion';
import HelpEdit from '../../components/Help/HelpEdit';
import {
  Typography,
  Divider,
  Card,
  Icon,
  IconButton,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  ExpansionPanelActions,
  Dialog,
  withStyles, DialogContent
} from '@material-ui/core';
import { ExpandMore, Delete, Edit, Help } from '@material-ui/icons';
import { fetchPages, createPage, deleteQuestion, deleteGuide, deletePage } from '../../../redux/actions';
import { capitalize } from '../../../helpers';
import baseStyle from '../../../styles/helpConfig';

class HelpConfig extends Component {

  state = {
    expanded: false,
    expandedPage: false,
    open: false,
    edit: false,
    type: '',
    pageId: null,
    data: {}
  };

  componentDidMount(){
    this.props.fetchPages();
  }

  handleChange = (panel) => (ev, isExpanded) => {
    this.setState({ expanded: isExpanded ? panel : false })
  };

  handlePageChange = (panel) => (ev, isExpanded) => {
    this.setState({ expandedPage: isExpanded ? panel : false })
  };

  handleEdit = (type, data, pageId) => {
    this.setState({ data, type, pageId, edit: !this.state.edit })
  };

  handleOpen = (guide) => {
    this.setState({ open: true, url: guide.url })
  };

  close = () => {
    this.setState({ edit: false, open: false });
  };

  handleDelete = (type, item, pageId) => {
    switch (type){
      case 'guide':
        return this.props.deleteGuide(item, pageId);
      case 'question':
        return this.props.deleteQuestion(item, pageId);
      case 'page':
        return this.props.deletePage(pageId);
      default:
        break;
    }
  };

  render(){
    const { classes, helpPages } = this.props;
    return (
      <section>
        <div className={classes.title}>
          <Help className={classes.icon} fontSize='inherit'/>
          <Typography className={classes.text} variant='h3'>
            <FormattedMessage id='Sidebar.help'/>
          </Typography>
        </div>
        <Divider />

        {
          helpPages && helpPages.map(page => (
            <ExpansionPanel
              expanded={this.state.expandedPage === page._id}
              onChange={this.handlePageChange(page._id)}
              key={page._id}
              className={classes.page}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                <Typography variant='h4'>{page.name ? capitalize(page.name) : ''}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.expansion}>
                <div className={classes.questions}>
                  <Typography variant='h6'>Questions</Typography>
                  {
                    page.questions && page.questions.length > 0
                    ? page.questions.map(question => (
                      <Card className={classes.question} key={question._id}>
                        <div>
                          <Typography variant='h6'> Q: {question.question} </Typography>
                          <Typography variant='subtitle1'><Typography variant='h6' component='span'> A: </Typography> {question.answer} </Typography>
                        </div>
                        <div className={classes.editBtns}>
                          <IconButton
                            onClick={() => this.handleDelete('question', question, page._id)}
                            color='secondary'
                          >
                            <Delete/>
                          </IconButton>
                          <IconButton
                            onClick={() => this.handleEdit('question', question, page._id)}
                          >
                            <Edit/>
                          </IconButton>
                        </div>
                      </Card>
                    ))
                    : <div className={classes.page}>
                        <Typography> There are no questions for this page. </Typography>
                      </div>
                  }
                  <AddQuestion pageId={page._id}/>
                </div>
                <div className={classes.guides}>
                  <Typography variant='h6'>Guides</Typography>
                  {
                    page.guides && page.guides.length > 0
                    ? page.guides.map(guide => (
                        guide.type === 'video'
                        ? <ExpansionPanel
                            expanded={this.state.expanded === guide._id}
                            onChange={this.handleChange(guide._id)}
                            className={classes.guide}
                            key={guide._id}
                          >
                            <ExpansionPanelSummary
                              // className={classes.guide}
                              expandIcon={<ExpandMore />}
                              id={`${guide.description}-content`}
                            >
                              <Icon className='fas fa-video icon'/> <Typography> {guide.description} </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className={classes.content} id={`${guide.description}-header`}>
                              <iframe
                                title='guide-video'
                                width="512"
                                height="288"
                                align='center'
                                src={`https://www.youtube.com/embed/${guide.url}?modestbranding=1&rel=0`}
                                frameBorder='0'
                                allow='accelerometer; autoplay; encrypted-media; gyroscope;'
                                allowFullScreen
                              />
                            </ExpansionPanelDetails>
                            <ExpansionPanelActions>
                              <IconButton
                                onClick={() => this.handleDelete('guide', guide, page._id)}
                                color='secondary'
                              >
                                <Delete/>
                              </IconButton>
                              <IconButton
                                onClick={() => this.handleEdit('guide', guide, page._id)}
                              >
                                <Edit/>
                              </IconButton>
                            </ExpansionPanelActions>
                          </ExpansionPanel>
                        : <Card className={classes.pdf} key={guide._id}>
                            <div onClick={() => this.handleOpen(guide)}  className='content'>
                              <Icon className='fas fa-file-alt icon'/> <Typography variant='subtitle1'> {guide.description} </Typography>
                            </div>
                            <div className={classes.editBtns}>
                              <IconButton
                                onClick={() => this.handleDelete('guide', guide, page._id)}
                                color='secondary'
                              >
                                <Delete/>
                              </IconButton>
                              <IconButton
                                onClick={() => this.handleEdit('guide', guide, page._id)}
                              >
                                <Edit/>
                              </IconButton>
                            </div>
                          </Card>
                      ))
                    : <div className={classes.page}>
                        <Typography> There are no guides for this page. </Typography>
                      </div>
                  }
                  <AddGuide pageId={page._id}/>
                </div>
              </ExpansionPanelDetails>
              <Divider />
              <ExpansionPanelActions>
                <IconButton
                  onClick={() => this.handleDelete('page', null, page._id)}
                  color='secondary'
                >
                  <Delete/>
                </IconButton>
                <IconButton
                  onClick={() => this.handleEdit('page', page)}
                >
                  <Edit/>
                </IconButton>
              </ExpansionPanelActions>
            </ExpansionPanel>
          ))
        }
        <AddPage />
        <Dialog
          open={this.state.open}
          onClose={this.close}
          maxWidth={false}
        >
          <DialogContent>
            <iframe
              title='guide-attachment'
              width="640"
              height="720"
              src={this.state.url}
              frameBorder='0'
              allow='accelerometer; autoplay; encrypted-media; gyroscope;'
              allowFullScreen
            />
          </DialogContent>
        </Dialog>
        <Dialog
          onClose={this.close}
          open={this.state.edit}
        >
          <HelpEdit
            type={this.state.type}
            data={this.state.data}
            pageId={this.state.pageId}
            close={this.close}
          />
        </Dialog>
      </section>
    )
  }
}

const mapState = ({ shared: { helpPages }}) => {
  return {
    helpPages
  }
};

const mapDispatch = (dispatch) => {
  return {
    fetchPages: () => dispatch(fetchPages()),
    createPage: (page) => dispatch(createPage(page)),
    deleteQuestion: (question, pageId) => dispatch(deleteQuestion(question, pageId)),
    deleteGuide: (guide, pageId) => dispatch(deleteGuide(guide, pageId)),
    deletePage: (pageId) => dispatch(deletePage(pageId))
  }
};

export default connect(mapState,mapDispatch)(withStyles(baseStyle)(HelpConfig));