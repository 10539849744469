import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, FormGroup, Typography, TextField, IconButton, MenuItem } from '@material-ui/core/index';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core/index';
import { Close } from '@material-ui/icons/index';
import { withStyles } from '@material-ui/core/styles/index';
import baseStyle from '../../../../styles/wizardBase';

class AccountWizardPage1 extends Component {

  render() {
    const { classes, account: { name, shortName, billingCount, type, brailleAIBillingCount }} = this.props;
    const incomplete = !name || !shortName || !billingCount || !brailleAIBillingCount || !type;
    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        open={this.props.open}
        onClose={this.close}
        aria-labelledby='form-dialog-title'
        PaperProps={{ classes: { root: classes.container } }}
      >
        <DialogTitle disableTypography={true} className={classes.title} id='form-dialog-title'>
          <Typography className={classes.titleText} variant='h4'><FormattedMessage id='Wizard.Support.title'/></Typography>
          <IconButton
            onClick={this.props.close}
            className={classes.exitIcon}
            aria-label='close dialog'
          >
            <Close/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage id='Wizard.Support'/> (<FormattedMessage id='General.required'/>)
          </DialogContentText>
          <FormGroup>
            <TextField
              classes={{ root: classes.firstName }}
              id='account-name'
              label={<FormattedMessage id='General.name'/>}
              type='text'
              name='name'
              variant='outlined'
              value={name}
              onChange={this.props.handleChange}
            />
            <TextField
              classes={{ root: classes.firstName }}
              id='account-short-name'
              label={<FormattedMessage id='Wizard.Support.shortName'/>}
              type='text'
              name='shortName'
              variant='outlined'
              value={shortName}
              onChange={this.props.handleChange}
            />
            <TextField
              classes={{ root: classes.firstName }}
              id='student-count'
              label={<FormattedMessage id='General.billableStudent'/>}
              type='text'
              name='billingCount'
              variant='outlined'
              value={billingCount}
              onChange={this.props.handleChange}
            />
            <TextField
              classes={{ root: classes.firstName }}
              id='braille-student-count'
              label='Braille AI Billable Student Count'
              type='text'
              name='brailleAIBillingCount'
              variant='outlined'
              value={brailleAIBillingCount}
              onChange={this.props.handleChange}
            />
            <TextField
              select
              classes={{ root: classes.firstName }}
              id='account-type'
              label={<FormattedMessage id='General.type'/>}
              type='text'
              name='type'
              variant='outlined'
              value={type}
              onChange={this.props.handleChange}
            >
              <MenuItem value='INTERNAL'><FormattedMessage id='General.internal'/></MenuItem>
              <MenuItem value='CUSTOMER'><FormattedMessage id='General.customer'/></MenuItem>
              <MenuItem value='PILOT'><FormattedMessage id='General.pilot'/></MenuItem>
              <MenuItem value='SALES'><FormattedMessage id='General.sales'/></MenuItem>
            </TextField>
          </FormGroup>
        </DialogContent>
        <DialogActions className={classes.buttons}>
          <Button onClick={this.props.close} color='secondary'>
            <FormattedMessage id='General.cancel'/>
          </Button>
          {
            type === 'INTERNAL'
          ? <Button disabled={incomplete} onClick={() => this.props.submit('internal')} color='primary'>
              <FormattedMessage id='General.submit'/>
            </Button>
          : <Button disabled={incomplete} onClick={this.props.next} color='primary'>
              <FormattedMessage id='General.continue'/>
            </Button>
           }
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(baseStyle)(AccountWizardPage1);