import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Logo from '../../../assets/BrandAssets/Logo/OBJECTIVEED-Logo-Vertical-2.png';
import SidebarTitle from '../../components/Shared/SidebarTitle';
import { Drawer, Typography, List, ListItem, ListItemIcon, ListItemText, Button, Divider } from '@material-ui/core';
import {
  AccountBox,
  Face,
  InsertChartOutlined,
  CheckCircleOutlined,
  Settings,
  Extension,
  SupervisorAccount,
  HelpOutline
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles';
import { getAccount, getUser, getStaffUser, getStaffById, getStudent, getGuardian, reset, getStudentsForStaff } from '../../../redux/actions';
import styles from '../../../styles/sidebar';
import { FormattedMessage } from 'react-intl';

class Sidebar extends Component {

  state = {
    products: []
  }

  componentDidMount = () => {
    const { getStaffById, getStudent, getGuardian, location, getStudentsForStaff } = this.props;
    const type = location.pathname.split('/')[1];
    const id = location.pathname.split('/')[2];
    if (type === 'staff') {
      getStaffById(id);
      getStudentsForStaff(id);
    }
    if (type === 'students') {
      getStudent(id)
    }
    if (type === 'guardians') {
      getGuardian(id)
    }
    if (type === 'admin'){
      // this.props.getUser();
      return this.props.getAccount();
    }
    // if (type !== 'admin'){
    //   return this.props.getStaffUser();
    // }
    // else {
    //   this.props.getUser();
    // }
 };

  componentWillReceiveProps(nextProps, nextContext) {
    const location = nextProps.location;
    const type = location.pathname.split('/')[1];
    let products = [];
    if(type == "students" && nextProps.student != null) {
      products = nextProps.student.products;
    } else {
      products = nextProps.user.products;
    }
    if (products && products.length > 0)
      this.setState({ products });
    if (nextProps.user.id !== this.props.user.id) {
      if (nextProps.user.type === 'Admin' || nextProps.user.type === 'Master') {
        this.props.getAccount(this.props.history);
        // this.props.getUser();
      }
      else if (nextProps.user.id) {
        // this.props.getStaffUser();
      }
    }
  }

  handleClick = () => {
    const type = this.props.location.pathname.split('/')[1];
    this.props.history.push(`/admin/${type}`, this.props.location.pathname);
    this.props.reset();
  };

  handleStaffClick = () => {
    const { history, staffMember, user } = this.props;
    if (user && user.products && user.products.includes("Reading")) {
      if (history.location.state && history.location.state.from == "admindashboard")
        history.push(`/admin/dashboard`, this.props.location.pathname);
      else
        history.push(`/staff/${staffMember.id}/dashboard`, this.props.location.pathname);
    }
    else {
      history.push(`/staff/${staffMember.id}/students`, this.props.location.pathname);
    }
  };

  render() {
    const { classes, location, user, student } = this.props;
    console.log(user);
    const products = this.state.products;
    const pathArr = location.pathname.split('/');
    const type = pathArr[1];
    const id = pathArr[2];
    const goal = pathArr.includes('goals') && pathArr.indexOf('goals') !== pathArr.length-1 && pathArr.includes('students');
    const list = {
      dev: [
        // {icon: <InsertChartOutlined/>, text: <FormattedMessage id='Sidebar.dashboard'/>, url: '/admin/home'},
        {icon: <Face/>, text: <FormattedMessage id='Sidebar.students'/>, url: '/admin/students', products: ['TVI','Reading','OM']},
        {icon: <AccountBox/>, text: <FormattedMessage id='Sidebar.staff'/>, url: '/admin/staff', products: ['TVI','Reading','OM']},
        // {icon: <School/>, text: <FormattedMessage id='Sidebar.schools'/>, url: '/admin/schools'},
        {icon: <Settings/>, text: <FormattedMessage id='Sidebar.config'/>, url: '/admin/config', products: ['TVI','Reading','OM']},
        {icon: <HelpOutline />, text: <FormattedMessage id='Sidebar.help'/>, url: '/admin/help-config', products: ['TVI','Reading','OM']}
      ],
      admin: [
        // {icon: <InsertChartOutlined/>, text: <FormattedMessage id='Sidebar.dashboard'/>, url: '/admin/home'},
        {icon: <InsertChartOutlined/>, text: <FormattedMessage id='Sidebar.admindashboard'/>, url: '/admin/dashboard', products: ['Reading']},
        {icon: <Face/>, text: <FormattedMessage id='Sidebar.students'/>, url: '/admin/students', products: ['TVI','Reading','OM']},
        {icon: <AccountBox/>, text: <FormattedMessage id='Sidebar.staff'/>, url: '/admin/staff', products: ['TVI','Reading','OM']},
        // {icon: <School/>, text: <FormattedMessage id='Sidebar.schools'/>, url: '/admin/schools'},
      ],
      students: [
        {icon: <InsertChartOutlined/>, text: <FormattedMessage id='Sidebar.bvidashboard'/>, url:  `/students/${id}`, products: ['TVI','OM'] },
        {icon: <InsertChartOutlined/>, text: <FormattedMessage id='Sidebar.readingdashboard'/>, url:  `/students/${id}/dashboard`, products: ['Reading'] },
        {icon: <CheckCircleOutlined/>, text: <FormattedMessage id='Sidebar.goals'/>, url: `/students/${id}/goals`, products: ['TVI','OM']},
        {icon: <CheckCircleOutlined/>, text: <FormattedMessage id='Sidebar.books'/>, url: `/students/${id}/books`, products: ['Reading']},
        // {icon: <VideogameAsset/>, text: <FormattedMessage id='Sidebar.games'/>, url: `/students/${id}/games`},
        // {icon: <People/>, text: <FormattedMessage id='Sidebar.guardians'/>, url: `/students/${id}/guardians`},
        {icon: <Settings/>, text: <FormattedMessage id='Sidebar.profile'/>, url: `/students/${id}/profile`, products: ['TVI','Reading','OM']},
      ],
      staff: [
        {icon: <InsertChartOutlined/>, text: <FormattedMessage id='Sidebar.bvidashboard'/>, url: `/staff/${id}/bvidashboard`, products: ['TVI','OM']},
        { icon: <InsertChartOutlined />, text: <FormattedMessage id='Sidebar.dashboard' />, url: `/staff/${id}/dashboard`,products: ['Reading']},
        {icon: <Face/>, text: <FormattedMessage id='Sidebar.students'/>, url: `/staff/${id}/students`, products: ['TVI','OM']},
        {icon: <Extension/>, text: <FormattedMessage id='Sidebar.databanks'/>, url: `/staff/${id}/databanks`, products: ['TVI','OM']},
        {icon: <Settings/>, text: <FormattedMessage id='Sidebar.profile'/>, url: `/staff/${id}/profile`, products: ['TVI','Reading','OM']},
      ],
      support: [
        {icon: <SupervisorAccount />, text: <FormattedMessage id='Sidebar.users'/>, url: `/support`, products: ['TVI','Reading','OM']},
        {icon: <Settings/>, text: <FormattedMessage id='Sidebar.accounts' />, url: '/support/accounts', products: ['TVI','Reading','OM']},
        {icon: <InsertChartOutlined/>, text: 'Metrics', url:  `/support/metrics`, products: ['TVI','Reading','OM']},
      ],
      guardians: [
        {icon: <InsertChartOutlined/>, text: <FormattedMessage id='Sidebar.dashboard'/>, url: `/guardians/${id}`, products: ['TVI','Reading','OM']},
        {icon: <Face/>, text: <FormattedMessage id='Sidebar.students'/>, url: `/guardians/${id}/students`, products: ['TVI','Reading','OM']},
      ]
    };

    let options = this.props.account.id === '0' && type === 'admin' ? list.dev : list[type];
    if (user && products) { options = options.filter(option => option.products.some(item => products.includes(item))) }
    // if (user.type === "Teacher" && type == "staff") {
    //   options.splice(0, 1);
    //   options.splice(1, 0, { icon: <InsertChartOutlined />, text: <FormattedMessage id='Sidebar.dashboard' />, url: `/staff/${id}/dashboard` },)

    // }
    return (
      <nav>
        <Drawer classes={{ paper: classNames(classes.drawer, { [classes.drawerGoal]: goal }) }} align='center' variant='permanent'>
          <img className={classNames(classes.logo, { [classes.hidden]: goal })} alt='logo' src={Logo} />
          <Divider classes={{ root: classNames(classes.divider, { [classes.hidden]: goal }) }} />
          <SidebarTitle location={location} />
          <Divider classes={{ root: classNames(classes.divider, { [classes.hidden]: goal }) }} />
          {/* Regular Sidebar shrinks on goal page */}
          <List className={classes.list}>
            {
              options.map(item => {
                const active = location.pathname === item.url || (location.pathname === '/admin/home' && item.url === '/admin/students');
                let dashboardActive = false;
                if (location.pathname == `/staff/${id}` && item.url.includes('/dashboard')) {
                  dashboardActive = true;
                }
                return (
                  <Button
                    disableFocusRipple
                    component={Link}
                    className={ active || dashboardActive ? classes.active : classes.link }
                    key={item.url}
                    to={item.url}
                    classes={{ root: classes.linkButton }}
                  >
                    <ListItem>
                      <ListItemIcon classes={{root: active ? classes.activeListItem : classes.listItem}}>
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText>
                        <Typography className={active ? classes.activeListItem : classes.listItem}>
                          {item.text}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </Button>
                )
              })
            }
          </List>
          {
            this.props.user.homePage === '/admin/home'
              ? !goal && type === 'staff' &&
              <Button
                onClick={this.handleClick}
                variant='outlined'
                className={classes.button}
              >
                <FormattedMessage id='Sidebar.backToStaff' />
              </Button>
              : null
          }
          {
            this.props.user.homePage === '/admin/home'
              ? !goal && type === 'students' && !this.props.staffMember.id &&
              <Button
                onClick={this.handleClick}
                variant='outlined'
                className={classes.button}
              >
                <FormattedMessage id='Sidebar.backToStudents' />
              </Button>
              : null
          }
          {
            !goal && type === 'students' && this.props.staffMember.id &&
            <Button
              onClick={this.handleStaffClick}
              variant='outlined'
              className={classes.button}
            >
              {this.props.history && this.props.history.location.state &&
                this.props.history.location.state.from == "admindashboard" ?
                <>
                  <FormattedMessage id='Sidebar.backTo' /> &nbsp;Admin Dashboard</>
                :
                <>
                  <FormattedMessage id='Sidebar.backTo' /> &nbsp;{this.props.staffMember.firstName}&nbsp;
                  {user && user.products.includes('Reading') ? "Dashboard" : <FormattedMessage id="Sidebar.student's" />}
                </>
              }
            </Button>
          }
        </Drawer>
      </nav>
    )
  }
}

const mapState = ({ shared: { user, account }, platform: { staffMember, staffStudents, student } }) => {
  return {
    user,
    account,
    staffMember,
    staffStudents,
    student
  }
};

const mapDispatch = (dispatch, { history }) => {
  return {
    getUser: () => dispatch(getUser(history)),
    getStaffUser: () => dispatch(getStaffUser(history)),
    getAccount: () => dispatch(getAccount(history)),
    getStaffById: (id) => dispatch(getStaffById(id)),
    getStudent: (id) => dispatch(getStudent(id)),
    getGuardian: (id) => dispatch(getGuardian(id)),
    getStudentsForStaff: (staffId) => dispatch(getStudentsForStaff(staffId)),
    reset: () => dispatch(reset())
  }
};

export default connect(mapState, mapDispatch)(withStyles(styles)(Sidebar));