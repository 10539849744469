import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { InputAdornment, Divider } from '@material-ui/core';
import { Paper, Typography, TextField, Toolbar, IconButton, withStyles } from '@material-ui/core';
import { Search, ArrowDropDown, ArrowDropUp, CloudDownload } from '@material-ui/icons';
import { downloadSheet } from '../../../redux/actions';
import styles from '../../../styles/goalBrailleSheets.js';
import { capitalize } from '../../../helpers';
import BrailleSheetWizardGrid from '../Wizards/BrailleSheets/BrailleSheetWizardGrid';
import { getBrailleSheetById } from '../../../redux/actions';

const rows = [
  { id: 'name', label: <FormattedMessage id='General.name'/>, searchable: true },
  { id: 'description', label: <FormattedMessage id='General.description'/> },
];

class GoalBrailleSheetsList extends Component {

  state = {
    order: 'asc',
    orderBy: 'name',
    rowsPerPage: 5,
    page: 0,
    filteredData: [],
    searchValue: '',
    checked: {},
    sheets: []
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.data && nextProps.data !== this.props.data) {
      this.handleSearch(this.state.searchValue, nextProps.data)
    }
    for (var count = 0; count< this.props.skills.length; count++)
    {
      var skill = this.props.skills[count];
      if (skill.brailleSheetId) {
        getBrailleSheetById(skill.brailleSheetId)
            .then(sheet => this.addSheetToState(sheet));
      }
    }
  }


  addSheetToState = (sheet) => {
    var sheetsTemp = this.state.sheets;
    if(!sheetsTemp.some(x => x.id === sheet.id)) {
      sheetsTemp.push(sheet);
    }
    this.setState({sheets: sheetsTemp});
  }

  handleSearch = (searchValue) => {
    const searchableData = this.state.sheets.filter(sheet => this.props.skills.some(skill => skill.brailleSheetId === sheet.id));
    const filtered = searchableData.filter(item => {
      const searchableKeys = rows.map(row => row.id);
      return searchableKeys.some(key => {
        if (item[key]) {
          return item[key].toLowerCase().includes(searchValue.toLowerCase())
        }
        else {
          return false;
        }
      })
    });
    this.setState({ filteredData: filtered, page: 0 })
  };

  handleSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  toggleShow = () => {
    this.setState(state => ({ show: !state.show }))
  };

  render() {
    const { classes, skills, brailleSheets } = this.props;
    const { searchValue, filteredData } = this.state;
    const data = filteredData.length || searchValue ? filteredData : this.state.sheets;
    return (
      <Paper className={classes.goalSheetsTable}>
        <Toolbar classes={{ root: classes.toolbar }}>
          <Typography noWrap className={classes.header}>
            <strong> Braille Sheets Used In Skills </strong>
          </Typography>
          <div className={classes.spacer}/>
          <div className={classes.actions}>
            <TextField
              variant='outlined'
              id='table-search'
              aria-label='search input'
              type='search'
              InputProps={{
                inputProps: {
                  'aria-label': 'search input'
                },
                endAdornment: (
                  <InputAdornment position='end'>
                    <Search/>
                  </InputAdornment>
                ),
                className: classes.searchBar,
              }}
              onChange={(ev) => {
                this.handleSearch(ev.target.value);
                this.setState({ searchValue: ev.target.value });
              }}
              value={this.state.searchValue}
            />
          </div>
        </Toolbar>
        <div className={classes.sheet}>
          <Typography className='name' variant='subtitle2'><FormattedMessage id='General.name'/></Typography>
          <Typography className='description' variant='subtitle2'> <FormattedMessage id='General.description'/></Typography>
          <Typography className='code' variant='subtitle2'>Code</Typography>
          <Typography className='download' variant='subtitle2'> <FormattedMessage id='General.download'/></Typography>
        </div>
        <Divider />
          {
            data.map((sheet, index) => (
              <div key={sheet.id+index} className={classes.sheet}>
                <Typography className='name' variant='subtitle1'>{sheet.name}</Typography>
                <Typography className='description' variant='subtitle1'>{capitalize(sheet.description)}</Typography>
                <Typography className='code' variant='subtitle1'>{sheet.code}</Typography>
                <IconButton className='download' onClick={() => downloadSheet(sheet.id, sheet.name)}><CloudDownload /></IconButton>
                <IconButton className='show' onClick={this.toggleShow}>
                  {
                    this.state.show
                    ? <ArrowDropUp/>
                    : <ArrowDropDown/>
                  }
                </IconButton>
                {
                  this.state.show &&
                  <div className={classes.grid}>
                    <BrailleSheetWizardGrid grid={JSON.parse(sheet.grid)}/>
                  </div>
                }
              </div>
            ))
          }
      </Paper>
    )
  }
}

export default connect(null)(withStyles(styles)(GoalBrailleSheetsList));