import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from "../../../styles/dashboard";
import { Component } from "react";
import { fetchSliderList } from '../../../redux/actions';
import DataTable from './../../components/Shared/DataTable';
import SliderListManagementWizardBase from '../../components/Wizards/SliderListManagement/SliderListManagementWizardBase';
import SeriesStoryList from "./SeriesStoryList";

class SliderListManagement extends Component {

  state={
   storyListData:[],
   storyListModal:false,
   selectedId:"",
   listName:"",

  }
  componentDidUpdate(prevProps){
    if(prevProps.sliderList !== this.props.sliderList){
      this.props.sliderList.map(val=>{
        if(val.id === this.state.selectedId){
          this.setState({
            storyListData:val.items
          })
        }
      })
    }
  }

  componentDidMount() {
    this.props.fetchSliderList();
  }

  openWizard = () => {
    this.setState({ open: true })
  };

  clickFunc=(data)=>{
    this.setState({
      storyListData:data.items,
      storyListModal:true,
      selectedId:data.id,
      listName:data.name,
    })
  }

  closeModal=()=>{
    this.setState({storyListModal:false})
  }

  render() {
    
    const rows = [
      { id: 'name', label: <FormattedMessage id='General.name' />, searchable: true },
      { id: 'description', label: <FormattedMessage id='General.description' />, searchable: true }
    ];

    const title = (
      <>          
        <FormattedMessage id='DataTable.SliderListManagement' />  
      </>
    );

    return (
      <>
        <DataTable
          type={'slider'}
          editable
          deletable
          clickable
          clickFunc={this.clickFunc}
          rows={rows}
          rowsPerPage={[5, 10, 15]}
          orderBy={''}
          data={this.props.sliderList}
          title={title}
        />
        <SliderListManagementWizardBase history={this.props.history} />
        <SeriesStoryList
        type="storyListManagement"
        open={this.state.storyListModal}
        closeModal={this.closeModal}
        storyListData={this.state.storyListData}
        seriesId={this.state.selectedId}
        seriesName={this.state.listName}
        />
      </>
    )
  }
}

const mapState = ({ platform: { sliderList } }) => {
  return {
    sliderList
  }
};

const mapDispatch = (dispatch, { history }) => {
  return {
    fetchSliderList: () => dispatch(fetchSliderList(history))
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(SliderListManagement));