import React, { Component } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { Button, Card, CardContent, Divider, Typography } from '@material-ui/core';
import { Timeline } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../styles/lineChart';
import { Skeleton } from '@material-ui/lab';
import { saveAs } from 'file-saver';

const tooltip = (slice) => {
  const options = { weekday: 'long', month: 'numeric', day: 'numeric' };
  return (
    <div>
      <div><strong>{slice.id.toLocaleDateString('en-US', options)}</strong></div>
      <div>Minutes Played: <strong>{slice.data[0].data.y}</strong></div>
    </div>
  )
};

const drawInlineSVG = (svgElement, ctx, callback) => {
  let svgURL = new XMLSerializer().serializeToString(svgElement);
  let img  = new Image();
  img.onload = function(){
    ctx.drawImage(this, 0,0);
    callback();
  };
  img.src = 'data:image/svg+xml; charset=utf8, '+encodeURIComponent(svgURL);
};

class SupportMinutesChart extends Component {

  handleClick = () => {
    let myCanvas = document.getElementById("supportMinutesCanvas");
    let ctxt = myCanvas.getContext("2d");
    let chart = document.querySelector('#SupportMinutesChart svg[role="img"]');
    drawInlineSVG(chart, ctxt, function(){myCanvas.toBlob(blob => saveAs(blob, 'chart.png'))})
  };

  render(){
    const { classes, title, data } = this.props;
    const chart = data || [];
    return (
      <Card aria-label='line-chart' className={classes.lineChartCard}>
        <div className={classes.header}>
          <Timeline className={classes.icon}/>
          <Typography className={classes.chartTitle} variant='h4'>{title}</Typography>
        </div>
        <Divider />
        <CardContent id='SupportMinutesChart' className={classes.lineChart}>
          <canvas style={{ display: 'none' }} height={420} width={1500} id='supportMinutesCanvas'/>
          <Button align='right' variant='contained' onClick={this.handleClick}>Download Chart</Button>
          {
            chart.length <= 0
              ? <Skeleton variant="rect" width={1000} height={340} />
              : <ResponsiveLine
                data={chart}
                margin={{
                  top: 30,
                  right: 80,
                  bottom: 60,
                  left: 60
                }}
                xScale={{
                  type: 'time',
                  format: '%Y-%m-%d',
                }}
                yScale={{
                  type: 'linear',
                  stacked: false,
                  min: 0,
                  max: 'auto'
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickValues: 5,
                  orient: 'bottom',
                  tickSize: 10,
                  tickPadding: 5,
                  tickRotation: 0,
                  format: '%-m/%-d/%-y'
                }}
                axisLeft={{
                  tickValues: 5,
                  orient: 'left',
                  tickSize: 0,
                  tickPadding: 15,
                  tickRotation: 0,
                }}
                colors={[
                  '#0087b6',
                  '#33658A',
                  '#86BBD8',
                  '#91C4F2',
                  '#B8E1FF'
                ]}
                dotSize={10}
                dotColor='inherit:darker(0.3)'
                dotBorderWidth={1}
                dotBorderColor='inherit:darker(1)'
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                tooltip={tooltip}
                theme={{
                  axis: {
                    ticks: {
                      text: { fontSize: 16, fontWeight: 800 }
                    }
                  }
                }}
              />
          }
        </CardContent>
      </Card>
    )
  }
}

export default withStyles(baseStyle)(SupportMinutesChart);