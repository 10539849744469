import React, { Component } from 'react';
import DataTable from '../Shared/DataTable';
import { FormattedMessage } from 'react-intl';

class BrailleSheetsList extends Component {
  render() {
    const rows = [
      { id:'name', label: <FormattedMessage id='General.name'/>, searchable: true },
      { id: 'description',  label: <FormattedMessage id='General.description'/>},
      { id: 'code', label: 'Code'},
      { id: 'sheetSize', label: 'Device Size'}
    ];

    const title = (
      <>
        {
          this.props.myself
            ? <FormattedMessage id='General.my' />
            : `${this.props.staff.firstName}'s`}&nbsp;
        <FormattedMessage id='Databanks.brailleSheets'/>
      </>
    );

    return (
      <div>
        <DataTable
          editable
          myself={this.props.myself}
          deletable
          type={this.props.type}
          rows={rows}
          rowsPerPage={[5,10,15]}
          orderBy='name'
          data={this.props.brailleSheets}
          account={this.props.account}
          staffId={this.props.id}
          title={title}
        />
      </div>
    );
  }
}

export default BrailleSheetsList;