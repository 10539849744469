import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import WizardToggleButton from '../WizardToggleButton';
import { createAccount } from '../../../../redux/actions';
import AccountWizardPage1 from './AccountWizardPage1';
import AccountWizardPage2 from './AccountWizardPage2';
import AccountConfirmation from './AccountConfirmation';

class AccountWizardBase extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      name: '',
      brailleAIStudentCount: '',
      brailleAIBillingCount: '0',
      studentCount: '',
      billingCount: '0',
      shortName: '',
      type: '',
      id: '',
      primaryEmail: '',
      secondaryEmail: '',
      street1: '',
      street2: '',
      state: '',
      postalCode: '',
      country: '',
      city: '',
      rate: 250,
      cycle: '',
      open: false,
      page: 1,
      terms: ''
    }
  }

  openWizard = () => {
    this.setState({ open: true })
  };

  closeWizard = () => {
    this.setState({
      open: false,
      page: 1,
      name: '',
      brailleAIStudentCount: '',
      shortName: '',
      studentCount: '',
      type: '',
      id: '',
      primaryEmail: '',
      secondaryEmail: '',
      street1: '',
      street2: '',
      state: '',
      postalCode: '',
      country: '',
      city: '',
      rate: 250,
      cycle: '',
      terms: undefined
    });
  };

  next = () => {
    this.setState({ page: this.state.page+1 });
  };

  back = () => {
    this.setState({ page: this.state.page-1 });
  };

  handleChange = ({ target: { name, value }}) => {
    this.setState({ [name]: value })
  };

  handleFileChange = (ev) => {
    this.setState({ terms: ev.target.files[0] });
    return ev.target.files[0]
  };

  submit = (type) => {
    const account = {
      name: this.state.name,
      shortName: this.state.shortName,
      studentCount: this.state.billingCount*1,
      brailleAICount: this.state.brailleAIBillingCount*1,
      parentAccountId: this.props.user.accountId,
      type: this.state.type
    };
    const billingData = {
      primaryEmail: this.state.primaryEmail,
      secondaryEmail: this.state.secondaryEmail,
      street1: this.state.street1,
      street2: this.state.street2,
      state: this.state.state,
      postalCode: this.state.postalCode,
      country: this.state.country,
      city: this.state.city,
      rate: this.state.rate,
      cycle: this.state.cycle,
    };
    switch(type){
      case 'internal':
        this.props.createAccount(account);
        return this.setState(state => ({ page: 3, terms: '' }));
      case 'billable':
        this.props.createAccount({ ...account, billingDataDto: billingData }, this.state.terms);
        return this.setState(state => ({ page: state.page+1, terms: '' }));
      default:
        return this.closeWizard();
    }
  };

  render(){
    const { page, open } = this.state;
    return (
      <div>
        {
          page === 1 &&
          <AccountWizardPage1
            open={open}
            close={this.closeWizard}
            submit={this.submit}
            next={this.next}
            user={this.props.user}
            account={this.state}
            handleChange={this.handleChange}
          />
        }
        {
          page === 2 &&
            <AccountWizardPage2
              open={open}
              close={this.closeWizard}
              submit={this.submit}
              next={this.next}
              user={this.props.user}
              billing={this.state}
              terms={this.state.terms}
              back={this.back}
              handleChange={this.handleChange}
              handleFileChange={this.handleFileChange}
            />
        }
        {
          page === 3 &&
            <AccountConfirmation
              open={open}
              close={this.closeWizard}
            />
        }
        <WizardToggleButton
          openWizard={this.openWizard}
          type='account'
          text={<FormattedMessage id='Support.createAccount'/>}
        />
      </div>
    )
  }
}

const mapDispatch = (dispatch) => {
  return {
    createAccount: (account, terms) => dispatch(createAccount(account, terms)),
  }
};

const mapState = ({ shared: { user, account }}) => {
  return {
    user,
    account
  }
};

export default connect(mapState, mapDispatch)(AccountWizardBase);