export default {
  title: {
    backgroundColor: '#383871',
    marginBottom: '15px',
  },
  titleText: {
    float: 'left',
    color: '#fff'
  },
  exitIcon: {
    float: 'right',
    color: '#fff',
  },
  content: {
    height: '90vh'
  },
  id: {
    margin: '10px 0',
    width: '100%'
  },
  variables: {
    margin: '10px 0',
    width: '100%'
  },
  wizardButton: {
    marginTop: '20px',
    color: '#fff',
    backgroundColor:'#00729b',
    '&:hover': {
      backgroundColor:'#005C87'
    },
    '&:focus': {
      backgroundColor:'#005C87'
    },
  }
}