const desc = (a, b, orderBy, extraData) => {
  if (orderBy === "progress") {
    if (b[orderBy] && a[orderBy])
      return b[orderBy] < a[orderBy] ? -1 : 1;
    else if (b[orderBy] == null && a[orderBy])
      return -1;
    else if (a[orderBy] == null && b[orderBy])
      return 1;
    else
      return 0;
  }
  if (orderBy == "readingTime") {
    const accuracyData = extraData['staffDashboardReadingTime'];
    let a1 = accuracyData.find(obj => obj.studentId == a.studentId).readingTime;
    let b1 = accuracyData.find(obj => obj.studentId == b.studentId).readingTime;
    let avg1 = a1.map((a) => a.secondsRead).reduce((a, b) => a + b, 0);
    let avg2 = b1.map((a) => a.secondsRead).reduce((a, b) => a + b, 0);
    
    return avg2 < avg1 ? -1 : 1;
  }
  if (orderBy == "sectionIndex") {
    if (parseInt(a[orderBy]) < parseInt(b[orderBy]))
      return 1;
    else
      return -1;
  }
  if (orderBy == "accuracy") {
    const accuracyData = extraData['staffDashboardAccuracy'];
    let a1 = accuracyData.find(obj => obj.studentId == a.studentId).accuracy;
    let b1 = accuracyData.find(obj => obj.studentId == b.studentId).accuracy;
    return a1 < b1 ? 1 : -1;
  }
  if (b[orderBy]) {
    if (b[orderBy] === null) return 1;
    if (a[orderBy] === null) return -1;
    if (typeof (b[orderBy]) === 'string' || typeof (a[orderBy]) === 'string'){
      if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
        return -1;
      }
      if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
        return 1;
      }
    }
    else {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
    }
  }
  return 0;
};

export const stableSort = (array, cmp) => {
  const stabilizedThis = Array.isArray(array) ? array.map((el, index) => [el, index]) : [];
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    else if (a[0] === null) {
      return 1;
    }
    else if (b[0] === null) {
      return -1;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

export const getSorting = (order, orderBy, extraData) => {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy, extraData) : (a, b) => -desc(a, b, orderBy, extraData);
};

export const capitalize = (str) => {
  return str.slice(0, 1).toUpperCase().concat(str.slice(1))
};

// Will make sure that random color is consistent
const _stringAsciiPRNG = (value, m) => {
  const charCodes = [...value].map(letter => letter.charCodeAt(0));
  const len = charCodes.length;

  const a = (len % (m - 1)) + 1;
  const c = charCodes.reduce((current, next) => current + next) % m;

  let random = charCodes[0] % m;
  for (let i = 0; i < len; i++)
    random = ((a * random) + c) % m;

  return random;
};

export const getRandomColor = (value, colors = ['#1C77C3',
  '#F39237',
  '#29b6f6',
  '#26c6da',
  '#00c4b5',
  '#EF476F',
  '#ffa726',
  '#06D6A0']) => {
  if (!value)
    return 'transparent';

  const colorIndex = _stringAsciiPRNG(value, colors.length);
  return colors[colorIndex];
};

export const states = [
  "Alaska",
  "Alabama",
  'Alberta',
  "Arkansas",
  "American Samoa",
  "Arizona",
  'British Columbia',
  "California",
  "Colorado",
  "Connecticut",
  "District of Columbia",
  "Delaware",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Iowa",
  "Idaho",
  "Illinois",
  "Indiana",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Massachusetts",
  "Maryland",
  "Maine",
  'Manitoba',
  "Michigan",
  "Minnesota",
  "Missouri",
  "Mississippi",
  "Montana",
  "North Carolina",
  "North Dakota",
  "Nebraska",
  'New Brunswick',
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  'Newfoundland and Labrador',
  "Nevada",
  "New York",
  'Northwest Territories',
  'Nova Scotia',
  'Nunavut',
  "Ohio",
  "Oklahoma",
  'Ontario',
  "Oregon",
  "Pennsylvania",
  "Puerto Rico",
  'Prince Edward Island',
  'Quebec',
  "Rhode Island",
  'Saskatchewan',
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Virginia",
  "Virgin Islands",
  "Vermont",
  "Washington",
  "Wisconsin",
  "West Virginia",
  "Wyoming",
  'Yukon Territory'
];
