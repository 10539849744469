import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { createPage } from '../../../redux/actions';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, withStyles } from '@material-ui/core';
import baseStyle from '../../../styles/helpConfig';

class AddPage extends Component {

  state = {
    open: false,
    name: ''
  };

  toggleDialog = () => {
    this.setState({ open: !this.state.open })
  };

  handleChange = ({ target: { name, value }}) => {
    this.setState({ [name]: value })
  };

  submit = () => {
    this.props.createPage({name: this.state.name});
    this.toggleDialog();
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <Dialog
          fullWidth
          open={this.state.open}
          aria-label='New page creator'
          onClose={this.toggleDialog}
        >
          <DialogTitle> Add A New Page </DialogTitle>
          <DialogContent>
            <TextField
              name='name'
              fullWidth
              label='Page Name'
              variant='outlined'
              value={this.state.name}
              onChange={this.handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button
              color='secondary'
              onClick={this.toggleDialog}
            >
              <FormattedMessage id='General.cancel'/>
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={this.submit}
            >
              <FormattedMessage id='General.submit'/>
            </Button>
          </DialogActions>
        </Dialog>
        <Button
          className={classes.addPageBtn}
          aria-label='toggle page creator'
          onClick={this.toggleDialog}
        >
          Add Page
        </Button>
      </>
    );
  }
}

const mapDispatch = (dispatch) => {
  return {
    createPage: (page) => dispatch(createPage(page))
  }
};

export default connect(null, mapDispatch)(withStyles(baseStyle)(AddPage));