import { Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import React from 'react';
import { useEffect, useState } from 'react';

function MobileSupportModal(props){
    const [browserModal,setBrowserModal]=useState(false)
    useEffect(()=>{
        const {mobile} = checkSupport()
        if(mobile){
            setBrowserModal(true)
        }
        },[])

        const checkSupport = () => {
            let isIpad = false
            let checkMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || Math.min(window.screen.width, window.screen.height) < 768
            if(/MacIntel/i.test(navigator.platform) || /Macintosh/i.test(navigator.userAgent)){
                if(navigator.maxTouchPoints && navigator.maxTouchPoints > 2){             
                    isIpad = true
                }
            }
                return {mobile: checkMobile || isIpad}
        };
    return <>
    {browserModal && <Dialog
                    open={browserModal}
                    onClose={() => { setBrowserModal(false) }}
                >
                    <DialogContent>
                        <Typography variant='subtitle1'>{"ObjectiveEd currently only supports access from PC, Mac, and Chromebook devices."}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setBrowserModal(false) }} color='secondary'>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>}
    </>
}

export default MobileSupportModal;

