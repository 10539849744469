import { makeStyles } from "@material-ui/core/styles";
export const SwitchToggleStyle = makeStyles({
  root: {
    width: "50px",
    height: "24px",
    padding: "0px"
  },
  switchBase: {
    color: "white",
    padding: "1px",
    "&$checked": {
      "& + $track": {
        backgroundColor: "#f57aa6"
      }
    }
  },
  thumb: {
    color: "f7397d",
    width: "20px",
    height: "20px",
    margin: "1px"
  },
  track: {
    borderRadius: "20px",
    color: "white",
    backgroundColor: "#818181",
    opacity: "1 !important",
    "&:after, &:before": {
      color: "white",
      fontSize: "11px",
      position: "absolute",
      top: "6px"
    },
    "&:after": {
      content: "'P'",
      left: "8px"
    },
    "&:before": {
      content: "'S'",
      right: "7px"
    }
  },
  checked: {
    color: "#f50057 !important",
    transform: "translateX(26px) !important"
  }
});