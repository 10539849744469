import React from 'react';
import { connect } from 'react-redux';
import WizardToggleButton from "../WizardToggleButton";
import SkillWizardPage1 from './SkillWizardPage1';
import { withStyles } from '@material-ui/core/styles';
import { fetchUserSkillDatabanks, addSkillToGoal } from '../../../../redux/actions';
import styles from '../../../../styles/wizardBase';
import { FormattedMessage } from 'react-intl';

class SkillWizardBase extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      open: false,
      page: 1,
      includeSelf: true,
    }
  }
  componentDidMount() {
    this.props.fetchUserSkillDatabanks(this.state.includeSelf)
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.account.id !== this.props.account.id) {
      if (nextProps.account.id) this.props.fetchUserSkillDatabanks(this.state.includeSelf)
    }
  };

  openWizard = () => {
    this.setState({ open: true })
  };

  closeWizard = () => {
    this.setState({ open: false, page: 1 });
  };

  next = () => {
    this.setState({ page: this.state.page+1 });
  };

  back = () => {
    this.setState({ page: this.state.page-1 });
  };

  submit = (values) => {
    const skill = values.skill;
    const percentages = {targetPercentage: values.targetPercentage*1, targetPrompts: values.targetPrompts*1};
    this.props.addSkillToGoal(this.props.id, {...skill, status: 'Inactive'}, percentages);
    this.closeWizard();
  };

  render(){
    const { page, open } = this.state;
    return (
      <div>
        {
          page === 1 &&
          <SkillWizardPage1
            open={open}
            close={this.closeWizard}
            next={this.next}
            goal={this.props.goal}
            onSubmit={this.submit}
            isStaff={this.props.isStaff}
            account={this.props.account}
            studentProfile={this.props.studentProfile}
          />
        }
        <WizardToggleButton
          openWizard={this.openWizard}
          text={<FormattedMessage id='Skill.add'/>}
        />
      </div>
    )
  }
}

const mapState = ({ shared: { account }}) => {
  return {
    account
  }
};

const mapDispatch = (dispatch) => {
  return {
    fetchUserSkillDatabanks: (includeSelf) => dispatch(fetchUserSkillDatabanks(includeSelf)),
    addSkillToGoal: (goalId, skill, percentages) => dispatch(addSkillToGoal(goalId, skill, percentages))
  }
};

export default connect(mapState, mapDispatch)(withStyles(styles)(SkillWizardBase));