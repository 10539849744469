export default {
  header: {
    backgroundColor: '#310073',
    fontSize: '24px',
    height: '80px',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '220px 1fr auto',
    '@media (max-width: 400px)': {
      gridTemplateColumns: '1fr 1fr',
      justifyContent: 'end',
    }
  },
  title: {
    marginLeft: '300px',
    gridColumn: 1,
    alignSelf: 'center',
    color: '#fff',
  },
  search: {
    gridColumn: 2,
    justifySelf: 'center',
    padding: '10px',
    width: '90%',
    alignSelf: 'center'
  },
  logo: {
    width: '220px',
    alignSelf: 'center',
  },
  buttons: {
    gridColumn: 3,
    alignSelf: 'center',
    justifySelf: 'end',
    marginRight: '40px',
    '@media (max-width: 400px)': {
      justifySelf: 'end',
      marginRight: 0,
    }
  },
  searchIcon: {
    color: '#310073'
  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: '#310073',
    },
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: '#310073',
    },
    color: '#310073',
    backgroundColor: '#fff',
    borderColor: '#310073',
    borderRadius: '4px'
  },
  notchedOutline: {},
  link: {
    color: '#fff',
    textDecoration: 'none',
    '&:focus &:hover &:visited &:link &:active': {
      textDecoration: 'none'
    }
  },
  icon: {
    padding: 5,
    backgroundColor: '#310073',
    color: '#fff',
    alignSelf: 'center',
    borderRadius: 10,
    marginRight: 10
  },
  app: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignContent: 'center'
  },
  appIcon: {
    padding: 5,
    backgroundColor: '#310073',
    color: '#fff',
    alignSelf: 'center',
    justifySelf: 'center',
    borderRadius: 10,
  },
  appText: {
    alignSelf: 'center',
    marginLeft: 10
  },
}