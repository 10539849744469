import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import AvatarEditor from 'react-avatar-editor';
import Avatar from 'react-avatar';
import {
  Divider,
  Typography,
  Paper,
  withStyles,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  Grid, DialogContentText
} from '@material-ui/core';
import { Slider } from '@material-ui/lab';
import { CameraAlt, AddCircleOutlined, RemoveCircleOutlined } from '@material-ui/icons';
import { myProfile, uploadProfileImage } from '../../redux/actions';
import baseStyle from '../../styles/profile';
import { FormattedMessage } from 'react-intl';

class ProfileDetail extends Component {

  state = {
    open: false,
    image: this.props.profile.imgSrc,
    width: 200,
    height: 200,
    borderRadius: 100,
    position: { x: 0.5, y: 0.5 },
    scale: 1,
    preview: null,
    img: ''
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.profile.imgSrc !== nextProps.profile.imgSrc){
      this.setState({ image: nextProps.profile.imgSrc })
    }
  };

  setEditorRef = editor => {
    if (editor) this.editor = editor;
    this.setState({ img: this.props.profile.imgSrc })
  };

  openFileUpload = () => {
    this.setState({ open: true })
  };

  closeFileUpload = () => {
    this.setState({ open: false })
  };

  handleScale = (ev, val) => {
    const scale = parseFloat(val);
    this.setState({ scale })
  };

  handlePositionChange = (position) => {
    this.setState({ position })
  };

  handleFileUpload = () => {
    const img = this.editor.getImageScaledToCanvas();
    this.props.uploadProfileImage(this.props.profile, img);
    this.setState({ open: false, image: img.toDataURL() })
  };

  handleDrop = (dropped) => {
    this.setState({ img: dropped[0] })
  };

  handleFileChange = (ev) => {
    this.setState({ img: ev.target.files[0] })
  };

  render() {
    const { classes, profile } = this.props;
    const { open } = this.state;
    return (
      <Paper className={classes.container}>
        <Dialog
          fullWidth
          open={open}
          onClose={this.closeFileUpload}
          onEscapeKeyDown={this.closeFileUpload}
        >
          <DialogTitle>
            Upload Profile Image
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <FormattedMessage id='Group.image.text'/>
            </DialogContentText>
            <section className={classes.dropzone}>
              <Dropzone
                onDrop={this.handleDrop}
                disableClick
                multiple={false}
                style={{ width: this.state.width, height: this.state.height, marginBottom:'35px' }}
              >
                {({getRootProps}) => (
                    <div>
                      <AvatarEditor
                        {...getRootProps()}
                        ref={this.setEditorRef}
                        width={this.state.width}
                        height={this.state.height}
                        scale={parseFloat(this.state.scale)}
                        position={this.state.position}
                        borderRadius={this.state.width / (100 / this.state.borderRadius)}
                        onPositionChange={this.handlePositionChange}
                        image={this.state.img}
                        crossOrigin='use-credentials'
                      />
                    </div>
                )}
              </Dropzone>
              <div className={classes.slider}>
                <Grid container spacing={2}>
                  <Grid item>
                    <RemoveCircleOutlined />
                  </Grid>
                  <Grid item xs>
                    <Slider
                      width={120}
                      aria-labelledby="continuous-slider"
                      name='scale'
                      onChange={this.handleScale}
                      min={0.1}
                      max={2}
                      defaultValue={1}
                      step={0.1}
                      value={this.state.scale}
                    />
                  </Grid>
                  <Grid item>
                    <AddCircleOutlined />
                  </Grid>
                </Grid>
              </div>
              <input
                accept="image/*"
                className={classes.input}
                style={{ display: 'none' }}
                id="raised-button-file"
                multiple
                type="file"
                onChange={this.handleFileChange}
              />
              <label className={classes.addImage} htmlFor="raised-button-file">
                <Button variant="contained" component="span">
                  <FormattedMessage id='Group.image.change'/>
                </Button>
              </label>
            </section>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.closeFileUpload}
              color='secondary'
            >
              <FormattedMessage id='General.cancel'/>
            </Button>
            <Button
              onClick={this.handleFileUpload}
              color='primary'
            >
              <FormattedMessage id='General.save'/>
            </Button>
          </DialogActions>
        </Dialog>
        <div className={classes.title}>
          <Typography
            align='center'
            variant='h5'
            className={classes.text}
          >
           {profile.name}
          </Typography>
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.pictureContainer}>
          <Avatar round src={this.state.image} name={profile.name} className={classes.avatar}/>
          <IconButton
            onClick={this.openFileUpload}
            className={classes.editButton}
          >
            <CameraAlt />
          </IconButton>
        </div>
        {
          this.state.description &&
          <>
            <Divider className={classes.secondaryDivider}/>
            <Typography align='center' variant='subtitle2'> {this.state.description} </Typography>
          </>
        }
      </Paper>
    );
  }
}

const mapState = ({ social: { profile }}) => {
  return {
    profile
  }
};

const mapDispatch = (dispatch, { history }) => {
  return {
    myProfile: () => dispatch(myProfile(history)),
    uploadProfileImage: (profile, file) => dispatch(uploadProfileImage(profile, file))
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(ProfileDetail));