import {
  ROOT_URL,
  FETCH_GOAL_DATABANKS,
  CREATE_GOAL_DATABANK,
  UPDATE_GOAL_DATABANK,
  FETCH_STAFF_GOAL_DATABANKS,
  CREATE_STAFF_GOAL_DATABANK,
  DELETE_STAFF_GOAL_DATABANK,
  DELETE_GOAL_DATABANK,
} from '../../constants';
import axios from 'axios';

export const fetchGoalDatabanks = (userType, id) => {
  const url = userType === 'staff' ?  `${ROOT_URL}/goal-databank/staff/${id}` : `${ROOT_URL}/goal-databank/admin/${id}`;
  const type = userType === 'staff' ? FETCH_STAFF_GOAL_DATABANKS : FETCH_GOAL_DATABANKS;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type,
          goalDatabanks: response.data
        })
      })
  };
};

export const createGoalDatabank = (userType, id, goalDatabank, history, guided) => {
  const url = userType === 'staff' ?  `${ROOT_URL}/goal-databank/staff/${id}` : `${ROOT_URL}/goal-databank/admin/${id}`;
  const type = userType === 'staff' ? CREATE_STAFF_GOAL_DATABANK : CREATE_GOAL_DATABANK;
  return (dispatch) => {
    return axios
      .post(url, goalDatabank)
      .then((response) => {
        dispatch({
          type,
          goalDatabank: response.data
        });
        guided
          ? history.push(`/staff/${id}/goals/${response.data.id}?guided`)
          : history.push(`/staff/${id}/goals/${response.data.id}`);
        return response.data
      })
      .catch(err => console.log(err))
  }
};

export const updateGoalDatabank = (goal) => {
  const url = `${ROOT_URL}/goal-databank`;
  return (dispatch) => {
    axios
      .patch(url, goal)
      .then(response => {
        dispatch({
          type: UPDATE_GOAL_DATABANK,
          goalDatabank: response.data
        });
      })
      .catch(err => console.log(err))
  }
};

export const deleteGoalDatabank = (userType, id, goalDatabank) => {
  const url = userType === 'staff' ?  `${ROOT_URL}/goal-databank/staff/${id}` : `${ROOT_URL}/goal-databank/admin`;
  const type = userType === 'staff' ? DELETE_STAFF_GOAL_DATABANK : DELETE_GOAL_DATABANK;
  return (dispatch) => {
    axios
      .delete(url, {data: {...goalDatabank}})
      .then(() => {
        dispatch({
          type,
          goalDatabank
        })
      })
      .catch(err => console.log(err))
  }
};

export const fetchUserGoalDatabanks = () => {
  const url = `${ROOT_URL}/goal-databank/my-databanks`;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: FETCH_GOAL_DATABANKS,
          goalDatabanks: response.data
        })
      })
      .catch(err => console.log(err))
  }
};