import { FETCH_ALL_GAMES, FETCH_ALL_STUDENT_GAMES, CREATE_STUDENT_GAME, PATCH_STUDENT_GAME, DELETE_STUDENT_GAME } from '../../constants';

export const games = (state = [], action) => {
  switch(action.type){
    case FETCH_ALL_GAMES:
      return action.games;
    default:
      return state;
  }
}

export const studentGames = (state = [], action) => {
  switch(action.type){
    case FETCH_ALL_STUDENT_GAMES:
      return action.games;
    case CREATE_STUDENT_GAME:
      return [...state, action.game];
    case PATCH_STUDENT_GAME:
      return state.map(game => game.id === action.game.id ? action.game : game );
    case DELETE_STUDENT_GAME:
      return state.filter(game => game.id !== action.game.id);
    default:
      return state;
  }
}