import { Dialog, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAccount } from '../../../../redux/actions';
import styles from '../../../../styles/registration';
import PurchaseInfo from './PurchaseInfo';
import RegistrationCheckout from './RegistrationCheckout';
import RegistrationStudentCount from './RegistrationStudentCount';

const defaultTerms = 'https://docs.google.com/document/d/e/2PACX-1vRgiqiZ2EZ0jHECJ_bY972X3rhK1m722J19V7Rt3nTPnQLdzhMIMwQAH3_Iq1UM11Ask9bbs_l-ebB1/pub?embedded=true'
class PaymentDialog extends Component {
  state = {
    page: 1,
    studentCount: 1,
    students: [],
    email: this.props.user.email || this.props.email || '',
    product: {},
    terms: {},
    purchase: false,
    coupon: ""
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.user.id !== nextProps.user.id) {
      this.setState({ email: nextProps.user.email })
    }
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.account != this.props.account) {
      if (this.props.account && this.props.account.billingCount > 0) {
        this.props.history.push(this.user.homePage);
      }
    }
  }

  next = () => {
    this.setState({
      page: this.state.page + 1,
      purchase: true
    });
  };

  goToPage = (page) => {
    this.setState({ page })
  };

  handleChange = ({ target: { value, name } },plans=[]) => {
    let plan = "";
    if(plans.length !== 0){
      plan = plans.find(plan => plan.id === value.id)
      console.log(plan ? plan.coupon:"")
    }
    this.setState(({ [name]: value,coupon : plan ? plan.coupon : "" }))
  };

  handleProceedToPurchase = () => {
    this.setState({ proceedToPurchase: true, purchase: false, page: this.state.page + 1 });
  }

  closeDialog = () => {
    this.setState({ setup: false, purchase: false, });
    this.props.close();
  };

  closePurchaseDialog = () => {
    this.setState({ proceedToPurchase: false })
  };

  handleLogin = () => {
    if (this.props.isLoggedIn) {
      this.props.history.push(this.user.homePage);
    } else {
      this.props.handleNext();
    }
  }
  setDefaultProduct = (product,coupon) => {
    this.setState({ product: product, coupon: coupon })
  }
  render() {
    const { page } = this.state;
    return (
      page === 2
        ? <RegistrationCheckout
          product={this.state.product}
          email={this.state.email}
          students={this.state.studentCount}
          next={this.handleLogin}
          goToPage={this.goToPage}
          user={this.props.user}
          terms={this.props.terms || defaultTerms}
          coupon={this.state.coupon}
        />
        : <Dialog
          maxWidth={'sm'}
          fullWidth
          open={this.props.open}
          onClose={this.props.close}
          disableBackdropClick={true}
          hideBackdrop
        >
          {
            page === 1 &&
            <RegistrationStudentCount
              registered={this.props.registered}
              email={this.state.email}
              user={this.props.user}
              product={this.state.product}
              handleChange={this.handleChange}
              students={this.state.studentCount}
              close={this.props.close}
              goToPage={this.goToPage}
              next={this.next}
              setDefaultProduct={this.setDefaultProduct}
            />
          }
          {/* {
            page === 2 &&
            <PurchaseInfo close={this.closeDialog} open={this.state.purchase} id={this.props.staffId || this.props.user.staffId} handleProceedToPurchase={this.handleProceedToPurchase} />
          } */}
        </Dialog>
    );
  }
}

const mapState = ({ shared: { error, user, account } }) => {
  return {
    user,
    account,
    error
  }
};
const mapDispatch = (dispatch) => {
  return {
    getAccount: () => dispatch(getAccount())
  }
};

export default connect(mapState, mapDispatch)(withStyles(styles)(PaymentDialog));