import React from 'react';
import DataTable from '../Shared/DataTable';
import { FormattedMessage } from 'react-intl';

const SkillDatabankList = (props) => {

  const rows = [
    { id: 'name', label: <FormattedMessage id='General.name'/>, searchable: true },
    { id: 'description', label: <FormattedMessage id='General.description'/> },
    { id: 'difficulty', label:  <FormattedMessage id='General.difficulty'/> },
    { id: 'area', label:  <FormattedMessage id='General.area'/>},
  ];

  const title = (
    <>
      {
        props.myself
          ? <FormattedMessage id='General.my' />
          : `${props.staff.firstName}'s`}&nbsp;
      <FormattedMessage id='Databanks.skillDB'/>
    </>
  );

  return <DataTable
    history={props.history}
    myself={props.myself}
    staffId={props.id}
    account={props.account}
    type={props.type}
    editable
    deletable
    clickable={props.clickable}
    rows={rows}
    rowsPerPage={[5,10,15]}
    orderBy={''}
    data={props.skillDatabanks}
    numCols={2}
    title={title}
  />
};

export default SkillDatabankList;