import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Typography, Divider } from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import StaffEmploymentInfo from '../../components/Staff/StaffEmploymentInfo';
import StaffPersonalInfo from '../../components/Staff/StaffPersonalInfo';
import { deregisterUser, getStaffById, patchStaffMember, registerUser, getStaffProfile, updateStaffProfile, createStaffProfile } from '../../../redux/actions';
import baseStyle from '../../../styles/account';

class StaffProfile extends Component {

  state = {
    firstName: this.props.staffMember.firstName || '',
    lastName: this.props.staffMember.lastName || '',
    email: this.props.staffMember.email || '',
    type: this.props.staffMember.type || 'Teacher',
    edit: false,
    login: this.props.staffMember.userId !== 'null',
    role: this.props.staffProfile.role || '',
    phone: this.props.staffProfile.phone || '',
    employerName: this.props.staffProfile.employerName ||'',
    employerType: this.props.staffProfile.employerType || ''
  };

  componentDidMount() {
    this.props.getStaffById(this.props.id);
    this.props.getStaffProfile(this.props.id);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {staffMember: { firstName, lastName, email, type }, staffProfile: {role, phone, employerType, employerName}} = nextProps;
    this.setState({ firstName, lastName, email, type, role, phone, employerType, employerName });
  }

  toggleLogin = () => {
    this.state.login ? this.props.deregisterUser(this.props.data) : this.props.registerUser(this.props.data);
    this.setState(state => ({ login: !state.login }));
  };

  toggleEdit = (type) => {
    this.setState({ [type]: !this.state[type] })
  };

  handleChange = ({ target: { name, value }}) => {
    this.setState({ [name]: value })
  };

  handleSubmit = (_type) => {
    if (_type === 'personal') {
      const { firstName, lastName, email, type } = this.state;
      const staffMember = {type, firstName, lastName, email, id: this.props.id};
      this.props.patchStaffMember(staffMember);
      this.setState({ editPersonal: false })
    }
    else if (_type === 'employment'){
      const { id } = this.props.staffProfile;
      const { role, phone, employerName, employerType } = this.state;
      const staffProfile = {role, phone, employerName, employerType };
      if (id !== 'null') {
        this.props.updateStaffProfile(this.props.id, {...staffProfile, id});
      }
      else {
        this.props.createStaffProfile(this.props.id, staffProfile);
      }
      this.setState({ editEmployment: false })
    }
  };

  cancel = (edit) => {
    const {staffMember: {firstName, lastName, email, type}, staffProfile: {role, phone, employerType, employerName}} = this.props;
    this.setState({ [edit]: false, role, phone, employerType, employerName, firstName, lastName, email, type });
  };

  render() {
    const { classes } = this.props;
    const { firstName, lastName, email, type, editEmployment, editPersonal, role, phone, employerType, employerName } = this.state;
    const myself = this.props.user.staffId === this.props.id;
    const admin = this.props.user.type === 'Admin' || this.props.user.type === 'Master';
    return (
      <div>
        <div className={classes.title}>
          <Settings className={classes.icon} fontSize='inherit'/>
          <Typography className={classes.text} variant='h3'>
            <FormattedMessage id="Profile.settings"/>
          </Typography>
        </div>
        <Divider />
        <br />
        <div className={classes.profile}>
          <StaffPersonalInfo
            myself={myself}
            id={this.props.id}
            admin={admin}
            edit={editPersonal}
            staffMember={{type, firstName, lastName, email}}
            profile={this.props.staffProfile}
            login={this.state.login}
            cancel={this.cancel}
            submit={this.handleSubmit}
            handleChange={this.handleChange}
            toggleEdit={this.toggleEdit}
            updateStaffProfile={this.props.updateStaffProfile}
            createStaffProfile={this.props.createStaffProfile}
          />
          {/* <StaffEmploymentInfo
            myself={myself}
            admin={admin}
            edit={editEmployment}
            staffProfile={{role, phone, employerType, employerName}}
            cancel={this.cancel}
            submit={this.handleSubmit}
            handleChange={this.handleChange}
            toggleEdit={this.toggleEdit}
            type={type}
          /> */}
        </div>
      </div>
    )
  }
};

const mapState = ({ platform: { staffMember, staffProfile }, shared: { user }}) => {
  return {
    staffMember,
    staffProfile,
    user
  }
};

const mapDispatch = (dispatch) => {
  return {
    getStaffById: (id) => dispatch(getStaffById(id)),
    getStaffProfile: (id) => dispatch(getStaffProfile(id)),
    patchStaffMember: (staffMember) => dispatch(patchStaffMember(staffMember)),
    updateStaffProfile: (staffId, profile) => dispatch(updateStaffProfile(staffId, profile)),
    createStaffProfile: (staffId, profile) => dispatch(createStaffProfile(staffId, profile)),
    registerUser: (user) => dispatch(registerUser(user)),
    deregisterUser: (user) => dispatch(deregisterUser(user)),
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(StaffProfile));
