import {
    FETCH_SLIDER_MANAGEMENT_SERIES,
    CREATE_SLIDER_MANAGEMENT_SERIES,
    PATCH_SLIDER_MANAGEMENT_SERIES,
    DELETE_SLIDER_MANAGEMENT_SERIES,
    ADD_STORY_TO_SERIES,
  } from "../../constants";
  
  
  export default function (state = [], action) {
      switch(action.type){
        case FETCH_SLIDER_MANAGEMENT_SERIES:
          return action.sliderSeries;
        case CREATE_SLIDER_MANAGEMENT_SERIES:
          return [...state, action.sliderSeries];
        case PATCH_SLIDER_MANAGEMENT_SERIES:
          return state.map(slider => slider.id === action.slider.id ? action.slider : slider );
        case DELETE_SLIDER_MANAGEMENT_SERIES:
         return state.filter(slider => slider.id !== action.slider.id);
         case ADD_STORY_TO_SERIES:
          return state.map(slider=>slider.id === action.slider.id ? action.slider : slider)
        default:
          return state;
      }
    }