import React, { Component } from 'react';
import SocialHeader from '../../../Social/containers/SocialHeader';
import { Typography } from '@material-ui/core';
import errorPic from '../../../assets/SocialMockups/404.png';
import '../../../styles/error.css';
import { FormattedMessage } from 'react-intl';

class ErrorBoundary extends Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.log('!', error, info, '!');
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <SocialHeader error/>
          <div id='error'>
            <Typography variant='h4'><FormattedMessage id='404.1'/></Typography>
            <Typography variant='h6'>
              <FormattedMessage id='404.2'/>
            </Typography>
            <img className='error-pic' alt='confused businessman' src={errorPic}/>
            <Typography variant='h6'>
              <FormattedMessage id='404.3'/>
            </Typography>
          </div>
        </>
      )
    }

    return this.props.children;
  }
}

export default ErrorBoundary;