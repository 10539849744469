export default {
  title: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '55px',
  },
  subtitle: {
    marginBottom: 20,
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '55px',
  },
  text: {
    gridRow: 1,
    marginLeft: '20px',
    alignSelf: 'center'
  },
  subtext:{
    margin: '20px auto'
  },
  divider: {
    margin: '20px auto',
    padding: '0 5px'
  },
  icon: {
    padding: '5px',
    backgroundColor: '#0087b6',
    color: '#f9f3ff',
    fontSize: '3rem',
    alignSelf: 'center',
    borderRadius: '10px'
  },
  skillBtns: {
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'start',
    gridColumnGap: '15px'
  }
}