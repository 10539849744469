import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import EditWizardButton from '../Wizards/Edit/EditWizardButton';
import {
  Dialog, DialogActions, DialogContent,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Button, DialogContentText
} from '@material-ui/core';
import { Paper, IconButton, Typography, Toolbar, Tooltip, withStyles } from '@material-ui/core';
import { Delete, PlayCircleFilled, PlayCircleOutline, Refresh, Reorder, CloudDownload } from '@material-ui/icons';
import arrayMove from 'array-move';
import { deleteSkill, toggleSkill, resetSkill, orderSkills, downloadSheet } from '../../../redux/actions';
import { stableSort, getSorting } from '../../../helpers';
import { ROOT_URL } from '../../../redux/constants';
import styles from '../../../styles/skillList.js';
import { FormattedMessage } from 'react-intl';

const DragHandle = SortableHandle(() => <TableCell><Reorder /></TableCell>);

// Drag and drop row item
const SortableItem = SortableElement((props) => {
  const { value, test, rows, tested, selected, classes, handleClick, toggle, handleEditClick, handleDelete, handleTest, handleReset } = props;
  return (
    <TableRow
      hover={value.id !== selected.id}
      key={value.id}
      classes={{
        root: value.id === selected.id ? classes.selected : null
      }}
    >
      <DragHandle />
      {
        rows.length &&
        rows.map((row, index) => {
          return (
            <TableCell
              onClick={() => handleClick(value)}
              classes={{
                root: value.id === selected.id ? classes.selected : null
              }}
              key={index}
            >
              {
                value[row.id] && value[row.id] !== ' '
                  ? value[row.id]
                  : 'N/A'
              }
            </TableCell>
          )
        })
      }
      <TableCell>
        <Tooltip title={value.status === 'Active' ? 'Deactivate Skill' : 'Activate Skill'}>
          <Switch
            checked={value.status === 'Active'}
            onChange={() => toggle(value)}
            name='status'
            aria-label='toggle active or inactive'
          />
        </Tooltip>
      </TableCell>
      {
        <TableCell align='justify'>
          <div className={classes.buttons}>
            <EditWizardButton
              id={value.id}
              onClick={handleEditClick}
              type='skill'
              data={value}
            />
            {
              value.brailleSheetId &&
              <Tooltip title={<FormattedMessage id='General.download'/>}>
                <IconButton aria-label='download brf file' onClick={() => downloadSheet(value.brailleSheetId, value.name)}>
                  <CloudDownload />
                </IconButton>
              </Tooltip>
            }
            <Tooltip title={<FormattedMessage id='General.delete'/>}>
              <IconButton aria-label='delete' onClick={() => handleDelete(value)}>
                <Delete color='secondary'/>
              </IconButton>
            </Tooltip>
            <Tooltip title={tested === value.id ? 'Re-Test Skill' : 'Test Skill'}>
              <IconButton aria-label='test' onClick={() => handleTest(value)}>
                {
                  tested === value.id
                    ? <PlayCircleOutline color='primary'/>
                    : <PlayCircleFilled/>
                }
              </IconButton>
            </Tooltip>
            {
              test &&
              <Tooltip title='Reset Scores'>
                <IconButton aria-label='reset scores' onClick={() => handleReset(value)}>
                  <Refresh/>
                </IconButton>
              </Tooltip>
            }
          </div>
        </TableCell>
      }
    </TableRow>
  )
});

// Wrapper to make drag and drop usable
const SortableTable = SortableContainer(({children}) => {
  return children
});

class SkillList extends Component {

   state = {
     order: 'asc',
     orderBy: '',
     selected: {},
     tested: '',
     editing: false,
     items: [],
     reset: [],
     error: {}
   };

  onSortEnd = ({oldIndex, newIndex}) => {
    const items = arrayMove(this.state.items, oldIndex, newIndex);
    this.setState({ items });
    this.props.orderSkills(this.props.goal.id, items)
  };

  componentDidMount() {
    if (this.props.history.location.state){
      const skill = this.props.skills.find(skill => skill.id === this.props.history.location.state);
      if (skill) this.props.toggle(skill);
      this.setState({ selected: skill ? skill : {} })
    }
    else {
      this.setState({ selected: {}});
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if(nextProps.skills !== this.props.skills)  {
      this.setState({ items: nextProps.skills });
    }
    if(this.state.selected.id && !this.props.history.location.state) this.setState({ selected: {}})
  }

  componentWillUnmount() {
    this.setState({ reset: []})
  }

  handleSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    this.setState({ order, orderBy });
  };

  handleReset = (skill) => {
    this.props.resetSkill(this.props.goal.id, skill)
    this.setState({ tested: '', reset: [...this.state.reset, skill.id] })
  };

  handleClick = (skill) => {
      if (this.props.clickable !== false) {
        this.props.toggle(skill);
        const highlighted = this.props.toggled && this.state.selected === skill;
        this.setState({ selected: highlighted ? {} : skill });
      }
  };

  handleDelete = (skill) => {
    const { deleteSkill } = this.props;
    deleteSkill(this.props.goal.id, skill)
  };

  handleEditClick = () => {
    this.setState({ editing: false, clickable: this.props.clickable })
  };

  handleTest = (skill) => {
    this.runTest(skill.id);
    this.setState({ tested: skill.id })
  };

  runTest = (id) => {
    const url = `${ROOT_URL}/goals/${this.props.goal.id}/skills/${id}/test`;
    axios.post(url)
      .catch(err => console.log(err));
  };

  toggle = (skill) => {
    this.props.toggleSkill(this.props.goal.id, skill)
      .catch(err => {
        this.setState({ error: err.response })
        console.log({...err})
      })
  };

  toggleEdit = () => {
    this.setState(state => ({ editing: !state.editing }))
  };

  render(){
    const rows = [
      { id:'name', label: <FormattedMessage id='General.name'/>  },
      { id:'description', label: <FormattedMessage id='General.description'/> },
      { id:'difficulty', label: <FormattedMessage id='General.difficulty'/> },
      { id:'area', label: <FormattedMessage id='General.area'/> },
      { id:'targetPercentage', label: <FormattedMessage id='General.targetPercentage'/> },
      // { id:'targetPrompts', label: <FormattedMessage id='General.targetPrompts'/> },
    ];

    const { classes, goal, test } = this.props;
    const { orderBy, order, editing, selected, items, tested } = this.state;
    return (
      <>
      <Paper className={ classes.list }>
        <Toolbar classes={{ root: classes.toolbar }}>
          <Typography noWrap className={ classes.header }>
            <strong><FormattedMessage id='Skill.for'/> {goal.name}</strong>
          </Typography>
        </Toolbar>
        <div className={classes.tableWrapper}>
          <SortableTable
            useDragHandle
            onSortEnd={this.onSortEnd}
          >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell/>
                { rows.length &&
                rows.map(row => {
                  return (
                    <TableCell
                      key={row.id}
                      sortDirection={ orderBy === row.id ? order : false }
                    >
                      <Tooltip
                        title={<FormattedMessage id='General.sort' />}
                        placement='bottom-start'
                        enterDelay={300}
                      >
                        <TableSortLabel
                          active={orderBy === row.id}
                          direction={order}
                          onClick={(ev) => this.handleSort(ev, row.id)}
                        >
                          {row.label}
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                  )
                })
                }
                <TableCell
                  key='status'
                  sortDirection={ orderBy === 'status' ? order : false }
                >
                  <Tooltip
                    title={<FormattedMessage id='General.sort' />}
                    placement='bottom-start'
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={orderBy === 'status'}
                      direction={order}
                      onClick={(ev) => this.handleSort(ev, 'status')}
                    >
                      <FormattedMessage id='General.active'/>
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                stableSort(items, getSorting(order, orderBy))
                  .map((value, index) => {
                    return (
                      <SortableItem
                        error={this.props.error}
                        key={value.id}
                        value={value}
                        index={index}
                        classes={classes}
                        selected={selected}
                        editing={editing}
                        rows={rows}
                        tested={tested}
                        test={test}
                        handleClick={this.handleClick}
                        toggle={this.toggle}
                        handleEditClick={this.handleEditClick}
                        handleDelete={this.handleDelete}
                        handleTest={this.handleTest}
                        handleReset={this.handleReset}
                      />
                    );
                  })
                }
               <TableRow />
              </TableBody>
            </Table>
          </SortableTable>
        </div>
      </Paper>
      <Dialog open={this.state.error.status === 400}>
        <DialogContent>
          <DialogContentText>
            {this.state.error.data}. Please update your subscription in order to use these products
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            onClick={() => this.setState({ error: {} })}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      </>
    )
  }
}

const mapState = ({ platform: {staffMember, skills}, shared: { error } }) => {
  return {
    staffMember,
    skills,
    error
  }
};

const mapDispatch = (dispatch) => {
  return {
    deleteSkill: (goalId, skill) => dispatch(deleteSkill(goalId,skill)),
    toggleSkill: (goalId, skill) => dispatch(toggleSkill(goalId,skill)),
    resetSkill: (goalId, skill) => dispatch(resetSkill(goalId, skill)),
    orderSkills: (goalId, skills) => dispatch(orderSkills(goalId, skills))
  }
};

export default connect(mapState, mapDispatch)(withStyles(styles)(SkillList));