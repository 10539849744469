import React, { Component } from 'react';
import { Button, Typography, IconButton, FormGroup } from '@material-ui/core';
import { Dialog, DialogContent, DialogTitle, DialogContentText } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../../styles/wizardBase';
import { downloadSheet } from '../../../../redux/actions';
import { FormattedMessage } from 'react-intl';
import SkillDatabankWizardBase from '../SkillDatabank/SkillDatabankWizardBase';

class BrailleSheetsWizardPage3 extends Component {

  state = {
    downloaded: false,
    open: false
  };

  handleDownload = () => {
    downloadSheet(this.props.sheet.id, this.props.sheet.name);
    this.setState({ downloaded: true })
  };

  next = () => {
    this.setState({ open: true })
  };

  close = () => {
    this.setState({ open: false })
  };

  render()  {
    const { classes, sheet } = this.props;
    return (
      <>
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        open={this.props.open}
        onClose={this.props.close}
        aria-labelledby='form-dialog-title'
        PaperProps={{classes:{ root: classes.container }}}
        scroll='paper'
      >
        <DialogTitle disableTypography={true} className={classes.title} id='form-dialog-title'>
          <Typography className={classes.titleText} variant='h4'>
            <FormattedMessage id='Databanks.brailleSheets'/>
          </Typography>
          <IconButton
            onClick={this.props.close}
            className={ classes.exitIcon }
            aria-label="close dialog"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.container}>
          <FormGroup classes={{root: classes.brailleRoot}}>
            {
              sheet &&
              <DialogContentText align='center' variant='h6'>GREAT! We added "{sheet.name}" to your Braille Sheets</DialogContentText>
            }
            <Typography align='center' gutterBottom variant='h6'>Please download the BRF:  </Typography>
            <Button className={classes.downloadButton} onClick={this.handleDownload} variant='contained' color='primary'>
              <FormattedMessage id='General.download'/>
            </Button>
            {
              this.state.downloaded &&
                <>
                  <Button className={classes.selectButtons} onClick={this.next} variant='contained' color='primary'>
                    Let's make a new skill
                  </Button>
                  <Button onClick={this.props.close}>
                    no thanks, I'll come back to this later
                  </Button>
                </>
            }
          </FormGroup>
        </DialogContent>
      </Dialog>
      <SkillDatabankWizardBase sheet={sheet} close={this.close} history={this.props.history} type='staffSkillDatabank' open={this.state.open} braille />
      </>
    )
  }
}

export default withStyles(baseStyle)(BrailleSheetsWizardPage3);