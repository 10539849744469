import React, { Component } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { Card, CardContent, Divider, Typography } from '@material-ui/core';
import { BarChart as BarIcon } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../styles/barChart';

const sampleData = [
  {
    name: 'George Washington',
    Average: 80,
  },
  {
    name: 'Jim Hawkins',
    Average: 80,
  },
  {
    name: 'Johnny Five',
    Average: 55,
  },
  {
    name: 'Steve Martin',
    Average: 80,
  },
  {
    name: 'Laurel Hardy',
    Average: 90,
  },
];

class BarChart extends Component {

  state = {
    percentage: this.props.chart.yAxisLabel.toLowerCase() === 'accuracy'
  };

  formatName = (name) => {
    return name
      .replace(/([A-Z0-9])/g, ` $1`)
      .replace(/^./, (str) => str.toUpperCase())
  };

  percentage = (value) => {
    return `${value.toFixed(3) * 100}%`
  };

  normal = (value) => {
    return value
  };

  tooltip = (slice) => {
    return (
      <div>
        <div><strong>{slice.indexValue}</strong></div>
        <div>Accuracy: <strong>{`${slice.value.toFixed(3) * 100}%`}</strong></div>
      </div>
    )
  };

  render(){
    const { percentage } = this.state;
    const { classes, title, chart } = this.props;
    const Title = ({ width, height }) => {
      // You can console.log(data) to see what other properties are available

      const style = {fontWeight: 'bold'}

      return (
          <text
              x="42%" dominantBaseline="middle" textAnchor="middle"
              y={-10}
              style={style}
          >
            {title}
          </text>
      )
    };
    const data = chart ? chart.dataSet.map(data => {
      return { [chart.yAxisLabel]: data.value, [chart.xAxisLabel]: this.formatName(data.label) }
    }) : this.props.data ? this.props.data : sampleData;
    return (
      <Card className={classes.barChartCard}>
        <div className={classes.header}>
          <BarIcon className={classes.icon}/>
          <Typography className={classes.chartTitle} variant='h4'>{title}</Typography>
        </div>
        <Divider />
        <CardContent className={classes.barChart}>
          <ResponsiveBar
            data={data}
            keys={[
              chart.yAxisLabel
            ]}
            layers={['grid', 'axes', 'bars', 'markers', 'legends', 'annotations', Title]}
            indexBy={chart.xAxisLabel}
            margin={{
              top: 50,
              right: 60,
              bottom: 50,
              left: 60
            }}
            padding={0.05}
            groupMode='grouped'
            colorBy='id'
            colors={['#0087b6']}
            borderWidth={1}
            borderColor='inherit:darker(1)'
            axisLeft={{
              tickValues: 5,
              tickSize: 0,
              tickPadding: 5,
              tickRotation: 0,
              format: percentage ? this.percentage : this.normal
            }}
            enableGridY={true}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor='#000'
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            tooltip={this.tooltip}
            labelFormat={percentage ? this.percentage : this.normal}
            theme={{
              labels: {
                text: { fontSize: 18, fontWeight: 800 }
              },
              axis: {
                ticks: {
                  text: { fontSize: 16, fontWeight: 800 }
                }
              }
            }}
          />
        </CardContent>
      </Card>
    )
  }
}

export default withStyles(baseStyle)(BarChart);