import React, { Component } from 'react';
import { connect } from 'react-redux';
import GoalList from '../../components/Student/GoalList';
import { getStudentGoals, getStudent, fetchUserGoalDatabanks, fetchMyGoals } from '../../../redux/actions';
import {
  Dialog,
  DialogContent,
  Divider,
  Typography,
  Button,
  DialogActions
} from '@material-ui/core/index';
import { CheckCircleOutlined } from '@material-ui/icons/index';
import GoalWizard from '../../components/Wizards/Goal/GoalWizardBase';
import { withStyles } from '@material-ui/core/styles/index';
import baseStyle from '../../../styles/goals.js';
import ImportGoal from '../../components/Staff/ImportGoal';
import { FormattedMessage } from 'react-intl';

class Goals extends Component {

  state = {
    open: false,
    goal: false
  };

  componentDidMount() {
    this.props.getStudentGoals(this.props.id);
    this.props.getStudent(this.props.id);
    this.props.fetchUserGoalDatabanks();
    this.props.fetchMyGoals();
    if (this.props.history.location.search){
      this.setState({ open: true })
    }
  }

  render() {
    const { classes, student } = this.props;
    return (
      <div>
        <div className={classes.title}>
          <CheckCircleOutlined className={classes.icon} fontSize='inherit'/>
          <Typography className={classes.text} variant='h3'>
            {student.firstName}'s <FormattedMessage id='Goals.title'/>
          </Typography>
        </div>
        <Divider />
        <br />
        <GoalList skill={this.props.skillDatabank} clickable deletable editable type={this.props.history.location.search === '?guided' ? 'guidedGoal' : 'goal'} {...this.props}/>
        <div className={classes.skillBtns}>
          <GoalWizard guided={this.props.history.location.search === '?guided'} open={this.state.goal} id={this.props.id} history={this.props.history} />
          <ImportGoal title={<FormattedMessage id='Databanks.importGoal' />} history={this.props.history} id={this.props.id}/>
        </div>
        <Dialog open={this.state.open}>
          <DialogContent>
            <Typography variant='h6' align='center'>
              {
                this.props.goals.length > 0
                ? "Please Select which goal you want to put this skill under"
                : "It looks like there are no goals, let's add one!"
              }
            </Typography>
            <DialogActions>
              <Button onClick={() => this.setState({ open: false, goal: this.props.goals.length <= 0  })}>
                OK
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </div>
    )
  }
}

const mapState = ({ platform: { goals, student, skillDatabank }}) => {
  return {
    goals,
    student,
    skillDatabank
  }
};

const mapDispatch = (dispatch, {history}) => {
  return {
    getStudentGoals: (studentId) => dispatch(getStudentGoals(studentId, history)),
    getStudent: (studentId) => dispatch(getStudent(studentId)),
    fetchUserGoalDatabanks: () => dispatch(fetchUserGoalDatabanks(history)),
    fetchMyGoals: () => dispatch(fetchMyGoals()),
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(Goals));