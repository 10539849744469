import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { fetchStaff } from '../../../redux/actions';
import DataTable from "../Shared/DataTable";

class StaffList extends Component {

  componentDidMount(){
    if (this.props.user){
      this.props.fetchStaff();
    }
  }

  render(){
    const rows = [
      { id:'firstName', label: <FormattedMessage id='General.firstName'/>, searchable: true },
      { id:'lastName', label: <FormattedMessage id='General.lastName'/>, searchable: true },
      { id: 'email', label: <FormattedMessage id='General.email'/>, searchable: true },
      { id: 'type', label: <FormattedMessage id={'General.role'}/>, searchable: true }
    ];
    const data = this.props.staff;
    return (
      <DataTable
        type={'staff'}
        editable
        deletable
        clickable
        location={this.props.location}
        account={this.props.account}
        history={this.props.history}
        rows={rows}
        rowsPerPage={[5,10,25]}
        orderBy={'firstName'}
        data={data}
        numCols={4}
        title={<FormattedMessage id='StaffMembers.title'/>}
      />
    );
  }
}

const mapState = ({ platform: { staff }, shared: { user, account }}) => {
  return {
    staff,
    user,
    account,
  }
};

const mapDispatch = (dispatch, {history}) => {
  return {
    fetchStaff: () => dispatch(fetchStaff(history))
  }
};

export default  connect(mapState, mapDispatch)(StaffList);