import { ROOT_URL, FETCH_USER, PATCH_STAFF_MEMBER, PATCH_STUDENT, HANDLE_ERROR } from '../constants';
import axios from 'axios';

export const getUser = (history) => {
  const url = `${ROOT_URL}/admin/user`;
  return (dispatch) => {
    axios
      .get(url, { withCredentials:true })
      .then((response) => {
        if (typeof(response.data) === 'object'){
          dispatch({
            type: FETCH_USER,
            user: response.data
          })
        }
        else {
          if (history) history.push('/login');
        }
      })
  }
};

export const getStaffUser = (history) => {
  const url =`${ROOT_URL}/staff/user`;
  return (dispatch) => {
    axios
      .get(url)
      .then(response => {
        if (typeof(response.data) === 'object'){
          dispatch({
            type: FETCH_USER,
            user: response.data
          })
        }
        else {
          if (history) history.push('/login');
        }
      })
  }
};

export const registerUser = (user) => {
  const url = `${ROOT_URL}/staff/${user.id}/registerUser`;
  return (dispatch) => {
    axios
      .post(url, user)
      .then((response) => {
        dispatch({
          type: PATCH_STAFF_MEMBER,
          staffMember: response.data
        })
      })
      .catch(err => console.log(err))
  }
};

export const deregisterUser = (user) => {
  const url = `${ROOT_URL}/staff/${user.id}/deregisterUser`;
  return (dispatch) => {
    axios
      .post(url)
      .then((response) => {
        dispatch({
          type: PATCH_STAFF_MEMBER,
          staffMember: response.data
        })
      })
      .catch(err => console.log(err))
  }
};

export const toggleStudentLogin = (id, ignoreWarning, password) => {
  const url = `${ROOT_URL}/students/${id}/login?ignoreWarning=${ignoreWarning}`;
  return (dispatch) => {
    return axios
      .post(url)
      .then((response) => {
        if (password) {
          axios
           .post(`${ROOT_URL}/students/${id}/password`, password, { headers: {'Content-Type': 'text/plain' }})
           .catch(err => console.log(err))
        }
        dispatch({
          type: PATCH_STUDENT,
          student: response.data
        })
      })
      .catch(err => {
        console.log({err});
        dispatch({
          type: HANDLE_ERROR,
          error: {type: 'gamerTag', msg: err.response.data}
        })
      })
  };
};