import { GET_BOOKS_FOR_STUDENT, GET_ALL_BOOKS, GET_DATABANK, GET_STORY_LIST, ADD_BOOK_TO_STUDENT, DELETE_BOOK, SEARCH_FOR_BOOKS, SEARCH_FOR_BOOKS_FAIL, DOWNLOAD_BOOK_COMPLETE, DOWNLOAD_BOOK_FAIL, ADD_BOOK_TO_STUDENT_FAILED, BOOK_SHARE_LOGIN_VERIFED, GET_BOOKSHARE_USERS, ADD_BOOKSHARE_USER, STUDENT_BOOK_IMPORTED, RESET_IMPORT_MODAL, SAVE_IMPORTED_BOOK, REMOVE_IMPORTED_BOOK, REMOVE_STUDENT_BOOK, SEARCH_FOR_BOOKS_FOUND, REMOVE_SEARCH_IMPORTED_BOOK, RESET_BOOKLIST_TEMP, GET_BOOKSHARE_REQUEST_BOOKS, DELETE_BOOKSHARE_REQUEST_BOOK } from '../../constants';


export const books = (state = [], action) =>
{
    switch (action.type) {
        case GET_BOOKS_FOR_STUDENT:
            return action.books;
        case ADD_BOOK_TO_STUDENT:
            return [...state, action.book];
        case DELETE_BOOK:
            return state.filter(book => book.id !== action.book.id);
        default:
            return state;
    }
}

export const bookList = (state = [], action) => 
{
    switch (action.type) {
        case GET_ALL_BOOKS:
            return action.bookList;
        default:
            return state;
    }
}

export const storyList = (state = [], action) => 
{
    switch (action.type) {
        case GET_STORY_LIST:
            return action.storyList;
        default:
            return state;
    }
}

export const bookShareList = (state = [], action) =>
{
    switch (action.type) {
        case SEARCH_FOR_BOOKS:
            return action.bookShareList;
        case SEARCH_FOR_BOOKS_FAIL:
            return [];
        default:
            return state;
    }
}

export const downloadBook = (state = {}, action) => {
    switch (action.type) {
        case DOWNLOAD_BOOK_COMPLETE:
            return {isBookDowloaded: true};
        case DOWNLOAD_BOOK_FAIL:
            return {isBookDowloaded: false};
        default:
            return state;
    }
}

export const databank = (state = "", action) => {
    switch (action.type) {
        case GET_DATABANK:
            return action.databank;
        default:
            return state;
    }
}

export const booksImport = (state = { needToLogin:"true", bookList:[], book:[] }, action) => {
    switch (action.type) {
        case ADD_BOOK_TO_STUDENT_FAILED:
            return { ...state, message: action.errorMessage, status: action.status }
        case BOOK_SHARE_LOGIN_VERIFED:
            return {...state, needToLogin:"false" }
        case STUDENT_BOOK_IMPORTED:
            return { ...state, studentBookImported: true, book: [...state.book,action.book.name] };
        case RESET_IMPORT_MODAL:
            return { ...state, studentBookImported: false, bookSearchToggle: false, needToLogin:"true", message:"" };
        case SAVE_IMPORTED_BOOK:
            return { ...state, needToLogin:"false", bookList: [...state.bookList, action.payload.name] }
        case REMOVE_IMPORTED_BOOK:
            return { ...state, needToLogin:"false", bookList: state.bookList.filter((book) => book != action.payload.name) }
        case REMOVE_STUDENT_BOOK:
            return { ...state, needToLogin:"false", book: state.book.filter((b) => b != action.payload.name) }
        case SEARCH_FOR_BOOKS_FOUND:
            return { ...state, bookSearchToggle: true }
        case REMOVE_SEARCH_IMPORTED_BOOK:
            return { ...state, needToLogin: "false", book: state.book.filter((b) => b != action.payload.name) };
        case RESET_BOOKLIST_TEMP:
            return { ...state, book:[], bookList:[] }
        default:
            return state;
    }
}
export const bookShareUsers = (state = [], action) => {
    switch (action.type) {
        case GET_BOOKSHARE_USERS:
            return { data: action.data}
        case ADD_BOOKSHARE_USER:
            return { bookshareUserAdded: true }
        default:
            return state;
    }
}
export const bookShareRequestBooks = (state = [], action) => {
    switch (action.type) {
        case GET_BOOKSHARE_REQUEST_BOOKS:
            return { data: action.data }
        case DELETE_BOOKSHARE_REQUEST_BOOK:
            return { data: state.data.filter(data => data.storyRequestId !== action.id) };
        default:
            return state;
    }
}