import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import GoalDatabankWizard from '../../components/Wizards/GoalDatabank/GoalDatabankWizardBase';
import SkillDatabankWizard  from '../../components/Wizards/SkillDatabank/SkillDatabankWizardBase';
import SkillDatabankList from '../../components/Admin/SkillDatabankList';
import ImportSkill from '../../components/Staff/ImportSkill';
import GoalDatabankList from '../../components/Admin/GoalDatabankList';
import ImportGoal from '../../components/Staff/ImportGoal';
import BrailleSheetsList from '../../components/Admin/BrailleSheetsList';
import BrailleSheetsWizard from '../../components/Wizards/BrailleSheets/BrailleSheetsWizardBase';
import {
  fetchStaffSkillDatabanks,
  fetchGoalDatabanks,
  fetchStaffBrailleSheets,
  fetchUserGoalDatabanks,
  fetchMyGoals, getStaffProfile, fetchGames, fetchLevels
} from '../../../redux/actions';
import { Typography, withStyles, Divider } from '@material-ui/core';
import { Extension, Settings } from '@material-ui/icons';
import baseStyle from '../../../styles/skillDatabanks.js';

class StaffDatabanks extends Component {
  state={
    includeSelf: true
  };

  componentDidMount() {
    this.props.fetchStaffSkillDatabanks(this.props.id);
    this.props.fetchGoalDatabanks('staff', this.props.id);
    this.props.fetchStaffBrailleSheets(this.props.id);
    this.props.fetchUserGoalDatabanks();
    this.props.fetchMyGoals();
    this.props.getStaffProfile(this.props.id);
    this.props.fetchGames();
    this.props.fetchLevels();
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.id !== nextProps.id){
      this.props.getStaffProfile(this.props.id);
    }
  }

  render() {
    const { classes } = this.props;
    const myself = this.props.user.staffId === this.props.id;
    return (
      <section>
        <header className={classes.title}>
          <Extension tabIndex={0} className={classes.icon} fontSize='inherit'/>
          <Typography className={classes.text} variant='h3'>
            <FormattedMessage id='Databanks.title'/>
          </Typography>
        </header>
        <Typography
          className={classes.subtext}
          variant='subtitle1'
        >
          <FormattedMessage id='Databanks.subtitle'/>
        </Typography>
        <GoalDatabankList clickable myself={myself} staff={this.props.staffMember} id={this.props.id} type='staffGoalDatabank' account={this.props.account} history={this.props.history} goalDatabanks={this.props.staffGoalDatabanks}/>
        {
          myself &&
          <div className={classes.skillBtns}>
            <GoalDatabankWizard type='staff' history={this.props.history} id={this.props.id} />
            <ImportGoal staff title={<FormattedMessage id='Databanks.importGoal'/>} history={this.props.history} id={this.props.id}/>
            <ImportGoal staff title={<FormattedMessage id='Databanks.sampleGoal'/>} history={this.props.history} id={this.props.id} sample/>
          </div>
        }
        <br />
        <Divider className={classes.divider}/>
        <div className={classes.subtitle}>
          <Settings className={classes.icon}/>
          <Typography className={classes.text} variant='h3'>
            <FormattedMessage id='Databanks.advanced'/>
          </Typography>
        </div>
        <Typography className={classes.subtext} variant='subtitle1'>
          Below are settings for advanced functionality to help you fine tune your current workflow.
          Here you can create skills and braille sheets that you can reuse throughout the application,
          you can even share your braille sheets with others on our professional network!
        </Typography>
        <SkillDatabankList history={this.props.history} myself={myself} staff={this.props.staffMember} id={this.props.id} type='staffSkillDatabank' account={this.props.account} skillDatabanks={this.props.staffSkillDatabanks}/>
        {
          myself &&
          <div className={classes.skillBtns}>
            <SkillDatabankWizard history={this.props.history} type='staff' id={this.props.id} />
            <ImportSkill title={<FormattedMessage id='Databanks.sampleSkill'/>} history={this.props.history} id={this.props.id}/>
          </div>
        }
        <br />
        <BrailleSheetsList history={this.props.history} myself={myself} staff={this.props.staffMember} id={this.props.id} type='staffBrailleSheet' account={this.props.account} brailleSheets={this.props.staffBrailleSheets}/>
        {
          myself &&
          <div className={classes.skillBtns}>
            <BrailleSheetsWizard history={this.props.history} id={this.props.id} staffMember/>
          </div>
        }
      </section>
    )
  }
}

const mapState = ({ shared: { account, user }, platform: { staffSkillDatabanks, staffGoalDatabanks, staffBrailleSheets, staffMember, goalDatabanks,  games, levels }, social: { myGoals }}) => {
  return {
    account,
    goalDatabanks,
    myGoals,
    staffSkillDatabanks,
    staffGoalDatabanks,
    staffMember,
    user,
    staffBrailleSheets,
    games,
    levels,
  }
};

const mapDispatch = (dispatch, {history}) => {
  return {
    fetchStaffSkillDatabanks: (staffId) => dispatch(fetchStaffSkillDatabanks(staffId, history)),
    fetchGoalDatabanks: (type, id) => dispatch(fetchGoalDatabanks(type, id)),
    fetchStaffBrailleSheets: (id) => dispatch(fetchStaffBrailleSheets(id)),
    fetchUserGoalDatabanks: () => dispatch(fetchUserGoalDatabanks(history)),
    fetchMyGoals: () => dispatch(fetchMyGoals()),
    getStaffProfile: (id) => dispatch(getStaffProfile(id)),
    fetchGames: () => dispatch(fetchGames()),
    fetchLevels: () => dispatch(fetchLevels())
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(StaffDatabanks));