import { HANDLE_ERROR, CLEAR_ERRORS } from '../constants';

export default function (state = {}, action) {
  switch(action.type){
    case HANDLE_ERROR:
      return action.error;
    case CLEAR_ERRORS:
      return {};
    default:
      return state;
  }
};