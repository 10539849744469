import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import baseStyle from '../../../styles/goal';
import {
    getBooksForStudent,
    getAllBooks,
    deleteBook,
    getStudent,
    toggleBookType,
    toggleEchoType,
    getBookshareRequestedBooks,
    fetchStaffDashboard
} from '../../../redux/actions';
import { connect } from 'react-redux';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import EditWizardButton from '../../components/Wizards/Edit/EditWizardButton';
import WizardToggleButton from '../../components/Wizards/WizardToggleButton';
import ImportBook from '../../components/Student/ImportBook';
import BookShare from '../../components/Student/BookShare';
import {
    Dialog, DialogActions, DialogContent, 
    DialogTitle,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Slider,
    Button, DialogContentText, TablePagination
} from '@material-ui/core';
import { Paper, IconButton, Typography, Toolbar, Tooltip } from '@material-ui/core';
import { Delete, PlayCircleFilled, PlayCircleOutline, Refresh, Reorder, CloudDownload, Compare, Close } from '@material-ui/icons';
import arrayMove from 'array-move';
import { toggleBook, getStudentProfile, checkStudentProfile, createStudentProfile, patchStudentProfile, resetScore } from '../../../redux/actions';
import { stableSort, getSorting } from '../../../helpers';
import { ROOT_URL } from '../../../redux/constants';
import styles from '../../../styles/skillList.js';
import { FormattedMessage } from 'react-intl';
import ellipsis from '../../../assets/Loading/Ellipsis.svg';
import BrowseBook from '../../components/Student/BrowseBook';
import { SwitchToggleStyle } from '../../components/Shared/SwitchToggle';
import BookShareRequestUserTable from '../../components/Shared/BookShareRequestUserTable';

const DragHandle = SortableHandle(() => <TableCell><Reorder /></TableCell>);

const SortableItem = SortableElement((props) => {
    const classes2 = SwitchToggleStyle();
    const { value, test, rows, tested, selected, classes, toggle, toggleType, toggleEcho, handleEditClick, handleDelete, handleTest, handleReset } = props;
    return (
        <TableRow
            key={value.id}
        >
            {
                rows.length &&
                rows.map((row, index) => {
                    return (
                        <TableCell
                            key={index}
                            align={row.id == "titleImageSrc" ? "left" : "center"}
                        >
                            {
                                row.id == "titleImageSrc" ?
                                    (value["titleImage"] && value["titleImage"] != null ?
                                        <img src={value["titleImage"]} width={100} height={150} alt={row.name} /> :
                                        value[row.id] && value[row.id] !== null ?
                                            <img src={value["titleImageSrc"]} width={100} height={150} alt={row.name} /> : null) :
                                    value[row.id] ? value[row.id] : ''
                            }
                        </TableCell>
                    )
                })
            }
            <TableCell align='center'>
                <Tooltip title={value.status === 'Processing' ? 'Processing Skill' : value.status === 'Active' ? 'Deactivate Book' : 'Activate Book'}>
                    {
                        value.status === 'Processing'
                            ? <img className={classes.loading} src={ellipsis} height={25} width={25} alt='loading...' />
                            : <Switch
                                checked={value.status === 'Active'}
                                onChange={() => toggle(value)}
                                name='status'
                                aria-label='toggle active or inactive'
                                size="medium"
                            />
                    }
                </Tooltip>
            </TableCell>
            <TableCell align='center'>
                <Tooltip title={value.wordRate === '0' ? 'Sentence' : 'Paragraph'}>
                    {
                        value.wordRate === 'Processing'
                            ? <img className={classes.loading} src={ellipsis} height={25} width={25} alt='loading...' />
                            : <Switch
                                checked={value.wordRate === '75'}
                                onChange={() => toggleType(value)}
                                name='status'
                                classes={{
                                    root: classes2.root,
                                    switchBase: classes2.switchBase,
                                    thumb: classes2.thumb,
                                    track: classes2.track,
                                    checked: classes2.checked
                                }}
                                size="medium"
                                aria-label='toggle Sentence or Paragraph'
                            />
                    }
                </Tooltip>
            </TableCell>
            <TableCell align='center'>
                <Tooltip title="Repeat passages with the computer instead of alternating.  Recommended for young readers.">
                    {
                        value.wordRate == '75' ?
                            <Switch
                                checked={false}
                                disabled='disabled'
                                size="medium"
                                t
                                aria-label='toggle Echo Mode'/>
                            :
                             <Switch
                                checked={value.wordRate === '-1'}
                                onChange={() => toggleEcho(value)}
                                name='status'
                                size="medium"
                                aria-label='toggle Echo Mode'
                            />
                    }
                </Tooltip>
            </TableCell>
            <TableCell align='justify'>
                <div className={classes.buttons}>
                    <Tooltip title={<FormattedMessage id='General.delete' />}>
                        <IconButton aria-label='delete' onClick={() => handleDelete(value)}>
                            <Delete color='secondary' />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Reset Progress'>
                        <IconButton aria-label='reset scores' onClick={() => handleReset(value)}>
                            <Refresh />
                        </IconButton>
                    </Tooltip>
                </div>
            </TableCell>
        </TableRow>
    )
});

// Wrapper to make drag and drop usable
const SortableTable = SortableContainer(({ children }) => {
    return children
});
class Books extends Component {
    constructor(props) {
        super(props);
        this.state = {
            order: 'asc',
            orderBy: 'status',
            selected: {},
            tested: '',
            editing: false,
            items: [],
            reset: [],
            error: {},
            readingTime: 10,
            firstBookImportModal:false,
            page: 0,
            rowsPerPage: 5,
        };
    }

    toggle = (book) => {
        this.props.toggleBook(this.props.id, book.id);
    };

    toggleType = (book) => {
        this.props.toggleBookType(this.props.id, book.id);
    };

    toggleEcho = (book) => {
        this.props.toggleEchoType(this.props.id, book.id);
    }

    handleReset = (skill) => {
        if (window.confirm("Are you sure? This will delete all progress and data.")) {
            resetScore(this.props.id, skill.id)
        }
    };

    componentDidMount() {
        this.props.getStudent(this.props.id);
        this.props.getBooksForStudent(this.props.id);
        this.props.getStudentProfile(this.props.id);
        checkStudentProfile(this.props.id).catch((e) => {
            this.props.createStudentProfile({ studentId: this.props.id, readingTime: 10 });
        })
        this.props.getBookshareRequestedBooks(this.props.id);
        this.props.fetchStaffDashboard(this.props.user.staffId)
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.books !== this.props.books) {
            console.log('cwrp nextprops books', nextProps.books);
            this.setState({ items: nextProps.books });
        }
        if (nextProps.books.length < this.props.books.length) {
            this.props.getAllBooks(this.props.id);
        }
        if (nextProps.studentProfile.readingTime !== this.state.readingTime) {
            nextProps.studentProfile.readingTime && this.setState({ "readingTime": nextProps.studentProfile.readingTime });
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.user !== this.props.user){
            this.props.fetchStaffDashboard(this.props.user.staffId)
        }
        if (this.state.items && this.state.items.length == 1 && prevState.items.length > 1) {
            let book = this.state.items[0];
            if ((!book.status || book.status == "Inactive")) {
                this.props.toggleBook(this.props.id, book.id);
            }
        }
        else if (this.props.books !== prevProps.books) {
            if (this.props.books.length == 1) {
                let book = this.props.books[0];
                if ((!book.status || book.status == "Inactive") && prevProps.books.length === 0) {
                    this.props.toggleBook(this.props.id, book.id);
                }
            }
        }
        if(prevProps.user !== this.props.user){
            this.props.fetchStaffDashboard(this.props.user.staffId)
        }
        if(this.props.books !== prevProps.books || prevProps.staffDashboard !== this.props.staffDashboard){
            if(this.props.staffDashboard[0]){
            const filteredActiveStudent = this.props.staffDashboard.filter(std => std.activeBook == 'Yes');
            if(this.props.books.length === 1 && prevProps.books.length === 0 && filteredActiveStudent.length===0){
                this.setState({firstBookImportModal:true})
            }}
        }
    }
    saveReadingTarget = (newVal) => {
        let studentProfile = this.props.studentProfile;
        studentProfile.readingTime = newVal;
        if (studentProfile.id && studentProfile.id !== 'null') {
            this.props.patchStudentProfile(studentProfile);
        } else {
            this.props.getStudentProfile(this.props.id);
            setTimeout(() => {
                let studentProfile = this.props.studentProfile;
                studentProfile.readingTime = newVal;
                this.props.patchStudentProfile(studentProfile);
            }, 600)
        }
        this.setState({ "readingTime": newVal });
    }

    componentWillUnmount() {
        this.setState({ reset: [] })
    }

    handleDelete = (book) => {
        const { deleteBook } = this.props;
        deleteBook(this.props.id, book)
    };
    handleModalClose=()=>{
        this.setState({firstBookImportModal:false})
    }
    handleChangePage = (_event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    render() {
        const { id, student, bookList, books, bookShareRequestBooks } = this.props;
        const rows = [
            { id: 'name', label: <FormattedMessage id='General.name' /> },
            { id: 'description', label: <FormattedMessage id='General.description' /> },
        ];
        const { classes } = this.props;
        const readingTime
            = this.state.readingTime;
        console.log(readingTime);
        const { orderBy, order, editing, selected, items, tested, page, rowsPerPage } = this.state;
        let uniqueSet = new Set(items.map(JSON.stringify));
        let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
        const newRows = [{ id: 'titleImageSrc', label: 'Image' },
        { id: 'name', label: 'Title' }, { id: 'author', label: 'Author' },
        // { id: 'isbn', label: 'ISBN' },
        ];
        return (
            <>
                <Paper className={classes.list}>
                    <Toolbar classes={{ root: classes.toolbar }}>
                        <Typography noWrap className={classes.header}>
                            <strong><FormattedMessage id='Books.for' /> {this.props.student.firstName} {this.props.student.lastName}</strong>
                        </Typography>
                    </Toolbar>
                    <div className={classes.tableWrapper}>
                        <SortableTable
                            useDragHandle
                        >
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {newRows.length &&
                                            newRows.map(row => {
                                                return (
                                                    <TableCell
                                                        key={row.id}
                                                        align={row.id == "titleImageSrc" ? "left" : "center"}
                                                        sortDirection={orderBy === row.id ? order : false}
                                                    >
                                                        {row.label}
                                                    </TableCell>
                                                )
                                            })
                                        }
                                        <TableCell
                                            key='status'
                                            align="center"
                                            sortDirection={orderBy === 'status' ? order : false}
                                        >
                                            <FormattedMessage id='General.active' />
                                        </TableCell>
                                        <TableCell
                                            key='type'
                                            align="center"
                                            sortDirection={orderBy === 'type' ? order : false}
                                        >
                                            <FormattedMessage id='General.passage.length' /> <br/>
                                            <FormattedMessage id='General.passage.length.sub' />
                                        </TableCell>
                                        <TableCell   
                                            key='type'
                                            align="center"
                                            sortDirection={orderBy === 'type' ? order : false}
                                        >
                                            <FormattedMessage id='General.passage.echo' /> <br/>
                                            <FormattedMessage id='General.passage.echo.sub' />
                                        </TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        stableSort(uniqueArray, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((value, index) => {
                                                return (
                                                    <SortableItem
                                                        error={this.props.error}
                                                        key={value.id}
                                                        value={value}
                                                        index={index}
                                                        classes={classes}
                                                        rows={newRows}
                                                        toggle={this.toggle}
                                                        toggleType={this.toggleType}
                                                        toggleEcho={this.toggleEcho}
                                                        handleDelete={this.handleDelete}
                                                        handleReset={this.handleReset}
                                                    />
                                                );
                                            })
                                    }
                                    <TableRow />
                                </TableBody>
                            </Table>
                        </SortableTable>
                    </div>
                    <TablePagination
                            rowsPerPageOptions={[5,10,15]}
                            component='div'
                            count={uniqueArray.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            labelRowsPerPage={<FormattedMessage id='Datatable.rowsPerPage' />}
                            backIconButtonProps={{
                            'aria-label': 'Previous Page',
                             }}
                            nextIconButtonProps={{
                            'aria-label': 'Next Page',
                            }}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
                </Paper>
                <Dialog open={this.state.error.status === 400}>
                    <DialogContent>
                        <DialogContentText>
                            {this.state.error.data}. Please update your subscription in order to use these products
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color='primary'
                            onClick={() => this.setState({ error: {} })}
                        >
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                {/* <ImportBook title={<FormattedMessage id='Databanks.sampleSkill'/>} history={this.props.history} id={this.props.id}/> */}
                <BrowseBook title={<FormattedMessage id='Databanks.sampleSkill' />} history={this.props.history} id={this.props.id} />
                {bookShareRequestBooks && bookShareRequestBooks.data && bookShareRequestBooks.data.length > 0 &&
                    <BookShareRequestUserTable data={bookShareRequestBooks.data}
                        studentId={this.props.id} />}

                {this.state.firstBookImportModal && <Dialog
                    open={this.state.firstBookImportModal}
                    maxWidth='md'
                    fullWidth
                    onClose={this.handleModalClose}
                    PaperProps={{ classes:{ root: classes.container }}}
              >  <DialogTitle className={ classes.dialogTitle }>
                  <Typography className={classes.enrollTitle} variant='h4' style={{ float: 'left' }}>
                  Almost done!</Typography>
                  <IconButton
                    onClick={this.handleModalClose}
                    className={classes.exitIcon}
                    aria-label="close dialog"
                    style={{ float: 'right' }}
                  >
                    <Close />
                  </IconButton>
                </DialogTitle>
                <DialogContent className={classes.dialog}>
                  <div style={{ padding: '50px', paddingTop: '5px' }}>
                    <Typography variant="subtitle1" style={{fontSize:20}}>
                        Great! You’ve assigned your student a book, now it’s time for them to read, which they can do by logging in with their assigned username and password at,
                        <br/><a href={"http://BuddyBooks.ObjectiveEd.com"} target="_blank" rel="noopener noreferrer">BuddyBooks.ObjectiveEd.com</a>
                        </Typography>
                      <Typography variant="subtitle1" style={{fontSize:20}}>Check out our training video on how to use the reading website,<br /></Typography>
                       <iframe
                        title='guide-video'
                        width="640"
                        height="360"
                        src={`//www.youtube.com/embed/Fg5Qk-p_2A8?start=40`}
                        frameBorder='0'
                        allow='accelerometer; autoplay; encrypted-media; gyroscope;'
                        allowFullScreen
            />
                    <Typography variant="subtitle1" style={{fontSize:20}}><b>Our preferred web-browser:</b> Google Chrome</Typography>
                    <Typography variant="subtitle1" style={{fontSize:20}}><b>Reading Website URL:</b>
                    <a href={"http://BuddyBooks.ObjectiveEd.com"} target="_blank" rel="noopener noreferrer"> BuddyBooks.ObjectiveEd.com</a></Typography>
                    <Typography style={{marginTop:20}}>
                        *Make sure your student enables microphone permissions and sets up their audio settings the first time they go to read.
                    </Typography>
                  </div>
                </DialogContent>
              </Dialog>}
            </>
        )
    }
}


const mapState = ({ platform: { books, bookList, studentProfile, student, bookShareRequestBooks, staffDashboard }, shared: { user } }) => {
    return {
        books,
        bookList,
        student,
        user,
        studentProfile,
        bookShareRequestBooks,
        staffDashboard
    }
};

const mapDispatch = (dispatch, { history }) => {
    return {
        getStudent: (id) => dispatch(getStudent(id)),
        deleteBook: (studentId, book) => dispatch(deleteBook(studentId, book)),
        getAllBooks: (studentId) => dispatch(getAllBooks(studentId)),
        getBooksForStudent: (studentId) => dispatch(getBooksForStudent(studentId)),
        createStudentProfile: (studentProfile) => dispatch(createStudentProfile(studentProfile)),
        patchStudentProfile: (studentProfile) => dispatch(patchStudentProfile(studentProfile)),
        toggleBook: (studentId, bookId) => dispatch(toggleBook(studentId, bookId)),
        toggleBookType: (studentId, storyId) => dispatch(toggleBookType(studentId, storyId)),
        toggleEchoType: (studentId, storyId) => dispatch(toggleEchoType(studentId, storyId)),
        getStudentProfile: (id) => dispatch(getStudentProfile(id)),
        getBookshareRequestedBooks: (id) => dispatch(getBookshareRequestedBooks(id)),
        fetchStaffDashboard: (id) => dispatch(fetchStaffDashboard(id)),
    }
};

export default withStyles(baseStyle)(connect(mapState, mapDispatch)(Books));