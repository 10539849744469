import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Avatar from 'react-avatar';
import { KeyboardArrowRight, ThumbUpAlt, ThumbUpAltOutlined } from '@material-ui/icons';
import {
  IconButton,
  Paper,
  Tooltip,
  Typography,
  Chip,
  withStyles
} from '@material-ui/core';
import { toggleGoalLike } from '../../redux/actions';
import baseStyle from '../../styles/goalPost';
import { getRandomColor } from '../../helpers';

const CollisionLink = (url) => React.forwardRef((props, ref) => (
  <Link innerRef={ref} to={url} {...props} />
));

class GoalPost extends Component {

  state = {
    liked: this.props.goal.likes && this.props.goal.likes.find(like => like.key === this.props.profile.key),
    likeNum: this.props.goal.likes ? this.props.goal.likes.length : 0,
  };

  onLikeClick = () => {
    const { liked, likeNum } = this.state;
    this.props.toggleLike(this.props.goal, this.state.liked);
    this.setState({ liked: !liked, likeNum: liked ? likeNum - 1 : likeNum + 1 })
  };

  render() {
    const { classes, goal, group, feed } = this.props;
    const { likeNum } = this.state;
    const author = feed
      ? goal.author
      : group.members.find(member => member.key === goal.authorKey) || { name: 'Objective Ed', imgSrc: '' };
    const timeZone = moment.tz.guess();
    const date = moment.unix(new Date(goal.date)).tz(timeZone);
    const formattedDate =
      // Check to see if date is within 24 of today. If so, use relative str. If not, use date str
      date.isBetween(moment(), moment().add(1, 'week'))
        ? date.fromNow()
        : `${date.format("MMMM D")} at ${date.format('h:mm a')}`;
    return (
      <Paper className={classes.goal}>
        <Avatar round size={50} className='pic' src={author.imgSrc} name={author.name} alt={author.name}/>
        <Typography className='text' variant='h6'>
          {author.name} added a { this.props.sheet ? 'Braille Sheet' : 'Goal'} named {goal.name}
        </Typography>
        <Typography className='date' variant='caption'>{formattedDate} { feed && 'in'}
          { feed &&
            <div className='subtext'>
              <Chip
                clickable
                size='small'
                style={{ backgroundColor: getRandomColor(goal.group.key)}}
                component={feed ? CollisionLink(`/groups/${goal.group.key}`): ''}
                label={goal.group.name}
              />
            </div>
          }
        </Typography>
        <div className={classes.goalButtons}>
          <IconButton onClick={this.onLikeClick}>
            {
              this.state.liked
                ? <Tooltip title='Unlike Goal'>
                  <ThumbUpAlt className={classes.liked}/>
                </Tooltip>
                : <Tooltip title='Like Goal'>
                  <ThumbUpAltOutlined />
                </Tooltip>
            }
          </IconButton>
          <Typography className='likeNum'>{likeNum}</Typography>
          {
            !feed &&
            <Tooltip title='See more details'>
              <IconButton onClick={() => this.props.selectGoal(goal.key)}>
                <KeyboardArrowRight />
              </IconButton>
            </Tooltip>
          }
        </div>
      </Paper>
    )
  }
}

const mapDispatch = (dispatch) => {
  return {
    toggleLike: (goal, liked) => dispatch(toggleGoalLike(goal, liked))
  }
};

export default connect(null, mapDispatch)(withStyles(baseStyle)(GoalPost));