import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, FormGroup, Typography, TextField, IconButton } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { patchSkill } from '../../../../redux/actions';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../../styles/wizardBase';
import { FormattedMessage } from 'react-intl';

class SkillEdit extends Component {
  state = {
    ...this.props.data
  };

  handleChange = (ev) => {
    this.setState({[ev.target.name]: ev.target.value })
  };

  submit = (ev) => {
    ev.preventDefault();
    this.props.patchSkill(this.props.goal.id, this.state);
    this.props.close();
  };

  render()  {
    const { classes, data } = this.props;
    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        open={this.props.open}
        onClose={this.props.close}
        aria-labelledby='form-dialog-title'
        PaperProps={{ classes:{ root: classes.container }}}
      >
        <DialogTitle disableTypography={true} className={ classes.title } id='form-dialog-title'>
          <Typography className={ classes.titleText } variant='h4'><FormattedMessage id='General.edit'/> {data.name}</Typography>
          <IconButton
            onClick={this.props.close}
            className={ classes.exitIcon }
            aria-label="close dialog"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form className={ classes.root } onSubmit={this.submit}>
            <FormGroup classes={{ root: classes.root }}>
              <TextField
                variant='outlined'
                id='skill-edit-targetpercentage'
                classes={{ root: classes.lastName }}
                label={<FormattedMessage id='General.targetPercentage'/>}
                name='targetPercentage'
                type='number'
                value={this.state.targetPercentage}
                onChange={this.handleChange}
              />
            </FormGroup>
            <DialogActions className={classes.actions}>
              <Button onClick={this.props.close} color='secondary'>
                <FormattedMessage id='General.cancel'/>
              </Button>
              <Button type='submit' color='primary'>
                <FormattedMessage id='General.save'/>
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    )
  }
}

const mapState = ({ platform: { games, goal }}) => {
  return {
    games,
    goal
  }
};

const mapDispatch = (dispatch) => {
  return {
    patchSkill: (goalId, skill) => dispatch(patchSkill(goalId, skill)),
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(SkillEdit));