export default {
  "General.yes": "Oui",
  "General.no": "Non",
  "General.easy": "Facile",
  "General.medium": "Moyen",
  "General.hard": "Difficile",
  "General.firstName": "Prénom",
  "General.lastName": "Nom de famille",
  "General.role": "Rôle",
  "General.name": "Le nom",
  "General.id": "Id",
  "General.area": "Surface",
  "General.timed": "Chronométré?",
  "General.description": "la description",
  "General.difficulty": "Difficulté",
  "General.email": "Couriel",
  "General.edit": "Modifier",
  "General.add": "Ajouter",
  "General.delete": "Effacer",
  "General.save": "Enregistrer",
  "General.cancel": "Annuler",
  "General.close": "Proche",
  "General.targetPercentage": "Pourcentage cible",
  "General.targetPrompts": "Instructions de cibles",
  "General.active": "Actif?",
  "General.active2": "Actif",
  "General.inactive": "Inactif",
  "General.sort": "Trier",
  "General.select": "Choisir",
  "General.games": "Jeux",
  "General.game": "Jeu",
  "General.next": "Suivant",
  "General.levels": "Niveaux",
  "General.back": "Retour",
  "General.submit": "Soumettre",
  "General.test": "Essaie",
  "General.retest": "Réessayer",
  "General.type": "Type",
  "General.search": "Chercher",
  "General.text": "Texte",
  "General.comment": "Commentaire",
  "General.done": "Terminé",
  "General.status": "Statut",
  "General.studentCount": "Nombre d'Étudiants",
  "General.studentsEnabled": "Étudiants activés pour la connexion",
  "General.activate": "Activer",
  "General.suspend": "Suspendre",
  "General.levelVars": "Variables de Niveau",
  "General.value": "Valeur",
  "General.share": "Partager",
  "General.internal": "Interne",
  "General.customer": "Client",
  "General.pilot": "Pilote",
  "General.sales": "Sales",
  "General.viewLess": "Montre Moins",
  "General.viewMore": "Montre Plus",
  "General.view": "Montre",
  "General.join": "inscrire",
  "General.download": "Télécharger",
  "General.continue": "Continue",
  "General.primaryEmail": "Email primaire",
  "General.secondaryEmail": "Email secondaire",
  "General.street1": "Adresse de rue",
  "General.street2": "Adresse 2",
  "General.state": "Etat / Province",
  "General.postalCode": "code postal",
  "General.country": "Pays",
  "General.city": "Ville",
  "General.rate": "Taux",
  "General.startDate": "Date de début",
  "General.cycle": "Cycle de facturation",
  "General.ok": "d'accord",
  "General.my": "Mon",
  "General.billableStudent": "Nombre d'étudiants facturables",
  "General.studentsBillable": "Compte de facturation étudiant",
  "General.required": "Tous les champs sont requis",
  "404.1": "Euh oh, quelque chose s'est mal passé.",
  "404.2": "Il semble que vous ayez rencontré une erreur.",
  "404.3": "Veuillez réessayer ou utilisez le bouton d'accueil dans l'en-tête pour revenir à votre page d'accueil.",
  "Datatable.rowsPerPage": "Lignes par page:",
  "Datatable.showEdit": "Afficher les options",
  "Datatable.hideEdit": "Masquer les options",
  "Datatable.support": "Utilisateurs de Support",
  "Datatable.resetPW": "réinitialiser",
  "Datatable.resetConfirm": "Êtes-vous sûr de vouloir réinitialiser",
  "Datatable.resetConfirm2": "mot de passe?",
  "Datatable.levels": "Modèles de Niveau",
  "Header.platform": "Application",
  "Header.network": "Réseau",
  "Header.profile": "Mon profil",
  "Header.logout": "Déconnexion",
  "Header.support": "Compte d'assistance",
  "Sidebar.title": "Administrateur",
  "Sidebar.title.home": "L'accueil",
  "Sidebar.dashboard": "Tableau",
  "Sidebar.students": "Étudiants",
  "Sidebar.progress": "Le progrès",
  "Sidebar.staff": "Personnel",
  "Sidebar.schools": "Écoles",
  "Sidebar.databanks": "Données",
  "Sidebar.config": "Config",
  "Sidebar.profile": "Profil",
  "Sidebar.account": "Compte",
  "Sidebar.accounts": "Comptes",
  "Sidebar.users": "Utilisateurs",
  "Sidebar.guardians": "Guardiens",
  "Sidebar.goals": "Objectifs",
  "Sidebar.books": "Livres",
  "Sidebar.games": "Jeux",
  "Sidebar.help": "l' assistance",
  "Sidebar.games.entertainment": "Jeu de divertissement",
  "Sidebar.backToStudents": "Retour aux étudiants",
  "Sidebar.backToStaff": "Retour au personnel",
  "Sidebar.backTo": "Retour aux",
  "Sidebar.student's": "étudiants",
  "Login.button": "S'identifier",
  "Login.username": "Nom d'utilisateur",
  "Login.password": "Mot de passe",
  "Login.confirmPassword": "Confirmer le mot de passe",
  "Login.mustMatch": "Les mots de passe doivent correspondre",
  "Login.resetText": "Avez-vous oublié votre mot de passe?",
  "Login.resetLink": "Cliquez ici pour restaurer votre mot de passe",
  "Dashboard.title": "Tableau de bord",
  "Dashboard.possessive": "Tableau de bord",
  "StaffMembers.title": "Les membres du personnel",
  "StaffMembers.AddBtn": "Ajouter du personnel",
  "Students.title": "Tous les étudiants",
  "Students.AddBtn": "Ajouter un étudiant",
  "Students.DeletedStudents": "Élèves supprimés",
  "Databanks.title": "Données",
  "Databanks.subtitle": "Sur cette page, vous trouverez une liste de tous vos objectifs, compétences et feuilles de braille. Vous pouvez soit ajouter vos propres objectifs, compétences et feuilles de braille à l'aide de nos créateurs pas à pas, ou peut importer des objectifs et des compétences de vos groupes.",
  "Databanks.goalDB": "Objectif Données",
  "Databanks.addGoalDB": "Ajouter Objectif Données",
  "Databanks.importGoal": "importer Objectif",
  "Databanks.skillDB": "Compétence Données",
  "Databanks.addSkillDB": "Ajouter Compétence Données",
  "Databanks.importSkill": "importer Compétence",
  "Databanks.brailleSheets": "Feuilles de Braille",
  "Databanks.importSheet": "importer Feuille de Braille",
  "Databanks.addBrailleSheets": "Ajouter feuille de Braille",
  "Databanks.advanced": "Paramètre Avancé",
  "Databanks.sampleGoal": "montrer des exemples d'objectifs",
  "Chart.hoursPlayed.title": "Heures Jouées",
  "Chart.hoursPlayed.bar": "Progrès par étudiant",
  "Chart.percentLine.title": "Pourcentage à Cibler",
  "Chart.percentBar.title": "Pourcentage à Cibler pour la Compétence Active",
  "Chart.empty": "Aucune donnée d'étudiant à montrer",
  "Chart.empty.noStudents": "Une fois que votre administrateur a affecté des étudiants à votre compte, vous verrez leur progression ici",
  "Chart.allTime": "Tous le temps",
  "Chart.days": "journées",
  "Goals.title": "Objectifs",
  "Goals.for": "Objectifs pour",
  "Goals.add": "Ajouter Objectif",
  "Goals.allGoals": "Tous les Objectifs",
  "Goal.Chart.empty": "Veuillez sélectionner une compétence pour voir les données de score...",
  "Skill.add": "ajouter des compétences au objectif",
  "Skill.for": "Compétences pour",
  "Profile.settings": "Paramètres de Profil",
  "Profile.personal": "Informations Personnelles",
  "Profile.login": "Cet utilisateur devrait-il pouvoir se connecter?",
  "Profile.studentLogin": "Cet l'étudiant devrait-il pouvoir se connecter?",
  "Profile.helperText": "L'étudiant doit avoir un gamertag pour se connecter",
  "Profile.StudentConfig": "Config. Étudiante",
  "Profile.backgroundMusicLevel": "Niveau de musique de fond",
  "Profile.curtainOpacity": "Opacité du rideau",
  "Profile.curtain": "Activer le rideau?",
  "Profile.addFreeTime": "Ajouter du Temps Libre",
  "Profile.changeFreeTime": "Changer le Temps Libre",
  "Profile.playTime": "Récréation",
  "Profile.entertainmentTime": "Temps de Divertissement",
  "Profile.level": "Niveau jusqu'à la récompense",
  "Profile.levels": "Niveaux jusqu'à la récompense",
  "Profile.braille": "Professeur de braille",
  "Profile.AT": "Tech Assistive Spécialiste",
  "Profile.OM": "Certifié O & M",
  "Profile.TVI": "Certifié TVI",
  "Profile.specialEd": "Spécial Ed Professeur",
  "Profile.publicSchool": "District scolaire public",
  "Profile.privateSchool": "École privée",
  "Profile.DOE": "Etat DOE",
  "Profile.NFP": "Agence à but non lucratif",
  "Profile.FP": "Agence à but lucratif",
  "Profile.self": "Travailleur indépendant",
  "Support.createAccount": "Créer un compte",
  "Support.createUser": "Créer un utilisateur de support",
  "EditStaff.promoteDescription": "Les administrateurs sont des utilisateurs qui peuvent ajouter d'autres enseignants et membres du personnel. Ils ont accès à la page sur laquelle vous vous trouvez actuellement. Cette action n'est pas réversible\n",
  "EditStaff.promote": "Promouvoir en administrateur",
  "EditStaff.cannotPromote": "Cet utilisateur appartient à plusieurs organisations et ne peut pas être promu administrateur",
  "Wizard.Maps.questionsPerRound": "Questions Per Round",
  "Wizard.Maps.PinQuestion": "Pin",
  "Wizard.WordHunt.IntersectionQuestion": "Intersection",
  "Wizard.Maps.StreetQuestion": "Street",
  "Wizard.Maps.RegionQuestion": "Region",
  "Wizard.Maps.Type": "What Type of Questions Should Be Asked?",
  "Wizard.Maps.QuestionType": "Question Type",
  "Wizard.Maps.Shuffle": "Shuffle Questions?",
  "Wizard.Maps.Questions": "Questions",
  "Wizard.Maps.Question": "Question",
  "Wizard.Maps.Street": "Street",
  "Wizard.Maps.Intersection1": "First Street",
  "Wizard.Maps.Intersection2": "Second Street",
  "Wizard.Maps.Pin": "Pin",
  "Wizard.Maps.Region":"Region",
  "Wizard.Maps.title": "Map Explore",
  "Wizard.Maps.subtitle": "Map Explore is an educational tool for practicing mental mapping skills. Please provide an address in the bar below to set the center of your map. Then add markers to the map by pressing the add pin button. You can drag and name these pins to make your map more accessible or quiz the student to find certain locations. The student will track streets by running their finger over the map and pins you add. You can zoom in and out with the map but try not to zoom out too far as this makes it harder for the student to track streets and locations.",
  "Wizard.Maps.ModeType": "Game Mode",
  "Wizard.Maps.MapName": "Name of Map",
  "Wizard.title": "Créateur",
  "Wizard.skillDB.title": "Créateur de Données de Compétences",
  "Wizard.restore.title": "Restaurer les étudiants",
  "Wizard.restore.description": "Vous pouvez restaurer ou supprimer définitivement des étudiants ici.",
  "Wizard.skillDB": "Bienvenue dans le créateur de données de compétences! Cela vous guidera tout au long du processus de création d'une nouvelle banque de données de compétences pour un jeu.",
  "Wizard.loading": "Please wait while the server handles your request.",
  "Wizard.skillDB.subtitle1": "Veuillez inscrire le nom et la description de la banque de données de compétences",
  "Wizard.skillDB.subtitle2": "Veuillez sélectionner le jeu auquel vous souhaitez associer cette banque de compétences",
  "Wizard.skillDB.levelSelect": "Veuillez sélectionner le modèle de niveau que vous souhaitez utiliser",
  "Wizard.skillDB.levelConfig": "Configurez ce niveau",
  "Wizard.Support.title": "Créateur de Compte",
  "Wizard.Support": "Bienvenue sur le compte du support au créateur! Cela vous guidera tout au long du processus de création d'un nouveau compte de support.",
  "Wizard.Support.shortName": "Nom court",
  "Wizard.Support.studentCount": "Nombre d'étudiants",
  "Wizard.Support.parentAccount": "ID de compte parent",
  "Wizard.SupportUser.title": "Créateur d'utilisateurs du support",
  "Wizard.SupportUser": "Bienvenue dans le Créateur d'utilisateurs du support! Cela vous guidera tout au long du processus de création d'un nouvel utilisateur de support.",
  "Wizard.Barnyard.direction": "Type de direction",
  "Wizard.Barnyard.prompt": "Toujours rapide?",
  "Wizard.Barnyard.mistakes": "Nombre d'erreurs avant de demander",
  "Wizard.Barnyard.animal": "Nombre d'invites d'animaux au début",
  "Wizard.AudioMemory.collection": "Collection sonore à utiliser",
  "Wizard.AudioMemory.animals": "Animaux",
  "Wizard.AudioMemory.instruments": "Instruments",
  "Wizard.AudioMemory.vehicles": "Véhicules",
  "Wizard.AudioMemory.toys": "Jouets",
  "Wizard.AudioMemory.indoors": "Á l'intérieur",
  "Wizard.AudioMemory.length": "Durée du jeu",
  "Wizard.AudioMemory.opponent": "Difficulté de l'adversaire",
  "Wizard.AudioMemory.helperText": "Le guépard est le plus facile, la tortue est le plus difficile",
  "Wizard.AudioMemory.matches": "Nombre de correspondances à l'écran",
  "Wizard.AudioMemory.error": "Doit être un nombre entre 1-8",
  "Wizard.AudioMemory.sound": "Son à son",
  "Wizard.AudioMemory.tap": "Contrôle du robinet",
  "Wizard.Sounds.error": "Le nombre maximum de mots est 6",
  "Wizard.Sounds.numWords": "Nombre de mots",
  "Wizard.Sounds.unlimited": "Un nombre illimité de planches?",
  "Wizard.Sounds.sounds": "Nombre de sons",
  "Wizard.Sounds.errorSound": "Le nombre maximum de son est 6",
  "Wizard.Sounds.numBoards": "Nombre de planches dans un tour",
  "Wizard.Gesture.title": "Gestes Rapide",
  "Wizard.Gesture.subtitle": "Gestes Rapide est un jeu dans lequel les élèves sont invités à exécuter des gestes tels qu'ils sont annoncés.",
  "Wizard.Gesture.length": "Longueur du tour",
  "Wizard.Gesture.music": "Musique",
  "Wizard.Gesture.japan": "Japonais",
  "Wizard.Gesture.random": "Fait au Hasard",
  "Wizard.Gesture.numRounds": "Nombre de tours",
  "Wizard.Gesture.startingCount": "Nombre de gestes de départ",
  "Wizard.Gesture.gestures": "Les gestes que vous souhaitez utiliser.",
  "Wizard.Simon.title": "Simon",
  "Wizard.Simon.subtitle": "Simon est un jeu qui demande aux étudiants de se rappeler une liste de gestes et de les exécuter dans l’ordre lorsque cela leur est demandé.",
  "Wizard.BrailleQuiz.title": "Quiz de Braille",
  "Wizard.BrailleQuiz.subtitle": "Quiz de Braille est un jeu dans lequel les élèves doivent répondre à des questions de type interrogation en tapotant deux fois le mot correct sur la feuille de braille. Vous pouvez ajouter vos propres questions ou importer des questions.",
  "Wizard.BrailleQuiz.select": "Sélectionnez une feuille de braille à utiliser",
  "Wizard.BrailleQuiz.empty": "Aucune feuille de braille sélectionnée",
  "Wizard.BrailleQuiz.numQuestions": "Questions par jeu",
  "Wizard.BrailleQuiz.error": "Les questions par jeu doivent être inférieures au nombre total de questions!",
  "Wizard.BrailleQuiz.time": "Temps par question: (en secondes)",
  "Wizard.BrailleQuiz.shuffle": "Mélanger l’ordre des questions?",
  "Wizard.BrailleQuiz.numOpponents": "Nombre d'adversaires",
  "Wizard.BrailleQuiz.question": "Question",
  "Wizard.BrailleQuiz.answer": "Solution",
  "Wizard.BrailleQuiz.import": "Importer une question",
  "Wizard.BrailleQuiz.addQuestion": "Ajouter une question",
  "Wizard.BrailleQuiz.importText": "Veuillez utiliser les groupes de votre feuille ci-dessous pour rechercher des questions.",
  "Wizard.BrailleQuiz.emptyQuestions": "There are no questions for that group.",
  "Wizard.Scramble.title": "Brouillé de Braille",
  "Wizard.Scramble.subtitle": "Brouillé de Braille est un jeu d’orthographe et de vocabulaire dans lequel un nombre de lettres est présenté aux élèves pour trouver autant de mots que possible.",
  "Wizard.Scramble.set": "Jeu de mots",
  "Wizard.Scramble.number": "Nombre de mots jusqu'au prochain brouillage",
  "Wizard.Scramble.seconds": "Secondes par brouillage",
  "Wizard.WordHunt.title": "Chasse aux Braille",
  "Wizard.WordHunt.subtitle": "Chasse aux Braille est un jeu dans lequel les étudiants doivent trouver et sélectionner des mots en fonction des questions que vous avez choisies.",
  "Wizard.WordHunt.find": "L'étudiant devrait trouver",
  "Wizard.WordHunt.one": "un",
  "Wizard.WordHunt.all": "tout",
  "Wizard.WordHunt.words": "mots",
  "Wizard.WordHunt.word": "mot",
  "Wizard.WordHunt.letters": "des lettres",
  "Wizard.WordHunt.letter": "lettre",
  "Wizard.WordHunt.starting": "commençant par",
  "Wizard.WordHunt.ending": "se terminant en",
  "Wizard.WordHunt.containing": "contenant",
  "Wizard.WordHunt.need": "Ils auront besoin",
  "Wizard.WordHunt.correct": "Corriger pour passer",
  "Wizard.WordHunt.feedback": "Comment les commentaires devraient-ils être donnés?",
  "Wizard.WordHunt.missed": "Lire seulement les réponses manquées",
  "Wizard.WordHunt.readAll": "Tous les mots lus",
  "Wizard.WordHunt.readNum": "Lire le nombre de manqués / corrects",
  "Wizard.Braille.contractions": "Contrôles Feuille de Braille",
  "Wizard.Braille.selectContract": "Sélectionnez les abréviations que vous souhaitez utiliser",
  "Wizard.Braille.check": "choisir tous",
  "Wizard.Braille.uncheck": "choisir aucun",
  "Wizard.Braille.sheet": "Feuille de Braille",
  "Wizard.Braille.import": "Importer",
  "Wizard.Braille": "Bienvenue dans le créateur de feuilles de braille! Cela vous guidera tout au long du processus de création d'une nouvelle feuille de braille.",
  "Wizard.Braille.page2": "Sur cette page, veuillez inscrire des informations sur la feuille de Braille que vous souhaitez créer, puis sélectionnez les jeux que vous souhaitez utiliser avec cette feuille de Braille.",
  "Wizard.Braille.text": "Veuillez saisir le texte de votre feuille braille",
  "Wizard.Braille.error": "Le texte est trop long.",
  "Wizard.Braille.contract": "Inclure les abréviations",
  "Wizard.Braille.upperCase": "Autoriser les majuscules?",
  "Wizard.Braille.games": "Jeux utilisant cette fiche",
  "Wizard.Goal.title": "Créateur d'objectifs",
  "Wizard.Goal": "Bienvenue dans le créateur d'objectifs! Cela vous guidera tout au long du processus de création d'un nouvel objectif pour un étudiant.",
  "Wizard.GoalDB.title": "Créateur de données d'objectif",
  "Wizard.GoalDB": "Bienvenue dans le créateur de données d'objectif! Cela vous guidera tout au long du processus de création d'un nouvel données d’objectif.",
  "Wizard.Skill": "Ajouter une Compétence",
  "Wizard.Skill.select": "Veuillez sélectionner une compétence dans le tableau ci-dessous",
  "Wizard.Skill.different": "Sélectionnez une compétence différente",
  "Wizard.GameField": "Assistant de carte de terrain de jeu",
  "Wizard.Staff.invite": "Inviter",
  "Wizard.Staff.inviteText": "S'il vous plaît entrez l'email pour envoyer l'invitation à",
  "Wizard.Staff.title": "Créateur de membre du personnel",
  "Wizard.Staff": "Bienvenue au créateur de membre du personnel! Cela vous guidera tout au long du processus de création d'un nouveau membre du personnel.",
  "Wizard.Staff.sent": "Invitation envoyée!",
  "Wizard.Staff.error": "Une erreur s'est produite lors de l'envoi de l'invitation. Veuillez réessayer.",
  "Wizard.Staff.select": "Veuillez sélectionner un spécialiste dans le tableau ci-dessous",
  "Wizard.Staff.empty": "Vous ne voyez pas votre spécialiste?",
  "Wizard.Staff.inviteLink": "Invitez-les ici",
  "Wizard.Staff.role": "entrer le rôle de l'utilisateur",
  "Wizard.Staff.email": "Veuillez saisir l'adresse courriel du personnel.",
  "Wizard.Staff.email2": "Veuillez saisir l'adresse courriel du spécialiste.",
  "Wizard.Staff.emailSent": "Un message sera envoyé à l’adresse courriel donné avec des instructions sur la configuration d'un mot de passe pour le compte.",
  "Wizard.Staff.confirm": "Assurez-vous que tout est correct avant de soumettre!",
  "Wizard.Staff.specialist": "On dirait qu'il y a déjà un spécialiste dans notre système avec cette information! Nous avons téléchargé leur compte et y attacherons les étudiants que vous avez répertoriés.",
  "Wizard.Staff.selectStudent": "Sélectionnez les étudiants que vous souhaitez ajouter",
  "Wizard.Student.restore": "Restaurer l'élève",
  "Wizard.Student.title": "Créateur d’étudiant",
  "Wizard.Student.Staff": "Sélectionnez le personnel pour l'étudiant",
  "Wizard.Student": "Bienvenue chez le créateur d'étudiant! Cela vous guidera à travers le processus de création d'un nouvel étudiant.",
  "Wizard.Level": "Bienvenue dans le créateur de modèles de niveau! Cela vous guidera tout au long du processus de création d'un nouveau modèle de niveau.",
  "Wizard.Level.select": "Veuillez sélectionner le jeu pour lequel vous créez un niveau",
  "Wizard.Level.static": "Statique",
  "Wizard.Level.prompt": "Demande",
  "Wizard.Level.number": "Nombre",
  "Wizard.Asteroids.requiredAccuracyAngle": "Angle de précision requis",
  "Wizard.Asteroids.requiredAccuracyAngle.err": "Ne peut pas dépasser 180 degrés",
  "Wizard.Asteroids.spawnRadius": "Taille du champ d'astéroïdes",
  "Wizard.Asteroids.spawnRadius.err": "Doit être un nombre entier compris entre 8 et 20",
  "Wizard.Asteroids.spawnRadius.details": "Le rayon du cercle autour du joueur où apparaissent les astéroïdes",
  "Wizard.Asteroids.radiusRecArc": "Nous recommandons une taille de 10",
  "Wizard.Asteroids.radiusRecPass": "Nous recommandons une taille de 15-20",
  "Wizard.Asteroids.speed": "À quelle vitesse les astéroïdes devraient-ils voyager?",
  "Wizard.Asteroids.timeMinToMax": "Temps entre min et max",
  "Wizard.Asteroids.timeMinToMax.details": "Nombre de secondes qu'il faut à l'astéroïde pour qu'il se situe entre ses valeurs minimale et maximale",
  "Wizard.Asteroids.arcMovement": "L'astéroïde doit-il balayer?",
  "Wizard.Asteroids.minMaxAngleArc": "Quelle est la largeur du balayage de l'astéroïde?",
  "Wizard.Asteroids.minMaxAngleArc.details": "Une valeur de 0 entraînera la création d'un astéroïde fixe. Une valeur maximale de 180.",
  "Wizard.Asteroids.minMaxAngleArc.err": "Doit être un nombre entier compris entre 0 et 180",
  "Wizard.Asteroids.leftRightLimited": "Voulez-vous contrôler le mode d'affichage des astéroïdes?",
  "Wizard.Asteroids.leftRightLimited.details": "Si c'est le cas, l'emplacement de l'astéroïde sera basé sur la direction dans laquelle le joueur se trouve actuellement. Sinon, ils apparaîtront au hasard.",
  "Wizard.Asteroids.leftRatio": "Définir le rapport entre les apparences gauche / droite",
  "Wizard.Asteroids.angleOptions": "Options des angles d'apparence",
  "Wizard.Asteroids.angleOptions.details": "Ajoutez des angles séparés par des virgules. Chaque angle doit être compris entre 0 et 180",
  "Wizard.Asteroids.asteroidTimeout": "Combien de temps avant qu'un astéroïde disparaisse",
  "Wizard.Asteroids.asteroidTimeout.details": "Combien de temps après l'affichage de l'astéroïde disparaîtra-t-il automatiquement",
  "Wizard.Asteroids.maxShotsPerAsteroid": "Nombre maximal de coups par astéroïde",
  "Wizard.Asteroids.maxShotsPerAsteroid.details": "Nombre de coups sur un astéroïde donné avant qu'il ne disparaisse automatiquement",
  "Wizard.Asteroids.gameOverType": "Qu'est-ce qui termine le jeu?",
  "Wizard.Asteroids.gameOverType.timeout": "Une heure définie",
  "Wizard.Asteroids.gameOverType.limitedShots": "Un nombre défini de coups tirés",
  "Wizard.Asteroids.gameOverType.limitedAsteroids": "Un nombre défini d'astéroïdes",
  "Wizard.Asteroids.gameOverType.missed": "Un nombre défini d'astéroïdes manqués",
  "Wizard.Asteroids.gameOverCount.1": "Durée de la partie (en secondes)",
  "Wizard.Asteroids.gameOverCount.2": "Nombre total de coups de feu",
  "Wizard.Asteroids.gameOverCount.3": "Total des astéroïdes vus",
  "Wizard.Asteroids.gameOverCount.4": "Nombre d'astéroïdes manqués",
  "Wizard.Asteroids.gameOverCount.err": "Le nombre maximal de coups par astéroïde ne peut pas dépasser le nombre de coups limité.",
  "Wizard.Account.billing": "S'il vous plaît entrer vos informations de facturation",
  "Wizard.Game.add": "Ajouter un jeu de divertissement",
  "Config.game": "Ajouter un champ de jeu",
  "Config.list": "Ajouter la liste",
  "Level.title": "Assistant de modèle de niveau",
  "Level.Audio.timed": "Jeu chronométré?",
  "Level.Audio.matches": "Nombre de correspondances à l'écran",
  "Level.Gesture.spawn": "Geste pondéré",
  "Level.Gesture.difficulty": "Progression de difficulté",
  "Level.add": "Ajouter des variables à un",
  "Level.add2": "niveau",
  "Level.addBtn": "ajouter du niveau",
  "Level.testBtn": "Niveau de Test",
  "Level.variables": "Variables",
  "Level.confirm": "S'il vous plaît confirmer que tout ce qui suit est correct",
  "Share.group": "Avec quel groupe voulez-vous partager?",
  "Config.title": "Variables de configuration",
  "Tutorial.deactivate": "réinitialiser le didacticiel",
  "MyGroups.title": "Mes Groupes",
  "Groups.public": "Publique",
  "Groups.private": "Privé",
  "GroupDetail.startDate": "Date de début",
  "SocialGoals.title": "Tendance D’Objectifs",
  "MemberList.title": "Membres",
  "MemberList.invite": "Inviter des membres",
  "MemberList.admin": "Administrateur",
  "MemberList.mod": "Modérateur",
  "MemberList.removeAdmin": "Supprimer le statut d'administrateur",
  "MemberList.removeMod": "Supprimer le statut du modérateur",
  "MemberList.makeMod": "Faire modérateur",
  "MemberList.makeAdmin": "Faire Administrateur",
  "MemberList.leave": "Quitter le groupe",
  "MemberList.remove": "Supprimer du groupe",
  "AddPost.title": "Ajouter un Message",
  "AddPost.addTags": "Ajouter des balises",
  "AddPost.submit": "Soumettre",
  "AddGroup.title": "Créer un Nouveau Groupe",
  "AddGroup.text": "Les groupes sont parfaits pour partager des compétences et rester en contact avec des groupes de professionnels comme vous. Partagez des photos et des vidéos, prenez des conversations, définissez des objectifs, etc.",
  "AddGroup.name": "Nommez votre groupe",
  "AddGroup.description": "Donnez à votre groupe une description",
  "AddGroup.invite": "Inviter des membres",
  "AddGroup.members": "Inscrire le nom du membre...",
  "AddGroup.privacy": "Sélectionnez le niveau de confidentialité",
  "AddReply.comment": "Commente ici...",
  "Announcements.title": "Annonce",
  "Unlike": "Contrairement à l'objectif",
  "Like": "Comme objectif",
  "Shared": "Partagé",
  "Group.image.title": "Télécharger une Image de Groupe",
  "Group.image.text": "Faites glisser une photo dans la zone ou utilisez le bouton pour modifier la photo de profil de votre groupe.",
  "Group.image.change": "Changer l'image",
  "Group.startDate": "Date de début",
  "Group.created": "Créé par",
  "GroupEdit.toggle": "Ce groupe est-il privé?",
  "GroupFeed.more": "montrer plus de messages",
  "Profile.image.title": "Télécharger l'image du profil",
  "Profile.image.text": "Faites glisser une photo dans la zone ou utilisez le bouton pour modifier la photo de votre profil.",
  "Paddle.goalAdded": "Goal Added!",
  "Paddle.removeStudent": "Remove Student",
  "Paddle.error.first": "First name required",
  "Paddle.error.last": "Last name required",
  "Paddle.error.tag": "Gamer Tag required",
  "Paddle.error.unique": "Gamer Tag must be unique",
  "Paddle.error.password": "Password is required",
  "Paddle.error.match": "Passwords must match",
  "Paddle.enroll": "Enroll Student",
  "Paddle.clear": "Clear Student Data",
  "Paddle.start": "Get Started",
  "Paddle.checkout": "Checkout",
  "Paddle.email": "Subscription Notification Email",
  "Paddle.type": "Subscription Type",
  "Paddle.studentNum": "Number of Students",
  "Paddle.cost": "SUBSCRIPTION COST",
  "Paddle.studentCount.title": "Please enter the email that you would like to receive all subscription details and notifications to and enter the number of students that you would like to enroll:",
  "Paddle.terms": "Please read and accept the terms of use:",
  "Terms.decline": "I decline",
  "Terms.accept": "I accept the terms",
  "SliderListManagement.Add": "Add Story",
  "SliderListManagement.Title": "Story List Management",
  "Wizard.SliderListManagement": "Story List Management Creator",
  "DataTable.SliderListManagement": "Story List Management ",
  "SliderSeriesManagement.Add": "Add Series",
  "SliderSeriesManagement.Title": "Story Series Management",
  "Wizard.SliderSeriesManagement": "Story Series Management Creator",
  "DataTable.SliderSeriesManagement": "Story Series Management ",
  "seriesStoryList.Add": "Add Story",
  "seriesStoryList.Title": "Story List",
}