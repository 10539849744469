import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, SnackbarContent, IconButton, InputAdornment, Button, Card, Typography,  FormGroup, Link, withStyles } from '@material-ui/core';
import { Visibility, VisibilityOff, Close } from '@material-ui/icons';
import { login, clearErrors, getCourse } from '../../../../redux/actions';
import logo from '../../../../assets/BrandAssets/Logo/OBJECTIVEED-Logo-Vertical-1.png';
import styles from '../../../../styles/login';
import { FormattedMessage } from 'react-intl';


class CourseRegistration extends Component {

    state = {
    };

    componentDidMount(){
        document.body.style.backgroundColor = '#310073';
        console.log(this.props.id);
        this.props.getCourse(this.props.id);
    }
    login = () => {
        this.props.history.push('/login?register=true&courseKey='+this.props.id);
    }

    register = () => {
        this.props.history.push('/registration?register=true&courseKey='+this.props.id);
    }




    render(){
        const { classes, error, course } = this.props;
        const registration = true;
        return (
            <Card className={ classes.loginContainer }>
                <div className={ classes.loginForm }>
                    <img
                        className={ classes.loginLogo }
                        alt='login-logo'
                        src={logo}
                    />
                    <div>
                        <Typography gutterBottom variant='h6'>Thank you for registering for an ObjectiveEd Course.  Please login or register below</Typography>
                        <div style={{padding:20}}>
                        <Typography gutterBottom align='center' variant='h7'>
                            <b>Course Name:</b> {course.name}
                        </Typography>
                        </div>
                        <div>
                        <Typography gutterBottom align='center' variant='h7'>
                            <b>Course Description:</b> {course.description}
                        </Typography>
                        </div>
                    </div>


                    <div className={ classes.loginBtns }>
                        <Button
                            id='login-button'
                            variant='contained'
                            onClick={this.login}
                        >
                            <FormattedMessage id='Login.button'/>
                        </Button>
                        {
                            registration &&
                            <Button
                                id='login-button'
                                variant='contained'
                                onClick={this.register}
                            >
                                Register
                            </Button>
                        }
                    </div>
                </div>
            </Card>
        )
    }
}

const mapState = ({ platform: { course }}) => {
    return {
        course
    }
};

const mapDispatch = (dispatch) => {
    return {
        getCourse: (courseKey) => dispatch(getCourse(courseKey)),
        clearErrors: () => dispatch(clearErrors())
    }
};

export default connect(mapState, mapDispatch)(withStyles(styles)(CourseRegistration));