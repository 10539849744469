const planCouponDict = [
  {
    "value": "DYSLEXIA-F",
    "plans": [
      { "id": 794071, "coupon": "DYSLEXIA-F", "message":"Your discount of $10/month/child will be automatically applied at checkout.  The price is $9.99/month/child for the first 3 months.  After the 3rd month, the price is $19.99/month/child.  You can cancel at any time."}
    ]
  },
  {
    "value": "DYSLEXIA-AD",
    "plans": [
      { "id": 794071, "coupon": "DYSLEXIA-AD", "message":"Your discount of $10/month/child will be automatically applied at checkout.  The price is $9.99/month/child for the first 3 months.  After the 3rd month, the price is $19.99/month/child.  You can cancel at any time."}
    ]
  },
  {
    "value": "CHANNIE",
    "plans": [
      {"id": 795046, "coupon":"CHANNIE", "message": "Your discount of 25% off of the annual price will be automatically applied at checkout."}
    ]
  },
  {
    "value": "DYSLEXIA-G",
    "plans": [
      { "id": 794071, "coupon": "DYSLEXIA-AD", "message":"Your discount of $10/month/child will be automatically applied at checkout.  The price is $9.99/month/child for the first 3 months.  After the 3rd month, the price is $19.99/month/child.  You can cancel at any time."}
    ]
  },

  {
    "value": "DYSTEST-G",
    "plans": [
      { "id": 794071, "coupon": "DYSTEST-G", "message":"Your discount of $10/month/child will be automatically applied at checkout.  The price is $9.99/month/child for the first 3 months.  After the 3rd month, the price is $19.99/month/child.  You can cancel at any time."}
    ]
  },

  {
    "value": "AUDIOBOOK-G",
    "plans": [
      { "id": 794071, "coupon": "AUDIOBOOK-G", "message":"Your discount of $10/month/child will be automatically applied at checkout.  The price is $9.99/month/child for the first 3 months.  After the 3rd month, the price is $19.99/month/child.  You can cancel at any time."}
    ]
  },
  {
    "value": "TUTOR2023",
    "plans": [
      { "id": 794071, "coupon": "TUTOR2023", "message":"Your discount of $10/month/child will be automatically applied at checkout.  The price is $9.99/month/child for the first 3 months.  After the 3rd month, the price is $19.99/month/child.  You can cancel at any time."}
    ]
  },
  {
    "value": "IDA2023",
    "plans": [
      { "id": 794071, "coupon": "TUTOR2023", "message":"Your discount of $10/month/child will be automatically applied at checkout.  The price is $9.99/month/child for the first 3 months.  After the 3rd month, the price is $19.99/month/child.  You can cancel at any time."}
    ]
  },
  {
    "value": "TRIAL99",
    "plans": [
      { "id": 843858, "coupon": "", "message": "After your 14 day free trial you will be billed for:"}]
  }
];

export default planCouponDict;