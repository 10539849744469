import { FETCH_ALL_SKILL_DATABANKS, CREATE_SKILL_DATABANK, DELETE_SKILL_DATABANK, PATCH_SKILL_DATABANK, FETCH_STAFF_SKILL_DATABANKS, CLEAR_DATABANK, CREATE_STAFF_SKILL_DATABANK, PATCH_STAFF_SKILL_DATABANK, DELETE_STAFF_SKILL_DATABANK, FETCH_STORY_DATA, UPDATE_STORY_DATA, FETCH_STORY_DATA_SECTION, UPDATE_STORY_DATA_SECTION, RESET_STORY_DATA_SECTION } from '../../constants';

export const skillDatabanks = (state = [], action) => {
  switch(action.type){
    case FETCH_ALL_SKILL_DATABANKS:
      return action.skillDatabanks;
    case CREATE_SKILL_DATABANK:
      return [...state, action.skillDatabank];
    case PATCH_SKILL_DATABANK:
      return state.map(skillDatabank => skillDatabank.id === action.skillDatabank.id ? action.skillDatabank : skillDatabank );
    case DELETE_SKILL_DATABANK:
      return state.filter(skillDatabank => skillDatabank.id !== action.skillDatabank.id);
    default:
      return state;
  }
};

export const staffSkillDatabanks = (state = [], action) => {
  switch(action.type){
    case FETCH_STAFF_SKILL_DATABANKS:
      return action.skillDatabanks;
    case CREATE_STAFF_SKILL_DATABANK:
      return [...state, action.skillDatabank];
    case PATCH_STAFF_SKILL_DATABANK:
      return state.map(skillDatabank => skillDatabank.id === action.skillDatabank.id ? action.skillDatabank : skillDatabank );
    case DELETE_STAFF_SKILL_DATABANK:
      return state.filter(skillDatabank => skillDatabank.id !== action.skillDatabank.id);
    default:
      return state;
  }
};

export const skillDatabank = (state = {}, action) => {
  switch (action.type) {
    case CREATE_STAFF_SKILL_DATABANK:
      return action.skillDatabank;
    case PATCH_STAFF_SKILL_DATABANK:
      return action.skillDatabank;
    case CLEAR_DATABANK:
      return {};
    default:
      return state;
  }
};
export const storyDataConfig = (state = {}, action) => {
  switch (action.type) {
    case FETCH_STORY_DATA:
      return { data: action.data };
    case UPDATE_STORY_DATA:
      const index = state.data.findIndex(book => book.id == action.data.id);
      const newArray = [...state.data];
      newArray[index] = action.data;
      return { data: newArray };
    default:
      return state;
  }
}

export const storyDataSections = (state = {}, action) => {
  switch (action.type) {
    case FETCH_STORY_DATA_SECTION:
      return { data: action.data };
    case UPDATE_STORY_DATA_SECTION:
      const index = state.data.findIndex(book => book.id == action.data.fileId);
      const newArray = [...state.data];
      newArray[index] = action.data;
      return { data: newArray };
    case RESET_STORY_DATA_SECTION:
      return {};
    default:
      return state;
  }
}