import {
  FETCH_SLIDER_MANAGEMENT_LIST,
  CREATE_SLIDER_MANAGEMENT_LIST,
  PATCH_SLIDER_MANAGEMENT_LIST,
  DELETE_SLIDER_MANAGEMENT_LIST,
  ADD_STORY_TO_STORY_LIST,
} from "../../constants";


export default function (state = [], action) {
    switch(action.type){
      case FETCH_SLIDER_MANAGEMENT_LIST:
        return action.sliderList;
      case CREATE_SLIDER_MANAGEMENT_LIST:
        return [...state, action.sliderList];
      case PATCH_SLIDER_MANAGEMENT_LIST:
        return state.map(slider => slider.id === action.slider.id ? action.slider : slider );
      case DELETE_SLIDER_MANAGEMENT_LIST:
       return state.filter(slider => slider.id !== action.slider.id);
       case ADD_STORY_TO_STORY_LIST:
        return state.map(slider => slider.id === action.slider.id ? action.slider : slider )
      default:
        return state;
    }
  }