export default {
  title: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '55px',
    marginBottom: '20px'
  },
  text: {
    marginLeft: '20px',
    alignSelf: 'center'
  },
  icon: {
    padding: '5px',
    backgroundColor: '#0087b6',
    color: '#f9f3ff',
    fontSize: '3rem',
    alignSelf: 'center',
    borderRadius: '10px'
  },
  card: {
    display: 'grid',
    alignSelf: 'start',
    justifySelf: 'center',
    marginTop: '20px',
    minHeight: 0,
    overflow: 'hidden',
    width: '100%'
  },
  infoLine: {
    display: 'grid',
    gridTemplateColumns: '1fr 6fr 1fr',
    margin: '20px 0',
    '& .slider':{
      gridColumn: 2
    },
  },
  infoIcon: {
    gridColumn: 1,
    alignSelf: 'center',
    justifySelf: 'center',
  },
  infoText: {
    gridColumn: 2,
    alignSelf: 'center',
    justifySelf: 'start'
  },
  brailleText: {
    gridColumn: '2 / span 2',
    alignSelf: 'center',
    justifySelf: 'start',
  },
  infoEdit: {
    gridColumn: 3,
    alignSelf: 'center',
  },
  inputLine: {
    gridColumn: 2,
    alignSelf: 'center',
    marginBottom: '10px',
  },
  buttons: {
    display: 'grid',
    gridTemplateColumns: 'auto',
    gridAutoFlow: 'column'
  },
  playTimeBtn: {
    justifySelf: 'center'
  },
  confirm: {
    marginLeft: '10px'
  },
  login: {
    display: 'grid',
    gridAutoFlow: 'column',
    marginTop: 20,
    marginBottom: 20,
    '& .text': {
      alignSelf: 'center',
      justifySelf: 'center'
    },
    '& .toggle':{
      alignSelf: 'center',
    }
  },
  profile: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: 20,
    gridRowGap: 20
  },
  tutorialList: {
    gridColumn: '1/ span 2'
  },
  checkbox: {
    marginLeft: 15
  },
  brailleButton: {
    gridRow: 2,
    gridColumn: 2,
    marginTop: 20
  },
  subscriptionButtons: {
    display: 'grid',
    gridTemplateColumns: 'auto',
    gridAutoFlow: 'column',
    paddingTop: '25px',
  },
  dialogTitle: {
    backgroundColor: '#383871',
    marginBottom: 15,
  },
  headContainer: {
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center'},
  exitIcon: {
    justifySelf:'flex-end',
    color: '#fff',
  },
  dialog: {
    zIndex: 0,
    overflow: 'auto',
  },
  enrollTitle:{
    color:'#fff',
    fontSize: 24,
    textAlign: 'center',
    flex:1
  },
  list: {
    display:'flex',
    marginBottom:'10px',
    flexDirection:'column',
    fontSize:18,
    backgroundColor:'#D3D3D3',
  },
  snackbarSuccess:{
    backgroundColor:"#4caf50"
  },
  snackbarFailed:{
      backgroundColor:'#ab003c'
  }
}
