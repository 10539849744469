export default {
  drawer: {
    backgroundColor: '#310073',
    width: '300px',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
  },
  drawerGoal: {
    backgroundColor: '#310073',
    width: '70px',
  },
  goal: {

  },
  hidden: {
    visibility: 'hidden'
  },
  list: {
    display: 'grid',
    gridAutoFlow: 'row',
    textDecoration: 'none',
    width: '100%',
    marginLeft: 10
  },
  listItem: {
    color: '#fff',
    fontSize: '20px',
    textDecoration: 'none',
  },
  role: {
    color: '#00BBEB',
  },
  link: {
    textDecoration: 'none',
    '&:focus': {
      backgroundColor:'#0087B6',
      color: '#0B002D'
    },
    '&:hover': {
      backgroundColor:'#0087B6',
      color: '#0B002D'
    },
    marginBottom: '5px',
  },
  linkButton: {
    padding: 0,
  },
  activeListItem: {
    color: '#0B002D',
    fontWeight: 800,
    fontSize: '20px',
    textDecoration: 'none',
  },
  active: {
    backgroundColor: '#0087B6',
    color: '#fff',
    fontWeight: 800,
    '&:hover': {
      backgroundColor:'#00BBEB',
      color: '#0B002D'
    },
    marginBottom: '5px'
  },
  button: {
    marginTop: '30px',
    color: '#fff',
    borderColor: '#fff',
    margin: '0 auto'
  },
  logo: {
    width: '160px',
  },
  divider: {
    margin: '20px 0',
    width: '90%',
    backgroundColor: '#fff',
  }
};