export default {
  dialog: {
    overflow: 'visible'
  },
  content: {
    overflow: 'visible'
  },
  text: {
    marginBottom: 20
  },
  inviteTitle: {
    display: 'grid',
    gridAutoFlow: 'column',
    '& .text':{
      gridColumn: 1,
      justifySelf: 'start',
      alignSelf: 'center'
    },
    '& .button':{
      gridColumn: 2,
      justifySelf: 'end',
      alignSelf: 'center'
    },
  },
  message: {
    '& fieldset': {
      borderRadius: '0 0 4px 4px'
    }
  },
  line: {
    marginBottom: 15
  }
}