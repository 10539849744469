import React, { Component } from 'react';
import { connect } from 'react-redux';
import DataTable from '../Shared/DataTable';
import { fetchLevels } from '../../../redux/actions';
import { FormattedMessage } from 'react-intl';

class LevelList extends Component {

  componentDidMount() {
    this.props.fetchLevels();
  }

  render() {
    const rows = [
      { id:'name', label: <FormattedMessage id='General.name'/>, searchable: true },
      { id: 'description',  label: <FormattedMessage id='General.description'/>},
      { id:'levelVariables', label: <FormattedMessage id='Level.variables'/> },
    ];

    const title = (
      <>
        {
          this.props.myself
            ? <> <FormattedMessage id='General.my'/> <FormattedMessage id='Datatable.levels'/></>
            : <FormattedMessage id='Datatable.levels'/>
        }
      </>
    );

    return <DataTable
      type={'level'}
      editable
      deletable
      rows={rows}
      rowsPerPage={[5,10,15]}
      orderBy={''}
      data={this.props.levels}
      title={title}
    />
  }
}

const mapState = ({ platform: { levels }}) => {
  return {
    levels
  }
};

const mapDispatch = (dispatch, {history}) => {
  return {
    fetchLevels: () => dispatch(fetchLevels(history))
  }
};

export default connect(mapState, mapDispatch)(LevelList);
