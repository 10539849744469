import React, { Component } from 'react';
import moment from 'moment';
import SupportListChart from '../../components/Support/SupportListChart';
import PageViewChart from '../../components/Charts/PageViewChart';
import SupportChartList from '../../components/Support/SupportChartList';
import { getPageViewsChart, minutesPlayedChart, sheetsCreatedChart, skillsCreatedChart } from '../../../redux/actions';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../styles/metrics';
import SupportMinutesChart from '../../components/Support/SupportMinutesChart';
import { Typography, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

class SupportDashboard extends Component {

  state = {
    pageViewsChart: [],
    minutesPlayedChart: [],
    omTextChart: [],
    brailleTextChart: [],
    account: {},
    expanded: false
  };

  changeAccount = (account) => {
    getPageViewsChart(account.id)
      .then(chart => {
        this.setState({ account, pageViewsChart: chart })
      });
    this.createMinutesPlayedChart(account.id);
    this.createTextCharts(account.id);
    this.setState({ expanded: true })
  };

  createTextCharts = (accountId) => {
    let braille = [];
    let om = [];
    sheetsCreatedChart(accountId)
      .then((chart) => {
        let data = { label: 'Braille Sheets Made', value7: chart.sheets7Days.length, value30: chart.sheets30Days.length };
        braille.push(data)
      });
    skillsCreatedChart(accountId)
      .then((chart) => {
        let omSkills = { label: 'O&M Skills Made', value7: chart.onM7DayList.length, value30: chart.onM30DayList.length}
        let brailleSkills = { label: 'Braille Skills Made', value7: chart.braille7DayList.length, value30: chart.braille30DayList.length}
        braille.push(brailleSkills);
        om.push(omSkills)
      });
    this.setState({ omTextChart: om, brailleTextChart: braille })
  };

  createMinutesPlayedChart = (accountId) => {
    minutesPlayedChart(accountId)
      .then((chart) => {
        let dataSet = {};
        let data = [];
        console.log(chart);
        chart.forEach(_data => {
          const day = _data.date.dayOfMonth >= 10 ? `${_data.date.dayOfMonth}` : `0${_data.date.dayOfMonth}`;
          const month = _data.date.monthValue >= 10 ? `${_data.date.monthValue}` : `0${_data.date.monthValue}`;
          let date = moment(`${_data.date.year}-${month}-${day}`).format('Y-MM-DD');
          if (dataSet[date] == undefined){
            dataSet[date] = _data.mins/1000/60;
          } else {
              dataSet[date] =+ _data.mins/1000/60;
          }
        });
        for (let [date, value] of Object.entries(dataSet)){
          data.push({x: date, y: Math.round(value)})
        }
        this.setState({ minutesPlayedChart: [{ data, id: '0'}]  })
      });
  };

  render(){
    const { classes } = this.props;
    return (
      <div id='support-dashboard' className={classes.container}>
        {
          !this.state.account.id
          ? <section className={classes.chartGap}>
              <Typography align='center' gutterBottom variant='h4'> Select an account from below to see metrics </Typography>
            </section>
          : <ExpansionPanel
              classes={{
                rounded: classes.rounded
              }}
              className={classes.chartGap}
              expanded={this.state.expanded}
              onChange={() => this.setState({ expanded: !this.state.expanded })}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                aria-controls="expand-content"
              >
                {
                  !this.state.expanded &&
                    <Typography variant='body1'> Click to expand </Typography>
                }
              </ExpansionPanelSummary>
              <ExpansionPanelDetails classes={{ root: classes.expansion }}>
                {
                  this.state.pageViewsChart && this.state.pageViewsChart.length > 0 &&
                  <section className={classes.chartGap}>
                    <PageViewChart title={'Page Views'} data={this.state.pageViewsChart}/>
                  </section>
                }
                <section className={classes.chartGap} >
                    <SupportMinutesChart title={'Student Minutes Played'} data={this.state.minutesPlayedChart}/>
                  </section>
                <section className={classes.lineGroup}>
                  <SupportListChart title='Braille Skills' data={this.state.brailleTextChart}/>
                  <SupportListChart title='O&M Skills' data={this.state.omTextChart}/>
                </section>
              </ExpansionPanelDetails>
            </ExpansionPanel>
        }
        <section>
          <SupportChartList changeAccount={this.changeAccount}/>
        </section>
      </div>
    )
  }
}

export default withStyles(baseStyle)(SupportDashboard);