import React, {Component} from 'react';
import {connect} from 'react-redux';
import DataTable from '../Shared/DataTable';
import {FormattedMessage} from 'react-intl';
import {Button, Typography, IconButton, FormGroup, TextField, MenuItem} from '@material-ui/core';


class StoryText extends Component {

    state = {
        storySegment: {},
        analysis: {}
    };

    render() {
        const {storySegment, analysis} = this.props;
        const storyWordList = this.props.storySegment.storyWordList;
        var analysisWords = [];
        if(analysis && analysis.NBest) {
            analysisWords = analysis.NBest[0].Words;
        }
        return (
            <div>
                {storyWordList && storyWordList.map((value, index) =>{
                    let style={fontSize: "x-large"};
                    if(analysisWords.length>0) {
                        let analysisWord = analysisWords[index];
                        style = analysisWord.AccuracyScore < 90 ? analysisWord.AccuracyScore < 50 ? {color: "red",fontSize: "x-large"} : {color: "yellow",fontSize: "x-large"} : {fontSize: "x-large"}
                    }
                    return <text style={style}>{value.display} </text>
                })}
            </div>
        )
    }
}

export default connect(null)(StoryText);