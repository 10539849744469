export default {
  container: {
    color: '#310073',
    overflow: 'visible',
    '& .icon': {
      alignSelf: 'center',
      justifySelf: 'center',
      width: '1.5em'
    },
    '& .mention': {
      fontWeight: 800
    },
    '& .ql-mention-list-container': {
      overflow: 'scroll',
      maxHeight: 240
    }
  },
  root: {
    marginTop: '20px',
    maxWidth: 550,
  },
  title: {
    backgroundColor: '#383871',
    marginBottom: 15,
  },
  titleText: {
    float: 'left',
    color: '#fff'
  },
  line: {
    marginBottom: '10px',
  },
  lastLine: {
    marginBottom: '30px',
  },
  shareIcon: {
    fontSize: '1.5em',
    alignSelf: 'center'
  },
  exitIcon: {
    float: 'right',
    color: '#fff',
  },
  comment: {
    display: 'grid',
    gridAutoFlow: 'column',
    marginBottom: 10,
    gridTemplateColumns: '50px auto',
    height: 80,
  },
  quill: {
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 0.38)',
    borderWidth: '1px',
    borderRadius: '4px',
    height: 80,
    '& .ql-editor': {
      padding: '18.5px 14px',
      fontFamily: 'Roboto',
      fontSize: '1rem',
      color: 'rgba(0, 0, 0, 0.87)'
    }
  }
}