import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Button, Typography, IconButton, TextField, FormGroup } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../../styles/wizardBase';
import BrailleSheet from './BrailleSheetWizardGrid';
import { createBrailleSheet, createStaffBrailleSheet } from '../../../../redux/actions';
import { FormattedMessage } from 'react-intl';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControl from '@material-ui/core/FormControl';


class BrailleSheetsWizardPage2 extends Component {
  state={
    name:'',
    error: {},
    description: '',
    area: '',
    gameList: [],
    loading: false
  };

  checkForErrors = (ev) => {
    if (!ev.target.value){
      this.setState({ error: {...this.state.error, [ev.target.name]: true }})
    }
    else {
      let error = this.state.error;
      delete error[ev.target.name];
      this.setState({ error })
    }
  };

  close = () => {
    this.props.close();
    this.setState({ name: '', description: '', area: '', gameList: [], submitted: false })
  };

  handleSubmit = () => {
    const { name, description, area, gameList } = this.state;
    const { brfConversion, unicodeConversion, dotsConversion, grid, binary } = this.props.sheet;
    const { sheetSize } = this.props;
    const games = this.props.games.filter(game => gameList.some(_game => _game.value === game.id));
    const brailleSheet = { name, description, area, games, brf: brfConversion, unicode: unicodeConversion, dots: dotsConversion, grid, binary, sheetSize };
    this.props.staffMember
    ? this.props.createStaffBrailleSheet(brailleSheet, this.props.id)
        .then((response) => {
          if (response && response.status === 200) {
            this.setState({ loading: true });
            setTimeout(() => this.setState({ download: true, loading: false, sheet: response.data }), 1500)
            this.props.next(response.data);
          }
        })
    : this.props.createBrailleSheet(brailleSheet, this.props.account)
        .then((response) => {
          this.props.next(response.data);
        })
  };

  render()  {
    const { classes, sheet } = this.props;
    const { error } = this.state;
    const brailleGames = ['410', '409', '404'];
    const educationalGames = this.props.games.filter(game => game.type === 'Educational' && brailleGames.some(_game => _game === game.id));
    const disabled = this.state.gameList.length == 0 || !this.state.name;
    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        open={this.props.open}
        onClose={this.props.close}
        aria-labelledby='form-dialog-title'
        PaperProps={{classes:{ root: classes.container }}}
        scroll='paper'
      >
        <DialogTitle disableTypography={true} className={classes.title} id='form-dialog-title'>
          <Typography className={classes.titleText} variant='h4'>
            <FormattedMessage id='Databanks.brailleSheets'/>
          </Typography>
          <IconButton
            onClick={this.close}
            className={ classes.exitIcon }
            aria-label="close dialog"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.container}>
          <DialogContentText>
            <FormattedMessage id='Wizard.Braille.page2'/>
          </DialogContentText>
          <FormGroup classes={{root: classes.brailleRoot}}>
            <Typography gutterBottom variant='h6'><FormattedMessage id='General.name'/>: </Typography>
            <TextField
              classes={{root: classes.firstName}}
              variant='outlined'
              id='braille-sheets-text'
              type='name'
              name='name'
              error={error.name}
              onChange={(ev) => this.setState({ name: ev.target.value })}
              onBlur={this.checkForErrors}
            />
            <Typography gutterBottom variant='h6'> <FormattedMessage id='General.description'/>:  </Typography>
            <TextField
              multiline
              classes={{root: classes.firstName}}
              variant='outlined'
              id='braille-sheets-text'
              type='description'
              name='description'
              onChange={(ev) => this.setState({ description: ev.target.value })}
            />
            <Typography gutterBottom variant='h6'><FormattedMessage id='General.area'/>:  </Typography>
            <TextField
              classes={{root: classes.firstName}}
              variant='outlined'
              id='braille-sheets-text'
              type='area'
              name='area'
              onChange={(ev) => this.setState({ area: ev.target.value })}
            />
            <Typography gutterBottom variant='h6'> <FormattedMessage id='Wizard.Braille.games'/>:  </Typography>
            <Select
              isMulti
              className={classes.brailleSelect}
              options={educationalGames.map(game => ({ value: game.id, label: game.name }))}
              onChange={(ev) => this.setState({ gameList: ev })}
              error={error.gameList}
              onBlur={this.checkForErrors}
            />
          </FormGroup>
          <Typography gutterBottom variant='h6'>
            <FormattedMessage id='General.text'/> <Typography variant='body1'>{sheet.text}</Typography>
          </Typography>
          <BrailleSheet className={classes.gridContainer} sheet={sheet} grid={JSON.parse(sheet.grid)}/>
        </DialogContent>
        <DialogActions className={classes.buttons}>
          <Button onClick={this.props.back} color='secondary'>
            <FormattedMessage id='General.back'/>
          </Button>
          <Button disabled={disabled} onClick={this.handleSubmit} variant='contained' color='primary'>
            <FormattedMessage id='General.submit'/>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapState = ({ platform: { games }}) => {
  return {
    games
  }
};

const mapDispatch = (dispatch) => {
  return {
    createBrailleSheet: (sheet, account) => dispatch(createBrailleSheet(sheet, account)),
    createStaffBrailleSheet: (sheet, id) => dispatch(createStaffBrailleSheet(sheet, id))
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(BrailleSheetsWizardPage2));