import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Levels from './Levels';
import SkillDatabankList from '../../components/Admin/SkillDatabankList';
import SkillDatabankWizard from '../../components/Wizards/SkillDatabank/SkillDatabankWizardBase';
import { Settings } from '@material-ui/icons';
import { Typography, Divider } from '@material-ui/core';
import { getAccount, fetchSkillDatabanks, fetchStaffBrailleSheets, getUser } from '../../../redux/actions';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../styles/config';
import StoryDataList from './StoryDataList';
import SliderListManagement from './SliderListManagement';
import SliderSeriesManagement from './SliderSeriesManagement';

class Config extends Component {
  componentDidMount() {
    const { account, fetchSkillDatabanks, user, fetchStaffBrailleSheets } = this.props;
    this.props.getAccount();
    if (user.staffId) {
      fetchStaffBrailleSheets(user.staffId);
    }
    if (account.id) {
      fetchSkillDatabanks(account.id);
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.account.id !== this.props.account.id) {
      this.props.getAccount();
      this.props.fetchSkillDatabanks(nextProps.account.id);
    }
    if (nextProps.user.id !== this.props.user.id) {
      this.props.getUser();
      this.props.fetchStaffBrailleSheets(nextProps.user.staffId);
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <section>
        <div className={classes.title}>
          <Settings className={classes.icon} fontSize='inherit' />
          <Typography className={classes.text} variant='h3'>
            <FormattedMessage id='Config.title' />
          </Typography>
        </div>
        <Divider />
        <SkillDatabankList myself type='skillDatabank' history={this.props.history} account={this.props.account} skillDatabanks={this.props.skillDatabanks} />
        <SkillDatabankWizard type='admin' id={this.props.account.id} />
        <br />
        <Levels myself history={this.props.history} />
        <br />
        <StoryDataList history={this.props.history} />
        <br />
        <SliderListManagement history={this.props.history} />
        <br />
        <SliderSeriesManagement history={this.props.history} />
      </section>
         
    )
  }
}

const mapState = ({ shared: { account, user }, platform: { skillDatabanks } }) => {
  return {
    user,
    account,
    skillDatabanks,
  }
};

const mapDispatch = (dispatch, { history }) => {
  return {
    getAccount: () => dispatch(getAccount(history)),
    fetchSkillDatabanks: (accountId) => dispatch(fetchSkillDatabanks(accountId)),
    fetchStaffBrailleSheets: (accountId) => dispatch(fetchStaffBrailleSheets(accountId)),
    getUser: () => dispatch(getUser())
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(Config));