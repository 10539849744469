import React, { useEffect } from "react";
import { Box, Button, Card, CardActions, CardContent, CardHeader, Dialog, DialogActions, FormGroup, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Toolbar, Tooltip, Typography } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../styles/dashboard';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { connect } from "react-redux";
import { addBookShareUser, addStoryToStudent, downloadBook, addStudentToBookshare } from "../../redux/actions";
import { Close, Search } from "@material-ui/icons";
import { useState } from "react";
import { getSorting, stableSort } from "../../helpers";
import { FormattedMessage } from "react-intl";
import bookshareLogo from "../../assets/ScreenShots/bookshare-Logo.png"

const gradeOption = Array(12).fill(0);
const BookShareUserTable = ({ closeImportMessageModal, classes, student, dispatch, bookShareUsers,
    searchToggle, storyId, importSlide, booksImport, handleUnmount, addStudent, handleStudentWizardClose, showStudent, }) => {

    const [searchValue, setSearchValue] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('firstName');
    const [selectedGrade, setSelectedGrade] = useState();
    const [selectedDate, setSelectedDate] = React.useState();
    const [disabilityType, setDisabilityType] = useState();
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const rows = [{
        id: 'firstName',
        label: 'First Name'
    }, {
        id: 'lastName',
        label: 'Last Name'
    }, {

        id: 'dateOfBirth',
        label: 'Date of Birth'
    }]

    useEffect(() => {
        if (bookShareUsers && bookShareUsers.bookshareUserAdded && booksImport && !booksImport.bookSearchToggle && !searchToggle) {
            let id = importSlide.storyDataId || importSlide.id;
            dispatch(addStoryToStudent(storyId, id));
            handleUnmount();
            closeImportMessageModal && closeImportMessageModal();
        }
        if (bookShareUsers && bookShareUsers.bookshareUserAdded && ((booksImport && booksImport.bookSearchToggle) || searchToggle ) ) {
            if(importSlide && importSlide.storyDataId != null)
            dispatch(addStoryToStudent(storyId, importSlide.storyDataId));
            else
            dispatch(downloadBook(importSlide.id, storyId, importSlide));
            handleUnmount();
        }
    }, [bookShareUsers, booksImport]);

    const handleAddUser = (user) => {
        if (student)
            dispatch(addBookShareUser(student.id, user));
    }

    const handleSearch = (searchValue, data) => {
        let _data = [];
        if (bookShareUsers)
            _data = bookShareUsers.data;
        const searchableData = data ? data : _data ? _data : [];
        const filtered = Array.isArray(searchableData) && searchableData.filter(item => {
            const searchableKeys = [{ id: 'firstName' }, { id: 'lastName' }, { id: 'dateOfBirth' }]
            return searchableKeys.some(key => {
                if (item[key.id]) {
                    return item[key.id].toLowerCase().includes(searchValue.toLowerCase())
                }
                else {
                    return false
                }
            })
        });
        Array.isArray(searchableData) && setFilteredData(filtered);
    };

    const handleSort = (event, property) => {
        const orderBy1 = property;
        let order1 = 'desc';
        if (orderBy === property && order === 'desc') {
            order1 = 'asc';
        }
        setOrder(order1);
        setOrderBy(orderBy1);
    };
    const handleGradeChange = (event) => {
        setSelectedGrade(event.target.value);
    }
    const handleDateChange = (event) => {

        setSelectedDate(event.target.value);
    };

    const handleDisability = (event) => {
        setDisabilityType(event.target.value);
    };
    const handleStudentSubmit = () => {
        let userObj = {
            grade: selectedGrade || "",
            disabilityType: disabilityType || "",
            dateOfBirth: selectedDate || ""
        }
        dispatch(addStudentToBookshare(student.id, userObj));
    }

    const handleChangePage = (event, page) => {
        setPage(page);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value)
    };

    const data = (filteredData.length > 0 || searchValue) ? filteredData : bookShareUsers.data;
    return (
        <>
            <Toolbar classes={{ root: classes.toolbar }} style={{ marginTop: "10px" }}>
                <Typography className={classes.staffHeader} noWrap>
                    Users
                </Typography>
                <div className={classes.actions}>
                    {(
                        <TextField
                            variant='outlined'
                            id='table-search'
                            aria-label='search input'
                            type={'search'}
                            placeholder="Search for your student"
                            InputProps={{
                                inputProps: {
                                    'aria-label': 'search input'
                                },
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <Search />
                                    </InputAdornment>
                                ),
                                className: classes.searchBarBar,
                            }}
                            onChange={(ev) => {
                                handleSearch(ev.target.value);
                                setSearchValue(ev.target.value);
                            }}
                            value={searchValue}
                        />
                    )}
                </div>
            </Toolbar>
            <div className={classes.tableWrapper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {rows.map((row, i) => {
                                return (
                                    <TableCell align={"center"} key={i + row.id} aria-label={row}
                                        sortDirection={orderBy === row.id ? order : false} >
                                        <Tooltip
                                            title='Sort'
                                            placement='bottom-start'
                                            enterDelay={300}>
                                            <TableSortLabel
                                                active={orderBy === row.id}
                                                direction={order}
                                                onClick={(ev) => handleSort(ev, row.id)}>
                                                {row.label}
                                            </TableSortLabel>
                                        </Tooltip>
                                    </TableCell>
                                )
                            })}

                            <TableCell align="center" >Assign Student</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            stableSort(data, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((_user, index) => {
                                return (
                                    <TableRow key={index} className={classes.hoverRow} tabIndex={0} hover>
                                        {
                                            rows.map((r, i) =>

                                                <TableCell align="center" key={i} className={classes.ellipsis}>{_user[r.id]}</TableCell>)
                                        }
                                        <TableCell align="center" className={classes.ellipsis}>
                                            <Tooltip title="Assign Student">
                                                <IconButton aria-label="b4"
                                                    onClick={() => handleAddUser(_user)}>
                                                    <AddCircleOutlineIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component='div'
                    count={data && data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage={<FormattedMessage id='Datatable.rowsPerPage' />}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <div>
                    Can't find the student you're looking for?  <br />
                    <Button color='primary' className={classes.wizardButton} onClick={addStudent} >Click here to add them to Bookshare</Button>
                </div>
            </div>
            <Dialog open={showStudent} maxWidth='lg' onClose={handleStudentWizardClose}
                PaperProps={{ classes: { root: classes.container } }}>
                <Paper className='list'>
                    <Card aria-label="Message" >
                        <CardHeader style={{ backgroundColor: 'rgb(71 78 81)', marginBottom: '30px' }}
                            action={
                                <><img src={bookshareLogo} height={"70px"} width={"300px"} style={{ marginBottom: "-10px", marginTop: "5px" }}></img>
                                    <IconButton onClick={handleStudentWizardClose}>
                                        <Close />
                                    </IconButton>
                                </>
                            }
                            title={<Typography variant='h5' style={{ color: "white" }}>Assign Student</Typography>}
                        >
                        </CardHeader>
                        <CardContent style={{ fontFamily: "Calibri, sans-serif" }} >

                            <Grid container spacing={2}>
                                <Grid item lg={6} md={6}>
                                    <TextField
                                        fullWidth
                                        id='student-edit-first-name'
                                        label={<FormattedMessage id="General.firstName" />}
                                        name='firstName'
                                        type='text'
                                        variant='outlined'
                                        value={student.firstName}

                                        disabled
                                    />
                                </Grid>
                                <Grid item lg={6} md={6}>
                                    <TextField

                                        fullWidth
                                        id='student-edit-last-name'
                                        label={<FormattedMessage id="General.lastName" />}
                                        name='lastName'
                                        type='text'
                                        variant='outlined'
                                        value={student.lastName}
                                        disabled
                                    />
                                </Grid>
                                <Grid item lg={6} md={6} >

                                    <InputLabel >Grade</InputLabel>
                                    <Select
                                        fullWidth
                                        label="Grade"
                                        value={selectedGrade}
                                        onChange={handleGradeChange}
                                        required
                                    >
                                        {gradeOption && gradeOption.map((a, i) =>
                                            <MenuItem value={i + 1}>{i + 1}</MenuItem>
                                        )}
                                    </Select>
                                </Grid>
                                <Grid item lg={6} md={6}>

                                    <InputLabel >Disability Type</InputLabel>
                                    <Select
                                        fullWidth
                                        value={disabilityType}
                                        label="Disability Type"
                                        onChange={handleDisability}
                                        required
                                    >
                                        <MenuItem value="learning">Learning</MenuItem>
                                        <MenuItem value="visual">Visual</MenuItem>

                                    </Select>
                                    <Box fontWeight={500} fontSize="smaller"><FormattedMessage id="Bookshare.disability.text"/></Box>
                                </Grid>

                                <Grid item lg={6} md={6}>
                                    <TextField
                                        fullWidth
                                        id="date"
                                        label="Date of Birth"
                                        type="date"
                                        value={selectedDate}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            min: "2002-01-01"
                                            ,
                                        }}
                                        onChange={handleDateChange}
                                    />
                                </Grid>
                            </Grid>

                        </CardContent>
                        <Grid container>
                            <Grid item lg={12} md={12}>
                                <Box fontWeight={500} fontSize="x-small" style={{ marginLeft: "10px", marginTop: "5px" }}><FormattedMessage id="Terms.conditions"/></Box>
                            </Grid>
                        </Grid>
                        <CardActions>
                            <Grid container justifyContent="flex-end"
                                alignItems="center" spacing={4} >
                                <Grid item  >
                                    <Button color='secondary' onClick={handleStudentWizardClose} >
                                        <FormattedMessage id="General.cancel" />
                                    </Button>
                                    <Button color='primary' onClick={handleStudentSubmit}
                                        disabled={
                                            !selectedDate || !selectedGrade || !disabilityType ? true :
                                                false
                                        } >
                                        <FormattedMessage id="General.save" />
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardActions>
                    </Card>
                </Paper>
            </Dialog>

        </>
    )
}
const mapStateToProps = (({ platform: { bookShareUsers, booksImport } }) => {
    return {
        bookShareUsers,
        booksImport
    }
})

export default connect(mapStateToProps, null)(withStyles(baseStyle)(BookShareUserTable));