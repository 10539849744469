import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Button, FormGroup, TextField } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../../styles/wizardBase';
import { ROOT_URL } from '../../../../redux/constants';
import { FormattedMessage } from 'react-intl';

class StaffInviteWizard extends Component {
  constructor(props){
    super(props);
    this.state = {
      email: ''
    }
  }

  handleChange = ({ target: { name, value }}) => {
    this.setState({ [name]: value });
  };

  invite = () => {
    const { email } = this.state;
    const invitationDetails = JSON.stringify([{
      type: this.props.type,
      details: {
        key: this.props.group.key,
        groupName: this.props.group.name,
        description: this.props.group.description
      }
    }]);
    const url = `${ROOT_URL}/invitation`;
    return axios
      .post(url, { email, userId: this.props.user.id, groupId: this.props.group.key, invitationDetails })
      .then((response) => {
        if (response.status === 200) {
          this.props.close();
        }
      })
  };

  render()  {
    const { classes } = this.props;
    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        open={this.props.open}
        onClose={this.close}
        aria-labelledby="form-dialog-title"
        PaperProps={{ classes:{ root: classes.container }}}
      >
        <DialogTitle id="alert-dialog-title"><FormattedMessage id='Wizard.Staff.inviteText'/>: </DialogTitle>
        <DialogContent>
          <FormGroup classes={{ root: classes.root }}>
            <TextField
              classes={{ root: classes.invite }}
              variant='outlined'
              onChange={this.handleChange}
              id='specialists-email'
              type='email'
              name='email'
              label={<FormattedMessage id='General.email'/>}
              placeholder='specialist@example.com'
              value={this.state.email}
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.close} color="secondary" autoFocus>
            <FormattedMessage id='General.cancel'/>
          </Button>
          <Button onClick={this.invite} color="primary" autoFocus>
            <FormattedMessage id='Wizard.Staff.invite'/>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapState = ({ platform: { roles, specialists }, social: { group }, shared: { user }}) => {
  return {
    roles,
    specialists,
    group,
    user
  }
};

export default connect(mapState)(withStyles(baseStyle)(StaffInviteWizard));