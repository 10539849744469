import axios from 'axios/index';
import { DEV_DB } from '../constants';
import {
  CREATE_HELP_GUIDE,
  CREATE_HELP_PAGE,
  CREATE_HELP_QUESTION,
  DELETE_HELP_GUIDE,
  DELETE_HELP_QUESTION,
  FETCH_HELP_PAGES,
  UPDATE_HELP_PAGE,
  DELETE_HELP_PAGE, UPDATE_HELP_QUESTION, UPDATE_HELP_GUIDE
} from '../constants/help';

export const fetchPages = () => {
  const url = `${DEV_DB}/pages`;
  return (dispatch) => {
    axios
      .get(url, { withCredentials:true })
      .then((response) => {
        dispatch({
          type: FETCH_HELP_PAGES,
          helpPages: response.data
        })
      })
      .catch(err => console.log(err))
  }
};

export const createPage = (page) => {
  const url = `${DEV_DB}/pages`;
  return (dispatch) => {
    axios
      .post(url, page)
      .then((response) => {
        dispatch({
          type: CREATE_HELP_PAGE,
          helpPage: response.data.page
        })
      })
      .catch(err => console.log(err))
  }
};

export const createQuestion = (question, pageId) => {
  const url = `${DEV_DB}/pages/${pageId}/questions`;
  return (dispatch) => {
    axios
      .post(url, question)
      .then((response) => {
        dispatch({
          type: CREATE_HELP_QUESTION,
          helpPage: { id: pageId, question: response.data }
        })
      })
      .catch(err => console.log(err))
  }
};

export const createGuide = (guide, pageId) => {
  const url = `${DEV_DB}/pages/${pageId}/guides`;
  return (dispatch) => {
    axios
      .post(url, guide)
      .then((response) => {
        dispatch({
          type: CREATE_HELP_GUIDE,
          helpPage: { id: pageId, guide: response.data }
        })
      })
  }
};

export const updateQuestion = (question, pageId) => {
  const url = `${DEV_DB}/questions/${question._id}`;
  return (dispatch) => {
    axios
      .put(url, question)
      .then((response) => {
        dispatch({
          type: UPDATE_HELP_QUESTION,
          question: {...response.data, pageId}
        })
      })
  }
};

export const updateGuide = (guide, pageId) => {
  const url = `${DEV_DB}/guides/${guide._id}`;
  const _url = guide.type === 'video' ? guide.url.split('=')[1] : guide.url;
  return (dispatch) => {
    axios
      .put(url, { url: _url, description: guide.description, type: guide.type })
      .then((response) => {
        dispatch({
          type: UPDATE_HELP_GUIDE,
          guide: {...response.data, pageId}
        })
      })
  }
};

export const updatePage = (page) => {
  const url = `${DEV_DB}/pages/${page._id}`;
  return (dispatch) => {
    axios
      .put(url, page)
      .then((response) => {
        dispatch({
          type: UPDATE_HELP_PAGE,
          helpPage: response.data
        })
      })
  }
};

export const deleteQuestion = (question, pageId) => {
  const url = `${DEV_DB}/questions/${question._id}`;
  return (dispatch) =>
    axios
      .delete(url)
      .then(() => {
        dispatch({
          type: DELETE_HELP_QUESTION,
          question: {...question, pageId}
        })
      })
};

export const deleteGuide = (guide, pageId) => {
  const url = `${DEV_DB}/guides/${guide._id}`;
  return (dispatch) =>
    axios
      .delete(url)
      .then(() => {
        dispatch({
          type: DELETE_HELP_GUIDE,
          guide: {...guide, pageId}
        })
      })
};

export const deletePage = (pageId) => {
  const url = `${DEV_DB}/pages/${pageId}`;
  return (dispatch) =>
    axios
      .delete(url)
      .then(() => {
        dispatch({
          type: DELETE_HELP_PAGE,
          helpPage: pageId
        })
      })
};

