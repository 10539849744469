import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import store from './redux/store';
import App from './App';
import translation from './translations/data'

import es from 'moment/locale/es'; // eslint-disable-line no-unused-vars
import fr from 'moment/locale/fr-ca'; // eslint-disable-line no-unused-vars

const rootEl = document.getElementById('root');

// get Localization and Timezone information from browser
const usersLocale = (navigator.languages && navigator.languages[0]) || navigator.language;
const translatedLocales = ['en', 'fr-CA', 'fr', 'es', 'en-US'];
const messages = translation[usersLocale] || translation.en;
const language = usersLocale.split("-")[0];
moment.locale(language);

ReactDOM.render(
  <IntlProvider
    // Sets default locale to 'en' for some reason the built in default locale breaks
    locale={translatedLocales.indexOf(usersLocale) !== -1 ? usersLocale : 'en'}
    messages={messages}
  >
    <Provider store={store}>
      <App />
    </Provider>
  </IntlProvider>,
  rootEl
);

