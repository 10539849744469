import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import WizardToggleButton from '../WizardToggleButton';
import { createSupportUser } from '../../../../redux/actions';
import SupportWizardPage1 from './SupportWizardPage1';

class SupportWizardBase extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      open: false,
      page: 1
    }
  }

  openWizard = () => {
    this.setState({ open: true })
  };

  closeWizard = () => {
    this.setState({ open: false, page: 1 });
  };

  next = () => {
    this.setState({ page: this.state.page+1 });
  };

  back = () => {
    this.setState({ page: this.state.page-1 });
  };

  submit = (user) => {
    this.props.createSupportUser(user);
    this.closeWizard();
  };

  render(){
    const { page, open } = this.state;
    return (
      <div>
        {
          page === 1 &&
          <SupportWizardPage1
            open={open}
            close={this.closeWizard}
            submit={this.submit}
            user={this.props.user}
          />
        }
        <WizardToggleButton
          openWizard={this.openWizard}
          type='account'
          text={<FormattedMessage id='Support.createUser'/>}
        />
      </div>
    )
  }
}

const mapDispatch = (dispatch) => {
  return {
    createSupportUser: (user) => dispatch(createSupportUser(user))
  }
};

const mapState = ({ shared: { user }}) => {
  return {
    user
  }
};

export default connect(mapState, mapDispatch)(SupportWizardBase);