import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ResponsiveBar } from '@nivo/bar';
import { Card, CardContent, Divider, Typography, Button } from '@material-ui/core';
import { BarChart as BarIcon } from '@material-ui/icons';
import { getSkillsForGoal } from '../../../redux/actions';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../styles/barChart';
import { FormattedMessage } from 'react-intl';

const tooltip = (slice) => {
  const value = slice.value;
  const seconds = value;
  const minutes = seconds / 60;
  const hours = minutes / (60);
  const type = hours > 1 ? 'hrs' : minutes>1 ? 'mins': 'sec';
  const playTime = hours > 1 ? hours.toFixed(1) : minutes>1 ? minutes.toFixed(0) : seconds;
  return (
    <div>
      <div><strong>{slice.indexValue}</strong></div>
      <div>
        {slice.id}: {playTime} {type}
      </div>
    </div>
  )
};

class HoursPlayedBar extends Component {

  toggle = (ev) => {
    this.props.toggle(ev, ev.data.student.id)
  };

  render(){
    const { classes, title, data, changeTime, time } = this.props;
    const educationalGames = this.props.games.filter(game => game.type !== 'Entertainment');
    const games = educationalGames.map(game => game.name)
    return (
      <Card className={classes.barChartCard} aria-label={title}>
        <div className={classes.header}>
          <BarIcon className={classes.icon}/>
          <Typography className={classes.chartTitle} variant='h5'>{title}</Typography>
        </div>
        <Divider />
        <CardContent className={classes.barChart}>
          <div className={classes.buttons}>
            <Button
              onClick={() => changeTime(0)}
              variant={time === 0 ? 'contained' : 'text'}
              color={time === 0 ? 'primary' : 'default'}
            >
              <FormattedMessage id='Chart.allTime'/>
            </Button>
            <Button
              onClick={() => changeTime(365)}
              variant={time === 365 ? 'contained' : 'text'}
              color={time === 365 ? 'primary' : 'default'}
            >
              365&nbsp;<FormattedMessage id='Chart.days'/>
            </Button>
            <Button
              onClick={() => changeTime(30)}
              variant={time === 30 ? 'contained' : 'text'}
              color={time === 30 ? 'primary' : 'default'}
            >
              30&nbsp;<FormattedMessage id='Chart.days'/>
            </Button>
            <Button
              onClick={() => changeTime(7)}
              variant={time === 7 ? 'contained' : 'text'}
              color={time === 7 ? 'primary' : 'default'}
            >
              7&nbsp;<FormattedMessage id='Chart.days'/>
            </Button>
          </div>
          {
            data.length
            ?  <ResponsiveBar
                data={data}
                keys={games}
                indexBy='id'
                margin={{
                  top: 20,
                  right: 60,
                  bottom: 80,
                  left: 60
                }}
                padding={0.05}
                groupMode='stacked'
                colorBy='id'
                colors={{"scheme": "blues"}}
                borderWidth={1}
                borderColor='inherit:darker(1)'
                axisLeft={{
                  tickValues: 5,
                  tickSize: 0,
                  tickPadding: 5,
                  tickRotation: 0,
                  format: value => {
                    const seconds = value;
                    const minutes = seconds / 60;
                    const hours = minutes / (60);
                    return hours > 1 ? hours.toFixed(1) : minutes>1 ? minutes.toFixed(0) : seconds;
                  }
                }}
                axisBottom={{
                  tickSize: 0,
                  tickPadding: 10,
                  tickRotation: 0,
                }}
                enableGridY={true}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor='#000'
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                label={d => {
                  const seconds = d.value;
                  const minutes = seconds / 60;
                  const hours = minutes / (60);
                  const type = hours > 1 ? 'hrs' : minutes>1 ? 'mins': 'sec';
                  const playTime = hours > 1 ? hours.toFixed(1) : minutes>1 ? minutes.toFixed(0) : seconds;
                  return `${playTime} ${type}`
                }}
                tooltip={tooltip}
                onClick={(data) => this.toggle(data)}
                theme={{
                  labels: {
                    text: { fontSize: 18, fontWeight: 800 }
                  },
                  axis: {
                    ticks: {
                      text: { fontSize: 12, fontWeight: 800 }
                    }
                  }
                }}
              />
            : <Typography className={classes.empty} align='center' variant='h4'>
                {
                  this.props.staffStudents.length <= 0
                    ? <FormattedMessage id='Chart.empty.noStudents'/>
                    : <FormattedMessage id='Chart.empty'/>
                }
            </Typography>
          }
        </CardContent>
      </Card>
    )
  }
}

const mapState = ({ platform: { staffStudents }}) => {
  return {
    staffStudents
  }
};

const mapDispatch = (dispatch, { history }) => {
  return {
    getSkillsForGoal: (goalId) => dispatch(getSkillsForGoal(goalId, history)),
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(HoursPlayedBar));