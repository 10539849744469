import React, { Component } from 'react';
import { ResponsiveLine, Line } from '@nivo/line';
import moment from 'moment';
import { Button, Card, CardContent, Divider, Typography } from '@material-ui/core';
import { Timeline } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../styles/lineChart';
import { capitalize } from '../../../helpers';
import { saveAs } from 'file-saver';

const sampleData = [
  {
    'id': 'Accuracy',
    'data': [
      {
        'x': '2018-10-14T19:15:28Z',
        'y': 50
      },
      {
        'x': '2018-10-21T19:15:28Z',
        'y': 25
      },
      {
        'x': '2018-10-30T19:15:28Z',
        'y': 100
      },
      {
        'x': '2018-11-28T19:15:28Z',
        'y': 80
      }
    ]
  }
];

const tooltip = (slice, label) => {
  const options = { weekday: 'long', month: 'numeric', day: 'numeric' };
  return (
    <div
        style={{
          background: 'white',
          padding: '9px 12px',
          border: '1px solid #ccc',
        }}>
      <div><strong>{slice.slice.points[0].data.x.toLocaleDateString('en-US', options)}</strong></div>
      <div>
        {
          label === 'Accuracy'
          ? <>Accuracy: <strong>{Math.round(slice.slice.points[0].data.y)}%</strong></>
          : <>{label}: <strong>{Math.round(slice.slice.points[0].data.y.toFixed(3))}</strong></>
        }
      </div>
    </div>
  )
};

const percentage = (value) => {
  return `${value}%`
};

const standard = (value) => {
  return value
};

const drawInlineSVG = (svgElement, ctx, callback) => {
  let svgURL = new XMLSerializer().serializeToString(svgElement);
  let img  = new Image();
  img.onload = function(){
    ctx.drawImage(this, 0,0);
    callback();
  };
  img.src = 'data:image/svg+xml; charset=utf8, '+encodeURIComponent(svgURL);
};

class LineChart extends Component {

  handleClick = (title) => {
    let myCanvas = document.getElementById(`${this.props.title.split(" ").join('-')}Canvas`);
    let ctxt = myCanvas.getContext("2d");
    let chart = document.querySelector(`#${this.props.title.split(" ").join('-')} svg[role="img"]`);
    drawInlineSVG(chart, ctxt, function(){myCanvas.toBlob(blob => saveAs(blob, title+'.png'))})
  };

  render(){
    const { classes, title, chart, suppressStyle } = this.props;
    let countMap = {};
    let dataMap = {};
    const dataSet = [];
    const properTitle = capitalize(title);
    const Title = ({ width, height }) => {
      // You can console.log(data) to see what other properties are available
      const style = {fontWeight: 'bold'}

      return (
          <text
              x="44%" dominant-baseline="middle" text-anchor="middle"
              margin-left="auto"
              margin-right="auto"
              y={-10}
              style={style}
          >
            {capitalize(title)}
          </text>
      )
    };
    let _data = chart
        ? chart.dataSet.forEach(data => {
          const date = moment(data.x).format('YYYY-MM-DD');
          if (dataMap[date]){
            if (!isNaN(data.y)){
              countMap[date]++;
              dataMap[date] = (dataMap[date] + data.y)
            }
          }
          else {
            dataMap[date] = isNaN(data.y) ? 0 : Math.round(data.y);
            countMap[date] = 1;
          }
        })
        : {};
    for (let [date, value] of Object.entries(dataMap)){
      dataSet.push({x: date, y: value/countMap[date]})
    }
    const data = chart ? [{ data: dataSet, id: chart.yAxisLabel }] : sampleData;
    const width = suppressStyle ? 900 : 1140;
    const align = suppressStyle ? "left": "center";
    console.log(data);
    return (
        <ConditionalWrapper
        condition={!this.props.suppressStyle}
        wrapper={children => (
            <Card aria-label='line-chart' className={classes.lineChartCard}>
                <div className={classes.header}>
                    <Timeline className={classes.icon}/>
                    <Typography className={classes.chartTitle} variant='h4'>{properTitle}</Typography>
                </div>
                <Divider />
                <CardContent id={title.split(" ").join('-')} className={classes.lineChart}>
                    {children}
                </CardContent>
            </Card>
        )}>
          {
            chart.dataSet.length
            ? <>
              <canvas style={{ display: 'none' }} height={420} width={width} id={`${title.split(" ").join('-')}Canvas`}/>
              {!this.props.suppressStyle && <Button align='right' variant='contained' onClick={(title) => {this.handleClick(properTitle)}}>Download Chart</Button>}
              <div align={align}>
              <Line
                data={data}
                enableSlices={"x"}
                sliceTooltip={(slice) => tooltip(slice, chart.yAxisLabel)}
                layers={['grid', 'markers', 'axes', 'areas', 'crosshair', 'lines', 'points', 'slices', 'mesh', 'legends', Title]}
                margin={{
                  top: 30,
                  right: 80,
                  bottom: 60,
                  left: 60
                }}
                height={330}
                width={800}
                xScale={{
                  type: 'time',
                  format: '%Y-%m-%d',
                  precision: 'day'
                }}
                yScale={{
                  type: 'linear',
                  stacked: false,
                  min: 0,
                  max: chart && chart.yAxisLabel === 'Accuracy' ? 100 : 'auto'
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickValues: 5,
                  orient: 'bottom',
                  tickSize: 10,
                  tickPadding: 5,
                  tickRotation: 0,
                  format: '%-m/%-d'
                }}
                axisLeft={{
                  tickValues: 2,
                  orient: 'left',
                  tickSize: 0,
                  tickPadding: 15,
                  tickRotation: 0,
                  format: chart && chart.yAxisLabel === 'Accuracy' ? percentage : standard
                }}
                colors={['#0087b6']}
                dotSize={10}
                dotColor='inherit:darker(0.3)'
                dotBorderWidth={1}
                dotBorderColor='inherit:darker(1)'
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                theme={{
                  axis: {
                    ticks: {
                      text: { fontSize: 16, fontWeight: 800 }
                    }
                  }
                }}
              />
              </div>
              </>
            : <Typography className={classes.placeholder} variant='h6'> There is not enough data yet, please play more games in this skill...</Typography>
          }

        </ConditionalWrapper>
    )
  }
}

const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children;

export default withStyles(baseStyle)(LineChart);