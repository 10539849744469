import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { TextField, Typography, IconButton, InputAdornment, Button, Card, SnackbarContent } from '@material-ui/core';
import { Close, Visibility, VisibilityOff } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { setPassword } from '../../../redux/actions';
import logo from '../../../assets/BrandAssets/Logo/OBJECTIVEED-Logo-Vertical-1.png';
import styles from '../../../styles/resetPassword';

class Password extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      confirmPassword: '',
      showPassword: false,
      showConfirm: false,
      errors: {}
    }
  }

  componentDidMount(){
    document.body.style.backgroundColor = '#310073';
  }

  handleSubmit = () => {
    const { password, confirmPassword} = this.state;
    const token = queryString.parse(this.props.location.search);
    if (password === confirmPassword) {
      this.props.setPassword(token, confirmPassword)
    } else {
      this.setState({ errors: { confirm: 'Passwords must match'}})
    }
  };

  render(){
    const { confirmPassword, password, showPassword, showConfirm, errors } = this.state;
    const { classes, error } = this.props;
    return (
      <Card className={ classes.resetPasswordContainer }>
        <div className={ classes.resetPasswordForm }>
          <img
            className={ classes.resetPasswordLogo }
            alt='objective ed logo'
            src={logo}
          />
          <TextField
            autoFocus
            aria-label='enter your new password'
            id='password'
            helperText={errors.password}
            variant='outlined'
            error={!!errors.password}
            type={showPassword ? 'text' : 'password'}
            label='New Password'
            value={password}
            onChange={(ev) => this.setState({ password: ev.target.value })}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='Toggle password visibility'
                    onClick={() => this.setState({ showPassword: !showPassword })}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            id='confirm-password'
            helperText={errors.confirm}
            variant='outlined'
            error={!!errors.confirm}
            type={showConfirm ? 'text' : 'password'}
            label='Confirm New Password'
            value={confirmPassword}
            onChange={(ev) => this.setState({ confirmPassword: ev.target.value })}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='Toggle confirm password visibility'
                    onClick={() => this.setState({ showConfirm: !showConfirm })}
                  >
                    {showConfirm ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          { error.msg &&
          <SnackbarContent
            open={error.msg}
            onClose={this.handleClose}
            classes={{ root: classes.error }}
            message={<span id='message-id'>{error.msg}. Please try again.</span>}
            action={[
              <IconButton
                key='close'
                aria-label='Close'
                color='inherit'
                className={classes.close}
                onClick={this.handleClose}
              >
                <Close />
              </IconButton>
            ]}
          />
          }
          <Button
            onClick={this.handleSubmit}
            id='resetPassword-button'
            className={ classes.resetPasswordBtn }
            variant='contained'
          > Confirm Password </Button>
          <Typography align='center' variant='caption'>
            <Link to='/login'>Go back to login page</Link>
          </Typography>
        </div>
      </Card>
    )
  }
}

const mapState = ({ shared: { error }}) => {
  return {
    error
  }
};

const mapDispatch = (dispatch, { history }) => {
  return {
    setPassword: (token, password) => dispatch(setPassword(token, password, history))
  }
};

export default connect(mapState, mapDispatch)(withStyles(styles)(Password));