import { FETCH_ALL_FORMS, CREATE_FORM, GET_FORM } from '../../constants';

export const forms = (state = [], action) => {
  switch(action.type){
    case FETCH_ALL_FORMS:
      return action.forms;
    case CREATE_FORM:
      return [...state, action.form];
    default:
      return state;
  }
};

export const form = (state = {}, action) => {
  switch(action.type){
    case GET_FORM:
      return action.form;
    default:
      return state;
  }
};