import { FETCH_ALL_SUPPORT_USERS, FETCH_USER, FETCH_USER_BOOKSHARE, CLEAR_USER_BOOKSHARE, RESET_USER, CREATE_SUPPORT_USER,IMPORT_BOOKS_SUCCESS,CLEAR_IMPORTED_BOOK, GET_BOOKSHARE_IDENTITY } from '../constants';

export const user =  (state = {}, action) => {
  switch(action.type){
    case FETCH_USER:
      return action.user;
    case FETCH_USER_BOOKSHARE:
      return {...state, hasBookShareCredentials: true};
    case CLEAR_USER_BOOKSHARE:
      return {...state, hasBookShareCredentials: false};
    case RESET_USER:
      return {};
    default:
      return state;
  }
};

export const bookShareIdentity = (state={}, action) => {
  switch (action.type) {
    case GET_BOOKSHARE_IDENTITY:
      return action.data;
    default:
      return state;
  }
};

export const supportUsers = (state = [], action) => {
  switch (action.type){
    case FETCH_ALL_SUPPORT_USERS:
      return action.users;
    case CREATE_SUPPORT_USER:
      return [...state, action.user];
    default:
      return state;
  }
};

export const importBooks = (state = [], action) => {
  switch (action.type){
    case IMPORT_BOOKS_SUCCESS:
      return {
        bookImported: true,
        bookImportSessionExpired: false
      };
    case CLEAR_IMPORTED_BOOK:
      return { ...state, bookImported : false }
    default:
      return state;
  }
};