import React, { Component } from 'react';
import {
  Button, Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  Slider,
  withStyles
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Compare, Edit, Schedule, Stars, VolumeUp, TabletMac,  } from '@material-ui/icons';
import baseStyle from '../../../styles/account';

class StudentConfig extends Component {



  render() {
    const {
      editConfig,
      rewardTime,
      levelsToReward,
      curtain,
      curtainBool,
      studentCurtainOpacity,
      volume,
      studentProfile,
      classes,
      ipadSize,
        speechModel,
    } = this.props;
    const backgroundMusicLevel = Math.round(this.props.backgroundMusicLevel);
    const curtainOpacity = Math.round(this.props.curtainOpacity);
    const rewardEnabled = this.props.rewardEnabled
    if(this.props.rewardTime == null && this.props.levelToReward==null) {
      rewardEnabled = true;
    }
    return (
      <Card classes={{ root: classes.card }}>
        <CardHeader title={<FormattedMessage id='Profile.StudentConfig'/>} action={
          !editConfig &&
          <IconButton
            aria-label='edit'
            onClick={this.props.toggleConfigEdit}
          >
            <Edit />
          </IconButton>
        }/>
        <Divider />
        <CardContent>
          {
            editConfig
              ?
              <>
                <div className={classes.infoLine}>
                  <Compare className={classes.infoIcon}/>
                  <TextField
                      select
                      variant='outlined'
                      name='curtain'
                      label={<FormattedMessage id='Profile.playTime'/>}
                      value={rewardEnabled || false}
                      onChange={this.props.changeReward}
                  >
                    <MenuItem value={true}> <FormattedMessage id='General.yes'/> </MenuItem>
                    <MenuItem value={false}> <FormattedMessage id='General.no'/> </MenuItem>
                  </TextField>
                </div>
                {/*<div className={classes.infoLine}>*/}
                {/*  <Schedule className={classes.infoIcon}/>*/}
                {/*  <TextField*/}
                {/*    variant='outlined'*/}
                {/*    name='entertainmentTime'*/}
                {/*    label={<FormattedMessage id='Profile.entertainmentTime'/>}*/}
                {/*    value={entertainmentTime || '0'}*/}
                {/*    onChange={this.props.handleChange}*/}
                {/*  />*/}
                {/*</div>*/}
                <div className={classes.infoLine}>
                  <Compare className={classes.infoIcon}/>
                  <TextField
                    select
                    variant='outlined'
                    name='curtain'
                    label={<FormattedMessage id='Profile.curtain'/>}
                    value={curtain || false}
                    onChange={this.props.handleChange}
                  >
                    <MenuItem value={true}> <FormattedMessage id='General.yes'/> </MenuItem>
                    <MenuItem value={false}> <FormattedMessage id='General.no'/> </MenuItem>
                  </TextField>
                </div>
                <div className={classes.infoLine}>
                  <TabletMac className={classes.infoIcon}/>
                  <TextField
                    select
                    variant='outlined'
                    name='ipadSize'
                    label='Braille Sheet Device'
                    value={ipadSize}
                    onChange={this.props.handleChange}
                  >
                    <MenuItem value='iPad Mini (7.9")'>iPad Mini (7.9")</MenuItem>
                    <MenuItem value='iPad Air (9.7")'>iPad Air (9.7")</MenuItem>
                    <MenuItem value='iPad Pro (12.9")'>iPad Pro (12.9")</MenuItem>
                  </TextField>
                </div>
                <div className={classes.infoLine}>
                  <TabletMac className={classes.infoIcon}/>
                <TextField
                    select
                    variant='outlined'
                    name='speechModelId'
                    label='Speech Model'
                    value={this.props.speechModelId}
                    onChange={this.props.handleChange}>
                  <MenuItem key={-1} value={-1}>Default Model</MenuItem>
                  {
                    this.props.models.map(model => (<MenuItem key={model.id} value={model.id}> {model.name} </MenuItem>))
                  }</TextField>
                </div>
                {
                  curtain &&
                  <div className={classes.infoLine}>
                    <Compare className={classes.infoIcon}/>
                    <Typography><FormattedMessage id='Profile.curtainOpacity'/>: </Typography>
                    <Slider
                      className='slider'
                      value={curtainOpacity}
                      onChange={(ev, newVal) => this.props.changeSlider(newVal, 'curtainOpacity')}
                      valueLabelDisplay='auto'
                      name='curtainOpacity'
                      defaultValue={0}
                      step={5}
                      min={0}
                      max={100}
                      marks={[
                        { value: 0, label: '0%' },
                        { value: 50, label: '50%' },
                        { value: 100, label: '100%' },
                      ]}
                    />
                  </div>
                }
                <div className={classes.infoLine}>
                  <VolumeUp className={classes.infoIcon}/>
                  <Typography><FormattedMessage id='Profile.backgroundMusicLevel'/>: </Typography>
                  <br/>
                  <Slider
                    className='slider'
                    value={backgroundMusicLevel}
                    onChange={(ev, newVal) => this.props.changeSlider(newVal, 'backgroundMusicLevel')}
                    valueLabelDisplay="auto"
                    name='backgroundMusicLevel'
                    defaultValue={100}
                    step={5}
                    min={0}
                    max={200}
                    marks={[
                      { value: 0, label: '0%' },
                      { value: 100, label: '100%' },
                      { value: 200, label: '200%' },
                    ]}
                  />
                </div>
                <div className={classes.buttons}>
                  <Button
                    variant='outlined'
                    color='secondary'
                    onClick={this.props.cancelPlaytime}
                  >
                    <FormattedMessage id='General.cancel'/>
                  </Button>
                  <Button
                    className={classes.confirm}
                    variant='contained'
                    color='primary'
                    onClick={this.props.handleProfileEdit}
                  >
                    <FormattedMessage id='General.save'/>
                  </Button>
                </div>
              </>
              :
              <>
                <div className={classes.infoLine}>
                  <Schedule className={classes.infoIcon}/>
                  <Typography className={classes.infoText} variant='h6'>
                    <FormattedMessage id='Profile.playTime'/>: {(rewardEnabled) ? "Yes":"No"}
                  </Typography>
                </div>
                {/*<div className={classes.infoLine}>*/}
                {/*  <Schedule className={classes.infoIcon}/>*/}
                {/*  <Typography className={classes.infoText} variant='h6'>*/}
                {/*    <FormattedMessage id='Profile.entertainmentTime'/>: {(studentProfile.entertainmentTime / 60).toFixed(1)} mins*/}
                {/*  </Typography>*/}
                {/*</div>*/}
                {
                  curtainBool &&
                  <div className={classes.infoLine}>
                    <Compare className={classes.infoIcon}/>
                    <Typography className={classes.infoText} variant='h6'>Curtain Opacity: {(studentCurtainOpacity * 100) || '0'}%</Typography>
                  </div>
                }
                <div className={classes.infoLine}>
                  <VolumeUp className={classes.infoIcon}/>
                  <Typography className={classes.infoText} variant='h6'>Volume Level: {Math.floor(volume * 100)}%</Typography>
                </div>
                <div className={classes.infoLine}>
                  <TabletMac className={classes.infoIcon}/>
                  <Typography className={classes.infoText} variant='h6'> Braille Sheet Device: {ipadSize}</Typography>
                </div>

              </>
          }
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(baseStyle)(StudentConfig);