export default {
  listChart: {
    gridRow: 1,
    minHeight: 0,
    height: 480,
    fontSize: '24px',
  },
  list: {
    display: 'grid',
    justifySelf: 'center',
    margin: '20px 40px'
  },
  listItem: {
    display: 'grid',
    alignContent: 'center',
    gridTemplateColumns: '1fr 1fr',
  },
  key: {
    justifySelf: 'start'
  },
  value: {
    justifySelf: 'end'
  },
  header: {
    backgroundColor: '#0087b6',
    display: 'grid',
    gridTemplateColumns: '1fr 7fr',
    padding: '15px'
  },
  icon: {
    padding: '5px',
    backgroundColor: '#310073',
    color: '#fff',
    justifySelf: 'center',
    alignSelf: 'center',
    borderRadius: '5px'
  },
  chartTitle: {
    color: '#fff',
    alignSelf: 'center',
    marginLeft: '10px'
  },
  buttons: {
    display: 'grid',
    justifyContent: 'center',
    gridAutoFlow: 'column',
    marginTop: 10
  },
  empty: {
    marginTop: 120
  }
}