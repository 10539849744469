import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, IconButton, Button, Card, SnackbarContent, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { resetPassword } from '../../../redux/actions';
import logo from '../../../assets/BrandAssets/Logo/OBJECTIVEED-Logo-Vertical-1.png';
import styles from '../../../styles/resetPassword';
import { Link } from 'react-router-dom';

class Password extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      clicked: false
    }
  }

  componentDidMount(){
    document.body.style.backgroundColor = '#310073';
  }

  handleSubmit = () => {
    this.props.resetPassword(this.state.email);
    this.setState({ clicked: true })
  };

  render(){
    const { email, clicked } = this.state;
    const { classes } = this.props;
    return (
      <Card className={ classes.resetPasswordContainer }>
        <div className={ classes.resetPasswordForm }>
          <img
            className={ classes.resetPasswordLogo }
            alt='objective ed logo'
            src={logo}
          />
          <Typography align='center' variant='subtitle1'> Please enter your email to reset your password: </Typography>
          <TextField
            autoFocus
            id='email'
            variant='outlined'
            type='text'
            label='Email / Username'
            aria-label='please enter email to reset password'
            value={email}
            onChange={(ev) => this.setState({ email: ev.target.value })}
          />
          { clicked &&
          <SnackbarContent
            open={clicked}
            classes={{ root: classes.success }}
            message={
              <span id='message-id'>
                An email has been sent with how to reset your password!
              </span>
            }
            action={[
              <IconButton
                key='close'
                aria-label='Close'
                color='inherit'
                className={classes.close}
                onClick={() => this.setState({ clicked: false })}
              >
                <Close />
              </IconButton>
            ]}
          />
          }
          <Button
            onClick={this.handleSubmit}
            id='resetPassword-button'
            className={ classes.resetPasswordBtn }
            variant='contained'
          > Reset Password </Button>
          <Typography align='center' variant='caption'>
            <Link to='/login'>Go back to login page</Link>
          </Typography>
        </div>
      </Card>
    )
  }
}

const mapDispatch = (dispatch, { history }) => {
  return {
    resetPassword: (email) => dispatch(resetPassword(email, history))
  }
};

export default connect(null, mapDispatch)(withStyles(styles)(Password));