import React, { Component } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Divider, InputAdornment,
} from '@material-ui/core';
import { Paper, TextField, Toolbar, Tooltip, withStyles } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { getSorting, stableSort } from '../../../../helpers';
import baseStyle from '../../../../styles/skillWizardTable';
import { FormattedMessage } from 'react-intl';

class SkillWizardTable extends Component {

  state = {
    page: 0,
    rowsPerPage: 5,
    orderBy: 'name',
    order: 'desc',
    searchCategory: 'name',
    searchValue: '',
    filteredData: [],
    rows: [
      { id: 'name', label: <FormattedMessage id='General.name'/> },
      { id: 'description', label: <FormattedMessage id='General.description'/> },
      { id: 'area', label: <FormattedMessage id='General.area'/>}
    ]
  };

  handleSearch = (searchValue) => {
    const { skills } = this.props;
    const filtered = skills.filter(item => {
      const searchableKeys = Object.keys(item).filter(key => this.state.rows.some(row => row.id === key));
      return searchableKeys.some(key => {
        if (item[key]){
          return item[key].toLowerCase().includes(searchValue.toLowerCase())
        }
        else {
          return false;
        }
      })
    });
    return this.setState({ filteredData: filtered, page: 0 })
  };

  handleSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { rowsPerPage, page, orderBy, order, searchValue, filteredData, rows } = this.state;
    const { classes, selected } = this.props;
    const data = filteredData.length || searchValue ? filteredData : this.props.skills;
    return (
      <Paper>
        <Toolbar classes={{ root: classes.toolbar }}>
          <TextField
            variant='outlined'
            id='table-search'
            aria-label='search input'
            type='search'
            InputProps={{
              inputProps: {
                'aria-label':'search input'
              },
              className: classes.searchBar,
              endAdornment: (
                <InputAdornment position='end'>
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={(ev) => {
              this.handleSearch(ev.target.value);
              this.setState({ searchValue: ev.target.value });
            }}
          />
        </Toolbar>
        <Divider />
        <Table>
          <TableHead>
            <TableRow>
              {
                rows.map(row => {
                  return (
                    <TableCell
                      key={row.id}
                      sortDirection={ orderBy === row.id ? order : false }
                      aria-label={row.id}
                    >
                      <Tooltip
                        aria-label={`sort ${row.label}`}
                        title={<FormattedMessage id='General.sort'/>}
                        placement='bottom-start'
                        enterDelay={300}
                      >
                        <TableSortLabel
                          active={orderBy === row.id}
                          direction={order}
                          onClick={(ev) => this.handleSort(ev, row.id)}
                        >
                          {row.label}
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                  )
                })
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(data, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((skill, index) => {
                return (
                  <TableRow
                    hover={skill.id !== selected.id}
                    classes={{
                      root: skill.id === selected.id ? classes.selected : null
                    }}
                    tabIndex={0}
                    onClick={() => this.props.onClick(skill)}
                    key={skill.id+index}

                  >
                    {
                      rows.length &&
                        rows.map((row, index) => {
                          return (
                            <TableCell key={index}>
                              {
                                skill[row.id] && skill[row.id] !== 'null'
                                  ? skill[row.id]
                                  : 'N/A'
                              }
                            </TableCell>
                          )
                        })
                    }
                  </TableRow>
                );
              })
            }
          </TableBody>
        </Table>
        <TablePagination
            count={data.length}
            component='div'
            labelRowsPerPage={<FormattedMessage id='Datatable.rowsPerPage'/>}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 15]}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
      </Paper>
    );
  }
}

export default withStyles(baseStyle)(SkillWizardTable);