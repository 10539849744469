export default {
  header: {
    gridColumn: 1,
    gridRow: 1,
    flex: '0 0 auto',
    fontSize: '24px',
    color: '#fff',
    marginLeft: '20px',
  },
  toolbar: {
    display: 'grid',
    borderRadius: '5px 5px 0 0',
    backgroundColor: '#383871',
    boxShadow: ' 0px 3px 3px #6b6b6b',
    color: '#fff',
  },
  list: {
    borderRadius: '5px',
    overflowX: 'auto',
  },
  actions: {
    gridColumn: 2,
    gridRow: 1,
    justifySelf: 'end',
    marginRight: '20px',
  },
  searchBar: {
    color: '#000',
    fontWeight: 600,
    width: '80%',
    margin: '10 auto',
    backgroundColor: 'white',
    borderRadius: '4px',
    height: 40,
    alignSelf: 'center'
  },
  select: {
    color: '#fff',
    textAlign: 'center'
  },
  icon: {
    color: '#fff',
  },
  searchIcon: {
    color: '#fff',
  },
  empty: {
    textAlign: 'center'
  },
  hoverRow: {
    '&:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0.07)'
    },
  },
  hover: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  cell: {
    maxWidth: 250,
    wordWrap: 'break-word'
  },
  selected: {
    backgroundColor: '#c7c2cd',
  },
  disabled: {},
  error: {},
  focused: {}
}