import React, { Component } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  TextField,
  withStyles,
  MenuItem,
  FormGroup,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import baseStyle from '../../../../styles/wizardBase';
import { FormattedMessage } from 'react-intl';

class AudioMemoryLevelTemplate extends Component {

  state = {
    collection: 0,
    timedGame: 0,
    numberOfMatches: 8,
    soundToSound: 0,
    tapControl: 0,
  };

  close = () => {
    this.props.close();
  };

  onSubmit = () => {
    const level = { levelVariables: JSON.stringify({...this.state}) };
    this.props.onSubmit(level)
  };

  handleChange = (ev) => {
    const name = ev.target.name;
    const value = ev.target.type === 'number'
      ? ev.target.value * 1 > 0 ? ev.target.value * 1 : ''
      : ev.target.value;
    this.setState({ [name]: value })
  };

  formatName = (name) => {
    return name
      .replace(/"/g, '')
      .replace(/([A-Z0-9])/g, ` $1`)
      .replace(/^./, (str) => str.toUpperCase())
  };

  render() {
    const { classes, open, close, back } = this.props;
    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        open={open}
        onClose={close}
        aria-labelledby='level-dialog-title'
        PaperProps={{ classes:{ root: classes.container }}}
      >
        <DialogTitle disableTypography={true} className={ classes.title } id='level-dialog-title'>
          <Typography className={ classes.titleText } variant='h4'><FormattedMessage id='Level.title'/></Typography>
          <IconButton
            onClick={this.close}
            className={ classes.exitIcon }
            aria-label="close dialog"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <Typography className={classes.subtitle} variant='h6'>
          <FormattedMessage id='Level.add'/> {this.props.game.name} <FormattedMessage id='Level.add2'/>:</Typography>
        <DialogContent>
          <FormGroup className={classes.root}>
            <div className={classes.configureLine}>
              <strong className={classes.configTitle}> <FormattedMessage id='Wizard.AudioMemory.collection'/>: </strong>
              <TextField
                select
                variant='outlined'
                className={classes.configComponent}
                name='collection'
                type='select'
                value={this.state.collection}
                onChange={this.handleChange}
              >
                <MenuItem value={0}> <FormattedMessage id='Wizard.AudioMemory.animals'/> </MenuItem>
                <MenuItem value={1}> <FormattedMessage id='Wizard.AudioMemory.instruments'/> </MenuItem>
                <MenuItem value={2}> <FormattedMessage id='Wizard.AudioMemory.vehicles'/> </MenuItem>
                <MenuItem value={3}> <FormattedMessage id='Wizard.AudioMemory.indoors'/> </MenuItem>
                <MenuItem value={4}> <FormattedMessage id='Wizard.AudioMemory.toys'/> </MenuItem>
              </TextField>
            </div>
            <div className={classes.configureLine}>
              <strong className={classes.configTitle}>  <FormattedMessage id='Level.Audio.timed'/> </strong>
              <TextField
                select
                variant='outlined'
                className={classes.configComponent}
                name='timedGame'
                type='select'
                value={this.state.timedGame}
                onChange={this.handleChange}
              >
                <MenuItem value={0}><FormattedMessage id='General.no'/></MenuItem>
                <MenuItem value={1}><FormattedMessage id='General.yes'/></MenuItem>
              </TextField>
            </div>
            <div className={classes.configureLine}>
              <strong className={classes.configTitle}>  <FormattedMessage id='Level.Audio.matches'/>: </strong>
              <TextField
                variant='outlined'
                className={classes.configComponent}
                name='numberOfMatches'
                type='number'
                helperText={<FormattedMessage id='Wizard.AudioMemory.error'/>}
                value={this.state.numberOfMatches}
                onChange={this.handleChange}
              />
            </div>
            <div className={classes.configureLine}>
              <strong className={classes.configTitle}><FormattedMessage id='Wizard.AudioMemory.sound'/>: </strong>
              <TextField
                select
                variant='outlined'
                className={classes.configComponent}
                name='soundToSound'
                type='select'
                value={this.state.soundToSound}
                onChange={this.handleChange}
              >
                <MenuItem value={0}><FormattedMessage id='General.no'/></MenuItem>
                <MenuItem value={1}><FormattedMessage id='General.yes'/></MenuItem>
              </TextField>
            </div>
            <div className={classes.configureLine}>
              <strong className={classes.configTitle}><FormattedMessage id='Wizard.AudioMemory.tap'/>: </strong>
              <TextField
                select
                variant='outlined'
                className={classes.configComponent}
                name='tapControl'
                type='select'
                value={this.state.tapControl}
                onChange={this.handleChange}
              >
                <MenuItem value={0}><FormattedMessage id='General.no'/></MenuItem>
                <MenuItem value={1}><FormattedMessage id='General.yes'/></MenuItem>
              </TextField>
            </div>
          </FormGroup>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button onClick={back} color='secondary'>
            <FormattedMessage id='General.back'/>
          </Button>
          <Button onClick={this.onSubmit} variant='contained' color='primary'>
            <FormattedMessage id='General.submit'/>
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(baseStyle)(AudioMemoryLevelTemplate);