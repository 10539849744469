import React, { Component } from 'react';
import { List, Typography, withStyles } from '@material-ui/core';
import baseStyle from '../../styles/feed';
import TextPost from './TextPost';
import { FormattedMessage } from 'react-intl';

class Announcements extends Component {

  render() {
    const { classes, posts } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.announcements}>
          <Typography gutterBottom className='title' variant='h4'> <FormattedMessage id='Announcements.title'/> </Typography>
          <List component='div' style={{ padding: '0' }}>
            {
              posts.map(post => (
                <TextPost
                  isAdmin={this.props.isAdmin}
                  key={post.key}
                  picture={post.picture}
                  likeNum={post.likeNum}
                  starNum={post.starNum}
                  post={post}
                  pinned
                />
              ))
            }
          </List>
        </div>
      </div>
    );
  }
}

export default withStyles(baseStyle)(Announcements);