import { Card, CardContent, CardHeader, Dialog, IconButton, Paper, Typography, withStyles } from "@material-ui/core"
import { connect } from "react-redux"
import React, { Component } from "react";
import baseStyle from '../../../styles/dashboard';
import { Close } from "@material-ui/icons";
import { addStoryToStudent, getBookShareUsers, getStaffUser, downloadBook, getBooksForStudent, getBookshareRequestedBooks } from "../../../redux/actions";
import BookShareCredentialsLogin from "../Student/BookShareCredentialsLogin";
import BookShareUserTable from "../../../Social/NetflixSlider/BookShareUserTable";
import { CLEAR_IMPORTED_BOOK, RESET_IMPORT_MODAL, SAVE_IMPORTED_BOOK } from "../../../redux/constants";
import LoadingModal from '../Student/LoadingModal';
import BookShareRequestModal from "./BookShareRequestModal";
import BookShareRestrictedModal from "./BookShareRestrictedModal";

class ImportBooks extends Component {

    constructor(props) {
        super(props);

        this.state = {
            imported: [],
            loginRequired: false,
            showImportMessage: false,
            importErrorMessage: '',
            importSlide: null,
            messageLink: '',
            bookShareUser: [],
            showStudent: false,
            showSelectedRow: null,
            openModal: false,
            modalType: '',
            showRequestModal: false
        }
    }

    closeModal = () => {
        this.setState({ openModal: false, modalType: '' })
    }

    componentDidMount() {
        if (this.props.booksImport && this.props.booksImport.bookSearchToggle) {
            const filteredBook = this.props.bookShareList && this.props.bookShareList.filter((book) => book.id == this.props.story.id);
            if (filteredBook && filteredBook[0].storyDataId && filteredBook[0].storyDataId != null)
                this.props.dispatch(addStoryToStudent(this.props.storyId, filteredBook[0].storyDataId));
            else
                filteredBook && this.props.dispatch(downloadBook(this.props.story.id, this.props.storyId, filteredBook[0]));
        }
        else {
            if (this.props.searchFromBookShare) {
                const filteredBook = this.props.bookShareList && this.props.bookShareList.filter((book) => book.id == this.props.story.id);
                if (filteredBook && filteredBook[0].storyDataId && filteredBook[0].storyDataId != null)
                    this.props.dispatch(addStoryToStudent(this.props.storyId, filteredBook[0].storyDataId));
                else
                    filteredBook && this.props.dispatch(downloadBook(this.props.story.id, this.props.storyId, filteredBook[0]));
            }
            else
                this.props.dispatch(addStoryToStudent(this.props.storyId, this.props.story.id));
        }
        this.setState({
            importSlide: this.props.story
        });
    }
    componentDidUpdate(prevProps) {
        if (this.props.bookShareUsers && this.props.bookShareUsers !== prevProps.bookShareUsers) {
            this.setState({
                bookShareUser: this.props.bookShareUsers.data
            })
        }
        if (prevProps.booksImport != this.props.booksImport && this.props.booksImport && this.props.booksImport.message === "Cannot retrieve organization members") {
            this.setState({
                showImportMessage: true,
                importErrorMessage: "There was an error when trying to request this book from Bookshare, this could either be:\n" +
                    "\n" +
                    "1). That the book you are requesting is not available in your region\n" +
                    "\n" +
                    "Or\n" +
                    "\n" +
                    "2). Your Bookshare account is not active or has not been fully set up.  Please login at https://www.bookshare.org/cms/ to verify that your account is setup properly\n" +
                    "\n" +
                    "If you continue to experience this issue, please contact support@ObjectiveEd.com\n",
                loginRequired: false,
                messageLink: ''
            });
            this.props.dispatch({
                type: RESET_IMPORT_MODAL
            });
        }
        else if ((prevProps.booksImport != this.props.booksImport &&
            (this.props.booksImport.message === "User is Not Logged into bookshare"
                || this.props.booksImport.message === "Authentication has expired"))
        ) {
            this.setState({
                showImportMessage: false,
                importErrorMessage: "",
                loginRequired: true,
                messageLink: ''
            });
            this.props.dispatch({
                type: RESET_IMPORT_MODAL
            });
        }
        else if (this.props.booksImport && prevProps.booksImport != this.props.booksImport
            && this.props.booksImport.message === "Cannot find Student Match") {

            this.setState({
                showImportMessage: true,
                importErrorMessage: "We can't find this student in your Bookshare account, please check the list for the correct student and press the (+) icon.",
                loginRequired: false,
                messageLink: 'https://www.bookshare.org/orgAccountMembers'
            })
            this.props.dispatch({
                type: RESET_IMPORT_MODAL
            });
            if (this.state.bookShareUser.length == 0)
                this.props.dispatch(getBookShareUsers());
        }
        else if (this.props.booksImport && prevProps.booksImport != this.props.booksImport
            && this.props.booksImport.message === "Too Many Requests") {
            this.setState({
                loginRequired: false,
                showRequestModal: true
            });
            this.props.closeLoading();
            this.props.dispatch({
                type: RESET_IMPORT_MODAL
            });
        }
        else  if (this.props.booksImport && prevProps.booksImport != this.props.booksImport
            && this.props.booksImport.message === "Restricted Title") {
            this.setState({
                loginRequired: false,
                showRestrictedModal: true
            });
            this.props.closeLoading();
            this.props.dispatch({
                type: RESET_IMPORT_MODAL
            });
        }
        if (this.props.booksImport && prevProps.booksImport != this.props.booksImport && this.props.booksImport.studentBookImported) {
            // After the book is successfully imported
            this.props.handleImportedBook(this.state.importSlide);
            this.props.handleUnmount();
            if (this.props.booksImport && !this.props.booksImport.bookSearchToggle)
                this.props.dispatch({
                    type: SAVE_IMPORTED_BOOK,
                    payload: this.state.importSlide
                });
            this.setState({ showImportMessage: false });
            this.props.dispatch({ type: RESET_IMPORT_MODAL });
            this.props.dispatch(getBooksForStudent(this.props.storyId));
            this.props.dispatch(getBookshareRequestedBooks(this.props.storyId));
            this.props.closeLoading();
        }

        if (this.props.importBooks != prevProps.importBooks && this.props.importBooks && this.props.importBooks.bookImported === true) {
            // this.setState({
            //     loginRequired: false
            // })
            this.props.dispatch({ type: CLEAR_IMPORTED_BOOK })
            this.props.dispatch(getStaffUser());
            if (this.props.booksImport && this.props.booksImport.bookSearchToggle) {
                const filteredBook = this.props.bookShareList && this.props.bookShareList.filter((book) => book.id == this.props.story.id);
                if (filteredBook && filteredBook[0].storyDataId && filteredBook[0].storyDataId != null)
                    this.props.dispatch(addStoryToStudent(this.props.storyId, filteredBook[0].storyDataId));
                else
                    filteredBook && this.props.dispatch(downloadBook(this.props.story.id, this.props.storyId, filteredBook[0]));

            }
            else {
                if (this.props.storyId && this.state.importSlide) {
                    let id = this.props.story.storyDataId || this.props.story.id;
                    this.props.dispatch(addStoryToStudent(this.props.storyId, id));
                }
            }
        }

    }
    closeLoginModal = () => {
        this.setState({
            loginRequired: false
        });
        if (this.props.user && this.props.user.hasBookShareCredentials == false)
            this.props.handleUnmount();
    }
    closeImportMessageModal = () => {

        this.setState({
            showImportMessage: false
        });
        this.props.handleUnmount();
    }
    addStudent = () => {
        this.setState({
            showStudent: true
        })
    }
    handleStudentWizardClose = () => {
        this.setState({
            showStudent: false
        })
    }
    handleRequestClose = () => {
        this.setState({
            showRequestModal: false
        })
    }
    handleRestrictedClose = () => {
        this.setState({
            showRestrictedModal: false
        })
    }
    render() {
        const { booksImport, student } = this.props;
        const { loginRequired, showImportMessage, importErrorMessage, messageLink, bookShareUser, importSlide } = this.state;
        return (
            <>
                {
                    (loginRequired)
                        ? <BookShareCredentialsLogin
                            openLoading={loginRequired}
                            exitFunc={this.closeLoginModal} /> : null
                }
                <Dialog open={showImportMessage} maxWidth='lg' onClose={() => this.closeImportMessageModal()}>
                    <Paper className='list'>
                        <Card aria-label="Message" >
                            <CardHeader style={{ backgroundColor: '#00729b', marginBottom: '20px' }} action={
                                <IconButton onClick={this.closeImportMessageModal}>
                                    <Close />
                                </IconButton>}
                                title={<Typography variant='h5' className='header'>{bookShareUser && bookShareUser.length > 0 ? "Link Student" : "Something went wrong"}</Typography>}
                            >
                            </CardHeader>
                            <CardContent className='synopsis_description' style={{ textAlign: 'center', fontFamily: "Calibri, sans-serif" }} >
                                <div style={{ textAlign: 'center' }} >{importErrorMessage}
                                    <br /> </div>

                                {messageLink && messageLink.length > 0 && bookShareUser && bookShareUser.length > 0
                                    ?
                                    <>
                                        <br />
                                        <BookShareUserTable student={student}
                                            storyId={this.props.storyId}
                                            importSlide={importSlide}
                                            closeImportMessageModal={this.closeImportMessageModal}
                                            handleImportedBook={this.props.handleImportedBook}
                                            handleUnmount={this.props.handleUnmount}
                                            messageLink={messageLink}
                                            showStudent={this.state.showStudent}
                                            addStudent={this.addStudent}
                                            handleStudentWizardClose={this.handleStudentWizardClose}
          />
                                    </>
                                    : null
                                }
                            </CardContent>
                        </Card>
                    </Paper>
                </Dialog>
                {
                    this.state.openModal &&
                    <LoadingModal openLoading={this.state.openModal} type={this.state.modalType} exitFunc={this.closeModal} />
                }
                {
                    this.state.showRequestModal &&
                    <BookShareRequestModal open={this.state.showRequestModal} close={this.handleRequestClose} />
                }
                {
                 this.state.showRestrictedModal &&
                    <BookShareRestrictedModal open={this.state.showRestrictedModal} close={this.handleRestrictedClose} />
                }
            </>
        )
    }
}

const mapStateToProps = ({ shared: { user }, platform: { booksImport, importBooks, bookShareUsers, student, bookShareList } }) => {
    return {
        user,
        booksImport,
        importBooks,
        bookShareUsers,
        student,
        bookShareList
    }
};
export default connect(mapStateToProps)(withStyles(baseStyle)(ImportBooks));