import {ADD_SKILL, GET_SKILLS_FOR_GOAL, DELETE_SKILL, PATCH_SKILL} from '../../constants';

export default function (state = [], action) {
  switch(action.type){
    case GET_SKILLS_FOR_GOAL:
      return action.skills;
    case ADD_SKILL:
      return [...state, action.skill];
    case PATCH_SKILL:
      return state.map(skill => skill.id === action.skill.id ? action.skill : skill);
    case DELETE_SKILL:
      return state.filter(skill => skill.id !== action.skill.id);
    default:
      return state;
  }
}