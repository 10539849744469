import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Divider,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Menu,
  Paper,
  Typography,
  Dialog,
  withStyles,
  DialogContent,
  Icon
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons/index';
import baseStyle from '../../../styles/header';

class HelpMenu extends Component {

  state = {
    open: false,
    videoId: '',
  };

  handleOpen = (id) => {
    this.setState({ open: true, videoId: id })
  };

  handleClose = () => {
    this.setState({ open: false, videoId: '' });
  };

  render() {
    const { classes, page } = this.props;
    return (
      <Menu
        classes={{ list: classes.helpMenu }}
        open={this.props.open}
        anchorEl={this.props.anchor}
        getContentAnchorEl={null}
        onClose={() => this.props.handleClose('help')}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Typography variant='h6' className={classes.helpText}>Need help with {page ? `the ${page.name}` : 'this'} page?</Typography>
        {
          page &&
          <div>
            <Typography variant='subtitle2' className={classes.helpText}>Frequently Asked Questions</Typography>
            <Divider />
            <div className={classes.section}>
              {
                page.questions.map((question,index) => (
                  <ExpansionPanel key={`${question._id}-${index}`}>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMore />}
                      aria-controls={`q${index}-content`}
                      id={`q${index}-header`}
                    >
                      <Typography variant='subtitle1'>{question.question}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Typography variant='subtitle2'>
                        {question.answer}
                      </Typography>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                ))
              }
            </div>
            <Typography variant='subtitle2' className={classes.helpText}>Guides</Typography>
            <Divider />
            <div className={classes.section}>
              {
                page.guides.map((guide, index) => (
                  guide.type === 'video'
                    ? <Paper
                      key={`${guide._id}-${index}`}
                      onClick={() => this.handleOpen(guide.url)}
                      className={classes.guide}
                    >
                      <Typography className={classes.guideText}>
                        <Icon className='fas fa-video icon'/> {guide.description}
                      </Typography>
                    </Paper>
                    : <Paper
                      key={`${guide._id}-${index}`}
                      className={classes.guide}
                    >
                      <a className={classes.guideLink} href={guide.url} target='_blank' rel='noopener noreferrer'>
                        <Typography className={classes.guideText}>
                          <Icon className='fas fa-file-alt icon'/>{guide.description}
                        </Typography>
                      </a>
                    </Paper>
                ))
              }
            </div>
            <Typography variant='subtitle2' className={classes.helpText}>Still need help?</Typography>
            <Divider />
          </div>
        }
        <div className={classes.section}>
          {
            this.props.user && this.props.user.type == "Individual"
              ? <Typography variant='body1'>Email us at &nbsp;
                <a href='mailto: support@objectiveed.com'>support@objectiveed.com</a>
                <br />
                <a href="#" style={{ textDecoration: 'underline' }} onClick={this.props.handleTraining}> Click here for training</a>
              </Typography>
              : <Typography variant='body2'>
                Head over to the <Link to='/groups/4e899577-83e2-4ba4-ac65-c22f06397b6c'>Objective Ed Support Group</Link> on our Professional Network
                and see if your question has been asked over there.
              </Typography>}
        </div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          maxWidth={false}
        >
          <DialogContent>
            <iframe
              title='guide-video'
              width="640"
              height="360"
              src={`https://www.youtube.com/embed/${this.state.videoId}?modestbranding=1&rel=0`}
              frameBorder='0'
              allow='accelerometer; autoplay; encrypted-media; gyroscope;'
              allowFullScreen
            />
          </DialogContent>
        </Dialog>
      </Menu>
    );
  }
}

export default withStyles(baseStyle)(HelpMenu);