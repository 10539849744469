import React, { Component } from 'react';
import GoalDetail from './GoalDetail';
import { Typography, Button, withStyles, TextField, InputAdornment, IconButton, Icon } from '@material-ui/core';
import baseStyle from '../../styles/goalList';
import SheetsDetail from './SheetsDetail';
import { FormattedMessage } from 'react-intl';

class GoalsList extends Component {

  state = {
    showMore: this.props.showMore
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.showMore !== this.state.showMore) {
      this.setState({showMore: nextProps.showMore})
    }
  }

  showMore = () => {
    this.setState(({ showMore }) => ({ showMore: !showMore }))
  };

  render() {
    const { goals, sheets, classes, selected, group } = this.props;
    const { showMore } = this.state;
    return (
      <div ref={this.props.refProp} className={classes.container}>
        <div className={classes.title}>
          <Typography className='title' variant='h4'><FormattedMessage id='Shared'/></Typography>
          <TextField
            className='search'
            color='inherit'
            variant='outlined'
            InputProps={{
              root: classes.border,
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton>
                    <Icon className='fas fa-search button' />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Button color='inherit' onClick={this.showMore}>
            {
              showMore
                ? <FormattedMessage id='General.viewLess'/>
                : <FormattedMessage id='General.viewMore'/>
            }
          </Button>
        </div>
        {
          showMore &&
          <div className={classes.list}>
            {
              goals.map(goal =>
                <GoalDetail
                  selected={selected}
                  key={goal.key}
                  goal={goal}
                  group={group}
                  profile={this.props.profile}
                />
              )
            }
            {
              sheets.map(sheet =>
                <SheetsDetail
                  selected={selected}
                  key={sheet.key}
                  sheet={sheet}
                  group={group}
                  profile={this.props.profile}
                />
              )
            }
          </div>
        }
      </div>
    );
  }
}

export default withStyles(baseStyle)(GoalsList);