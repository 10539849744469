import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormGroup, IconButton, MenuItem, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { updateStoryData } from '../../../../redux/actions';
import baseStyle from '../../../../styles/wizardBase';

class StudentStoryEdit extends Component {
    state = {
        ...this.props.data,
    };

    handleChange = (ev) => {
        this.setState({ [ev.target.name]: ev.target.value })
    };


    submit = (ev) => {
        ev.preventDefault();
        let { name, id, description, status, wordRate, importSource, author, titleImageSrc, isbn, sourceId, gradeLevel, offset, ending } = this.state;
        let storyDto = { name, id, description, status, wordRate, importSource, author, titleImageSrc, isbn, sourceId, gradeLevel, offset, ending }
        this.props.updateStoryData(storyDto);
        this.props.close();
    };

    render() {
        const { classes, data } = this.props;
        return (
            <Dialog
                fullWidth={true}
                maxWidth="lg"
                open={this.props.open}
                onClose={this.props.close}
                aria-labelledby='form-dialog-title'
                PaperProps={{ classes: { root: classes.container } }}>
                <DialogTitle disableTypography={true} className={classes.title} id='form-dialog-title'>
                    <Typography className={classes.titleText} variant='h4'>
                        <FormattedMessage id="General.edit" />  {data.name}
                    </Typography>
                    <IconButton
                        onClick={this.props.close}
                        className={classes.exitIcon}
                        aria-label="close dialog">
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent classes={{ root: classes.scroll }}>
                    <FormGroup>
                        <div className={classes.storyDataElement}>
                            <TextField
                                variant='outlined'
                                id='student-edit-name'
                                label={<FormattedMessage id="General.name" />}
                                name='name'
                                type='text'
                                value={this.state.name}
                                onChange={this.handleChange}
                            />
                        </div>
                        <br />
                        <TextField
                            variant='outlined'
                            id='student-edit-description'
                            label={<FormattedMessage id="General.description" />}
                            name='description'
                            type='text'
                            value={this.state.description}
                            classes={{ root: classes.elementMargin }}
                            multiline
                            maxRows={6}
                            onChange={this.handleChange}
                        />
                        <div className={classes.storyDataElement}>
                            <TextField
                                variant='outlined'
                                select
                                id='student-edit-status'
                                classes={{ root: classes.elementMargin }}
                                label={<FormattedMessage id="General.status" />}
                                name='status'
                                type='text'
                                value={this.state.status}
                                onChange={this.handleChange}
                            >
                                <MenuItem key='Catalog' value={'Catalog'}>Catalog</MenuItem>
                                <MenuItem key='Processed' value={'Processed'}>Processed</MenuItem>
                                <MenuItem key='Loading' value={'Loading'}>Loading</MenuItem>
                                <MenuItem key='Broken' value={'Broken'}>Broken</MenuItem>
                            </TextField>
                            <TextField
                                variant='outlined'
                                id='student-edit-wordRate'
                                classes={{ root: classes.elementMargin }}
                                label={<FormattedMessage id="General.wordRate" />}
                                name='wordRate'
                                type='text'
                                value={this.state.wordRate}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className={classes.storyDataElement}>
                            <TextField
                                variant='outlined'
                                id='student-edit-importSource'
                                classes={{ root: classes.elementMargin }}
                                label={<FormattedMessage id="General.source" />}
                                name='importSource'
                                type='text'
                                value={this.state.importSource}
                                onChange={this.handleChange}
                            />
                            <TextField
                                variant='outlined'
                                id='student-edit-author'
                                classes={{ root: classes.elementMargin }}
                                label={<FormattedMessage id="General.author" />}
                                name='author'
                                type='text'
                                value={this.state.author}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className={classes.storyDataElement}>
                            <TextField
                                variant='outlined'
                                id='student-edit-image'
                                label={<FormattedMessage id="General.image" />}
                                name='titleImageSrc'
                                type='text'
                                value={this.state.titleImageSrc}
                                onChange={this.handleChange}
                            />
                            <TextField
                                variant='outlined'
                                id='student-edit-isbn'
                                classes={{ root: classes.elementMargin }}
                                label={<FormattedMessage id="General.isbn" />}
                                name='isbn'
                                type='text'
                                value={this.state.isbn}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className={classes.storyDataElement}>
                            <TextField
                                variant='outlined'
                                id='student-edit-lexileLevel'
                                classes={{ root: classes.elementMargin }}
                                label={<FormattedMessage id="General.gradeLevel" />}
                                name='gradeLevel'
                                type='text'
                                value={this.state.gradeLevel}
                                onChange={this.handleChange}
                            />

                            <TextField
                                variant='outlined'
                                id='student-edit-sourceId'
                                classes={{ root: classes.elementMargin }}
                                label={<FormattedMessage id="General.sourceId" />}
                                name='sourceId'
                                type='text'
                                value={this.state.sourceId}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className={classes.storyDataElement}>
                            <TextField
                                variant='outlined'
                                id='student-edit-offset'
                                classes={{ root: classes.elementMargin }}
                                label={<FormattedMessage id="General.offset" />}
                                name='offset'
                                type='text'
                                value={this.state.offset}
                                onChange={this.handleChange}
                            />
                            <TextField
                                variant='outlined'
                                id='student-edit-ending'
                                classes={{ root: classes.elementMargin }}
                                label={<FormattedMessage id="General.ending" />}
                                name='ending'
                                type='text'
                                value={this.state.ending}
                                onChange={this.handleChange}
                            />
                        </div>
                    </FormGroup>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <Button onClick={this.props.close} color='secondary'>
                        <FormattedMessage id="General.cancel" />
                    </Button>
                    <Button onClick={this.submit} color='primary'>
                        <FormattedMessage id="General.save" />
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapState = ({ platform: { students, staffStudents }, shared: { error, account, user } }) => {
    return {
        students,
        staffStudents,
        user
    }
};

const mapDispatch = (dispatch) => {
    return {
        updateStoryData: (storyDto) => dispatch(updateStoryData(storyDto))
    }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(StudentStoryEdit));