import {
    CREATE_STUDENT,
    FETCH_ALL_STUDENT_GAMES,
    FETCH_ALL_GOALS,
    FETCH_ALL_STUDENTS,
    GET_STUDENT,
    PATCH_STUDENT,
    GET_STUDENT_PROFILE,
    CREATE_STUDENT_PROFILE,
    PATCH_STUDENT_PROFILE,
    CREATE_STUDENT_GAME,
    FETCH_STUDENT_TUTORIALS,
    DELETE_STUDENT_TUTORIAL,
    FETCH_DELETED_STUDENTS,
    GET_STUDENTS_STAFF,
    ROOT_URL,
    REMOVE_STUDENT,
    REMOVE_DELETED_STUDENT,
    HANDLE_ERROR,
    FETCH_INFO,
    ADD_STAFF_STUDENT
} from '../../constants';
import axios from 'axios';

axios.defaults.withCredentials = true;

export const fetchDeletedStudents = (history) => {
    const url = `${ROOT_URL}/admin/students/deleted`;
    return (dispatch) => {
        axios.get(url)
            .then((response) => {
                console.log(response.data);
                dispatch({
                    type: FETCH_DELETED_STUDENTS,
                    deletedStudents: response.data
                })
            })
    }
}

export const deleteStudent = (student, history) => {
    const url = `${ROOT_URL}/admin/students/${student.id}`;
    console.log("Here");
    return (dispatch) => {
        console.log("Here")
        axios.delete(url)
            .then((response) => {
                dispatch({
                    type:REMOVE_STUDENT,
                    student: student
                })
            });
    }
}

export const fetchStudents = (history) => {
    const url = `${ROOT_URL}/admin/students`;
    return (dispatch) => {
        axios
            .get(url)
            .then((response) => {
                if (typeof (response.data) === 'object') {
                    dispatch({
                        type: FETCH_ALL_STUDENTS,
                        students: response.data
                    })
                } else {
                    history.push('/login');
                    dispatch({
                        type: HANDLE_ERROR,
                        error: {type: 'sessionExp', msg: 'Your session has expired, please log back in'}
                    })
                }
            })
            .catch(err => console.error(err))
    }
};

export const getStudent = (id) => {
    const url = `${ROOT_URL}/students/${id}`;
    return (dispatch) => {
        axios
            .get(url)
            .then((response) => {
                dispatch({
                    type: GET_STUDENT,
                    student: response.data
                })
            })
            .catch(err => console.log(err))
    }
};

export const getStaffForStudent = (studentId) => {
    const url = `${ROOT_URL}/students/${studentId}/staff`;
    return (dispatch) => {
        axios
            .get(url)
            .then((response) => {
                dispatch({
                    type: GET_STUDENTS_STAFF,
                    studentStaff: response.data
                })
            })
    };
};


export const createStudent = (student, ignoreWarning, defaultTag,staffId) => {
    let url = `${ROOT_URL}/students`;
    if(staffId) {
        url +="?staffId="+staffId;
    }
    const id = student.id ? student.id : -1;
    return (dispatch) => {
        return checkGamerTag(id, student.gamerTag)
            .then((unique) => {
                if (unique) {
                    return axios
                        .post(url, student)
                        .then((response) => {
                            if (student.password) {
                                axios
                                    .post(`${ROOT_URL}/students/${response.data.id}/login?ignoreWarning=${ignoreWarning}&?defaultGamerTag=${defaultTag}`)
                                    .then(() => {
                                        axios
                                            .post(`${ROOT_URL}/students/${response.data.id}/password`, student.password, {headers: {'Content-Type': 'text/plain'}})
                                            .catch(err => console.log(err))
                                    })
                                    .catch(err => console.log(err))
                            }
                            dispatch({
                                type: CREATE_STUDENT,
                                student: response.data
                            });
                            return response.data
                        })
                        .catch(err => console.error(err))
                } else {
                    return {error: 'Username already exists!'}
                }
            })
    }
};

export const createStudents = (students,staffId) => {
    let url = `${ROOT_URL}/staff/${staffId}/students/create`;
    return (dispatch) => {
            return axios
                .post(url, students)
                .then((response) => {
                    dispatch({
                        type: CREATE_STUDENT,
                        student: response.data
                    });
                    dispatch({
                        type: ADD_STAFF_STUDENT,
                        students: response.data
                    });
                    return response.data
                })
                .catch(err => console.error(err))
            };
};

export const patchStudent = (student, staff) => {
    const url = `${ROOT_URL}/students`;
    console.log(staff);
    return (dispatch) => {
        return checkGamerTag(student.id, student.gamerTag)
            .then((unique) => {
                if (unique) {
                    return axios
                        .patch(url, student)
                        .then(() => {
                            dispatch({
                                type: PATCH_STUDENT,
                                student
                            })
                        })
                        .then(() => {
                            if (staff) {
                                console.log('put going');
                                return axios
                                    .put(`${ROOT_URL}/students/${student.id}/staff`, staff)
                                    .catch(err => console.log(err))
                            }
                        });
                } else {
                    return {error: 'Username already exists!'}
                }
            })
    }
};

export const getStudentGames = (studentId) => {
    const url = `${ROOT_URL}/students/${studentId}/games`;
    return (dispatch) => {
        axios
            .get(url)
            .then((response) => {
                dispatch({
                    type: FETCH_ALL_STUDENT_GAMES,
                    games: response.data
                })
            })
            .catch(err => console.error(err))
    }
};

export const addGameToStudent = (game, studentId, history) => {
    const url = `${ROOT_URL}/students/${studentId}/games`;
    return (dispatch) => {
        axios
            .post(url, game)
            .then((response) => {
                if (typeof response.data === 'object') {
                    dispatch({
                        type: CREATE_STUDENT_GAME,
                        game: response.data
                    })
                } else {
                    history.push('/login');
                    dispatch({
                        type: HANDLE_ERROR,
                        error: {type: 'sessionExp', msg: 'Your session has expired, please log back in'}
                    })
                }
            })
            .catch(err => console.error(err))
    }
};

export const getStudentGoals = (studentId, history) => {
    const url = `${ROOT_URL}/students/${studentId}/goals`;
    return (dispatch) => {
        axios
            .get(url)
            .then((response) => {
                if (typeof response.data === 'object') {
                    dispatch({
                        type: FETCH_ALL_GOALS,
                        goals: response.data
                    })
                } else {
                    history.push('/login');
                    dispatch({
                        type: HANDLE_ERROR,
                        error: {type: 'sessionExp', msg: 'Your session has expired, please log back in'}
                    })
                }
            })
            .catch(err => console.error(err))
    }
};

export const getStudentProfile = (id) => {
    const url = `${ROOT_URL}/students/${id}/profile`;
    return (dispatch) => {
        axios
            .get(url)
            .then((response) => {
                dispatch({
                    type: GET_STUDENT_PROFILE,
                    studentProfile: response.data
                })
            })
            .catch(err => console.log(err))
    }
};

export const createStudentProfile = (studentProfile) => {
    const url = `${ROOT_URL}/students/${studentProfile.studentId}/profile`;
    return (dispatch) => {
        axios
            .post(url, studentProfile)
            .then(() => {
                dispatch({
                    type: CREATE_STUDENT_PROFILE,
                    studentProfile
                })
            })
            .catch(err => console.log(err))
    }
};

export const patchStudentProfile = (studentProfile) => {
    const url = `${ROOT_URL}/students/${studentProfile.studentId}/profile`;
    return (dispatch) => {
        axios
            .put(url, studentProfile)
            .then(() => {
                dispatch({
                    type: PATCH_STUDENT_PROFILE,
                    studentProfile
                })
            })
            .catch(err => console.log(err))
    }
};

export const fetchTutorialsForStudent = (studentId) => {
    const url = `${ROOT_URL}/students/${studentId}/tutorials`;
    return (dispatch) => {
        axios
            .get(url)
            .then((response) => {
                dispatch({
                    type: FETCH_STUDENT_TUTORIALS,
                    tutorials: response.data
                })
            })
    }
};

export const deleteTutorialsForStudent = (studentId, tutorial) => {
    const url = `${ROOT_URL}/students/${studentId}/tutorials`;
    return (dispatch) => {
        axios
            .delete(url, {data: tutorial})
            .then((response) => {
                dispatch({
                    type: DELETE_STUDENT_TUTORIAL,
                    tutorial
                })
            })
    }
};

export const deleteStudentPernament = (student) => {
    const url = `${ROOT_URL}/admin/students/${student.id}/permanent`;
    return (dispatch) => {
        axios.delete(url)
            .then((response) => {
                dispatch({
                    type:REMOVE_DELETED_STUDENT,
                    student: student
                });
            });
    }
}

export const deleteStudentRestore = (student) => {
    const url = `${ROOT_URL}/admin/students/${student.id}/restore`;
    return (dispatch) => {
        axios.delete(url)
            .then((response) => {
                dispatch({
                    type:REMOVE_DELETED_STUDENT,
                    student: student
                });
            });
    }
}

export const checkGamerTag = (studentId, tag) => {
    const url = `${ROOT_URL}/students/${studentId}/gamerTag?gamerTag=${tag}`;
    return axios
        .get(url)
        .then(response => response.data)
        .catch(err => console.log(err))
};

export const addProduct = (studentId, product) => {
    const url = `${ROOT_URL}/students/${studentId}/products`;
    return (dispatch) => {
        axios
            .post(url, product, {
                headers: {'Content-Type': 'text/plain'}
            })
            .then(response => {
                dispatch({
                    type: PATCH_STUDENT,
                    student: response.data
                })
            })
            .catch(err => console.log(err))
    }
};

export const removeProduct = (studentId, product) => {
    const url = `${ROOT_URL}/students/${studentId}/products`;
    return (dispatch) => {
        axios
            .delete(url, {
                data: product,
                headers: {'Content-Type': 'text/plain'}
            })
            .then(response => {
                dispatch({
                    type: PATCH_STUDENT,
                    student: response.data
                })
            })
            .catch(err => console.log(err))
    }
};
export const checkStudentProfile = (id) =>{
        return new Promise((resolve, reject) => {
            const url = `${ROOT_URL}/students/${id}/profile`;
            axios
                .get(url)
                .then((response) => {
                    resolve("Student profile found");
                })
                .catch(err => reject("Student profile not found"))
            
        });
    
  }

  export const fetchStudentsBookInfo = () => {
    const url = `${ROOT_URL}/admin/buddy-books/dashboard`;  
    return (dispatch) => {
      return axios
        .get(url)
        .then((response) => {    
            dispatch({
                type:FETCH_INFO,
                payload:response.data
            })
        })
        .catch(err => console.log(err))
    } 
    
  }