export default {
  goal: {
    padding: '10px 20px',
    marginBottom: 10,
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    gridAutoFlow: 'column',
    gridColumnGap: '10px',
    gridColumn: 1,
    marginTop: 10,
    alignSelf: 'center',
    justifySelf: 'start',
    '& .pic': {
      gridRow: '1/ span 2',
      justifySelf: 'start',
      alignSelf: 'center',
      marginLeft: 5,
    },
    '& .text': {
      gridRow: 1,
      alignSelf: 'center',
      justifyContent: 'start',
      marginLeft: 18
    },
    '& .date': {
      gridRow: 2,
      gridColumn: 2,
      display: 'grid',
      gridAutoFlow: 'column',
      gridTemplateColumns: 'auto',
      gridColumnGap: '5px',
      justifySelf: 'start',
      alignItems: 'center',
      marginLeft: 20
    },
    '& .subtext': {
      alignSelf: 'center',
      justifyContent: 'start',
    },
  },
  comment: {
    display: 'grid',
    gridRow: 2,
    marginTop: 10,
    gridTemplateColumns: 'auto 1fr',
    '& .icon': {
      color: 'black',
      marginRight: 10,
      paddingRight: 10
    }
  },
  goalButtons: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridColumn: 3,
    gridRow: '1/ span 3',
    justifySelf: 'end',
    alignSelf: 'center',
    '& .likeNum': {
      justifySelf: 'start',
      alignSelf: 'center',
      paddingRight: 20
    }
  },
  highlight: {
    backgroundColor: '#eee',
  },
  liked: {
    color: '#0087b6'
  },
}