import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, FormGroup, Typography, IconButton, TextField } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { fetchSkillDatabanks } from '../../../../redux/actions';
import { getStaffById, getStaffProfile } from '../../../../redux/actions';
import baseStyle from '../../../../styles/wizardBase';
import SkillWizardTable from './SkillWizardTable';
import { FormattedMessage } from 'react-intl';

class SkillWizardPage1 extends Component {

  state = {
    skill: { },
    selected: false,
    targetPercentage: '100',
    targetPrompts: '',
    error: false
  };

  componentDidMount() {
    this.props.getStaffById(this.props.user.staffId);
    this.props.getStaffProfile(this.props.user.staffId);
  }


  close = () => {
    this.setState({ skill: {}, selected: false, targetPercentage: '', targetPrompts: '' });
    this.props.close();
  };

  onClick = (ev) => {
    this.setState({ skill: ev ? ev : {}, selected: true })
    this.checkSheetSize(ev);
  };

  checkSheetSize = (skill) => {
    let error = false;
    if(this.props.staffProfile.prototype === "Yes") {
      error = !this.props.isStaff && !!skill.sheetSize && (this.props.studentProfile.ipadSize !== skill.sheetSize);
    }
    this.setState({ error })
  };

  openTable = () => {
    this.setState({ skill: {}, selected: false })
  };

  handleChange = (ev) => {
    this.setState({ [ev.target.name]: ev.target.value })
  };

  onSubmit = () => {
    this.props.onSubmit(this.state);
  };

  closeError = () => {
    this.setState({ error: false, skill: {}, selected: false })
  };

  continue = () => {
    this.setState({ error: false })
  };

  render()  {
    const { classes, open } = this.props;
    const { skill, selected, targetPercentage } = this.state;
    return (
      <>
        <Dialog
          disableEscapeKeyDown
          fullWidth={true}
          open={open}
          onClose={this.close}
          aria-labelledby='skill-dialog-title'
          PaperProps={{ classes:{ root: classes.container }}}
        >
          <DialogTitle disableTypography={true} className={ classes.title } id='skill-dialog-title'>
            <Typography className={ classes.titleText } variant='h4'><FormattedMessage id='Wizard.Skill'/></Typography>
            <IconButton
              onClick={this.close}
              className={ classes.exitIcon }
              aria-label="close dialog"
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent classes={{ root: classes.dialog }}>
            {
              !skill.id &&
              <Typography variant='h6'><FormattedMessage id='Wizard.Skill.select'/>:</Typography>
            }
            <FormGroup classes={{ root: classes.root }}>
              {
                skill.name &&
                <>
                  <Typography className={classes.skillName} variant='h2'><strong>{skill.name}</strong></Typography>
                  <FormGroup className={classes.skillDescription}>
                    <Typography variant='h6'><FormattedMessage id='General.description'/>: </Typography>
                    <Typography variant='body1'>{skill.description}</Typography>
                  </FormGroup>
                  <FormGroup classes={{ root: classes.configureSkillLine }}>
                    {/*<Typography variant='h6'><FormattedMessage id='General.difficulty'/>: </Typography>*/}
                    {/*<Typography variant='body1'>{skill.difficulty}</Typography>*/}
                    <TextField
                      disabled
                      variant='outlined'
                      id='difficulty'
                      label={<FormattedMessage id='General.difficulty'/>}
                      name='difficulty'
                      value={skill.difficulty}
                    />
                  </FormGroup>
                  <FormGroup classes={{ root: classes.configureSkillLine }}>
                      {/*<Typography variant='h6'><FormattedMessage id='General.area'/>: </Typography>*/}
                      {/*<Typography variant='body1'>{skill.area}</Typography>*/}
                     <TextField
                       disabled
                       variant='outlined'
                       id='area'
                       label={<FormattedMessage id='General.area'/>}
                       name='area'
                       value={skill.area}
                     />
                  </FormGroup>
                    <FormGroup classes={{ root: classes.configureSkillLine }}>
                      <TextField
                        variant='outlined'
                        id='target-percentage'
                        label={<FormattedMessage id='General.targetPercentage'/>}
                        name='targetPercentage'
                        type='number'
                        value={this.state.targetPercentage}
                        onChange={this.handleChange}
                        helperText='A Target Percentage is Required!'
                        />
                    </FormGroup>
                </>
              }
              {
                !selected
                  ? <SkillWizardTable selected={skill} onClick={this.onClick} skills={this.props.skillDatabanks} />
                  : <Button
                    variant='contained'
                    className={classes.skillWizardBtn}
                    onClick={this.openTable}
                  >
                    <FormattedMessage id='Wizard.Skill.different'/>
                </Button>
              }
            </FormGroup>
            <DialogActions className={classes.buttons}>
              <Button onClick={this.close} color='secondary'>
                <FormattedMessage id='General.cancel'/>
              </Button>
              <Button onClick={this.onSubmit} disabled={ !skill.id || !targetPercentage } color='primary'>
                <FormattedMessage id='General.submit'/>
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
        <Dialog onClose={this.closeError} open={!!this.state.error}>
          <DialogContent>
            <Typography variant='body1'>
            This sheet uses an {this.state.skill.sheetSize} this student has {
                                                                            !this.props.isStaff && this.props.studentProfile.ipadSize
                                                                            ? `an ${this.props.studentProfile.ipadSize} as their device`
                                                                            : 'no device set up. Please go to the student profile and add a device to stop seeing this message'
                                                                          }.  This may not be a problem but please check that this sheet will fit on the student's device.
            </Typography>
            <Typography align='center' variant='h6'> Would you like to proceed? </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeError} color='secondary'>No</Button>
            <Button onClick={this.continue} color='primary' variant='contained'>Yes</Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

const mapState = ({ platform: { skillDatabanks, staffMember, staffProfile }, shared: { account, user }}) => {
  return {
    skillDatabanks,
    account,
    user,
    staffMember,
    staffProfile
  }
};

const mapDispatch = (dispatch) => {
  return {
    fetchSkillDatabanks: (accountId) => dispatch(fetchSkillDatabanks(accountId)),
    getStaffById: (id) => dispatch(getStaffById(id)),
    getStaffProfile: (id) => dispatch(getStaffProfile(id))
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(SkillWizardPage1));