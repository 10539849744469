import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import WizardToggleButton from '../Wizards/WizardToggleButton';
import { getRandomColor } from '../../../helpers';
import { importGoalStudent, checkImport, clearErrors } from '../../../redux/actions';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import {
  Button,
  AppBar,
  Tab,
  Tabs,
  Dialog,
  TablePagination,
  IconButton,
  Paper,
  Typography,
  Toolbar,
  Icon,
  withStyles, SnackbarContent, TextField, InputAdornment, DialogContentText, DialogContent, DialogActions,
} from '@material-ui/core/index';
import { Close, CheckCircle, Cancel, Error, Search, CheckCircleOutline } from '@material-ui/icons/index';
import styles from '../../../styles/importSkill.js';

const CollisionLink = (url) => React.forwardRef((props, ref) => (
  <Link innerRef={ref} to={url} {...props} />
));

class ImportGoal extends Component {

  state = {
    selected: {},
    open: false,
    page: 0,
    rowsPerPage: 3,
    imported: [],
    type: 0,
    filteredData: [],
    warning: false,
    goal: {},
    products: [],
    searchValue: this.props.sample ? 'Objective Education Goals' : ''
  };

  componentDidMount() {
    if (this.props.sample){
      this.handleSearch('Objective Education Goals', this.props.myGoals)
    }
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.myGoals !== this.props.myGoals && this.props.sample){
      this.handleSearch('Objective Education Goals', nextProps.myGoals)
    }
  }

  handleSearch = (searchValue, data) => {
    const _data = this.state.type === 0 ? this.props.myGoals : this.props.goalDatabanks;
    const searchableData = data ? data : _data;
    const filtered = searchableData.filter(item => {
      const searchableKeys = this.state.type === 0 ? ['name', 'description', 'comment', 'author', 'group'] : ['name', 'description', 'comment'];
      return searchableKeys.some(key => {
        if (key === 'group') {
          return item.group.name.toLowerCase().includes(searchValue.toLowerCase())
        }
        if (key === 'author'){
          return item.author.name.toLowerCase().includes(searchValue.toLowerCase())
        }
        if (item[key]){
          return item[key].toLowerCase().includes(searchValue.toLowerCase())
        }
        else {
          return false;
        }
      })
    });
    this.setState({ filteredData: searchValue ? filtered : [], page: 0 })
  };

  // transform method for reactHtmlParser. Will take our tags and make them links
  transform = (node, index) => {
    if (node.type === 'tag' && node.attribs && node.attribs['data-denotation-char'] === "#"){
      return (
        <strong key={node.children[0].data+index} style={{'color': 'rgb(29, 161, 242)', 'textDecoration': 'none'}}>#{node.children[1].children[1].data}</strong>
      )}
    if (node.attribs && node.attribs.contenteditable) {
      node.attribs = {};
      return convertNodeToElement(node, index, this.transform)
    }
  };

  handleSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value, page: 0 });
  };

  handleChangeTab = (ev, newValue) => {
    this.setState({ type: newValue, page: 0 });
  };

  openWizard = () => {
    this.setState({ open: true })
  };

  closeWizard = () => {
    this.setState({ open: false });
  };

  closeError = (goal) => {
    this.props.clearErrors();
    this.setState({ imported: this.state.imported.filter(item => item !== goal.key)})
  };

  done = () => {
    this.setState({ open: false, imported: [] })
  };

  checkProducts = (goal) => {
    const shared = this.state.type === 0;
    const goalDto = shared
      ? { key: goal.key, goalId: goal.goalId }
      : goal;
    checkImport(this.props.id, goalDto, shared)
      .then(products => {
        if (products.length > 0) {
          this.setState({ warning: true, products })
        }
        else {
          this.importGoalDatabank(goal);
        }
      })
      .catch(err => console.log(err))
    this.setState({ goal })
  };

  importGoalDatabank = (goal) => {
    const shared = this.state.type === 0;
    const goalDto = shared
      ? { key: goal.key, goalId: goal.goalId }
      : goal;
    const type = this.props.staff ? 'staff' : '';
    this.props.importGoal(this.props.id, goalDto, goal.key, shared, type);
    const id = goal.key ? goal.key : goal.id;
    this.setState(state => ({ imported: [...state.imported, id], warning: false, products: [] }))
  };

  render(){
    const { classes, goalDatabanks, myGoals, error } = this.props;
    const { open, page, imported, rowsPerPage, filteredData, searchValue } = this.state;
    const data = filteredData.length || searchValue ? filteredData : this.state.type === 0 ? myGoals : goalDatabanks;
    const items = data.length;
    return (
      <>
        <Dialog open={open} maxWidth='xl' disableEscapeKeyDown onClose={this.closeWizard}>
          <Paper className={ classes.list }>
            <Toolbar classes={{ root: classes.toolbar }}>
              <Typography noWrap className={ classes.header }>
                <strong><FormattedMessage id='Databanks.importGoal'/></strong>
              </Typography>
              <div className={classes.actions}>
                <TextField
                  className={ classes.search }
                  variant='outlined'
                  id='table-search'
                  aria-label='search input'
                  type='search'
                  InputProps={{
                    inputProps: {
                      'aria-label':'search input'
                    },
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Search />
                      </InputAdornment>
                    ),
                    className: classes.searchBar,
                  }}
                  onChange={(ev) => {
                    this.handleSearch(ev.target.value);
                    this.setState({ searchValue: ev.target.value });
                  }}
                  value={this.state.searchValue}
                />
              </div>
              <IconButton aria-label='close menu' onClick={this.closeWizard} className={classes.close}>
                <Close />
              </IconButton>
            </Toolbar>
            {/* GOAL DETAIL */}
            {
              this.props.staff
              ? data && data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((goal, index) => (
                <Paper key={goal.key ? goal.key+index : goal.id+index} className={classes.post}>
                  {
                    goal.key && error.id === goal.key &&
                    <SnackbarContent
                      aria-describedby='snackbar'
                      message={
                        <span id='snackbar' className={classes.message}>
                                <Error/> {error.msg}
                               </span>
                      }
                      action={[
                        <IconButton key="close" aria-label="Close" color='inherit'
                                    onClick={() => this.closeError(goal)}>
                          <Close className={classes.icon}/>
                        </IconButton>,
                      ]}
                    />
                  }
                  <Typography className='name' variant='h4'>{goal.name}</Typography>
                  <Typography className='description' variant='h6'>{goal.description}</Typography>
                  <div className={classes.comment}>
                    <Icon className='far fa-comments icon'/>
                    <Typography variant='subtitle1'>
                      {ReactHtmlParser(goal.comment, { transform: this.transform })}
                    </Typography>
                  </div>
                  <div style={{ backgroundColor: getRandomColor(goal.groupKey) }} className={classes.footer}>
                    <Typography className='text' variant='subtitle1'>
                      {`By ${(goal.author && goal.author.name) || 'Objective Ed'} in `}
                      <Typography variant='subtitle1' component={CollisionLink(`/groups/${goal.groupKey}`)}>
                        {goal.group && `${goal.group.name}`}
                      </Typography>
                    </Typography>
                  </div>
                  <IconButton className='import' aria-label='import goal' disabled={imported.includes(goal.key)}
                              onClick={() => this.importGoalDatabank(goal)}>
                    {
                      imported.includes(goal.key)
                        ? error.type
                        ? <Cancel fontSize='large' color='secondary'/>
                        : <CheckCircle fontSize='large' color='primary'/>
                        : <CheckCircleOutline fontSize='large'/>
                    }
                  </IconButton>
                </Paper>
              ))
              : <>
                  <AppBar position='static' color='inherit'>
                    <Tabs
                      value={this.state.type}
                      onChange={this.handleChangeTab}
                      indicatorColor='primary'
                      textColor='primary'
                      variant='fullWidth'
                    >
                      <Tab label='From My Network' />
                      <Tab label='My Databank' />
                    </Tabs>
                  </AppBar>
                  <section>
                  {
                    this.state.type === 1
                      ? data && data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((goal, index) => (
                      <Paper key={goal.key ? goal.key+index : goal.id+index} className={classes.post}>
                        <Typography className='name' variant='h4'>{goal.name}</Typography>
                        <Typography className='description' variant='h6'>{goal.description}</Typography>
                        <div className={classes.footer}>
                          <div className={classes.details}>
                            <div className={classes.text}>
                              <Typography className='text' variant='subtitle1'>
                                From my Databank
                              </Typography>
                            </div>
                          </div>
                        </div>
                        <IconButton className='import' aria-label='import goal' disabled={imported.includes(goal.id)}
                                    onClick={() => this.checkProducts(goal)}>
                          {
                            imported.includes(goal.id)
                              ? <CheckCircle fontSize='large' color='primary'/>
                              : <CheckCircleOutline fontSize='large'/>
                          }
                        </IconButton>
                      </Paper>
                    ))
                      : data && data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((goal, index) => (
                      <Paper key={goal.key ? goal.key+index : goal.id+index} className={classes.post}>
                        {
                          goal.key && error.id === goal.key &&
                          <SnackbarContent
                            aria-describedby='snackbar'
                            message={
                              <span id='snackbar' className={classes.message}>
                                <Error/> {error.msg}
                               </span>
                            }
                            action={[
                              <IconButton key="close" aria-label="Close" color='inherit'
                                          onClick={() => this.closeError(goal)}>
                                <Close className={classes.icon}/>
                              </IconButton>,
                            ]}
                          />
                        }
                        <Typography className='name' variant='h4'>{goal.name}</Typography>
                        <Typography className='description' variant='h6'>{goal.description}</Typography>
                        <div className={classes.comment}>
                          <Icon className='far fa-comments icon'/>
                          <Typography variant='subtitle1'>
                            {ReactHtmlParser(goal.comment, { transform: this.transform })}
                          </Typography>
                        </div>
                        <div style={{ backgroundColor: getRandomColor(goal.groupKey) }} className={classes.footer}>
                          <Typography className='text' variant='subtitle1'>
                            {`By ${(goal.author && goal.author.name) || 'Objective Ed'} in `}
                            <Typography variant='subtitle1' component={CollisionLink(`/groups/${goal.groupKey}`)}>
                              {goal.group && `${goal.group.name}`}
                            </Typography>
                          </Typography>
                        </div>
                        <IconButton className='import' aria-label='import goal' disabled={imported.includes(goal.key)}
                                    onClick={() => this.checkProducts(goal)}>
                          {
                            imported.includes(goal.key)
                              ? error.type
                              ? <Cancel fontSize='large' color='secondary'/>
                              : <CheckCircle fontSize='large' color='primary'/>
                              : <CheckCircleOutline fontSize='large' />
                          }
                        </IconButton>
                      </Paper>
                    ))
                  }
                </section>
              </>
            }
            {
              imported.length > 0 && !error.msg &&
                <div className={classes.finalizeBtn}>
                  <Button
                    aria-label='finish importing'
                    color='primary'
                    variant='contained'
                    onClick={this.done}
                  >
                    DONE
                  </Button>
                </div>
            }
            <TablePagination
              rowsPerPageOptions={[3, 6, 9]}
              component='div'
              count={items}
              rowsPerPage={this.state.rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Paper>
        </Dialog>
        <Dialog open={this.state.warning}>
          <DialogContent>
            <DialogContentText>
              It looks like this goal uses the product{this.state.products.length > 1 ? 's ' : ' '}"{this.state.products.join(' ')}".
              Some of the skills in this goal will not be available without purchasing
              {
                this.state.products.length > 1
                  ? ' these products'
                  : ' this product'
              }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color='secondary'
              onClick={() => this.setState({ warning: false, products: [], goal: {} })}
            >
              <FormattedMessage id='General.cancel'/>
            </Button>
            <Button
              color='primary'
              onClick={() => this.importGoalDatabank(this.state.goal)}
            >
              continue
            </Button>
          </DialogActions>
        </Dialog>
        <WizardToggleButton
          openWizard={this.openWizard}
          text={this.props.title}
        />
      </>
    )
  }
}

const mapState = ({ platform: { goalDatabanks }, social: { myGoals }, shared: { error }}) => {
  return {
    goalDatabanks,
    myGoals,
    error
  }
};

const mapDispatch = (dispatch) => {
  return {
    importGoal: (studentId, goalDatabank, key, shared, type) => dispatch(importGoalStudent(studentId, goalDatabank, key, shared, type)),
    clearErrors: () => dispatch(clearErrors())
  }
};

export default connect(mapState, mapDispatch)(withStyles(styles)(ImportGoal));