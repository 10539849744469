import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import EditWizardButton from '../Wizards/Edit/EditWizardButton';
import ShareButton from './ShareButton';
import {
  Table,
  Button,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  InputAdornment,
  Switch,
  CircularProgress,
} from '@material-ui/core';
import { Paper, Typography, TextField, Toolbar, Tooltip, IconButton, withStyles } from '@material-ui/core';
import { Delete, PlayCircleFilled, Search, CloudDownload, Close } from '@material-ui/icons';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import {
  getStaffById,
  getStudent,
  getStudentsForStaff,
  getStaffForStudent,
  fetchGoals,
  fetchGames,
  deleteGoal,
  deleteSkillDatabank,
  deleteLevel,
  deleteSkill,
  deleteBrailleSheet,
  deleteStaffBrailleSheet,
  deleteStaffSkillDatabank,
  deleteStudentFromStaff,
  deleteGoalDatabank,
  deleteStaffFromAccount,
  deleteStudentFromAccount,
  downloadSheet, updateGoal, addSkillToGoal,
  deleteSliderList,
  deleteSliderSeries
} from '../../../redux/actions';
import { stableSort, getSorting } from '../../../helpers';
import { ROOT_URL } from '../../../redux/constants';
import styles from '../../../styles/dataTable.js';

class DataTable extends Component {

  state = {
    order: 'asc',
    orderBy: this.props.orderBy,
    rowsPerPage: this.props.rowsPerPage[0],
    page: 0,
    filteredData: [],
    searchValue: '',
    date: false,
    editing: false,
    clickable: this.props.clickable,
    tested: false,
    open: false,
    confirm: false,
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.data && nextProps.data !== this.props.data) {
      this.handleSearch(this.state.searchValue, nextProps.data)
    }
  }

  handleSearch = (searchValue, data) => {
    let _data = [];
    if(this.props.type ==='seriesStoryList'){
      _data = this.props.data;
      const searchableData = data ? data : _data ? _data : [];
        const filtered = Array.isArray(searchableData) && searchableData.filter(item => {
            const searchableKeys = [{ id: 'name' }, { id: 'author' }]
            return searchableKeys.some(key => {
                if (item[key.id]) {
                    return item[key.id].toLowerCase().includes(searchValue.toLowerCase())
                }
                else {
                    return false
                }
            })
        });
        Array.isArray(searchableData) && this.setState({ filteredData: filtered, page: 0 })
    }
    else{
    switch (this.props.type) {
      case 'staff':
        _data = this.props[this.props.type];
        break;
      case 'staffStudentGoal':
        _data = this.props.staffStudents;
        break;
      case 'guidedGoal':
        _data = this.props.goalDatabanks;
        break;
      case 'slider':
        _data = this.props.sliderList;
        break;
      case 'sliderSeries':
        _data = this.props.sliderSeries;
        break;
      default:
        _data = this.props[`${this.props.type}s`];
    }
    const searchableData = data ? data : _data ? _data : [];
    const filtered = Array.isArray(searchableData) && searchableData.filter(item => {
      const searchableKeys = Object.keys(item).filter(key => [...this.props.rows, { id: 'name' }].some(row => row.id === key));
      return searchableKeys.some(key => {
        if (item[key]) {
          return item[key].toLowerCase().includes(searchValue.toLowerCase())
        }
        else {
          return false
        }
      })
    });
    Array.isArray(searchableData) && this.setState({ filteredData: filtered, page: 0 })
  }
  };

  handleSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleClick = (data) => {
    const { getStaffById, getStudent, fetchGoals, history, type, toggle, clickFunc, reviewFunc } = this.props;
    const { clickable } = this.state;
    const location = this.props.location ? this.props.location.pathname : '';
    console.log(data);
    if (clickable && !toggle) {
      if (clickFunc) {
        clickFunc(data);
        return;
      }
      if (reviewFunc) {
        if (type === 'ReviewMode') {
          reviewFunc(data, true);
        } else {
          reviewFunc(data);
        }
        return;
      }
      let url;
      switch (type) {
        case 'staff':
          url = `/staff/${data.id}`;
          getStaffById(data.id);
          break;
        case 'student':
          url = data.products == "Reading" ? `/students/${data.id}/dashboard` : `/students/${data.id}`;
          getStudent(data.id);
          break;
        case 'staffStudent':
          url = data.products == "Reading" ? `/students/${data.id}/dashboard` : `/students/${data.id}`;
          getStudent(data.id);
          break;
        case 'staffStudentGoal':
          url = `/students/${data.id}/goals?guided`;
          getStudent(data.id);
          break;
        case 'form':
          url = `forms/${data.id}`;
          fetchGoals(data.id);
          break;
        case 'goal':
          url = `goals/${data.id}`;
          break;
        case 'guidedGoal':
          this.props.addSkillToGoal(data.id, { ...this.props.skill });
          url = `goals/${data.id}?guided`;
          break;
        case 'goalDatabank':
          url = `goals/${data.id}`;
          break;
        case 'staffGoalDatabank':
          url = `goals/${data.id}`;
          break;
       
        default:
          url = `/`;
          break;
      }
      return history.push(url, location)
    }
    else if (toggle && clickable) {
      this.props.toggle(data)
    }
  };

  processDelete = (data) => {
    this.setState({ confirm: false });
    const {
      type,
      deleteGoal,
      deleteSkillDatabank,
      deleteLevel,
      deleteSkill,
      deleteBrailleSheet,
      deleteStaffBrailleSheet,
      deleteStaffSkillDatabank,
      deleteGoalDatabank,
      deleteStudentFromStaff,
      deleteStaffFromAccount,
      deleteFunction,
      deleteSliderList,
      deleteSliderSeries
    } = this.props;
    switch (type) {
      case 'goal':
        return deleteGoal(data, this.props.studentId);
      case 'goalDatabank':
        return deleteGoalDatabank('admin', null, data);
      case 'staffGoalDatabank':
        return deleteGoalDatabank('staff', this.props.staffId, data);
      case 'skillDatabank':
        return deleteSkillDatabank(data, this.props.account);
      case 'staffSkillDatabank':
        return deleteStaffSkillDatabank(data, this.props.staffId);
      case 'skill':
        return deleteSkill(this.props.goalId, data);
      case 'level':
        return deleteLevel(data);
      case 'brailleSheet':
        return deleteBrailleSheet(data, this.props.account);
      case 'staffBrailleSheet':
        return deleteStaffBrailleSheet(data, this.props.staffId);
      case 'student':
        return deleteFunction(data);
      case 'staff':
        return deleteStaffFromAccount(this.props.account.id, data);
      case 'slider':
        return deleteSliderList(data);
      case 'sliderSeries':
        return deleteSliderSeries(data);
      default:
        break;
    }
  }

  handleDelete = (data) => {
    this.setState({ confirm: true, okFunc: () => { this.processDelete(data); } })
  };

  handleActiveToggle = (goal) => {
    if (goal.status === 'Active') {
      this.props.updateGoal({ ...goal, status: 'Inactive' })
    }
    else if (goal.status === 'Inactive') {
      this.props.updateGoal({ ...goal, status: 'Active' })
    }
  };

  handleTest = (skill) => {
    this.runTest(skill.levelJson, skill.levelTemplateId);
  };

  runTest = (levelVariables, templateId) => {
    const { tested } = this.state;
    const url = `${ROOT_URL}/staff/${this.props.staffId}/objective-databank/test`;
    if (tested) this.clearTest();
    axios.post(url, { levelVariables, templateId })
      .catch(err => console.log(err));
    this.setState({ tested: true });
  };

  clearTest = () => {
    const url = `${ROOT_URL}/staff/${this.props.staffId}/objective-databank/test`;
    axios.delete(url)
      .catch(err => console.log(err));
    this.setState({ tested: false })
  };

  handleEditClick = () => {
    this.setState({ editing: false, clickable: this.props.clickable })
  };

  handleOpen = () => {
    this.setState({ open: true })
  };

  handleClose = () => {
    this.setState({ open: false })
  };

  cancelConfirm = () => {
    this.setState({ confirm: false });
  }

  render() {
    const { classes, rows, title, deletable, exitFunc, progressReport } = this.props;
    const {
      rowsPerPage,
      page,
      orderBy,
      order,
      searchValue,
      filteredData,
      date,
    } = this.state;
    const actionLabel = this.props.actionLabel ? this.props.actionLabel : "Actions"
    const data = filteredData.length || searchValue ? filteredData : this.props.data;
    return (
      <Paper className={classes.list}>
        {!progressReport && <Toolbar classes={{ root: classes.toolbar }}>
          <Typography noWrap className={classes.header}>
            <strong>{title}</strong>
          </Typography>
          <div className={exitFunc ? null : classes.actions}>
            {!this.props.noSearch && (
              <TextField
                className={classes.search}
                variant='outlined'
                id='table-search'
                aria-label='search input'
                type={date ? 'date' : 'search'}
                InputProps={{
                  inputProps: {
                    'aria-label': 'search input'
                  },
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Search />
                    </InputAdornment>
                  ),
                  className: classes.searchBar,
                }}
                onChange={(ev) => {
                  this.handleSearch(ev.target.value);
                  this.setState({ searchValue: ev.target.value });
                }}
                value={this.state.searchValue}
              />
            )}
            {exitFunc && (
              <IconButton
                onClick={() => { exitFunc() }}
                className={classes.exitIcon}
                aria-label="close dialog"
              >
                <Close />
              </IconButton>
            )}
          </div>
        </Toolbar>}
        <div className={classes.tableWrapper}>
          <Table>
            <TableHead>
              <TableRow>
                {rows.length &&
                  rows.map((row, index) => {
                    return (
                      <TableCell
                        aria-label={row.id}
                        key={row.id + index}
                        sortDirection={orderBy === row.id ? order : false}
                      >
                        <Tooltip
                          title='Sort'
                          placement='bottom-start'
                          enterDelay={300}
                        >
                          <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={(ev) => this.handleSort(ev, row.id)}
                          >
                            {row.label}
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                    )
                  })
                }
                <TableCell align='center'>   {this.props.noActions ? "" : actionLabel}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {this.props.loader && 
            <TableRow>
              <TableCell colSpan={this.props.numCols}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems:'center' }}><CircularProgress /></div>
                </TableCell>
                </TableRow>}
              {stableSort(data, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((_data, index) => {
                  return (
                    <TableRow className={classes.hoverRow} tabIndex={0} hover key={_data.id + index}>
                      {rows.length &&
                        rows.map((row, index) => {
                          return (
                            row.id === 'status' && this.props.type !=="seriesStoryList"
                              ? <TableCell key={index + row.id}>
                                <Tooltip title={_data[row.id] === 'Active' ? 'Deactivate Goal' : 'Activate Goal'}>
                                  <Switch
                                    checked={_data[row.id] === 'Active'}
                                    onChange={() => this.handleActiveToggle(_data)}
                                    name='status'
                                    aria-label='toggle active or inactive'
                                  />
                                </Tooltip>
                              </TableCell>
                              : <TableCell onClick={() => this.handleClick(_data)} aria-label={row.id === 'firstName' ? `Select to enter ${_data.name}'s page` : null} className={classes.cell} key={index + row.id}>
                                {
                                  (_data[row.id] && _data[row.id] !== ' ') || (row.id === 'gradeLevel' && _data[row.id] === 0)
                                    ? row.id === 'userId'
                                      ? _data[row.id] !== 'null'
                                        ? <FormattedMessage id='General.yes' /> : <FormattedMessage id='General.no' />
                                      : (row.id==='titleImageSrc'? <img src={_data[row.id]} height="150" width="100" alt="Story Book" ></img>  : ((row.id === 'gradeLevel' && _data[row.id] === 0) ? 'K' : _data[row.id]))
                                    : 'N/A'
                                }
                              </TableCell>
                          )
                        })
                      }
                      {
                        deletable
                          ? <TableCell padding='checkbox' align='center'>
                            <div className={classes.buttons}>
                              {
                                (this.props.type === 'staffGoalDatabank' || this.props.type === 'staffBrailleSheet')
                                  ? <>
                                    <ShareButton
                                      id={_data.id}
                                      onClick={() => this.handleEditClick(_data.id)}
                                      data={_data}
                                      type={this.props.type}
                                    />
                                    {
                                      this.props.type === 'staffBrailleSheet' &&
                                      <Tooltip title='Download BRF File'>
                                        <IconButton onClick={() => downloadSheet(_data.id, _data.name)}>
                                          <CloudDownload />
                                        </IconButton>
                                      </Tooltip>
                                    }
                                    <EditWizardButton
                                      search={this.handleSearch}
                                      history={this.props.history}
                                      searchVal={this.state.searchValue}
                                      id={_data.id}
                                      onClick={() => this.handleEditClick(_data.id)}
                                      type={this.props.type}
                                      data={_data}
                                    />
                                  </>
                                  :
                                  this.props.type !== 'goalDatabank' &&
                                  <EditWizardButton
                                    history={this.props.history}
                                    search={this.handleSearch}
                                    searchVal={this.state.searchValue}
                                    id={_data.id}
                                    onClick={() => this.handleEditClick(_data.id)}
                                    type={this.props.type}
                                    data={_data}
                                  />
                              }
                              {
                                (_data.id !== this.props.user.staffId) &&
                                <Tooltip title={<FormattedMessage id="General.delete" />}>
                                  <IconButton aria-label='delete' onClick={() => this.handleDelete(_data)}>
                                    <Delete color='secondary' />
                                  </IconButton>
                                </Tooltip>
                              }

                              {
                                this.props.type === 'staffSkillDatabank' &&
                                <Tooltip title="Test Skill">
                                  <IconButton aria-label='test skill' onClick={() => this.handleTest(_data)}>
                                    <PlayCircleFilled />
                                  </IconButton>
                                </Tooltip>
                              }
                              {
                                this.props.type === 'staffSkillDatabank' && _data.brailleSheetId &&
                                <Tooltip title='Download BRF File'>
                                  <IconButton onClick={() => downloadSheet(_data.brailleSheetId, _data.name)}>
                                    <CloudDownload />
                                  </IconButton>
                                </Tooltip>
                              }
                              {
                                this.props.downloadFunc &&
                                <Tooltip title='Download'>
                                  <IconButton onClick={() => this.props.downloadFunc(data)}>
                                    <CloudDownload />
                                  </IconButton>
                                </Tooltip>
                              }
                            </div>
                          </TableCell>
                          : <TableCell padding='checkbox' align='center'>
                            {
                              ((this.props.type !== 'goal' || this.props.type !== 'goalDatabank') && this.props.type !== 'staffStudent' && this.props.type !== 'speech-data' && this.props.type !== "Phoneme" && this.props.type !== "ReviewMode" && this.props.type !=="seriesStoryList" && this.props.type !=="storyListSeries" && this.props.type !=="RecentBooks") &&
                              <EditWizardButton
                                history={this.props.history}
                                search={this.handleSearch}
                                searchVal={this.state.searchValue}
                                id={_data.id}
                                onClick={() => this.handleEditClick(_data.id)}
                                type={this.props.type}
                                data={_data}
                              />
                            }
                            {
                              (this.props.type === 'goal' || this.props.type === 'goalDatabank' || this.props.type === 'brailleSheet') &&
                              <ShareButton
                                id={_data.id}
                                onClick={() => this.handleEditClick(_data.id)}
                                data={_data}
                                type={this.props.type}
                              />
                            }
                            {
                              (this.props.type === 'staffGoalDatabank' || this.props.type === 'staffBrailleSheet') && !this.props.myself &&
                              <ShareButton
                                id={_data.id}
                                onClick={() => this.handleEditClick(_data.id)}
                                data={_data}
                                type={this.props.type}
                              />
                            }
                          </TableCell>
                      }
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </div>
        {!this.props.noPagination && (
          <TablePagination
            rowsPerPageOptions={this.props.rowsPerPage}
            component='div'
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage={<FormattedMessage id='Datatable.rowsPerPage' />}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        )}
        {this.state.confirm && (
          <Dialog
            disableEscapeKeyDown
            open={this.state.confirm}
            onClose={this.cancelConfirm}
            aria-labelledby='skill-dialog-title'
            scroll='paper'
            PaperProps={{ classes: { root: classes.container } }}
          >
            <DialogTitle>Are you sure?</DialogTitle>
            <DialogContent>
            </DialogContent>
            <DialogActions className={classes.buttons}>
              <Button onClick={this.cancelConfirm} color='secondary'>
                <FormattedMessage id='General.cancel' />
              </Button>
              <Button onClick={this.state.okFunc} color='primary'>
                <FormattedMessage id='General.ok' />
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Paper>
    )
  }
}

const mapState = ({ platform: { staffSkillDatabanks, skillDatabanks, staffGoalDatabanks, goalDatabanks, students, staffStudents, staff, brailleSheets, staffBrailleSheets, levels, games, goals, sliderList, sliderSeries }, shared: { user } }) => {
  return {
    staffSkillDatabanks,
    skillDatabanks,
    staffGoalDatabanks,
    goalDatabanks,
    games,
    staff,
    students,
    brailleSheets,
    staffBrailleSheets,
    staffStudents,
    levels,
    goals,
    user,
    sliderList,
    sliderSeries
  }
};

const mapDispatch = (dispatch, { history }) => {
  return {
    getStaffById: (id) => (dispatch(getStaffById(id))),
    getStudent: (id) => dispatch(getStudent(id)),
    fetchGoals: (id) => dispatch(fetchGoals(id, history)),
    fetchGames: () => dispatch(fetchGames()),
    getStudentsForStaff: (id) => dispatch(getStudentsForStaff(id)),
    getStaffForStudent: (id) => dispatch(getStaffForStudent(id)),
    deleteGoal: (goal, studentId) => dispatch(deleteGoal(goal, studentId)),
    updateGoal: (goal) => dispatch(updateGoal(goal)),
    deleteSkillDatabank: (databank, account) => dispatch(deleteSkillDatabank(databank, account)),
    deleteLevel: (level) => dispatch(deleteLevel(level)),
    deleteSkill: (goalId, skill) => dispatch(deleteSkill(goalId, skill)),
    addSkillToGoal: (goalId, skill, percentages) => dispatch(addSkillToGoal(goalId, skill, percentages)),
    deleteBrailleSheet: (sheet, account) => dispatch(deleteBrailleSheet(sheet, account)),
    deleteStaffBrailleSheet: (sheet, account) => dispatch(deleteStaffBrailleSheet(sheet, account)),
    deleteStaffSkillDatabank: (databank, staffId) => dispatch(deleteStaffSkillDatabank(databank, staffId)),
    deleteStaffFromAccount: (accountId, staffMember) => dispatch(deleteStaffFromAccount(accountId, staffMember)),
    deleteStudentFromStaff: (student, staffId) => dispatch(deleteStudentFromStaff(student, staffId)),
    deleteGoalDatabank: (type, staffId, goal) => dispatch(deleteGoalDatabank(type, staffId, goal)),
    deleteSliderList: (slider) => dispatch(deleteSliderList(slider)),
    deleteSliderSeries: (sliderSeries) => dispatch(deleteSliderSeries(sliderSeries))
  }
};

export default connect(mapState, mapDispatch)(withStyles(styles)(DataTable));