 import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import HoursPlayedBar from '../../components/Charts/HoursPlayedBar';
import HoursPlayedPie from '../../components/Charts/HoursPlayedPie';
import WeeklySummary from '../../components/Charts/WeeklySummary';
import { Typography, Divider } from '@material-ui/core';
import { InsertChartOutlined } from '@material-ui/icons';
import {
  fetchGames,
  getStaffById,
  hoursPlayedPerGame,
  getStudentsForStaff,
  hoursPlayedPerSkill,
  hoursPlayerPerGameForStaff,
    weeklySummarys,
  brailleAITime
} from '../../../redux/actions';
import { withStyles, } from '@material-ui/core/styles';
import baseStyle from '../../../styles/dashboard';
import MFATime from '../../components/Charts/MFATime';

class StaffDashboard extends Component {

  state = {
    time: 7,
    hoursPlayerPerGameForStaff: [],
    hoursPlayedPie: [],
    brailleAITimeChart: {data: [], billedTime: 0},
    student: {},
    students: 0,
    clicked: false,
    skill: false,
    setup: false,
    loading: true
  };

  componentDidMount() {
    this.props.getStaffById(this.props.id);
    this.props.getStudentsForStaff(this.props.id);
    this.props.fetchGames();
    this.createGameChart(this.state.time);
    this.createBrailleAIChart();
    this.props.weeklySummarys(this.props.id);
  }

  changeTime = (time) => {
    this.createGameChart(time);
    this.setState({ time, timeChanged: true })
  };

  createBrailleAIChart = () => {
    brailleAITime('staff', this.props.id)
      .then(chart => {
        if (chart) {
          const data = chart.data && chart.data.filter(student => student.student.products.includes('BRAILLE_AI_TUTOR'));
          this.setState({ brailleAITimeChart: { data, billedTime: chart.billedTime } })
        }
        })
  };

  createGameChart = (time) => {
    hoursPlayerPerGameForStaff(this.props.id, time).then(response => {
      const chart = Array.isArray(response) ? response.reduce((chart, data) => {
        const time = moment(data.time).format('M/D/YYYY');
        const playTime = Math.round(data.value);
        const student = this.props.staffStudents.find(student => student.id === data.studentId);
        const duplicate = chart.find(_student => _student.student.id === data.studentId);
        const name = data.gameName;
        if (duplicate) {
          const index = chart.indexOf(duplicate);
          chart[index] = {...chart[index], [name]: playTime}
        }
        else {
          chart.push({
            id: student.name,
            [`${name}`]: playTime,
            time,
            student,
          });
        }
        console.log(chart);
        return chart
      }, [])
      : [];
      this.setState({ hoursPlayerPerGameForStaff: chart, loading: false, time });
    });
  };

  toggleSkill = (skill) => {
    !this.state.skill ?
      hoursPlayedPerSkill(skill, this.state.student.id, this.state.time).then(response => {
        const chart = Array.isArray(response) ? response.map(data => {
          const time = moment(data.time).format('M/D/YYY');
          return {
            id: data.skillName,
            label: data.skillName,
            value: data.value,
            time,
            skillId: data.skillId,
          }
        }): [];
        this.setState({ hoursPlayedPie: chart })
      })
      : this.togglePieChart({...skill, data: { student: this.state.student }}, this.state.student.id);
    this.setState(({skill}) => ({ skill: !skill }))
  };

  togglePieChart = (skill, id) => {
    console.log(skill, id);
    const student = skill.data.student;
    hoursPlayedPerGame('student', id, this.state.time).then(response => {
      const chart = response.map(data => {
        const time = moment(data.time).format('M/D/YYYY');
        return {
          id: data.gameName,
          label: data.gameName,
          value: data.value,
          time,
          skillId: data.skillId,
        }
      });
      this.setState({ hoursPlayedPie: chart, clicked: true, student, toggled: skill })
    });
  };

  render(){
    const { classes, staffMember, games } = this.props;
    const { hoursPlayerPerGameForStaff, student, brailleAITimeChart } = this.state;
    if(this.state.clicked  && this.state.timeChanged) {
      this.setState({timeChanged: false})
      this.togglePieChart(this.state.toggled, this.state.student.id);
    }
    return (
      <div className={classes.container}>
        <div className={classes.title}>
          <InsertChartOutlined className={classes.icon} fontSize='inherit'/>
          <Typography className={classes.text} variant='h3'>
            {staffMember.firstName}<FormattedMessage id='Dashboard.possessive'/>
          </Typography>
        </div>
        <Divider />
        <br />
        {/*<div className={classes.staffChartTall}>*/}
        {/*  <WeeklySummary*/}
        {/*      students={this.props.staffStudents}*/}
        {/*      weeklySummaries={this.props.weeklySummaries}/>*/}
        {/*</div>*/}
        {
          this.state.clicked && this.state.student.id === student.id &&
          <div className={classes.staffChart}>
            <HoursPlayedPie
              toggleSkill={this.toggleSkill}
              time={this.state.time}
              changeTime={this.changeTime}
              data={this.state.hoursPlayedPie}
              title={`${student.name}'s Progress`}
              loading={false}
              id={student.id}
            />
          </div>
        }
        <div className={classes.staffChart}>
           <HoursPlayedBar
            toggle={this.togglePieChart}
            time={this.state.time}
            changeTime={this.changeTime}
            games={games}
            data={hoursPlayerPerGameForStaff}
            title={<FormattedMessage id='Chart.hoursPlayed.bar'/>}
          />
        </div>
        <div className={classes.staffChart}>
          {
            brailleAITimeChart.data.length > 0 &&
            <MFATime chart={brailleAITimeChart} title='Braille Tutor Time Used'/>
          }
        </div>
        </div>
    )
  }
}

const mapState = ({ platform: { staffMember, games, staffStudents, weeklySummaries }, shared: { user }}) => {
  return {
    staffMember,
    games,
    staffStudents,
    user,
    weeklySummaries,
  }
};

const mapDispatch = (dispatch) => {
  return {
    getStaffById: (id) => dispatch(getStaffById(id)),
    getStudentsForStaff: (id) => dispatch(getStudentsForStaff(id)),
    fetchGames: () => dispatch(fetchGames()),
    weeklySummarys: (id) => dispatch(weeklySummarys(id)),
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(StaffDashboard));