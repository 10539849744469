import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Feed from './Feed';
import Groups from './MyGroups';
import ProfileLeft from '../components/ProfileDetail';
import { withStyles, Typography, Card, Button } from '@material-ui/core';
import { myProfile, getMyFeed } from '../../redux/actions';
import empty from '../../assets/SocialMockups/empty-feed.png';
import baseStyle from '../../styles/homePage';

const CollisionLink = React.forwardRef((props, ref) => (
  <Link innerRef={ref} to="/groups" {...props} />
));

class HomePage extends Component {

  componentDidMount() {
    this.props.myProfile();
    this.props.getMyFeed();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (!this.props.profile.key) {
      this.props.myProfile();
      this.props.getMyFeed();
    }
  }

  render() {
    const { classes, feed } = this.props;
    return (
      <div className={classes.homePage}>
        <section className={classes.left}>
          <ProfileLeft profile={this.props.profile}/>
          <Groups id={this.props.profile.key} history={this.props.history}/>
        </section>
        <main className={classes.center}>
          {
            feed.length
            ? <Feed profile={this.props.profile} posts={feed}/>
            : <Card className={classes.emptyFeed}>
                <Typography variant='h4'>Your feed is empty!</Typography>
                <Typography variant='h5'>Join a group to see posts in your feed</Typography>
                <img alt='sad office guy on computer' src={empty}/>
                <Button
                  aria-label='go-to-public-groups'
                  component={CollisionLink}
                >
                  Check out all the public groups here!
                </Button>
              </Card>
          }
        </main>
        {/*<section className={classes.right}>*/}
        {/*</section>*/}
      </div>
    );
  }
}

const mapState = ({ social: { profile, posts, feed }}) => {
  return {
    profile,
    posts,
    feed
  }
};

const mapDispatch = (dispatch, { history }) => {
  return {
    myProfile: () => dispatch(myProfile(history)),
    getMyFeed: () => dispatch(getMyFeed(history)),
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(HomePage));