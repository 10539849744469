import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { ROOT_URL } from '../../../../redux/constants';
import { FormattedMessage } from 'react-intl';
import WizardToggleButton from '../WizardToggleButton';
import SkillDatabankWizardPage1 from './SkillDatabankWizardPage1';
import SkillDatabankWizardPage2 from './SkillDatabankWizardPage2';
import BarnyardSkillDatabank from './BarnyardSkillDatabank';
import BrailleQuizSkillDatabank from './BrailleQuizSkillDatabank';
import SimonSkillDatabank from './SimonSkillDatabank';
import BopGestureSkillDatabank from './GestureSkillDatabank';
import AudioMemorySkillDatabank from './AudioMemorySkillDatabank';
import SoundSearchSkillDatabank from './SoundSearchSkillDatabank';
import TempleExploreSkillDatabank from './TempleExploreSkillDatabank';
import MonsterCafeSkillDatabank from './MonsterCafeSkillDatabank';
import MapSkillDatabank from './MapsGame/MapsSkillDatabank';
import BrailleScramble from './BrailleScramble';
import SkillDatabankEdit from './SkillDatabankEdit';
import WordHunt from './WordHunt';
import AudioAsteroidsSkillDatabank from './AudioAsteroidsSkillDatabank';
import DefaultSkillDatabankWizardPage from './DefaultSkillDatabankWizardPage';
import SkillDatabankLoadingPage from "./SkillDatabankLoadingPage";
import {
  createSkillDatabank,
  createStaffSkillDatabank,
  deleteStaffSkillDatabank,
  patchStaffSkillDatabank,
  patchSkillDatabank,
} from '../../../../redux/actions';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../../styles/wizardBase';
import CodeBreakerSkillDatabank from './CodeBreakerSkillDatabank';
import ReadAloudPROTO from './ReadAloudPROTO';
import CellTutorPROTO from './CellTutorPROTO';
import SelfGuidedBase from './SelfGuidedBase';
import DisplayQuizPROTO from './DisplayQuizPROTO';
import WayfindingSkillDatabank from './WayfindingSkillDatabank';

class SkillDatabankWizardBase extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      open: this.props.open || false,
      page: 1,
      game: this.props.game || {},
      description: this.props.data ? this.props.data.description : '',
      name: this.props.data ? this.props.data.name : '',
      area: this.props.data ? this.props.data.area : '',
      selectedGame: this.props.game ? this.props.game.id : '',
      games: this.props.games || [],
      brailleWizard: false,
      selectedLevel: '',
      loading: false,
      level: {levelJson: "{}"}
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.game && this.props.game) {
      this.setState({ selectedGame: nextProps.game.id })
    }
    if (this.props.data !== nextProps.data) {
      this.setState({
        name: nextProps.data.name,
        area: nextProps.data.area,
        description: nextProps.data.description
      })
    }
    if (this.props.open !== nextProps.open) {
      this.setState({ open: nextProps.open })
    }
  }

  openWizard = () => {
    this.setState({ open: true })
  };

  closeWizard = () => {
    this.setState({ open: false, brailleWizard: false, page: 1, game: {}, selectedGame: '', name: '', description: '', area: '', level: {levelJson: "{}"} });
    if (this.props.close) this.props.close();
  };

  next = (values, sheet, dictionary) => {
    if(this.state.loading) {
      this.props.close();
      this.setState({page: this.state.page, open: true});
    } else {
      this.setState({page: this.state.page + 1, open: true});
      if (values) this.setState({values});
      if (sheet) this.setState({selectedSheet: sheet});
      if (dictionary) this.setState(({dictionary}));
      this.changeLevel({id: values.levelTemplateId, levelJson: values.levelJson, difficulty: values.difficulty});
    }
  };

  goToGame = () => {
    const game = this.props.games.find(game => game.id === this.state.selectedGame);
    this.setState({ game, page: this.state.page+1 });
  };

  back = () => {
    this.setState({ page: this.state.page-1 });
  };

  handleChange = (ev) => {
    this.setState({ [ev.target.name]: ev.target.value })
  };

  handleGameChange = (ev) => {
    const game = this.props.games.find(game => game.id === ev.target.value);
    this.setState({ selected: game, selectedGame: ev.target.value, selectedLevel: '', level: {levelJson: "{}"} })
  };

  submit = () => {
    const { name, area, description, selectedGame, selectedSheet, dictionary } = this.state;
    const values = {...this.state.values, name, area, description};
    const brailleSheetId = selectedSheet ? selectedSheet.id : null;
    const sheetSize = selectedSheet ? selectedSheet.sheetSize : null;
    if (dictionary) {
      const url = `${ROOT_URL}/braille-dictionaries/${dictionary}/scrambles`;
      this.setState({loading: true});
      axios
        .get(url)
        .then((response) => {
          this.setState({loading: false});
          if (this.props.edit){
            const levelJson = JSON.stringify({ ...values.levelJson, scrambles: response.data });
            const skill = {...values, levelJson, id: this.props.data.id};
            this.props.patchSkillDatabank(skill);
            this.next(values);
          }
          else {
            const levelJson = JSON.stringify({ ...values.levelJson, scrambles: response.data });
            const skill = {...values, levelJson, brailleSheetId};
            this.props.createStaffSkillDatabank(skill, this.props.id);
            this.next(values);
          }
        });
      return;
    }
    else if (this.props.type === 'admin'){
      if (this.props.edit){
        this.props.patchSkillDatabank(values);
      }
      else {
        this.props.createSkillDatabank({...values, gameId: selectedGame, brailleSheetId, sheetSize}, this.props.id);
      }
    }
    else if (this.props.type === 'staff'){
      if (this.props.edit){
        this.props.patchStaffSkillDatabank(values);
      }
      else {
        this.props.createStaffSkillDatabank({...values,  gameId: selectedGame, brailleSheetId, sheetSize}, this.props.id);
      }
    }
    else if (this.props.type === 'skillDatabank'){
      if (this.props.edit){
        this.props.patchSkillDatabank({...values, id: this.props.data.id, levelTemplateId: this.props.levelTemplate.id });
      }
      else {
        this.props.createSkillDatabank({...values,  gameId: selectedGame, brailleSheetId, sheetSize}, this.props.id);
      }
    }
    else if (this.props.type === 'staffSkillDatabank'){
      if (this.props.edit) {
        this.props.patchStaffSkillDatabank({...values, id: this.props.data.id, brailleSheetId, levelTemplateId: this.props.levelTemplate.id }, this.props.staffMember.id)
      }
      else {
        this.props.deleteStaffSkillDatabank(this.props.data, this.props.staffMember.id);
        this.props.createStaffSkillDatabank({...values, gameId: selectedGame, brailleSheetId, sheetSize}, this.props.staffMember.id);
      }
    }
    if (this.props.braille) {
      this.next(values);
      this.setState({ skill: {...values, gameId: selectedGame, brailleSheetId, sheetSize }, brailleWizard: true })
    }
    this.setState({ skill: {...values, gameId: selectedGame, brailleSheetId, sheetSize }});
    this.next(values);
  };

  changeLevel = (level) => {
    this.setState({ level })
  };

  renderGameScreen = () => {
    const { page, game } = this.state;
    const open = this.props.open ? this.props.open : this.state.open;
    if (game && page === 2) {
      switch (game.id) {
        case '400':
          return (
            <BarnyardSkillDatabank
              selectedLevel={this.state.selectedLevel}
              type={this.props.type}
              id={this.props.id}
              open={open}
              close={this.closeWizard}
              back={this.back}
              games={this.props.games}
              selectedGame={this.state.game}
              onSubmit={this.next}
              area={this.state.area}
              description={this.state.description}
              name={this.state.name}
              level={this.props.level ? this.props.level : this.state.level}
              edit={this.props.edit}
              levelTemplate={this.props.levelTemplate}
            />
          );
        case '401':
          return (
            <SimonSkillDatabank
              type={this.props.type}
              id={this.props.id}
              open={open}
              close={this.closeWizard}
              back={this.back}
              game={game}
              onSubmit={this.next}
              area={this.state.area}
              description={this.state.description}
              name={this.state.name}
              level={this.props.level ? this.props.level : this.state.level}
              edit={this.props.edit}
              levelTemplate={this.props.levelTemplate}
            />
          );
        case '404':
          return (
            <BrailleQuizSkillDatabank
              type={this.props.type}
              id={this.props.id}
              open={open}
              close={this.closeWizard}
              back={this.back}
              game={game}
              onSubmit={this.next}
              area={this.state.area}
              description={this.state.description}
              name={this.state.name}
              level={this.props.level ? this.props.level : this.state.level}
              edit={this.props.edit}
              levelTemplate={this.props.levelTemplate}
            />
          );
        case '406':
          return (
            <SoundSearchSkillDatabank
              type={this.props.type}
              id={this.props.id}
              open={open}
              close={this.closeWizard}
              back={this.back}
              game={game}
              onSubmit={this.next}
              area={this.state.area}
              description={this.state.description}
              name={this.state.name}
              level={this.props.level ? this.props.level : this.state.level}
              edit={this.props.edit}
              levelTemplate={this.props.levelTemplate}
            />
          );
        case '407':
          return (
            <BopGestureSkillDatabank
              type={this.props.type}
              id={this.props.id}
              open={open}
              close={this.closeWizard}
              back={this.back}
              game={game}
              onSubmit={this.next}
              area={this.state.area}
              description={this.state.description}
              name={this.state.name}
              level={this.props.level ? this.props.level : this.state.level}
              edit={this.props.edit}
              levelTemplate={this.props.levelTemplate}
            />
          );
        case '408':
          return (
            <AudioMemorySkillDatabank
              type={this.props.type}
              id={this.props.id}
              open={open}
              close={this.closeWizard}
              back={this.back}
              game={game}
              onSubmit={this.next}
              area={this.state.area}
              description={this.state.description}
              name={this.state.name}
              level={this.props.level ? this.props.level : this.state.level}
              edit={this.props.edit}
              levelTemplate={this.props.levelTemplate}
            />
          );
        case '409':
          return (
            <BrailleScramble
              type={this.props.type}
              id={this.props.id}
              open={open}
              close={this.closeWizard}
              back={this.back}
              game={game}
              onSubmit={this.next}
              area={this.state.area}
              description={this.state.description}
              name={this.state.name}
              level={this.props.level ? this.props.level : this.state.level}
              edit={this.props.edit}
              levelTemplate={this.props.levelTemplate}
            />
          );
        case '410':
          return (
            <WordHunt
              type={this.props.type}
              id={this.props.id}
              open={open}
              close={this.closeWizard}
              back={this.back}
              game={game}
              onSubmit={this.next}
              area={this.state.area}
              description={this.state.description}
              name={this.state.name}
              level={this.props.level ? this.props.level : this.state.level}
              edit={this.props.edit}
              levelTemplate={this.props.levelTemplate}
            />
          );
        case '411':
          return (
            <WayfindingSkillDatabank
              type={this.props.type}
              id={this.props.id}
              open={open}
              close={this.closeWizard}
              back={this.back}
              game={game}
              onSubmit={this.next}
              area={this.state.area}
              description={this.state.description}
              name={this.state.name}
              level={this.props.level ? this.props.level : this.state.level}
              edit={this.props.edit}
              levelTemplate={this.props.levelTemplate}
              selectedLevel={this.state.selectedLevel}
              selectedGame={this.state.game}
            />
          );
        case '412':
          return (
            <AudioAsteroidsSkillDatabank
              type={this.props.type}
              level={this.props.level ? this.props.level : this.state.level}
              id={this.props.id}
              open={open}
              close={this.closeWizard}
              back={this.back}
              game={game}
              onSubmit={this.next}
              area={this.state.area}
              description={this.state.description}
              name={this.state.name}
              edit={this.props.edit}
              levelTemplate={this.props.levelTemplate}
            />
          );
        case '414':
          return (
            <CodeBreakerSkillDatabank
              id={this.props.id}
              type={this.props.type}
              open={open}
              close={this.closeWizard}
              back={this.back}
              games={this.props.games}
              selectedGame={this.state.game}
              onSubmit={this.next}
              area={this.state.area}
              description={this.state.description}
              name={this.state.name}
              level={this.props.level ? this.props.level : this.state.level}
              edit={this.props.edit}
              levelTemplate={this.props.levelTemplate}
            />
          );
        case '415':
          return (
            <CellTutorPROTO
              open={open}
              id={this.props.id}
              type={this.props.type}
              close={this.closeWizard}
              back={this.back}
              onSubmit={this.next}
              games={this.props.games}
              selectedGame={this.state.game}
              area={this.state.area}
              description={this.state.description}
              name={this.state.name}
              level={this.props.level}
              edit={this.props.edit}
              levelTemplate={this.props.levelTemplate}
            />
          );
        case '416':
          return (
            <ReadAloudPROTO
              open={open}
              id={this.props.id}
              type={this.props.type}
              close={this.closeWizard}
              back={this.back}
              onSubmit={this.next}
              games={this.props.games}
              selectedGame={this.state.game}
              area={this.state.area}
              description={this.state.description}
              name={this.state.name}
              level={this.props.level ? this.props.level : this.state.level}
              edit={this.props.edit}
              levelTemplate={this.props.levelTemplate}
            />
          );
        case '417':
          return (
            <DisplayQuizPROTO
              open={open}
              id={this.props.id}
              type={this.props.type}
              close={this.closeWizard}
              back={this.back}
              onSubmit={this.next}
              games={this.props.games}
              selectedGame={this.state.game}
              area={this.state.area}
              description={this.state.description}
              name={this.state.name}
              level={this.props.level}
              edit={this.props.edit}
              levelTemplate={this.props.levelTemplate}
            />
          );
        case '420':
          return (
              <TempleExploreSkillDatabank
                  open={open}
                  id={this.props.id}
                  type={this.props.type}
                  close={this.closeWizard}
                  back={this.back}
                  onSubmit={this.next}
                  games={this.props.games}
                  selectedGame={this.state.game}
                  area={this.state.area}
                  description={this.state.description}
                  name={this.state.name}
                  level={this.props.level ? this.props.level : this.state.level}
                  edit={this.props.edit}
                  levelTemplate={this.props.levelTemplate}
              />
          );
        case '421':
          return (
              <MonsterCafeSkillDatabank
                  open={open}
                  id={this.props.id}
                  type={this.props.type}
                  close={this.closeWizard}
                  back={this.back}
                  onSubmit={this.next}
                  games={this.props.games}
                  selectedGame={this.state.game}
                  area={this.state.area}
                  description={this.state.description}
                  name={this.state.name}
                  level={this.props.level ? this.props.level : this.state.level}
                  edit={this.props.edit}
                  levelTemplate={this.props.levelTemplate}
              />
          );
        case '419':
          return (<MapSkillDatabank
              open={open}
              id={this.props.id}
              type={this.props.type}
              close={this.closeWizard}
              back={this.back}
              onSubmit={this.next}
              games={this.props.games}
              selectedGame={this.state.game}
              area={this.state.area}
              description={this.state.description}
              name={this.state.name}
              level={this.props.level ? this.props.level : this.state.level}
              edit={this.props.edit}
              levelTemplate={this.props.levelTemplate}
            />);
        default:
          return (
            this.props.edit
              ? <SkillDatabankEdit
                id={this.props.staffMember.id}
                data={this.props.data}
                open={this.props.open}
                close={this.props.close}
                type={this.props.type}
                game={game}
                edit={this.props.edit}
                level={this.props.level}
                levelTemplate={this.props.levelTemplate}
              />
              : page === 2 && <DefaultSkillDatabankWizardPage
                type={this.props.type}
                id={this.props.id}
                open={open}
                close={this.closeWizard}
                back={this.back}
                games={this.props.games}
                selectedGame={this.state.game}
                onSubmit={this.next}
                edit={this.props.edit}
              />
          );
      }
    }
  };

  render(){
    const { page, name, description, area } = this.state;
    const open = this.props.open ? this.props.open : this.state.open;
    const skill = { name, description, area };
    const allGames = this.props.sheet ? this.props.sheet.games : this.props.games;
    const games = this.props.staffProfile.prototype === 'Yes'
      ? allGames.filter(game => (game.type === "Educational" && game.id !== '405' ) || game.type === "Prototype").sort((a,b) => a.name.localeCompare(b.name))
      : allGames.filter(game => game.type === "Educational" && game.id !== '405').sort((a,b) =>  a.name.localeCompare(b.name));
    return (
      <div>
        {
          this.state.loading &&
          <SkillDatabankLoadingPage
              edit={this.props.edit}
              skill={skill}
              open={open}
              close={this.closeWizard}
              next={this.goToGame}
              games={games}
              braille={this.props.braille}
              selectedGame={this.state.selectedGame}
              handleChange={this.handleChange}
              handleGameChange={this.handleGameChange}
          />
        }
        {
          page === 1 &&
          <SkillDatabankWizardPage1
            edit={this.props.edit}
            skill={skill}
            open={open}
            close={this.closeWizard}
            next={this.goToGame}
            games={games}
            braille={this.props.braille}
            selectedGame={this.state.selectedGame}
            handleChange={this.handleChange}
            handleGameChange={this.handleGameChange}
          />
        }
        {
          page === 3 && !this.state.loading  &&
          <SkillDatabankWizardPage2
            edit={this.props.edit}
            skill={skill}
            open={open}
            close={this.closeWizard}
            submit={this.submit}
            back={this.back}
            games={games}
            selectedGame={this.state.selectedGame}
            handleChange={this.handleChange}
            handleGameChange={this.handleGameChange}
          />
        }
        {
          page === 4 &&
          <SelfGuidedBase close={this.closeWizard} history={this.props.history} id={this.props.staffMember.id} open={open} skill={this.state.skill}/>
        }
        {
          this.renderGameScreen()
        }
        { !this.props.edit && !this.props.braille &&
          <WizardToggleButton
          openWizard={this.openWizard}
          text={<FormattedMessage id='Databanks.addSkillDB'/>}
          />
        }
      </div>
    )
  }
}

const mapState = ({ platform: { games, levels, staffMember, staffProfile }, shared: { user }}) => {
  return {
    games,
    levels,
    staffMember,
    staffProfile,
    user
  }
};

const mapDispatch = (dispatch, { history }) => {
  return {
    createSkillDatabank: (databank, accountId) => dispatch(createSkillDatabank(databank, accountId, history)),
    createStaffSkillDatabank: (databank, staffId) => dispatch(createStaffSkillDatabank(databank, staffId)),
    deleteStaffSkillDatabank: (databank, staffId) => dispatch(deleteStaffSkillDatabank(databank, staffId)),
    patchSkillDatabank: (skillDatabank) => dispatch(patchSkillDatabank(skillDatabank)),
    patchStaffSkillDatabank: (skillDatabank, id) => dispatch(patchStaffSkillDatabank(skillDatabank, id)),
  }
};

export default connect(mapState, mapDispatch)(withStyles(styles)(SkillDatabankWizardBase));