import React, { useEffect, useState } from "react";
import { connect } from "react-redux"
import {
    Dialog, DialogContent, DialogTitle, IconButton, Typography, withStyles, Button,
    DialogActions, Paper, TableHead, Table, TableCell, TableBody, TableRow
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import TablePagination from '@material-ui/core/TablePagination';
import { FormattedMessage } from "react-intl";
import bookshareLogo from "../../../../assets/ScreenShots/bookshare-Logo.png"
import { getDatabank } from "../../../../redux/actions";
import styles from '../../../../styles/purchase';

const rows = [
    { id: 'titleImageSrc', label: 'Image' },
    { id: 'name', label: 'Title' },
    { id: 'author', label: 'Author' }
];

const BookShareImage = () => {
    return (<>
        <img src={bookshareLogo} height={"112px"} width={"450px"} style={{ marginBottom: "10px", marginTop: "10px" }} /><br />
    </>)
}
const PurchaseInfo = (props) => {
    const [showBooks, setShowBooks] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(() => {
        props.id && props.getDatabank(props.id);
    }, [])

    const handleChangePage = (event, page) => {
        setPage(page);
    };
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(event.target.value);
    }
    const { classes, databank } = props;
    const data = databank.length > 0 && databank.filter(data => data.source == 'ObjectiveEd');
    const showData = data.length && data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

    return (<>
        <Dialog
            disableEscapeKeyDown
            fullWidth={true}
            open={props.open}
            onClose={props.close}
            aria-labelledby='form-dialog-title'
            PaperProps={{ classes: { root: classes.container } }}
            scroll='paper'
            hideBackdrop
            disableBackdropClick={true}
            maxWidth='md'>
            <DialogTitle disableTypography={true} className={classes.title} id='form-dialog-title'>
                <Typography className={classes.titleText} variant='h4'>
                    <FormattedMessage id="Paddle.start.buddyBooks" />
                </Typography>
                <IconButton
                    onClick={props.close}
                    className={classes.exitIcon}
                    aria-label="close dialog"
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.container}>
                <span>
                    <b>What content can I access with BuddyBooks?</b> <br /><br />
                    BuddyBooks is designed to be used <b>with a Bookshare account.</b> <br />
                    <BookShareImage />
                    Bookshare is an eBook library that makes reading easier. People with reading barriers
                    like dyslexia, blindness, and physical disabilities can read in ways that work for them with a
                    huge collection of titles in audio, audio + highlighted text, braille, and other formats.
                    Bookshare is FREE for all qualified U.S. studentsand schools,and less than $1 per week for all
                    other members. <br /><br />
                    You can use your Bookshare account in BuddyBooks to read any of
                    Bookshare’s 1,000,000+ titles. <br />
                    If you don’t currently have a Bookshare account you can
                    sign up for one here, <br />
                    <a href="https://www.bookshare.org/cms/get-started/sign" target="_blank" className={classes.anchorTag}>https://www.bookshare.org/cms/get-started/sign</a> <br /><br />
                    <b>What if I don’t have a Bookshare account, or my student/child does not qualify?</b> <br /><br />
                    You can still use BuddyBooks! But you will be limited to using books that are freely available.<br />
                    Click below to see a list of freely available books. <br />
                    <Button variant="contained" className={classes.bookButton} color='primary' onClick={() => setShowBooks(true)}>Show Books</Button>
                    <footer>
                        <b>I understand that without a Bookshare account I will only be able to use the freely available books with BuddyBooks
                        </b></footer>
                    <br />
                </span>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button onClick={props.close} color='secondary'>
                    <FormattedMessage id="General.cancel" />
                </Button>
                <Button onClick={props.handleProceedToPurchase} color='primary'>
                    <FormattedMessage id="purchase" />
                </Button>
            </DialogActions>


        </Dialog>
        {showBooks && <Dialog
            disableEscapeKeyDown
            fullWidth={true}
            open={props.open}
            onClose={props.close}
            aria-labelledby='form-dialog-title'
            PaperProps={{ classes: { root: classes.container } }}
            scroll='paper'
            maxWidth="md"
            hideBackdrop
            disableBackdropClick={true}>
            <DialogTitle disableTypography={true} className={classes.title} id='form-dialog-title'>
                <Typography className={classes.titleText} variant='h4'>
                    <FormattedMessage id="Sidebar.available.books" />
                </Typography>
                <IconButton
                    onClick={() => setShowBooks(false)}
                    className={classes.exitIcon}
                    aria-label="close dialog"
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.container}>
                <Paper>
                    <Table>
                        <TableHead>
                            {rows && rows.map(row => {
                                return <TableCell
                                    key={row.id}
                                    align={row.id == "titleImageSrc" ? "left" : "center"}>
                                    {row.label}
                                </TableCell>

                            })}
                        </TableHead>
                        <TableBody>
                            {
                                showData && showData.map(_data => {
                                    return (
                                        <TableRow
                                            hover
                                            key={_data.id}>
                                            {
                                                rows.map(row => {
                                                    return (
                                                        <TableCell align={row.id == 'titleImageSrc' ? 'left' : 'center'}>
                                                            {row.id == 'titleImageSrc' ?
                                                                _data['titleImageSrc'] != null ?
                                                                    <img src={_data.titleImageSrc} width={120} height={150} alt={_data.name} /> : '' :
                                                                _data[row.id]}
                                                        </TableCell>
                                                    )
                                                })
                                            }
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': 'Previous Page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Next Page',
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Paper>
            </DialogContent>
            <DialogActions style={{ position: 'relative' }}>
                <div style={{ position: 'relative', float: 'right', paddingRight: '40px' }}>
                    <Button variant="contained" className={classes.bookButton} color='primary' onClick={() => setShowBooks(false)}>Back to Purchase </Button>
                </div>
            </DialogActions>
        </Dialog>}
    </>)
}
const mapState = ({ platform: { databank } }) => {
    return {
        databank
    }
};

const mapDispatch = (dispatch, { history }) => {
    return {
        getDatabank: (id) => dispatch(getDatabank(id)),
    }
};
export default connect(mapState, mapDispatch)(withStyles(styles)(PurchaseInfo));