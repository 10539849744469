import React, { Component } from 'react';
import { Button, FormGroup, TextField, Typography } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../../styles/wizardBase';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';

const productOptions = [
  { value: 'Reading', label: 'Reading' },
  { value: 'OM', label: 'O&M' },
  { value: 'TVI', label: 'TVI' },
];

class StaffWizardPage3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      error: {
        email: false,
        firstName: false,
        lastName: false
      }
    }
  }

  close = () => {
    this.props.close();
  };

  submit = () => {
    this.props.submit(this.state.products);
  };

  check = (ev, type) => {
    this.setState({ checked: true });
    switch (type) {
      case 'email':
        let email = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(ev.target.value);
        this.setState({ error: { ...this.state.error, email } });
        break;
      case 'firstName':
        let firstName = !ev.target.value;
        this.setState({ error: { ...this.state.error, firstName } });
        break;
      case 'lastName':
        let lastName = !ev.target.value;
        this.setState({ error: { ...this.state.error, lastName } });
        break;
      default:
        break;
    }
  };

  generateProduct(products){
    let obj=[];
    if(products)
    {
      for(let p of products) {
        var prod = productOptions.filter(o => (o.value == p));
        var label = prod.length == 1 ? prod[0].label : p;
        obj.push({"value": p, "label": label});
      }
    }
    return obj;
  }

  render() {
    let products = this.props.products && this.props.products.split(',');
    let obj = this.generateProduct(products);
    console.log("PRODUCTS:", obj)
    const { classes } = this.props;
    const { error } = this.state;
    const roles = this.props.roles.filter(role => role.id === '1' || role.id === '-4524935518238898931');
    const disabled = error.firstName || error.lastName || error.email;
    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        open={this.props.open}
        onClose={this.props.close}
        aria-labelledby="form-dialog-title"
        PaperProps={{ classes: { root: classes.container } }}
      >
        <DialogTitle disableTypography={true} className={classes.title} id="form-dialog-title">
          <Typography className={classes.titleText} variant='h4'><FormattedMessage id='Wizard.Staff.title' /></Typography>
          <IconButton
            onClick={this.close}
            className={classes.exitIcon}
            aria-label="close dialog"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialog}>
          <DialogContentText>
            Doesn't look like we have them in our system. Let's go ahead and get their information.
          </DialogContentText>
          <Typography variant='h6'>Enter the Staff Member's First and Last Name:</Typography>
          <FormGroup classes={{ root: classes.staffFullName }}>
            <TextField
              variant='outlined'
              onChange={this.props.handleChange}
              id='staff-member-first-name'
              label={<FormattedMessage id='General.firstName' />}
              type='text'
              name='firstName'
              error={error.firstName}
              helperText={error.firstName ? '*A First Name is required' : ''}
              onBlur={(ev) => this.check(ev, 'firstName')}
            />
            <TextField
              variant='outlined'
              onChange={this.props.handleChange}
              id='staff-member-last-name'
              label={<FormattedMessage id='General.lastName' />}
              type='text'
              name='lastName'
              error={error.lastName}
              helperText={error.lastName ? '*A Last Name is required' : ''}
              onBlur={(ev) => this.check(ev, 'lastName')}
            />
          </FormGroup>
          <FormGroup>
            <Typography gutterBottom variant='h6'><FormattedMessage id='Wizard.Staff.email' />:</Typography>
            <TextField
              variant='outlined'
              onChange={this.props.handleChange}
              classes={{ root: classes.firstName }}
              id='user-email'
              type='email'
              name='email'
              label={<FormattedMessage id='General.email' />}
              value={this.props.email}
              error={error.email}
              helperText={error.email ? '*A valid email is required' : ''}
              onBlur={(ev) => this.check(ev, 'email')}
            />
          </FormGroup>
          <FormGroup classes={{ root: classes.studentSelect }}>
            <Typography gutterBottom variant='h6'><FormattedMessage id='Wizard.Staff.role' />:</Typography>
            <Select
              styles={{
                control: (styles) => ({ ...styles, minHeight: 56 }),
              }}
              className={classes.studentSelect}
              menuPlacement='bottom'
              maxMenuHeight={240}
              placeholder='Select a Role'
              value={this.props.role}
              options={roles.map(role => ({ value: role.id, label: role.name }))}
              onChange={(ev) => this.props.handleChange({ target: { name: 'role', value: ev } })}
            />
          </FormGroup>
          <FormGroup classes={{ root: classes.studentSelect }}>
            <Typography gutterBottom variant='h6'> <FormattedMessage id='Wizard.Staff.selectStudent' />:</Typography>
            <Select
              styles={{
                control: (styles) => ({ ...styles, minHeight: 56 }),
                multiValueLabel: (styles) => ({
                  ...styles,
                  display: 'grid',
                  fontFamily: 'Roboto',
                  alignContent: 'center',
                  fontSize: '14px',
                  minHeight: 40,
                  fontWeight: 500
                }),
              }}
              isMulti
              menuPlacement='top'
              maxMenuHeight={240}
              placeholder='Select students to attach to user...'
              options={this.props.students.map(student => ({ value: student.id, label: student.name }))}
              onChange={(ev) => this.props.handleChange({ target: { name: 'students', value: ev } })}
            />
          </FormGroup>
          {products && products.length > 1 ?
            <FormGroup classes={{ root: classes.studentSelect }}><Typography gutterBottom variant='h6'> <FormattedMessage id='Wizard.Staff.selectProduct' />:</Typography>
              <Select
                styles={{
                  control: (styles) => ({ ...styles, minHeight: 56 }),
                  multiValueLabel: (styles) => ({
                    ...styles,
                    display: 'grid',
                    fontFamily: 'Roboto',
                    alignContent: 'center',
                    fontSize: '14px',
                    minHeight: 40,
                    fontWeight: 500
                  }),
                }}
                isMulti
                defaultValue={products && products.length == 1 && products[0].key}
                menuPlacement='top'
                maxMenuHeight={180}
                placeholder='Select Products to attach to user...'
                options={obj && obj.map(p => ({ value: p.value, label: p.label }))}
                onChange={(ev) => this.props.handleChange({ target: { name: 'products', value: ev } })}

              />
            </FormGroup>
            : null}
        </DialogContent>
        <DialogActions className={classes.buttons}>
          <Button onClick={this.props.back} color="secondary">
            <FormattedMessage id='General.back' />
          </Button>
          <Button disabled={!this.state.checked || disabled ||
            (products && products.length > 1 ? this.props.selectedProduct.length < 1 ? true : false : false)} onClick={this.submit} color="primary">
            <FormattedMessage id='General.submit' />
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(baseStyle)(StaffWizardPage3);