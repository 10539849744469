import React, { Component } from 'react';
import { purchase } from '../../../../redux/actions';
import { Backdrop, CircularProgress } from '@material-ui/core';

class RegistrationCheckout extends Component {

  state = {
    loading: false
  }
  componentDidMount() {
    window.Paddle.Checkout.open({
      allowQuantity: false,
      product: this.props.product.id,
      quantity: this.props.students,
      marketingConsent: 1,
      email: this.props.email,
      coupon: this.props.coupon,
      closeCallback: this.onCheckoutClose,
      successCallback: this.onCheckoutSuccess
    });
    setInterval(()=>{
      this.setState({loading: true})
    },7000)
  }

  onCheckoutClose = () => {
    this.props.goToPage(1);
  };

  onCheckoutSuccess = () => {
    const { user } = this.props;
    const specialist =  {
      id: user.staffId,
      name: user.name,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      type: user.type,
      userId: user.id
    };
    purchase(specialist, this.props.terms, this.props.email, this.props.students*1, )
      .then(() => {
        this.props.next()
      })
      .catch((err) => console.log(err))
  };

  render() {
    return <div>
      { this.state.loading &&
      <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}>
      <CircularProgress style={{color:'#fff'}}/>
    </Backdrop>}
    </div>
  }
}

export default RegistrationCheckout;