import React, { Component } from 'react';
import { Button, FormGroup, Typography, TextField, IconButton } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../../styles/wizardBase';
import { FormattedMessage } from 'react-intl';

class GoalWizardPage1 extends Component {

  close = () => {
    this.props.close();
  };

  submit = () => {
    const { name, description } = this.props.goal;
    this.props.onSubmit({ name, description, status: 'Active' })
  };

  edit = () => {
    const goal = this.props.goal;
    this.props.save(goal);
  };

  render()  {
    const { classes } = this.props;
    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        open={this.props.open}
        onClose={this.props.close}
        aria-labelledby='form-dialog-title'
        PaperProps={{ classes:{ root: classes.container }}}
      >
        <DialogTitle disableTypography={true} className={ classes.title } id='form-dialog-title'>
        <Typography className={ classes.titleText } variant='h4'>
          {
            this.props.edit
              ? <><FormattedMessage id='General.edit'/> {this.props.goal.name}</>
              : <FormattedMessage id='Wizard.Goal.title'/>
          }
        </Typography>
        <IconButton
          onClick={this.close}
          className={ classes.exitIcon }
          aria-label="close dialog"
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <br />
        <DialogContentText>
          {
            !this.props.edit &&
            <FormattedMessage id='Wizard.Goal'/>
          }
        </DialogContentText>
          <FormGroup classes={{ root: classes.root }}>
            <TextField
              classes={{ root: classes.firstName }}
              variant='outlined'
              id='goal-name'
              label={<FormattedMessage id='General.name'/>}
              type='text'
              name='name'
              value={this.props.goal.name}
              onChange={this.props.handleChange}
            />
            <TextField
              multiline
              variant='outlined'
              classes={{ root: classes.firstName }}
              id='goal-description'
              label={<FormattedMessage id='General.description'/>}
              type='text'
              name='description'
              placeholder='Add your description here...'
              value={this.props.goal.description}
              rows={2}
              onChange={this.props.handleChange}
            />
          </FormGroup>
        </DialogContent>
        <DialogActions className={classes.buttons}>
            <Button onClick={this.close} color='secondary'>
              <FormattedMessage id='General.cancel'/>
            </Button>
          {
            this.props.edit
            ? <Button onClick={this.edit} variant='contained' color='primary'>
                <FormattedMessage id='General.save'/>
              </Button>
            : <Button onClick={this.submit} variant='contained' color='primary'>
                <FormattedMessage id='General.submit'/>
              </Button>
          }
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(baseStyle)(GoalWizardPage1);