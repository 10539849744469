import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Typography, IconButton, FormGroup, TextField, MenuItem } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { AddCircle, Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../../styles/wizardBase';
import { FormattedMessage } from 'react-intl';

class LevelWizardPage2 extends Component {

  state={
    fields: [],
    open: false,
  };

  close = () => {
    this.props.close();
  };

  closeWizard = () => {
    this.setState({ open: false });
 };

  handleChange = (ev, key, index, type) => {
    const fields = [...this.state.fields];
    const value = type === 'number'
      ? ev.target.value * 1
      : ev.target.value;
    fields[index][key] = value;
    this.setState({ fields })
  };

  stringParser = () => {
    let data = [];
    let json = '';
    this.state.fields.forEach(input => {
      data.push('{"type": "' + input.type + '", "name": "' + input.name + '", "value": ' + input.value + '}');
    });
    json = json.concat('{' +
      data.map((item, index) => {
        return index <= data.length-1 ? item : `${item},`;
      })
      + '}'
    );
    return json
  };

  onSubmit = () => {
    const level = {levelVariables: this.stringParser()};
    this.props.onSubmit(level);
  };

  renderLevelInput = () => {
    const { classes } = this.props;
    const { fields } = this.state;
    return <>
      {Object.keys(fields).map((item, index) => (
        <FormGroup key={index} className={classes.inputLine} row>
          <TextField
            select
            variant='outlined'
            name={`${item}.type`}
            id="type"
            label={<FormattedMessage id='General.type'/>}
            value={fields[index].type || ''}
            onChange={(ev) => this.handleChange(ev, 'type', index)}
          >
            <MenuItem value='static'><FormattedMessage id='Wizard.Level.static'/></MenuItem>
            <MenuItem value='prompt'><FormattedMessage id='Wizard.Level.prompt'/></MenuItem>
            <MenuItem value='number'><FormattedMessage id='Wizard.Level.number'/></MenuItem>
          </TextField>
          <TextField
            variant='outlined'
            id="name"
            label={<FormattedMessage id='General.name'/>}
            onChange={(ev) => this.handleChange(ev, 'name', index)}
          />
          <TextField
            variant='outlined'
            id="value"
            label={<FormattedMessage id='General.value'/>}
            onChange={(ev) => this.handleChange(ev, 'value', index, fields[index].type)}
          />
        </FormGroup>
       )
      )}
    </>
  };

  render(){
    const { classes, open, close, back } = this.props;
    return (
      <Dialog
        fullWidth={true}
        open={open}
        onClose={close}
        aria-labelledby='level-dialog-title'
        PaperProps={{ classes:{ root: classes.container }}}
      >
        <DialogTitle disableTypography={true} className={ classes.title } id='level-dialog-title'>
          <Typography className={ classes.titleText } variant='h4'>Level Template Wizard</Typography>
          <IconButton
            onClick={this.close}
            className={ classes.exitIcon }
            aria-label="close dialog"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography gutterBottom variant='h6'>Add Variables to the Level:</Typography>
          {this.renderLevelInput()}
          <Button onClick={() => this.setState({ fields: [...this.state.fields, {}]})}>
            Add Field &nbsp; <AddCircle/>
          </Button>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button onClick={back} color='secondary'>
            Back
          </Button>
          <Button onClick={this.onSubmit} variant='contained' color='primary'>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapState = ({ form }) => {
  return {
    currentForm: form
  }
};

export default connect(mapState)(withStyles(baseStyle)(LevelWizardPage2));