export default {
  container: {
    color: '#0a0a0a',
    overflow: 'wrap',
  },
  title: {
    backgroundColor: '#383871',
    marginBottom: 15,
  },
  titleText: {
    float: 'left',
    color: '#fff'
  },
  exitIcon: {
    float: 'right',
    color: '#fff',
  },
  toolbar: {
    display: 'grid',
    gridTemplateColumns: '8fr 2fr',
    gridTemplateRows: '1fr',
    borderRadius: '5px 5px 0 0',
    backgroundColor: '#00729b',
    boxShadow: ' 0px 3px 3px #6b6b6b',
    marginTop: '20px'
  },
  tableWrapper: {
    overflowX: 'auto',
    // width: 800
  },
  searchBarBar: {
    color: '#000',
    fontWeight: 600,
    width: '320px',
    marginLeft: '10px',
    backgroundColor: 'white',
    borderRadius: '4px',
    height: 40
  },
  buttons: {
    // maxWidth: 250,
    wordWrap: 'break-word',
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'end',
    alignContent: 'center'
  },
  elementMargin: {
    marginBottom: 10
  },
  storyDataElement: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignContent: 'center',
    gridColumnGap: '10px',
    marginTop: 5
  },
  scroll: {
    marginTop: '20px',
    overflow: 'visible',
  },
}