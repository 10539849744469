import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import { FormattedMessage } from "react-intl";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormGroup, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Toolbar, Tooltip, Typography } from "@material-ui/core";
import { Close, Edit, Search, PlayCircleFilledOutlined, PauseCircleOutline } from "@material-ui/icons";
import { getSorting, stableSort } from "../../../helpers";
import baseStyle from "../../../styles/storyData";
import { getSectionAudioFile, updateStoryDataSections } from "../../../redux/actions";

const rows = [
    { id: 'sectionIndex', label: <FormattedMessage id="General.sectionIndex" />, searchable: true },
    { id: 'text', label: <FormattedMessage id="General.text" />, searchable: true }
]
class StoryDataSectionList extends Component {
    state = {
        order: 'asc',
        orderBy: 'sectionIndex',
        rowsPerPage: 25,
        page: 0,
        filteredData: [],
        searchValue: '',
        open: false,
        bookData: [],
        selectedSection: null,
        showSelectedSection: false,
        sectionUrl: '',
        playAudio: false,
        selectedFile: ''
    };
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.storyDataSections != this.props.storyDataSections) {
            this.setState({
                bookData: this.props.storyDataSections.data
            })
        }
    }

    handleSearch = (searchValue, data) => {
        let _data = [];
        if (this.state.bookData)
            _data = this.state.bookData;

        const searchableData = data ? data : _data ? _data : [];
        const filtered = Array.isArray(searchableData) && searchableData.filter(item => {
            const searchableKeys = [{ id: 'sectionIndex' }, { id: 'text' }, { id: 'analysis' }]
            return searchableKeys.some(key => {
                if (item[key.id]) {
                    return item[key.id].toLowerCase().includes(searchValue.toLowerCase())
                }
                else {
                    return false
                }
            })
        });
        Array.isArray(searchableData) && this.setState({ filteredData: filtered, page: 0 })
    }
    handleSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';
        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }
        this.setState({ order, orderBy });
    };
    handleChangePage = (_event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleStoryDataSectionEdit = (e, data) => {
        e.stopPropagation();
        this.setState({
            showSelectedSection: true,
            selectedSection: data
        });
    }
    handleTextChange = (e) => {
        this.setState({
            sectionText: e.target.value
        })
    }
    closeSelectedSectionModal = () => {
        this.setState({
            showSelectedSection: false,
            selectedSection: []
        });

    }
    downloadAudio = (data) => {
        getSectionAudioFile(data.fileId, data.text).then((res) => {
            this.setState({
                sectionUrl: URL.createObjectURL(res),
                playAudio: true,
                selectedFile: data.fileId
            });

        })
    }
    submitSections = () => {
        let { selectedSection, sectionText } = this.state;
        let data = selectedSection;
        data.text = sectionText;
        this.props.updateStoryDataSections(selectedSection.id, data);
        this.closeSelectedSectionModal();
    }
    pauseAudio = () => {
        this.setState({
            playAudio: false
        });
    }
    render() {

        const { classes, open, close } = this.props;
        const { orderBy, sectionUrl, order, filteredData, page, searchValue, rowsPerPage, bookData, showSelectedSection,
            selectedSection, playAudio, selectedFile } = this.state;
        const data = (filteredData.length > 0 || searchValue) ? filteredData : bookData ? bookData : [];
        return (
            <>
                <Dialog open={open} close={close} fullWidth
                    maxWidth="lg"
                    aria-labelledby='form-dialog-title'
                    PaperProps={{ classes: { root: classes.container } }}>
                    <DialogTitle disableTypography={true} className={classes.title} id='form-dialog-title'>
                        <Typography className={classes.titleText} variant='h4'>  Sections
                        </Typography>
                        <IconButton
                            onClick={close}
                            className={classes.exitIcon}
                            aria-label="close dialog">
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <Paper>
                        <Toolbar classes={{ root: classes.toolbar }}>
                            <Typography className={classes.staffHeader} noWrap>
                                Story Data Sections
                            </Typography>
                            <div className={classes.actions}>
                                {(
                                    <TextField
                                        variant='outlined'
                                        id='table-search'
                                        aria-label='search input'
                                        type={'search'}
                                        InputProps={{
                                            inputProps: {
                                                'aria-label': 'search input'
                                            },
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <Search />
                                                </InputAdornment>
                                            ),
                                            className: classes.searchBarBar,
                                        }}
                                        onChange={(ev) => {
                                            this.handleSearch(ev.target.value);
                                            this.setState({ searchValue: ev.target.value });
                                        }}
                                        value={this.state.searchValue}
                                    />
                                )}
                            </div>
                        </Toolbar>

                        <div className={classes.tableWrapper}>
                            <Table >
                                <TableHead>
                                    <TableRow>
                                        {
                                            rows.length &&
                                            rows.map((row, index) => {
                                                return (
                                                    <TableCell
                                                        aria-label={row.id}
                                                        key={row.id + index}
                                                        colSpan={row.id == 'descriptiom' ? 2 : 1}
                                                        align={(row.id == "titleImageSrc") ? "left" : "center"}
                                                        sortDirection={orderBy === row.id ? order : false}
                                                    >
                                                        {row.id != "titleImageSrc" ? <Tooltip
                                                            title='Sort'
                                                            placement='bottom-start'
                                                            enterDelay={300}>
                                                            <TableSortLabel
                                                                active={orderBy === row.id}
                                                                direction={order}
                                                                onClick={(ev) => this.handleSort(ev, row.id)}>
                                                                {row.label}
                                                            </TableSortLabel>
                                                        </Tooltip> :
                                                            row.label
                                                        }
                                                    </TableCell>
                                                )
                                            })
                                        }
                                        <TableCell align="right">
                                            <Tooltip
                                                title='Action'
                                                placement='bottom-start'
                                                enterDelay={300}>
                                                <TableSortLabel
                                                >Action
                                                </TableSortLabel>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {stableSort(data, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((_data, index) => {
                                            return (
                                                <TableRow className={classes.hoverRow} tabIndex={0} hover key={_data.id + index}>
                                                    {rows.length &&
                                                        rows.map((row, index) => {
                                                            return (
                                                                <TableCell key={index}
                                                                    align={"center"} >
                                                                    {_data[row.id]}
                                                                </TableCell>
                                                            )
                                                        })
                                                    }
                                                    <TableCell align="left">
                                                        <div className={classes.buttons}>
                                                            <Tooltip title={<FormattedMessage id='General.edit' />}>
                                                                <IconButton
                                                                    onClick={(e) => this.handleStoryDataSectionEdit(e, _data)}>
                                                                    <Edit />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title='Play'>
                                                                {(!playAudio || _data.fileId != selectedFile) ? <IconButton onClick={() => { this.downloadAudio(_data) }}>
                                                                    <PlayCircleFilledOutlined />
                                                                </IconButton>
                                                                    : <IconButton onClick={() => { this.pauseAudio() }}>
                                                                        <PauseCircleOutline />
                                                                    </IconButton>
                                                                }
                                                            </Tooltip>
                                                        </div>
                                                    </TableCell>

                                                </TableRow>
                                            );
                                        })
                                    }
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[100, 500, 1000]}
                                component='div'
                                count={data && data.length}
                                labelRowsPerPage={<FormattedMessage id='Datatable.rowsPerPage' />}
                                rowsPerPage={this.state.rowsPerPage}
                                page={page}
                                backIconButtonProps={{
                                    'aria-label': 'Previous Page',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'Next Page',
                                }}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                            />
                        </div>
                    </Paper>
                </Dialog>
                {
                    showSelectedSection &&
                    <Dialog open={showSelectedSection} close={this.closeSelectedSectionModal} fullWidth
                        maxWidth="lg"
                        aria-labelledby='form-dialog-title'
                        PaperProps={{ classes: { root: classes.container } }}>
                        <DialogTitle disableTypography={true} className={classes.title} id='form-dialog-title'>
                            <Typography className={classes.titleText} variant='h4'>  Sections
                            </Typography>
                            <IconButton
                                onClick={this.closeSelectedSectionModal}
                                className={classes.exitIcon}
                                aria-label="close dialog">
                                <Close />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent classes={{ root: classes.scroll }}>
                            <FormGroup>
                                <TextField
                                    variant='outlined'
                                    id='student-edit-text'
                                    classes={{ root: classes.elementMargin }}
                                    label={<FormattedMessage id="General.text" />}
                                    name='text'
                                    type='text'
                                    defaultValue={selectedSection.text}
                                    value={this.state.sectionText}
                                    multiline
                                    maxRows={5}
                                    onChange={this.handleTextChange}
                                />
                                <div className={classes.storyDataElement}>
                                    <TextField
                                        variant='outlined'
                                        id='student-edit-sectionIndex'
                                        classes={{ root: classes.elementMargin }}
                                        label={<FormattedMessage id="General.sectionIndex" />}
                                        name='sectionIndex'
                                        type='text'
                                        value={selectedSection.sectionIndex}
                                        disabled
                                    />
                                    <TextField
                                        variant='outlined'
                                        id='student-edit-visemeData'
                                        classes={{ root: classes.elementMargin }}
                                        label={<FormattedMessage id="General.visemeData" />}
                                        name='visemeData'
                                        type='text'
                                        value={selectedSection.visemeData}
                                        disabled
                                    />
                                </div>
                                <div className={classes.storyDataElement}>
                                    <TextField
                                        variant='outlined'
                                        id='student-edit-analysis'
                                        classes={{ root: classes.elementMargin }}
                                        label={<FormattedMessage id="General.analysis" />}
                                        name='analysis'
                                        type='text'
                                        value={selectedSection.analysis}
                                        multiline
                                        maxRows={5}
                                        disabled
                                    />
                                </div>
                            </FormGroup>
                        </DialogContent>
                        <DialogActions className={classes.actions}>
                            <Button onClick={this.closeSelectedSectionModal} color='secondary'>
                                <FormattedMessage id="General.cancel" />
                            </Button>
                            <Button onClick={this.submitSections} color='primary'>
                                <FormattedMessage id="General.save" />
                            </Button>
                        </DialogActions>
                    </Dialog>
                }
                {playAudio && (sectionUrl && sectionUrl.length > 0) && <div style={{ display: 'none' }}>
                    <audio
                        id='sectionAud'
                        controls
                        autoPlay
                        onEnded={() => { this.pauseAudio(); }}
                    >
                        <source src={sectionUrl} type={"audio/mpeg"} />
                    </audio>
                </div>}
            </>
        )
    }
}

const mapState = ({ platform: { storyDataSections } }) => {
    return {
        storyDataSections
    }
};

const mapDispatch = (dispatch, { history }) => {
    return {
        updateStoryDataSections: (id, data) => dispatch(updateStoryDataSections(id, data))
    }
};
export default connect(mapState, mapDispatch)(withStyles(baseStyle)(StoryDataSectionList));