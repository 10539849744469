import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  TextField,
  Typography,
  Button,
  FormGroup,
  Link,
  withStyles,
  SnackbarContent,
  IconButton
} from '@material-ui/core/index';
import { Close } from '@material-ui/icons/index';
import logo from '../../../../assets/BrandAssets/Logo/OBJECTIVEED-Logo-Vertical-1.png';
import styles from '../../../../styles/registration';

const CollisionLink = React.forwardRef((props, ref) => (
  <RouterLink innerRef={ref} to="/login" {...props} />
));

class SpecialistRegistrationPage1 extends Component {

  state = {
    error: {
      email: false,
      firstName: false,
      lastName: false,
    },
  };

  componentDidMount(){
    // document.body.style.backgroundColor = '#310073';
  }

  handleClose = () => {
    this.props.clearErrors();
  };

  handleError = (err) => {
    switch(err.msg){
      case 'This invitation is not for you':
        return 'Please use your email from the invitation to register.';
      case 'Improper Access Key':
        return 'Please enter the correct Access Key';
      case 'Email In Use':
        return 'This email is already registered in our system.  If you require a password reset please go to https://platform.objectiveed.com/forgot-password';
      default:
        return err.msg.includes('is already in use') ? err.msg.split(':')[1] : err.msg
    }
  };

  check = (ev, type) => {
    switch(type){
      case 'email':
        let email = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(ev.target.value);
        this.setState({ error: { ...this.state.error, email }});
        break;
      case 'firstName':
        let firstName = !ev.target.value;
        this.setState({ error: {...this.state.error, firstName }});
        break;
      case 'lastName':
        let lastName = !ev.target.value;
        this.setState({ error: { ...this.state.error, lastName }});
        break;
      default:
        break;
    }
  };

  render(){
    const { classes, error } = this.props;
    const disabled = this.state.error.firstName || this.state.error.lastName || this.state.error.email;
    return (
      <>
        <Link className={ classes.logo } component={CollisionLink}>
          <img
            alt='objective ed logo'
            src={logo}
          />
        </Link>
        <Typography gutterBottom variant='h6'>Welcome to Objective Ed! Please fill out this form to register:</Typography>
        <FormGroup className={classes.name}>
          <TextField
            id='firstName'
            name='firstName'
            variant='outlined'
            label='First Name'
            error={this.state.error.firstName}
            helperText={this.state.error.firstName ? 'First Name is required' : ''}
            onChange={this.props.handleChange}
            onBlur={(ev) => this.check(ev, 'firstName')}
          />
          <TextField
            id='lastName'
            name='lastName'
            variant='outlined'
            label='Last Name'
            error={this.state.error.lastName}
            helperText={this.state.error.lastName ? 'Last Name is required' : ''}
            onChange={this.props.handleChange}
            onBlur={(ev) => this.check(ev, 'lastName')}
          />
        </FormGroup>
        <FormGroup>
          <TextField
            id='email'
            name='email'
            variant='outlined'
            label='Email'
            error={this.state.error.email}
            helperText={this.state.error.email ? 'A valid email is required' : ''}
            onChange={this.props.handleChange}
            onBlur={(ev) => this.check(ev, 'email')}
          />
        </FormGroup>
        {
          error.msg &&
          <SnackbarContent
            open={!!error.msg}
            onClose={this.handleClose}
            classes={{ root: classes.error }}
            message={<span id='message-id'>
              {
                this.handleError(error)
              }
            </span>}
            action={[
              <IconButton
                key='close'
                aria-label='Close'
                color='inherit'
                className={classes.close}
                onClick={this.handleClose}
              >
                <Close />
              </IconButton>,
            ]}
          />
        }
        <Button
          id='confirm-registration-button'
          disabled={disabled}
          className={ classes.confirmBtn }
          variant='contained'
          onClick={this.props.next}
        >
          Confirm Registration and Continue
        </Button>
        <Typography gutterBottom align='center' variant='caption'>
          Once your registration is complete, you will be sent an email with instructions on how to set up your password.
          Already have an account? <Link to='/login'>Go to login page</Link>.
        </Typography>
      </>
    )
  }
}

export default withStyles(styles)(SpecialistRegistrationPage1);