import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { ROOT_URL } from '../../../../../redux/constants';
import { FormattedMessage } from 'react-intl';
import ScrollManager from '../../../../containers/Shared/ScrollManager'
import {
    Button,
    Typography,
    IconButton,
    FormGroup,
    TextField,
    MenuItem,
    Divider
} from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import { Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { fetchLevels } from '../../../../../redux/actions';
// import baseStyle from '../../../../../styles/brailleSkill';
import baseStyle from '../../../../../styles/wordHunt';

import Map from './Maps';

class TempleExploreSkillDatabank extends Component {

    state = {
        tested: false,
        error: {},
        difficulty: 'Easy',
        type:'',
        mode:'',
        type: 'Pins',
        shuffle: 'Yes',
        questions: [],
        name: '',
        questionsPerRound: 1,
        center: {lat: 42.34501750600192, lng: -71.0827869023339},
        markers: []
    };

    componentDidMount() {
        if (this.props.level.id) {
            const variables = JSON.parse(this.props.level.levelJson);
            var lat = parseFloat(variables.latlong.split(",")[0]);
            var lng = parseFloat(variables.latlong.split(",")[1]);
            var center = {lat: lat, lng: lng};
            console.log(variables);
            console.log(center);
            var locations = variables.locations;
            var markers = [];
            var questions = [];
            variables.questions.map((jsonQuestion) => {
                var newQuestion = {
                    question: jsonQuestion.question,
                    questionType: jsonQuestion.type
                }
                switch (jsonQuestion.type) {
                    case "Pin":
                        newQuestion.pin = jsonQuestion.answer;
                        break;
                    case "Street":
                        newQuestion.street = jsonQuestion.answer;
                        break;
                    case "Intersection":
                        newQuestion.street1 = jsonQuestion.answer.split(",")[0].trim();
                        newQuestion.street2 = jsonQuestion.answer.split(",")[1].trim();
                        break;
                    case "Region":
                        newQuestion.region = jsonQuestion.answer;
                        break;
                    default:
                        break;
                }
                questions.push(newQuestion);
            })
            for (var i = 0; i < locations.length; i++) {
                let location = locations[i]
                let lat = parseFloat(location.latlong.split(",")[0]);
                let lng = parseFloat(location.latlong.split(",")[1]);
                let label = location.label;
                let id = Math.random();
                markers.push({lat: lat, lng: lng, name: label, id: id});
            }
            this.setState({
                difficulty: this.props.level.difficulty,
                mode: variables.mode,
                name: variables.name,
                type: variables.type,
                center: center,
                markers: markers,
                shuffle: variables.shuffle,
                questionsPerRound: variables.questionsPerRound,
                questions: questions
            })
        }
    }

    close = () => {
        this.props.close();
        this.setState({
            tested: false,
            error: {},
            difficulty: 'Easy',
            mode: '',
            name: '',
            type:''
        })
    };

    runTest = () => {
        const {
            difficulty,
            mode,
            name,
            type,
            questions,
            tested,
            shuffle,
            questionsPerRound
        } = this.state;
        const map_data = this.state.mapProps();
        let lat = map_data.mapPosition.lat;
        let lng = map_data.mapPosition.lng;
        let markers = map_data.markers;
        var locations = [];
        for (var i = 0; i < markers.length; i++) {
            let marker = markers[i];
            locations.push({"latlong": marker.lat+","+marker.lng, "label":marker.name});
        }
        var jsonQuestions = [];
        questions.map((question) => {
            var answer;
            switch (question.questionType) {
                case "Pin":
                    answer=question.pin;
                    break;
                case "Street":
                    answer=question.street;
                    break;
                case "Intersection":
                    answer=question.street1+", "+question.street2;
                    break;
                case "Region":
                    answer = question.region;
                    break;
                default:
                    answer="";
                    break;
            }
            jsonQuestions.push({
                "question": question,
                "type": question.questionType,
                "answer": answer
            })
        })

        const levelJson = JSON.stringify({
            "latlong": lat+","+lng,
            "locations": locations,
            "mode": mode,
            "name": name,
            "type": type,
            "shuffle": shuffle,
            "questions": jsonQuestions,
            "questionsPerRound": questionsPerRound
        });
        const url = this.props.type === 'staff' ? `${ROOT_URL}/staff/${this.props.id}/objective-databank/test`: `${ROOT_URL}/admin/objective-databank/test`;
        if (tested) this.clearTest();
        axios.post(url, { levelVariables: levelJson, templateId: "-3049475864438646220"})
            .catch(err => console.log(err));
        this.setState({ tested: true });
    };

    clearTest = () => {
        const url = this.props.type === 'staff' ? `${ROOT_URL}/staff/${this.props.id}/objective-databank/test`: `${ROOT_URL}/admin/objective-databank/test`;
        axios.delete(url)
            .catch(err => console.log(err));
        this.setState({ tested: false })
    };

    handleChange = (ev) => {
        const value = ev.target.type === 'number' ? ev.target.value > 0 ? ev.target.value * 1 : '' : ev.target.value;
        this.setState({ [ev.target.name]: value })
    };

    checkForErrors = (ev) => {
        if (!ev.target.value){
            this.setState({ error: {...this.state.error, [ev.target.name]: true }})
        }
        else {
            let error = this.state.error;
            delete error[ev.target.name];
            this.setState({ error })
        }
    };

    handleSubmit = (ev) => {
        ev.preventDefault();
        const {
            difficulty,
            mode,
            name,
            type,
            questions,
            shuffle,
            questionsPerRound
        } = this.state;
        const map_data = this.state.mapProps();
        let lat = map_data.mapPosition.lat;
        let lng = map_data.mapPosition.lng;
        let markers = map_data.markers;
        var locations = [];
        for (var i = 0; i < markers.length; i++) {
            let marker = markers[i];
            locations.push({"latlong": marker.lat+","+marker.lng, "label":marker.name});
        }
        var jsonQuestions = [];
        questions.map((question) => {
            var answer;
            switch (question.questionType) {
                case "Pin":
                    answer=question.pin;
                    break;
                case "Street":
                    answer=question.street;
                    break;
                case "Intersection":
                    answer=question.street1+", "+question.street2;
                    break;
                case "Region":
                    answer = question.region;
                    break;
                default:
                    answer="";
                    break;
            }
            jsonQuestions.push({
                "question": question.question,
                "type": question.questionType,
                "answer": answer
            })
        })
        const levelJson = JSON.stringify({
            "latlong": lat+","+lng,
            "locations": locations,
            "mode": mode,
            "name": name,
            "type": type,
            "shuffle": shuffle,
            "questions": jsonQuestions,
            "questionsPerRound": questionsPerRound
        });
        this.props.onSubmit({ levelTemplateId: "-3049475864438646220", levelJson, difficulty });
    };

    register = (func) => {
        this.setState({"mapProps": func});
    }

    addQuestion = () => {
        this.setState({
            questions: [
                ...this.state.questions,
                {
                    question: '',
                    questionType: '',
                    pin: '',
                    street: '',
                    street1: '',
                    street2: '',
                    region: ''
                }
            ]
        })
    };
    removeQuestion = (idx) => {
        const questions = this.state.questions.filter((question, index) => index !== idx);
        this.setState({ questions })
    };

    handleChangeQuestion = (ev, index) => {
        const value = ev.target.value;
        const name = ev.target.name;
        this.setState(
            { questions: this.state.questions.map(
                    obj => this.state.questions.indexOf(obj) === index ? {...obj, [name]: value, index } : obj
                )
            }
        )
    };

    render()  {
        const { classes, open, close, back, levels, game } = this.props;
        const { tested, error } = this.state;
        let disabled = Object.keys(error).length > 0 || !this.state.mode  || !this.state.name
        disabled = disabled && (this.state.questionsPerRound > this.state.questions.length);
        if(this.state.type === "Custom" && this.state.questionsPerRound > this.state.questions.length) {
            disabled = true;
        }
        let markerError = false;
        let markers = [];
        if(this.state.type == "Custom" && this.state.questions.length<=0) {
            this.addQuestion();
        }
        if(this.state.mapProps) {
            const map_data = this.state.mapProps();
            let lat = map_data.mapPosition.lat;
            let lng = map_data.mapPosition.lng;
            markers = map_data.markers;
            if(this.state.mode && (this.state.mode == "Quiz" || this.state.mode == "Quiz-Touch")) {
                disabled = disabled || (markers.length <= 0);
                markerError = (markers.length <= 0);
            }
        }
        return (
            <Dialog
                disableEscapeKeyDown
                maxWidth='xl'
                fullWidth={true}
                open={this.props.open}
                onClose={this.props.close}
                aria-labelledby='form-dialog-title'
                PaperProps={{classes:{ root: classes.container }}}
            >

                <DialogTitle disableTypography={true} className={ classes.title }>
                    <Typography className={ classes.titleText } variant='h4' id='skill-dialog-title'><FormattedMessage id='Wizard.skillDB.title'/></Typography>
                    <IconButton
                        onClick={this.close}
                        className={ classes.exitIcon }
                        aria-label="close dialog button"
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <ScrollManager scrollKey="some-list-key">
                    {({ connectScrollTarget, ...props }) =>


                <DialogContent>
                    <Typography gutterBottom variant='h3'>
                        <FormattedMessage id='Wizard.Maps.title'/>
                    </Typography>
                    <Typography className={classes.description} variant='subtitle1'>
                        <FormattedMessage id='Wizard.Maps.subtitle'/>
                    </Typography>
                    <Divider />
                    <FormGroup className={classes.configureLine}>
                        <ScrollManager scrollKey="some-screen-key" />
                        <Typography                             style={{ marginTop: "20px"}}
                                                                className={classes.configTitle} variant='h6'><FormattedMessage id='Wizard.Maps.MapName'/>: </Typography>
                        <TextField
                            variant='outlined'
                            className={classes.configComponent}
                            type='text'
                            name='name'
                            onBlur={this.checkForErrors}
                            error={error.name}
                            helperText={error.name ? '* required' : ''}
                            onChange={this.handleChange}
                            value={this.state.name}
                        />
                        <Map
                            //42.34501750600192, -71.0827869023339
                            google={this.props.google}
                            center={this.state.center}
                            height='600px'
                            width='800px'
                            markers={this.state.markers}
                            register={this.register}
                            zoom={18}
                        />
                        <Typography                             style={{ marginTop: "60px"}}
                                                                className={classes.configTitle} variant='h6'><FormattedMessage id='Wizard.Maps.ModeType'/>: </Typography>
                        <TextField
                            select
                            variant='outlined'
                            className={classes.configComponent}
                            name='mode'
                            type='text'
                            error={error.mode || markerError}
                            helperText={error.mode ? '* required' : markerError ? "This mode requires map pins" : ""}
                            onBlur={this.checkForErrors}
                            value={this.state.mode}
                            onChange={this.handleChange}
                        >
                            <MenuItem key={"Touch"} value={"Touch"}>{"Touch Explore Mode"}</MenuItem>
                            <MenuItem key={"Quiz"} value={"Quiz"}>{"Quiz Mode"}</MenuItem>
                            <MenuItem key={"Quiz-Touch"} value={"Quiz-Touch"}>{"Quiz with Touch Explore"}</MenuItem>
                        </TextField>

                        <Typography style={{ marginTop: "20px"}} className={classes.configTitle} variant='h6'><FormattedMessage id='Wizard.Maps.Type'/>: </Typography>
                        <TextField
                            select
                            variant='outlined'
                            className={classes.configComponent}
                            name='type'
                            type='text'
                            error={error.mode || markerError}
                            helperText={error.mode ? '* required' : markerError ? "This mode requires map pins" : ""}
                            onBlur={this.checkForErrors}
                            value={this.state.type}
                            onChange={this.handleChange}
                        >
                            <MenuItem key={"Pins"} value={"Pins"}>{"Default Questions"}</MenuItem>
                            <MenuItem key={"Custom"} value={"Custom"}>{"Custom Questions"}</MenuItem>
                        </TextField>
                        { this.state.type == "Custom" && (
                            <div>
                                <Typography style={{ marginTop: "20px"}} className={classes.configTitle} variant='h6'><FormattedMessage id='Wizard.Maps.Shuffle'/>: </Typography>
                                <TextField
                                    select
                                    variant='outlined'
                                    className={classes.configComponent}
                                    name='shuffle'
                                    type='text'
                                    error={error.mode || markerError}
                                    helperText={error.mode ? '* required' : markerError ? "This mode requires map pins" : ""}
                                    onBlur={this.checkForErrors}
                                    value={this.state.shuffle}
                                    onChange={this.handleChange}
                                >
                                    <MenuItem key={"Yes"} value={"Yes"}>{"Yes"}</MenuItem>
                                    <MenuItem key={"No"} value={"No"}>{"No"}</MenuItem>
                                </TextField>
                                <Typography style={{ marginTop: "20px"}} className={classes.configTitle} variant='h6'><FormattedMessage id='Wizard.Maps.questionsPerRound'/>: </Typography>
                                <TextField
                                    variant='outlined'
                                    id='time-per-question'
                                    name='questionsPerRound'
                                    type='number'
                                    value={this.state.questionsPerRound}
                                    onChange={this.handleChange}
                                    onBlur={this.checkForErrors}
                                    error={error.questionsPerRound}
                                    helperText={error.questionsPerRound ? '* required' : ''}
                                />
                                {
                                    this.state.questions.map((question, index) => {
                                        return (
                                            <div className={classes.question} key={index}>
                                                <FormGroup className={classes.lastLine}>
                                                    <Typography color='inherit' className={classes.inlineText} variant='h6'><FormattedMessage id='Wizard.Maps.Question'/>: </Typography>
                                                    <TextField
                                                        className={classes.inlineSelect}
                                                        id='question-select'
                                                        name='question'
                                                        aria-label='Question To Ask'
                                                        value={question.question}
                                                        onChange={(ev) => this.handleChangeQuestion(ev, index)}
                                                    >
                                                    </TextField>
                                                    <TextField
                                                        select
                                                        className={classes.inlineSelect}
                                                        id='question-type'
                                                        name='questionType'
                                                        aria-label='Answer Type'
                                                        value={question.questionType}
                                                        onChange={(ev) => this.handleChangeQuestion(ev, index)}
                                                    >
                                                        {
                                                            [
                                                                {text: <FormattedMessage id='Wizard.Maps.PinQuestion'/>, value: "Pin"},
                                                                {text: <FormattedMessage id='Wizard.WordHunt.IntersectionQuestion'/>, value: "Intersection"},
                                                                {text: <FormattedMessage id='Wizard.Maps.StreetQuestion'/>, value: "Street"},
                                                                {text: <FormattedMessage id='Wizard.Maps.RegionQuestion'/>, value: "Region"}
                                                            ].map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>{option.text}</MenuItem>
                                                            ))
                                                        }
                                                    </TextField>
                                                        {question.questionType == "Pin" && (
                                                            <TextField
                                                                select
                                                                className={classes.inlineSelect}
                                                                id='question-type'
                                                                name='pin'
                                                                aria-label='Answer Type'
                                                                value={question.pin}
                                                                onChange={(ev) => this.handleChangeQuestion(ev, index)}
                                                            >
                                                                {
                                                                markers.map((marker) => (
                                                                    <MenuItem key={marker.name} value={marker.name}>{marker.name}</MenuItem>
                                                                ))
                                                                }
                                                            </TextField>
                                                        )
                                                        }
                                                        {question.questionType == "Intersection" && (
                                                            <div>
                                                                <Typography color='inherit' className={classes.inlineText} variant='h6'><FormattedMessage id='Wizard.Maps.Intersection1'/>: </Typography>
                                                                <TextField
                                                                    className={classes.inlineSelect}
                                                                    name='street1'
                                                                    aria-label='First Street'
                                                                    value={question.street1}
                                                                    onChange={(ev) => this.handleChangeQuestion(ev, index)}
                                                                >
                                                                </TextField>
                                                                <Typography color='inherit' className={classes.inlineText} variant='h6'><FormattedMessage id='Wizard.Maps.Intersection2'/>: </Typography>
                                                                <TextField
                                                                    className={classes.inlineSelect}
                                                                    name='street2'
                                                                    aria-label='Second Street'
                                                                    value={question.street2}
                                                                    onChange={(ev) => this.handleChangeQuestion(ev, index)}
                                                                >
                                                                </TextField>
                                                            </div>
                                                        )
                                                        }
                                                        {question.questionType == "Street" && (
                                                            <div>
                                                                <Typography color='inherit' className={classes.inlineText} variant='h6'><FormattedMessage id='Wizard.Maps.Street'/>: </Typography>
                                                                <TextField
                                                                    className={classes.inlineSelect}
                                                                    name='street'
                                                                    aria-label='Street'
                                                                    value={question.street}
                                                                    onChange={(ev) => this.handleChangeQuestion(ev, index)}
                                                                >
                                                                </TextField>
                                                            </div>
                                                        )
                                                        }
                                                        {question.questionType == "Region" && (
                                                            <div>
                                                                <Typography color='inherit' className={classes.inlineText} variant='h6'><FormattedMessage id='Wizard.Maps.Region'/>: </Typography>
                                                                <TextField
                                                                    select
                                                                    className={classes.inlineSelect}
                                                                    name='region'
                                                                    aria-label='Region'
                                                                    value={question.region}
                                                                    onChange={(ev) => this.handleChangeQuestion(ev, index)}
                                                                >
                                                                    <MenuItem key={"North East"} value={"North East"}>{"North East"}</MenuItem>
                                                                    <MenuItem key={"North West"} value={"North West"}>{"North West"}</MenuItem>
                                                                    <MenuItem key={"South East"} value={"South East"}>{"South East"}</MenuItem>
                                                                    <MenuItem key={"South West"} value={"South West"}>{"South West"}</MenuItem>

                                                                </TextField>
                                                            </div>
                                                        )
                                                        }
                                                </FormGroup>

                                                <IconButton onClick={() => this.removeQuestion(index)} classes={{root:classes.delete}}>
                                                    <Close color='secondary'/>
                                                </IconButton>
                                            </div>
                                        )
                                    })
                                }
                                <div className={classes.questionBtns}>
                                    <Button
                                        variant='contained'
                                        id='add-question'
                                        color='primary'
                                        classes={{ root: classes.addButton }}
                                        onClick={this.addQuestion}
                                    >
                                        <FormattedMessage id='Wizard.BrailleQuiz.addQuestion'/>
                                    </Button>
                                </div>
                            </div>
                        )}
                    </FormGroup>
                </DialogContent>
                    }
                </ScrollManager>
                <DialogActions className={classes.buttons}>
                    <Button onClick={back} color='secondary'>
                        <FormattedMessage id='General.back' />
                    </Button>
                    <Button
                        onClick={ this.runTest }
                        variant={ tested ? 'outlined' : 'text' }
                        color={ tested ? 'secondary' : 'primary' }
                    >
                        { tested ? <FormattedMessage id='General.retest' /> : <FormattedMessage id='General.test' /> }
                    </Button>
                    <Button disabled={disabled} onClick={this.handleSubmit} color='primary'>
                        <FormattedMessage id='General.next' />
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const  mapState = ({ platform: { levels }}) => {
    return {
        levels
    }
};

const mapDispatch = (dispatch) => {
    return {
        fetchLevels: () => dispatch(fetchLevels())
    }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(TempleExploreSkillDatabank));