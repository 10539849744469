import React, { Component } from 'react';
import { Button, FormGroup, Typography, TextField, IconButton } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../../styles/wizardBase';
import { FormattedMessage } from 'react-intl';

class GoalDatabankWizardPage1 extends Component {

  close = () => {
    this.props.close();
  };

  submit = () => {
    this.props.submit()
  };

  render()  {
    const { classes, goalDB } = this.props;
    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        open={this.props.open}
        onClose={this.props.close}
        aria-labelledby='form-dialog-title'
        PaperProps={{ classes:{ root: classes.container }}}
      >
        <DialogTitle disableTypography={true} className={ classes.title } id='form-dialog-title'>
          <Typography className={ classes.titleText } variant='h4'><FormattedMessage id='Wizard.GoalDB.title'/></Typography>
          <IconButton
            onClick={this.close}
            className={ classes.exitIcon }
            aria-label="close dialog"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <br />
          <DialogContentText>
            <FormattedMessage id='Wizard.GoalDB'/>
          </DialogContentText>
          <FormGroup classes={{ root: classes.root }}>
            <TextField
              variant='outlined'
              classes={{ root: classes.firstName }}
              id='goal-name'
              label={<FormattedMessage id='General.name'/>}
              type='text'
              name='name'
              onChange={this.props.handleChange}
              value={goalDB.name}
            />
            <TextField
              multiline
              variant='outlined'
              classes={{ root: classes.firstName }}
              id='goal-description'
              label={<FormattedMessage id='General.description'/>}
              type='text'
              name='description'
              rows={3}
              value={goalDB.description}
              onChange={this.props.handleChange}
            />
          </FormGroup>
        </DialogContent>
        <DialogActions className={classes.buttons}>
          <Button onClick={this.close} color='secondary'>
            <FormattedMessage id='General.cancel'/>
          </Button>
          <Button onClick={this.submit} color='primary'>
            <FormattedMessage id='General.submit'/>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(baseStyle)(GoalDatabankWizardPage1);