import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { capitalize, getRandomColor } from '../../../helpers';
import { fetchStaffBrailleSheetsForGame, fetchMyBrailleSheets, clearErrors } from '../../../redux/actions';
import BrailleSheetWizardGrid from '../Wizards/BrailleSheets/BrailleSheetWizardGrid';
import {
  AppBar,
  Tab,
  Tabs,
  TablePagination,
  IconButton,
  Paper,
  Typography,
  Toolbar,
  Icon,
  TextField,
  InputAdornment,
  withStyles,
} from '@material-ui/core/index';
import { Search, ArrowDropUp, ArrowDropDown } from '@material-ui/icons/index';
import styles from '../../../styles/importBrailleSheet.js';

const CollisionLink = (url) => React.forwardRef((props, ref) => (
  <Link innerRef={ref} to={url} {...props} />
));

class ImportBrailleSheet extends Component {

  state = {
    selected: {},
    open: false,
    page: 0,
    rowsPerPage: 3,
    imported: [],
    type: 0,
    filteredData: [],
    searchValue: '',
    show: []
  };

  componentDidMount() {
    // this.props.fetchStaffBrailleSheetsForGame(this.props.id, this.props.game);
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if ((nextProps.staffBrailleSheets || nextProps.myBrailleSheets) && (nextProps.myBrailleSheets !== this.props.myBrailleSheets || nextProps.staffBrailleSheets !== this.props.staffBrailleSheets)) {
      const data = this.state.type === 0 ? nextProps.staffBrailleSheets : nextProps.myBrailleSheets;
      this.handleSearch(this.state.searchValue, data)
    }
  }

  handleSearch = (searchValue, data) => {
    const _data = this.state.type === 0 ? this.props.staffBrailleSheets : this.props.myBrailleSheets;
    const searchableData = data ? data : _data;
    const filtered = searchableData.filter(item => {
      const searchableKeys = this.state.type === 0
        ? ['name', 'description']
        : ['name', 'description', 'comment', 'author', 'group'];
      return searchableKeys.some(key => {
        if (key === 'group' && item.group) {
          return item.group.name.toLowerCase().includes(searchValue.toLowerCase())
        }
        if (key === 'author' && item.author){
          return item.author.name.toLowerCase().includes(searchValue.toLowerCase())
        }
        if (item[key]){
          return item[key].toLowerCase().includes(searchValue.toLowerCase())
        }
        else {
          return false
        }
      })
    });
    this.setState({ filteredData: filtered, page: 0 })
  };

  handleSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleChangeTab = (ev, newValue) => {
    this.setState({ type: newValue, page: 0 });
    const data = newValue === 0 ? this.props.staffBrailleSheets : this.props.myBrailleSheets;
    this.handleSearch(this.state.searchValue, data);
  };

  closeError = (sheet) => {
    this.props.clearErrors();
    this.setState({ imported: this.state.imported.filter(item => item !== sheet.key)})
  };

  importBrailleSheet = (sheet) => {
    const shared = this.state.type === 0;
    const brailleSheetDto = {};
    const type = this.props.staff ? 'staff' : '';
    this.props.importGoal(this.props.id, brailleSheetDto, sheet.key, shared, type);
    const id = sheet.key ? sheet.key : sheet.id;
    this.setState(state => ({ imported: [...state.imported, id]}))
  };

  toggleShow = (sheet) => {
    const id = sheet.key ? sheet.key : sheet.id;
    this.setState(state => ({ show: { ...state.show, [id]: !state.show[id] }}))
  };

  render(){
    const { classes, staffBrailleSheets, myBrailleSheets } = this.props;
    const { page, rowsPerPage, filteredData, searchValue } = this.state;
    const data = filteredData.length || searchValue
      ? filteredData
      : this.state.type === 0 ? staffBrailleSheets : myBrailleSheets;
    const items = data.length;
    return (
      <>
        <Paper className={ classes.list }>
          <Toolbar classes={{ root: classes.toolbar }}>
            <Typography noWrap className={ classes.header }>
              <strong>Braille Sheets</strong>
            </Typography>
            <div className={classes.actions}>
              <TextField
                className={ classes.search }
                variant='outlined'
                id='table-search'
                aria-label='search input'
                type='search'
                InputProps={{
                  inputProps: {
                    'aria-label':'search input'
                  },
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Search />
                    </InputAdornment>
                  ),
                  className: classes.searchBar,
                }}
                onChange={(ev) => {
                  this.handleSearch(ev.target.value);
                  this.setState({ searchValue: ev.target.value });
                }}
                value={this.state.searchValue}
              />
            </div>
          </Toolbar>
          {/* SHEET DETAIL */}
          {
              <>
                <AppBar position='static' color='inherit'>
                  <Tabs
                    value={this.state.type}
                    onChange={this.handleChangeTab}
                    indicatorColor='primary'
                    textColor='primary'
                    variant='fullWidth'
                  >
                    <Tab label='My Databank' />
                    <Tab label='From My Network' />
                  </Tabs>
                </AppBar>
                <section>
                  {
                    this.state.type === 0
                      ? data && data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((sheet, index) => (
                      <Paper key={sheet.id+index} className={classNames(classes.post, this.props.selected === sheet.id ? classes.selected : '')}>
                        <div onClick={() => this.props.selectSheet(sheet)}>
                          <Typography className='name' variant='h4'>{sheet.name}</Typography>
                          <Typography className='description' variant='h6'>{capitalize(sheet.description)}</Typography>
                          <Typography className='name' variant='h6'>Code: {capitalize(sheet.code)}</Typography>

                        </div>
                        <IconButton className={classes.show} onClick={() => this.toggleShow(sheet)}>
                        {
                          this.state.show[sheet.id]
                            ? <ArrowDropUp/>
                            : <ArrowDropDown/>
                        }
                        </IconButton>
                        {
                          this.state.show[sheet.id] &&
                          <div className={classes.grid}>
                            <BrailleSheetWizardGrid grid={JSON.parse(sheet.grid)}/>
                          </div>
                        }
                      </Paper>
                    ))
                      : data && data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((sheet,index) => (
                      <Paper key={sheet.key+index} className={classNames(classes.post, this.props.selected === sheet.key ? classes.selected : '')}>
                        <div onClick={() => this.props.selectSheet(sheet)}>
                          <Typography className='name' variant='h4'>{sheet.name}</Typography>
                          <Typography className='description' variant='h6'>{capitalize(sheet.description)}</Typography>
                          <div className={classes.comment}>
                            <Icon className='far fa-comments icon'/><Typography
                            variant='subtitle1'>{sheet.comment}</Typography>
                          </div>
                          <div style={{ backgroundColor: getRandomColor(sheet.groupKey) }} className={classes.footer}>
                            <Typography className='text' variant='subtitle1'>
                              {`By ${(sheet.author && sheet.author.name) || 'Objective Ed'} in `}
                              <Typography variant='subtitle1' component={CollisionLink(`/groups/${sheet.groupKey}`)}>
                                {sheet.group && `${sheet.group.name}`}
                              </Typography>
                            </Typography>
                          </div>
                        </div>
                        <IconButton className={classes.show} onClick={() => {
                          this.props.selectSheet(sheet, true);
                          this.toggleShow(sheet);
                        }}>
                          {
                            this.state.show[sheet.key]
                              ? <ArrowDropUp/>
                              : <ArrowDropDown/>
                          }
                        </IconButton>
                        {
                          this.state.show[sheet.key] && this.props.grid &&
                          <div className={classes.grid}>
                            <BrailleSheetWizardGrid grid={this.props.grid}/>
                          </div>
                        }
                      </Paper>
                    ))
                  }
                </section>
              </>
          }
          <TablePagination
            rowsPerPageOptions={[
              this.state.rowsPerPage,
              this.state.rowsPerPage*2,
              this.state.rowsPerPage*3
            ]}
            component='div'
            count={items}
            rowsPerPage={this.state.rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </>
    )
  }
}

const mapState = ({ platform: { staffBrailleSheets }, social: { myBrailleSheets }, shared: { error }}) => {
  return {
    staffBrailleSheets,
    myBrailleSheets,
    error
  }
};

const mapDispatch = (dispatch) => {
  return {
    fetchStaffBrailleSheetsForGame: (id, game) => dispatch(fetchStaffBrailleSheetsForGame(id, game)),
    fetchMyBrailleSheets: () => dispatch(fetchMyBrailleSheets()),
    clearErrors: () => dispatch(clearErrors())
  }
};

export default connect(mapState, mapDispatch)(withStyles(styles)(ImportBrailleSheet));