import {
  FETCH_GOAL_DATABANKS,
  CREATE_GOAL_DATABANK,
  DELETE_GOAL_DATABANK,
  UPDATE_GOAL_DATABANK,
  FETCH_STAFF_GOAL_DATABANKS,
  CREATE_STAFF_GOAL_DATABANK,
  DELETE_STAFF_GOAL_DATABANK,
} from '../../constants';

export const goalDatabanks = (state = [], action) => {
  switch(action.type){
    case FETCH_GOAL_DATABANKS:
      return action.goalDatabanks;
    case CREATE_GOAL_DATABANK:
      return [...state, action.goalDatabank];
    case UPDATE_GOAL_DATABANK:
      return state.map(goalDatabank => goalDatabank.id === action.goalDatabank.id ? action.goalDatabank : goalDatabank );
    case DELETE_GOAL_DATABANK:
      return state.filter(goalDatabank => goalDatabank.id !== action.goalDatabank.id);
    default:
      return state;
  }
};

export const staffGoalDatabanks = (state = [], action) => {
  switch(action.type){
    case FETCH_STAFF_GOAL_DATABANKS:
      return action.goalDatabanks;
    case CREATE_STAFF_GOAL_DATABANK:
      return [...state, action.goalDatabank];
    case UPDATE_GOAL_DATABANK:
      return state.map(goalDatabank => goalDatabank.id === action.goalDatabank.id ? action.goalDatabank : goalDatabank );
    case DELETE_STAFF_GOAL_DATABANK:
      return state.filter(goalDatabank => goalDatabank.id !== action.goalDatabank.id);
    default:
      return state;
  }
};