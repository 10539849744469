import { FETCH_POSTS, CREATE_POST, UPDATE_POST, ROOT_URL, GET_TAGS } from '../../constants';
import { getGroup, getMyFeed } from '../../actions';
import axios from 'axios';

export const fetchPosts = (posts) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_POSTS,
      posts
    })
  };
};

export const createPost = (groupId, post) => {
  const url = `${ROOT_URL}/groups/${groupId}/posts`;
  return (dispatch) => {
    return axios
      .post(url, post)
      .then(response => {
        dispatch({
          type: CREATE_POST,
          post: response.data
        });
        return response.data
      })
      .catch(err => console.log(err))
  }
};

export const addReply = (post, reply) => {
  const url = `${ROOT_URL}/posts/${post.key}/posts`;
  return (dispatch) => {
    return axios
      .post(url, reply)
      .then(response => {
        dispatch({
          type: UPDATE_POST,
          // add the new reply to the post so redux is updated
          post: {
            ...post,
            replies: [
              ...post.replies,
              response.data
            ]
          }
        });
        dispatch(getMyFeed());
        return response.data;
      })
      .catch(err => console.log(err))
  }
};

export const deletePost = (post) => {
  const url = `${ROOT_URL}/posts/${post.key}`;
  return (dispatch) => {
    axios
      .delete(url, {data: post})
      .then(() => {
        dispatch(getGroup(post.groupKey))
      })
      .catch(err => console.log(err))
  }
};

export const togglePin = (post, pinned) => {
  const url =`${ROOT_URL}/posts/${post.key}/${pinned ? 'unpin' : 'pin'}`;
  return (dispatch) => {
    axios
      .post(url)
      .then(() => {
        dispatch(getGroup(post.groupKey))
      })
      .catch(err => console.log(err))
  }
};

export const toggleLike = (post, liked) => {
  const url = `${ROOT_URL}/posts/${post.key}/${liked ? 'unlike' : 'like'}`;
  return (dispatch) => {
    axios
      .post(url)
      .then(() => {
        dispatch(getGroup(post.groupKey))
      })
      .catch(err => console.log(err))
  }
};

export const loadMore = (posts, batchPosts, page, pages) => {
  const url = `${ROOT_URL}/posts/batch`;
  return (dispatch) => {
    axios
      .post(url, batchPosts)
      .then(response => {
        console.log([...posts.slice(0, page*pages), ...response.data, ...posts.slice((page+1)*pages)]);
        dispatch({
          type: FETCH_POSTS,
          posts: [...posts.slice(0, page*pages), ...response.data, ...posts.slice((page+1)*pages)]
        });
      })
  }
};

export const searchTags = (tagTerm) => {
  const url = `${ROOT_URL}/posts/search/tags`;
  return (dispatch) => {
    return axios
      .post(url, tagTerm, { headers: {'content-type': 'text/plain' }})
      .then(response => {
        dispatch({
          type: GET_TAGS,
          tags: response.data
        });
        return response.data
      })
  }
};
