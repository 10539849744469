import { ROOT_URL, FETCH_ALL_SPECIALISTS, REGISTER_SPECIALIST, DEREGISTER_SPECIALIST } from '../../constants';
import axios from 'axios';

export const fetchSpecialists = () => {
  const url = `${ROOT_URL}/specialists`;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: FETCH_ALL_SPECIALISTS,
          specialists: response.data
        })
      })
      .catch(err => console.error(err))
  }
};

export const createSpecialist = (account, specialist, students) => {
  const url = `${ROOT_URL}/specialists?accountId=${account.id}`;
  return (dispatch) => {
    axios
      .post(url, specialist)
      .then((response) => {
        dispatch({
          type: REGISTER_SPECIALIST,
          specialist: response.data
        })
      })
      .then(() => {
        if (students) {
          return axios
            .post(`${ROOT_URL}/staff/${specialist.id}/students`, students)
            .catch(err => console.log(err))
        }
      })
      .catch(err => console.log(err))
  }
};

export const deregisterSpecialist = (specialist, account) => {
  const url = `${ROOT_URL}/specialists/${specialist.id}/accounts/${account.id}`;
  return (dispatch) => {
    axios
      .delete(url)
      .then(() => {
        dispatch({
          type: DEREGISTER_SPECIALIST,
          specialist
        })
      })
      .catch(err => console.log(err))
  }
};

export const resetDevice = (specialistId) => {
  const url = `${ROOT_URL}/specialists/${specialistId}/device/reset`;
  axios
    .post(url)
    .catch(err => console.log(err))
};