import { FETCH_ALL_LEVELS, CREATE_LEVEL, DELETE_LEVEL, PATCH_LEVEL } from "../../constants";

export default function (state = [], action) {
  switch(action.type){
    case FETCH_ALL_LEVELS:
      return action.levels;
    case CREATE_LEVEL:
      return [...state, action.level];
    case PATCH_LEVEL:
      return state.map(level => level.id === action.level.id ? action.level : level );
    case DELETE_LEVEL:
      return state.filter(level => level.id !== action.level.id);
    default:
      return state;
  }
}