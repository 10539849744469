import React, { Component } from 'react';
import { ResponsiveLine, Line } from '@nivo/line';
import { Button, Card, CardContent, Divider, Typography } from '@material-ui/core';
import { Timeline } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../styles/lineChart';
import { FormattedMessage } from 'react-intl';
import { saveAs } from 'file-saver';

const tooltip = (slice) => {
  const options = { weekday: 'long', month: 'numeric', day: 'numeric' };
  const skills = slice.slice.points.map(skill => {
    return (
      <div key={skill.id}>
        <Typography variant='subtitle2'><strong>{skill.serieId}: </strong>{Math.round(skill.data.y.toFixed(3) * 100)}%</Typography>
      </div>
    )
  });

  return (
    <div
        style={{
          background: 'white',
          padding: '9px 12px',
          border: '1px solid #ccc',
        }}>
      <Typography gutterBottom variant={'h6'}>{slice.slice.points[0].data.x.toLocaleDateString('en-US', options)}</Typography>
      {skills}
    </div>
  )
};

const percentage = (value) => {
  return `${value * 100}%`
};

const drawInlineSVG = (svgElement, ctx, callback) => {
  let svgURL = new XMLSerializer().serializeToString(svgElement);
  let img  = new Image();
  img.onload = function(){
    ctx.drawImage(this, 0,0);
    callback();
  };
  img.src = 'data:image/svg+xml; charset=utf8, '+encodeURIComponent(svgURL);
};

class PercentToTargetLine extends Component {

  state = {
    loading: true
  };

  componentDidMount() {
    setTimeout(() => this.setState({ loading: false }), 500)
  };

  handleClick = () => {
    let myCanvas = document.getElementById(`percentToLineCanvas`);
    let ctxt = myCanvas.getContext("2d");
    let chart = document.querySelector(`#percentToLine svg[role="img"]`);

    drawInlineSVG(chart, ctxt, function(){myCanvas.toBlob(blob => saveAs(blob, 'Percent To Target.png'))})
  };

  getTspanGroups = (value) => {
    const str = value;
    const pattern = /(.{1,10}\W)|(.{1,10})/gm;
    var array = str.match(pattern).map(match => match.trim())
    var toRet = "";
    array.map(_val => toRet+=_val+"/n");
    return value;
  }

  render(){
    const { classes, title, data,suppressStyle} = this.props;
    const { loading } = this.state;
    const Title = ({ width, height }) => {
      // You can console.log(data) to see what other properties are available

      const style = {fontWeight: 'bold'}

      return (
          <text
              x="28%" dominantBaseline="middle" textAnchor="middle"
              y={-15}
              style={style}
          >
            Percent to Target
          </text>
      )
    };
    data.map(_data => _data.id = this.getTspanGroups(_data.id));
    const colors = [
      '#f44336',
      '#9c27b0',
      '#3f51b5',
      '#2196f3',
      '#009688',
      '#4caf50',
      '#cddc39',
      '#ff9800',
      '#ff5722',
      '#795548'
    ];
    const width = suppressStyle ? 900 : 1500;
    return (
        <ConditionalWrapper
            condition={!this.props.suppressStyle}
            wrapper={children => (
                <Card aria-label='Percent to Target Line Chart' className={classes.lineChartCard}>
                  <div className={classes.header}>
                    <Timeline className={classes.icon}/>
                    <Typography className={classes.chartTitle} variant='h5'>{title}</Typography>
                  </div>
                  <Divider />
                  {/*{this.props.subText}*/}
                  <CardContent id='percentToLine' className={classes.lineChart}>
                    {children}
                  </CardContent>
                </Card>
            )}>
          {
            this.props.data.length > 0 ?
            <>
              <canvas style={{ display: 'none', heigh:'420', width:'1500', overflow:'auto'}} height={420} width={width} id='percentToLineCanvas'/>
              {!this.props.suppressStyle && <Button align='right' variant='contained' onClick={(title) => {this.handleClick(title)}}>Download Chart</Button>}
              <Line
                data={data}
                layers={['grid', 'markers', 'axes', 'areas', 'crosshair', 'lines', 'points', 'slices', 'mesh', 'legends',Title]}
                margin={{
                  top: 30,
                  right: 400,
                  bottom: 60,
                  left: 60
                }}
                xScale={{
                  type: 'time',
                  // format: "%Y-%m-%dT%H:%M:%S.000Z",
                  format: "native",
                  precision: 'day'
                }}
                yScale={{
                  type: 'linear',
                  stacked: false,
                  min: 0,
                  max: 1
                }}
                height={330}
                width={1040}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickValues: 5,
                  orient: 'bottom',
                  tickSize: 10,
                  tickPadding: 5,
                  tickRotation: 0,
                  format: '%-m/%-d'
                }}
                axisLeft={{
                  tickValues: 2,
                  orient: 'left',
                  tickSize: 0,
                  tickPadding: 15,
                  tickRotation: 0,
                  format: percentage
                }}
                colors={colors}
                dotSize={10}
                dotColor='inherit:darker(0.3)'
                dotBorderWidth={1}
                dotBorderColor='inherit:darker(1)'
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                enableSlices="x"
                sliceTooltip={tooltip}
                legends={[
                  {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    data: data.map((item, index) =>
                    {
                      return {
                        color: colors[index],
                        id: item.id,
                        label: this.getTspanGroups(item.id)
                    }
                    }),
                    itemWidth: 1,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemBackground: 'rgba(0, 0, 0, .03)',
                          itemOpacity: 1
                        }
                      }
                    ]
                  }
                ]}
                theme={{
                  axis: {
                    ticks: {
                      text: { fontSize: 16, fontWeight: 800 }
                    }
                  }
                }}
              />
            </>
            : !loading && <Typography className={classes.empty} align='center' variant='h4'> <FormattedMessage id='Chart.empty'/> </Typography>
          }
        </ConditionalWrapper>
        )
  }
}


const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children;


export default withStyles(baseStyle)(PercentToTargetLine);