import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { searchQuestions } from '../../../../redux/actions';
import {
  Button,
  Dialog,
  DialogContent, DialogTitle,
  FormGroup,
  IconButton, Table, TableHead,
  Typography,
  withStyles, TableRow, TableCell, Paper, TableBody, TablePagination
} from '@material-ui/core';
import { CheckCircle, CloudDownload } from '@material-ui/icons';
import baseStyle from '../../../../styles/brailleSheetsImportQuestion';
import { Close } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
import { capitalize } from '../../../../helpers';

class BrailleSheetsImportQuestion extends Component {

  state = {
    answers: this.props.groups.map(group => ({ label: group.text, value: group.text })),
    imported: [],
    searched: false,
    rowsPerPage: 5,
    page: 0
  };

  search = () => {
    const query = this.state.answers.map(answer => answer.value);
    this.props.searchQuestions(query);
    setTimeout(() => this.setState({ searched: true }), 500)
  };

  close = () => {
    this.props.toggleImport();
  };

  import = (question) => {
    this.setState(state => ({ imported: [...state.imported, question.id ]}));
    this.props.importQuestion({question: question.question, answers: [question.answer.toLowerCase()]})
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { questions, groups, classes, open } = this.props;
    const { searched, page, rowsPerPage } = this.state;
    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        PaperProps={{ classes:{ root: classes.container }}}
        open={open}
      >
        <DialogTitle disableTypography={true} className={classes.title} id='form-dialog-title'>
          <Typography className={classes.titleText} variant='h4'> <FormattedMessage id='Wizard.BrailleQuiz.import'/> </Typography>
          <IconButton
            onClick={this.close}
            className={ classes.exitIcon }
            aria-label="close dialog"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <FormGroup classes={{ root: classes.root }}>
            <Typography gutterBottom variant='subtitle2'><FormattedMessage id='Wizard.BrailleQuiz.importText'/></Typography>
            <Select
              isMulti
              id='group-select'
              classes={{ root: classes.select }}
              name='answers'
              onChange={(ev) => this.setState({ answers: ev })}
              value={this.state.answers}
              options={groups.map(group => ({
                  label: capitalize(group.text),
                  value: group.text.toLowerCase()
              }))}
            />
          </FormGroup>
          <Paper className={classes.table}>
            {
              searched &&
                <>
              {
                questions.length > 0
                ? <>
                  <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell><FormattedMessage id='Wizard.BrailleQuiz.question'/></TableCell>
                      <TableCell><FormattedMessage id='Wizard.BrailleQuiz.answer'/></TableCell>
                      <TableCell><FormattedMessage id='Wizard.Braille.import'/>?</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      questions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(question => {
                        return (
                          <TableRow key={question.id}>
                            <TableCell> {question.question}</TableCell>
                            <TableCell> {capitalize(question.answer)}</TableCell>
                            <TableCell>
                              {
                                this.state.imported.indexOf(question.id) === -1
                                  ? <IconButton onClick={() => this.import(question)}>
                                    <CloudDownload/>
                                  </IconButton>
                                  : <CheckCircle/>
                              }
                            </TableCell>
                          </TableRow>
                        )
                      })
                    }
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5]}
                  component="div"
                  count={this.props.questions.length}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  backIconButtonProps={{
                    'aria-label': 'Previous Page',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Next Page',
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </>
                : <Typography className={classes.error} align='center' variant='h6'><FormattedMessage id='Wizard.BrailleQuiz.emptyQuestions'/></Typography>
              }
              </>
            }
          </Paper>
          </DialogContent>
        <Button onClick={this.search} variant='contained'><FormattedMessage id='General.search'/></Button>
      </Dialog>
    );
  }
}

const mapState = ({ platform: { questions }}) => {
  return {
    questions
  }
};

const mapDispatch = (dispatch) => {
  return {
    searchQuestions: (query) => dispatch(searchQuestions(query))
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(BrailleSheetsImportQuestion));