import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
import { Button, FormGroup, Typography, TextField, IconButton, Switch } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { checkStudentCount, createStudent, patchStudent } from '../../../../redux/actions';
import { toggleStudentLogin, clearErrors, changeStudentPassword } from '../../../../redux/actions';
import baseStyle from '../../../../styles/wizardBase';


const productOptions = [
  { value: 'Reading', label: 'Reading' },
  { value: 'OM', label: 'O&M' },
  { value: 'TVI', label: 'TVI' },
];
class StudentWizardPage1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      userName: '',
      password: '',
      confirmPassword: '',
      login: false,
      confirm: false,
      agree: false,
      ignoreWarning: false,
      checked: false,
      admin: false,
      products:[],
      studentStaff: [],
    }
  }

  componentDidMount() {    
    if (this.props.user && this.props.user.products) {
      let multiProdAvail = this.props.user.products.includes(',');    
      if (!multiProdAvail) {
        this.setState({products: [this.props.user.products]});
      }
    }
  }
  componentWillReceiveProps(nextProps, nextContext) {  
    if (nextProps.user !== this.props.user && nextProps.user) {
      let multiProdAvail = nextProps.user.products.includes(',');
      if (!multiProdAvail) {
        this.setState({products: [nextProps.user.products]});
      }
    }
  }
  close = () => {
    this.setState({
      firstName:'',
      lastName:'',
      userName:'',
      password:'',
      confirmPassword:'',
      login:false,
      error:false,
      passwordError:false,
      confirmPasswordError:false
    });
    this.props.close();
  };

  submit = () => {
    const { firstName, lastName, userName, password, ignoreWarning,products } = this.state;
    const student = { firstName, lastName, gamerTag:userName.trim(), products}; 
    this.props.createStudent(student, ignoreWarning)
      .then((res) => {
        if (this.state.login) {
          console.log('here');
          if(this.state.studentStaff.length > 0){
            const studentStaff = this.props.staff.filter(student => this.state.studentStaff.some(_student => _student.value === student.id));
            this.props.patchStudent(res, studentStaff)
          }
          this.props.toggleStudentLogin(res.id, ignoreWarning, password.trim() );
        }
        if (res && res.error) {
          this.setState({ error: res.error })
        }
        else {
          this.props.close();

          this.setState({ firstName: '', lastName: '', userName: '', password: '', confirmPassword: '', studentStaff:[] });
        }
      });
  };

  toggleLogin = () => {
    if ( this.state.login === false ){
      checkStudentCount()
        .then((warn) => {
          this.setState({...warn})
        });
    }
    this.setState(state => ({ login: !state.login }))
  };

  confirm = () => {
    this.setState({ agree: true, confirm: false, ignoreWarning: true })
  };

  closeConfirm = () => {
    this.setState({  agree: false, confirm: false, login: false, ignoreWarning: false  });
  };

  handleChange = (ev) => {
    let mappedProduct=ev.target.value && ev.target.value.map(obj => obj.value);
    this.setState({ products: mappedProduct || [] })
  };

  generateProduct(products){
    let obj=[];
    if(products)
    {
      for(let p of products) {
        var prod = productOptions.filter(o => (o.value == p));
        var label = prod.length == 1 ? prod[0].label : p;
        obj.push({"value": p, "label": label});
      }
    }
    return obj;
  }
  render() {
    let products=this.props.user.products && this.props.user.products.split(',');
    let obj=this.generateProduct(products);
    const { admin } = this.state;
    const { classes, staff } = this.props;
    // const error = !!this.state.confirmPassword && (this.state.password !== this.state.confirmPassword);
    // const needUserName = !this.state.userName && this.state.login;
    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        open={this.props.open}
        onClose={this.close}
        aria-labelledby="form-dialog-title"
        PaperProps={{ classes: { root: classes.container } }}
      >
        <DialogTitle disableTypography={true} className={classes.title} id="form-dialog-title">
          <Typography className={classes.titleText} variant='h4'><FormattedMessage id='Wizard.Student.title'/></Typography>
          <IconButton
            onClick={this.close}
            className={classes.exitIcon}
            aria-label="close dialog"
          >
            <Close/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage id='Wizard.Student'/>
          </DialogContentText>
          <FormGroup classes={{ root: classes.fullName }}>
            <TextField
              id='student-first-name'
              label={<FormattedMessage id='General.firstName'/>}
              type='text'
              name='firstName'
              variant='outlined'
              value={this.state.firstName}
              onChange={(ev) => this.setState({ firstName: ev.target.value })}
            />
            <TextField
              id='student-last-name'
              label={<FormattedMessage id='General.lastName'/>}
              type='text'
              name='lastName'
              variant='outlined'
              value={this.state.lastName}
              onChange={(ev) => this.setState({ lastName: ev.target.value })}
            />
          </FormGroup>

          {products&& products.length>1?
           <FormGroup >
           <Typography gutterBottom variant='h6'> <FormattedMessage id='Wizard.Staff.selectProduct'/>:</Typography>  
           <Select
              styles={{
                control: (styles) => ({...styles, minHeight: 56}),
                multiValueLabel: (styles) => ({
                  ...styles,
                  display: 'grid',
                  fontFamily: 'Roboto',
                  alignContent: 'center',
                  fontSize: '14px',
                  minHeight: 40,
                  fontWeight: 500
                }),
              }}
              isMulti
              defaultValue={products && products.length==1 && products[0].key}
              menuPlacement='top'
              maxMenuHeight={180}
              placeholder='Product'
              options={obj && obj.map(p => ({ value: p.value, label: p.label }))}
              onChange={(ev) => this.handleChange({ target: { name: 'products', value: ev }})}
            /></FormGroup>:null}

            <div className={classes.login}>
              <Typography variant='h6' className='text'> <FormattedMessage id="Profile.studentLogin"/> </Typography>
              <Switch className='toggle' checked={this.state.login} onChange={this.toggleLogin}/>
            </div>
            {
              this.state.login &&
              <FormGroup>
                <TextField
                    classes={{ root: classes.firstName }}
                    id='student-userName'
                    label='User Name'
                    type='text'
                    name='UserName'
                    variant='outlined'
                    error={!!this.state.error}
                    helperText={ this.state.error ? this.state.error : '' }
                    value={this.state.userName}
                    onChange={(ev) => this.setState({ userName: ev.target.value, error: !ev.target.value ? "Username cannot be empty" :  '' })}
                />
              <div> {/* additional div container to avoid styling bugs on safari */}
              <div className={classes.fullName}>
                <TextField
                  variant='outlined'
                  type='password'
                  label={<FormattedMessage id='Login.password'/>}
                  name='password'
                  error={this.state.passwordError}
                  helperText={this.state.passwordError ? "Password cannot be empty" : ''}
                  value={this.state.password}
                  onChange={(ev) => this.setState({ password: ev.target.value, passwordError: !ev.target.value, confirmPasswordError: (ev.target.value !== this.state.confirmPassword) })}
                />
                <TextField
                  variant='outlined'
                  type='password'
                  label={<FormattedMessage id='Login.confirmPassword'/>}
                  name='confirmPassword'
                  error={this.state.confirmPasswordError}
                  helperText={this.state.confirmPasswordError ? <FormattedMessage id='Login.mustMatch'/> : ''}
                  value={this.state.confirmPassword}
                  onChange={(ev) => this.setState({ confirmPassword: ev.target.value,confirmPasswordError: ev.target.value !== this.state.password })}
                />
              </div>
              </div>
              <div>
              <Typography gutterBottom variant='subtitle2'>
            <FormattedMessage id="Wizard.Student.Staff"/>
          </Typography>
          <Select
              isMulti
              id='student-select'
              maxMenuHeight={180}
              styles={{
                control: (styles) => ({...styles, minHeight: 56}),
                multiValueLabel: (styles) => ({
                  ...styles,
                  display: 'grid',
                  fontFamily: 'Roboto',
                  alignContent: 'center',
                  fontSize: '14px',
                  minHeight: 40,
                  fontWeight: 500
                }),
              }}
              menuPlacement='top'
              onChange={(ev) =>{this.setState({ studentStaff: ev })}}
              value={this.state.studentStaff}
              options={staff.map(student => ({label: `${student.firstName} ${student.lastName}`, value: student.id }))}
          />
              </div>
              </FormGroup>
           }
        </DialogContent>
        <DialogActions className={classes.buttons}>
          <Button onClick={this.close} color="secondary">
            <FormattedMessage id='General.cancel'/>
          </Button>
          <Button disabled={
            (this.state.login
            ? (!this.state.userName || !this.state.password || !this.state.confirmPassword || (this.state.password !== this.state.confirmPassword))
            : this.state.confirm && !this.state.agree && !this.state.userName) 
            || (this.state.products.length<1 ?true: false)
          } onClick={this.submit} color="primary">
            <FormattedMessage id='General.submit'/>
          </Button>
        </DialogActions>
        <Dialog
          open={this.state.confirm}
          onClose={this.closeConfirm}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Confirm Addition to Account</DialogTitle>
          {
            admin
              ? <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Adding another student to this account will exceed your original billed amount of {this.props.account.studentCount} and you may be billed accordingly.
                    Do you agree to these terms?
                  </DialogContentText>
                  <div className={classes.confirmButtons}>
                    <Button onClick={this.confirm} color='primary' variant='contained' autoFocus>
                      Yes, increase my billable student count!
                    </Button>
                    <Button onClick={this.closeConfirm} color='secondary'>
                      No, I will continue without login.
                    </Button>
                  </div>
                </DialogContent>
              : <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Your account is over the allotted {this.props.account.studentCount} students. Please contact your administrator to add more students.
                  </DialogContentText>
                  <div className={classes.confirmButtons}>
                    <Button onClick={this.closeConfirm} variant='contained' color='secondary'>
                      Continue without login.
                    </Button>
                  </div>
                </DialogContent>
          }
        </Dialog>
      </Dialog>
    )
  }
}

const mapState = ({platform: {staff}, shared: { error }}) => {
  return {
    error,
    staff,
  }
};

const mapDispatch = (dispatch) => {
  return {
    createStudent: (student, ignoreWarning) => dispatch(createStudent(student, ignoreWarning)),
    toggleStudentLogin: (id, ignoreWarning, password) => dispatch(toggleStudentLogin(id, ignoreWarning, password)),
    changeStudentPassword: (id, password) => dispatch(changeStudentPassword(id, password)),
    patchStudent: (student, staff) => dispatch(patchStudent(student, staff)),
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(StudentWizardPage1));