import { ROOT_URL, FETCH_ACCOUNT, FETCH_ACCOUNTS, FETCH_ALL_ROLES, CREATE_ACCOUNT, UPDATE_ACCOUNT, FETCH_BILLING, UPDATE_BILLING, HANDLE_ERROR, FETCH_BUDDYBOOKS_SUBSCRIPTION, UPDATE_LICENSE_AMOUNT, SUSPEND_SUBSCRIPTION, CHECK_FOR_SUSPENSION } from '../constants';
import axios from 'axios';

export const getAccount = (history) => {
  const url = `${ROOT_URL}/admin`;
  return (dispatch) => {
    axios
      .get(url, { withCredentials: true })
      .then((response) => {
        if (typeof (response.data) === 'object') {
          dispatch({
            type: FETCH_ACCOUNT,
            account: response.data
          })
        }
        else {
          history.push('/login');
          dispatch({
            type: HANDLE_ERROR,
            error: { type: 'sessionExp', msg: 'Your session has expired, please log back in' }
          })
        }
        return response.data
      })
  }
};

export const fetchRoles = (accountId) => {
  const url = `${ROOT_URL}/roles?accountId=${accountId}`;
  return (dispatch) => {
    axios
      .get(url, { withCredentials: true })
      .then((response) => {
        dispatch({
          type: FETCH_ALL_ROLES,
          roles: response.data
        })
      })
      .catch(err => console.error(err))
  }
};

export const fetchAccounts = (history) => {
  const url = `${ROOT_URL}/support/accounts`;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        if (typeof (response.data) === 'object') {
          dispatch({
            type: FETCH_ACCOUNTS,
            accounts: response.data
          })
        }
        else {
          history.push('/login');
          dispatch({
            type: HANDLE_ERROR,
            error: { type: 'sessionExp', msg: 'Your session has expired, please log back in' }
          })
        }
        return response.data
      })
  }
};

export const fetchSpecialistAccounts = (specialistId) => {
  const url = `${ROOT_URL}/specialists/${specialistId}/accounts`;
  return (dispatch) => {
    return axios
      .get(url)
      .then((response) => {
        dispatch({
          type: FETCH_ACCOUNTS,
          accounts: response.data
        });
        return response.data
      })
      .catch(err => console.log(err))
  }
};

export const createAccount = (account, terms) => {
  const url = `${ROOT_URL}/admin/create-account`;
  return (dispatch) => {
    axios
      .post(url, account)
      .then((response) => {
        if (terms) {
          uploadTerms(terms, response.data.accountDto.id)
        }
        dispatch({
          type: CREATE_ACCOUNT,
          account: response.data
        })
      })
  }
};

export const updateAccount = (account) => {
  const url = `${ROOT_URL}/admin/${account.id}`;
  return (dispatch) => {
    axios
      .patch(url, account)
      .then((response) => {
        dispatch({
          type: UPDATE_ACCOUNT,
          account: response.data
        })
      })
  }
};

export const activateAccount = (account) => {
  const url = `${ROOT_URL}/admin/${account.id}/activate`;
  return (dispatch) => {
    axios
      .patch(url)
      .then(() => {
        dispatch({
          type: UPDATE_ACCOUNT,
          account: { ...account, status: 'ACTIVE' }
        })
      })
  }
};

export const suspendAccount = (account) => {
  const url = `${ROOT_URL}/admin/${account.id}/suspend`;
  return (dispatch) => {
    axios
      .patch(url)
      .then(() => {
        dispatch({
          type: UPDATE_ACCOUNT,
          account: { ...account, status: 'INACTIVE' }
        })
      })
  }
};

export const checkStudentCount = () => {
  const url = `${ROOT_URL}/admin/check-student-count`;
  return axios
    .get(url)
    .then(() => {
      return { confirm: false, checked: true }
    })
    .catch(({ response }) => {
      if (response.status === 403) {
        return { confirm: true, checked: true, admin: false }
      }
      else {
        return { confirm: true, checked: true, admin: true }
      }
    })
};

export const uploadTerms = (file, id) => {
  const url = `${ROOT_URL}/admin/${id}/terms`;
  const data = new FormData();
  data.append("file", file, `tou_${id}.pdf`);
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  axios
    .post(url, data, config)
    .catch(err => console.log(err))
};

export const fetchBillingData = (accountId) => {
  const url = `${ROOT_URL}/admin/${accountId}/billing-data`;
  return (dispatch) => {
    axios
      .get(url)
      .then(response => {
        dispatch({
          type: FETCH_BILLING,
          billing: response.data
        })
      })
      .catch(err => {
        dispatch({
          type: FETCH_BILLING,
          billing: {}
        })
      })
  }
};

export const updateBillingData = (accountId, billing) => {
  const url = `${ROOT_URL}/admin/${accountId}/billing-data`;
  return (dispatch) => {
    return axios
      .patch(url, billing)
      .then((response) => {
        dispatch({
          type: UPDATE_BILLING,
          billing
        });
        return response.data;
      })
      .catch(err => console.log(err))
  }
};

export const downloadTerms = (id) => {
  const url = `${ROOT_URL}/admin/${id}/terms`;
  return axios
    .get(url, { responseType: 'blob' })
};
export const fetchBuddyBookSubscription = () => {
  const url = `${ROOT_URL}/purchase/user`;
  return (dispatch) => {
    axios
      .get(url, { withCredentials: true })
      .then((response) => {
        dispatch({
          type: FETCH_BUDDYBOOKS_SUBSCRIPTION,
          data: response.data
        })
      })
      .catch(err => console.error(err))
  }
};

export const updateLicenseAmount = (licenseAmount, deletionArr) => {
  const url = `${ROOT_URL}/purchase/update/${licenseAmount}`;
  return (dispatch) => {
    axios
      .post(url, deletionArr)
      .then((response) => {
        dispatch({
          type: UPDATE_LICENSE_AMOUNT,
          data: response.data
        })
      }).catch(err => console.error(err))
  }
}
export const cancelSubscription = (cancel, reason) => {
  const url = `${ROOT_URL}/purchase/suspend?cancel=${cancel}`;
  return (dispatch) => {
    axios
      .post(url, reason)
      .then((response) => {
        if(response.status === 200){
          dispatch({
            type: SUSPEND_SUBSCRIPTION,
            data: `${cancel ? 'Canceled' : 'Paused'} subscription successfully !`
          })
        }
        else{
          dispatch({
            type: SUSPEND_SUBSCRIPTION,
            data: `${cancel ? 'Cancel' : 'Pause'} subscription failed !`
          })
        }
        
      }).catch(err => {
        dispatch({
          type: SUSPEND_SUBSCRIPTION,
          data: `${cancel ? 'Cancel' : 'Pause'} subscription failed !`
        })
        console.error(err)})
  }
}

export const checkForSuspension = () => {
  const url = `${ROOT_URL}/purchase/suspend/check`;
  return (dispatch) => {
    axios
      .get(url, { withCredentials: true })
      .then((response) => {
        dispatch({
          type: CHECK_FOR_SUSPENSION,
          data: response.data
        })
      })
      .catch(err => console.error(err))
  }
};