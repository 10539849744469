import React, { Component } from 'react';
import moment from 'moment';
import axios from 'axios';
import TextPost from '../components/TextPost';
import GoalPost from '../components/GoalPost';
import { ROOT_URL } from '../../redux/constants';
import { List, withStyles, Button, TextField, InputAdornment, IconButton, Icon, Card } from '@material-ui/core';
import baseStyle from '../../styles/feed';

class Feed extends Component {

  state = {
    page: 1,
    posts: this.props.posts
  };

  renderPosts = (post) => {
    if (post.goalId) {
      return <GoalPost
        feed
        isAdmin={this.props.isAdmin}
        key={post.key}
        goal={post}
        profile={this.props.profile}
      />
    }
    if (post.brailleSheetId){
      return <GoalPost
        feed
        sheet
        isAdmin={this.props.isAdmin}
        key={post.key}
        goal={post}
        profile={this.props.profile}
      />
    }
    else {
      return <TextPost
        feed
        isAdmin={this.props.isAdmin}
        key={post.key}
        post={post}
        profile={this.props.profile}
      />
    }
  };

  loadMore = () => {
    const { page } = this.state;
    const url = `${ROOT_URL}/profiles/my-feed?offset=${page*10}`;
    axios
      .get(url)
      .then(response => {
        this.setState((state) => ({ page: state.page+1, posts:  [...state.posts.slice(0, page*10), ...response.data.slice(page*10)] }))
      })
  };

  handleSearch = (searchValue) => {
    const { posts } = this.props;
    const filtered = posts.filter(item => {
      const author = item.author && item.author.name;
      const name = item.name;
      const text = item.text;
      const group = item.group && item.group.name;
      return [author, name, text, group].some(key => {
        if (key) {
          return key.toLowerCase().includes(searchValue.toLowerCase())
        }
        else {
          return false
        }
      })
    });

    // if search is emptied, reset to all posts
    if (searchValue === '') {
      this.setState({ posts: this.props.posts });
    }
    else {
      this.setState({ posts: filtered });
    }

  };

  handleChange = (ev) => {
    this.setState({ [ev.target.name]: ev.target.value })
  };

  render() {
    const { classes, pinned } = this.props;
    const { page, posts } = this.state;
    const paginatedPosts = posts.slice(0,10*page);
    const sorted = paginatedPosts.sort((a, b) => moment(new Date(b.date)).isAfter(moment(new Date(a.date))) ? 1 : -1);
    return (
      <div className={classes.container}>
        <Card className={classes.searchContainer}>
          <TextField
            className='searchBar'
            color='inherit'
            variant='outlined'
            onChange={(ev) => this.handleSearch(ev.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton>
                    <Icon className='fas fa-search button' />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Card>
        <List style={{ padding: '0'}}>
          {
            sorted.map(post =>
              !pinned &&
              this.renderPosts(post)
            )
          }
        </List>
        <div className={classes.footer}>
        { posts.length > paginatedPosts.length && <Button onClick={this.loadMore}>See More Posts</Button> }
        </div>
      </div>
    );
  }
}

export default withStyles(baseStyle)(Feed);