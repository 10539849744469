export default {
  drawer: {
    backgroundColor: '#383871',
    width: '280px',
    marginLeft: '70px'
  },
  logo: {
    width: '160px'
  },
  role: {
    color: '#00BBEB',
  },
  listItem: {
    color: '#f9f3ff',
    fontSize: '18px',
    textDecoration: 'none',
  },
  link: {
    textDecoration: 'none',
    marginBottom: '5px',
    width: '90%',
    '&:hover': {
      backgroundColor:'#0087B6'
    },
    textAlign: 'left',
  },
  linkButton: {
    padding: 0
  },
  active: {
    backgroundColor: '#0087B6',
    width: '90%',
    '&:hover': {
      backgroundColor:'#00BBEB'
    },
    marginBottom: '5px'
  },
  goalButton: {
    marginTop: '80px',
    margin: '20px auto',
  },
  formButton: {
    borderColor: '#f9f3ff',
    color: '#f9f3ff',
    margin: '20px auto'
  },
  gutter: {
    margin: 0
  },
  divider: {
    margin: '20px 0',
    width: '90%',
    backgroundColor: '#f9f3ff',
  }
}