import {GET_ANALYSIS, GET_STORY, HANDLE_ERROR, ROOT_URL, GAME_URL} from '../../constants';
import moment from 'moment';
import axios from 'axios';


export const runAnalysis = (speechId, studentId, blob) => {
    var url = `${GAME_URL}/speech/${studentId}/analysis/${speechId}`;
    const data = new FormData();
    data.append("file", blob, "filename.wav");
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
            'access-token': '0207cb2d-2ee5-4645-98cc-821d57ed26b7'
        }
    };
    return (dispatch) => {
        axios
            .post(url, data, config)
            .then((response) => {
                dispatch({
                    type: GET_ANALYSIS,
                    speechData: response.data
                });
            })
            .catch(err => console.log(err))
    }
};

export const getStory = (storyId) => {
    var url = `${GAME_URL}/buddy-books/story/${storyId}`;
    const config = {
        headers: {
            'access-token': '0207cb2d-2ee5-4645-98cc-821d57ed26b7'
        }
    };
    return (dispatch) => {
        axios
            .get(url,config)
            .then(response => {
                dispatch({
                    type: GET_STORY,
                    story: response.data
                })
            })
            .catch(err => console.log(err))
    }
};