import React, { Component } from 'react';
import { connect } from 'react-redux';
import Carousel from 'nuka-carousel';
import LineChart from '../../components/Charts/LineChart';
import ListChart from '../../components/Charts/ListChart';
import BarChart from '../../components/Charts/BarChart';
import { Card, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { getStudent } from '../../../redux/actions';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../styles/dashboard';

class SkillDashboard extends Component {

  state = {
    charts: [],
    loaded: false
  };

  componentDidMount() {
    this.props.getStudent(this.props.id);
    if (this.props.charts) {
      this.renderCharts(this.props.charts)
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.charts.length !== nextProps.charts.length){
      this.renderCharts(nextProps.charts)
    }
  }

  renderCharts = (data) => {
    const charts = data.map((chart, index) => {
      switch (chart.chartType) {
        case 'Line':
          return <LineChart key={index} chart={chart} title={chart.title}/>;
        case 'Bar':
          return <BarChart key={index} chart={chart} title={chart.title}/>;
        case 'List':
          return <ListChart key={index} chart={chart} title={chart.title}/>;
        default:
          return null;
      }
    });
    this.setState({ charts, loaded: true })
  };

  renderChartsTest = (data) => {
    const charts = data.map((chart, index) => {
      switch (chart.chartType) {
        case 'Line':
          return <LineChart key={index} chart={chart} title={chart.title}/>;
        case 'Bar':
          return <BarChart key={index} chart={chart} title={chart.title}/>;
        case 'List':
          return <ListChart key={index} chart={chart} title={chart.title}/>;
        default:
          return null;
      }
    });
    return charts;
  }

  render(){
    const { classes, charts } = this.props;
    var int_charts = this.renderChartsTest(charts);
    return (
      this.state.charts.length
      ? <Carousel
          autoplay={charts.length > 1}
          pauseOnHover
          wrapAround
          cellAlign='center'
          autoplayInterval={5000}
          className={classes.carousel}
        >
          { int_charts }
        </Carousel>
      :
        this.state.loaded
        ? <Card className={classes.placeholder}>
          <Typography variant='h6'> There is not enough data yet, please play more games in this skill...</Typography>
        </Card>
        : <Skeleton />
    )
  }
}

const mapState = ({ platform: { skills, student }}) => {
  return {
    skills,
    student
  }
};

const mapDispatch = (dispatch) => {
  return {
    getStudent: (id) => dispatch(getStudent(id)),
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(SkillDashboard));