import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  FormGroup,
  Typography,
  IconButton,
  TextField,
  Switch,
  FormControlLabel,
  SnackbarContent,
  MenuItem
} from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { clearErrors, handleError } from '../../../../redux/actions';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../../styles/wizardBase';
import BrailleSheetWizardGrid from './BrailleSheetWizardGrid';
import { ROOT_URL } from '../../../../redux/constants';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import { getStaffById, getStaffProfile } from '../../../../redux/actions';

class BrailleSheetsWizardPage1 extends Component {

  state = {
    contract: false,
    text: '',
    sheet: {
      brfConversion: '',
      contract: false,
      contractionSet: [],
      dotsConversion: '',
      grid: '{"cells":[{"row":[]}],"width":24,"groups":[],"height":11}',
      height: 11,
      text: '',
      unicodeConversion: '',
      width: 24,
    },
    useUpperCase: false,
    useNumbers: true,
    sheetSize: 'iPad Mini (7.9")',
    width: 24,
    height: 11,
    numberSystem: 'Literary',
    error: {}
  };

  close = () => {
    this.setState({ contract: false });
    this.props.close();
  };

  next = () => {
    const { sheet } = this.state;
    if (this.state.contract) {
      this.props.checkContractions(sheet);
    }
    else {
      this.props.convertBraille(sheet);
    }
    this.props.clearErrors();
  };

  handleClose = () => {
    this.props.clearErrors();
  };

  braille = (_text, options) => {
    const text = _text.target ? _text.target.value : _text;
    this.setState({ text });
    const contract = options && options.hasOwnProperty('contract') ? options.contract : this.state.contract;
    const numberSystem = options && options.numberSystem ? options.numberSystem : this.state.numberSystem;
    const useUpperCase = options && options.hasOwnProperty('useUpperCase') ? options.useUpperCase : this.state.useUpperCase;
    const width = options && options.width ? options.width : this.state.width;
    const height = options && options.height ? options.height : this.state.height;
    const { useNumbers } = this.state;
    const sheet = {contract, text, useNumbers, useUpperCase, width, height, numberSystem};
    if (contract) {
      const url = `${ROOT_URL}/braille-conversion/check-contractions`;
      axios.post(url, sheet)
        .then((response) => {
          const url = `${ROOT_URL}/braille-conversion/convert`;
          axios.post(url, {...sheet, contractionSet: response.data.contractionSet})
            .then((response) => {
              this.setState({ sheet: response.data })
              this.props.clearErrors();
            })
              .catch(err => this.props.handleError(err))
        })
          .catch(err => this.props.handleError(err))
    }
    else {
      const url = `${ROOT_URL}/braille-conversion/convert`;
      axios.post(url, sheet)
        .then((response) => {
          this.setState({ contract: false, sheet: response.data })
          this.props.clearErrors();
        })
          .catch(err => this.props.handleError(err))
    }
  };

  changeSheetSize = ({ target: { value }}) => {
    let width, height;
    switch(value) {
      case 'Amazon Fire HD (7)':
        width = 24;
        height = 9;
        break;
      case 'iPad Mini (7.9")':
        width = 24;
        height = 11;
        break;
      case 'iPad Air (9.7")':
        width = 30;
        height = 13;
        break;
      case 'iPad Pro (12.9")':
        width = 40;
        height = 18;
        break;
      default:
        width = 24;
        height = 11;
    }
    this.props.changeSheetSize(value);
    this.braille(this.state.text, { width, height });
    this.setState({ sheetSize: value, width, height })
  };

  handleChange = ({ target: { value, name }}) => {
    this.setState({ [name]: value });
    this.braille(this.state.text, { [name]: value })
  };

  handleCheck = ({ target: {name}}) => {
    this.setState({[name]: !this.state[name]});
    this.braille(this.state.text, {[name]: !this.state[name]});
  };

  componentDidMount() {
    this.props.getStaffById(this.props.user.staffId);
    this.props.getStaffProfile(this.props.user.staffId);
  }


  render()  {
    const { classes, error } = this.props;
    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        maxWidth='xl'
        open={this.props.open}
        onClose={this.props.close}
        aria-labelledby='form-dialog-title'
        scroll='paper'
      >
        <DialogTitle disableTypography={true} className={classes.title} id='form-dialog-title'>
          <Typography className={classes.titleText} variant='h4'> <FormattedMessage id='Databanks.brailleSheets'/> </Typography>
          <IconButton
            onClick={this.close}
            className={classes.exitIcon}
            aria-label='close dialog'
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage id='Wizard.Braille'/>
          </DialogContentText>
          <br />
          <Typography variant='h6'> <FormattedMessage id='Wizard.Braille.text'/>: </Typography>
          <FormGroup>
            <TextField
              multiline
              rows={3}
              fullWidth
              classes={{root: classes.firstName}}
              variant='outlined'
              id='braille-sheets-text'
              label='Text'
              type='text'
              name='text'
              onChange={(ev) => this.braille(ev)}
            />
            {  error.data &&
            <SnackbarContent
              open={true}
              onClose={this.handleClose}
              classes={{ root: classes.error }}
              message={<span id='message-id'><FormattedMessage id='Wizard.Braille.error'/></span>}
              action={[
                <IconButton
                  key='close'
                  aria-label='Close'
                  color='inherit'
                  className={classes.close}
                  onClick={this.handleClose}
                >
                  <Close />
                </IconButton>,
              ]}
            />
            }
            {this.props.staffProfile.prototype === "Yes" &&
            <FormGroup>
              <Typography variant='subtitle1'> Device Size: </Typography>
              <TextField
                  select
                  variant='outlined'
                  onChange={this.changeSheetSize}
                  value={this.state.sheetSize}
              >
                <MenuItem value='iPad Mini (7.9")'>iPad Mini (7.9")</MenuItem>
                <MenuItem value='iPad Air (9.7")'>iPad Air (9.7")</MenuItem>
                <MenuItem value='iPad Pro (12.9")'>iPad Pro (12.9")</MenuItem>
              </TextField>
            </FormGroup>
            }
            <FormGroup>
              <Typography variant='subtitle1'> {this.props.staffProfile.prototype} Number System to Use: </Typography>
              <TextField
                select
                name='numberSystem'
                variant='outlined'
                onChange={this.handleChange}
                value={this.state.numberSystem}
              >
                <MenuItem value='Literary'>Literary</MenuItem>
                <MenuItem value='Nimith'>Nemeth</MenuItem>
              </TextField>
            </FormGroup>
            <div className={classes.options}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.contract}
                    name='contract'
                    onChange={this.handleCheck}
                    value={this.state.contract}
                    color='primary'
                  />
                }
                label={<FormattedMessage id='Wizard.Braille.contract'/>}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.useUpperCase}
                    name='useUpperCase'
                    onChange={this.handleCheck}
                    value={this.state.useUpperCase}
                    color='primary'
                  />
                }
                label={<FormattedMessage id='Wizard.Braille.upperCase'/>}
              />
            </div>
          </FormGroup>
          <BrailleSheetWizardGrid grid={JSON.parse(this.state.sheet.grid)}/>
        </DialogContent>
        <DialogActions className={classes.buttons}>
          <Button onClick={this.close} color='secondary'>
            <FormattedMessage id='General.cancel'/>
          </Button>
          <Button onClick={this.next} disabled={error.data} color='primary'>
            <FormattedMessage id='General.next'/>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapState = ({platform: {staffMember, staffProfile},  shared: { error, user }}) => {
  return {
    error,
    user,
    staffMember,
    staffProfile
  }
};

const mapDispatch = (dispatch) => {
  return {
    getStaffById: (id) => dispatch(getStaffById(id)),
    clearErrors: () => dispatch(clearErrors()),
    handleError: (err) => dispatch(handleError(err)),
    getStaffProfile: (id) => dispatch(getStaffProfile(id))
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(BrailleSheetsWizardPage1));