import React, { Component } from 'react';
import {
  Dialog,
  IconButton,
  Paper,
  Toolbar,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Button,
  withStyles,
  CircularProgress
} from '@material-ui/core';
import uuid from "uuid";
import { connect } from 'react-redux';
import styles from '../../styles/importSkill.js';
import { CheckCircle, Close, CloudDownload } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
// import { getSorting, stableSort } from '../../helpers';
// import IconCross from './../Icons/IconCross';
import './Content.css';
import { stableSort, getSorting } from '../../../src/helpers';
import LoadingModal from '../../Platform/components/Student/LoadingModal';
import ellipsis from '../../assets/Loading/Ellipsis.svg';
import BookShareCredentialsLogin from '../../Platform/components/Student/BookShareCredentialsLogin';
import { addStoryToStudent } from '../../redux/actions';
import DetailsWizard from './DetailsWizard.js';
import ImportBooks from '../../Platform/components/Shared/ImportBooks.jsx';

const rows = [
  { id: 'name', label: <FormattedMessage id='General.title' /> },
  { id: 'author', label: <FormattedMessage id='General.author' /> },
  { id: 'isbn', label: <FormattedMessage id='General.isbn' /> },
  { id: 'source', label: <FormattedMessage id='General.source' /> }
];

class Content extends Component {

  state = {
    order: 'asc',
    orderBy: 'difficulty',
    selected: {},
    open: false,
    page: 0,
    rowsPerPage: 5,
    filteredData: [],
    searchValue: '',
    searchCategory: 'name',
    imported: [],
    loadingImportBook: false,
    importBookId: [],
    openModal: false,
    modalType: '',
    loginRequired: false,
    storyDetails: null,
    isStoryImported: false,
    selectedBook: null,
    allReadyImportedBooks: [],
    importKey: '',
    showLoading: false

  };

  componentDidMount() {
    this.setState({ open: true });
    const {books, movie} = this.props;
    if (books.length > 0 && movie.items && movie.items.length > 0) {
      const { items } = movie;
      items.forEach(item => {
        if (books.some(book => book.name === item.name)) {
          this.setState({ imported: [...this.state.imported, item.id]})
        }
      })
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.movie !== this.props.movie && nextProps.movie) {
      this.setState({ open: true });
    }

    if (nextProps.books !== this.props.books && this.props.bookShareList.length > 0) {
      console.log('bookId, books, booklist ', this.state.importBookId, nextProps.books, nextProps.bookList);
      let newArr = this.state.importBookId.filter(item => {
        let bool = true;
        nextProps.books.forEach(({ name }) => {
          if (item === name) {
            bool = false;
          }
        });
        return bool;
      });
      console.log('newArr ', newArr);
      this.setState({ loadingImportBook: false, importBookId: newArr });
    }

    if (nextProps.user.hasBookShareCredentials && this.state.loginRequired) {
      this.setState({ loginRequired: false });
    }

    if (nextProps.bookShareList !== this.props.bookShareList && !nextProps.bookShareList.length) {
      this.setState({ noResultFound: true });
    }
  }

  handleChildUnmount = () => {
    this.setState({ 
      selectedBook: null,
      showLoading: false
    });
  }
  // importSkill = (book, e) => {
  //   e.stopPropagation();
  //   if ((this.props.user.hasBookShareCredentials && book.source === "Bookshare") || book.source === "ObjectiveEd") {
  //     this.props.dispatch(addStoryToStudent(this.props.storyId, book.id));
  //     if (!this.state.imported.includes(book.id)) {
  //       this.setState({ importBookId: [...this.state.importBookId, book.name] });
  //       this.setState(state => ({ imported: [...state.imported, book.id] }));
  //     }
  //     console.log('addImport ', book);
  //   } else {
  //     this.setState({ loginRequired: true });
  //   }
  // };
  handleSelectedBook = (e,currentBook) => {
    e.stopPropagation();
    this.setState({
      selectedBook: currentBook,
      importKey: uuid.v4(),
      showLoading: true
    });
    
  }
  handleImportedBook = (currentBook) => {
    if (!this.state.allReadyImportedBooks.includes(currentBook.id)) {
      this.setState({
        allReadyImportedBooks: [...this.state.allReadyImportedBooks, currentBook.name]
      })
    }

  }
  showDetailsWizard = (data) => {
    let isImported = this.state.imported.includes(data.id);
    this.setState({ storyDetails: data, isStoryImported: isImported, showDetailsWizard: true });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  closeWizard = () => {
    this.setState({ open: false });
    this.props.onClose();
  }

  closeDetailsWizard = () => {
    this.setState({ storyDetails: '', isStoryImported: false, showDetailsWizard: false });
  }
  closeLoading = () => {
    this.setState({ showLoading: false });
}

  render() {
    const { classes, movie, user, seriesTitle, booksImport, storyId, books } = this.props;
    const { orderBy, order, open, page, rowsPerPage } = this.state;
    const data = movie && movie.items || [];
    console.log('data, user', data, user);
    return (
      <>
        <Dialog open={open} maxWidth='lg' onClose={this.closeWizard}>
          <Paper className='list'>
            <Toolbar className='toolbar'>
              <Typography noWrap className='header'>
                {seriesTitle}: {movie.name}
              </Typography>
              <IconButton onClick={this.closeWizard} className='close'>
                <Close />
              </IconButton>
            </Toolbar>
            <Typography component='div' style={{ minHeight: 480 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {rows.length &&
                      rows.map(row => {
                        return (
                          <TableCell
                            key={row.id}
                            sortDirection={orderBy === row.id ? order : false}
                            colSpan={row.id === 'name' ? 2 : null}
                          >
                            <Tooltip
                              title={<FormattedMessage id='General.sort' />}
                              placement='bottom-start'
                              enterDelay={300}
                            >
                              <TableSortLabel
                                active={orderBy === row.id}
                                direction={order}
                                onClick={(ev) => this.handleSort(ev, row.id)}
                              >
                                {row.label}
                              </TableSortLabel>
                            </Tooltip>
                          </TableCell>
                        )
                      })
                    }
                    <TableCell key='status'>
                      <FormattedMessage id='Wizard.Braille.import' />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.length > 0 ?
                    stableSort(data, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map(_data => {
                        return (
                          <TableRow
                            hover
                            key={_data.id}
                            onClick={() => this.showDetailsWizard(_data)}
                          >
                            <TableCell>
                              {
                                _data.titleImageSrc && _data.titleImageSrc !== ' '
                                  ? <img src={_data.titleImageSrc} width={100} height={150} alt={_data.name} />
                                  : 'N/A'
                              }
                            </TableCell>
                            {rows.length &&
                              rows.map((row, index) => {
                                return (
                                  <>

                                    <TableCell
                                      key={index}
                                    >
                                      {
                                        _data[row.id] && _data[row.id] !== ' '
                                          ? _data[row.id]
                                          : 'N/A'
                                      }
                                    </TableCell>
                                  </>
                                )
                              })
                            }
                            <TableCell>
                              <IconButton>
                                {
                                  books.some(book => book.name === _data.name) || (booksImport && booksImport.bookList && booksImport.bookList.includes(_data.name))
                                    ? <CheckCircle />
                                    :
                                    this.state.showLoading && (_data && this.state.selectedBook == _data) ?
                                      <CircularProgress size={30} /> : <CloudDownload
                                        onClick={(e) => this.handleSelectedBook(e, _data)}
                                      />
                                }
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    :
                    <TableRow>
                      <TableCell />
                      <TableCell colSpan={5} align={"center"}>
                        <h4>No Results Found</h4>
                      </TableCell>
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  }
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component='div'
                count={data.length}
                labelRowsPerPage={<FormattedMessage id='Datatable.rowsPerPage' />}
                rowsPerPage={this.state.rowsPerPage}
                page={page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
              />
            </Typography>
          </Paper>
        </Dialog>
        {
          this.state.selectedBook && <ImportBooks storyId={storyId} story={this.state.selectedBook}
            handleImportedBook={this.handleImportedBook}
            key={this.state.importKey}
            handleUnmount={this.handleChildUnmount}
            closeLoading={this.closeLoading} />
        }
        {
          this.state.showDetailsWizard &&
          <DetailsWizard
            storyId={this.props.storyId}
            story={this.state.storyDetails}
            isImported={this.state.isStoryImported}
            open={this.state.showDetailsWizard}
            exitFunc={this.closeDetailsWizard}
          />
        }
      </>
    );
  }
}

const mapState = ({ platform: { books, bookList, bookShareList, downloadBook, booksImport }, shared: { user } }) => {
  return {
    books,
    bookList,
    bookShareList,
    user,
    downloadBook,
    booksImport
  }
};

export default connect(mapState)(withStyles(styles)(Content));
