import {
    ROOT_URL,
    FETCH_USER,
    FETCH_USER_BOOKSHARE,
    CLEAR_USER_BOOKSHARE,
    HANDLE_ERROR,
    CLEAR_ERRORS,
    RESET_USER,
    RESET_ACCOUNT,
    IMPORT_BOOKS_SUCCESS,
    CLEAR_IMPORTED_BOOK,
    BOOK_SHARE_LOGIN_VERIFED
} from '../constants';
import {GET_COURSE} from '../constants/course';
import axios from 'axios';
import querystring from 'query-string';
import {registerChatUser, deregisterUser} from '../../socket-client';

export const login = (credentials, history, register, passthrough, courseKey, openTrainingWizard = false) => {
    var url = `${ROOT_URL}/Auth/login`;
    if (courseKey) {
        url += `?courseKey=${courseKey}`
    }
    return (dispatch) => {
        axios
            .post(url, querystring.stringify(credentials), {withCredentials: true})
            .then((response) => {
                var configUrl = response.config.url;
                configUrl = configUrl.substr(configUrl.indexOf("://"));
                var responseUrl = response.request.responseURL;
                responseUrl = responseUrl.substr(responseUrl.indexOf("://"));
                if (responseUrl === configUrl) {
                    sessionStorage.removeItem(`loginCount_${credentials.username}`);
                    dispatch({
                        type: FETCH_USER,
                        user: response.data
                    });
                    dispatch({
                        type: CLEAR_ERRORS
                    });
                    if (passthrough) {
                        history.push(passthrough);
                    } else {
                        register
                            ? history.push(`${response.data.homePage}?setup`)
                            : history.push(response.data.homePage, { openTrainingWizard: openTrainingWizard });
                    }
                } else {
                    let error;
                    let type = 'auth';
                    let userName = credentials.username;
                    const count = sessionStorage.getItem(`loginCount_${userName}`) ? sessionStorage.getItem(`loginCount_${userName}`) : 0;
                    switch (response.data.split("\n")[0].trim()) {
                        case "Account is not active.":
                            error = "Your account is inactive, please contact your administrator";
                            break;
                        case "Bad credentials":
                            sessionStorage.setItem(`loginCount_${userName}`, parseInt(count) + 1);
                            error = `Incorrect email or password, please try again (${5 - (parseInt(count) + 1)} attempts remaining)`;
                            break;
                        case "Please Enter a verification code":
                            error = "Your organization requires Multi-Factor Authentication.  Please enter the code on your authenticator application."
                            type = 'MFA';
                            break;
                        case "Invalid verification code":
                            type = 'MFA';
                            error = "Incorrect verification code";
                            break;
                        case "Register Authenticator:":
                            type = "MFA Register"
                            error = response.data.split("\n")[1].trim();
                            break;
                        case "Account Is Locked Due to Failed Attempts":
                            type = 'auth';
                            sessionStorage.removeItem(`loginCount_${userName}`);
                            error = 'Your account has been locked due to multiple failed login attempts, please contact customer support.';
                            break;
                        default:
                            error = "There was an error, please try again.";
                            break;
                    }
                    dispatch({
                        type: HANDLE_ERROR,
                        error: {type: type, msg: error}
                    });
                }
                return response.data;
            })
            .then((user) => {
                // registerChatUser(user);
            })
            .catch((err) => console.log(err))
    }
};

export const bookShareLoginV2 = (token, expiration) => {
    var url = `${ROOT_URL}/bookshare/login/v2?token=${token}&expiration=${expiration}`;
    return (dispatch) => {
            axios.post(url).then(res =>{
                dispatch({
                    type: IMPORT_BOOKS_SUCCESS
                });
                dispatch({
                    type: BOOK_SHARE_LOGIN_VERIFED
                });
            })
}
}

export const bookShareLogin = (credentials, courseKey = null) => {
    console.log('share called');
    var url = `${ROOT_URL}/bookshare/login`;
    if (courseKey) {
        url += `?courseKey=${courseKey}`
    }
    return (dispatch) => {
        axios
            .post(url, querystring.stringify(credentials), {withCredentials: true})
            .then(res => {
                dispatch({
                    type: FETCH_USER_BOOKSHARE
                });
                dispatch({
                    type: CLEAR_ERRORS
                });
            })
            .catch((err) => {
                let error;
                let type = 'auth';
                switch (err.response.status) {
                    case 400:
                        error = err.response.data
                        break;

                    default:
                        error = "There was an error, please try again.";
                        break;
                }
                dispatch({
                    type: HANDLE_ERROR,
                    error: {type: type, msg: error}
                });
            })
    }
};

export const clearBookShareLogin = (credentials, courseKey = null) => {
    var url = `${ROOT_URL}/bookshare/clear-login`;
    if (courseKey) {
        url += `?courseKey=${courseKey}`
    }
    return (dispatch) => {
        axios
            .post(url, querystring.stringify(credentials), {withCredentials: true})
            .then(res => {
                dispatch({
                    type: CLEAR_USER_BOOKSHARE
                })
            })
            .catch((err) => console.log(err))
    }
};

export const logout = (history, err, user) => {
    const url = `${ROOT_URL}/Auth/invalidate`;
    return (dispatch) => {
        axios
            .post(url)
            .catch(() => {
                // deregisterUser(user);
                if (err) {
                    dispatch({
                        type: HANDLE_ERROR,
                        error: err
                    })
                }
                dispatch({
                    type: RESET_USER,
                });
                dispatch({
                    type: RESET_ACCOUNT,
                });
            })
    };
};

export const setPassword = (token, password, history) => {
    const url = `${ROOT_URL}/password-reset?tokenId=${token.tokenId}`;
    return (dispatch) => {
        axios
            .post(url, password, {headers: {'Content-Type': 'text/plain'}})
            .then(() => history.push('/login'))
            .catch(error => dispatch({type: HANDLE_ERROR, error: {msg: error.response.data}}))
    }
};

export const setPasswordWithPurchase = (token, email, password, history) => {
    const url = `${ROOT_URL}/password-reset?tokenId=${token.tokenId}`;
    return (dispatch) => {
        axios
            .post(url, password, {headers: {'Content-Type': 'text/plain'}})
            .then(() => {
                const url = `${ROOT_URL}/staff/user`;
                axios.get(url)
                    .then(response => {
                        if (typeof (response.data) === 'object') {
                            dispatch({
                                type: FETCH_USER,
                                user: response.data
                            });
                        }
                    })
            })
            .catch(error => dispatch({ type: HANDLE_ERROR, error: { msg: error.response.data } }))
    }
};


export const resetPassword = (email, history) => {
    const url = `${ROOT_URL}/password-reset?email=${email}`;
    return () => {
        axios
            .get(url)
            .then(() => history.push('/login'))
            .catch((err) => console.log(err))
    }
};

export const changeStudentPassword = (id, password) => {
    const url = `${ROOT_URL}/students/${id}/password`;
    return () => {
        return axios
            .post(url, password, {headers: {'Content-Type': 'text/plain'}})
            .catch(err => console.log(err))
    }
};

export const getInvitation = (inviteId) => {
    const url = `${ROOT_URL}/registration/invitation/${inviteId}`;
    return axios
        .get(url)
        .then(response => {
            localStorage.setItem('invite', JSON.stringify(response.data));
            return response.data
        })
        .catch((err) => console.log(err))
};

export const getCourse = (groupId) => {
    const url = `${ROOT_URL}/course-list/${groupId}`;
    console.log(url);
    return (dispatch) => {
        return axios
            .get(url)
            .then(response => {
                dispatch({
                    type: GET_COURSE,
                    course: response.data
                })
            })
    }
}

export const registerSpecialist = (accessKey, specialistDto, terms, userDto, invitationDto, invitationDetails, courseKey) => {
    const url = `${ROOT_URL}/registration/specialist`;
    const data = invitationDto ? {
        accessKey,
        specialistDto,
        userDto,
        invitationDto,
        invitationDetails,
        terms
    } : {accessKey, specialistDto, userDto, terms, courseKey};
    return (dispatch) => {
        return axios
            .post(url, data)
            .then(response => {
                return response.data
            })
            .catch(err => {
                dispatch({
                    type: HANDLE_ERROR,
                    error: {msg: err.response.data, status: err.response.status}
                })
            })
    }
};

export const registerGuardian = (accessKey, guardianDto, userDto, children, password, history) => {
    const url = `${ROOT_URL}/registration/guardian`;
    return (dispatch) => {
        axios
            .post(url, {
                accessKey: "dfdf4d77-5b50-4053-a440-9b5d0be504e3",
                guardianDto,
                userDto,
                children,
                invitationDto: {},
                specialists: []
            })
            .then((response) => {
                axios.post(`${ROOT_URL}/password-reset?tokenId=${response.data}&password=${password}`, {
                    tokenId: response.data,
                    password
                })
                    .then(() => {
                        const credentials = {password, email: guardianDto.email};
                        axios
                            .post(`${ROOT_URL}/Auth/login`, querystring.stringify(credentials), {withCredentials: true})
                            .then((_response) => {
                                if (_response.config.url === _response.request.responseURL) {
                                    history.push(_response.data.homePage);
                                }
                            })
                    })
            })
            .catch(err => {
                dispatch({
                    type: HANDLE_ERROR,
                    error: {msg: err.response.data}
                })
            })
    }
};

export const purchase = (specialistDto, terms, email, studentCount) => {
    const url = `${ROOT_URL}/purchase`;
    return axios
        .post(url, {specialistDto, email, studentCount, terms})
        .then(response => response.status)
        .catch(err => err.status)
};

export const fetchSubscriptionOptions = () => {
    const url = `${ROOT_URL}/purchase`;
    return axios
        .get(url)
        .then(response => response.data)
        .catch(err => ({error: true, err}))
}

export const clearImportedBook = () =>{
    return (dispatch => {
        dispatch({
            type: CLEAR_IMPORTED_BOOK
        })
    })   
}