import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { TextField, SnackbarContent, IconButton, InputAdornment, Button, Card, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Visibility, VisibilityOff, Close } from '@material-ui/icons';
import { login, bookShareLogin, clearErrors } from '../../../redux/actions';
import logo from '../../../assets/BrandAssets/Logo/OBJECTIVEED-Logo-Vertical-1.png';
import styles from '../../../styles/login';
import { FormattedMessage } from 'react-intl';
import queryString from 'query-string';


const CollisionLink = React.forwardRef((props, ref) => {
  return (
    <Link innerRef={ref} to={{ pathname: '/registration', buyNow: true }} {...props} />
  )
});

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      code: '',
      showPassword: false,
      errors: { password: '' }
    }
  }

  componentDidMount() {
    localStorage.clear();
    !this.props.isBookShareLogin && (document.body.style.backgroundColor = '#310073');
  }

  togglePasswordVisibility = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  login = () => {
    const { username, password, code } = this.state;
    let params = queryString.parse(this.props.location.search);
    console.log(params);
    const courseKey = params.courseKey;
    console.log(courseKey);
    !password ? this.setState({ errors: { password: '* Please enter password' } })
      : this.props.login({ username: username, password: password, code: code }, false, null, courseKey);
  };

  registerLogin = () => {
    const { username, password } = this.state;
    let params = queryString.parse(this.props.location.search);
    console.log(params);
    const courseKey = params.courseKey;
    !password ? this.setState({ errors: { password: '* Please enter password' } })
      : this.props.login({ username: username, password: password }, true, null, courseKey);
  };

  submitCredential = () => {
    const { username, password } = this.state;
    !password ? this.setState({ errors: { password: '* Please enter password' } })
      : this.props.bookShareLogin({ username: username, password: password });
  }

  handleClose = () => {
    this.props.clearErrors();
  };

  render() {
    const { username, password, showPassword, errors, code } = this.state;
    const { classes, error, isBookShareLogin } = this.props;
    let params = queryString.parse(this.props.location.search);
    const registration = this.props.history && this.props.history.location && this.props.history.location.search === '?register';
    return (
      <Card className={isBookShareLogin ? classes.bookShareLoginCont : classes.loginContainer}>
        <div className={classes.loginForm}>
          <img
            className={classes.loginLogo}
            alt='login-logo'
            src={logo}
          />
          {error.type === 'sessionExp' &&
            <SnackbarContent
              open={!!error.msg}
              onClose={this.handleClose}
              classes={{ root: classes.error }}
              message={<span id='message-id'>Your session has expired, please log back in</span>}
              action={[
                <IconButton
                  key='close'
                  aria-label='Close'
                  color='inherit'
                  className={classes.close}
                  onClick={this.handleClose}
                >
                  <Close />
                </IconButton>,
              ]}
            />
          }
          {
            isBookShareLogin &&
            <Typography align='center' variant="h6">
              <FormattedMessage id='Login.header' />
            </Typography>
          }
          <TextField
            autoFocus={true}
            placeholder='first.last@location.edu'
            id='username'
            variant='outlined'
            type='email'
            label={<FormattedMessage id='General.email' />}
            value={username}
            onChange={(ev) => this.setState({ username: ev.target.value })}
          />
          <TextField
            id='password'
            helperText={errors.password}
            variant='outlined'
            error={!!errors.password}
            type={showPassword ? 'text' : 'password'}
            label={<FormattedMessage id='Login.password' />}
            value={password}
            onChange={(ev) => this.setState({ password: ev.target.value })}
            onKeyUp={(event) => {
              if (event.key == 'Enter')
              isBookShareLogin ? this.submitCredential() : this.login();
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='Toggle password visibility'
                    onClick={this.togglePasswordVisibility}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {error.type === 'auth' &&
            <SnackbarContent
              open={!!error.msg}
              onClose={this.handleClose}
              classes={{ root: classes.error }}
              // style={{padding:0}}
              message={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span id='message-id' style={{ flex: 1 }}>
                    {error.msg}
                  </span>
                  <IconButton
                    key='close'
                    aria-label='Close'
                    color='inherit'
                    className={classes.close}
                    onClick={this.handleClose}
                  >
                    <Close />
                  </IconButton>
                </div>
              }
            />
          }
          {error.type === 'MFA' &&
            <TextField
              id='code'
              helperText={errors.password}
              variant='outlined'
              error={!!errors.code}
              type='number'
              label={<FormattedMessage id='Login.code' />}
              value={code}
              onChange={(ev) => this.setState({ code: ev.target.value })}
              onKeyUp={(event) => {
                if (event.key == 'Enter')
                isBookShareLogin ? this.submitCredential() : this.login();
              }}
            />
          }
          {error.type === 'MFA' &&
            <SnackbarContent
              open={!!error.msg}
              onClose={this.handleClose}
              classes={{ root: classes.error }}
              message={<span id='message-id'>{error.msg}</span>}
              action={[
                <IconButton
                  key='close'
                  aria-label='Close'
                  color='inherit'
                  className={classes.close}
                  onClick={this.handleClose}
                >
                  <Close />
                </IconButton>,
              ]}
            />
          }
          {error.type === "MFA Register" &&
            <div width="100%">
              <TextField
                id='code'
                helperText={errors.password}
                variant='outlined'
                error={!!errors.code}
                type='number'
                label={<FormattedMessage id='Login.code' />}
                value={code}
                onChange={(ev) => this.setState({ code: ev.target.value })}
                onKeyUp={(event) => {
                  if (event.key == 'Enter')
                  isBookShareLogin ? this.submitCredential() : this.login();
                }}
              />
              <div>
                <FormattedMessage id="Authentication.MFA.Register" />
                <img src={"https://chart.googleapis.com/chart?chs=200x200&chld=M%%7C0&cht=qr&chl=" + error.msg} />
              </div>
            </div>
          }
          <div className={classes.loginBtns}>
            <Button
              disabled={!username}
              id='login-button'
              variant='contained'
              onClick={isBookShareLogin ? this.submitCredential : registration ? this.registerLogin : this.login}
            >
              {isBookShareLogin ?
                <FormattedMessage id='Login.submit' /> :
                <FormattedMessage id='Login.button' />
              }
            </Button>
            {
              params && params.buyNow == 'Y' &&
              <Button variant='contained'
                id='buy-button'
                disableElevation
                component={CollisionLink}
              >
                Buy Now
              </Button>
            }
            {
              registration &&
              <Button
                component={CollisionLink}
                id='register-button'
                variant='contained'
              >
                Register
              </Button>
            }
          </div>
        </div>
        <Typography align='center' variant='caption'>
          {isBookShareLogin ?
            <><FormattedMessage id='Login.bookShareSignUpText' /><a href='https://www.bookshare.org/cms/get-started/sign' target="_blank"><FormattedMessage id='Login.bookShareSignUpLink' /></a></> :
            <><FormattedMessage id='Login.resetText' /> <Link to='/forgot-password'><FormattedMessage id='Login.resetLink' /></Link></>
          }
        </Typography>
      </Card>
    )
  }
}

const mapState = ({ shared: { error } }) => {
  return {
    error
  }
};

const mapDispatch = (dispatch, { history }) => {
  return {
    login: (credentials, register, passthrough, courseKey) => dispatch(login(credentials, history, register, passthrough, courseKey)),
    bookShareLogin: (credentials, courseKey) => dispatch(bookShareLogin(credentials, courseKey)),
    clearErrors: () => dispatch(clearErrors())
  }
};

export default connect(mapState, mapDispatch)(withStyles(styles)(Login));