import React, { Component } from 'react';
import {
  Button,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  withStyles, Checkbox,
} from '@material-ui/core';
import styles from '../../../../styles/registration';

class SpecialistRegistrationPage2 extends Component {

  state = {
    checked: [],
    options: []
  };

  componentDidMount(){
    // document.body.style.backgroundColor = '#310073';
    const invitationDetails = this.props.invitation ? JSON.parse(this.props.invitation.invitationDetails) : [];
    const checked = invitationDetails.map(option => option.details.key);
    this.setState({ checked, options: invitationDetails })
  }

  change = ({ target: { checked }}, key) => {
    if (checked) {
      this.setState({ checked: [...this.state.checked, key]})
    }
    else {
      const filteredCheck = this.state.checked.filter(option => option !== key);
      this.setState({checked: filteredCheck});
    }
  };

  submit = () => {
    const options = this.state.options.filter(option => this.state.checked.some(checked => option.details.key === checked));
    this.props.submit('', options)
  };

  render(){
    const { classes } = this.props;
    return (
      <>
        <Typography> Here are all your invitations! Please select the ones you would like to accept and then continue: </Typography>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} size='small'>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Details</TableCell>
                <TableCell align='center'>Accept?</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                this.state.options.map(option => (
                  <TableRow key={option.details.key}>
                    <TableCell>
                      {option.details.groupName}
                    </TableCell>
                    <TableCell>
                      {option.details.description}
                    </TableCell>
                    <TableCell padding='checkbox' align='center'>
                      <Checkbox checked={this.state.checked.includes(option.details.key)} onChange={(ev) => this.change(ev, option.details.key)}/>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </div>
        <Button
          id='accept-TOI'
          className={ classes.confirmBtn }
          variant='contained'
          onClick={this.submit}
        >
          Create my account
        </Button>
      </>
    )
  }
}

export default withStyles(styles)(SpecialistRegistrationPage2);