export default {
  brailleChartCard: {
    marginTop: 20,
    gridColumn: 'span 3',
    gridRow: 2,
  },
  brailleChart: {
    height: '540px',
    margin: '0 auto',
    overflow: 'scroll',
  },
  header: {
    backgroundColor: '#0087b6',
    display: 'grid',
    gridTemplateColumns: '48px 1fr',
    padding: '15px'
  },
  icon: {
    padding: '5px',
    backgroundColor: '#310073',
    color: '#fff',
    justifySelf: 'center',
    alignSelf: 'center',
    borderRadius: '5px'
  },
  chartTitle: {
    alignSelf: 'center',
    color: '#fff',
    marginLeft: '30px'
  },
  buttons: {
    display: 'grid',
    justifyContent: 'center',
    gridAutoFlow: 'column',
    marginTop: 10
  },
  empty: {
    marginTop: 120
  },
  card: {
    margin: 10
  },
  info: {
    display: 'grid',
    gridTemplateColumns: '1fr 6fr',
    marginBottom: 15,
    gridColumnGap: 10,
    alignItems: 'center'
  },
  questions: {
    gridAutoFlow: 'row',
    display: 'grid'
  },
  answers: {
    display: 'grid',
    marginBottom: 10
  },
  answer: {
    display: 'inherit',
    alignSelf: 'center',
    gridTemplateColumns: 'auto 1fr',
    gridColumnGap: '10px',
    '.MuiSvgIcon-root': {
      color: 'green'
    }
  },
  button: {
    marginTop: 5,
    marginBottom: 15
  }
}