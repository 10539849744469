import { ROOT_URL, HANDLE_ERROR, FETCH_ALL_LEVELS, CREATE_LEVEL, PATCH_LEVEL, DELETE_LEVEL } from '../../constants';
import axios from 'axios';

export const fetchLevels = (history) => {
  const url = `${ROOT_URL}/level-templates`;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        if (typeof(response.data) === 'object'){
          dispatch({
            type: FETCH_ALL_LEVELS,
            levels: response.data
          })
        }
        else {
         history.push('/login');
          dispatch({
            type: HANDLE_ERROR,
            error: {type: 'sessionExp', msg: 'Your session has expired, please log back in'}
          })
        }
      })
      .catch(err => console.log(err))
  }
};

export const createLevel = (level) => {
  const url = `${ROOT_URL}/level-templates`;
  return (dispatch) => {
    axios
      .post(url, level)
      .then((response) => {
        dispatch({
          type: CREATE_LEVEL,
          level: response.data
        })
      })
      .catch(err => console.log({err}))
  }
};

export const patchLevel = (level) => {
  const url =`${ROOT_URL}/level-templates`;
  return (dispatch) => {
    axios
      .patch(url, level)
      .then(() => {
        dispatch({
          type: PATCH_LEVEL,
          level
        })
      })
      .catch(err => console.log(err))
  }
};

export const deleteLevel = (level) => {
  const url =`${ROOT_URL}/level-templates`;
  return (dispatch) => {
    console.log('got it!', level)
    axios
      .delete(url, { data: level })
      .then(() => {
        dispatch({
          type: DELETE_LEVEL,
          level
        })
      })
      .catch(err => console.log(err))
  }
};