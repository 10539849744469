import React, { useState, useEffect } from 'react';
import reviewStyle from '../../../styles/reviewPage.js';
import { Divider, Popover, Typography, Tooltip, withStyles } from '@material-ui/core';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';

function ReviewText({ type, analysis, data, play, classes, reviewArray, reviewTextList, highlightWordIndex }) {
    const [textAnalysis, setTextAnalysis] = useState(null);
    const [wordScore, setWordScore] = useState(null);
    const [firstAnalysis, setFirstAnalysis] = useState(null);


    useEffect(() => {
        if (data && data.firstAnalysis && data.firstAnalysis.NBest) {
            setFirstAnalysis(data.firstAnalysis.NBest[0])
        }
    }, [])

    let reviewTextListStripped = reviewTextList.filter(obj => obj.ErrorType!="Insertion");


    const textOverlay = (index, e) => {
        if (reviewTextListStripped && reviewTextListStripped.length > 0) {
            setTextAnalysis(e.currentTarget);
            setWordScore(reviewTextListStripped[index]);
        }
    }

    const closeTextOverlay = () => {
        setTextAnalysis(null);
    }


    const openPoper = textAnalysis;
    return (
        <>
            <div className={classes.textWrapper}>
                <div className={classes.innerTextWrap}>
                    {
                        Array.isArray(reviewArray) && reviewArray.length > 0 &&
                        reviewArray.map((text, index) => {
                            let style;
                            if (reviewTextListStripped && reviewTextListStripped.length > 0) {
                                let reviewText = reviewTextListStripped[index];
                                style = reviewText &&
                                    reviewText.AccuracyScore < 60 ?
                                    reviewText.AccuracyScore < 40 ?
                                        { color: "#E74C3C" } :
                                        { color: "#F1C40F" } :
                                    {}
                            }
                            return (
                                <span key={index}>
                                    <span
                                        style={style}
                                        className={
                                            `${play && (highlightWordIndex === index) && classes.highlight}`
                                        }
                                        aria-owns={openPoper ? 'mouse-over-popover' : undefined}
                                        aria-haspopup="true"
                                        onMouseEnter={(e) => { textOverlay(index, e) }}
                                        onMouseLeave={closeTextOverlay}
                                    > {text} </span>
                                    <Popover
                                        id='mouse-over-popover'
                                        open={openPoper}
                                        className={classes.popover}
                                        classes={{
                                            paper: classes.paper,
                                        }}
                                        anchorEl={textAnalysis}
                                        onClose={closeTextOverlay}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        disableRestoreFocus
                                    >
                                        <Typography className={classes.popWord}>
                                            {wordScore && wordScore.Word &&
                                                wordScore.Word}
                                        </Typography>
                                        <Divider style={{ background: "darkgreen" }} />
                                        <Divider style={{ background: "darkgreen" }} />
                                        <Typography className={classes.typography}>
                                            <span style={{ color: "red" }}>Accuracy:</span> {wordScore && wordScore.AccuracyScore &&
                                                wordScore.AccuracyScore}
                                        </Typography>
                                        <Divider style={{ background: "darkblue" }} />
                                        <Typography className={classes.typography} style={{ color: "red" }}>
                                            Phonemes
                                        </Typography>
                                        {wordScore && wordScore.Phonemes &&
                                            wordScore.Phonemes.map((value, index) => {
                                                return (
                                                    <Typography className={classes.typography} key={index}>
                                                        {value.Phoneme}: {value.AccuracyScore}
                                                    </Typography>
                                                )
                                            })
                                        }
                                    </Popover>
                                </span>
                            )
                        })
                    }
                </div>
            </div>
            {
                analysis && firstAnalysis && (
                    <div>
                        <div className={classes.analysisContainer}>
                            <Tooltip title="Overall score indicating the pronunciation quality of the given speech. This is aggregated from Accuracy Score, Fluency Score and Completeness Score with weight.">
                                <div className={classes.analysisContInnerBox}>
                                    <div>Score: <span>{analysis.PronScore}</span></div>
                                    { type='Second Analysis' && analysis.PronScore > firstAnalysis.PronScore
                                        ? <ArrowUpward style={{ fill: 'green'}} />
                                        : analysis.PronScore < firstAnalysis.PronScore
                                        ? <ArrowDownward style={{ fill: 'red'}} />
                                        : ''
                                    }
                                </div>
                            </Tooltip>
                            <Tooltip title="Fluency indicates how closely the speech matches a native speaker's use of silent breaks between words.">
                                <div className={classes.analysisContInnerBox}>
                                    <div>Fluency Score: <span>{analysis.FluencyScore}</span></div>
                                    { type='Second Analysis' && analysis.FluencyScore > firstAnalysis.FluencyScore
                                        ? <ArrowUpward style={{ fill: 'green'}} />
                                        : analysis.FluencyScore < firstAnalysis.FluencyScore
                                        ? <ArrowDownward style={{ fill: 'red'}} />
                                        : ''
                                    }
                                </div>
                            </Tooltip>
                            <Tooltip title="Accuracy indicates how closely the phonemes match a native speaker's pronunciation. Word and full text level accuracy score is aggregated from phoneme level accuracy score.">
                                <div className={classes.analysisContInnerBox}>
                                    <div>Accuracy Score: <span>{analysis.AccuracyScore}</span></div>
                                    { type='Second Analysis' && analysis.AccuracyScore > firstAnalysis.AccuracyScore
                                        ? <ArrowUpward style={{ fill: 'green'}} />
                                        : analysis.AccuracyScore < firstAnalysis.AccuracyScore
                                        ? <ArrowDownward style={{ fill: 'red'}} />
                                        : ''
                                    }
                                </div>
                            </Tooltip>
                            <Tooltip title="Completeness of the speech, determined by calculating the ratio of pronounced words to reference text input.">
                                <div className={classes.analysisContInnerBox}>
                                    <div>Completeness Score: <span>{analysis.CompletenessScore}</span></div>
                                    { type='Second Analysis' && analysis.CompletenessScore > firstAnalysis.CompletenessScore
                                        ? <ArrowUpward style={{ fill: 'green'}} />
                                        : analysis.CompletenessScore < firstAnalysis.CompletenessScore
                                        ? <ArrowDownward style={{ fill: 'red'}} />
                                        : ''
                                    }
                                </div>
                            </Tooltip>
                        </div>
                        {/*<JSONPretty id="json-pretty" data={analysis}></JSONPretty>*/}
                    </div>
                )
            }
        </>
    )
}

export default withStyles(reviewStyle)(ReviewText);
