import React, {Component} from 'react';
import { connect } from 'react-redux';
import StudentList from '../../components/Admin/StudentList';
import StudentWizard from '../../components/Wizards/Student/StudentWizardBase';
import {Face} from '@material-ui/icons';
import {Typography, Divider} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import baseStyle from '../../../styles/students';
import {FormattedMessage} from 'react-intl';
import RestoreStudents from "../../components/Admin/RestoreStudents";
import { getAccount } from '../../../redux/actions';
import { RESET_BOOKLIST_TEMP } from '../../../redux/constants';


class Students extends Component {

    componentDidMount(){
        const { account } = this.props;
        this.props.getAccount();
        this.props.dispatch({ type: RESET_BOOKLIST_TEMP })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.account.id !== this.props.account.id) {
            this.props.getAccount();
        }
    }

    render() {
        const {history, classes, location} = this.props;
        return (
            <div>
                <div className={classes.title}>
                    <Face className={classes.icon} fontSize='inherit'/>
                    <Typography className={classes.text} variant='h3'>
                        <FormattedMessage id='Students.title'/>
                    </Typography>
                </div>
                <Divider/>
                <br/>
                <StudentList location={location} history={history}/>
                <div className={this.props.classes.container}>
                    <StudentWizard/>
                    <RestoreStudents/>
                </div>
                <div className={this.props.classes.container}>
                    <br/>
                </div>
                <div className={this.props.classes.container}>
                    <FormattedMessage id="General.studentsEnabled"/>: {this.props.account.studentCount}
                </div>
                <div className={this.props.classes.container}>
                    <FormattedMessage id="General.studentsBillable"/>: {this.props.account.billingCount}
                </div>
            </div>
        )
    }
}


const mapState = ({ shared: { account }}) => {
    return {
        account,
    }
};

const mapDispatch = (dispatch, { history }) => {
    return {
        getAccount: () => dispatch(getAccount(history)),
        dispatch
    }
};

export default connect(mapState,mapDispatch)(withStyles(baseStyle)(Students));
