import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, withStyles, } from '@material-ui/core/index';
import SpecialistRegistrationPage1 from '../Specialist/SpecialistRegistrationPage1';
import SpecialistRegistrationPage3 from '../Specialist/SpecialistRegistrationPage3';
import SpecialistRegistrationPage4 from '../Specialist/SpecialistRegistrationPage4';
import RegistrationPage1 from './RegistrationPersonalInfo';
import RegistrationTerms from './RegistrationTerms';
import RegistrationWizard from './StudentEnrollmentWizard';
import { getInvitation, registerSpecialist, clearErrors, setPasswordWithPurchase, login } from '../../../../redux/actions';
import styles from '../../../../styles/registration';
import { ROOT_URL } from '../../../../redux/constants';
import axios from 'axios';
import {Helmet} from 'react-helmet';
import PaymentDialog from './PaymentDialog';

class SpecialistRegistration extends Component {

  state = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    page: this.props.page || 1,
    students: 1,
    invitation: {},
    error: {},
    terms: '',
    token: '',
    proceedToPurchase: true,
    staffId: '',
    acceptedTerms: false,
    termAndCond:false,
  };

  componentDidMount() {
    // document.body.style.backgroundColor = '#310073';
    getInvitation(this.props.id)
      .then(invitation => this.setState({ invitation }))
  }

  handleChange = (ev) => {
    if (ev.target.name == 'checked') {
      this.setState({ acceptedTerms: ev.target.checked })
    }
    else
      this.setState({ [ev.target.name]: ev.target.value })
  };

  setTerms = (newTerms) => {
    console.log(newTerms);
    this.setState({ terms: newTerms })
    console.log(this.state.terms);
  };

  next = () => {
    if (this.props.specialist && (this.state.email !== this.state.invitation.email)) {
      this.setState({ error: { field: 'email', msg: 'Please use your email from your invitation to register.' } });
    } else if (this.state.password !== this.state.confirmPassword) {
      this.setState({ error: { field: 'confirmPassword', msg: 'passwords must match' } });
    }
    else if(!this.state.firstName){
      this.setState({ error: { field: 'firstname', msg: 'first name is required' } })
    } else if (!this.state.lastName) {
      this.setState({ error: { field: 'lastname', msg: 'last name is required' } })
    }
    else {
      if (!this.state.acceptedTerms) {
        this.setState({ termAndCond: true });
      } else {
        axios.get(`${ROOT_URL}/registration/check-email?email=${this.state.email}`).then(response => {
          if (response.data) {
            this.submit('https://docs.google.com/document/d/e/2PACX-1vRgiqiZ2EZ0jHECJ_bY972X3rhK1m722J19V7Rt3nTPnQLdzhMIMwQAH3_Iq1UM11Ask9bbs_l-ebB1/pub?embedded=true')
          } else {
            console.log('here');
            this.setState({ error: { field: 'email', msg: 'Email in Use' } });
          }
        })
      }
    }
  };

  goToPage = (page) => {
    this.setState({ page })
  };

  clearErrors = () => {
    this.setState({ error: {} });
    this.props.clearErrors();
  };

  submit = (terms, options) => {
    const { firstName, lastName, email, password, confirmPassword } = this.state;
    const courseKey = this.props.courseKey;
    const userDto = { type: 'Specialist', firstName, lastName, email, password, confirmPassword, products: 'Reading' };
    const specialistDto = { type: 'Specialist', firstName, lastName, email, password, confirmPassword };
    const invitationDetails = JSON.stringify(options);
    const invitationDto = this.state.invitation ? { ...this.state.invitation } : null;
    this.props.registerSpecialist('dfdf4d77-5b50-4053-a440-9b5d0be504e3', specialistDto, terms, userDto, invitationDto, invitationDetails, courseKey)
      .then(specialist => {
        this.setState({ token: specialist.resetToken, staffId: specialist.staffId })
        this.goToPage(2);
        this.setTerms(terms);
        this.setPassword();
      });
  };

  setPassword = () => {
    let token = { tokenId: this.state.token };
    this.props.setPasswordWithPurchase(token, this.state.email, this.state.password, this.props.history)
  }

  closePurchaseDialog = () => {
    this.setState({ proceedToPurchase: false, page: 1 })
  };

  login = () => {
    const credentials = { code: '', password: this.state.password, username: this.state.email };
    this.props.login(credentials, this.props.history, null, null, null, true)
  }

  renderPage = () => {
    if (this.props.specialist) {
      switch (this.state.page) {
        case 1:
          return <SpecialistRegistrationPage1 handleChange={this.handleChange} next={this.next} error={this.state.error} clearErrors={this.clearErrors} />;
        case 2:
          return <RegistrationTerms goToPage={this.goToPage} next={this.next} />;
        case 3:
          return <SpecialistRegistrationPage3 invitation={this.state.invitation} goToPage={this.goToPage} submit={this.submit} />;
        case 4:
          return <RegistrationWizard />;
        case 5:
          return <SpecialistRegistrationPage4 setPassword={this.setPassword} />;
        default:
          break;
      }
    }
    else {
      switch (this.state.page) {
        case 1:
          return <RegistrationPage1 stateVal={this.state} handleChange={this.handleChange} next={this.next}
            error={this.state.error} clearErrors={this.clearErrors} />;
        case 2:
          return <PaymentDialog history={this.props.history} user={this.props.user} open={this.state.proceedToPurchase} close={this.closePurchaseDialog}
            email={this.state.email} staffId={this.state.staffId} terms={this.state.terms}
            handleNext={this.login} />;
        default:
          break;
      }
    }
  };

  render() {
    const { classes } = this.props;
    document.body.style = 'background: white;';
    return (
        <div>
          <Helmet>
            <style>{'body { background-color: white; }'}</style>
          </Helmet>
      <Card className={this.state.page === 2 ? classes.customContainer : classes.container}>
        {this.renderPage()}
      </Card>
        </div>
    )
  }
}

const mapState = ({ shared: { error, user } }) => {
  return {
    user,
    error
  }
};

const mapDispatch = (dispatch) => {
  return {
    registerSpecialist: (accessKey, specialistDto, userDto, invitationDto, invitationDetails) => dispatch(registerSpecialist(accessKey, specialistDto, userDto, invitationDto, invitationDetails)),
    setPasswordWithPurchase: (token, email, password, history) => dispatch(setPasswordWithPurchase(token, email, password, history)),
    clearErrors: () => dispatch(clearErrors()),
    login: (credentials, history, register, passthrough, courseKey, manageStudent) => dispatch(login(credentials, history, register, passthrough, courseKey, manageStudent)),

  }
};

export default connect(mapState, mapDispatch)(withStyles(styles)(SpecialistRegistration));