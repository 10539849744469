import {
    GET_ALL_BOOKS, GET_STORY_LIST, GET_DATABANK, GET_BOOKS_FOR_STUDENT, ADD_BOOK_TO_STUDENT, DELETE_BOOK, SEARCH_FOR_BOOKS
    , SEARCH_FOR_BOOKS_FAIL, ROOT_URL, DOWNLOAD_BOOK_COMPLETE, DOWNLOAD_BOOK_FAIL, ADD_BOOK_TO_STUDENT_FAILED, BOOK_SHARE_LOGIN_VERIFED, GET_BOOKSHARE_USERS, ADD_BOOKSHARE_USER, STUDENT_BOOK_IMPORTED, REMOVE_IMPORTED_BOOK, REMOVE_STUDENT_BOOK, REMOVE_SEARCH_IMPORTED_BOOK, SEARCH_FOR_BOOKS_FOUND, RESET_IMPORT_MODAL, GET_BOOKSHARE_REQUEST_BOOKS,GET_BOOKSHARE_IDENTITY, DELETE_BOOKSHARE_REQUEST_BOOK
} from '../../constants';
import { saveAs } from 'file-saver';
import axios from 'axios';


export const getBooksForStudent = (studentId) => {
    const url = `${ROOT_URL}/students/${studentId}/story`;
    return (dispatch) => {
        axios
            .get(url)
            .then(response => {
                dispatch({
                    type: GET_BOOKS_FOR_STUDENT,
                    books: response.data
                })
            })
            .catch(err => console.log(err))
    }
}

export const addStoryToStudent = (studentId, bookId) => {
    const url = `${ROOT_URL}/students/${studentId}/story/${bookId}`;
    return (dispatch) => {
        axios
            .post(url)
            .then((response) => {
                console.log('addStoryToStudent', response.data);
                dispatch({
                    type: ADD_BOOK_TO_STUDENT,
                    book: response.data
                });
                dispatch({
                    type: STUDENT_BOOK_IMPORTED,
                    book: response.data
                })
            })
            .catch(err => {
                console.log("Import error", err.response)
                dispatch({
                    type: ADD_BOOK_TO_STUDENT_FAILED,
                    errorMessage: err.response.data,
                    status: err.response.status
                });               
                // dispatch({
                //     type: BOOK_SHARE_LOGIN_VERIFED
                // })
            })
    };
}

export const getAllBooks = (studentId) => {
    console.log("called");
    const url = `${ROOT_URL}/students/${studentId}/story/databank`;
    return (dispatch) => {
        axios
            .get(url)
            .then((response) => {
                dispatch({
                    type: GET_ALL_BOOKS,
                    bookList: response.data
                })
            })
            .catch(err => console.log(err))
    };
}

export const getStoryList = () => {
    console.log("getStoryList called");
    const url = `${ROOT_URL}/story-list`;
    return (dispatch) => {
        axios
            .get(url)
            .then((response) => {
                dispatch({
                    type: GET_STORY_LIST,
                    storyList: response.data
                })
            })
            .catch(err => console.log(err))
    };
}

export const getDatabank = (studentId) => {
    console.log("getDatabanks called");
    const url = `${ROOT_URL}/students/${studentId}/story/databank`;
    return (dispatch) => {
        axios
            .get(url)
            .then((response) => {
                dispatch({
                    type: GET_DATABANK,
                    databank: response.data
                })
            })
            .catch(err => console.log(err))
    };
}

export const searchForBooks = (searchTerm, searchField) => {
    const url = `${ROOT_URL}/bookshare/search`;
    console.log(searchField, searchTerm);
    var data = {};
    data[searchField] = searchTerm;
    return (dispatch) => {
        axios.
            post(url, data)
            .then((response) => {
                dispatch({
                    type: SEARCH_FOR_BOOKS,
                    bookShareList: response.data
                });
                dispatch({
                    type: SEARCH_FOR_BOOKS_FOUND
                });
            })
            .catch(err => {
                console.log(err);
                dispatch({
                    type: SEARCH_FOR_BOOKS_FAIL
                })
            })
    };
}

export const toggleBook = (studentId, bookId) => {
    const url = `${ROOT_URL}/students/${studentId}/story/${bookId}/toggle`;
    return (dispatch) => {
        axios
            .post(url)
            .then((response) => {
                console.log('toogle book rdux', response.data);
                dispatch({
                    type: GET_BOOKS_FOR_STUDENT,
                    books: response.data
                })
            })
            .catch(err => console.log(err))
    };
}

export const toggleEchoType = (studentId, storyId) => {
    const url = `${ROOT_URL}/students/${studentId}/story/${storyId}/change-echo`;
    return (dispatch, getState) => {
        let state = getState();
        axios
            .post(url)
            .then((response) => {
                const { platform: { books } } = state;
                let newBooks = books.length > 0 && books.map((item) => {
                    if (item.name === response.data.name) {
                        return response.data;
                    }
                    return item;
                })
                console.log('books, newBooks, toggleBookType redux res ', books, newBooks, response.data);
                dispatch({
                    type: GET_BOOKS_FOR_STUDENT,
                    books: newBooks
                })
            })
            .catch(err => console.log(err))
    };
}

export const toggleBookType = (studentId, storyId) => {
    const url = `${ROOT_URL}/students/${studentId}/story/${storyId}/change-word-rate`;
    return (dispatch, getState) => {
        let state = getState();
        axios
            .post(url)
            .then((response) => {
                const { platform: { books } } = state;
                let newBooks = books.length > 0 && books.map((item) => {
                    if (item.name === response.data.name) {
                        return response.data;
                    }
                    return item;
                })
                console.log('books, newBooks, toggleBookType redux res ', books, newBooks, response.data);
                dispatch({
                    type: GET_BOOKS_FOR_STUDENT,
                    books: newBooks
                })
            })
            .catch(err => console.log(err))
    };
    
}

export const deleteBook = (studentId, book) => {
    const url = `${ROOT_URL}/students/${studentId}/story`;
    return (dispatch) => {
        axios
            .delete(url, {data: book})
            .then(()=> {
                dispatch({
                    type: DELETE_BOOK,
                    book
                });
                dispatch({
                    type: REMOVE_IMPORTED_BOOK,
                    payload: book
                });
                dispatch({
                    type: REMOVE_STUDENT_BOOK,
                    payload: book
                });
                dispatch({
                    type: REMOVE_SEARCH_IMPORTED_BOOK,
                    payload: book
                });
                dispatch({
                    type: RESET_IMPORT_MODAL
                })
            })
            .catch(err => console.log(err))
    }
}

export const downloadBook = (bookId, studentId, book) => {
    console.log('download from action starts');
    const url = `${ROOT_URL}/bookshare/download/${bookId}/for/${studentId}`;
    return (dispatch) => {
        console.log('download calling');
        axios
            .post(url, { responseType: 'blob' })
            .then((response) => {
                const newBook={...book,name:book.title, description:book.synopsis};
                dispatch({
                    type:  DOWNLOAD_BOOK_COMPLETE
                });
                dispatch({
                    type: STUDENT_BOOK_IMPORTED,
                    book: newBook
                });
            })
            .catch(err => {
                console.log('download from action err res',err);
                dispatch({
                    type: DOWNLOAD_BOOK_FAIL
                })
                dispatch({
                    type: ADD_BOOK_TO_STUDENT_FAILED,
                    errorMessage: err.response.data,
                    status: err.response.status
                });
                dispatch({
                    type: SEARCH_FOR_BOOKS_FOUND
                });              
                // dispatch({
                //     type: BOOK_SHARE_LOGIN_VERIFED
                // });
            });
    }
}

export const resetScore = (studentId, bookId) => {
    const  url = `${ROOT_URL}/students/${studentId}/story/${bookId}/progress`;
    return axios.delete(url);
}


export const getBookShareUsers = () => {
    const url = `${ROOT_URL}/bookshare/users`;
    return (dispatch) => {
        axios.get(url).then(res => {
            dispatch({
                type: GET_BOOKSHARE_USERS,
                data: res.data
            })
        }
        );
    }
}

export const addBookShareUser = (studentId, user) => {
    const url = `${ROOT_URL}/bookshare/assign/${studentId}`;
    return (dispatch) => {
        axios.post(url, user).then(res => {
            dispatch({
                type: ADD_BOOKSHARE_USER
            })
        }
        ).catch(e => console.log("ERROR", e.response.data));
    }
}
export const addStudentToBookshare =(studentId, user) => {
    const url = `${ROOT_URL}/bookshare/student/${studentId}`;
    return (dispatch) => {
        axios.post(url, user).then(res => {
            dispatch({
                type: ADD_BOOKSHARE_USER
            })
        }
        ).catch(e => console.log("ERROR", e.response.data));
    }
}

export const getBookshareRequestedBooks = (studentId) => {
    const url = `${ROOT_URL}/bookshare/${studentId}/requests`;
    return (dispatch) => {
        axios.get(url).then(res => {
            dispatch({
                type: GET_BOOKSHARE_REQUEST_BOOKS,
                data: res.data
            })
        }
        );
    }
}

export const getBookShareIdentity = () => {
    const url = `${ROOT_URL}/bookshare/bookshare-identity`;
    return (dispatch) => {
        axios.get(url).then(res => {
            dispatch({
                type: GET_BOOKSHARE_IDENTITY,
                data: res.data
            })
        })
    }
}

export const deleteBookshareRequestedBook = (studentId, storyRequestId) => {
    const url = `${ROOT_URL}/bookshare/${studentId}/requests/${storyRequestId}`;
    return (dispatch) => {
        axios
            .delete(url)
            .then((res)=> {
                dispatch({
                    type: DELETE_BOOKSHARE_REQUEST_BOOK,
                    id:storyRequestId
                })
            })
            .catch(err => console.log(err))
    }
}