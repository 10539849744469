import {
    ROOT_URL, HANDLE_ERROR, FETCH_SLIDER_MANAGEMENT_SERIES,
    DELETE_SLIDER_MANAGEMENT_SERIES, CREATE_SLIDER_MANAGEMENT_SERIES,
    PATCH_SLIDER_MANAGEMENT_SERIES,
    ADD_STORY_TO_SERIES
  } from '../../constants';
  import axios from 'axios';
  
  export const fetchSliderSeries = (history) => {
      const url = `${ROOT_URL}/buddy-books/admin/series`;
      return (dispatch) => {
        axios
          .get(url)
          .then((response) => {
            if (typeof(response.data) === 'object'){
              dispatch({
                type: FETCH_SLIDER_MANAGEMENT_SERIES,
                sliderSeries: response.data
              })
            }
            else {
             history.push('/login');
              dispatch({
                type: HANDLE_ERROR,
                error: {type: 'sessionExp', msg: 'Your session has expired, please log back in'}
              })
            }
          })
          .catch(err => console.log(err))
      }
  }
    
  export const createSliderSeries = (slider) => {
    const url = `${ROOT_URL}/buddy-books/admin/series`;
    return (dispatch) => {
      axios
        .post(url, slider)
        .then((response) => {
          dispatch({
            type: CREATE_SLIDER_MANAGEMENT_SERIES,
            sliderSeries: response.data
          })
        })
        .catch(err => console.log({err}))
    }
  };
  
  export const patchSliderSeries = (slider) => {
    const url =`${ROOT_URL}/buddy-books/admin/series`;
    return (dispatch) => {
      axios
        .put(url, slider)
        .then(() => {
          dispatch({
            type: PATCH_SLIDER_MANAGEMENT_SERIES,
            slider
          })
        })
        .catch(err => console.log(err))
    }
  };
  
  export const deleteSliderSeries = (slider) => {
    const url =`${ROOT_URL}/buddy-books/admin/series`;
    return (dispatch) => {
      axios
        .delete(url, { data: slider })
        .then(() => {
          dispatch({
            type: DELETE_SLIDER_MANAGEMENT_SERIES,
            slider
          })
        })
        .catch(err => console.log(err))
    }
  };

  export const addStoryToSeries = (data, seriesId) => {
    const url = `${ROOT_URL}/buddy-books/admin/series/${seriesId}/story-data`;
    return (dispatch) => {
      axios
        .post(url, data)
        .then((response) => {
          if(response && response.status===200){
            dispatch({
              type: ADD_STORY_TO_SERIES,
              slider: response.data
            })
          }
        })
        .catch(err => console.log({ err }))
    }
  }