import React, { Component } from 'react';
import { IconButton, InputAdornment, Paper, TextField, Toolbar, ListItem, Typography, List, Button, withStyles } from '@material-ui/core';
import styles from '../../../styles/chatbox';
import { sendMessage, joinRoom } from '../../../socket-client';
import { Send, Close } from '@material-ui/icons';
import { connect } from 'react-redux';

class ChatBox extends Component {

  state = {
    room: this.props.room,
    msg: '',
  };

  sendMessage = () => {
    this.props.sendMessage(this.state.room, this.state.msg, this.props.user.name);
    this.setState({ msg: '' })
  };

  joinRoom = (room) => {
    joinRoom(room);
    this.setState({ room })
  };

  render() {
    const {classes, onlineUsers, messages} = this.props;
    let myself = this.props.user.name;
    let other = this.props.room === this.props.user.name && this.props.messages[0] !== this.props.user.name
                ? `with ${this.props.messages[0].username.split(' ')[0]}`
                : this.props.room && `with ${this.props.room.split(' ')[0]}`;
    return (
      <Paper className={classes.chatBox}>
        <Toolbar className={classes.title}>
          <Typography variant='subtitle1'> Support Chat {other} </Typography>
          <IconButton color='inherit' onClick={this.props.closeChatWindow}>
            <Close color='inherit'/>
          </IconButton>
        </Toolbar>
        <div className={classes.chat}>
          {
            (this.props.support && !this.state.room)
            ? <>
                <Typography variant='subtitle1'> Online Users: </Typography>
                <List>
                  {
                    onlineUsers.map(user => (
                      <Button fullWidth key={user} onClick={() => this.joinRoom(user)}>
                        <ListItem > {user} </ListItem>
                      </Button>
                    ))
                  }
                </List>
              </>
            : <>
                {
                  this.props.support &&
                  <Button size='small' onClick={() => this.setState({ room: '' })}>{`${'<'} back to users`}</Button>
                }
                {
                  messages.map((message, index) => {
                    return (
                     <ListItem
                       key={index}
                       classes={{ root: classes.chatLineSpace }}
                       style={{
                         justifyContent: message.username === myself ? 'flex-end' : 'flex-start',
                       }}
                     >
                        <div
                          className={classes.bubble}
                          key={message + index}
                          style={{
                            backgroundColor: message.username === myself ? '#147efb' : '#8e8e93',
                          }}
                        >
                          {message.message}
                        </div>
                      </ListItem>
                  )})
                }
              </>
          }
        </div>
        {
          !!this.state.room &&
          <TextField
            multiline
            variant='outlined'
            className={classes.input}
            onChange={(ev) => this.setState({ msg: ev.target.value })}
            value={this.state.msg}
            onKeyDown={(ev) => {
              if(ev.key === 'Enter') {
                ev.preventDefault();
                this.sendMessage();
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='send message'
                    onClick={this.sendMessage}
                  >
                    <Send fontSize='small'/>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        }
      </Paper>
    );
  }
}

const mapState = ({ shared: { onlineUsers, messages, user }}) => {
  return {
    onlineUsers,
    messages,
    user
  }
};

const mapDispatch = (dispatch) => {
  return {
    sendMessage: (room, message, username) => dispatch(sendMessage(room, message, username))
  }
};

export default connect(mapState, mapDispatch)(withStyles(styles)(ChatBox));