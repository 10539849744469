export default {
  divider: {
    width: '90%',
    margin: '0 auto'
  },
  title:{
    padding: '20px 0'
  },
  listItem: {
    cursor: 'pointer',
  },
  buttons: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridRowGap: 5,
    paddingBottom: 10
  },
  button: {
    justifySelf: 'center'
  },
  container: {
    marginBottom: 10
  },
  icon: {
    borderRadius: '100%',
    color: 'black',
    padding: 8
  }
}