import { Card, CardContent, CardHeader, Dialog, IconButton, Paper, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";
const BookShareRestrictedModal = ({ open, close }) => {
    return (
        <>
            <Dialog open={open} onClose={close}>
                <Paper>
                    <Card>
                        <CardHeader style={{ backgroundColor: '#00729b', marginBottom: '20px' }} action={
                            <IconButton onClick={close}>
                                <Close />
                            </IconButton>}
                                    title={<Typography variant='h5' className='header'>Bookshare Request</Typography>}
                        >
                        </CardHeader>
                        <CardContent>
                            <div style={{ textAlign: 'center', fontFamily: "Calibri, sans-serif", fontSize: "large" }}>
                                Regrettably, the book you're seeking has been labeled as a restricted title by Bookshare, rendering it unavailable for download in your region. Please consider another book instead.
                            </div>
                        </CardContent>
                    </Card>
                </Paper>
            </Dialog>
        </>
    )
}

export default BookShareRestrictedModal;