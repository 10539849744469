import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { ROOT_URL } from '../../../../redux/constants';
import BrailleSheetsWizardPage1 from './BrailleSheetsWizardPage1';
import WizardToggleButton from '../WizardToggleButton';
import BrailleSheetsWizardPage2 from './BrailleSheetsWizardPage2';
import BrailleSheetsContractions from './BrailleSheetsContractions';
import { handleError } from '../../../../redux/actions';
import { FormattedMessage } from 'react-intl';
import BrailleSheetsWizardPage3 from './BrailleSheetsWizardPage3';
import BrailleSkillBase from '../SkillDatabank/SelfGuidedBase';

class BrailleSheetsWizardBase extends Component {

  state = {
    open: false,
    page: 1,
    contract: false,
    sheet: {},
    sheetSize: 'iPad Mini (7.9")'
  };

  openWizard = () => {
    this.setState({ open: true })
  };

  closeWizard = () => {
    this.setState({ open: false, page: 1, contract: false });
  };

  next = (sheet) => {
    this.setState({ page: this.state.page+1, sheet });
  };

  contraction = (contractions) => {
    const contractionSet = [];
    for  (const [key, value] of Object.entries(contractions)) {
      if (value === true) contractionSet.push(key)
    }
    this.convertBraille({...this.state.sheet, contractionSet})
    this.setState({ contract: false })
  };

  back = () => {
    this.setState({ page: this.state.page-1 });
  };


  convertBraille = (sheet) => {
    const url = `${ROOT_URL}/braille-conversion/convert`;
    axios.post(url, sheet)
      .then((response) => {
        this.next(response.data);
      })
      .catch(err => this.props.handleError(err))
  };

  checkContractions = (sheet) => {
    const url = `${ROOT_URL}/braille-conversion/check-contractions`;
    axios.post(url, sheet)
      .then((response) => {
        this.setState({ contract: true, sheet: response.data })
      })
      .catch(err => this.props.handleError(err))
  };

  changeSheetSize = (sheetSize) => {
    this.setState({ sheetSize })
  };

  render() {
    const { page, open, contract, sheet } = this.state;
    return (
      <div>
        {
          page === 1 &&
          <BrailleSheetsWizardPage1
            open={open}
            close={this.closeWizard}
            next={this.next}
            checkContractions={this.checkContractions}
            convertBraille={this.convertBraille}
            changeSheetSize={this.changeSheetSize}
          />
        }
        {
          contract &&
            <BrailleSheetsContractions
              open={open}
              sheet={sheet}
              contraction={this.contraction}
              close={this.closeWizard}
              back={this.back}
            />
        }
        {
          page === 2 && !contract &&
          <BrailleSheetsWizardPage2
            open={open}
            staffMember={this.props.staffMember}
            id={this.props.id}
            back={this.back}
            next={this.next}
            close={this.closeWizard}
            sheet={this.state.sheet}
            account={this.props.account}
            sheetSize={this.state.sheetSize}
          />
        }
        {
          page === 3 &&
          <BrailleSheetsWizardPage3
            open={open}
            history={this.props.history}
            staffMember={this.props.staffMember}
            id={this.props.id}
            close={this.closeWizard}
            sheet={this.state.sheet}
          />
        }
        <WizardToggleButton
          openWizard={this.openWizard}
          text={<FormattedMessage id='Databanks.addBrailleSheets'/>}
        />
      </div>
    );
  }
}

const mapState = ({ shared: { error }}) => {
  return {
    error
  }
};

const mapDispatch = (dispatch) => {
  return {
    handleError: (err) => dispatch(handleError(err))
  }
};

export default connect(mapState, mapDispatch)(BrailleSheetsWizardBase);