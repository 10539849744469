import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Button,
  Typography,
  withStyles,
} from '@material-ui/core';
import styles from '../../../../styles/registration';
import { connect } from 'react-redux';

const CollisionLink = React.forwardRef((props, ref) => (
  <RouterLink innerRef={ref} to="/login" {...props} />
));

class SpecialistRegistrationPage4 extends Component {

  render(){
    const { classes } = this.props;
    return (
      <>
        <div style={{ padding: '50px', paddingTop:'5px' }}>
          <Typography> Thanks for joining Objective Ed! Please check your email for a password setup link.  Once you login please click buy now to continue your purchaase.  The following video will get you started with our application: </Typography>
          <iframe
            title='getting started video'
            width="500"
            height="315"
            src="https://www.youtube.com/embed/CJ1-DXC3gSs"
            frameBorder="0"
            allowFullScreen
          />
          <Typography>For more training videos please click <a href={"https://www.objectiveed.com/videos"}>here!</a></Typography>
          <div className={classes.nextBtn}>
            <Typography>Or, check out one of our free training weebinars <a href={"https://www.objectiveed.com/free-training"}>here!</a></Typography>
             {/* <Button
              id='set-password'
              variant='contained'
              onClick={this.props.handleNext}
            >
              Next
            </Button> */}
          </div>
        </div></>
    )
  }
}
const mapState = ({ shared: { user } }) => {
  return {
    user,
  }
};
export default connect(mapState)(withStyles(styles)(SpecialistRegistrationPage4));