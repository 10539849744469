import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { IconButton, Tooltip } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import EditWizardBase from './EditWizardBase';
import { getStudentsForStaff,getStaffForStudent, clearErrors, fetchStudents } from '../../../../redux/actions';

class EditWizardButton extends Component {
  state = {
    open: false
  };

  closeWizard = () => {
    this.setState({ open: false });
    this.props.onClick();
    this.props.clearErrors();
    this.props.fetchStudents();
  };

  openWizard = () => {
    if (this.props.type === 'staff') {
      this.props.getStudentsForStaff(this.props.id);
    }
    if(this.props.type === 'student') {
      console.log('here');
      this.props.getStaffForStudent(this.props.id);
    }
    this.setState({ open: true });
  };

  render() {
    return (
      <div>
        <Tooltip title={<FormattedMessage id='General.edit'/>}>
          <IconButton
            aria-label={`edit ${this.props.type} ${this.props.data.name ? this.props.data.name : `${this.props.data.firstName} ${this.props.data.lastName}`}`}
            onClick={this.openWizard}>
            <Edit/>
          </IconButton>
        </Tooltip>
        <EditWizardBase
          history={this.props.history}
          id={this.props.id}
          type={this.props.type}
          data={this.props.data}
          open={this.state.open}
          close={this.closeWizard}
        />
      </div>
    )
  }
}

const mapState = ({ platform: { staffStudents, studentStaff }}) => {
  return {
    staffStudents,
    studentStaff
  }
};

const mapDispatch = (dispatch) => {
  return {
    getStudentsForStaff: (staffId) => dispatch(getStudentsForStaff(staffId)),
    getStaffForStudent: (studentId) => dispatch(getStaffForStudent(studentId)),
    clearErrors: () => dispatch(clearErrors()),
    fetchStudents: () => dispatch(fetchStudents()), 
  }
};

export default connect(mapState, mapDispatch)(EditWizardButton);