import React from 'react';
import StaffList  from '../../components/Admin/StaffList';
import StaffWizard from '../../components/Wizards/Staff/StaffWizardBase'
import { AccountBox } from '@material-ui/icons';
import { Typography, Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../styles/staff.js';
import { FormattedMessage } from 'react-intl';

const Staff = (props) => {
  const { history, classes, location } = props;
  return (
    <div>
      <div className={classes.title}>
        <AccountBox className={classes.icon} fontSize='inherit'/>
        <Typography className={classes.text} variant='h3'>
          <FormattedMessage id='StaffMembers.title'/>
        </Typography>
      </div>
      <Divider />
      <br />
      <StaffList location={location} history={history}/>
      <StaffWizard />
    </div>
  )
};

export default withStyles(baseStyle)(Staff);