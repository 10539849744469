import { Card, CardHeader, Divider, IconButton, Paper, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { BarChart as BarIcon, Close } from '@material-ui/icons';
import { Bar } from '@nivo/bar';
import Carousel from 'nuka-carousel';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getSevenDayTime, getStudent, getStudentProfile } from '../../../../redux/actions';
import baseStyle from '../../../../styles/wizardBase';

const minutesAxis = (value) => {
  let val = (Math.round(value) / 60.0).toFixed(0);
  let type = "Minutes";
  if (val > 60) {
    val = (value / 60.0).toFixed(1)
    type = "Hours";
  }
  return `${val}`
}
const minutes = (value) => {
  let val = (Math.round(value) / 60.0).toFixed(0);
  let type = "Minutes";
  if (val > 60) {
    val = (value / 60.0).toFixed(1)
    type = "Hours";
  }
  return `${val} ${type}`
};

class StaffRecord extends Component {

  state = {
    timeChart: [],
    time: 7,
  };

  componentDidMount() {
    getSevenDayTime(this.props.studentId).then(data => {
      if (data) {
        data = data.reverse();
        this.setState({ timeChart: data });
      }
    });

    this.props.getStudentProfile(this.props.studentId);
  }

  render() {

    const { classes, } = this.props;
    const targetValue = this.props.studentProfile ? this.props.studentProfile.readingTime * 60 : 15 * 60;
    let tickpoint = 0;
    const tickValues = [];
    while (tickpoint <= targetValue) {
      tickValues.push(tickpoint);
      tickpoint = tickpoint + 300;
    }
    tickValues.push(tickpoint);
    return (
      <Paper style={{ "width": "100%" }}>
        <div className={classes.container}>
          <Card aria-label="Last Seven Days Reading Time" >
            <CardHeader style={{ backgroundColor: '#0087b6' }} action={
              <IconButton onClick={this.props.closeWizard} className={classes.close} >
                <Close />
              </IconButton>}
              avatar = {  <BarIcon className={classes.icon} />}
              title = {<Typography className={classes.chartTitle} variant='h5'>Reading Time</Typography>}>
            </CardHeader>
            <Divider />
            <br />
            {this.state.timeChart.length > 0 &&
              <Carousel
                slideIndex='4'
                cellAlign="center">
                {
                  this.state.timeChart.map(weekData => {
                    return (
                      <div  className={classes.staffTimeReport} style={{marginLeft:"100px", paddingTop:"60px"}}>
                        <Bar
                          data={weekData}
                          keys={[
                            'secondsRead',
                          ]}
                          layers={['grid', 'axes', 'bars', 'markers', 'legends', 'annotations', () => {
                            const style = { fontWeight: 'bold' }
                            return (
                              <text
                                x="32%" dominantBaseline="middle" textAnchor="middle"
                                y={-18}
                                style={style}
                              >
                                {weekData[0]["range"]}
                              </text>
                            )
                          }]}
                          indexBy='date'
                          margin={{
                            top: 50,
                            right: 280,
                            bottom: 60,
                            left: 100
                          }}
                          markers={[
                            {
                              axis: 'y',
                              value: targetValue,
                              legend: "Target",
                              lineStyle: {
                                stroke: 'green',
                              },
                              textStyle: {
                                fill: 'green',
                              },
                            },
                          ]}
                          padding={0.05}
                          groupMode='grouped'
                          colorBy='time'
                          colors={['#0087b6']}
                          borderWidth={1}
                          borderColor='inherit:darker(1)'
                          axisLeft={{
                            tickValues: tickValues,
                            tickPadding: tickValues.length,
                            tickRotation: 0,
                            format: minutesAxis
                          }}
                          gridYValues={tickValues}
                          height={350}
                          width={1250}
                          enableGridY={true}
                          labelSkipWidth={12}
                          labelSkipHeight={12}
                          labelTextColor='#000'
                          animate={true}
                          motionStiffness={90}
                          motionDamping={15}
                          minValue={0}
                          maxValue={tickpoint}
                          labelFormat={minutes}
                          tooltip={(d) => (
                            <>
                              <Typography variant='h6'>{d.data.date}</Typography>
                              <Typography variant='subtitle2'><strong>Minutes:</strong> {(d.value / 60).toFixed(0)}</Typography>
                            </>
                          )}
                          theme={{
                            labels: {
                              text: { fontSize: 18, fontWeight: 800 }
                            },
                            axis: {
                              ticks: {
                                text: { fontSize: 12, fontWeight: 800 }
                              }
                            }
                          }}
                        />
                      </div>
                    )
                  })
                }
              </Carousel>
            }
          </Card>
        </div>

      </Paper>
    )
  }
}

const mapState = ({ platform: { studentProfile, student } }) => {
  return {
    student,
    studentProfile,
  }
};

const mapDispatch = (dispatch) => {
  return {
    getStudent: (id) => dispatch(getStudent(id)),
    getStudentProfile: (id) => dispatch(getStudentProfile(id)),
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(StaffRecord));