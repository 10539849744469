export default {
  title: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '55px',
    marginBottom: '20px'
  },
  text: {
    marginLeft: '20px',
    alignSelf: 'center'
  },
  icon: {
    padding: '5px',
    backgroundColor: '#0087b6',
    color: '#f9f3ff',
    fontSize: '3rem',
    alignSelf: 'center',
    borderRadius: '10px'
  },
  page: {
    padding: '10px 20px',
    margin: '20px 0',
  },
  line: {
    marginBottom: 10
  },
  addPageBtn: {
    width: 240,
    display: 'block',
    margin: '10px auto',
  },
  addBtn: {
    margin: '0 20px'
  },
  expansion: {
    display: 'grid'
  },
  questions: {
    gridRow: 1,
    margin: '20px 0'
  },
  guides: {
    gridRow: 2,
    margin: '20px 0'
  },
  question: {
    display: 'grid',
    gridAutoFlow: 'column',
    padding: '10px 20px',
    margin: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.07)',
  },
  editBtns: {
    justifySelf: 'end',
    alignSelf: 'center'
  },
  guide: {
    padding: '10px 20px',
    margin: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.07)',
    '& .icon': {
      paddingRight: 20
    },
  },
  content: {
    justifyContent: 'center'
  },
  pdf: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridAutoFlow: 'column',
    padding: '10px 20px',
    margin: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.07)',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#d5d5d5'
    },
    '& .content': {
      display: 'flex',
      flexGrow: 1,
      margin: '12px 0',
      paddingLeft: 24,
      '& .icon': {
        paddingRight: 20,
        textAlign: 'center'
      },
    },
  },
}