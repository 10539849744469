import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, withStyles } from '@material-ui/core';
import Slider from '../../../Social/NetflixSlider';
import styles from '../../../styles/browseStory.js';
import BookShareTable from '../Shared/BookShareTable';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { getDatabank } from '../../../redux/actions';
import Select from 'react-select';
import BookShareCredentialsLogin from './BookShareCredentialsLogin';

let GradeLevelList = [
    { value: 0, label: 'K' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 10, label: '+10'}
];

const rows = [
    { id: 'gradeLevel', label: <FormattedMessage id='General.gradeLevel' /> },
    { id: 'name', label: <FormattedMessage id='General.title' /> },
    { id: 'author', label: <FormattedMessage id='General.author' /> },
    { id: 'isbn', label: <FormattedMessage id='General.isbn' /> },
    { id: 'source', label: <FormattedMessage id='General.source' /> }
];

function DisplayStoryList({ id, databank, storyList, type, classes, dispatch, storyId, imported, user, closeWizard }) {
    const [noResultFound, setNoResultFound] = useState(false);
    const [dbank, setDbank] = useState([]);
    const [lexile, setLexile] = useState({
        value: 0,
        label: 'K'
    });
    const [loginRequired, setLoginRequired] = useState(null);

    useEffect(() => {
        if (databank.length >  0) {
        let newDB = filterDB();
            if (user.type == "Individual" && user.hasBookShareCredentials == false) {
                newDB = newDB.filter(data => data.source == "ObjectiveEd");
            }
        setDbank(newDB);
      }
    }, [])
    

    useEffect(() => {
        if (storyList.length) {
            setNoResultFound(true);
        }
    }, [storyList])

    useEffect(() => {
        if (type === 'LexileStory') {
            console.log('databank', databank);
        }
    }, [type])
    
    useEffect(() => {
        let newDB = filterDB();
        console.log(newDB);
        if (user.type == "Individual" && user.hasBookShareCredentials == false) {
            newDB = newDB.filter(data => data.source == "ObjectiveEd");
        }
        setDbank(newDB);
    }, [lexile])

    const filterDB = () => {
        let selectedValue = lexile.value
        let newDB = databank && databank.filter(db => {
            if(selectedValue === 10 ){
                return db.gradeLevel >= 10
            }
            else{
                return db.gradeLevel == selectedValue
            }
        })
        return newDB;
    }
    const RecomendList = (story, force) => {
        let styleRecommended = {};
        if ((user.type === "Individual" || user.type === "Teacher") && !user.hasBookShareCredentials && story.locked === 'Y') {
            styleRecommended = { pointerEvents: 'none', opacity: '0.2' }
        }
        return (
            <div style={{ backgroundColor: 'rgba(255,255,255,0.62)' }}>
                <Box sx={{ p: 2 }}>
                    <Typography variant='h4' component='h4' style={{ color: "#00729b" }}>
                        <b>{story.name}</b>
                    </Typography>
                    <div style={{ position: 'relative' }}>
                        {(user.type === "Individual" || user.type === "Teacher") && !(user.hasBookShareCredentials) && (story.locked === 'Y') &&
                            <Button className={classes.bookshareLoginButton} variant='contained' onClick={() => handleBookshareLogin()}>
                                Login to Bookshare to Access More Content.</Button>}
                        <Box className={classes.recomendListBox} style={styleRecommended}>
                            {
                                <Slider>
                                    {story.items && story.items.length > 0 && story.items.map(storyItem => (
                                        <Slider.Item movie={storyItem} storyHeader={story.name} id={id} key={storyItem.id}>item1</Slider.Item>
                                    ))}
                                </Slider>
                            }
                        </Box>
                    </div>
                </Box>
            </div>
        )
    }

    const ReadingList = () => {
        return (
            <div style={{ minHeight: '395px', textAlign: 'center' }}>Reading List</div>
        )
    }
    
    const handleChangeLexileLevel = (e) => {
        console.log(e);
        setLexile(e.target.value);
    }
    const handleBookshareLogin = () => {
        setLoginRequired(true);
    }
    const closeLoginModal = () => {
        setLoginRequired(false);
    }
    return (
        <div
        // style={{ backgroundColor: '#d3e6ff' }}
        >
            {type === 'Recomendations' &&
                storyList.constructor === Array && storyList.length > 0 &&
                storyList.map((story, index) => {
                    // var force = false;
                    // if(index<=2) force = true;
                    if(user.hasBookShareCredentials){
                        return story.locked === 'Y' && RecomendList(story)
                    }
                    else{
                         return RecomendList(story)
                    }
                })
            }
            {
                type === 'LexileStory' &&
                databank.constructor === Array && databank.length > 0 &&
                <div>
                    <div className={classes.lexileWrap}>
                        <Select
                            styles={{
                                control: (styles) => ({ ...styles, minHeight: 56 }),
                            }}
                            className={classes.lexileSelect}
                            defaultValue={0}
                            menuPlacement='bottom'
                            // placeholder='Select lexile level'
                            maxMenuHeight={240}
                            value={lexile}
                            options={GradeLevelList}
                            onChange={(ev) => handleChangeLexileLevel({ target: { name: 'lexile', value: ev }})}
                        />
                    </div>
                    <BookShareTable
                        rows={rows}
                        data={dbank}
                        importSkill={null}
                        includeTitleImage={false}
                        imported={imported}
                        noResultFound={noResultFound}
                        lexileTabClicked={true}
                        storyId={storyId}
                        closeWizard={()=>{closeWizard()}}
                    />
                </div>

            }
            {
                type === 'StudentStory' &&
                ReadingList()
            }
            {loginRequired && <BookShareCredentialsLogin
                openLoading={loginRequired}
                exitFunc={closeLoginModal} />}
        </div>

    )
}

const mapState = ({ platform: { databank }, shared: { user } }) => {
    return {
        databank,
        user
    }
};

export default connect(mapState)(withStyles(styles)(DisplayStoryList));