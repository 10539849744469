import {
  ROOT_URL,
  FETCH_ALL_SKILL_DATABANKS,
  CREATE_SKILL_DATABANK,
  DELETE_SKILL_DATABANK,
  PATCH_SKILL_DATABANK,
  HANDLE_ERROR,
  PATCH_STAFF_SKILL_DATABANK,
  DELETE_STAFF_SKILL_DATABANK,
  CREATE_STAFF_SKILL_DATABANK,
  FETCH_STAFF_SKILL_DATABANKS,
  FETCH_STORY_DATA,
  UPDATE_STORY_DATA,
  FETCH_STORY_DATA_SECTION,
  UPDATE_STORY_DATA_SECTION
} from '../../constants';
import axios from 'axios';

export const fetchSkillDatabanks = (accountId, history) => {
  const url = `${ROOT_URL}/admin/objective-databank?accountId=${accountId}`;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        if (typeof(response.data) === 'object'){
          dispatch({
            type: FETCH_ALL_SKILL_DATABANKS,
            skillDatabanks: response.data
          })
        }
        else {
          history.push('/login');
          dispatch({
            type: HANDLE_ERROR,
            error: {type: 'sessionExp', msg: 'Your session has expired, please log back in'}
          })
        }
      })
      .catch(err => console.log(err))
  }
};

export const fetchUserSkillDatabanks = (includeSelf, history) => {
  const url = `${ROOT_URL}/objective-databank?includeSelf=${includeSelf}`;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        if (typeof(response.data) === 'object'){
          dispatch({
            type: FETCH_ALL_SKILL_DATABANKS,
            skillDatabanks: response.data
          })
        }
        else {
          history.push('/login');
          dispatch({
            type: HANDLE_ERROR,
            error: {type: 'sessionExp', msg: 'Your session has expired, please log back in'}
          })
        }
      })
      .catch(err => console.log(err))
  }
};

export const createSkillDatabank = (databank, accountId) => {
  const url = `${ROOT_URL}/admin/objective-databank?accountId=${accountId}`;
  return (dispatch) => {
    axios
      .post(url, databank)
      .then((response) => {
        dispatch({
          type: CREATE_SKILL_DATABANK,
          skillDatabank: response.data
        })
      })
      .catch(err => console.log(err))
  }
};

export const patchSkillDatabank = (skillDatabank) => {
  const url =`${ROOT_URL}/admin/objective-databank`;
  return (dispatch) => {
    axios
      .patch(url, skillDatabank)
      .then(() => {
        dispatch({
          type: PATCH_SKILL_DATABANK,
          skillDatabank
        })
      })
      .catch(err => console.log(err))
  }
};

export const deleteSkillDatabank = (skillDatabank, account) => {
  const url = `${ROOT_URL}/admin/objective-databank?accountId=${account.id}`;
  return (dispatch) => {
    axios
      .delete(url, { data: skillDatabank })
      .then(() => {
        dispatch({
          type: DELETE_SKILL_DATABANK,
          skillDatabank
        })
      })
      .catch(err => console.log(err))
  }
};

export const fetchStaffSkillDatabanks = (staffId, history) => {
  const url =`${ROOT_URL}/staff/${staffId}/objective-databank`;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        if (typeof(response.data) === 'object'){
          dispatch({
            type: FETCH_STAFF_SKILL_DATABANKS,
            skillDatabanks: response.data
          })
        }
        else {
          history.push('/login');
          dispatch({
            type: HANDLE_ERROR,
            error: {type: 'sessionExp', msg: 'Your session has expired, please log back in'}
          })
        }
      })
      .catch(err => console.log(err))
  }
};

export const createStaffSkillDatabank = (databank, staffId) => {
  const url = `${ROOT_URL}/staff/${staffId}/objective-databank`;
  return (dispatch) => {
    axios
      .post(url, databank)
      .then((response) => {
        dispatch({
          type: CREATE_STAFF_SKILL_DATABANK,
          skillDatabank: response.data
        })
      })
      .catch(err => console.log(err))
  }
};

export const patchStaffSkillDatabank = (skillDatabank, staffId) => {
  const url = `${ROOT_URL}/staff/${staffId}/objective-databank`;
  return (dispatch) => {
    axios
      .patch(url, skillDatabank)
      .then(() => {
        dispatch({
          type: PATCH_STAFF_SKILL_DATABANK,
          skillDatabank
        })
      })
      .catch(err => console.log(err))
  }
};

export const deleteStaffSkillDatabank = (skillDatabank, staffId) => {
  const url = `${ROOT_URL}/staff/${staffId}/objective-databank`;
  return (dispatch) => {
    axios
      .delete(url, { data: skillDatabank })
      .then(() => {
        dispatch({
          type: DELETE_STAFF_SKILL_DATABANK,
          skillDatabank
        })
      })
      .catch(err => console.log(err))
  }
};

export const fetchStoryData = () => {
  const url = `${ROOT_URL}/buddy-books/admin/story-data`;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: FETCH_STORY_DATA,
          data: response.data
        })
      })
      .catch(err => console.log(err))
  }
};
export const updateStoryData = (storyDataDto) => {
  const url = `${ROOT_URL}/buddy-books/admin/story-data`;
  return (dispatch) => {
    axios
      .put(url, storyDataDto)
      .then((response) => {
        dispatch({
          type: UPDATE_STORY_DATA,
          data: response.data
        })
        console.log("story updated", response.data);
      })
      .catch(err => console.log(err))
  }
};

export const fetchStoryDataSections = (storyDataId) => {
  const url = `${ROOT_URL}/buddy-books/admin/story-data/${storyDataId}/sections`;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: FETCH_STORY_DATA_SECTION,
          data: response.data
        })
      })
      .catch(err => console.log(err))
  }
};

export const fetchBookPreview = (storyDataId) => {
  const url = `${ROOT_URL}/buddy-books/admin/story-data/${storyDataId}/preview`;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: FETCH_STORY_DATA_SECTION,
          data: response.data
        })
      })
      .catch(err => console.log(err))
  }
};

export const updateStoryDataSections = (storyDataId,data) => {
  const url = `${ROOT_URL}/buddy-books/admin/story-data/${storyDataId}/sections`;
  return (dispatch) => {
    axios
      .put(url,data)
      .then((response) => {
        dispatch({
          type: UPDATE_STORY_DATA_SECTION,
          data: response.data
        })
      })
      .catch(err => console.log(err))
  }
};
export const getSectionAudioFile = (fileId, text) => {
  const url = `${ROOT_URL}/buddy-books/admin/audio/${fileId}`;
  let data = { text: text };
  return axios
    .post(url, data, { responseType: 'blob' })
    .then((response) => {
      return response.data;
    })
    .catch(err => console.log(err))
};
export const refreshStoryData = (storyDataId) => {
  const url = `${ROOT_URL}/buddy-books/admin/story-data/${storyDataId}/refresh`;
  return (dispatch) => {
    axios
      .post(url)
      .then((response) => {
        dispatch({
          type: UPDATE_STORY_DATA,
          data: response.data
        })      
      })
      .catch(err => console.log(err))
  }
};