import React, { Component } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  TextField,
  withStyles, MenuItem, FormGroup, Checkbox
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import baseStyle from '../../../../styles/wizardBase';
import { FormattedMessage } from 'react-intl';

class SimonLevelTemplate extends Component {

  state={
    startingGestures: '',
    numberOfRounds: '',
    weightedGestureSpawn: '',
    difficultyProgression: '',
    gestureSet: []
  };

  close = () => {
    this.props.close();
  };

  onSubmit = () => {
    const weightedGestureSpawn = this.state.weightedGestureSpawn.indexOf('[') === 0
      ? JSON.parse(this.state.weightedGestureSpawn)
      : JSON.parse(`[${this.state.weightedGestureSpawn}]`);
    const gestureSet = JSON.stringify(this.state.gestureSet);
    const level = { levelVariables: JSON.stringify({...this.state, weightedGestureSpawn, gestureSet}) };
    this.props.onSubmit(level)
  };

  handleChange = (ev) => {
    const name = ev.target.name;
    const value = ev.target.type === 'number' ? ev.target.value*1 : ev.target.value;
    this.setState({ [name]: value })
  };

  formatName = (name) => {
    return name
      .replace(/"/g, '')
      .replace(/([A-Z0-9])/g, ` $1`)
      .replace(/^./, (str) => str.toUpperCase())
  };

  handleChangeGesture = (ev) => {
    const value = ev.target.checked;
    const gesture = value && ev.target.name;
    this.setState(prevState => ({ gestureSet: [...prevState.gestureSet, gesture] }));
  };

  render() {
    const gestures = [
      "swipeUp1",
      "swipeUp2",
      "swipeDown1",
      "swipeDown2",
      "swipeLeft1",
      "swipeLeft2",
      "swipeRight1",
      "swipeRight2",
      "tap1",
      "tap2",
      "doubleTap1",
      "doubleTap2",
      "shake",
      "twistLeft",
      "twistRight",
      "rotation",
      "scrub"
    ];
    const { classes, open, close, back } = this.props;
    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        open={open}
        onClose={close}
        aria-labelledby='level-dialog-title'
        PaperProps={{ classes:{ root: classes.container }}}
      >
        <DialogTitle disableTypography={true} className={ classes.title } id='level-dialog-title'>
          <Typography className={ classes.titleText } variant='h4'><FormattedMessage id='Level.title'/></Typography>
          <IconButton
            onClick={this.close}
            className={ classes.exitIcon }
            aria-label="close dialog"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <Typography className={classes.subtitle} variant='h6'><FormattedMessage id='Level.add'/> {this.props.game.name} <FormattedMessage id='Level.add2'/>:</Typography>
        <DialogContent>
          <FormGroup className={classes.root}>
            <div className={classes.configureLine}>
              <strong className={classes.configTitle}> <FormattedMessage id='Wizard.Gesture.startingCount'/>: </strong>
              <TextField
                variant='outlined'
                className={classes.configComponent}
                name='startingGestures'
                type='number'
                value={this.state.startingGestures}
                onChange={this.handleChange}
              />
            </div>
            <div className={classes.configureLine}>
              <strong className={classes.configTitle}><FormattedMessage id='Wizard.Gesture.numRounds'/>: </strong>
              <TextField
                variant='outlined'
                className={classes.configComponent}
                name='numberOfRounds'
                type='number'
                value={this.state.numberOfRounds}
                onChange={this.handleChange}
              />
            </div>
            <div className={classes.configureLine}>
              <strong className={classes.configTitle}> <FormattedMessage id='Level.Gesture.spawn'/>: </strong>
              <TextField
                variant='outlined'
                className={classes.configComponent}
                name='weightedGestureSpawn'
                value={this.state.weightedGestureSpawn}
                onChange={this.handleChange}
              />
            </div>
            <div className={classes.configureLine}>
              <strong className={classes.configTitle}> <FormattedMessage id='Wizard.Gesture.difficulty'/>: </strong>
              <TextField
                variant='outlined'
                className={classes.configComponent}
                name='difficultyProgression'
                value={this.state.difficultyProgression}
                onChange={this.handleChange}
              />
            </div>
            <div className={classes.configureLine}>
              <strong className={classes.configTitle}><FormattedMessage id='Wizard.Gesture.gestures'/>: </strong>
              <br />
              {
                gestures.map(gesture => (
                  <MenuItem key={gesture} value={gesture}>
                    <Checkbox
                      name={gesture}
                      checked={this.state.gestureSet.includes(gesture)}
                      onChange={this.handleChangeGesture}
                    />
                    {this.formatName(gesture)}
                  </MenuItem>
                ))
              }
            </div>
          </FormGroup>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button onClick={back} color='secondary'>
            <FormattedMessage id='General.back'/>
          </Button>
          <Button onClick={this.onSubmit} variant='contained' color='primary'>
            <FormattedMessage id='General.submit'/>
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(baseStyle)(SimonLevelTemplate);