import React from 'react';
import { connect } from 'react-redux';
import WizardToggleButton from "../WizardToggleButton";
import SliderSeriesManagementPage1 from './SliderSeriesManagementPage1';
import { withStyles } from '@material-ui/core/styles';
import { createSliderSeries } from '../../../../redux/actions';
import styles from '../../../../styles/wizardBase';
import { FormattedMessage } from 'react-intl';

class SliderSeriesManagementWizardBase extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      open: false,
      page: 1,
      name: '',
      description: '',
    }
  }

  openWizard = () => {
    this.setState({ open: true })
  };

  closeWizard = () => {
    this.setState({ open: false, page: 1, name: '', description: '' });
  };

  next = () => {
    this.setState({ page: this.state.page+1 });
  };

  back = () => {
    this.setState({ game: {}, page: this.state.page > 1 ? this.state.page-1 : 1 });
  };

  handleChange = (ev) => {
    this.setState({ [ev.target.name]: ev.target.value })
  };

  submit = () => {
    const { name, description } = this.state;
    this.props.createSliderSeries({name, description });
    this.closeWizard();
  };

  render(){
    const { page, open } = this.state;
    return (
      <div>
        {
          page === 1 &&
          <SliderSeriesManagementPage1
            open={open}
            close={this.closeWizard}
            submit={this.submit}
            handleChange={this.handleChange}
          />
        }
        <WizardToggleButton
          openWizard={this.openWizard}
          text={<FormattedMessage id='SliderSeriesManagement.Add'/>}
        />
   
      </div>
    )
  }
}

const mapState = ({ platform: {  }}) => {
  return {
  }
};

const mapDispatch = (dispatch, { history }) => {
  return {
    createSliderSeries: (slider) => dispatch(createSliderSeries(slider))
  }
};

export default connect(mapState, mapDispatch)(withStyles(styles)(SliderSeriesManagementWizardBase));