import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    ROOT_URL
} from '../../../redux/constants';
import moment from 'moment';
import classNames from 'classnames';
import PercentToTarget from '../../components/Charts/PercentToTargetBar';
import Logo from '../../../assets/BrandAssets/Logo/OBJECTIVEED-Logo-Vertical-1.png';
import { InsertChartOutlined } from '@material-ui/icons';
import { Divider, Typography, Button, IconButton} from '@material-ui/core';
import { getStudent, hoursPlayedPerGame, hoursPlayedPerSkill, getScoreChartsDirect, getSkillsForGoal, getStudentGoals, percentToTargetLine, percentToTarget, brailleAIChart, brailleSheetsChart, getAIAudioFile } from '../../../redux/actions';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../styles/dashboard';
import HoursPlayedPie from '../../components/Charts/HoursPlayedPie';
import PercentToTargetLine from '../../components/Charts/PercentToTargetLine';
import { FormattedMessage } from 'react-intl';
import BrailleAIChart from '../../components/Charts/BrailleAIChart';
import BrailleSheetsChart from '../../components/Charts/BrailleSheetsChart';
import { Link } from 'react-router-dom';
import LineChart from '../../components/Charts/LineChart';
import ListChart from '../../components/Charts/ListChart';
import BarChart from '../../components/Charts/BarChart';
import { Print, ArrowBack } from '@material-ui/icons';

import axios from 'axios';

const pageBreakStyle = {
    pageBreakBefore: 'always',
    pageBreakInside: 'avoid'
}
const CollisionLink = (url) => React.forwardRef((props, ref) => (
    <Link innerRef={ref} to={url} {...props} />
));

class StudentDashboard extends Component {

    printPage = () => {
        return (
            <Button onClick={window.print}>Print</Button>
        );
    }

    state = {
        hoursPlayed: [],
        percentToTargetBar: [],
        percentToTarget: [],
        skillChartData: {},
        time: 7,
        clicked: false,
        skills: [],
    };

    componentDidMount() {
        this.props.getStudent(this.props.id);
        this.props.getStudentGoals(this.props.id);
        percentToTarget(this.props.id).then((response) => {
            const data = response || [];
            var dataSets = [];
            while (data.length > 3) {
                dataSets.push(data.splice(0, 3));
            }
            dataSets.push(data);
            this.setState({percentToTargetBar: dataSets})
        });
        percentToTargetLine(this.props.id).then(data => {
            this.setState({percentToTarget: data})
        });
    }

    componentWillReceiveProps(nextProps, nextContext){
        console.log(nextProps.goals);
        if (nextProps.goals !== this.props.goals) {
            for(const goal of nextProps.goals) {
                if(goal.status === 'Active') {
                    const url = `${ROOT_URL}/goals/${goal.id}/skills`;
                    axios.get(url).then((response) => this.addSkillCharts(response.data))
                }
            }
        }
    }

    addSkillCharts(skillsList) {
        for(const skill of skillsList) {
            if(skill.status==='Active') {
                this.setState(state => {
                    const newSkills = this.state.skills.concat(skill);
                    return {
                        skills: newSkills
                    }
                });
                const url = `${ROOT_URL}/students/${this.props.id}/objectives/${skill.id}/scores/charts`;
                axios.get(url).then(response => {
                    this.setState(state => {
                        const skillChartData = state.skillChartData;
                        skillChartData[skill.id] = response.data;
                        return {
                            ...skillChartData
                        };
                    });
                });
            }
        }
    }

    renderChartsTest = (data) => {
        const charts = data.map((chart, index) => {
            if(chart.dataSet.length) {
                switch (chart.chartType) {
                    case 'Line':
                        return <LineChart suppressStyle={true} key={index} chart={chart} title={chart.title}/>;
                    case 'Bar':
                        return <BarChart suppressStyle={true} key={index} chart={chart} title={chart.title}/>;
                    default:
                        return null;
                }
            }
        });
        return charts;
    }

    render() {
        const {classes, student, history} = this.props;
        const {skillChartData, percentToTargetBar, percentToTarget, skills} = this.state;
        console.log("Skills: ",skills);
        return (
            <div className={classes.container}>
                <div style={{ display: "flex", width:"100%" }}>
                    <div style={{ position:"absolute",
                        right: "0px",
                        top: "0px"}}>
                        <IconButton
                            aria-label='home'
                            color='inherit'
                            component={CollisionLink('/students/'+this.props.id)}
                        >
                            <ArrowBack />
                        </IconButton>
                        <IconButton
                            aria-label='home'
                            color='inherit'
                            onClick = {()=> window.print()}
                        >
                            <Print />
                        </IconButton>
                    </div>
                    <div style={{marginLeft: "auto"}}>
                    <img style={{marginLeft: "auto"}} className={classNames(classes.logo, {[classes.hidden]: false})} alt='logo' src={Logo}/>
                    </div>
                </div>

                <Typography className={classes.text} variant='h5'>
                        <FormattedMessage id='Progress.Title'/>

                    </Typography>
                <Typography className={classes.text} variant='h5'>
                    <FormattedMessage id='Progress.Student'/> {student.firstName} {student.lastName}
                </Typography>
                <br/>
                <Divider/>
                <br/><br/>
                <Typography className={classes.text} variant='h5'>
                    <FormattedMessage id='Progress.PercentToTargetLineTitle'/>
                </Typography>
                <br/><br/>
                <div className={classes.chart}>
                    <PercentToTargetLine
                        suppressStyle={true}
                        title={<FormattedMessage id='Chart.percentLine.title'/>}
                        data={this.state.percentToTarget}
                        suppressFluff={true}
                    />
                </div>
                {skillChartData && Object.keys(skillChartData).map((k,v) => (
                    <div>
                    { skillChartData[k][0] && skillChartData[k][0].dataSet.length ?
                    <div style={pageBreakStyle}>
                    <Typography className={classes.text} variant="h5">
                    <FormattedMessage id='Progress.Skill'/> {skills.find(skill => skill.id == k).name}
                    </Typography><br/><br/>
                    {this.renderChartsTest(skillChartData[k])}
                    <br/><br/>
                    </div> : <div/>
                }
                    </div>
                    )
                )}
            </div>
            )
    }
}

const mapState = ({platform: {student, goals}}) => {
    return {
        student,
        goals
    }
};

const mapDispatch = (dispatch, {history}) => {
    return {
        getStudentGoals: (studentId) => dispatch(getStudentGoals(studentId, history)),
        getStudent: (id) => dispatch(getStudent(id))
    }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(StudentDashboard));