import React from 'react';
import { Dialog, IconButton, Paper, withStyles } from '@material-ui/core';
import {BOOKSHARE_OAUTH} from '../../../redux/constants';
import {bookShareLoginV2} from '../../../redux/actions/authentication';
import { Close } from '@material-ui/icons';
import styles from '../../../styles/importSkill.js';
import Login from '../Auth/Login.jsx';
import axios from 'axios';
import { connect } from 'react-redux';

function BookShareCredentialsLogin({ classes, openLoading, exitFunc,dispatch, }) {
    const url = `${BOOKSHARE_OAUTH}?response_type=token&client_id=ut7fvyalnyxkuumeijbm8vx4&redirect_uri=https://platform.objectiveed.com%2Fbookshare%2Flogin&scope=basic&state=something&client_secret`;
    var left = window.screenX + (window.outerWidth - 500) / 2;
    var top = window.screenY + (window.outerHeight - 500) / 2.5;
    var externalPopup = window.open(url, '', "width=".concat(500, ",height=").concat(500, ",left=").concat(left, ",top=").concat(top));
    var popup = window.addEventListener("message", (event) => {
        if(event.origin=="https://platform.objectiveed.com") {
            console.log(event.data);
            var data = JSON.parse(event.data)
            dispatch(bookShareLoginV2(data.access_token, data.expiration));
        }
    }, false);
    var timer = setInterval( () => {
        if(externalPopup && externalPopup.closed){
            exitFunc && exitFunc();
            clearInterval(timer);
        }
    },1000);
    return (
        <div>
        {/*<Dialog open={openLoading} maxWidth='xl'>*/}
        {/*    <Paper className={classes.importLoadingPage}>*/}
        {/*        <IconButton*/}
        {/*            onClick={() => { exitFunc() }}*/}
        {/*            className={classes.exitBookShareIcon}*/}
        {/*            aria-label="close dialog"*/}
        {/*        >*/}
        {/*            <Close />*/}
        {/*        </IconButton>*/}
        {/*        <Login isBookShareLogin={true} />*/}
        {/*    </Paper>*/}
        {/*</Dialog>*/}
        </div>
    )
}

export default connect(null,null)(withStyles(styles)(BookShareCredentialsLogin));