import { FETCH_MY_GOALS, GET_GROUP, ROOT_URL } from '../../constants';
import axios from 'axios';

// Fetches all goals in user's groups
export const fetchMyGoals = () => {
  const url = `${ROOT_URL}/profiles/all-goals`;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: FETCH_MY_GOALS,
          goals: response.data
        })
      })
      .catch(err => console.log(err))
  }
};

export const shareGoal = (goal, comment, group) => {
  const sharedGoal = { goalDto: goal, comment, group };
  const url = `${ROOT_URL}/goals/${goal.id}/share`;
  return (dispatch) => {
    axios
      .post(url, sharedGoal)
      .then(() => {
        dispatch({
          type: GET_GROUP,
          group
        })
      })
      .catch(err => console.log(err))
  }
};

export const toggleGoalLike = (goal, liked) => {
  const url = `${ROOT_URL}/goals/${goal.key}/${liked ? 'unlike' : 'like'}`;
  return (dispatch) => {
    axios
      .post(url)
      .catch(err => console.log(err))
  }
};