import React, { Component } from 'react';
import { Button, Typography, IconButton, FormGroup } from '@material-ui/core';
import { Dialog, DialogContent, DialogTitle, DialogContentText } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../../styles/wizardBase';
import { FormattedMessage } from 'react-intl';

class SelfGuidedPage1 extends Component {

  render()  {
    const { classes, skill } = this.props;
    return (
      <>
        <Dialog
          disableEscapeKeyDown
          fullWidth={true}
          open={this.props.open}
          onClose={this.props.close}
          aria-labelledby='form-dialog-title'
          PaperProps={{classes:{ root: classes.container }}}
          scroll='paper'
        >
          <DialogTitle disableTypography={true} className={classes.title} id='form-dialog-title'>
            <Typography className={classes.titleText} variant='h4'>
              <FormattedMessage id="Wizard.skillDB.title"/>
            </Typography>
            <IconButton
              onClick={this.props.close}
              className={ classes.exitIcon }
              aria-label="close dialog"
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.container}>
            <FormGroup classes={{root: classes.brailleRoot}}>
              {
                skill &&
                <DialogContentText align='center' variant='h6'>GREAT! We added "{skill.name}" to your skills</DialogContentText>
              }
              <Typography align='center' gutterBottom variant='h6'>This skill is:  </Typography>
              <Button className={classes.selectButtons} onClick={() => this.props.next('single')} variant='contained' color='primary'>
                For a specific student
              </Button>
              <Button className={classes.selectButtons} onClick={() => this.props.next('multiple')} variant='contained' color='primary' >
                To be used for several students
              </Button>
              <Button onClick={this.props.close}>
                no thanks, I'll come back to this later
              </Button>
            </FormGroup>
          </DialogContent>
        </Dialog>
      </>
    )
  }
}

export default withStyles(baseStyle)(SelfGuidedPage1);