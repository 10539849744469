import React, { Component } from 'react';
import { Button, Typography, IconButton, FormGroup } from '@material-ui/core';
import { Dialog, DialogContent, DialogTitle, DialogContentText } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../../styles/wizardBase';
import { FormattedMessage } from 'react-intl';
import DataTable from '../../Shared/DataTable';

class SelfGuidedPage2 extends Component {

  render()  {
    const rows = [
      { id:'firstName', label: <FormattedMessage id="General.firstName"/>, searchable: true },
      { id:'lastName', label: <FormattedMessage id="General.lastName"/>, searchable: true },
      { id:'gamerTag', label: 'User Name', searchable: true }
    ];
    const { classes } = this.props;
    return (
      <>
        <Dialog
          disableEscapeKeyDown
          fullWidth={true}
          open={this.props.open}
          onClose={this.props.close}
          aria-labelledby='form-dialog-title'
          PaperProps={{classes:{ root: classes.container }}}
          scroll='paper'
        >
          <DialogTitle disableTypography={true} className={classes.title} id='form-dialog-title'>
            <Typography className={classes.titleText} variant='h4'>
              <FormattedMessage id="Wizard.skillDB.title"/>
            </Typography>
            <IconButton
              onClick={this.props.close}
              className={ classes.exitIcon }
              aria-label="close dialog"
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.container}>
            <FormGroup classes={{root: classes.brailleRoot}}>
              <DialogContentText align='center' variant='h6'>Which student is this for?</DialogContentText>
              <DataTable
                staffId={this.props.id}
                type='staffStudentGoal'
                clickable
                rows={rows}
                rowsPerPage={[5,10,15]}
                orderBy='firstName'
                data={this.props.students}
                numCols={2}
                skill={this.props.skill}
                history={this.props.history}
              />
            </FormGroup>
          </DialogContent>
        </Dialog>
      </>
    )
  }
}

export default withStyles(baseStyle)(SelfGuidedPage2);