export default {
  header: {
    backgroundColor: '#484ca0',
    fontSize: '24px',
    height: '80px',
    width: '100%',
    display: 'grid',
    justifyContent: 'center',
    gridTemplateColumns: '3fr 5fr 3fr'
  },
  title: {
    marginLeft: '300px',
    gridColumn: 1,
    alignSelf: 'center',
    color: '#f9f3ff',
  },
  search: {
    gridColumn: 2,
    justifySelf: 'center',
    padding: '10px',
    width: '90%',
    alignSelf: 'center'
  },
  buttons: {
    gridColumn: 3,
    alignSelf: 'center',
    justifySelf: 'end',
    marginRight: '40px',
  },
  searchIcon: {
    color: '#310073'
  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: '#310073',
    },
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: '#310073',
    },
    color: '#310073',
      backgroundColor: '#f9f3ff',
      borderColor: '#310073',
      borderRadius: '4px'
  },
  notchedOutline: {},
  link: {
    color: '#f9f3ff',
    textDecoration: 'none',
      '&:focus &:hover &:visited &:link &:active': {
      textDecoration: 'none'
    }
  },
  icon: {
    padding: 5,
    backgroundColor: '#0087b6',
    color: '#fff',
    alignSelf: 'center',
    borderRadius: 10,
    marginRight: 10
  },
  app: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignContent: 'center'
  },
  appIcon: {
    padding: 5,
    backgroundColor: '#0087b6',
    color: '#fff',
    alignSelf: 'center',
    justifySelf: 'center',
    borderRadius: 10,
    marginRight: 10
  },
  appText: {
    alignSelf: 'center',
  },
  helpMenu: {
    padding: 10,
    width: 380
  },
  helpText: {
    marginTop: 10
  },
  guide: {
    padding: '12px 24px',
    marginTop: 10,
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      cursor: 'pointer'
    }
  },
  guideLink: {
    textDecoration: 'none',
    color: 'inherit',
    '&:focus &:hover &:visited &:link &:active': {
      textDecoration: 'none'
    }
  },
  guideText: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignContent: 'center',
    justifySelf: 'start',
    gridColumnGap: 10,
    '& .icon': {
      justifySelf: 'start',
      alignSelf: 'center'
    }
  },
  section: {
    marginTop: 10
  },
  videoDialog: {
    maxWidth: 1800
  }
}