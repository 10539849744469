export default {
  container:{
    margin: '0 5px',
    padding: 10,
    borderRadius: 4,
    marginBottom: 20,
    border: '1px solid #310073',
    backgroundColor: '#45458d',
    color: 'white',
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
  },
  list: {
    marginTop: 20,
    display: 'grid',
    gridRowGap: '20px'
  },
  title: {
    display: 'grid',
    gridTemplateColumns: '1fr 4fr 1fr',
    '& .title':{
      marginLeft: 5,
      color: 'white',
      gridColumn: 1,
      justifySelf: 'start',
      alignSelf: 'center'
    },
    '& .search': {
      color: 'white',
      backgroundColor: '#fff',
      borderRadius: 4
    },
    '& button': {
      marginRight: 5,
      gridColumn: 3,
      justifySelf: 'end',
    }
  },
  border: {
    borderColor: 'black'
  }
}