import {
  CREATE_STAFF_MEMBER,
  FETCH_ALL_STAFF_MEMBERS,
  GET_STAFF_MEMBER,
  GET_STAFF_STUDENTS,
  HANDLE_ERROR,
  PATCH_STAFF_MEMBER,
  DELETE_STUDENT_FROM_STAFF,
  ROOT_URL,
  DELETE_STAFF_MEMBER,
  GET_STAFF_PROFILE,
    GET_STAFF_ROLES,
  ADD_STAFF_STUDENT,
  GET_STAFF_DASHBOARD,
  NO_STAFF_STUDENT_RECORD_FOUND,
  GET_STAFF_DASHBOARD_ACCURACY,
  GET_STAFF_DASHBOARD_READING_TIME
} from '../../constants';
import axios from 'axios';

axios.defaults.withCredentials = true;

export const fetchStaff = (history) => {
  const url = `${ROOT_URL}/admin/staff`;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        if (typeof(response.data) === 'object'){
          dispatch({
            type: FETCH_ALL_STAFF_MEMBERS,
            staff: response.data
          })
        }
        else {
          history.push('/login');
          dispatch({
            type: HANDLE_ERROR,
            error: {type: 'sessionExp', msg: 'Your session has expired, please log back in'}
          })
        }
      })
      .catch(err => console.error(err))
  }
};

export const getStaffById = (id) => {
  const url = `${ROOT_URL}/staff/${id}`;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_STAFF_MEMBER,
          staffMember: response.data
        })
      })
      .catch(err => console.log(err))
  }
};

export const getStudentsForStaff = (staffId) => {
  const url = `${ROOT_URL}/staff/${staffId}/students`;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_STAFF_STUDENTS,
          students: response.data
        })
      })
  };
};

export const createStaffMember = (staffMember) => {
  const url = `${ROOT_URL}/admin/staff`;
  return (dispatch) => {
    axios
      .post(url, staffMember)
      .then((response) => {
        console.log(response);
        dispatch({
          type: CREATE_STAFF_MEMBER,
          staffMember: response.data
        });
        return response.data
      })
      .catch(err => console.log({err}))
  }
};

export const createStaffMemberAsUser = (staffMember, user, role, students) => {
  const userRole = {
    id: role.id,
    name: role.name,
    ownerId: role.ownerId,
    description: role.description,
    homePage: role.homePage,
    priority: role.priority
  };
  const url = `${ROOT_URL}/admin/completeUser`;
  return (dispatch) => {
    axios
      .post(url, { staffDto: staffMember, userDto: user, role: userRole })
      .then((response) => {
        dispatch({
          type: CREATE_STAFF_MEMBER,
          staffMember: response.data.staffDto
        });
        return response.data
      })
      .then(({staffDto}) => {
        if (students) {
          return axios
            .post(`${ROOT_URL}/staff/${staffDto.id}/students`, students)
            .catch(err => console.log(err))
        }
      })
  }
};

export const promoteStaff = (id) => {
    const url = `${ROOT_URL}/staff/${id}/promote`;
    return (dispatch) => {
        axios
            .post(url)
            .then((response) => {
                dispatch({
                    type: PATCH_STAFF_MEMBER,
                    staffMember: response.data
                })
            })
            .catch(err => console.log(err))
    }
};

export const patchStaffMember = (staffMember, students) => {
  const url = `${ROOT_URL}/staff`;
  const { type, firstName, lastName, id, userId, email } = staffMember;
  return (dispatch) => {
    axios
      .patch(url, { type, firstName, lastName, id, userId, email })
      .then(() => {
        return dispatch({
          type: PATCH_STAFF_MEMBER,
          staffMember
        })
      })
      .then(() => {
        if (students) {
          return axios
            .put(`${ROOT_URL}/staff/${staffMember.id}/students`, students)
            .catch(err => console.log(err))
        }
      })
      .catch(err => console.log(err))
  }
};

export const deleteStudentFromStaff = (student, staffId) => {
  const url = `${ROOT_URL}/staff/${staffId}/students`;
  return (dispatch) => {
    axios
      .delete(url, { data: [student]})
      .then(() => {
        return dispatch({
          type: DELETE_STUDENT_FROM_STAFF,
          student
        })
      })
      .catch(err => console.log(err))
  }
};

export const deleteStaffFromAccount = (accountId, staffDto) => {
  const url = `${ROOT_URL}/staff?accountId=${accountId}`;
  return (dispatch) => {
    axios
      .delete(url, { data: staffDto })
      .then(() => {
        dispatch({
          type: DELETE_STAFF_MEMBER,
          staffMember: staffDto
        })
      })
  }
};

export const searchStaff = (email) => {
  const url = `${ROOT_URL}/admin/staff/search?email=${email}`;
  return axios
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch(err => console.log(err))
};

export const convertStaff = (staffMember) => {
  const url = `${ROOT_URL}/admin/staff/convert`;
  return axios
    .post(url, staffMember)
    .then(response => {
      return response.data
    })
    .catch(err => console.log(err))
};

export const addStudentsToStaff = (staffMember, students) => {
  return (dispatch) => {
    axios
      .post(`${ROOT_URL}/staff/${staffMember.id}/students`, students)
      .then(() => {
        dispatch({
          type: ADD_STAFF_STUDENT,
          students
        })
      })
      .catch(err => console.log(err))
  }
};

export const getStaffProfile = (staffId) => {
  const url = `${ROOT_URL}/staffs/${staffId}/profile`;
  return (dispatch) => {
    axios
      .get(url)
      .then(response => {
        dispatch({
          type: GET_STAFF_PROFILE,
          staffProfile: response.data
        })
      })
  }
};

export const getRolesForStaff = (staffId) => {
    const url = `${ROOT_URL}/staff/roles`;
    return (dispatch) => {
        axios
            .get(url)
            .then(response => {
                console.log(response.data)
                dispatch({
                    type: GET_STAFF_ROLES,
                    staffRoles: response.data
                })
            })
    }
}

export const createStaffProfile = (staffId, profile) => {
  const url = `${ROOT_URL}/staffs/${staffId}/profile`;
  return (dispatch) => {
    axios
      .post(url, profile)
      .then(response => {
        dispatch({
          type: GET_STAFF_PROFILE,
          staffProfile: response.data
        })
      })
  }
};

export const updateStaffProfile = (staffId, profile) => {
  const url = `${ROOT_URL}/staffs/${staffId}/profile`;
  return (dispatch) => {
    axios
      .put(url, profile)
      .then(() => {
        dispatch({
          type: GET_STAFF_PROFILE,
          staffRoles: profile
        })
      })
  }
};

export const fetchStaffDashboard = (staffId) => {
  const url = `${ROOT_URL}/staff/${staffId}/buddy-books/dashboard`;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_STAFF_DASHBOARD,
          staffDashboard: response.data
        })
      }).catch( (e) =>{
        dispatch({
          type: NO_STAFF_STUDENT_RECORD_FOUND,
          staffDashboard: []
        })
      })
  }
}
export const addStaffProduct = (staffId, product) => {
  const url = `${ROOT_URL}/staff/${staffId}/products`;
  return (dispatch) => {
    axios
      .post(url, product, {
        headers: { 'Content-Type': 'text/plain' }
      })
      .then(response => {
        dispatch({
          type: PATCH_STAFF_MEMBER,
          staffMember: response.data
        })
      })
      .catch(err => console.log(err))
  }
};

export const removeStaffProduct = (staffId, product) => {
  const url = `${ROOT_URL}/staff/${staffId}/products`;
  return (dispatch) => {
    axios
      .delete(url, {
        data: product,
        headers: { 'Content-Type': 'text/plain' }
      })
      .then(response => {
        dispatch({
          type: PATCH_STAFF_MEMBER,
          staffMember: response.data
        })
      })
      .catch(err => console.log(err))
  }
};

export const fetchStaffAccuracy = (staffId) => {
  const url = `${ROOT_URL}/staff/${staffId}/buddy-books/dashboard/accuracy`;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_STAFF_DASHBOARD_ACCURACY,
          data: response.data
        })
      }).catch((e) =>{
        console.log(e)
      })
  }
}

export const fetchStaffReadingTime = (staffId) => {
  const url = `${ROOT_URL}/staff/${staffId}/buddy-books/dashboard/reading-time`;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_STAFF_DASHBOARD_READING_TIME,
          data: response.data
        })
      }).catch((e) =>{
        console.log(e)
      })
  }
}