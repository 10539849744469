import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button, Typography, IconButton } from '@material-ui/core/index';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core/index';
import { Close } from '@material-ui/icons/index';
import { withStyles } from '@material-ui/core/styles/index';
import baseStyle from '../../../../styles/wizardBase';

class AccountConfirmation extends Component {

  state = {
    account: {}
  };

  componentDidMount() {
    this.setState({ account: this.props.account })
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.account.accountDto && !this.props.account.accountDto) {
      this.setState({ account: nextProps.account })
    }
  }

  render() {
    const { classes } = this.props;
    const { accountDto: info, billingDataDto: billing, invoiceDto: invoice } = this.state.account;
    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        open={this.props.open}
        onClose={this.close}
        aria-labelledby='form-dialog-title'
        PaperProps={{ classes: { root: classes.container } }}
      >
        <DialogTitle disableTypography={true} className={classes.title} id='form-dialog-title'>
          <Typography className={classes.titleText} variant='h4'><FormattedMessage id='Wizard.Support.title'/></Typography>
          <IconButton
            onClick={this.props.close}
            className={classes.exitIcon}
            aria-label='close dialog'
          >
            <Close/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {
            this.state.account.accountDto && this.state.account.invoiceDto
            ? <div>
                <Typography variant='h6'>Name: </Typography>
                <Typography variant='subtitle1'>{info.name}</Typography>
                <Typography variant='h6'>Account Created: </Typography>
                <Typography variant='subtitle1'>{`${invoice.date.monthValue}/${invoice.date.dayOfMonth}/${invoice.date.year}`}</Typography>
                <Typography variant='h6'>Amount: </Typography>
                <Typography variant='subtitle1'>{invoice.amount}</Typography>
                <Typography variant='h6'>Rate: </Typography>
                <Typography variant='subtitle1'>{invoice.rate}</Typography>
                <Typography variant='h6'>Billable Student Count: </Typography>
                <Typography variant='subtitle1'>{info.billingCount}</Typography>
                <hr/>
                <Typography variant='h6'>Billing Info: </Typography>
                {
                  this.state.account.billingDataDto &&
                  <div className={classes.address}>
                    <Typography variant='subtitle1'>{billing.street1}</Typography>
                    {
                      billing.street2 &&
                      <Typography variant='subtitle1'>{billing.street2}</Typography>
                    }
                    <Typography variant='subtitle1'>{`${billing.city}, ${billing.state} ${billing.postalCode}`}</Typography>
                    <Typography variant='subtitle1'>{billing.country}</Typography>
                  </div>
                }
              </div>
            : this.state.account.accountDto && <Typography align='center' variant='h6'>Account {info.name} created! </Typography>
          }
        </DialogContent>
        <DialogActions className={classes.buttons}>
          <Button onClick={this.props.close} color='primary'>
            <FormattedMessage id='General.ok'/>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapState = ({ shared: { account }}) => {
  return {
    account
  }
};

export default connect(mapState)(withStyles(baseStyle)(AccountConfirmation));