import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import {
  Button,
  Typography,
  IconButton,
  FormGroup,
  TextField,
  MenuItem,
  Checkbox,
  Divider
} from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { fetchLevels } from '../../../../redux/actions';
import baseStyle from '../../../../styles/wizardBase';
import { ROOT_URL } from '../../../../redux/constants';
import { FormattedMessage } from 'react-intl';

class SimonSkillDatabank extends Component {

  state = {
    tested: false,
    error: {},
    level: this.props.level.id ? this.props.level : this.props.levels.find(level => level.gameId === this.props.game.id),
    difficulty: 'Easy',
    startingGestures: 1,
    numberOfRounds: 5,
    gestureSet: [
      "swipeUp1",
      "swipeDown1",
      "swipeLeft1",
      "swipeRight1",
      "tap1",
      "doubleTap1",
      "shake",
      "twistRight",
      "scrub",
      "swipeUp2",
      "swipeDown2",
      "swipeLeft2",
      "swipeRight2",
      "tap2",
      "doubleTap2",
      "twistLeft",
      "rotation"
    ],
    difficultyProgression: [9,10]
  };

  componentDidMount() {
    if (this.props.level.id) {
      const variables = JSON.parse(this.props.level.levelJson);
      this.setState({...variables, gestureSet: JSON.parse(variables.gestureSet), level: this.props.level})
    }
  }

  close = () => {
    this.props.close();
  };

  formatName = (name) => {
    return name
      .replace(/([A-Z0-9])/g, ` $1`)
      .replace(/^./, (str) => str.toUpperCase())
  };

  runTest = () => {
    const { startingGestures, numberOfRounds, gestureSet, tested, level } = this.state;
    const url = this.props.type === 'staff'
      ? `${ROOT_URL}/staff/${this.props.id}/objective-databank/test`
      : `${ROOT_URL}/admin/objective-databank/test`;
    const variables = level.id ? JSON.parse(level.levelVariables) : [];
    if (tested) this.clearTest();
    axios.post(url, {
      levelVariables: JSON.stringify({
        ...variables,
        startingGestures,
        numberOfRounds,
        gestureSet: JSON.stringify(gestureSet),
      }),
      templateId: level.id
    })
      .catch(err => console.log(err));
    this.setState({ tested: true });
  };

  clearTest = () => {
    const url = this.props.type === 'staff'
      ? `${ROOT_URL}/staff/${this.props.id}/objective-databank/test`
      : `${ROOT_URL}/admin/objective-databank/test`;
    axios.delete(url)
      .catch(err => console.log(err));
    this.setState({ tested: false })
  };

  handleChange = (ev) => {
    const value = ev.target.type === 'number' ? ev.target.value > 0 ? ev.target.value * 1 : '' : ev.target.value;
    this.setState({ [ev.target.name]: value })
  };

  handleChangeDifficulty = (ev) => {
    switch (ev.target.value){
      case 'Easy':
        return this.setState({ difficulty: 'Easy', difficultyProgression:  [9,10]});
      case 'Medium':
        return this.setState({ difficulty: 'Medium', difficultyProgression: [7, 8]});
      case 'Hard':
        return this.setState({ difficulty: 'Hard', difficultyProgression: [4, 6]});
      default:
        return;
    }
  };

  handleChangeGesture = (ev) => {
    const { gestureSet } = this.state;
    const gesture =  ev.target.name;
    const index = this.state.gestureSet.indexOf(gesture);
    if (ev.target.checked) {
      gestureSet.push(gesture);
    }
    else {
      gestureSet.splice(index, 1)
    }
    this.setState({ gestureSet });
    if (this.state.gestureSet.length < 1){
      this.setState({ error: {...this.state.error, gestureSet: true }})
    }
    else {
      const error = this.state.error;
      delete error.gestureSet;
      this.setState({ error })
    }
    this.setState({ gestureSet });
  };

  handleSubmit = (ev) => {
    ev.preventDefault();
    const { level, difficulty, startingGestures, numberOfRounds, gestureSet, difficultyProgression } = this.state;
    const variables = level.levelVariables ? JSON.parse(level.levelVariables) : JSON.parse(level.levelJson);
    const { weightedGestureSpawn } = variables;
    const levelJson = JSON.stringify({
      difficultyProgression,
      weightedGestureSpawn,
      startingGestures,
      gestureSet: JSON.stringify(gestureSet),
      numberOfRounds
    });
    this.props.onSubmit({ levelTemplateId: level.id, levelJson, difficulty });
  };

  checkForErrors = (ev) => {
    if (!ev.target.value){
      this.setState({ error: {...this.state.error, [ev.target.name]: true }})
    }
    else {
      let error = this.state.error;
      delete error[ev.target.name];
      this.setState({ error })
    }
  };

  render()  {
    const { classes, open, close, back } = this.props;
    const { tested, level, error } = this.state;
    const gestures = [
      "swipeUp1",
      "swipeDown1",
      "swipeLeft1",
      "swipeRight1",
      "tap1",
      "doubleTap1",
      "shake",
      "twistRight",
      "scrub",
      "swipeUp2",
      "swipeDown2",
      "swipeLeft2",
      "swipeRight2",
      "tap2",
      "doubleTap2",
      "twistLeft",
      "rotation"
    ];
    const disabled = Object.keys(error).length > 0 || this.state.startingGestures > 10 || this.state.gestureSet < 1;
    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        open={open}
        onClose={close}
        aria-labelledby='skill-dialog-title'
        scroll='paper'
        PaperProps={{ classes:{ root: classes.container }}}
      >
        <DialogTitle disableTypography={true} className={ classes.title }>
          <Typography className={ classes.titleText } variant='h4' id='skill-dialog-title'><FormattedMessage id='Wizard.skillDB.title'/></Typography>
          <IconButton
            onClick={this.close}
            className={ classes.exitIcon }
            aria-label="close dialog button"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography gutterBottom variant='h3'>
            <FormattedMessage id='Wizard.Simon.title'/>
          </Typography>
          <Typography className={classes.description} variant='subtitle1'>
            <FormattedMessage id='Wizard.Simon.subtitle'/>
          </Typography>
          <Divider />
          {
            level &&
            <div className={classes.level}>
              <FormGroup className={classes.configureLine}>
                <Typography className={classes.configTitle} variant='h6'><FormattedMessage id='Wizard.Gesture.startingCount'/>: </Typography>
                <TextField
                  variant='outlined'
                  className={classes.configComponent}
                  name='startingGestures'
                  type='number'
                  error={this.state.startingGestures > 10 || error.startingGestures}
                  helperText={
                    this.state.startingGestures > 10
                      ? 'Starting gestures cannot exceed 10'
                      : error.startingGestures
                        ? '* required'
                        : ''
                  }
                  onBlur={this.checkForErrors}
                  value={this.state.startingGestures}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup className={classes.configureLine}>
                <Typography className={classes.configTitle} variant='h6'><FormattedMessage id='Wizard.Gesture.numRounds'/>: </Typography>
                <TextField
                  variant='outlined'
                  className={classes.configComponent}
                  name='numberOfRounds'
                  type='number'
                  error={error.numberOfRounds}
                  helperText={error.numberOfRounds ? '* required' : ''}
                  onBlur={this.checkForErrors}
                  value={this.state.numberOfRounds}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup className={classes.configureLine}>
                <Typography className={classes.configTitle} variant='h6'><FormattedMessage id='Wizard.Gesture.gestures'/>:</Typography>
                <br />
                {
                  gestures.map(gesture => (
                    <div key={gesture}>
                      <Checkbox
                        name={gesture}
                        checked={this.state.gestureSet.includes(gesture)}
                        onChange={this.handleChangeGesture}
                      />
                      <Typography display='inline' variant='subtitle1'>{this.formatName(gesture)}</Typography>
                    </div>
                  ))
                }
                {
                  error.gestureSet &&
                  <Typography color='secondary' className={classes.gestureError} align='center' variant='subtitle1'>You must select at least one gesture</Typography>
                }
              </FormGroup>
              <FormGroup className={classes.configureLine}>
                <Typography className={classes.configTitle} variant='h6'><FormattedMessage id='General.difficulty'/>: </Typography>
                <TextField
                  select
                  variant='outlined'
                  className={classes.configComponent}
                  name='difficulty'
                  value={this.state.difficulty}
                  onChange={this.handleChangeDifficulty}
                >
                  {
                    [
                      { value: 'Easy', text: <FormattedMessage id='General.easy' /> },
                      { value: 'Medium', text: <FormattedMessage id='General.medium' /> },
                      { value: 'Hard', text: <FormattedMessage id='General.hard' /> }
                    ].map(difficulty => (<MenuItem key={difficulty.value} value={difficulty.value}> {difficulty.text} </MenuItem>))
                  }
                </TextField>
              </FormGroup>
            </div>
          }
        </DialogContent>
        <DialogActions className={classes.buttons}>
          <Button onClick={back} color='secondary'>
            <FormattedMessage id='General.back'/>
          </Button>
          <Button
            onClick={ this.runTest }
            variant={ tested ? 'outlined' : 'text' }
            color={ tested ? 'secondary' : 'primary' }
          >
            { tested ? <FormattedMessage id='General.retest' /> : <FormattedMessage id='General.test' /> }
          </Button>
          <Button disabled={disabled} onClick={this.handleSubmit} color='primary'>
            <FormattedMessage id='General.next'/>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const  mapState = ({ platform: { levels }}) => {
  return {
    levels
  }
};

const mapDispatch = (dispatch) => {
  return {
    fetchLevels: () => dispatch(fetchLevels())
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(SimonSkillDatabank));