import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../../styles/wizardBase';
import BrailleSkillPage1 from './SelfGuidedPage1';
import BrailleSkillPage2 from './SelfGuidedPage2';
import { fetchGoalDatabanks, fetchMyGoals, getStudentsForStaff } from '../../../../redux/actions';
import BrailleSkillPage3 from './SelfGuidedPage3';

class SelfGuidedBase extends Component {

  state = {
    page: 1
  };

  componentDidMount() {
    this.props.getStudentsForStaff(this.props.id);
    this.props.fetchGoalDatabanks('staff', this.props.id);
  }

  next = (type) => {
    const page = type === 'single' ? 2 : 3;
    this.setState({ page })
  };

  render()  {
    console.log(this.props.history)
    const { skill } = this.props;
    const { page } = this.state;
    return (
      <>
        {
          page === 1 &&
          <BrailleSkillPage1 history={this.props.history} open={this.props.open} close={this.props.close} next={this.next} skill={skill}/>
        }
        {
          page === 2 &&
          <BrailleSkillPage2
            skill={this.props.skillDatabank}
            history={this.props.history}
            open={this.props.open}
            students={this.props.staffStudents}
            close={this.props.close}
          />
        }
        {
          page === 3 &&
          <BrailleSkillPage3
            skill={this.props.skillDatabank}
            history={this.props.history}
            open={this.props.open}
            close={this.props.close}
            goals={this.props.staffGoalDatabanks}
            staffId={this.props.id}
          />
        }
      </>
    )
  }
}

const mapState = ({ platform: { staffStudents, skillDatabank, staffGoalDatabanks }}) => {
  return {
    staffStudents,
    skillDatabank,
    staffGoalDatabanks
  }
};

const mapDispatch = (dispatch) => {
  return {
    getStudentsForStaff: (id) => dispatch(getStudentsForStaff(id)),
    fetchGoalDatabanks: (type, id) => dispatch(fetchGoalDatabanks(type, id)),
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(SelfGuidedBase));