import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import { Card, CardContent, CardHeader, Dialog, IconButton, Paper, Typography, withStyles } from "@material-ui/core";
import { green, yellow } from "@material-ui/core/colors";
import { Close } from "@material-ui/icons";
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React, { useState } from "react";
import { useEffect } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from '../../../styles/browseStory.js';

const eventWrapperStyle = {
    display: 'flex',
    justifyContent: "center",
    fontSize: '1.1rem',
    flexDirection: 'column',
    textAlign: 'center'
};
const week = {
    0: 'Sun',
    1: 'Mon',
    2: 'Tue',
    3: 'Wed',
    4: 'Thu',
    5: 'Fri',
    6: 'Sat',
}
let weekDays = [];
const EventComponent = (props) => {
    let readTime = props.event.extendedProps && props.event.extendedProps.secondsRead;
    let txt = "";
    if (readTime == 0) {
        txt = "";
    } else if (readTime < 60) {
        txt = readTime + " Seconds";
    } else if (readTime >= 60 && readTime < 3600) {
        txt = parseInt(readTime / 60) + " Minutes";
    } else {
        txt = parseFloat(readTime / 3600).toFixed(1) + " Hours";
    }
    const handleDateClick = (e) => {
        e.stopPropagation();
    }
    return (
        <div style={eventWrapperStyle}
            onClick={(e) => handleDateClick(e)}>
            <div style={{
                color: "black",
                position: "absolute",
                top: "-24px",
                left: "50%",
                transform: "translateX(-50%)",
            }} >
                {txt}
            </div>
            <div>
                {
                    (readTime && (readTime / 60) >= props.event.extendedProps.targetReadingTime)
                        ? <CheckCircleIcon fontSize="large" style={{ color: green[500] }} />
                        : (readTime / 60) > 0
                            ? <CheckCircleIcon fontSize="large" style={{ color: yellow[500] }} />
                            : <CancelIcon fontSize="large" color="error" />
                }
            </div>
        </div>
    )
}
const generateEvents = (data,targetReadingTime) => {
    let last60daysEvent = [];
    for (let i = 0; i < 60; i++) {
        let date = new Date();
        let now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
        let d = new Date(now_utc);
        d.setDate(d.getDate() - i);
        last60daysEvent.push({
            title: "Event " + d.toISOString().split('T')[0],
            allDay: true,
            'start': d.toISOString().split('T')[0],
            'end': d.toISOString().split('T')[0],
            'secondsRead': 0,
            'targetReadingTime':targetReadingTime
        })

    }
    let eventsMap = new Map();
    data && data.map((_data) => {
        let key = "Event " + _data.date;
        let date = new Date(_data.date);
        let now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
        let d = new Date(now_utc);
        eventsMap.set(key, {
            title: "Event",
            allDay: true,
            'start': d.toISOString().split('T')[0],
            'end': d.toISOString().split('T')[0],
            'secondsRead': _data["secondsRead"],
            'targetReadingTime':targetReadingTime
        })
    });
    let resEvents = [];
    for (let i = 0; i < 60; i++) {

        if (eventsMap.has(last60daysEvent[i].title)) {
            resEvents.push(eventsMap.get(last60daysEvent[i].title));
        }
        else {
            resEvents.push(last60daysEvent[i]);
        }
    }

    return resEvents;

}
const LastSeveDay = ({ data, classes, targetReadingTime, teacherStyle, downloadComplete }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [weeklyData, setWeeklyData] = useState([]);

    useEffect(() => {
        if (data) {
            weekDays = [];
            const dates = [...Array(7)].map((_, i) => {
                const d = new Date();
                d.setDate(d.getDate() - i);
                weekDays.push({ id: d.getDay(), label: week[d.getDay()], date: d.toISOString().split('T')[0] });
                return d.getDate();
            });
            weekDays = weekDays.map((_data) => {
                let time = 0;
                data.forEach(element => {
                    if (element.date == _data.date) {
                        time = element.secondsRead;
                    }
                });
                return { ..._data, readingTarget: time }
            }).reverse();
            setWeeklyData(weekDays);
        }
    }, [data])

    const closeWizard = (e) => {
        e.stopPropagation();
        setModalOpen(false);
    }
    const openWizard = (e) => {
        e.stopPropagation();
        setModalOpen(true);
    }
    const events = generateEvents(data,targetReadingTime);
    const customWidth = teacherStyle ? '100%' : '80%';
    return (
        <>
            {<div className={classes.customCalenderHover}>
                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', width: customWidth, margin: '0 auto', cursor: 'pointer' }} onClick={(e) => openWizard(e)}>
                    {
                        !downloadComplete ?
                            <div style={{ display: 'flex', justifyContent: 'center', margin: '0 auto' }}>
                                <CircularProgress />
                            </div> :
                            weeklyData.map(_data => {
                                let txt = _data["readingTarget"] && _data["readingTarget"] > 60 ? "Minutes" : "Minute";
                                return (
                                    <div >
                                        <div style={teacherStyle ? {
                                            textTransform: 'capitalize',
                                            fontSize: "1rem"
                                        } : {
                                            marginRight: '5px',
                                            textTransform: 'capitalize',
                                            fontSize: "2rem"
                                        }}>
                                            {_data.label}

                                        </div>
                                        <div style={{ marginTop: "10px" }}>
                                            {(_data["readingTarget"] && (_data.readingTarget / 60) >= targetReadingTime)
                                                ? <CheckCircleIcon fontSize="large" style={teacherStyle ? { color: green[500], fontSize: "2rem" } : { color: green[500], fontSize: "3.5rem" }} />
                                                : (_data.readingTarget / 60) > 0
                                                    ? <CheckCircleIcon fontSize="large" style={teacherStyle ? { color: yellow[500], fontSize: "2rem" } : { color: yellow[500], fontSize: "3.5rem" }} />
                                                    : <CancelIcon fontSize="large" color="error" style={teacherStyle ? { fontSize: "2rem" } : { fontSize: "3.5rem" }} />
                                            }
                                            <br />
                                            {
                                                !teacherStyle && Math.floor(_data.readingTarget / 60) != 0 ?
                                                    <div style={(_data.readingTarget / 60) >= targetReadingTime ? { color: green[800] } :
                                                        (_data.readingTarget / 60) >= 0 ? { color: yellow[800] } :
                                                            {}}>{Math.floor(_data.readingTarget / 60)} {txt}</div> : ""
                                            }
                                        </div>
                                    </div>)
                            }
                            )}
                </div>
            </div>}
            <Dialog open={modalOpen} onClose={(e) => closeWizard(e)}
                fullWidth={true}
                maxWidth={true}>
                <Paper >
                    <Card>
                        <CardHeader action={
                            <IconButton
                                onClick={(e) => closeWizard(e)}
                                className={classes.exitIcon}
                                aria-label="close dialog"
                            >
                                <Close />
                            </IconButton>

                        }
                            title={<Typography className={classes.titleText} variant='h4'>
                                Reading Time
                            </Typography>
                            }>

                        </CardHeader>
                        <CardContent onClick={(e) => { e.stopPropagation() }}>
                            <FullCalendar
                                plugins={[dayGridPlugin]}
                                initialView="dayGridMonth"
                                events={events}
                                height={660}
                                contentHeight='auto'
                                eventContent={EventComponent}
                                eventBorderColor={'white'}
                                eventBackgroundColor={'white'}
                                headerToolbar={{
                                    left: 'prev today next',
                                    right: '',
                                    center: 'title',
                                }}
                                buttonText={
                                    { today: 'TODAY' }
                                }
                            />
                        </CardContent>
                    </Card>
                </Paper>
            </Dialog>
        </>
    )
}
export default withStyles(styles)(LastSeveDay);