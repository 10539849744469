import React, { Component } from 'react';
import moment from 'moment';
import { Button, Card, Divider, List, Typography, withStyles } from '@material-ui/core';
import TextPost from '../components/TextPost';
import GoalPost from '../components/GoalPost';
import baseStyle from '../../styles/feed';
import { ROOT_URL } from '../../redux/constants';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import AddPost from './AddPost';
import pic from '../../assets/SocialMockups/blog_post.png';

class GroupFeed extends Component {

  state = {
    page: 1,
    posts: this.props.posts
  };

  renderPosts = (post) => (
    post.goalId
    ? <GoalPost
      isAdmin={this.props.isAdmin}
      key={post.key}
      goal={post}
      isLocked={this.props.isLocked}
      selectGoal={this.props.selectGoal}
      profile={this.props.profile}
      group={this.props.group}
    />
    : <TextPost
        isAdmin={this.props.isAdmin}
        isModerator={this.props.isModerator}
        isLocked={this.props.isLocked}
        key={post.key}
        post={post}
      />
  );

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.posts !== nextProps.posts) {
      this.setState({ posts: nextProps.posts })
    }
  }

  loadMore = () => {
    const { page, posts } = this.state;
    const batchPosts = posts.slice(page*50, (page+1)*50);
    const url = `${ROOT_URL}/posts/batch`;
    axios
      .post(url, batchPosts)
      .then(response => {
        this.setState((state) => ({
          page: state.page+1,
          posts:  [...posts.slice(0, page*50), ...response.data, ...this.state.posts.slice((page+1)*50)]
        }));
        console.log(this.state.posts);
      });
  };

  addPost = (_post) => {
    const filteredPosts = this.state.posts.filter(post => post.key !== _post.key);
    const posts = [_post,...filteredPosts];
    this.setState({ posts })
  };

  render() {
    const { classes, goals, group } = this.props;
    const { page, posts } = this.state;
    const paginatedPosts = posts.slice(0,((50*page)-goals.length)).concat(goals);
    const sorted = paginatedPosts.sort((a, b) => moment(new Date(b.date)).isAfter(moment(new Date(a.date))) ? 1 : -1);
    return (
      <div className={classes.container}>
        { !this.props.isLocked &&
          <AddPost add={this.addPost} group={group}/>
        }
        <Divider className={classes.divider}/>
        {
          posts.length || goals.length
            ? <List style={{ padding: '0'}}>
                { sorted.map(post => this.renderPosts(post)) }
              </List>
            : <Card className={classes.noPosts}>
                <Typography variant='h4'>Looks like there aren't any posts yet. </Typography>
                <img src={pic} alt='man at computer'/>
                <Typography variant='h4'>Add one to get this group started! </Typography>
              </Card>
        }
        <div className={classes.footer}>
          { (posts.length + goals.length) > paginatedPosts.length && <Button onClick={this.loadMore}><FormattedMessage id='GroupFeed.more'/></Button> }
        </div>
      </div>
    );
  }
}

export default withStyles(baseStyle)(GroupFeed);