export default {
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    gridColumnGap: '15px',
    paddingTop: 30,
  },
  loading: {
    margin: '0 auto',
    display: 'block',
    width: '25%'
  },
  snackbar: {
    backgroundColor: 'white',
    color: 'black',
    marginTop: -8,
    '& #message-id': {
      fontSize: 16
    }
  },
  title: {
    display: 'grid'
  },
  private: {
    display: 'grid'
  },
  subtitle: {
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'center'
  },
  icon: {
    alignSelf: 'center',
    justifySelf: 'end',
    fontSize: 14,
    marginRight: 5,
    gridColumn: 2
  },
  text: {
    alignSelf: 'center',
    justifySelf: 'start',
    gridColumn: 3
  },
  editBtn: {
    minWidth: 0,
    justifySelf: 'end',
    gridColumn: 4,
  },
  left: {
    gridRow: 1,
    gridColumn: 1,
  },
  right: {
    gridRow: 1,
    gridColumn: 3,
  },
  center: {
    gridRow: 1,
    gridColumn: 2,
    overflowY: 'scroll',
  },
  detail: {
    marginBottom: 10,
    padding: 10
  },
  pictureContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'center'
  },
  avatar: {
    width: '100%',
    maxWidth: 300,
    margin: '0 auto',
    marginBottom: 10,
    zIndex: 1,
    overflow: 'hidden'
  },
  editButton: {
    gridColumn: 3,
    minHeight: 0,
    alignSelf: 'end',
    zIndex: 2,
    marginLeft: -25,
    backgroundColor: 'white'
  },
  memberTitle: {
    paddingTop: 15
  },
  divider: {
    margin: 15
  },
  secondaryDivider: {
    marginBottom: 10,
    margin: '0 auto',
    width: '40%'
  },
  '@media (max-width: 1200px)': {
    container: {
      display: 'grid',
      gridTemplateColumns: '1fr 3fr',
      gridColumnGap: '15px',
      paddingTop: 30,
    },
    right: {
      display: 'none',
    }
  },
  '@media (max-width: 800px)': {
    container: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridColumnGap: '15px',
      paddingTop: 30,
    },
    left: {
      display: 'none',
    },
    right: {
      display: 'none',
    },
  },
  inviteMember: {
    margin: '0 15px'
  },
  inviteSpecialist: {
    margin: '0 15px',
    textAlign: 'center'
  },
  inviteInput: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    minHeight: 0,
    alignContent: 'center'
  },
  inviteSelect: {
    alignSelf: 'center'
  },
  inviteBtn: {
    alignSelf: 'center',
    minHeight: 0
  },
  admin: {
    marginLeft: 5
  },
  member: {
    marginLeft: 10
  },
  listItem: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    gridAutoFlow: 'column',
    '& .admin': {
      fontSize: 18,
      color: '#D4AF37',
      width: '100%'
    }
  },
  viewMore: {
    width: '100%'
  },
  noPosts: {
    padding: 20,
    textAlign: 'center'
  },
  avatarPicture: {
    overflow: 'hidden'
  },
  dropzone:{
    display: 'grid',
    justifyContent: 'center',
    gridRowGap: '20px',
    margin: 20,
  },
  slider: {
    width: '100%'
  },
  addImage: {
    justifySelf: 'center',
    margin: '0 auto'
  }
}