import React, { Component } from 'react';
import { Button, FormGroup, Typography, IconButton, TextField, MenuItem } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../../styles/wizardBase';
import { FormattedMessage } from 'react-intl';
import {css} from "@emotion/core";
import CircleLoader from "react-spinners/FadeLoader";

class SkillDatabankWizardPage1 extends Component {

    constructor(props) {
        super(props);
        this.state={
            loading: true
        };
    }

    close = () => {
        this.props.close();
    };

    render()  {
        const { classes, next, open, close } = this.props;
        const games = this.props.games;
        return (
            <Dialog
                disableEscapeKeyDown
                fullWidth={true}
                open={open}
                onClose={close}
                aria-labelledby='skill-dialog-title'
                PaperProps={{ classes:{ root: classes.container }}}
            >
                <DialogTitle disableTypography={true} className={ classes.title } id='skill-dialog-title'>
                    <Typography className={ classes.titleText } variant='h4'>
                        <FormattedMessage id="Wizard.skillDB.title"/>
                    </Typography>
                    <IconButton
                        onClick={this.close}
                        className={ classes.exitIcon }
                        aria-label="close dialog"
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent min-height={"600px"}>
                    <DialogContentText>
                        <FormattedMessage id='Wizard.loading' />
                    </DialogContentText>
                    <form className={ classes.root }>
                        <div className="sweet-loading" align={"center"} width={"100%"} min-height={"200px"}>
                            <CircleLoader
                                size={300}
                                color={"#3f51b5"}
                                loading={this.state.loading}
                            />
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        )
    }
}

export default withStyles(baseStyle)(SkillDatabankWizardPage1);