import { FETCH_ALL_SPECIALISTS, REGISTER_SPECIALIST, DEREGISTER_SPECIALIST } from '../../constants';

export default function (state = [], action){
  switch (action.type){
    case FETCH_ALL_SPECIALISTS:
      return action.specialists;
    case REGISTER_SPECIALIST:
      return [...state, action.specialist];
    case DEREGISTER_SPECIALIST:
      return state.filter(specialist => specialist.id !== action.specialist.id)
    default:
      return state;
  }
}