import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, FormGroup, Typography, TextField, IconButton } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../../styles/wizardBase';

class SupportWizardPage1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: ''
    }
  }

  close = () => {
    this.props.close();
    this.setState({ firstName: '', lastName: '', email: '' });
  };

  handleChange = ({ target: { name, value }}) => {
    this.setState({ [name]: value })
  };

  submit = () => {
    const account = {...this.state, type: 'Support', accountId: this.props.user.accountId };
    this.props.submit(account);
    this.setState({ firstName: '', lastName: '', email: '' });
  };

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        open={this.props.open}
        onClose={this.close}
        aria-labelledby='form-dialog-title'
        PaperProps={{ classes: { root: classes.container } }}
      >
        <DialogTitle disableTypography={true} className={classes.title} id='form-dialog-title'>
          <Typography className={classes.titleText} variant='h4'><FormattedMessage id='Wizard.SupportUser.title'/></Typography>
          <IconButton
            onClick={this.close}
            className={classes.exitIcon}
            aria-label='close dialog'
          >
            <Close/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage id='Wizard.SupportUser'/>
          </DialogContentText>
          <FormGroup>
            <FormGroup classes={{ root: classes.fullName }}>
              <TextField
                id='support-first-name'
                label={<FormattedMessage id='General.firstName'/>}
                type='text'
                name='firstName'
                variant='outlined'
                value={this.state.firstName}
                onChange={this.handleChange}
              />
              <TextField
                id='support-last-name'
                label={<FormattedMessage id='General.lastName'/>}
                type='text'
                name='lastName'
                variant='outlined'
                value={this.state.lastName}
                onChange={this.handleChange}
              />
            </FormGroup>
            <TextField
              classes={{ root: classes.firstName }}
              id='support-email'
              label={<FormattedMessage id='General.email'/>}
              type='text'
              name='email'
              variant='outlined'
              value={this.state.email}
              onChange={this.handleChange}
            />
          </FormGroup>
        </DialogContent>
        <DialogActions className={classes.buttons}>
          <Button onClick={this.close} color='secondary'>
            <FormattedMessage id='General.cancel'/>
          </Button>
          <Button onClick={this.submit} color='primary'>
            <FormattedMessage id='General.submit'/>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(baseStyle)(SupportWizardPage1);