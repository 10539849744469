import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import classNames from 'classnames';
import { Button, Typography, IconButton, FormGroup, TextField, MenuItem } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { fetchLevels } from '../../../../redux/actions';
import baseStyle from '../../../../styles/wizardBase';
import { ROOT_URL } from '../../../../redux/constants';
import { FormattedMessage } from 'react-intl';

class BarnyardSkillDatabank extends Component {

  state = {
    tested: false,
    error: {},
    variables: {
      acceptedPen: '',
      assignAnimalPenTarget: ''
    },
    level: this.props.level.id ? this.props.level : {},
    difficulty: 'Easy',
    directionType: '',
    errorThreshold: 0,
    orderToRandomLoopNumber: 0,
    alwaysPrompt: true,
    selectedLevel: '',
    min: 7,
    max: 9
  };

  componentDidMount() {
    if (this.props.level.id) {
      const variables = JSON.parse(this.props.level.levelJson);
      const alwaysPrompt = variables.errorThreshold === 0;
      this.setState({
        variables,
        directionType: variables.directionType,
        errorThreshold: variables.errorThreshold,
        orderToRandomLoopNumber: variables.orderToRandomLoopNumber,
        alwaysPrompt,
        difficulty: this.props.level.difficulty,
        min: variables.difficultyProgression && variables.difficultyProgression[0],
        max: variables.difficultyProgression && variables.difficultyProgression[1],
        selectedLevel: this.props.level.id
      })
    }
    if (this.props.levelTemplate) {
      this.setState({ selectedLevel: this.props.levelTemplate.id})
    }
  }

  close = () => {
    this.props.close();
  };

  handleSubmit = (ev) => {
    ev.preventDefault();
    const { difficulty, level, directionType, variables, errorThreshold, orderToRandomLoopNumber, min, max } = this.state;
    const error = errorThreshold ? errorThreshold : 0;
    const loop = orderToRandomLoopNumber ? orderToRandomLoopNumber : -1;
    const difficultyProgression = [min, max];
    const levelVariables = {...variables, directionType, errorThreshold: error, orderToRandomLoopNumber: loop, difficultyProgression };
    const levelJson = JSON.stringify(levelVariables);
    this.props.onSubmit({ levelTemplateId: level.id, levelJson, difficulty });
  };

  runTest = () => {
    const { variables, tested, level, directionType, errorThreshold, orderToRandomLoopNumber, min, max } = this.state;
    const url = this.props.type === 'staff' ? `${ROOT_URL}/staff/${this.props.id}/objective-databank/test`: `${ROOT_URL}/admin/objective-databank/test`;
    if (tested) this.clearTest();
    const error = errorThreshold ? errorThreshold : 0;
    const loop = orderToRandomLoopNumber ? orderToRandomLoopNumber : -1;
    const difficultyProgression = [min, max];
    const levelVariables = {...variables, directionType, errorThreshold: error, orderToRandomLoopNumber: loop, difficultyProgression};
    axios.post(url, { levelVariables: JSON.stringify(levelVariables), templateId: level.id })
      .catch(err => console.log(err));
    this.setState({ tested: true });
  };

  clearTest = () => {
    const url = this.props.type === 'staff' ? `${ROOT_URL}/staff/${this.props.id}/objective-databank/test`: `${ROOT_URL}/admin/objective-databank/test`;
    axios.delete(url)
      .catch(err => console.log(err));
    this.setState({ tested: false })
  };

  handleChange = (ev) => {
    if (ev.target.name === 'difficulty') this.BarnyardJSON(ev.target.value);
    if (ev.target.name === 'alwaysPrompt') this.setState({ alwaysPrompt: ev.target.value });
    const value = ev.target.type === 'number' ? ev.target.value > 0 ? ev.target.value * 1 : '' : ev.target.value;
    this.setState({ [ev.target.name]: value })
  };

  handleLevelChange = (ev) => {
    const level = this.props.levels.find(level => level.id === ev.target.value);
    this.setState({ variables: JSON.parse(level.levelVariables), level, selectedLevel: ev.target.value })
    this.BarnyardJSON('Easy', JSON.parse(level.levelVariables))
  };

  formatName = (name) => {
    return `${name.split('')[0].toUpperCase()}${name.slice(1)}`
  };

  BarnyardJSON = (difficulty, _variables) => {
    const { alwaysPrompt } = this.state;
    const variables = _variables ? _variables : this.state.variables;
    switch (difficulty) {
      case 'Easy':
        if (variables.hitBoxType === 'Location') {
          alwaysPrompt
            ? this.setState({
              variables: {
                ...variables,
                acceptedPen: variables.acceptedPen.toLowerCase(),
                assignAnimalPenTarget: variables.assignAnimalPenTarget.toLowerCase(),
                difficultyProgression: [16,30],
                animalHitBoxSize: [1.5,1.5],
                indexAnimalSpawn: 5,
                indexAnimalFound: 1,
                indexAnimalFoundPrompt: 5,
              },
              min: 16,
              max: 30,
            })
            : this.setState({
              variables: {
                ...variables,
                acceptedPen: variables.acceptedPen.toLowerCase(),
                assignAnimalPenTarget: variables.assignAnimalPenTarget.toLowerCase(),
                difficultyProgression: [16,30],
                animalHitBoxSize: [1.5,1.5],
                indexAnimalSpawn: 5,
                indexAnimalFound: 5,
                indexAnimalFoundPrompt: 1
              },
              min: 16,
              max: 30,
            })
        }
        else if (variables.hitBoxType === 'Drag'){
          alwaysPrompt
            ? this.setState({
              variables: {
                ...variables,
                acceptedPen: variables.acceptedPen.toLowerCase(),
                assignAnimalPenTarget: variables.assignAnimalPenTarget.toLowerCase(),
                difficultyProgression: [7,9],
                animalHitBoxSize: [30,30],
                indexAnimalSpawn: 5,
                indexAnimalSpawnPrompt: 1
              },
              min: 7,
              max: 9,
            })
            : this.setState({
              variables: {
                ...variables,
                acceptedPen: variables.acceptedPen.toLowerCase(),
                assignAnimalPenTarget: variables.assignAnimalPenTarget.toLowerCase(),
                difficultyProgression: [7,9],
                animalHitBoxSize: [30,30],
                indexAnimalSpawn: 5,
                indexAnimalSpawnPrompt: 1
              },
              min: 7,
              max: 9,
            })
        }
        else if (variables.hitBoxType === 'Swipe'){
          alwaysPrompt
            ? this.setState({
              variables: {
                ...variables,
                acceptedPen: variables.acceptedPen.toLowerCase(),
                assignAnimalPenTarget: variables.assignAnimalPenTarget.toLowerCase(),
                difficultyProgression: [7,9],
                animalHitBoxSize: [0,0],
                indexAnimalSpawn: 5,
                indexAnimalSpawnPrompt: 4,
              },
              min: 7,
              max: 9,
            })
            : this.setState({
              variables: {
                ...variables,
                acceptedPen: variables.acceptedPen.toLowerCase(),
                assignAnimalPenTarget: variables.assignAnimalPenTarget.toLowerCase(),
                difficultyProgression: [7,9],
                animalHitBoxSize: [0,0],
                indexAnimalSpawn: 5,
                indexAnimalSpawnPrompt: 4
              },
              min: 7,
              max: 9,
            })
        }
        break;
      case 'Medium':
        if (variables.hitBoxType === 'Location') {
          alwaysPrompt
            ? this.setState({
              variables: {
                ...variables,
                acceptedPen: variables.acceptedPen.toLowerCase(),
                assignAnimalPenTarget: variables.assignAnimalPenTarget.toLowerCase(),
                difficultyProgression: [10,16],
                animalHitBoxSize: [1.5,1.5],
                indexAnimalSpawn: 5,
                indexAnimalFound: 1,
                indexAnimalFoundPrompt: 5,
              },
              min: 10,
              max: 16,
            })
            : this.setState({
              variables: {
                ...variables,
                acceptedPen: variables.acceptedPen.toLowerCase(),
                assignAnimalPenTarget: variables.assignAnimalPenTarget.toLowerCase(),
                difficultyProgression: [10,16],
                animalHitBoxSize: [1.5,1.5],
                indexAnimalSpawn: 5,
                indexAnimalFound: 5,
                indexAnimalFoundPrompt: 1,
              },
              min: 10,
              max: 16,
            })
        }
        else if (variables.hitBoxType === 'Drag'){
          alwaysPrompt
            ? this.setState({
              variables: {
                ...variables,
                acceptedPen: variables.acceptedPen.toLowerCase(),
                assignAnimalPenTarget: variables.assignAnimalPenTarget.toLowerCase(),
                difficultyProgression: [5,7],
                animalHitBoxSize: [30,30],
                indexAnimalSpawn: 5,
                indexAnimalSpawnPrompt: 1
              },
              min: 5,
              max: 7,
            })
            : this.setState({
              variables: {
                ...variables,
                acceptedPen: variables.acceptedPen.toLowerCase(),
                assignAnimalPenTarget: variables.assignAnimalPenTarget.toLowerCase(),
                difficultyProgression: [5,7],
                animalHitBoxSize: [30,30],
                indexAnimalSpawn: 5,
                indexAnimalSpawnPrompt: 1
              },
              min: 5,
              max: 7,
            })
        }
        else if (variables.hitBoxType === 'Swipe'){
          alwaysPrompt
            ? this.setState({
              variables: {
                ...variables,
                acceptedPen: variables.acceptedPen.toLowerCase(),
                assignAnimalPenTarget: variables.assignAnimalPenTarget.toLowerCase(),
                difficultyProgression: [5,7],
                animalHitBoxSize: [0,0],
                indexAnimalSpawn: 5,
                indexAnimalSpawnPrompt: 4,
              },
              min: 5,
              max: 7,
            })
            : this.setState({
              variables: {
                ...variables,
                acceptedPen: variables.acceptedPen.toLowerCase(),
                assignAnimalPenTarget: variables.assignAnimalPenTarget.toLowerCase(),
                difficultyProgression: [5,7],
                animalHitBoxSize: [0,0],
                indexAnimalSpawn: 5,
                indexAnimalSpawnPrompt: 4
              },
              min: 5,
              max: 7,
            })
        }
        break;
      case 'Hard':
        if (variables.hitBoxType === 'Location') {
          alwaysPrompt
            ? this.setState({
              variables: {
                ...variables,
                acceptedPen: variables.acceptedPen.toLowerCase(),
                assignAnimalPenTarget: variables.assignAnimalPenTarget.toLowerCase(),
                difficultyProgression: [5,10],
                animalHitBoxSize: [1.5,1.5],
                indexAnimalSpawn: 5,
                indexAnimalFound: 1,
                indexAnimalFoundPrompt: 5,
              },
              min: 5,
              max: 10,
            })
            : this.setState({
              variables: {
                ...variables,
                acceptedPen: variables.acceptedPen.toLowerCase(),
                assignAnimalPenTarget: variables.assignAnimalPenTarget.toLowerCase(),
                difficultyProgression: [5,10],
                animalHitBoxSize: [1.5,1.5],
                indexAnimalSpawn: 5,
                indexAnimalFound: 5,
                indexAnimalFoundPrompt: 1
              },
              min: 5,
              max: 10,
            })
        }
        else if (variables.hitBoxType === 'Drag'){
          alwaysPrompt
            ? this.setState({
              variables: {
                ...variables,
                acceptedPen: variables.acceptedPen.toLowerCase(),
                assignAnimalPenTarget: variables.assignAnimalPenTarget.toLowerCase(),
                difficultyProgression: [3,5],
                animalHitBoxSize: [30,30],
                indexAnimalSpawn: 5,
                indexAnimalSpawnPrompt: 1
              },
              min: 3,
              max: 5,
            })
            : this.setState({
              variables: {
                ...variables,
                acceptedPen: variables.acceptedPen.toLowerCase(),
                assignAnimalPenTarget: variables.assignAnimalPenTarget.toLowerCase(),
                difficultyProgression: [3,5],
                animalHitBoxSize: [30,30],
                indexAnimalSpawn: 5,
                indexAnimalSpawnPrompt: 1
              },
              min: 3,
              max: 5,
            })
        }
        else if (variables.hitBoxType === 'Swipe'){
          alwaysPrompt
            ? this.setState({
              variables: {
                ...variables,
                acceptedPen: variables.acceptedPen.toLowerCase(),
                assignAnimalPenTarget: variables.assignAnimalPenTarget.toLowerCase(),
                difficultyProgression: [3,5],
                animalHitBoxSize: [0,0],
                indexAnimalSpawn: 5,
                indexAnimalSpawnPrompt: 4,
              },
              min: 3,
              max: 5,
            })
            : this.setState({
              variables: {
                ...variables,
                acceptedPen: variables.acceptedPen.toLowerCase(),
                assignAnimalPenTarget: variables.assignAnimalPenTarget.toLowerCase(),
                difficultyProgression: [3,5],
                animalHitBoxSize: [0,0],
                indexAnimalSpawn: 5,
                indexAnimalSpawnPrompt: 4
              },
              min: 3,
              max: 5,
            })
        }
        break;
      default:
        if (variables.difficultyProgression){
          this.setState({
            min: variables.difficultyProgression[0],
            max: variables.difficultyProgression[1]
          })
        }
        else {
          this.setState({
            min: 7,
            max: 9,
            variables: {
              ...variables,
              difficultyProgression: [7,9],
            }
          })
        }
        break;
    }
  };

  checkForErrors = (ev) => {
    if (!ev.target.value){
      this.setState({ error: {...this.state.error, [ev.target.name]: true }})
    }
    else {
      let error = this.state.error;
      delete error[ev.target.name];
      this.setState({ error })
    }
  };

  render()  {
    const { classes, open, close, back, levels, levelTemplate } = this.props;
    const { tested, level, error } = this.state;
    const gameId = this.props.selectedGame.id;
    const gameLevels = levels.filter(level => level.gameId === gameId);
    const variables = levelTemplate ? JSON.parse(levelTemplate.levelVariables) : [];
    const directionType = variables.directionType ? variables.directionType : ['cardinal', 'clock', 'relative'];
    const disabled = Object.keys(error).length > 0 || !this.state.directionType;
    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        open={open}
        onClose={close}
        aria-labelledby='skill-dialog-title'
        PaperProps={{ classes:{ root: classNames(classes.container, classes.skillDatabank) }}}
      >
        <DialogTitle disableTypography={true} className={ classes.title }>
          <Typography className={ classes.titleText } variant='h4' id='skill-dialog-title'><FormattedMessage id='Wizard.skillDB.title'/></Typography>
          <IconButton
            onClick={this.close}
            className={ classes.exitIcon }
            aria-label="close dialog button"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={ classes.root }>
          <div>
            <Typography gutterBottom variant='h6'><FormattedMessage id='Wizard.skillDB.levelSelect'/>:</Typography>
            <FormGroup>
              <TextField
                disabled={this.props.edit}
                select
                variant='outlined'
                label={<FormattedMessage id='General.levels' />}
                name='level'
                onChange={this.handleLevelChange}
                value={this.state.selectedLevel}
              >
                {
                  gameLevels.map(level => (<MenuItem key={level.id} value={level.id}> {level.name} </MenuItem>))
                }
              </TextField>
            </FormGroup>
            {
              level.id &&
              <FormGroup classes={{ root: classes.safariFix }}  className={classes.level}>
                <Typography variant='h6'><FormattedMessage id='General.description' />:</Typography> {level.description+"\n\n"}
                <div className={classes.configureInfo}>
                  <Typography variant='h6'><FormattedMessage id='Wizard.skillDB.levelConfig' />:</Typography>
                </div>
                <div className={classes.configureLine}>
                  <strong className={classes.configTitle}> <FormattedMessage id='Wizard.Barnyard.direction' />: </strong>
                  <TextField
                    select
                    variant='outlined'
                    className={classes.configComponent}
                    name='directionType'
                    onChange={this.handleChange}
                    value={this.state.directionType}
                  >
                    {
                      Array.isArray(directionType) &&
                      directionType.map((option, index) => (<MenuItem key={index} value={option}> {this.formatName(option)} </MenuItem>))
                    }
                  </TextField>
                </div>
                <div className={classes.configureLine}>
                  <strong className={classes.configTitle}><FormattedMessage id='Wizard.Barnyard.prompt'/></strong>
                  <TextField
                    select
                    variant='outlined'
                    options={[true, false]}
                    className={classes.configComponent}
                    name='alwaysPrompt'
                    onChange={this.handleChange}
                    value={this.state.alwaysPrompt}
                  >
                    {
                      [
                        <MenuItem key={true} value={true}><FormattedMessage id='General.yes' /></MenuItem>,
                        <MenuItem key={false} value={false}><FormattedMessage id='General.no' /></MenuItem>
                      ]
                    }
                  </TextField>
                </div>
                {
                  this.state.alwaysPrompt === false &&
                  <>
                    <div className={classes.configureLine}>
                      <strong className={classes.configTitle}> <FormattedMessage id='Wizard.Barnyard.mistakes' />: </strong>
                      <TextField
                        variant='outlined'
                        className={classes.configComponent}
                        type='number'
                        name='errorThreshold'
                        onBlur={this.checkForErrors}
                        error={error.errorThreshold}
                        helperText={error.errorThreshold ? '* required' : ''}
                        onChange={this.handleChange}
                        value={this.state.errorThreshold}
                      />
                    </div>
                    <div className={classes.configureLine}>
                      <strong className={classes.configTitle}> <FormattedMessage id='Wizard.Barnyard.animal' />: </strong>
                      <TextField
                        variant='outlined'
                        className={classes.configComponent}
                        type='number'
                        name='orderToRandomLoopNumber'
                        onBlur={this.checkForErrors}
                        error={error.orderToRandomLoopNumber}
                        helperText={error.orderToRandomLoopNumber ? '* required' : ''}
                        onChange={this.handleChange}
                        value={this.state.orderToRandomLoopNumber}
                      />
                    </div>
                  </>
                }
                <div className={classes.configureLine}>
                  <strong className={classes.configTitle}><FormattedMessage id='General.difficulty' /></strong>
                  <TextField
                    select
                    variant='outlined'
                    className={classes.configComponent}
                    name='difficulty'
                    value={this.state.difficulty}
                    onChange={this.handleChange}
                  >
                    {
                      [
                        { label: <FormattedMessage id='General.easy' />, value: 'Easy' },
                        { label: <FormattedMessage id='General.medium' />, value: 'Medium' },
                        { label: <FormattedMessage id='General.hard' />, value: 'Hard' },
                      ].map(difficulty => (<MenuItem key={difficulty.value} value={difficulty.value}> {difficulty.label} </MenuItem>))
                    }
                  </TextField>
                </div>
                {
                  this.state.difficulty &&
                  <>
                    <div>
                      <Typography gutterBottom>
                        Difficulty dictates the time in seconds the user has to put an animal in to their pen.
                        The maximum amount of time a student has to put the animal in the pen is what they will be given on their first play-through.
                        With each successive play-through in which they achieve their target percentage the time allowed will decrease incrementally towards the minimum.
                      </Typography>
                    </div>
                    <div className={classes.configureLine}>
                      <strong className={classes.configTitle}> Maximum amount of time (Seconds): </strong>
                      <TextField
                        type='number'
                        variant='outlined'
                        className={classes.configComponent}
                        name='max'
                        onChange={this.handleChange}
                        onBlur={this.checkForErrors}
                        error={error.max}
                        helperText={error.max ? '* required' : ''}
                        value={this.state.max}
                      />
                    </div>
                    <div className={classes.configureLine}>
                      <strong className={classes.configTitle}> Minimum amount of time (Seconds): </strong>
                      <TextField
                        type='number'
                        variant='outlined'
                        className={classes.configComponent}
                        name='min'
                        onChange={this.handleChange}
                        onBlur={this.checkForErrors}
                        error={error.min}
                        helperText={error.min ? '* required' : ''}
                        value={this.state.min}
                      />
                    </div>
                  </>
                }
              </FormGroup>
            }
            <DialogActions className={classes.buttons}>
              <Button onClick={back} color='secondary'>
                <FormattedMessage id='General.back' />
              </Button>
              <Button
                onClick={ this.runTest }
                variant={ tested ? 'outlined' : 'text' }
                color={ tested ? 'secondary' : 'primary' }
              >
                { tested ? <FormattedMessage id='General.retest' /> : <FormattedMessage id='General.test' />}
              </Button>
              <Button disabled={disabled} onClick={this.handleSubmit} color='primary'>
                <FormattedMessage id='General.next' />
              </Button>
            </DialogActions>
          </div>
        </DialogContent>
      </Dialog>
    )
  }
}

const  mapState = ({ platform: { levels }}) => {
  return {
    levels,
  }
};

const mapDispatch = (dispatch) => {
  return {
    fetchLevels: () => dispatch(fetchLevels())
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(BarnyardSkillDatabank));