import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { InsertChartOutlined, ThreeSixty } from '@material-ui/icons';
import { Card, Divider, Typography, Button, CardContent, Slider, Box, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../styles/dashboard';
import { BarChart as BarIcon, Close } from '@material-ui/icons';
import { PieChartRounded } from '@material-ui/icons';
import { Timeline } from '@material-ui/icons';
import { ResponsiveBar, Bar } from '@nivo/bar';
import Carousel from 'nuka-carousel';
import { ResponsiveLine, Line } from '@nivo/line';
import { Paper, Toolbar, Tooltip } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import DataTable from '../../components/Shared/DataTable';
import PercentToTargetLine from '../../components/Charts/PercentToTargetLine';
import { FormattedMessage } from 'react-intl';
import ProgressBar from "@ramonak/react-progress-bar";
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { getProgress, getSevenDayTime, getPhonemes, getStudent, getAccuracy, getWordsPerMinute, getPhonemesSpecific, getAIAudioFile, getStudentProfile, getReviewData, getReadingTime, patchStudentProfile, checkStudentProfile, createStudentProfile, getBooksForStudent, getWPMBooks } from '../../../redux/actions'
import { Link } from 'react-router-dom';
import {
    CircularProgressbarWithChildren,
    buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import PhonemesReview from '../../components/Shared/ReviewModal';
import LastSevenDay from '../../components/Shared/LastSevenDay';


const minutes = (value) => {
    let val = (Math.round(value) / 60.0).toFixed(0);
    let type = "Minutes";
    if (val > 60) {
        val = (value / 60.0).toFixed(1)
        type = "Hours";
    }
    return `${val} ${type}`
};

const minutesAxis = (value) => {
    let val = (Math.round(value) / 60.0).toFixed(0);
    let type = "Minutes";
    if (val > 60) {
        val = (value / 60.0).toFixed(1)
        type = "Hours";
    }
    return `${val}`
}

const percentage = (value) => {
    return `${value}%`;
}

const dateFormat = (value) => {
    return `${moment.utc(value).toDate()}`;
}

const CollisionLink = (url) => React.forwardRef((props, ref) => (
    <Link innerRef={ref} to={url} {...props} />
));

const selectOptions = [30,90,365,"All Time"]

class BuddyBooksDashboard extends Component {

    state = {
        progress: {},
        timeChart: [],
        phonemes: [],
        phonemesSpecific: [],
        accuracy: [],
        wordsPerMinute: [],
        time: 7,
        clicked: false,
        skill: {},
        reviewData: [],
        readingTime: [],
        readingTimeValue: 10,
        downloadComplete: false,
        bookListWPM: [],
        accuracyOption: 30,
        wordsPerMinuteOption: 30
    };

    componentDidMount() {
        const endDate = moment().add(1, 'day').format('YYYY-MM-DD');
        const startDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
        
        this.props.getStudent(this.props.id);
        getProgress(this.props.id).then(data => {
            if (data) {
                this.setState({ progress: data })
            }
            this.setState({ progressLoaded: true })
        });
        getSevenDayTime(this.props.id).then(data => {
            if (data) {
                data = data.reverse();
                this.setState({ timeChart: data });
            }
        });
        getPhonemes(this.props.id).then(data => {
            if (data) {
                let dataAltered = data.map(d => {
                    return { "phoneme": d.phoneme, "averageScore": d.averageScore.toFixed(0) }
                })
                this.setState({ phonemes: dataAltered });
            }
            this.setState({ phonemeLoaded: true })
        });
        this.fetchAccuracy(startDate,endDate)
        this.fetchWordsPerMinute(startDate,endDate)
        getReviewData(this.props.id).then(data => {
            if (data) {
                console.log('getReview dashboard response', data);
                let dataAltered = data.map(d => {
                    var firstDate = new Date(d.firstTime);
                    var secondDate = new Date(d.secondTime);
                    console.log(d.firstTime);
                    console.log(firstDate.toLocaleDateString("en-us"));
                    var firstAnalysis = JSON.parse(d.firstAnalysis);
                    var secondAnalysis = JSON.parse(d.secondAnalysis);
                    return {
                        "text": d.text,
                        "firstTime": firstDate.toLocaleDateString("en-US"),
                        "firstScore": d.firstScore.toFixed(0),
                        "firstAnalysis": firstAnalysis,
                        "firstFileId": d.firstFileId,
                        "secondTime": secondDate.toLocaleDateString("en-US"),
                        "secondScore": d.secondScore.toFixed(0),
                        "secondAnalysis": secondAnalysis,
                        "secondFileId": d.secondFileId,
                    };
                });
                this.setState({ reviewData: dataAltered });
            }
            this.setState({ reviewDataLoaded: true })
        })
        getReadingTime(this.props.id).then(data => {
            this.setState({
                readingTime: data,
                downloadComplete: true
            });
        })
        this.props.getStudentProfile(this.props.id);
        checkStudentProfile(this.props.id).catch((e) => {
            this.props.createStudentProfile({ studentId: this.props.id, readingTime: 10 });
        })
        this.props.getBooksForStudent(this.props.id);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.studentProfile != this.props.studentProfile) {
            this.setState({
                readingTimeValue: this.props.studentProfile.readingTime
            });
        }
        if (prevState.wordsPerMinute != this.state.wordsPerMinute) {
            let uniqueBookId = new Set();
            this.state.wordsPerMinute.map(record => uniqueBookId.add(record.bookId));
            getWPMBooks(this.props.id, [...uniqueBookId]).then((res) => {
                this.setState({ bookListWPM: res })
            })
        }
    }

    fetchWordsPerMinute=(startDate,endDate)=>{
        this.setState({ wordsPerMinuteLoaded: false })
        getWordsPerMinute(this.props.id,startDate,endDate).then(data => {
            if (data) {
                let dataAltered = data.map(d => {
                    return { "x": d.date, "y": d.wordsPerMinute > 200 ? 200 : d.wordsPerMinute.toFixed(0) , "bookId": d.bookId };
                })
                if( dataAltered.length === 1){
                    const date = dataAltered[0].x
                    const mockArray = [{"x": moment.utc(date).subtract(1,'days').format("YYYY-MM-DD"), "y": "" , "bookId": "" },...dataAltered,{"x": moment.utc(date).add(1,'days').format("YYYY-MM-DD"), "y": "" , "bookId": "" }]
                    dataAltered = mockArray
                }
                this.setState({ wordsPerMinute: dataAltered });
            }
            this.setState({ wordsPerMinuteLoaded: true })
        });
      }
    fetchAccuracy=(startDate,endDate)=>{
        this.setState({ accuracyLoaded: false })
        getAccuracy(this.props.id,startDate,endDate).then(data => {
            if (data) {
                let dataAltered = data.map(d => {
                    return { "x": moment.utc(d.x).format("MM-DD-YYYY"), "y": d.y }
                })
                this.setState({ accuracy: data });
            }
            this.setState({ accuracyLoaded: true })
        });

      }

      handleFilterChange = (event,type) => {
        const { value } = event.target;
        const endDate = value !== "All Time" ? moment().add(1, 'day').format('YYYY-MM-DD') : ""; // Current date + 1 day
        const startDate = value !== "All Time" ? moment().subtract(value, 'days').format('YYYY-MM-DD') : "";
        if(type === "accuracy"){
            this.setState({accuracyOption: value})
            this.fetchAccuracy(startDate,endDate)
        }
        if(type === "wordsPerMinute"){
            this.setState({wordsPerMinuteOption: value})
            this.fetchWordsPerMinute(startDate,endDate)
        }
      };
      
    showPhonemeModal = (data) => {
        this.setState({ openPhonemes: true, phoneme: data.phoneme });
        getPhonemesSpecific(this.props.id, data.phoneme).then(ret => {
            let dataAltered = ret.map(d => {
                var date = new Date(d.date);
                var analysis = JSON.parse(d.analysis)
                return { "text": d.text, "score": d.score.toFixed(0), "date": date.toLocaleDateString("en-US"), "fileId": d.fileId, analysis }
            }).filter(d => {
                return d.score > 30 && d.score < 90;
            })
            this.setState({ phonemesSpecific: dataAltered, phonemesSpecificLoaded: true })
        })
    }

    closePhonemeModal = () => {
        this.setState({ openPhonemes: false, phonemesSpecific: [] });
    }

    showReviewModal = (data, reviewMode = false) => {
        this.setState({ openPhonemesReview: true, reviewData: data, reviewMode });
        console.log('review data', data);
    }

    closeReviewModal = () => {
        this.setState({ openPhonemesReview: false });
    }

    downloadPhoneme = (data) => {
        getAIAudioFile(this.props.id, data.fileId);
    }
    saveReadingTarget = (newVal) => {
        let studentProfile = this.props.studentProfile;
        studentProfile.readingTime = newVal;
        if (studentProfile.id && studentProfile.id !== 'null') {
            this.props.patchStudentProfile(studentProfile);
        } else {
            this.props.getStudentProfile(this.props.id);
            setTimeout(() => {
                let studentProfile = this.props.studentProfile;
                studentProfile.readingTime = newVal;
                this.props.patchStudentProfile(studentProfile);
            }, 600)
        }
        this.setState({ "readingTimeValue": newVal });
    }
    calculateMinuteTickValue = () => {
        let startMinuteRecord = this.state.wordsPerMinute && this.state.wordsPerMinute[0] && this.state.wordsPerMinute[0].x;
        let endMinuteRecord = this.state.wordsPerMinute && this.state.wordsPerMinute[this.state.wordsPerMinute.length - 1] && this.state.wordsPerMinute[this.state.wordsPerMinute.length - 1].x;
        startMinuteRecord = moment(startMinuteRecord);
        endMinuteRecord = moment(endMinuteRecord);
        return endMinuteRecord.diff(startMinuteRecord, 'days')
    }
    calculateAccuracyTickValue = () => {
        let startAccuracyRecord = this.state.accuracy && this.state.accuracy[0] && this.state.accuracy[0].x;
        let endAccuracyRecord = this.state.accuracy && this.state.accuracy[this.state.accuracy.length - 1] && this.state.accuracy[this.state.accuracy.length - 1].x;
        startAccuracyRecord = moment(startAccuracyRecord);
        endAccuracyRecord = moment(endAccuracyRecord);
        return endAccuracyRecord.diff(startAccuracyRecord, 'days');   
    }
    render() {
        const { classes, student, history, studentProfile } = this.props;
        const rows = [
            { id: 'phoneme', label: <FormattedMessage id='BuddyBooks.phoneme' /> },
            { id: 'averageScore', label: <FormattedMessage id='BuddyBooks.averageScore' /> },
        ];

        const rows2 = [
            { id: 'text', label: <FormattedMessage id='BuddyBooks.text' /> },
            { id: 'score', label: <FormattedMessage id='BuddyBooks.score' /> },
            { id: 'date', label: <FormattedMessage id={'BuddyBooks.date'} /> }

        ]

        const rows3 = [
            { id: 'text', label: <FormattedMessage id='BuddyBooks.text' /> },
            { id: 'firstTime', label: <FormattedMessage id='BuddyBooks.firstTime' /> },
            { id: 'firstScore', label: <FormattedMessage id={'BuddyBooks.firstScore'} /> },
            { id: 'secondTime', label: <FormattedMessage id={'BuddyBooks.secondTime'} /> },
            { id: 'secondScore', label: <FormattedMessage id={'BuddyBooks.secondScore'} /> },

        ]
        const TitleAcc = ({ width, height }) => {
            const style = { fontWeight: 'bold' }

            return (
                <text
                    x="44%" dominantBaseline="middle" textAnchor="middle"
                    y={-15}
                    style={style}
                >
                    Total Accuracy
                </text>
            )
        };
        const Title = ({ width, height }) => {
            // You can console.log(data) to see what other properties are available
            const style = { fontWeight: 'bold' }

            return (
                <text
                    x="36%" dominantBaseline="middle" textAnchor="middle"
                    y={-18}
                    style={style}
                >
                    Last Seven Days Reading Time in Minutes
                </text>
            )
        };


        const tooltip = (slice, label) => {
            const options = { weekday: 'long', month: 'numeric', day: 'numeric' };
            let bookId = "";
            let bookDetail = {};
            if (label === 'Words Per Minute') {
                bookId = slice.slice.points[0].data.bookId;
                bookDetail = (this.props.books && this.props.books.filter(book => book.id == bookId));
                let res = this.state.bookListWPM.filter(book => book.id == bookId);
                bookDetail = bookDetail.length == 0 ? res : bookDetail;
            }
            return (
                <>
                {!isNaN(slice.slice.points[0].data.y) ?
                <div
                    style={{
                        background: 'white',
                        padding: '9px 12px',
                        border: '1px solid #ccc',
                    }}>
                    <div>
                        <strong>{slice.slice.points[0].data.x.toLocaleDateString('en-US', options)}</strong>
                    </div>
                    <div>
                        {
                            label === 'Accuracy'
                                ? <>Accuracy: <strong>{Math.round(slice.slice.points[0].data.y)}%</strong></>
                                :
                                label === 'Words Per Minute' ?
                                    <>
                                        WCPM: <strong>{slice.slice.points[0].data.y >= 200 ? "200+" : Math.round(slice.slice.points[0].data.y.toFixed(0))}</strong>
                                        <br />
                                        Book: <strong>{bookDetail.length > 0 && bookDetail[0].name}</strong> <br />
                                        {/* Render Lexile levels only if the nvp are found in data */}
                                        { bookDetail.length > 0 && (bookDetail[0].gradeLevel || bookDetail[0].gradeLevel === 0 ) && 
                                            <>Grade Level: <strong>{bookDetail[0].gradeLevel === 0 ? 'K' : bookDetail[0].gradeLevel}</strong> <br /></>
                                        }

                                    </> : <>{label}: <strong>{Math.round(slice.slice.points[0].data.y.toFixed(0))}</strong></>
                        }
                    </div>
                </div>: ""}
                </>
            )
        };

        
        const strokeWidth = 7;
        const rotation = 0.625;
        const circleRatio = 0.75;
        const numSeparators = 10;
        const targetValue = this.props.studentProfile ? this.props.studentProfile.readingTime * 60 : 15 * 60;
        let tickpoint = 0;
        const tickValues = [];
        while (tickpoint <= targetValue) {
            tickValues.push(tickpoint);
            tickpoint = tickpoint + 300;
        }
        tickValues.push(tickpoint);
        let specificTickValue = this.state.wordsPerMinute.length > 8 ? 5 : 'every day';
        let specificTickValueAcc = this.state.accuracy.length > 8 ? 5 : 'every day';
        let diffMinuteRecord = this.calculateMinuteTickValue();
        let diffAccuracyRecord = this.calculateAccuracyTickValue();

        if (diffMinuteRecord >= 14) {
            specificTickValue = 7;
        }
        if (diffAccuracyRecord >= 14) {
            specificTickValueAcc = 7;
        }
        const percentageProgress = this.state.progress && this.state.progress.currentProgress ? (this.state.progress.currentProgress * 100).toFixed(0) : 0;
        const getColors = (chart) => {
            if (chart.id == 'Words Correct Per Minute')
                return "#0087b6";
            else if (chart.id == 'Under50')
                return "rgba(255, 0, 0,0.1)";
            else if (chart.id == 'Under150')
                return "rgba(255, 255, 0,0.3)";
            else
                return 'rgba(0, 255, 0,0.15)'
        }
        let under50Data = {};
        let under150Data = {};
        let under200Data = {};
        let bookChange = [];
        if (this.state.wordsPerMinute && this.state.wordsPerMinute.length > 0) {
            let length = this.state.wordsPerMinute.length;
            let bookChangePoints = [];
            for (let i = 1; i < length; i++) {
                if (this.state.wordsPerMinute[i].bookId != this.state.wordsPerMinute[i - 1].bookId) {
                    bookChangePoints.push(
                        {
                            axis: 'x',
                            value: new Date(this.state.wordsPerMinute[i].x),
                            lineStyle: {
                                stroke: 'black',
                                strokeWidth: 1.5,
                                opacity: 0.8,
                            },
                        })
                }
            }
            under50Data = {
                "id": 'Challenge', color: 'hsl(301, 70%, 50%)',
                "data": [{ x: this.state.wordsPerMinute[0].x, y: '50' },
                { x: this.state.wordsPerMinute[length - 1].x, y: '50' }]
            };
            under150Data = {
                "id": 'Instructional', color: 'hsl(1, 83%, 69%)',
                "data": [{ x: this.state.wordsPerMinute[0].x, y: '150' },
                { x: this.state.wordsPerMinute[length - 1].x, y: '150' }]
            };
            under200Data = {
                "id": 'Fluent', color: 'green',
                "data": [{ x: this.state.wordsPerMinute[0].x, y: '200' },
                { x: this.state.wordsPerMinute[length - 1].x, y: '200' }]
            };
            bookChange.push(...bookChangePoints);
        }
        const dataSourceWPM = this.state.wordsPerMinute.length > 0 ? [under50Data, under150Data, under200Data,
            { "id": "Words Correct Per Minute", "data": this.state.wordsPerMinute }]
            : [{ "id": "Words Correct Per Minute", "data": this.state.wordsPerMinute }];

        return (
            <div className={classes.container}>
                <div className={classes.title}>
                    <InsertChartOutlined className={classes.icon} fontSize='inherit' />
                    <Typography className={classes.text} variant='h3'>
                        {student.firstName}'s <FormattedMessage id='Dashboard.title' />
                    </Typography>
                </div>
                <Divider />
                <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                    <Button component={CollisionLink('/reading/progress/' + student.id)} variant='contained'>
                        Progress Report
                    </Button>
                </div>
                <div style={{ marginBottom: "80px" }}>

                    <Card aria-label="Last Seven Days Reading Time">
                        <div className={classes.header}>
                            <BarIcon className={classes.icon} />
                            <Typography className={classes.chartTitle} variant='h5'>Reading Time &nbsp; - &nbsp; Last 7 Days</Typography>
                        </div>
                        <CardContent>
                            <div style={{
                                'display': 'flex',
                                'margin': '0 auto',
                                'width': '80%',
                                'padding': '0 16px 0 6px',
                                'boxSizing': 'border-box'
                            }}>
                                <Typography style={{ paddingRight: "10px", minWidth: "fit-content" }}>
                                    <FormattedMessage id='BuddyBooks.ReadingTarget' />: </Typography>
                                <Slider
                                    className='slider'
                                    value={this.state.readingTimeValue}
                                    onChange={(ev, newVal) => this.saveReadingTarget(newVal)}
                                    valueLabelDisplay='auto'
                                    name='curtainOpacity'
                                    defaultValue={this.state.readingTimeValue}
                                    step={5}
                                    min={10}
                                    max={90}
                                    marks={[
                                        { value: 10, label: '10' },
                                        { value: 15, label: '15' },
                                        { value: 20, label: '20' },
                                        { value: 25, label: '25' },
                                        { value: 30, label: '30' },
                                        { value: 35, label: '35' },
                                        { value: 40, label: '40' },
                                        { value: 45, label: '45' },
                                        { value: 50, label: '50' },
                                        { value: 55, label: '55' },
                                        { value: 60, label: '60' },
                                        { value: 65, label: '65' },
                                        { value: 70, label: '70' },
                                        { value: 75, label: '75' },
                                        { value: 80, label: '80' },
                                        { value: 85, label: '85' },
                                        { value: 90, label: '90' },
                                    ]}
                                />
                            </div>
                            <br />
                            <LastSevenDay
                                data={this.state.readingTime}
                                targetReadingTime={studentProfile.readingTime}
                                downloadComplete={this.state.downloadComplete}
                                studentId={this.props.id}
                            />
                        </CardContent>
                        <Divider />
                    </Card>
                </div>
                <div className={classes.charts}>
                    <div>
                        <Card aria-label="Progress On Book">
                            <div className={classes.header}>
                                <PieChartRounded className={classes.icon} />
                                <Typography className={classes.chartTitle} variant='h5'>Progress On Book</Typography>
                            </div>
                            <Divider />
                            <div>

                                <div
                                    style={{
                                        align: "center",
                                        maxWidth: "49%",
                                        maxHeight: "49%",
                                        width: 'fit-content',
                                        height: '314px',
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        marginTop: "10px",
                                    }}
                                >
                                    { this.state.progressLoaded ?
                                    <CircularProgressbarWithChildren
                                        value={percentageProgress}
                                        strokeWidth={strokeWidth}
                                        circleRatio={circleRatio}
                                        styles={buildStyles({
                                            strokeLinecap: "butt",
                                            rotation: rotation
                                        })}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            {/*<RadialSeparators*/}
                                            {/*    values={_.range(numSeparators + 1).map(*/}
                                            {/*        index => (circleRatio / numSeparators) * index + rotation*/}
                                            {/*    )}*/}
                                            {/*    style={{*/}
                                            {/*        background: "#888",*/}
                                            {/*        width: "2px",*/}
                                            {/*        height: "5%",*/}
                                            {/*        marginTop: -10*/}
                                            {/*    }}*/}
                                            {/*/>*/}
                                            <b>{percentageProgress}%</b>
                                        </div>
                                    </CircularProgressbarWithChildren>
                                    : <div style={{ display: 'flex', justifyContent: 'center', margin:'10px auto', alignItems:'center', height:'100%' }}><CircularProgress /></div> }
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div>
                        <Card aria-label="Progress">
                            <div>
                                <DataTable
                                    type="Phoneme"
                                    history={this.props.history}
                                    rows={rows}
                                    rowsPerPage={[5, 10, 15]}
                                    orderBy={'averageScore'}
                                    noActions={true}
                                    noSearch={true}
                                    noPagination={true}
                                    data={this.state.phonemes}
                                    numCols={2}
                                    clickable={true}
                                    clickFunc={this.showPhonemeModal}
                                    title={<FormattedMessage id='BuddyBooks.Phonemes' />}
                                    loader = {this.state.phonemeLoaded ? false : true}
                                />
                            </div>
                            <Dialog open={this.state.openPhonemes} fullWidth={true} maxWidth='xl'>
                                <Paper className={classes.list}>
                                    <DataTable
                                        type="Phoneme"
                                        history={this.props.history}
                                        rows={rows2}
                                        rowsPerPage={[5, 10, 15]}
                                        orderBy={'score'}
                                        noSearch={true}
                                        data={this.state.phonemesSpecific}
                                        numCols={3}
                                        noActions={true}
                                        clickable={true}
                                        reviewFunc={this.showReviewModal}
                                        downloadFunc={this.downloadPhoneme}
                                        exitFunc={this.closePhonemeModal}
                                        loader = {this.state.phonemesSpecificLoaded ? false : true}
                                        title={<div><FormattedMessage id='BuddyBooks.PhonemeSpecific' />: {this.state.phoneme} <FormattedMessage id='BuddyBooks.phoneme' /></div>}
                                    />
                                </Paper>
                            </Dialog>
                        </Card>
                    </div>
                </div>
                {this.state.accuracy &&
                    <div className={classes.chart}>
                        <Card aria-label="Total Accuracy">
                            <div className={classes.header}>
                                <Timeline className={classes.icon} />
                                <Typography className={classes.chartTitle} variant='h5'>Total Accuracy</Typography>
                                <div className={classes.selectContainer}>
                                    <label className={classes.labelStyles}>{}Time Filter: </label>
                                    <select
                                    id="accuracyTimeFilter"
                                    className={classes.selectStyles}
                                    value={this.state.accuracyOption}
                                    onChange={(e)=>{this.handleFilterChange(e,"accuracy")}}
                                    >
                                        {selectOptions.map(val=><option className={classes.optionStyles} value={val}>{val === "All Time" ? val : `${val} days`}</option>)}
                                        </select>
                                        </div>
                            </div>
                            <Divider />
                            <Box height={340}>
                                { this.state.accuracyLoaded ?
                                <ResponsiveLine
                                    data={[{ "id": "Accuracy", "data": this.state.accuracy }]}
                                    enableSlices={"x"}
                                    layers={['grid', 'markers', 'axes', 'areas', 'crosshair', 'lines', 'points', 'slices', 'mesh', 'legends', TitleAcc]}
                                    margin={{
                                        top: 30,
                                        right: 80,
                                        bottom: 60,
                                        left: 60
                                    }}
                                    sliceTooltip={(slice) => tooltip(slice, "Accuracy")}
                                    xScale={{
                                        type: 'time',
                                        format: '%Y-%m-%d',
                                        precision: 'day',
                                        useUTC: false
                                    }}
                                    yScale={{
                                        type: 'linear',
                                        stacked: false,
                                        min: 0,
                                        max: 100
                                    }}
                                    axisTop={null}
                                    axisRight={null}
                                    axisBottom={{
                                        tickValues: specificTickValueAcc,
                                        orient: 'bottom',
                                        tickSize: 10,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        format: '%-m/%-d'
                                    }}
                                    axisLeft={{
                                        tickValues: [0,25,50,75,100],
                                        orient: 'left',
                                        tickSize: 0,
                                        tickPadding: 15,
                                        tickRotation: 0,
                                        format: percentage
                                    }}
                                    colors={['#0087b6']}
                                    dotSize={10}
                                    dotColor='inherit:darker(0.3)'
                                    dotBorderWidth={1}
                                    dotBorderColor='inherit:darker(1)'
                                    animate={true}
                                    motionStiffness={90}
                                    motionDamping={15}
                                    theme={{
                                        axis: {
                                            ticks: {
                                                text: { fontSize: 16, fontWeight: 800 }
                                            }
                                        }
                                    }}
                                /> : <div style={{ display: 'flex', justifyContent: 'center', margin:'10px auto', alignItems:'center', height:'100%' }}><CircularProgress /></div>}
                            </Box>
                        </Card>
                    </div>
                }
                {this.state.wordsPerMinute &&
                    <div className={classes.chart}>
                        <Card aria-label="Words Correct Per Minute">
                            <div className={classes.header}>
                                <Timeline className={classes.icon} />
                                <Typography className={classes.chartTitle} variant='h5'>Words Correct Per Minute</Typography>
                                <div className={classes.selectContainer}>
                                    <label className={classes.labelStyles}>Time Filter:</label>
                                    <select
                                    id="wordsPerMinuteTimeFilter"
                                    className={classes.selectStyles}
                                    value={this.state.wordsPerMinuteOption}
                                    onChange={(e)=>{this.handleFilterChange(e,"wordsPerMinute")}}
                                    >
                                        {selectOptions.map(val=><option className={classes.optionStyles} value={val}>{val === "All Time" ? val : `${val} days`}</option>)}
                                        </select>
                                        </div>
                            </div>
                            <Divider />
                            <Box height={340}>
                                { this.state.wordsPerMinuteLoaded ?
                                <ResponsiveLine
                                    legends={[
                                        {
                                          anchor: "bottom",
                                          data: dataSourceWPM.slice(0, 3).map((item) => {
                                            const color = item.id === "Fluent" ? "#D1E8D1" : item.id === "Instructional" ? "#DCEE9F" : "#E4B579"
                                            return {
                                              color: color,
                                              id: item.id,
                                              label: item.id
                                            };
                                          }),
                                          direction: "row",
                                          itemHeight: 24,
                                          itemWidth: 120,
                                          translateY: 60
                                        }
                                      ]}
                                    markers={[...bookChange]}
                                    data={dataSourceWPM}
                                    enableSlices={"x"}
                                    layers={['grid', 'axes', 'areas', 'crosshair', 'lines',  'slices', 'mesh', 'legends', 'markers','points',]}
                                    margin={{
                                        top: 30,
                                        right: 80,
                                        bottom: 60,
                                        left: 60
                                    }}
                                    sliceTooltip={(slice) => tooltip(slice, "Words Per Minute")}
                                    xScale={{
                                        type: 'time',
                                        format: '%Y-%m-%d',
                                        precision: 'day',
                                        useUTC: false
                                    }}
                                    yScale={{
                                        type: 'linear',
                                        stacked: false,
                                        min: 0,
                                        max: 200
                                    }}
                                    axisTop={null}
                                    axisRight={null}
                                    axisBottom={{
                                        tickValues: specificTickValue,
                                        orient: 'bottom',
                                        tickSize: 10,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        format: '%-m/%-d'
                                    }}
                                    axisLeft={{
                                        tickValues: [0, 50, 100, 150, 200]
                                    }}
                                    colors={getColors}
                                    dotSize={10}
                                    dotColor='inherit:darker(0.3)'
                                    dotBorderWidth={1}
                                    dotBorderColor='inherit:darker(1)'
                                    animate={true}
                                    motionStiffness={90}
                                    motionDamping={15}
                                    theme={{
                                        axis: {
                                            ticks: {
                                                text: { fontSize: 16, fontWeight: 800 }
                                            }
                                        }
                                    }}
                                    defs={[{
                                        id: 'gradientA',
                                        type: 'linearGradient',
                                        colors: [
                                            { color: 'red', opacity: 0.4 },
                                        ],
                                    },
                                    {
                                        id: 'gradientB',
                                        type: 'linearGradient',
                                        colors: [
                                            { color: 'yellow', opacity: 0.4 },
                                        ],
                                    },
                                    {
                                        id: 'gradientC',
                                        type: 'linearGradient',
                                        colors: [
                                            { color: 'green', opacity: 0.3 },
                                        ],
                                    }, {
                                        id: 'gradientD',
                                        type: 'linearGradient',
                                        colors: [
                                            { color: 'white', opacity: 0 },
                                        ],
                                    }]}
                                    fill={[
                                        { match: d => d.id === 'Challenge', id: 'gradientA' },
                                        { match: d => d.id === 'Instructional', id: 'gradientB' },
                                        { match: d => d.id === 'Fluent', id: 'gradientC' },
                                        { match: '*', id: 'gradientD' },
                                    ]}
                                    enableArea={true}
                                    areaOpacity={0.6}
                                /> : <div style={{ display: 'flex', justifyContent: 'center', margin:'10px auto', alignItems:'center', height: '100%' }}><CircularProgress /></div>}
                            </Box>
                        </Card>
                    </div>
                }
                {this.state.reviewData &&
                    <div>
                        <Card aria-label="Review">
                            <div>
                                <DataTable
                                    type="ReviewMode"
                                    history={this.props.history}
                                    rows={rows3}
                                    rowsPerPage={[5, 10, 15]}
                                    orderBy={'score'}
                                    noActions={true}
                                    noSearch={true}
                                    // noPagination={true}
                                    data={this.state.reviewData}
                                    numCols={5}
                                    clickable={true}
                                    reviewFunc={this.showReviewModal}
                                    loader={this.state.reviewDataLoaded ? false : true}
                                    title={<FormattedMessage id='BuddyBooks.ReviewMode' />}
                                />
                            </div>
                        </Card>
                    </div>
                }
                <Dialog open={this.state.openPhonemesReview} fullWidth={true} maxWidth='xl'>
                    <Paper className={classes.list}>
                        <PhonemesReview
                            data={this.state.reviewData}
                            title={this.state.reviewMode ? 'Review Mode' : `Phonemes (${this.state.phoneme}): Review`}
                            studentId={this.props.id}
                            exitReview={this.closeReviewModal}
                        />
                    </Paper>
                </Dialog>
            </div>
        );
    }

}

const mapState = ({ platform: { skills, studentProfile, student, books } }) => {
    return {
        skills,
        student,
        studentProfile,
        books
    }
};

const mapDispatch = (dispatch) => {
    return {
        getStudent: (id) => dispatch(getStudent(id)),
        getStudentProfile: (id) => dispatch(getStudentProfile(id)),
        patchStudentProfile: (studentProfile) => dispatch(patchStudentProfile(studentProfile)),
        createStudentProfile: (studentProfile) => dispatch(createStudentProfile(studentProfile)),
        getBooksForStudent: (studentId) => dispatch(getBooksForStudent(studentId)),
    }
};
export default connect(mapState, mapDispatch)(withStyles(baseStyle)(BuddyBooksDashboard));