export const GET_BOOKS_FOR_STUDENT = 'GET_BOOKS_FOR_STUDENT';
export const GET_ALL_BOOKS = "GET_ALL_BOOKS";
export const GET_STORY_LIST = "GET_STORY_LIST";
export const GET_DATABANK = "GET_DATABANK";
export const ADD_BOOK_TO_STUDENT = "ADD_BOOK_TO_STUDENT";
export const DELETE_BOOK = "DELETE_BOOK";
export const SEARCH_FOR_BOOKS = "SEARCH_FOR_BOOKS";
export const SEARCH_FOR_BOOKS_FAIL = "SEARCH_FOR_BOOKS_FAIL";
export const ADD_BOOK_TO_STUDENT_FAILED = "ADD_BOOK_TO_STUDENT_FAILED";
export const BOOK_SHARE_LOGIN_VERIFED = "BOOK_SHARE_LOGIN_VERIFED";
export const GET_BOOKSHARE_USERS = "GET_BOOKSHARE_USERS";
export const GET_BOOKSHARE_IDENTITY = "GET_BOOKSHARE_IDENTITY";
export const ADD_BOOKSHARE_USER = "ADD_BOOKSHARE_USER";
export const STUDENT_BOOK_IMPORTED = "STUDENT_BOOK_IMPORTED";
export const RESET_IMPORT_MODAL = "RESET_IMPORT_MODAL";
export const SAVE_IMPORTED_BOOK = "SAVE_IMPORTED_BOOK";
export const REMOVE_IMPORTED_BOOK = "REMOVE_IMPORTED_BOOK";
export const REMOVE_STUDENT_BOOK = "REMOVE_STUDENT_BOOK";
export const SEARCH_FOR_BOOKS_FOUND = "SEARCH_FOR_BOOKS_FOUND";
export const REMOVE_SEARCH_IMPORTED_BOOK = "REMOVE_SEARCH_IMPORTED_BOOK";
export const RESET_BOOKLIST_TEMP = "RESET_BOOKLIST_TEMP";
export const GET_BOOKSHARE_REQUEST_BOOKS = "GET_BOOKSHARE_REQUEST_BOOKS";
export const DELETE_BOOKSHARE_REQUEST_BOOK = "DELETE_BOOKSHARE_REQUEST_BOOK";