export default {
  header: {
    gridColumn: 1,
    gridRow: 1,
    flex: '0 0 auto',
    fontSize: '24px',
    color: '#fff',
    marginLeft: '20px',
  },
  toolbar: {
    display: 'grid',
    gridTemplateColumns: '8fr 1fr',
    gridTemplateRows: '1fr',
    borderRadius: '5px 5px 0 0',
    backgroundColor: '#00729b',
    boxShadow: ' 0px 3px 3px #6b6b6b',
  },
  list: {
    borderRadius: '5px',
    overflowX: 'auto',
  },
  textWrapper: {
    height: '13rem',
    background: '#ddf0ff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  innerTextWrap: {
    // position: 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    textAlign: 'justify',
    width: 'fit-content',
    padding: '0 50px',
    boxSizing: 'border-box'
  },
  popover: {
    pointerEvents: 'none',
    boxShadow: "none"
  },
  paper: {
    padding: '8px',
    outline: "none",
    boxShadow: "1px -0.5px 0 #eee, 1px -0.5px 2px #ccc"
  },
  popWord: {
    textAlign: "center",
    color: "darkgreen",
    fontWeight: "700",
    fontSize: "18px"
  },
  typography: {
    padding: '8px',
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    background: '#ddf0ff',
    color: '#ffffff'
  },
  highlight: {
    background: 'rgb(255, 218, 185)',
    boxShadow: '1px 1px 6px #e25'
  },
  exitIcon: {
    float: 'right',
    color: '#fff',
    width: '48px',
    marginLeft: '20px'
  },
  buttons: {
    // maxWidth: 250,
    wordWrap: 'break-word',
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'end',
    alignContent: 'center'
  },
  analysisContainer: {
    display: 'flex',
    background: '#ddf0ff',
    padding: '0 0 20px'
  },
  analysisContInnerBox: {
    background: 'white',
    margin: '0 10px',
    width: '25%',
    padding: '3px 6px',
    borderRadius: '10px',
    boxShadow: '2px 0px 5px #aaa, 1.1px -1px 12px #bbb',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}