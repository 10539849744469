import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Typography, Divider } from '@material-ui/core/index';
import { Settings, } from '@material-ui/icons/index';
import { withStyles } from '@material-ui/core/styles/index';
import TutorialList from '../../components/Student/TutorialList';
import SpeechData from '../../components/Student/SpeechData';
// import EntertainmentGameList from '../../components/Student/EntertainmentGameList';
// import EntertainmentGameWizardBase from '../../components/Wizards/Games/EntertainmentGameWizardBase';
import StudentPersonalInfo from '../../components/Student/StudentPersonalInfo';
import StudentConfig from '../../components/Student/StudentConfig';
import {
  getStudent,
  patchStudent,
  fetchTutorialsForStudent,
  getStudentProfile,
  patchStudentProfile,
  createStudentProfile,
  toggleStudentLogin,
  clearErrors,
  checkStudentCount,
  changeStudentPassword,
  getStudentGames, addProduct, removeProduct,fetchSpeechDataForStudent, fetchAllModels, createTrainingData, checkGamerTag
} from '../../../redux/actions';
import baseStyle from '../../../styles/account';

class StudentProfile extends Component {

  state = {
    firstName: this.props.student.firstName || '',
    lastName: this.props.student.lastName || '',
    email: this.props.student.email || '',
    products: this.props.student.products|| [],
    edit: false,
    editConfig: false,
    password: !!this.props.student.userId && (this.props.student.userId !== 'null') ? '**********' : '',
    confirmPassword: !!this.props.student.userId && (this.props.student.userId !== 'null') ? '**********' : '',
    login: !!this.props.student.userId && (this.props.student.userId !== 'null'),
    userName: this.props.student.gamerTag || '',
    rewardTime: this.props.studentProfile.rewardTime / 60,
    rewardEnabled: true,
    levelsToReward: this.props.studentProfile.levelsToReward ? this.props.studentProfile.levelsToReward : 0,
    curtain: this.props.studentProfile.curtainData && JSON.parse(this.props.studentProfile.curtainData).enabled,
    backgroundMusicLevel: this.props.studentProfile.backgroundMusicData && JSON.parse(this.props.studentProfile.backgroundMusicData).backgroundMusicLevel * 100,
    speechModelId: this.props.studentProfile.speechModelId,
    curtainOpacity: (this.props.studentProfile.curtainData && JSON.parse(this.props.studentProfile.curtainData).curtainOpacity * 100),
    entertainmentTime: this.props.studentProfile.entertainmentTime / 60,
    confirm: false,
    agree: false,
    ignoreWarning: false,
    checked: false,
    admin: false,
    ipadSize: this.props.studentProfile.ipadSize || ''
  };

  componentDidMount() {
    this.props.getStudent(this.props.id);
    this.props.getStudentProfile(this.props.id);
    this.props.fetchTutorialsForStudent(this.props.id);
    this.props.getStudentGames(this.props.id);
    this.props.fetchSpeechDataForStudent(this.props.id);
    this.props.fetchAllModels();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { student: { firstName, lastName, gamerTag, userId }, studentProfile: { rewardTime, speechModelId, levelsToReward, entertainmentTime, curtainData, backgroundMusicData, ipadSize }} = nextProps;
    var rewardEnabled = rewardTime == null || (rewardTime >0 && levelsToReward > 0);
    this.setState({
      firstName,
      lastName,
      userName:gamerTag,
      speechModelId,
      login: !!userId && userId !== 'null',
      levelsToReward: levelsToReward*1,
      rewardTime: rewardTime/60,
      rewardEnabled: rewardEnabled,
      entertainmentTime: entertainmentTime/60,
      curtainOpacity: nextProps.studentProfile.curtainData ? JSON.parse(curtainData).curtainOpacity*100 : 0,
      curtain: nextProps.studentProfile.curtainData ? JSON.parse(curtainData).enabled : false,
      backgroundMusicLevel: nextProps.studentProfile.backgroundMusicData ? JSON.parse(backgroundMusicData).backgroundMusicLevel * 100 : 100,
      ipadSize
    });
  }

  handleStudentEdit = async () => {
    const { firstName, lastName, email, userName, ignoreWarning, password } = this.state;
    const student = { firstName, lastName, email, id: this.props.id, gamerTag:userName.trim(), userId: this.props.student.userId };
    const gamerTagIsUnique = await checkGamerTag(this.props.id, userName.trim())
    if(gamerTagIsUnique){
      this.props.patchStudent(student)
      .then((res) => {
        if ((!res || !res.error) && !this.state.login && this.state.checked) {
          this.props.toggleStudentLogin(this.props.id, ignoreWarning, password.trim() )
            .then(() => this.cancel());
        }
        else if ((!res || !res.error) && this.state.login && !this.state.checked){
          // TODO: ADD a check for error and don't let this run if errored
          if (password.trim() !== '' && password.trim()!= '**********') {
            this.props.changeStudentPassword(this.props.id, password.trim())
              .then(() => this.cancel());
          }
        }
        else if ((!res || !res.error) && this.props.student.userId && this.props.student.userId !== 'null' && this.state.checked) {
          this.props.toggleStudentLogin(this.props.id, ignoreWarning)
            .then(() => this.cancel());
        }
        if (res && res.error) {
          console.log(res.error);
          this.setState({ error: res.error })
        }
        else {
          this.setState({ edit: false, error: '' });
        }
      });
    this.props.clearErrors();
  }
  else{
    this.setState({ error: "Username already exists!" })
  }
  };

  handleProfileEdit = () => {
    const { rewardTime, levelsToReward, curtainOpacity, backgroundMusicLevel, entertainmentTime, ipadSize, speechModelId } = this.state;
    const curtain = this.state.curtain ? this.state.curtain : false;
    const backgroundMusicData = JSON.stringify({ backgroundMusicLevel: (backgroundMusicLevel/100).toFixed(2)*1 });
    const curtainData = JSON.stringify({ curtainOpacity: (curtainOpacity/100).toFixed(2)*1, enabled: curtain });
    const { studentProfile } = this.props;
    const profile = {
      entertainmentTime: entertainmentTime*60,
      rewardTime: rewardTime*60,
      curtainData,
      backgroundMusicData,
      levelsToReward,
      studentId: this.props.id,
      ipadSize,
      speechModelId
    };
    console.log(speechModelId);
    console.log(this.props.models);
    const model = this.props.models.filter(model => model.id === speechModelId);
    if(model.length>0 && model[0].status === "Training") {
      console.log(model[0]);
      createTrainingData(this.props.id, model[0].id);
    }
    if (studentProfile.id !== 'null'){
      this.props.patchStudentProfile({...profile, id: studentProfile.id});
    }
    else {
      this.props.createStudentProfile(profile);
    }
    this.setState({ editConfig: false })
  };

  cancel = () => {
    const { student: { firstName, lastName, gamerTag }} = this.props;
    this.setState({  firstName, lastName, userName:gamerTag, password: '', confirmPassword: '', error: '', confirm: false, ignoreWarning: false, agree: false, checked: false, edit: false, });
    this.props.clearErrors();
  };

  cancelPlaytime = () => {
    this.setState({
      rewardTime: this.props.studentProfile.rewardTime / 60,
      levelsToReward: this.props.studentProfile.levelsToReward * 1,
      curtain: this.props.studentProfile.curtainData ? JSON.parse(this.props.studentProfile.curtainData).enabled : false,
      backgroundMusicLevel: this.props.studentProfile.backgroundMusicData ? JSON.parse(this.props.studentProfile.backgroundMusicData).backgroundMusicLevel * 100 : 100,
      curtainOpacity: this.props.studentProfile.curtainData ? JSON.parse(this.props.studentProfile.curtainData).curtainOpacity * 100 : 0,
      entertainmentTime: (this.props.studentProfile.entertainmentTime / 60) || 0,
      ipadSize: this.props.studentProfile.ipadSize,
      editConfig: false
    })
  };

  toggleLogin = () => {
    if ( this.state.login === false ){
      checkStudentCount()
        .then((warn) => {
          this.setState({...warn})
        });
    }
    this.setState(state => ({ login: !state.login, checked: !state.checked }))
  };

  confirm = () => {
    this.setState({ agree: true, confirm: false, ignoreWarning: true })
  };

  closeConfirm = () => {
    this.setState({  agree: false, confirm: false, login: false, ignoreWarning: false  });
  };

  handleChange = ({ target: { name, value }}) => {
    console.log(name);
    console.log(value);
    if(name === 'userName'){
      this.setState({error: !value ? "Username cannot be empty" : ""})
    }
    if(name === 'password'){
      this.setState({passwordError: !value})
    }
    this.setState({ [name]: value })
  };

  changeSlider = (val, name) => {
    this.setState({ [name]: val })
  };

  toggleEdit = () => {
    this.setState({ edit: !this.state.edit })
  };

  toggleConfigEdit = () => {
    this.setState({ editConfig: !this.state.editConfig })
  };

  addProduct = () => {
    this.props.addProduct(this.props.id, 'BRAILLE_AI_TUTOR');
    this.setState({ products: [...this.state.products, 'BRAILLE_AI_TUTOR']})
  };

  removeProduct = () => {
    this.props.removeProduct(this.props.id, 'BRAILLE_AI_TUTOR');
    this.setState({ products: this.state.products.filter(product => product !== 'BRAILLE_AI_TUTOR')})
  };

  changeReward = ({ target: { name, value }}) => {
    let rewardTime = 0;
    let levelsToReward = 0;
    let rewardEnabled = false;
    if(value) {
      rewardTime =1;
      levelsToReward=1;
      rewardEnabled = true;
    }
    this.setState({ rewardTime });
    this.setState({ levelsToReward});
    this.setState({rewardEnabled});
  };

  render() {
    const { classes, studentProfile } = this.props;
    const { firstName, lastName, userName, password, confirmPassword, products, ipadSize, speechModel } = this.state;
    // const entertainmentGames = this.props.studentGames.filter(game => game.type === 'Entertainment');
    const curtainOpacity = studentProfile.curtainData ? JSON.parse(studentProfile.curtainData).curtainOpacity : 0;
    console.log(this.props.user);
    const curtain = studentProfile.curtainData ? JSON.parse(studentProfile.curtainData).enabled : false;
    const volume = studentProfile.backgroundMusicData ? JSON.parse(studentProfile.backgroundMusicData).backgroundMusicLevel : 1;
    if(this.props.user.products && (this.props.user.products.includes("Reading")) && !this.state.edit) {
      this.setState({"edit": true})
    }
    const confirmPasswordError = !!this.state.confirmPassword && (this.state.password !== this.state.confirmPassword);
    return (
      <div>
        <div className={classes.title}>
          <Settings className={classes.icon} fontSize='inherit'/>
          <Typography className={classes.text} variant='h3'>
            <FormattedMessage id='Profile.settings'/>
          </Typography>
        </div>
        <Divider />
        <br />
        <div className={classes.profile}>
          <StudentPersonalInfo
            error={this.state.error}
            passwordError = {this.state.passwordError}
            confirmPasswordError = {confirmPasswordError}
            student={{firstName, lastName, userName, password, confirmPassword, products}}
            id={this.props.student.id}
            edit={this.state.edit}
            open={this.state.confirm}
            admin={this.state.admin}
            login={this.state.login}
            account={this.props.account}
            handleStudentEdit={this.handleStudentEdit}
            cancel={this.cancel}
            toggleLogin={this.toggleLogin}
            closeConfirm={this.closeConfirm}
            user={this.props.user}
            confirm={this.confirm}
            toggleEdit={this.toggleEdit}
            handleChange={this.handleChange}
            addProduct={this.addProduct}
            removeProduct={this.removeProduct}
          />
          {this.props.user.products && (this.props.user.products.includes("TVI") || this.props.user.products.includes("OM")) && (
          <StudentConfig
            ipadSize={ipadSize}
            studentCurtainOpacity={curtainOpacity}
            curtainBool={curtain}
            volume={volume}
            studentProfile={studentProfile}
            rewardEnabled = {this.state.rewardEnabled}
            rewardTime={this.state.rewardTime}
            curtainOpacity={this.state.curtainOpacity}
            editConfig={this.state.editConfig}
            curtain={this.state.curtain}
            levelsToReward={this.state.levelsToReward}
            entertainmentTime={this.state.entertainmentTime}
            backgroundMusicLevel={this.state.backgroundMusicLevel}
            toggleConfigEdit={this.toggleConfigEdit}
            handleChange={this.handleChange}
            changeReward={this.changeReward}
            handleProfileEdit={this.handleProfileEdit}
            cancelPlaytime={this.cancelPlaytime}
            changeSlider={this.changeSlider}
            speechModelId={this.state.speechModelId}
            models={this.props.models}
          />
          )}
          {this.props.user.products && (this.props.user.products.includes("TVI") || this.props.user.products.includes("OM")) && (

              <TutorialList title='Active Tutorials' className={classes.tutorialList} id={this.props.id} studentTutorials={this.props.studentTutorials}/>
          )}
          {/* TODO: remove for now */}
          {/*<EntertainmentGameList*/}
          {/*  className={classes.tutorialList}*/}
          {/*  title={<FormattedMessage id='Sidebar.games.entertainment'/>}*/}
          {/*  id={this.props.student.id}*/}
          {/*  games={entertainmentGames}*/}
          {/*  history={this.props.history}/>*/}
          {/*<EntertainmentGameWizardBase />*/}
          {this.props.user.products && (this.props.user.products.includes("TVI") || this.props.user.products.includes("OM")) && (
          <div className={classes.tutorialList}>
          <SpeechData id={this.props.id} className={classes.tutorialList} categories={this.props.models} type="speech-data" studentSpeechData={this.props.speechData}/>
          </div>
          )}
        </div>
      </div>
    )
  }
}

const mapState = ({ platform: { student, studentProfile, studentTutorials, studentGames, speechData, models }, shared: { account, user }}) => {
  return {
    user,
    student,
    studentProfile,
    studentTutorials,
    studentGames,
    speechData,
    models,
    account
  }
};

const mapDispatch = (dispatch) => {
  return {
    getStudent: (id) => dispatch(getStudent(id)),
    getStudentProfile: (id) => dispatch(getStudentProfile(id)),
    fetchTutorialsForStudent: (id) => dispatch(fetchTutorialsForStudent(id)),
    fetchAllModels: () => dispatch(fetchAllModels()),
    getStudentGames: (studentId) => dispatch(getStudentGames(studentId)),
    fetchSpeechDataForStudent: (studentId) => dispatch(fetchSpeechDataForStudent(studentId)),
    createStudentProfile: (studentProfile) => dispatch(createStudentProfile(studentProfile)),
    patchStudentProfile: (studentProfile) => dispatch(patchStudentProfile(studentProfile)),
    patchStudent: (student, password) => dispatch(patchStudent(student, password)),
    toggleStudentLogin: (id, ignoreWarning, password) => dispatch(toggleStudentLogin(id, ignoreWarning, password)),
    changeStudentPassword: (id, password) => dispatch(changeStudentPassword(id, password)),
    addProduct: (studentId, product) => dispatch(addProduct(studentId, product)),
    removeProduct: (studentId, product) => dispatch(removeProduct(studentId, product)),
    clearErrors: () => dispatch(clearErrors())
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(StudentProfile));