import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, FormGroup, Typography, TextField, IconButton } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { patchStaffSkillDatabank } from '../../../../redux/actions';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../../styles/wizardBase';
import { FormattedMessage } from 'react-intl';

class SkillDatabankEdit extends Component {
  state = {
    ...this.props.data
  };

  handleChange = (ev) => {
    this.setState({[ev.target.name]: ev.target.value })
  };

  submit = (ev) => {
    console.log(this.props)
    ev.preventDefault();
    console.log('submitted', this.state);
    this.props.patchStaffSkillDatabank(this.state, this.props.id);
    this.props.close();
  };

  render()  {
    const { classes, data } = this.props;
    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        open={this.props.open}
        onClose={this.props.close}
        aria-labelledby='form-dialog-title'
        PaperProps={{ classes:{ root: classes.container }}}
      >
        <DialogTitle disableTypography={true} className={ classes.title } id='form-dialog-title'>
          <Typography className={ classes.titleText } variant='h4'>{data.name}</Typography>
          <IconButton
            onClick={this.props.close}
            className={ classes.exitIcon }
            aria-label="close dialog"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form className={ classes.root } onSubmit={this.submit}>
            <FormGroup classes={{ root: classes.root }}>
              <TextField
                variant='outlined'
                classes={{ root: classes.lastName }}
                id='skill-databank-edit-name'
                label={<FormattedMessage id='General.name' />}
                name='name'
                type='text'
                value={this.state.name}
                onChange={this.handleChange}
              />
              <TextField
                multiline
                variant='outlined'
                id='skill-databank-edit-description'
                classes={{ root: classes.lastName }}
                label={<FormattedMessage id='General.description' />}
                name='description'
                type='text'
                value={this.state.description}
                onChange={this.handleChange}
              />
              <TextField
                variant='outlined'
                classes={{ root: classes.lastName }}
                id='skill-databank-edit-area'
                label={<FormattedMessage id='General.area' />}
                name='area'
                value={this.state.area}
                onChange={this.handleChange}
              />
              <TextField
                variant='outlined'
                classes={{ root: classes.lastName }}
                id='skill-databank-edit-difficulty'
                label={<FormattedMessage id='General.difficulty' />}
                name='difficulty'
                value={this.state.difficulty}
                onChange={this.handleChange}
              />
              <TextField
                variant='outlined'
                classes={{ root: classes.lastName }}
                multiline
                id='skill-databank-edit-level'
                label='Level JSON'
                name='levelJson'
                value={this.state.levelJson}
                onChange={this.handleChange}
              />
            </FormGroup>
            <DialogActions className={classes.actions}>
              <Button onClick={this.props.close} color='secondary'>
                <FormattedMessage id='General.cancel' />
              </Button>
              <Button type='submit' color='primary'>
                <FormattedMessage id='General.save' />
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    )
  }
}

const mapState = ({ platform: { games }}) => {
  return {
    games
  }
};

const mapDispatch = (dispatch) => {
  return {
    patchStaffSkillDatabank: (skillDatabank, id) => dispatch(patchStaffSkillDatabank(skillDatabank, id))
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(SkillDatabankEdit));