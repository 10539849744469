import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  InputAdornment,
} from '@material-ui/core';
import { Paper, Typography, TextField, Toolbar, Tooltip, IconButton, Switch, withStyles } from '@material-ui/core';
import { Delete, Search } from '@material-ui/icons';
import { patchEntertainmentGame, deleteEntertainmentGame } from '../../../redux/actions';
import { stableSort, getSorting } from '../../../helpers';
import styles from '../../../styles/dataTable.js';

const rows = [
  { id: 'name', label: <FormattedMessage id='General.name'/>, searchable: true },
  { id: 'description', label: <FormattedMessage id='General.description'/> },
  { id: 'timed', label: <FormattedMessage id='General.timed'/> },
];

class EntertainmentGameList extends Component {

  state = {
    order: 'asc',
    orderBy: 'name',
    rowsPerPage: 5,
    page: 0,
    filteredData: [],
    searchValue: '',
    checked: {}
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.data && nextProps.data !== this.props.data) {
      this.handleSearch(this.state.searchValue, nextProps.data)
    }
  }

  handleSearch = (searchValue) => {
    const searchableData = this.props.games;
    const filtered = searchableData.filter(item => {
      const searchableKeys = ['name', 'description'];
      return searchableKeys.some(key => {
        if (item[key]){
          return item[key].toLowerCase().includes(searchValue.toLowerCase())
        }
        else {
          return false
        }
      })
    });
    this.setState({ filteredData: filtered, page: 0 })
  };

  handleSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleDelete = (data) => {
    this.props.deleteEntertainmentGame(this.props.id, data)
  };

  handleToggle = (data) => {
    this.props.patchEntertainmentGame(this.props.id, {...data, timed: !data.timed});
  };

  render(){
    const { classes, title } = this.props;
    const {
      rowsPerPage,
      page,
      orderBy,
      order,
      searchValue,
      filteredData,
    } = this.state;
    const data = filteredData.length || searchValue ? filteredData : this.props.games;
    return (
      <Paper className={ classes.gameList }>
        <Toolbar classes={{ root: classes.toolbar }}>
          <Typography noWrap className={ classes.header }>
            <strong>{ title }</strong>
          </Typography>
          <div className={classes.actions}>
            <TextField
              className={ classes.search }
              variant='outlined'
              id='table-search'
              aria-label='search input'
              type='search'
              InputProps={{
                inputProps: {
                  'aria-label':'search input'
                },
                endAdornment: (
                  <InputAdornment position='end'>
                    <Search />
                  </InputAdornment>
                ),
                className: classes.searchBar,
              }}
              onChange={(ev) => {
                this.handleSearch(ev.target.value);
                this.setState({ searchValue: ev.target.value });
              }}
              value={this.state.searchValue}
            />
          </div>
        </Toolbar>
        <div className={classes.tableWrapper}>
          <Table>
            <TableHead>
              <TableRow>
                {
                  rows.length &&
                  rows.map((row, index) => {
                    return (
                      <TableCell
                        aria-label={row.id}
                        key={row.id+index}
                        sortDirection={ orderBy === row.id ? order : false }
                      >
                        <Tooltip
                          title='Sort'
                          placement='bottom-start'
                          enterDelay={300}
                        >
                          <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={(ev) => this.handleSort(ev, row.id)}
                          >
                            {row.label}
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                    )
                  })
                }
                <TableCell align='right' />
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(data, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((_data, index) => {
                  return (
                    <TableRow className={classes.hoverRow} tabIndex={0} hover key={_data.id+index}>
                      {rows.length &&
                      rows.map((row, index) => {
                        return row.id === 'timed'
                        ? <TableCell aria-label={row.id} className={classes.cell} key={index+row.id}>
                            <Switch
                              checked={_data.timed}
                              onChange={() => this.handleToggle(_data)}
                              inputProps={{ 'aria-label': 'toggle timed' }}
                            />
                          </TableCell>
                        :  <TableCell aria-label={row.id} className={classes.cell} key={index+row.id}>
                            {
                              _data[row.id] && _data[row.id] !== ' '
                                ? _data[row.id]
                                : 'N/A'
                            }
                          </TableCell>
                      })
                      }
                      <TableCell align='right'>
                        <div className={classes.buttons}>
                          <Tooltip title={<FormattedMessage id="General.delete"/>}>
                            <IconButton aria-label='delete' onClick={() => this.handleDelete(_data)}>
                              <Delete color='secondary'/>
                            </IconButton>
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5,10,15]}
          component='div'
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage={<FormattedMessage id='Datatable.rowsPerPage'/>}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    )
  }
}

const mapDispatch = (dispatch) => {
  return {
    patchEntertainmentGame: (id, game) => dispatch(patchEntertainmentGame(id, game)),
    deleteEntertainmentGame: (id, game) => dispatch(deleteEntertainmentGame(id, game))
  }
};

export default connect(null, mapDispatch)(withStyles(styles)(EntertainmentGameList));