import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Button, FormGroup, Typography, TextField, IconButton, Grid } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { getStudentsForStaff, patchStaffMember, fetchStudents } from '../../../../redux/actions';
import { withStyles } from '@material-ui/core/styles';
import { ROOT_URL } from '../../../../redux/constants';
import baseStyle from '../../../../styles/wizardBase';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import WizardToggleButton from '../WizardToggleButton';
import { removeStaffProduct, addStaffProduct } from '../../../../redux/actions';


const productOptions = [
  { value: 'Reading', label: 'Reading' },
  { value: 'OM', label: 'O&M' },
  { value: 'TVI', label: 'TVI' },
];
class StaffEdit extends Component {
  state = {
    ...this.props.data,
    students:[],
    addProduct: null,
    deleteProduct: null
  };

  componentDidMount() {
    const students = this.props.staffStudents ? this.props.staffStudents.map(student => ({label: student.name, value: student.id })) : [];
    this.setState({ students })
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.staffStudents !== nextProps.staffStudents){
      this.setState({ students: nextProps.staffStudents.map(student => ({ label: student.name, value: student.id }))})
      this.setState({"products": this.generateProduct(this.props.data.products)})
    }
  }

  handleChange = (ev) => {
    this.setState({[ev.target.name]: ev.target.value })
  };

  toggleLogin = () => {
    this.state.login ? this.props.deregisterUser(this.props.data) : this.props.registerUser(this.props.data);
    this.setState(state => ({ login: !state.login }));
  };

  submit = (ev) => {
    ev.preventDefault();
    const students = this.props.students.filter(student => this.state.students.some(_student => _student.value === student.id));
    this.handleProductsChange();
    this.props.patchStaffMember(this.state, students);
    this.props.close();
  };


  handleProductsChange = () => {
    var oldProducts = this.props.data.products;
    var newProducts = this.state.products.map(p => (p.value));
    if(oldProducts && newProducts) {
      var toAdd = newProducts.filter(e => oldProducts.indexOf(e) < 0);
      var toDelete = oldProducts.filter(e => newProducts.indexOf(e) < 0);
      console.log("toAdd: ", toAdd);
      console.log("toDelete: ", toDelete);
      for (let add of toAdd) {
        this.props.addStaffProduct(this.props.data.id, add);
      }
      for (let del of toDelete) {
        this.props.removeStaffProduct(this.props.data.id, del);
      }
    }
  }

  promoteStaff = () => {
     const url = `${ROOT_URL}/staff/${this.state.id}/promote`;
      axios
          .post(url)
          .then((response) => {
            this.setState({type: "Admin"});
          })
          .catch(err => console.log(err));
  };

  handleAddProducts = () => {
    this.props.addStaffProduct(this.props.data.id, this.state.addProduct);
  }

  handleDeleteProducts = () => {
    this.props.removeStaffProduct(this.props.data.id, this.state.deleteProduct);

  }


  generateProduct(products){
    let obj=[];
    if(products)
    {
      for(let p of products) {
        var prod = productOptions.filter(o => (o.value == p));
        var label = prod.length == 1 ? prod[0].label : p;
        obj.push({"value": p, "label": label});
      }
    }
    return obj;
  }
 render() {
    const { classes, data, students } = this.props;
   let products=this.props.user.products && this.props.user.products.split(',');
   let obj=this.generateProduct(products);
    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        open={this.props.open}
        onClose={this.props.close}
        aria-labelledby='form-dialog-title'
        PaperProps={{ classes:{ root: classes.container }}}
      >
        <DialogTitle disableTypography={true} className={ classes.title } id='form-dialog-title'>
          <Typography className={ classes.titleText } variant='h4'>
            <FormattedMessage id="General.edit"/> {data.firstName} {data.lastName}
          </Typography>
          <IconButton
            onClick={this.props.close}
            className={ classes.exitIcon }
            aria-label="close dialog"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent classes={{ root: classes.scroll }}>
          <FormGroup>
            <TextField
              variant='outlined'
              classes={{ root: classes.firstName }}
              id='student-edit-first-name'
              label={<FormattedMessage id="General.firstName"/>}
              name='firstName'
              type='text'
              value={this.state.firstName}
              onChange={this.handleChange}
            />
            <TextField
              variant='outlined'
              id='student-edit-last-name'
              classes={{ root: classes.lastName }}
              label={<FormattedMessage id="General.lastName"/>}
              name='lastName'
              type='text'
              value={this.state.lastName}
              onChange={this.handleChange}
            />
            <TextField
                disabled={true}
                variant='outlined'
                id='student-edit-last-name'
                classes={{ root: classes.lastName }}
                label={<FormattedMessage id="General.role"/>}
                name='Role'
                type='text'
                value={this.state.type}
            />
            <Typography gutterBottom variant='subtitle2'>
              <FormattedMessage id="General.select"/> {data.name}
              <FormattedMessage id="Sidebar.student's"/>:
            </Typography>
            <Select
              isMulti
              id='student-select'
              maxMenuHeight={180}
              styles={{
                control: (styles) => ({...styles, minHeight: 56}),
                multiValueLabel: (styles) => ({
                  ...styles,
                  display: 'grid',
                  fontFamily: 'Roboto',
                  alignContent: 'center',
                  fontSize: '14px',
                  minHeight: 40,
                  fontWeight: 500
                }),
              }}
              menuPlacement='top'
              onChange={(ev) => this.setState({ students: ev })}
              value={this.state.students}
              options={students.map(student => ({label: `${student.firstName} ${student.lastName}`, value: student.id }))}
            />
          </FormGroup>
          {products&& products.length>1?
              <FormGroup >
                <Typography gutterBottom variant='h6'> <FormattedMessage id='Wizard.Staff.selectProduct'/>:</Typography>
                <Select
                    styles={{
                      control: (styles) => ({...styles, minHeight: 56}),
                      multiValueLabel: (styles) => ({
                        ...styles,
                        display: 'grid',
                        fontFamily: 'Roboto',
                        alignContent: 'center',
                        fontSize: '14px',
                        minHeight: 40,
                        fontWeight: 500
                      }),
                    }}
                    isMulti
                    value = {this.state.products}
                    menuPlacement='top'
                    maxMenuHeight={180}
                    placeholder='Product'
                    options={obj && obj.map(p => ({ value: p.value, label: p.label }))}
                    onChange={(ev) => this.handleChange({ target: { name: 'products', value: ev }})}
                /></FormGroup>:null}
          {this.state.type === "Teacher" &&
          <Typography>
            <Typography gutterBottom variant='h6' style={{paddingTop: "10px", paddingBottom:"20px"}}>
              <FormattedMessage id="EditStaff.promoteDescription"/>
              <WizardToggleButton
                  openWizard={this.promoteStaff}
                  text={<FormattedMessage id="EditStaff.promote"/>}/>
            </Typography>
          </Typography>
          }
          {this.state.type === "Specialist" &&
          <Typography>
            <FormattedMessage id="EditStaff.cannotPromote"/>
          </Typography>
          }
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button onClick={this.props.close} color='secondary'>
            <FormattedMessage id="General.cancel"/>
          </Button>
          <Button onClick={this.submit} disabled={ this.state.products.length>0 ? false: true} color='primary'>
            <FormattedMessage id="General.save"/>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapState = ({ platform: { students, staffStudents }, shared: { error, account, user }}) => {
  return {
    students,
    staffStudents,
    user
  }
};

const mapDispatch = (dispatch) => {
  return {
    getStudentsForStaff: (staffId) => dispatch(getStudentsForStaff(staffId)),
    patchStaffMember: (staffMember, students) => dispatch(patchStaffMember(staffMember, students)),
    fetchStudents: () => dispatch(fetchStudents()),
    addStaffProduct: (id, product) => dispatch(addStaffProduct(id, product)),
    removeStaffProduct: (id, product) => dispatch(removeStaffProduct(id, product)),
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(StaffEdit));