import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from "../../../styles/dashboard";
import { Component } from "react";
import { fetchSliderSeries } from '../../../redux/actions';
import DataTable from './../../components/Shared/DataTable';
import SliderSeriesManagementWizardBase from '../../components/Wizards/SliderSeriesManagement/SliderSeriesManagementWizardBase';
import { IconButton, Snackbar } from "@material-ui/core";
import SeriesStoryList from "./SeriesStoryList";
import { Close } from "@material-ui/icons";

class SliderSeriesManagement extends Component {

  state={
    storyListData:[],
    storyListModal:false,
    seriesId:"",
    seriesName:"",
    type: this.props.type ==='storyList' ? 'storyListSeries' : 'sliderSeries',
    deletable : this.props.type ==='storyList' ? false : true,
    noActions : this.props.type ==='storyList' ? true : false,
  }

  componentDidMount() {
    this.props.fetchSliderSeries();
  }
  
 componentDidUpdate(prevProps,prevState){
  if(prevProps.sliderSeries !== this.props.sliderSeries){
    this.props.sliderSeries.map(val=>{
      if(val.id=== this.state.seriesId){
        this.setState({
          storyListData:val.items
        })
      }
    })
  }
}
  openWizard = () => {
    this.setState({ open: true })
  };
  clickFunc = (data) => {
    if(this.props.type==="storyList"){
      this.props.handleClick(data)
    }
    else{
      this.setState({
        storyListData:data.items,
        storyListModal:true,
        seriesId:data.id,
        seriesName:data.name})
    }
  }

  closeModal=()=>{
    this.setState({storyListModal:false})
  }

  render() {
    
    const rows = [
      { id: 'name', label: <FormattedMessage id='General.name' />, searchable: true },
      { id: 'description', label: <FormattedMessage id='General.description' />, searchable: true }
    ];

    const title = (
      <>          
        <FormattedMessage id='DataTable.SliderSeriesManagement' />  
      </>
    );

    return (
      <>
        <DataTable
          type={this.state.type}
          editable
          deletable={this.state.deletable}
          noActions={this.state.noActions}
          clickable
          clickFunc={this.clickFunc}
          rows={rows}
          rowsPerPage={[5, 10, 15]}
          orderBy={''}
          data={this.props.sliderSeries}
          title={title}
        />
        {this.props.type === 'storyList' ? "" : <SliderSeriesManagementWizardBase history={this.props.history} />}
        <SeriesStoryList
        open={this.state.storyListModal}
        closeModal={this.closeModal}
        storyListData={this.state.storyListData}
        seriesId={this.state.seriesId}
        seriesName={this.state.seriesName} />

      </>
    )
  }
}

const mapState = ({ platform: { sliderSeries } }) => {
  return {
    sliderSeries
  }
};

const mapDispatch = (dispatch, { history }) => {
  return {
    fetchSliderSeries: () => dispatch(fetchSliderSeries(history))
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(SliderSeriesManagement));