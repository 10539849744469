import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Switch,
  TextField,
  Typography, withStyles
} from '@material-ui/core';
import { AccountCircle, Assignment, Edit, Fingerprint, VideogameAsset } from '@material-ui/icons';
import baseStyle from '../../../styles/account';
import { connect } from 'react-redux';
import { removeProduct, addProduct } from '../../../redux/actions';

class StudentPersonalInfo extends Component {

  addBraille = () => {
    this.props.addProduct()
  };

  removeProduct = () => {
    this.props.removeProduct()
  };

  render() {
    const { edit, error, login, classes, passwordError,confirmPasswordError, user } = this.props;
    const { firstName, lastName, userName, confirmPassword, password, products } = this.props.student;
    return (
      <Card classes={{ root: classes.card }}>
        <CardHeader title={<FormattedMessage id='Profile.personal'/>} action={
          !edit &&
          <IconButton
            aria-label='edit'
            name='edit'
            onClick={this.props.toggleEdit}
          >
            <Edit />
          </IconButton>
        }/>
        <Divider/>
        <CardContent>
          {
            edit
              ? <>
                <div className={classes.infoLine}>
                  <AccountCircle className={classes.infoIcon}/>
                  <>
                    <TextField
                      variant='outlined'
                      className={classes.inputLine}
                      type='text'
                      name='firstName'
                      label={<FormattedMessage id='General.firstName'/>}
                      value={firstName}
                      onChange={this.props.handleChange}
                    />
                    <TextField
                      variant='outlined'
                      className={classes.infoText}
                      type='text'
                      name='lastName'
                      label={<FormattedMessage id='General.lastName'/>}
                      value={lastName}
                      onChange={this.props.handleChange}
                    />
                  </>
                </div>
                <div className={classes.infoLine}>
                  <VideogameAsset className={classes.infoIcon}/>
                  <TextField
                    variant='outlined'
                    type='text'
                    label={this.props.user.products && this.props.user.products.includes("Reading") ? "User Name" :  "Gamer Tag"}
                    name='userName'
                    error={!!error}
                    helperText={error ? error : ''}
                    value={userName}
                    onChange={this.props.handleChange}
                  />
                </div>
                {
                  this.props.account.brailleAIBillingCount > 0 && products.length > 0 &&
                  <div className={classes.infoLine}>
                    <Assignment className={classes.infoIcon}/>
                    <div className={classes.brailleText}>
                      <Typography variant='h6'> Subscribed to:</Typography>
                      <Typography variant='h6'> {products.map(product => products.length > 1 ? `${product}, ` : product)} </Typography>
                    </div>
                    {
                     user && user.products.includes('TVI') && products.includes('BRAILLE_AI_TUTOR') ?
                      <Button
                        color='secondary'
                        variant='contained'
                        fullWidth
                        className={classes.brailleButton}
                        onClick={this.removeProduct}
                      >
                        Remove Braille Tutor from student
                      </Button>
                      : <Button
                        variant='contained'
                        fullWidth
                        className={classes.brailleButton}
                        onClick={this.addBraille}
                      >
                        Add Braille Tutor to student
                      </Button>
                    }
                  </div>
                }
                <div className={classes.login}>
                  <Typography variant='h6' className='text'> <FormattedMessage id='Profile.studentLogin'/> </Typography>
                  <Switch disabled={!userName} className='toggle' checked={login} onChange={this.props.toggleLogin}/>
                </div>
                {
                  login &&
                  <div className={classes.infoLine}>
                    <Fingerprint className={classes.infoIcon}/>
                    <>
                      <TextField
                        variant='outlined'
                        className={classes.inputLine}
                        type='password'
                        placeholder={'***********'}
                        label={<FormattedMessage id='Login.password'/>}
                        name='password'
                        error={passwordError}
                        helperText={passwordError ? "Password cannot be empty" : ''}
                        value={password}
                        onChange={this.props.handleChange}
                      />
                      <TextField
                        variant='outlined'
                        className={classes.inputLine}
                        type='password'
                        placeholder={'***********'}
                        label={<FormattedMessage id='Login.confirmPassword'/>}
                        name='confirmPassword'
                        error={confirmPasswordError}
                        helperText={confirmPasswordError ? <FormattedMessage id='Login.mustMatch'/> : ''}
                        value={confirmPassword}
                        onChange={this.props.handleChange}
                      />
                    </>
                  </div>
                }
                <div className={classes.buttons}>
                  <Button
                    variant='outlined'
                    color='secondary'
                    onClick={this.props.cancel}
                  >
                    <FormattedMessage id='General.cancel'/>
                  </Button>
                  <Button
                    className={classes.confirm}
                    variant='contained'
                    color='primary'
                    disabled = {!userName || (login && (!password || !confirmPassword || confirmPasswordError))}
                    onClick={this.props.handleStudentEdit}
                  >
                    <FormattedMessage id='General.save'/>
                  </Button>
                </div>
              </>
              : <>
                <div className={classes.infoLine}>
                  <AccountCircle className={classes.infoIcon}/>
                  <Typography className={classes.infoText} variant='h6'>{firstName} {lastName}</Typography>
                </div>
                <div className={classes.infoLine}>
                  <VideogameAsset className={classes.infoIcon}/>
                  <Typography className={classes.infoText} variant='h6'>{userName || 'No User Name'}</Typography>
                </div>
              </>
          }
          <Dialog
            open={this.props.open}
            onClose={this.props.closeConfirm}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Confirm Addition to Account</DialogTitle>
            {
              this.props.admin
                ? <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Adding another student to this account will exceed your original billed amount of {this.props.account.studentCount} and you may be billed accordingly.
                    Do you agree to these terms?
                  </DialogContentText>
                  <div className={classes.confirmButtons}>
                    <Button onClick={this.props.confirm} color='primary' variant='contained' autoFocus>
                      Yes, increase my billable student count!
                    </Button>
                    <Button onClick={this.props.closeConfirm} color='secondary'>
                      No, I will continue without login.
                    </Button>
                  </div>
                </DialogContent>
                : <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Your account is over the allotted {this.props.account.studentCount} students. Please contact your administrator to add more students.
                  </DialogContentText>
                  <div className={classes.confirmButtons}>
                    <Button onClick={this.props.closeConfirm} variant='contained' color='secondary'>
                      Continue without login.
                    </Button>
                  </div>
                </DialogContent>
            }
          </Dialog>
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(baseStyle)(StudentPersonalInfo);