import React from 'react';
import { Ballot } from '@material-ui/icons';
import { Typography, Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../styles/progress.js';

const Progress = (props) => {
  const { classes } = props;
  return (
    <section>
      <div className={classes.title}>
        <Ballot className={classes.icon} fontSize='inherit'/>
        <Typography className={classes.text} variant='h3'>
          Progress
        </Typography>
      </div>
      <Divider />
    </section>
  )
};

export default withStyles(baseStyle)(Progress);