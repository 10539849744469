import {
    FETCH_ALL_MODELS,
    FETCH_MODEL_BY_ID,
    FETCH_SPEECH_DATA,
    FETCH_TRAINING_TYPES
} from '../../constants';


export const models = (state = [], action) => {
    switch (action.type) {
        case FETCH_ALL_MODELS:
            return action.models;
        default:
            return state;
    }
};

export const model = (state = [], action) => {
    switch (action.type) {
        case FETCH_MODEL_BY_ID:
            return action.model;
        default:
            return state;
    }
};

export const speechData = (state = [], action) => {
    switch (action.type) {
        case FETCH_SPEECH_DATA:
            return action.speechData;
        default:
            return state;
    }
};

export const trainingTypes = (state = [], action) => {
    switch (action.type) {
        case FETCH_TRAINING_TYPES:
            return action.trainingTypes;
        default:
            return state;
    }
};