import React, { Component } from 'react';
import SupportUserList from '../../components/Support/SupportUserList';
import SupportWizardBase from '../../components/Wizards/Support/SupportWizardBase';

class Support extends Component {
  render() {
    return (
      <div>
        <SupportUserList history={this.props.history}/>
        <SupportWizardBase history={this.props.history}/>
      </div>
    );
  }
}

export default Support;