import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { TextField, SnackbarContent, IconButton, InputAdornment, Button, Card, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Visibility, VisibilityOff, Close } from '@material-ui/icons';
import { login, clearErrors } from '../../../redux/actions';
import logo from '../../../assets/BrandAssets/Logo/OBJECTIVEED-Logo-Vertical-1.png';
import styles from '../../../styles/login';
import { FormattedMessage } from 'react-intl';
import queryString from 'query-string';


class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            code:'',
            showPassword: false,
            errors:{ password: '' },
            posted: false
        }
    }

    render(){
        const params = new URLSearchParams(this.props.location.hash.replace("#","?"));
        const access_token = params.get("access_token")
        const expiration = params.get("expires_in")
        const data = {"access_token": access_token, "expiration": expiration};
        console.log(data);
        if(window.opener  && !this.state.posted){
            this.setState({posted: !this.state.posted})
            window.opener.postMessage(JSON.stringify(data), "*");
        }
        const { classes, error } = this.props;
        return (
            <Card className={ classes.loginContainer }>
                <div className={ classes.loginForm }>
                    <img
                        className={ classes.loginLogo }
                        alt='login-logo'
                        src={logo}
                    />
                </div>
                <Typography align='center' variant='caption'>
                    Logged into bookshare successfully!  You may now close this window.
                </Typography>
            </Card>
        )
    }
}

const mapState = ({ shared: { error }}) => {
    return {
        error
    }
};

const mapDispatch = (dispatch, { history }) => {
    return {
        login: (credentials, register, passthrough, courseKey) => dispatch(login(credentials, history, register, passthrough, courseKey)),
        clearErrors: () => dispatch(clearErrors())
    }
};

export default connect(mapState, mapDispatch)(withStyles(styles)(Login));