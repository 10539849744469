import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Quill from 'quill';
import Avatar from 'react-avatar';
import { IconButton, Paper, withStyles, Icon } from '@material-ui/core';
import { addReply, searchProfiles, searchTags } from '../../redux/actions';
import baseStyle from '../../styles/reply';

class AddReply extends Component {

  state = {
    reply: '',
    tags: [],
    unchanged: false,
    replyAuthor: {}
  };

  componentWillReceiveProps(nextProps, nextContext) {
    console.log(nextProps.author, this.state.replyQuill.getText().trim().length);
    if(nextProps.author && this.state.replyQuill.getText().trim().length<=0) {
      this.state.replyQuill.setText("");
      this.insertMention(nextProps.author, this.state.replyQuill);
    }
  }

  componentDidMount() {
    const source = (searchTerm, renderList, mentionChar) => {
      if (mentionChar === '@') {
        this.props.searchProfiles(searchTerm)
          .then((profiles) => {
            console.log(profiles, searchTerm);
            renderList(profiles, searchTerm);
          })
      }
      if (mentionChar === '#'){
        this.props.searchTags(searchTerm)
          .then((tags) => {
            renderList(tags.map(tag => ({ name: tag })), searchTerm);
          })
      }
    };

    // initialize our Quill instance
    const replyQuill = new Quill(`#reply-container-${this.props.post.key}`, {
      selection: {start:0, end:0},
      placeholder: this.props.placeholder,
      modules: {
        mention:{
          mentionDenotationChars: ["@", "#"],
          dataAttributes: ['name'],
          renderItem(item) {
            return `<div class="cql-list-item-inner">${item.name}</div>`
          },
          onSelect(item, insertItem){
            if (item.denotationChar === '#') {
              if (item.name[item.name.length-1] === '\n') {
                item.value = item.name.slice(1,-1);
              } else {
                item.value = item.name.slice(1);
              }
              insertItem(item);
            }
            else {
              item.value = item.name;
              insertItem(item);
            }
          },
          defaultMenuOrientation: 'bottom',
          allowedChars: /[a-zA-Z0-9_\s]/,
          source: source
        },
        keyboard: {
          bindings: {
            submit: {
              key: 13,
              empty: false,
              shortKey: true,
              handler: () => {
                this.submitReply()
              }
            }
          },
        },
      },
    });
    // const replyQuill = new Quill(`#reply-container-${this.props.post.key}`);
    replyQuill.on('text-change', (delta, old) => {
      const text = replyQuill.getText(0);
      const val = replyQuill.root.innerHTML;
      if (delta.ops[1] && delta.ops[1].insert === '#'){
        replyQuill.formatText(delta.ops[0].retain, 1, { 'bold': true, 'color': '#1DA1F2' });
        this.setState({ tag: true })
      }
      if (delta.ops[0].insert === '#'){
        replyQuill.formatText(0, 1, { 'bold': true, 'color': '#1DA1F2' });
        this.setState({ tag: true })
      }
      if (delta.ops[1] && delta.ops[1].insert === ' ' && this.state.tag === true){
        replyQuill.removeFormat(delta.ops[0].retain, 1);
        this.setState({ tag: false })
      }
      this.handlePostChange(val, old, text)
    });
    if(this.props.author) {
      this.insertMention(this.props.author, replyQuill);
    } else {
      replyQuill.focus();
    }
    this.setState({ replyQuill })
  }

  insertMention(author, replyQuill) {
    replyQuill.focus();
    replyQuill.setText("@");
    replyQuill.setSelection(1);
    replyQuill.getModule('mention').onSomethingChange();
    const mention = {
      denotationChar: "@",
      index: "0",
      name: author.name,
      value: author.name,
    }
    replyQuill.getModule('mention').insertItem(mention, true);
    this.setState({replyAuthor: author});
  }

  handlePostChange = (reply, delta, text) => {
    const words = text.split(' ');
    const tags = words.filter(word => word[0] === '#');
    this.setState({ reply, tags })
  };

  submitReply = () => {
    const reply = {
        text: this.state.reply,
        tags: this.state.tags,
    };
    this.props.addReply(this.props.post, reply)
      .then(_reply => this.props.add(_reply.key, _reply));
    this.setState({ reply: '' });
    this.state.replyQuill.setText('');
  };

  render() {
    const { classes, profile, child, author } = this.props;

    return (
      <div className={child ? classes.child : classes.replyWrapper}>
        <div className={classes.avatar}>
          <Avatar round size={'50px'} name={profile.name} src={profile.imgSrc} alt={profile.name}/>
        </div>
        <Paper className={classes.reply}>
          <div id={`reply-container-${this.props.post.key}`} className={classNames(classes.replyContent)}/>
          <div className={classes.replyLike}>
            <IconButton onClick={this.submitReply}>
               <Icon className='far fa-comment-alt' />
            </IconButton>
          </div>
        </Paper>
      </div>
    );
  }
}

const mapState = ({ social: { profile, profiles, tags }}) => {
  return {
    profile,
    profiles,
    tags
  }
};

const mapDispatch = (dispatch) => {
  return {
    addReply: (post, reply) => dispatch(addReply(post, reply)),
    searchProfiles: (searchVal) => dispatch(searchProfiles(searchVal)),
    searchTags: (searchVal) => dispatch(searchTags(searchVal))
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(AddReply));