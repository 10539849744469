import React, { Component } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  TextField,
  withStyles, MenuItem, FormGroup
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import baseStyle from '../../../../styles/wizardBase';
import { FormattedMessage } from 'react-intl';

class BarnyardLevelTemplate extends Component {

  state = {
    acceptedPen: '',
    hitBoxType: '',
    assignAnimalPenTarget: '',
    autoClearFenceWhenMaxIsHit: '',
    clearFenceOnIncorrectAddition: '',
    lengthOfRound: '',
    numberOfAnimalsToSpawn: '',
    penMax: '',
    penSet: '',
    spawnerType: 'turn',
    weightedAnimalSpawn: '',
    indexAnimalPlacedInPen: '',
    indexWrongHitch: '',
    indexFenceIsFullPenalty: '',
    indexFenceIsFull: '',
    indexFenceClearAnnouncement: ''
  };

  close = () => {
    this.props.close();
  };

  onSubmit = () => {
    const weightedAnimalSpawn = this.state.weightedAnimalSpawn.indexOf('[') === 0
      ? JSON.parse(this.state.weightedAnimalSpawn)
      : JSON.parse(`[${this.state.weightedAnimalSpawn}]`);
    const level = { levelVariables: JSON.stringify({ ...this.state, weightedAnimalSpawn }) };
    this.props.onSubmit(level)
  };

  handleChange = (ev) => {
    const name = ev.target.name;
    const value = ev.target.type === 'number' ? ev.target.value * 1 : ev.target.value;
    this.setState({ [name]: value })
  };

  render() {
    const { classes, open, close, back } = this.props;
    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        open={open}
        onClose={close}
        aria-labelledby='level-dialog-title'
        PaperProps={{ classes: { root: classes.container } }}
      >
        <DialogTitle disableTypography={true} className={classes.title} id='level-dialog-title'>
          <Typography className={classes.titleText} variant='h4'><FormattedMessage id='Level.title'/></Typography>
          <IconButton
            onClick={this.close}
            className={classes.exitIcon}
            aria-label="close dialog"
          >
            <Close/>
          </IconButton>
        </DialogTitle>
        <Typography className={classes.subtitle} variant='h6'><FormattedMessage id='Level.add'/> {this.props.game.name} <FormattedMessage id='Level.add2'/>:</Typography>
        <DialogContent>
          <FormGroup className={classes.root}>
            <div className={classes.configureLine}>
              <strong className={classes.configTitle}> Accepted Pen: </strong>
              <TextField
                select
                variant='outlined'
                className={classes.configComponent}
                type='text'
                name='acceptedPen'
                onChange={this.handleChange}
                value={this.state.acceptedPen}
              >
                {
                  ['Target', 'Any'].map((option, index) => (<MenuItem key={index} value={option}>{option}</MenuItem>))
                }
              </TextField>
            </div>
            <div className={classes.configureLine}>
              <strong className={classes.configTitle}> Animal Hitbox Size: </strong>
              <TextField
                select
                variant='outlined'
                className={classes.configComponent}
                name='hitBoxType'
                onChange={this.handleChange}
                value={this.state.hitBoxType}
              >
                {
                  ['Drag', 'Swipe', 'Location'].map((option, index) => (
                    <MenuItem key={index} value={option}>{option}</MenuItem>))
                }
              </TextField>
            </div>
            <div className={classes.configureLine}>
              <strong className={classes.configTitle}> Assign Animal Pen Target: </strong>
              <TextField
                select
                variant='outlined'
                className={classes.configComponent}
                name='assignAnimalPenTarget'
                onChange={this.handleChange}
                value={this.state.assignAnimalPenTarget}
              >
                {
                  ['Random', 'Fixed'].map((option, index) => (<MenuItem key={index} value={option}>{option}</MenuItem>))
                }
              </TextField>
            </div>
            <div className={classes.configureLine}>
              <strong className={classes.configTitle}> Auto Clear Fence When Max Is Hit? </strong>
              <TextField
                select
                variant='outlined'
                className={classes.configComponent}
                name='autoClearFenceWhenMaxIsHit'
                onChange={this.handleChange}
                value={this.state.autoClearFenceWhenMaxIsHit}
              >
                {
                  [
                    <MenuItem key={true} value={true}>True</MenuItem>,
                    <MenuItem key={false} value={false}>False</MenuItem>
                  ]
                }
              </TextField>
            </div>
            <div className={classes.configureLine}>
              <strong className={classes.configTitle}> Clear Fence On Incorrect Addition? </strong>
              <TextField
                select
                variant='outlined'
                className={classes.configComponent}
                name='clearFenceOnIncorrectAddition'
                onChange={this.handleChange}
                value={this.state.clearFenceOnIncorrectAddition}
              >
                {
                  [
                    <MenuItem key={true} value={true}>True</MenuItem>,
                    <MenuItem key={false} value={false}>False</MenuItem>
                  ]
                }
              </TextField>
            </div>
            <div className={classes.configureLine}>
              <strong className={classes.configTitle}> Length Of Round: </strong>
              <TextField
                variant='outlined'
                className={classes.configComponent}
                type='number'
                name='lengthOfRound'
                onChange={this.handleChange}
                value={this.state.lengthOfRound}
              />
            </div>
            <div className={classes.configureLine}>
              <strong className={classes.configTitle}> Number Of Animals To Spawn: </strong>
              <TextField
                variant='outlined'
                className={classes.configComponent}
                type='number'
                name='numberOfAnimalsToSpawn'
                onChange={this.handleChange}
                value={this.state.numberOfAnimalsToSpawn}
              />
            </div>
            <div className={classes.configureLine}>
              <strong className={classes.configTitle}> Pen Max: </strong>
              <TextField
                variant='outlined'
                className={classes.configComponent}
                type='number'
                name='penMax'
                onChange={this.handleChange}
                value={this.state.penMax}
              />
            </div>
            <div className={classes.configureLine}>
              <strong className={classes.configTitle}> Pen Set: </strong>
              <TextField
                select
                variant='outlined'
                className={classes.configComponent}
                type='text'
                name='penSet'
                onChange={this.handleChange}
                value={this.state.penSet}
              >
                {
                  ['leftRight', 'upDown', 'upDownLeftRight'].map((option, index) => (
                    <MenuItem key={index} value={option}>{option}</MenuItem>))
                }
              </TextField>
            </div>
            <div className={classes.configureLine}>
              <strong className={classes.configTitle}> Weighted Animal Spawn: </strong>
              <TextField
                variant='outlined'
                className={classes.configComponent}
                type='text'
                name='weightedAnimalSpawn'
                onChange={this.handleChange}
                value={this.state.weightedAnimalSpawn}
              />
            </div>
            <div className={classes.configureLine}>
              <strong className={classes.configTitle}> Fence Clear Announcement Index: </strong>
              <TextField
                variant='outlined'
                className={classes.configComponent}
                type='number'
                name='indexFenceClearAnnouncement'
                onChange={this.handleChange}
                value={this.state.indexFenceClearAnnouncement}
              />
            </div>
            <div className={classes.configureLine}>
              <strong className={classes.configTitle}> Fence Is Full Index: </strong>
              <TextField
                variant='outlined'
                className={classes.configComponent}
                type='number'
                name='indexFenceIsFull'
                onChange={this.handleChange}
                value={this.state.indexFenceIsFull}
              />
            </div>
            <div className={classes.configureLine}>
              <strong className={classes.configTitle}> Fence Is Full Penalty Index: </strong>
              <TextField
                variant='outlined'
                className={classes.configComponent}
                type='number'
                name='indexFenceIsFullPenalty'
                onChange={this.handleChange}
                value={this.state.indexFenceIsFullPenalty}
              />
            </div>
            <div className={classes.configureLine}>
              <strong className={classes.configTitle}> Wrong Hitch Index: </strong>
              <TextField
                variant='outlined'
                className={classes.configComponent}
                type='number'
                name='indexWrongHitch'
                onChange={this.handleChange}
                value={this.state.indexWrongHitch}
              />
            </div>
            <div className={classes.configureLine}>
              <strong className={classes.configTitle}> Animal Placed In Pen Index: </strong>
              <TextField
                variant='outlined'
                className={classes.configComponent}
                type='number'
                name='indexAnimalPlacedInPen'
                onChange={this.handleChange}
                value={this.state.indexAnimalPlacedInPen}
              />
            </div>
          </FormGroup>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button onClick={back} color='secondary'>
            <FormattedMessage id='General.back'/>
          </Button>
          <Button onClick={this.onSubmit} variant='contained' color='primary'>
            <FormattedMessage id='General.submit'/>
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(baseStyle)(BarnyardLevelTemplate);