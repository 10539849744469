import React from 'react';
import DataTable from '../Shared/DataTable';
import { FormattedMessage } from 'react-intl';

const GoalDatabankList = (props) => {
  const rows = [
    { id: 'name', label: <FormattedMessage id='General.name'/> },
    { id: 'description', label: <FormattedMessage id='General.description'/> },
  ];

  const title = (
    <>
      {
        props.myself
        ? <FormattedMessage id='General.my' />
        : `${props.staff.firstName}'s`}&nbsp;
      <FormattedMessage id='Databanks.goalDB'/>
    </>
  );

  return <DataTable
    myself={props.myself}
    staffId={props.id}
    account={props.account}
    clickable={props.clickable}
    type={props.type}
    editable
    deletable
    rows={rows}
    rowsPerPage={[5,10,15]}
    orderBy={'name'}
    data={props.goalDatabanks}
    numCols={2}
    title={title}
    history={props.history}
  />
};

export default GoalDatabankList;