import React, { Component } from 'react';
import {
  Button, DialogActions, DialogContent,
  Typography,
  withStyles,
} from '@material-ui/core/index';
import { FormattedMessage } from 'react-intl';
import styles from '../../../../styles/registration';

class RegistrationTerms extends Component {

  state = {
    scrolled: false
  };

  componentDidMount(){
    if (!this.props.purchase){
      // document.body.style.backgroundColor = '#310073';
    }
  }

  checkScrolled = ({target}) => {
    if (target.scrollHeight - target.scrollTop <= 440){
      this.setState({ scrolled: true })
    }
  };

  submit = () => {
    const value = this.props.registered
        ? 'https://docs.google.com/document/d/e/2PACX-1vRgiqiZ2EZ0jHECJ_bY972X3rhK1m722J19V7Rt3nTPnQLdzhMIMwQAH3_Iq1UM11Ask9bbs_l-ebB1/pub?embedded=true'
        : 'https://docs.google.com/document/d/e/2PACX-1vRgiqiZ2EZ0jHECJ_bY972X3rhK1m722J19V7Rt3nTPnQLdzhMIMwQAH3_Iq1UM11Ask9bbs_l-ebB1/pub?embedded=true';
    this.props.submit(value);
  };

  next = () => {
    this.props.next();
    const value = this.props.registered
      ? 'https://docs.google.com/document/d/e/2PACX-1vRgiqiZ2EZ0jHECJ_bY972X3rhK1m722J19V7Rt3nTPnQLdzhMIMwQAH3_Iq1UM11Ask9bbs_l-ebB1/pub?embedded=true'
      : 'https://docs.google.com/document/d/e/2PACX-1vRgiqiZ2EZ0jHECJ_bY972X3rhK1m722J19V7Rt3nTPnQLdzhMIMwQAH3_Iq1UM11Ask9bbs_l-ebB1/pub?embedded=true';
    this.props.handleChange({ target: { name: 'terms', value }})
  };

  render(){
    const { classes, registered, purchase } = this.props;
    return (
      <>
        {
          registered || purchase
          ? <DialogContent className={classes.center}>
            <Typography variant='h6'> <FormattedMessage id='Paddle.terms'/> </Typography>
            <iframe
              id='toi'
              title='Terms of Use'
              width={540}
              height={400}
              onScroll={this.checkScrolled}
              src="https://docs.google.com/document/d/e/2PACX-1vRgiqiZ2EZ0jHECJ_bY972X3rhK1m722J19V7Rt3nTPnQLdzhMIMwQAH3_Iq1UM11Ask9bbs_l-ebB1/pub?embedded=true"
            />
          </DialogContent>
          : <>
            <Typography variant='h6'> <FormattedMessage id='Paddle.terms'/> </Typography>
            <iframe
              id='toi'
              title='Terms of Use'
              width={600}
              height={400}
              onScroll={this.checkScrolled}
              src='https://docs.google.com/document/d/e/2PACX-1vRgiqiZ2EZ0jHECJ_bY972X3rhK1m722J19V7Rt3nTPnQLdzhMIMwQAH3_Iq1UM11Ask9bbs_l-ebB1/pub?embedded=true'
            />
          </>
        }
        {
          registered || purchase
          ? <DialogActions>
              <Button
                id='decline-TOI'
                color='secondary'
                onClick={() => this.props.goToPage(1)}
              >
                <FormattedMessage id='Terms.decline'/>
              </Button>
              <Button
                variant='contained'
                color='primary'
                id='accept-TOI'
                onClick={this.next}
              >
                <FormattedMessage id='Terms.accept'/>
              </Button>
            </DialogActions>
          : <div className={classes.toiButtons}>
            <Button
              id='accept-TOI'
              variant='contained'
              color='primary'
              onClick={this.submit}
            >
              <FormattedMessage id='Terms.accept'/>
            </Button>
            <Button
              id='decline-TOI'
              onClick={() => this.props.goToPage(1)}
              size='small'
            >
              <FormattedMessage id='Terms.decline'/>
            </Button>
          </div>
        }
      </>
    )
  }
}

export default withStyles(styles)(RegistrationTerms);