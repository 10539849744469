import React, { Component } from 'react';
import { Button, FormGroup, Typography, IconButton, TextField, MenuItem } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../../styles/wizardBase';
import { FormattedMessage } from 'react-intl';

class SliderListManagementPage1 extends Component {
    
  close = () => {
    this.props.close();
  };

  render()  {
    const { classes, open, close, submit } = this.props;
      
      return (
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        open={open}
        onClose={close}
        aria-labelledby='level-dialog-title'
        PaperProps={{ classes:{ root: classes.container }}}
      >
        <DialogTitle disableTypography={true} className={ classes.title } id='level-dialog-title'>
          <Typography className={ classes.titleText } variant='h4'><FormattedMessage id='SliderListManagement.Title'/></Typography>
          <IconButton
            onClick={this.close}
            className={ classes.exitIcon }
            aria-label="close dialog"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.subtext}>
            <FormattedMessage id='Wizard.SliderListManagement'/>
          </DialogContentText>
          
          <FormGroup classes={{ root: classes.multiline }}>
            <TextField
              variant='outlined'
              classes={{ root: classes.firstName }}
              label={<FormattedMessage id='General.name'/>}
              name='name'
              type='text'
              onChange={this.props.handleChange}
            />
            <TextField
              multiline
              classes={{ root: classes.lastName }}
              variant='outlined'
              label={<FormattedMessage id='General.description'/>}
              name='description'
              type='text'
              onChange={this.props.handleChange}
            />
          </FormGroup>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button onClick={this.close} color='secondary'>
            <FormattedMessage id='General.cancel'/>
          </Button>
          <Button onClick={submit} color='primary'>
            <FormattedMessage id='General.submit'/>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(baseStyle)(SliderListManagementPage1);