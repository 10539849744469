import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  withStyles
} from '@material-ui/core/index';
import { Search } from '@material-ui/icons/index';
import { getSorting, stableSort } from '../../../helpers';
import { fetchAccounts } from '../../../redux/actions';
import baseStyle from '../../../styles/skillList.js';

class SupportChartList extends Component {

  state = {
    order: 'asc',
    orderBy: this.props.orderBy,
    rowsPerPage: 10,
    page: 0,
    searchCategory: 'firstName',
    filteredData: [],
    searchValue: '',
    rows: [
      { id: 'name', label: <FormattedMessage id='General.name'/>, searchable: true },
      { id: 'status', label: <FormattedMessage id='General.status'/>, searchable: true},
      { id: 'type', label: <FormattedMessage id='General.type'/>, searchable: true},
      { id: 'studentCount', label: <FormattedMessage id='General.studentCount'/>},
      { id: 'brailleAIStudentCount', label: 'Braille AI'},
    ],
  };

  componentDidMount() {
    this.props.fetchAccounts();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.accounts !== this.props.accounts){
      this.handleSearch(this.state.searchValue, nextProps.accounts)
    }
  }

  handleSearch = (searchValue, data) => {
    const accounts = data ? data : this.props.accounts;
    const filtered = accounts.filter(item => {
      const searchableKeys = Object.keys(item).filter(key => this.state.rows.some(row => row.id === key));
      return searchableKeys.some(key => {
        if (typeof (item[key]) === 'string'){
          return item[key].toLowerCase().includes(searchValue.toLowerCase())
        }
        else {
          return false
        }
      })
    });
    this.setState({ filteredData: filtered, page: 0 })
  };

  handleSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { classes } = this.props;
    const { rows, orderBy, order, rowsPerPage, page, searchValue, filteredData } = this.state;
    const data = searchValue ? filteredData : this.props.accounts;
    return (
      <>
        <Paper className={ classes.list }>
          <Toolbar classes={{ root: classes.toolbar }}>
            <Typography noWrap className={ classes.header }>
              <strong><FormattedMessage id='Sidebar.accounts'/></strong>
            </Typography>
            <div className={classes.actions}>
              <TextField
                className={ classes.search }
                variant='outlined'
                id='table-search'
                aria-label='search input'
                type={'search'}
                InputProps={{
                  inputProps: {
                    'aria-label':'search input'
                  },
                  className: classes.searchBar,
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Search />
                    </InputAdornment>
                  ),
                }}
                onChange={(ev) => {
                  this.handleSearch(ev.target.value);
                  this.setState({ searchValue: ev.target.value });
                }}
              />
            </div>
          </Toolbar>
          <div className={classes.tableWrapper}>
            <Table>
              <TableHead>
                <TableRow>
                  { rows.length &&
                  rows.map(row => {
                    return (
                      <TableCell
                        key={row.id}
                        sortDirection={ orderBy === row.id ? order : false }
                      >
                        <Tooltip
                          title='Sort'
                          placement='bottom-start'
                          enterDelay={300}
                        >
                          <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={(ev) => this.handleSort(ev, row.id)}
                          >
                            {row.label}
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                    )
                  })
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(data, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(_data => {
                    return (
                      <TableRow
                        onClick={() => this.props.changeAccount(_data)}
                        hover
                        className={classes.hover}
                        key={_data.id}
                      >
                        {
                          rows.length &&
                          rows.map((row, index) => {
                            return (
                              <TableCell key={index}>
                                {
                                  typeof _data[row.id] === 'object'
                                    ? _data[row.id] ? `${_data[row.id].monthValue}/${ _data[row.id].dayOfMonth}/${ _data[row.id].year}` : 'N/A'
                                    : _data[row.id]
                                }
                              </TableCell>
                            )
                          })
                        }
                      </TableRow>
                    );
                  })
                }
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[5,10,25]}
            component='div'
            labelRowsPerPage={<FormattedMessage id='Datatable.rowsPerPage'/>}
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </>
    );
  }
}

const mapState = ({ shared: { accounts }}) => {
  return {
    accounts,
  }
};

const mapDispatch = (dispatch, { history }) => {
  return {
    fetchAccounts: () => dispatch(fetchAccounts(history)),
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(SupportChartList));