import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../../styles/wizardBase';
import StudentEdit from '../Student/StudentEdit';
import StaffEdit from '../Staff/StaffEdit';
import LevelEdit from '../Level/LevelEdit';
// import SkillDatabankEdit from '../SkillDatabank/SkillDatabankEdit';
import SkillEdit from '../Skill/SkillEdit';
import BrailleSheetsEditWizard from '../BrailleSheets/BrailleSheetsEditWizard';
import SkillDatabankWizardBase from '../SkillDatabank/SkillDatabankWizardBase';
import GoalDatabankWizardBase from '../GoalDatabank/GoalDatabankWizardBase';
import GoalWizardBase from '../Goal/GoalWizardBase';
import AccountEdit from '../Account/AccountEdit';
import SliderListManagementEdit from '../SliderListManagement/SliderListManagementEdit'; 
import SliderSeriesManagementEdit from '../SliderSeriesManagement/SliderSeriesMangementEdit'; 

class EditWizardBase extends React.Component {

  render(){
    const level = this.props.data;
    const levelTemplate = this.props.levels.find(_level => _level.id === level.levelTemplateId);
    const game = levelTemplate && this.props.games.find(game => levelTemplate.gameId === game.id);
    switch (this.props.type){
      case 'level':
        return <LevelEdit
          data={this.props.data}
          open={this.props.open}
          close={this.props.close}
        />;
      case 'skillDatabank':
        return <SkillDatabankWizardBase
          id={this.props.id}
          history={this.props.history}
          data={this.props.data}
          open={this.props.open}
          close={this.props.close}
          type={this.props.type}
          game={game}
          level={level}
          edit={true}
          levelTemplate={levelTemplate}
        />;
      case 'staffSkillDatabank':
        return <SkillDatabankWizardBase
          history={this.props.history}
          id={this.props.id}
          data={this.props.data}
          open={this.props.open}
          close={this.props.close}
          type={this.props.type}
          game={game}
          level={level}
          levelTemplate={levelTemplate}
          edit={true}
        />;
      case 'staff':
        return <StaffEdit
          id={this.props.id}
          data={this.props.data}
          open={this.props.open}
          close={this.props.close}
          students={this.props.staffStudents}
        />;
      case 'student':
        return <StudentEdit
          data={this.props.data}
          open={this.props.open}
          close={this.props.close}
        />;
      case 'skill':
        return <SkillEdit
          data={this.props.data}
          open={this.props.open}
          close={this.props.close}
        />;
      case 'brailleSheet':
        return <BrailleSheetsEditWizard
          history={this.props.history}
          data={this.props.data}
          open={this.props.open}
          close={this.props.close}
        />;
      case 'staffBrailleSheet':
        return <BrailleSheetsEditWizard
          history={this.props.history}
          data={this.props.data}
          open={this.props.open}
          close={this.props.close}
        />;
      case 'goalDatabank':
        return <GoalDatabankWizardBase
          data={this.props.data}
          open={this.props.open}
          close={this.props.close}
          id={this.props.id}
          edit={true}
        />;
      case 'staffGoalDatabank':
        return <GoalDatabankWizardBase
          data={this.props.data}
          open={this.props.open}
          close={this.props.close}
          id={this.props.id}
          edit={true}
        />;
      case 'goal':
        return <GoalWizardBase
          data={this.props.data}
          open={this.props.open}
          close={this.props.close}
          id={this.props.id}
          edit={true}
        />;
      case 'account':
        return <AccountEdit
          data={this.props.data}
          open={this.props.open}
          close={this.props.close}
          id={this.props.id}
          edit={true}
        />;
        case 'slider':
          return <SliderListManagementEdit
            data={this.props.data}
            open={this.props.open}
            close={this.props.close}
        />;
        case 'sliderSeries':
          return <SliderSeriesManagementEdit
            data={this.props.data}
            open={this.props.open}
            close={this.props.close}
          />;
      default:
        return null;
    }
  }
}

const mapState = ({ platform: { staffStudents, games, levels }}) => {
  return {
    staffStudents,
    games,
    levels
  }
};

export default connect(mapState)(withStyles(baseStyle)(EditWizardBase));