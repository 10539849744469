import React, { Component } from 'react';
import { Card, Typography, Divider, Button } from '@material-ui/core';
import { PieChartRounded } from '@material-ui/icons';
import { ResponsivePie } from '@nivo/pie';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../styles/pieChart';
import { FormattedMessage } from 'react-intl';

class HoursPlayedPie extends Component {
  state = {
    loading: this.props.loading || true
  };

  componentDidMount() {
    setTimeout(() => this.setState({ loading: false }), 500)
  }

  onClick = (ev) => {
    this.props.toggleSkill(ev);
  };

  tooltip = (d) => {
    const value = d.datum.value;
      const seconds = value;
      const minutes = seconds / 60;
      const hours = minutes / (60);
    const type = hours > 1 ? 'hrs' : minutes>1 ? 'mins': 'sec';
    const playTime = hours > 1 ? hours.toFixed(1) : minutes>1 ? minutes.toFixed(0) : seconds;
    return (
        <div
            style={{
              background: 'white',
              padding: '9px 12px',
              border: '1px solid #ccc',
            }}>
          <div>
              {d.datum.id}: <strong>{playTime} {type}</strong>
          </div>
        </div>
    )
  };

  render(){
    const { classes, title, time, data, changeTime } = this.props;
    const { loading } = this.state;
    return (
      <Card aria-label={title}>
        <div className={classes.header}>
          <PieChartRounded className={classes.icon}/>
          <Typography className={classes.chartTitle} variant='h5'>{title}</Typography>
        </div>
        <Divider />
        <div className={classes.buttons}>
          <Button
            onClick={() => changeTime(0)}
            variant={time === 0 ? 'contained' : 'text'}
            color={time === 0 ? 'primary' : 'default'}
          >
            <FormattedMessage id='Chart.allTime'/>
          </Button>
          <Button
            onClick={() => changeTime(365)}
            variant={time === 365 ? 'contained' : 'text'}
            color={time === 365 ? 'primary' : 'default'}
          >
            365&nbsp;<FormattedMessage id='Chart.days'/>
          </Button>
          <Button
            onClick={() => changeTime(30)}
            variant={time === 30 ? 'contained' : 'text'}
            color={time === 30 ? 'primary' : 'default'}
          >
            30&nbsp;<FormattedMessage id='Chart.days'/>
          </Button>
          <Button
            onClick={() => changeTime(7)}
            variant={time === 7 ? 'contained' : 'text'}
            color={time === 7 ? 'primary' : 'default'}
          >
            7&nbsp;<FormattedMessage id='Chart.days'/>
          </Button>
        </div>
        {
          data.length ?
          <ResponsivePie
            className={classes.listChart}
            data={data}
            margin={{
              "top": 20,
              "right": 80,
              "bottom": 180,
              "left": 80
            }}
            innerRadius={.5}
            sliceLabel={d => {
                const seconds = d.value;
                const minutes = seconds / 60;
                const hours = minutes / (60);
                const type = hours > 1 ? 'hrs' : minutes>1 ? 'mins': 'sec';
                const playTime = hours > 1 ? hours.toFixed(1) : minutes>1 ? minutes.toFixed(0) : seconds;
              return `${playTime} ${type}`
            }}
            tooltip={(slice) => this.tooltip(slice)}
            padAngle={0.7}
            cornerRadius={3}
            colors={{"scheme": "blues"}}
            colorBy="id"
            borderWidth={1}
            borderColor={{ from: 'color', modifiers: [ [ 'darker', .2 ] ] }}
            radialLabelsSkipAngle={10}
            radialLabelsTextXOffset={6}
            radialLabelsTextColor="#333333"
            radialLabelsLinkOffset={0}
            radialLabelsLinkDiagonalLength={16}
            radialLabelsLinkHorizontalLength={24}
            radialLabelsLinkStrokeWidth={1}
            radialLabelsLinkColor="#333333"
            slicesLabelsSkipAngle={10}
            slicesLabelsTextColor="#333333"
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            // defs={[
            //   {
            //     "id": "lines",
            //     "type": "patternLines",
            //     "background": "inherit",
            //     "color": "rgba(255, 255, 255, 0.3)",
            //     "rotation": -45,
            //     "lineWidth": 6,
            //     "spacing": 10
            //   },
            //   {
            //     "id": "dots",
            //     "type": "patternDots",
            //     "background": "inherit",
            //     "color": "rgba(255, 255, 255, 0.3)",
            //     "size": 4,
            //     "padding": 1,
            //     "stagger": true
            //   }
            // ]}
            // fill={[
            //   { "match": { "id": "Bop Gesture" }, "id": "lines" },
            //   { "match": { "id": "Barn Yard Animals" }, "id": "dots" },
            // ]}
            theme={{
              labels: {
                text: { fontSize: 12, fontWeight: 800 }
              }
            }}
          />
            : !loading && <Typography className={classes.empty} align='center' variant='h4'> <FormattedMessage id='Chart.empty'/> </Typography>
        }
      </Card>
    )
  }
}

export default withStyles(baseStyle)(HoursPlayedPie);