import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import SliderContext from './context'
import Content from './Content'
import SlideButton from './SlideButton'
import SliderWrapper from './SliderWrapper'
import useSliding from './useSliding'
import useSizeElement from './useSizeElement';
import DetailsWizard from './DetailsWizard.js';
import './Slider.css'

const Slider = ({ books, children, activeSlide }) => {
  const [currentSlide, setCurrentSlide] = useState(activeSlide);
  const [storyId, setStoryId] = useState(null);
  const [seriesTitle, setSeriesTitle] = useState(null);
  const [isImported, setIsImported] = useState(false);
  const { width, elementRef } = useSizeElement();
  const {
    handlePrev,
    handleNext,
    slideProps,
    containerRef,
    hasNext,
    hasPrev
  } = useSliding(width, React.Children.count(children));
  const [selectedBook, setSelectedBook] = useState();

  useEffect(() => {
    if (currentSlide && currentSlide.type === 'Story') {
      if (books.some(book => book.name === currentSlide.name)) {
        setIsImported(true);
      }
    }
  }, [currentSlide])


  const handleSelect = (movie, id, storyHeader) => {
    setCurrentSlide(movie);
    setStoryId(id);
    setSeriesTitle(storyHeader);
  };

  const handleClose = () => {
    setCurrentSlide(null);
    setSelectedBook(null);
  };

  const contextValue = {
    onSelectSlide: handleSelect,
    onCloseSlide: handleClose,
    elementRef,
    currentSlide,
  };

  const handleSelectedBook = (currentBook) => {
    setSelectedBook(currentBook);
 }
  let type = currentSlide && currentSlide.type;

  return (
    <SliderContext.Provider value={contextValue}>
      <SliderWrapper>
        <div
          className={cx('slider', { 'slider--open': currentSlide != null })}
        >
          <div ref={containerRef} className="slider__container" {...slideProps}>{children}</div>
        </div>
        {hasPrev && <SlideButton onClick={handlePrev} type="prev" />}
        {hasNext && <SlideButton onClick={handleNext} type="next" />}
      </SliderWrapper>
      {currentSlide && currentSlide.type === 'Series'
        ? <Content movie={currentSlide} seriesTitle={seriesTitle} storyId={storyId} onClose={handleClose} />
        : currentSlide && <DetailsWizard
          storyId={storyId}
          story={currentSlide}
          isImported={isImported}
          open={type}
          exitFunc={handleClose}
          selectedBook={selectedBook}
          handleSelectedBook={handleSelectedBook}
        />
      }
    </SliderContext.Provider>
  );
};

const mapState = ({ platform: { books } }) => {
  return {
    books
  }
};

export default connect(mapState)(Slider);
