import { DEV_DB, GET_SCORE_CHART, HANDLE_ERROR, ROOT_URL, GET_WEEKLY_SUMMARIES } from '../../constants';
import moment from 'moment';
import axios from 'axios';
import { saveAs } from 'file-saver';


export const getScoreCharts = (skill, studentId, history) => {
  const url = `${ROOT_URL}/students/${studentId}/objectives/${skill.id}/scores/charts`;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        if (typeof(response.data) === 'object'){
          dispatch({
            type: GET_SCORE_CHART,
            charts: response.data
          })
        }
        else {
          history.push('/login');
          dispatch({
            type: HANDLE_ERROR,
            error: {type: 'sessionExp', msg: 'Your session has expired, please log back in'}
          })
        }
      })
      .catch(err => {
        console.log(err)
        dispatch({
          type: HANDLE_ERROR,
          error: {type: 'noData', msg: 'There is not enough data collected yet'}
        })
      })
  }
};


export const getScoreChartsDirect = (skill, studentId, history) => {
  const url = `${ROOT_URL}/students/${studentId}/objectives/${skill.id}/scores/charts`;
  return
    axios
        .get(url)
        .then((response) => {
          return response.data;
        })
        .catch(err => err)
};

export const hoursPlayedPerSkill = (skill, id, days) => {
  const checkDate = days !== 0 ? moment().subtract(days, 'days') : moment('1991-09-05');
  const url = `${ROOT_URL}/students/${id}/dashboards/hours-played`;
  return axios
    .get(url)
    .then(response => {
      return response.data.reduce((memo, score) => {
        let _skill = memo.find(skill => skill.skillId === score.skillId);
        if (_skill) {
          if (moment(score.time).isAfter(checkDate)){
            memo[memo.indexOf(_skill)].value =  _skill.value + score.value;
          }
        }
        else if (moment(score.time).isAfter(checkDate)) {
          if (score.gameName === skill.id) {
            memo.push(score)
          }
        }
        return memo
      }, [])
    })
    .catch(err => err)
};

export const weeklySummarys = (id) => {
  const url = `${ROOT_URL}/staff/${id}/dashboards/weekly-summary`
  return (dispatch) => {
    axios
        .get(url)
        .then((response) => {
          dispatch({
            type: GET_WEEKLY_SUMMARIES,
            weeklySummaries: response.data
          })
        })
        .catch(err => console.log(err))
  };
}

export const hoursPlayerPerGameForStaff = (id, days) => {
  const checkDate = days !== 0 ? moment().subtract(days, 'days') : moment('1991-09-05');
  const url = `${ROOT_URL}/staff/${id}/dashboards/hours-played`;
  return axios
      .get(url)
      .then(response => {
        return response.data.reduce((memo, score) => {
          let student = memo.find(playTime => (playTime.studentId === score.studentId && playTime.gameName === score.gameName));
          if (student) {
            if (moment(score.time).isAfter(checkDate)){
              memo[memo.indexOf(student)].value = student.value + score.value;
            }
          }
          else {
            if (moment(score.time).isAfter(checkDate)) {
              memo.push(score)
            }
          }
          return memo
        }, [])
      })
      .catch(err => err)
};


export const hoursPlayedPerGame = (type, id, days) => {
  const checkDate = days !== 0 ? moment().subtract(days, 'days') : moment('1991-09-05');
  const url = type === 'staff'
    ? `${ROOT_URL}/staff/${id}/dashboards/hours-played`
    : `${ROOT_URL}/students/${id}/dashboards/hours-played`;
  return axios
    .get(url)
    .then(response => {
      return response.data.reduce((memo, score) => {
        let game = memo.find(game => game.gameName === score.gameName);
        if (game) {
          if (moment(score.time).isAfter(checkDate)){
            memo[memo.indexOf(game)].value = game.value + score.value;
          }
        }
        else {
          if (moment(score.time).isAfter(checkDate)) {
            memo.push(score)
          }
        }
        return memo
      }, [])
    })
    .catch(err => err)
};

export const percentToTarget = (studentId) => {
  const url =`${ROOT_URL}/students/${studentId}/dashboards/percent-to-target`;
  return axios
    .get(url)
    .then(response => {
      if (response.status !== 500) {
        return response.data.reduce((chart, skill) => {
          const time = moment(skill.time).format('MMM D, YYYY');
          if (chart.some(_skill => _skill.id === skill.skillId)) {
            return chart;
          }
          else {
            chart.push({
              "skill": skill.skillName,
              date: time,
              id: skill.skillId,
              'Percent to Target': skill.per,
              goalId: skill.goalId
            });
            return chart;
          }
        }, [])
      }
      else {
        return []
      }
    })
    .catch(err => console.log(err))
};

export const percentToTargetLine = (studentId, startDate, endDate) => {
  var url =`${ROOT_URL}/students/${studentId}/dashboards/percent-to-target`;
  const addendum=`?startDate=${startDate}&endDate=${endDate}`;
  if(startDate && endDate) {
    url = url+addendum;
  }
  return axios
    .get(url)
    .then(response => {
      // turn chart data into dates and sort them by date
      const sortedData = response.data.sort((a,b) => moment(a.time) - moment(b.time));
      return sortedData.reduce((memo, score) => {
        let indexCk;
        if (memo.some((skill, index) => {
          indexCk = index;
          return skill.id === score.skillName
        })){
          memo[indexCk].data.push({
            'x': moment(score.time).toDate(),
            'y': score.per
          })
        }
        else {
          memo.push({
            id: score.skillName,
            data: [
                {
                  'x': moment(score.time).toDate(),
                  'y': score.per
                }
              ]
          })
        }
        return memo
      }, [])
    })
    .catch(err => err)
};

export const brailleAITime = (type, id) => {
  const url = `${ROOT_URL}/${type}/${id}/dashboards/braille-ai-hours`;
  return axios
    .get(url)
    .then((response) => {
      return response.data
    })
    .catch(err => console.log(err))
};

export const logPageView = (user, account, route) => {
  const url = `${DEV_DB}/page-views`;
  axios
    .post(url, { user, account, route })
    .then((response) => {
      return response.data
    })
    .catch(err => console.log(err))
};

export const getPageViewsChart = (accountId) => {
  const url = `${DEV_DB}/page-views/chart/${accountId}`;
  return axios
    .get(url)
    .then((response) => {
      return response.data
    })
    .catch(err => console.log(err))
};

export const minutesPlayedChart = (accountId) => {
  const url = `${ROOT_URL}/support/metrics/time-played?accountId=${accountId}`;
  return axios
    .get(url)
    .then((response) => {
      return response.data
    })
    .catch(err => console.log(err))
};

export const sheetsCreatedChart = (accountId) => {
  const url = `${ROOT_URL}/support/metrics/sheets?accountId=${accountId}`;
  return axios
    .get(url)
    .then((response) => {
      return response.data
    })
    .catch(err => console.log(err))
};

export const skillsCreatedChart = (accountId) => {
  const url = `${ROOT_URL}/support/metrics/skills?accountId=${accountId}`;
  return axios
    .get(url)
    .then((response) => {
      return response.data
    })
    .catch(err => console.log(err))
};

export const brailleSheetsChart = (studentId) => {
  const url = `${ROOT_URL}/students/${studentId}/braille-dashboard/braille-sheet-plays`;
  return axios
    .get(url)
    .then((response) => {
      return response.data
    })
    .catch(err => console.log(err))
};

export const brailleAIChart = (studentId) => {
  const url = `${ROOT_URL}/students/${studentId}/braille-dashboard/braille-ai-plays`;
  return axios
    .get(url)
    .then((response) => {
      return response.data
    })
    .catch(err => console.log(err))
};

export const getAIAudioFile = (studentId, fileId, phonemeReview = false) => {
  const url = `${ROOT_URL}/students/${studentId}/speech/${fileId}/file`;
  return axios
    .get(url, { responseType: 'blob' })
    .then((response) => {
      if (phonemeReview) { 
        return response.data;
      }
      saveAs(response.data, 'speech.wav');
    })
    .catch(err => console.log(err))
};