import {
  ROOT_URL,
  FETCH_ALL_ROLES,
  FETCH_ALL_LIST_CONFIGS,
  FETCH_ALL_GAME_FIELD_MAPS,
  CREATE_LIST_CONFIG,
  CREATE_GAME_FIELD_MAP,
  RESET
} from '../../constants';
import axios from 'axios';

export const fetchRoles = (account) => {
  const url = `${ROOT_URL}/roles?accountId=${account.id}`;
  return (dispatch) => {
    axios
      .get(url, { withCredentials:true })
      .then((response) => {
        dispatch({
          type: FETCH_ALL_ROLES,
          roles: response.data
        })
      })
      .catch(err => console.error(err))
  }
};

export const fetchListConfigs = (account, group) => {
  const url = `${ROOT_URL}/lists?groupName=${group}&accountId=${account.id}`;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: FETCH_ALL_LIST_CONFIGS,
          listConfigs: response.data
        })
      })
  }
};

export const fetchGameFieldMaps = (account, group) => {
  const url = `${ROOT_URL}/game-field-maps?groupName=${group}&accountId=${account.id}`;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: FETCH_ALL_GAME_FIELD_MAPS,
          gameFieldMaps: response.data
        })
      })
  }
};

export const createListConfig = (account, group, listConfig) => {
  const url = `${ROOT_URL}/lists?groupName=${group}&accountId=${account.id}`;
  return (dispatch) => {
    axios
      .post(url, listConfig)
      .then((response) => {
        dispatch({
          type: CREATE_LIST_CONFIG,
          listConfig: response.data
        })
      })
  }
};

export const clearLists = () => {
  return (dispatch) => {
    dispatch({
      type: RESET
    })
  }
};

export const createGameFieldMap = (account, group, gameFieldMap) => {
  const url = `${ROOT_URL}/game-field-maps?groupName=${group}&accountId=${account.id}`;
  return (dispatch) => {
    axios
      .post(url, gameFieldMap)
      .then((response) => {
        dispatch({
          type: CREATE_GAME_FIELD_MAP,
          gameFieldMap: response.data
        })
      })
  }
};

export const fetchGroups = (type, account) => {
  const url = ( type === 'game' )
    ? `${ROOT_URL}/game-field-maps/groupNames?accountId=${account.id}`
    : `${ROOT_URL}/${type}s/groupNames?accountId=${account.id}`;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: `FETCH_ALL_${type.toUpperCase()}_GROUPS`,
          [`${type}Groups`]: response.data
        })
      })
  }
};

export const reset = () => {
  return (dispatch) => {
    dispatch({
      type: RESET
    })
  }
};