export default {
  header: {
    gridColumn: 1,
    gridRow: 1,
    flex: '0 0 auto',
    fontSize: '24px',
    color: '#fff',
    marginLeft: '20px',
  },
  toolbar: {
    display: 'grid',
    gridTemplateColumns: '4fr 6fr',
    gridTemplateRows: '1fr',
    borderRadius: '5px 5px 0 0',
    backgroundColor: '#00729b',
    boxShadow: ' 0px 3px 3px #6b6b6b',
  },
  actions: {
    gridColumn: 2,
    gridRow: 1,
    justifySelf: 'end',
    marginRight: '20px',
  },
  searchBar: {
    color: '#000',
    fontWeight: 600,
    width: 320,
    margin: '10 auto',
    backgroundColor: 'white',
    borderRadius: '4px',
    height: 40,
    alignSelf: 'center'
  },
  select: {
    color: '#fff',
    fontWeight: 800,
    width: '120px',
    textAlign: 'center',
  },
  icon: {
    color: '#fff',
    fontWeight: 800,
  },
  underline: {
    '&:before': {
      borderBottom: '1px solid #fff',
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: '1px solid #fff',
    },
    '&:hover': {
      borderBottom: '1px solid #fff',
    },
    '&:after': {
      borderBottom: '1px solid #fff',
    }
  },
  searchIcon: {
    color: '#fff',
  },
  list: {
    borderRadius: '5px',
  },
  buttons: {
    display: 'grid',
    gridTemplateColumns: 'auto',
    gridAutoFlow: 'column'
  },
  delete: {
    gridColumn: 1
  },
  edit: {
    gridColumn: 2
  },
  selected: {
    backgroundColor: '#c7c2cd',
  },
  disabled: {},
  focused: {},
  error: {},
  tableWrapper: {
    overflowX: 'auto',
  },
  billingInfo: {
    padding: 20
  },
  title: {
    backgroundColor: '#383871',
    marginBottom: 15,
  },
  titleText: {
    float: 'left',
    color: '#fff'
  },
  editIcon: {
    float: 'right',
    color: '#fff',
  },
  firstName: {
    marginBottom: '10px',
  },
  addressLine: {
    display: 'grid',
    gridTemplateColumns: '3fr 4fr 3fr',
    gridColumnGap: 10
  },
  priceLine: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: 10
  },
  detailsLine: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '1fr 2fr'
  },
  address: {
    marginTop: 10,
    gridAutoFlow: 'columns'
  },
  download: {
    gridRow: 2,
    marginTop: 20,
    justifySelf: 'center',
    gridColumn: '1 /span 2'
  },
  termsError: {
    gridRow: 3,
    marginTop: 5,
    justifySelf: 'center',
    gridColumn: '1 /span 2'
  },
  hover: {
    '& :hover': {
      cursor: 'pointer'
    }
  }
}