export default {
  resetPasswordContainer: {
    margin: '120px auto 0 auto',
    padding: '20px',
    maxWidth: '600px',
    boxShadow: '0px 1px 5px 0px rgba(107, 107, 107, 1), 0px 2px 2px 0px rgba(107, 107, 107, 0.9), 0px 3px 1px -2px rgba(107, 107, 107, 0.8)',
  },
  resetPasswordForm: {
    display: 'grid',
    gridGap: '15px',
    padding: '0 50px',
  },
  resetPasswordBtn: {
    justifySelf: 'center',
    width: '180px',
    margin: '40px 0',
  },
  resetPasswordLogo: {
    gridRow: '1',
    width: '500px',
  },
  error: {
    backgroundColor: '#f44336'
  },
  success: {
    backgroundColor: '#43a047'
  }
}