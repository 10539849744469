export default {
  homePage: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    gridColumnGap: '15px',
    height: '100%',
  },
  left: {
    paddingTop: 20,
  },
  right: {
    paddingTop: 20,
    height: 120
  },
  center: {
    paddingTop: 20,
    gridColumn: 2,
    overflowY: 'scroll',
  },
  divider: {
    width: '98%',
    margin: '0 auto'
  },
  '@media (max-width: 500px)': {
    center: {
      gridColumnStart: 1,
      gridColumnEnd: -1
    },
    container: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      paddingTop: 30,
    },
    left: {
      display: 'none',
    },
    right: {
      display: 'none',
    },
  },
  emptyFeed: {
    padding: 20,
    textAlign: 'center',
    display: 'grid',
    '& img':{
      justifySelf: 'center'
    }
  },
}