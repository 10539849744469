export default {
  // Shared
  "Authentication.MFA.Register": "Your Organization uses Multi-Factor Authentication.  Please download the Google Authenticator app on your smart phone to scan the following barcode:",
  "General.yes": "Yes",
  "General.no": "No",
  "General.easy": "Easy",
  "General.medium": "Medium",
  "General.hard": "Hard",
  "General.firstName": "First Name",
  "General.lastName": "Last Name",
  "General.role": "Role",
  "General.name": "Name",
  "General.title": "Title",
  "General.author": "Author",
  "General.synopsis": "Synopsis",
  "General.isbn": "ISBN",
  "General.id": "Id",
  "General.area": "Area",
  "General.timed": "Timed?",
  "General.description": "Description",
  "General.ending": "Ending",
  "General.visemeData": "Viseme Data",
  "General.analysis": "Analysis",
  "General.sectionIndex": "Section Index",
  "General.source": "Source",
  "General.sourceId": "Source Id",
  "General.offset": "Offset",
  "General.image": "Image",
  "General.gradeLevel": "Grade Level",
  "General.wordRate": "Word Rate",
  "General.difficulty": "Difficulty",
  "General.email": "Email",
  "General.edit": "Edit",
  "General.add": "Add",
  "General.delete": "Delete",
  "General.save": "Save",
  "General.cancel": "Cancel",
  "General.close": "Close",
  "General.targetPercentage": "Target Percentage",
  "General.targetPrompts": "Target Prompts",
  "General.active": "Active?",
  "General.active2": "Active",
  "General.inactive": "Inactive",
  "General.version": "Version",
  "General.passage.length": "Passage Length",
  "General.passage.length.sub": "Paragraph/Sentence",
  "General.passage.echo": "Echo Mode",
  "General.passage.echo.sub": "Sentence Only",
  "General.sort": "Sort",
  "General.select": "Select",
  "General.games": "Games",
  "General.game": "Game",
  "General.next": "Next",
  "General.levels": "Levels",
  "General.back": "Back",
  "General.submit": "Submit",
  "General.refresh": "Refresh",
  "General.test": "Test",
  "General.updatedSystem": "We have updated our System",
  "General.retest": "Re-Test",
  "General.type": "Type",
  "General.search": "Search",
  "General.text": "Text",
  "General.comment": "Comment",
  "General.done": "Done",
  "General.status": "Status",
  "General.studentCount": "Student Count",
  "General.studentsEnabled": "Students Enabled For Login",
  "General.activate": "Activate",
  "General.suspend": "Suspend",
  "General.levelVars": "Level Variables",
  "General.value": "Value",
  "General.share": "Share",
  "General.internal": "Internal",
  "General.customer": "Customer",
  "General.pilot": "Pilot",
  "General.sales": "Sales",
  "General.viewLess": "View Less",
  "General.viewMore": "View More",
  "General.view": "View",
  "General.join": "Join",
  "General.download": "Download BRF",
  "General.continue": "Continue",
  "General.primaryEmail": "Primary Email",
  "General.secondaryEmail": "Secondary Email",
  "General.street1": "Street Address Line 1",
  "General.street2": "Address Line 2",
  "General.state": "State/Province",
  "General.postalCode": "Postal Code",
  "General.country": "Country",
  "General.city": "City",
  "General.rate": "Rate",
  "General.startDate": "Start Date",
  "General.cycle": "Billing Cycle",
  "General.ok": "Ok",
  "General.my": "My",
  "General.billableStudent": "Billable Student Count",
  "General.studentsBillable": "Student Billing Count",
  "General.required": "All fields are required",
  "404.1": "Uh oh, Something went wrong.",
  "404.2": "It looks like you've run into an error. ",
  "404.3": "Please try again, or use the home button in the header to go back to your home page.",

  // Platform
  "Datatable.rowsPerPage": "Rows per Page: ",
  "Datatable.showEdit": "Show edit options",
  "Datatable.hideEdit": "Hide edit options",
  "Datatable.support": "Support Users",
  "Datatable.resetPW": "reset pw",
  "Datatable.resetConfirm": "Are you sure you want to reset",
  "Datatable.resetConfirm2": "'s password",
  "Datatable.levels": "Level Templates",
  "Header.platform": "Platform",
  "Header.network": "Network",
  "Header.profile": "My Profile",
  "Header.logout": "Logout",
  "Header.support": "Support Account",
  "Sidebar.title": "Admin Home",
  "Sidebar.title.home": "Home",
  "Sidebar.dashboard": "Dashboard",
  "Sidebar.admindashboard": "Admin Dashboard",
  "Sidebar.bvidashboard": "BVI Dashboard",
  "Sidebar.readingdashboard": "Dashboard",
  "Sidebar.students": "Students",
  "Sidebar.progress": "Progress",
  "Sidebar.staff": "Staff",
  "Sidebar.schools": "Schools",
  "Sidebar.databanks": "Databanks",
  "Sidebar.config": "Config",
  "Sidebar.profile": "Profile",
  "Sidebar.account": "Account",
  "Sidebar.accounts": "Accounts",
  "Sidebar.users": "Users",
  "Sidebar.guardians": "Guardians",
  "Sidebar.goals": "Goals",
  "Sidebar.books": "Books",
  "Sidebar.available.books": "Freely Available Books",
  "Sidebar.games": "Games",
  "Sidebar.help": "Help",
  "Sidebar.games.entertainment": "Entertainment Games",
  "Sidebar.backToStudents": "Back to Students",
  "Sidebar.backToStaff": "Back to Staff",
  "Sidebar.backTo": "Back to",
  "Sidebar.student's":"'s Students",
  "Login.button": "Log In",
  "Login.submit": "Submit",
  "Login.header": "Please enter your Bookshare login information.",
  "Login.username": "Username",
  "Login.password": "Password",
  "Login.confirmPassword": "Confirm Password",
  "Login.mustMatch": "Passwords must match",
  "Login.resetText": "Forgot Password?",
  "Login.resetLink": "Reset your password.",
  "Login.bookShareSignUpText": "Don't have a Bookshare account? ",
  "Login.bookShareSignUpLink": "Please click here to sign up!",
  "Dashboard.title": "Dashboard",
  "Dashboard.possessive": "'s Dashboard",
  "BuddyBooks.Phonemes": "Phonemes",
  "BuddyBooks.phoneme": "Phoneme",
  "BuddyBooks.averageScore": "Average Score",
  "BuddyBooks.ReadingTarget": "Daily Reading Time",
  "BuddyBooks.PhonemeSpecific": "Example Passages",
  "BuddyBooks.ReviewMode": "Review Mode",
  "BuddyBooks.firstTime": "First Attempt",
  "BuddyBooks.firstScore": "First Score",
  "BuddyBooks.secondTime": "Second Attempt",
  "BuddyBooks.secondScore": "Second Score",
  "BuddyBooks.text": "Passage",
  "BuddyBooks.score": "Accuracy Score",
  "BuddyBooks.date": "Date",
  "BuddyBooks.activeLogin": "Students with Logins",
  "BuddyBooks.activeBooks": "Students with Active Books",
  "BuddyBooks.pastWeek": "Students Who Have Read in the past Week",
  "BuddyBooks.averageReadingTime": "Average Reading Time of Students Who Have Read in the past Week",
  "BuddyBooks.title":"Title",
  "BuddyBooks.author":"Author",
  "BuddyBooks.firstRead":"First Read",
  "BuddyBooks.gradeLevel":"Grade",
  "BuddyBooks.recentBooks":"Recent Books",
  "StaffMembers.title": "Staff Dashboard",
  "StaffMembers.AddBtn": "Add Staff Member",
  "Students.DeletedStudents": "Deleted Students",
  "Students.DeletedStudents": "Deleted Students",
  "Students.title": "All Students",
  "Students.AddBtn": "Add Students",
  "Progress.Title": "Generated Data Report",
  "Progress.Student": "Student: ",
  "Progress.PercentToTargetLineTitle": "Percent to Target for All Active Skills",
  "Progress.PercentToTargetBarTitle": "Percent to Target Bar Chart",
  "Progress.Skill": "Skill Charts: ",
  "Databanks.title": "Databanks",
  "Databanks.subtitle": "On this page you will find a list of all of your goals, skills, and braille sheets.  You can either add your own goals, skills and braille sheets using our step-by-step creators, or you can import goals and skills from your groups.",
  "Databanks.goalDB": "Goal Databank",
  "Databanks.addGoalDB": "Add Goal",
  "Databanks.importGoal": "Import Goal",
  "Databanks.sampleGoal": "Show Sample Goals",
  "Databanks.skillDB": "Skill Databank",
  "Databanks.addSkillDB": "Add Skill",
  "Databanks.importSkill": "Import Skill",
  "Databanks.importBook": "Import Book",
  "Databanks.importBookShareBtn": "Don't see what you're looking for? Click here to Import from BookShare!",
  "Databanks.importBookShareHeader": "Import from BookShare",
  "Databanks.browseStory": "Find a Book",
  "Databanks.brailleSheets": "Braille Sheets",
  "Databanks.importSheet": "Import Braille Sheet",
  "Databanks.addBrailleSheets": "Add Braille Sheet",
  "Databanks.advanced": "Advanced Settings",
  "Chart.hoursPlayed.title": "Hours Played",
  "Chart.hoursPlayed.bar": "Progress by Student",
  "Chart.percentLine.title": "Percent to Target",
  "Chart.percentBar.title": "Percent to Target for Active Skills",
  "Chart.empty": "No student data to show...",
  "Chart.empty.noStudents": "Once your administrator has assigned students to your account, you will see their progress here",
  "Chart.allTime": "All Time",
  "Chart.days": "Days",
  "Goals.title": "Goals",
  "Goals.for": "Goals for",
  "Goals.add": "Add Goal",
  "Goals.allGoals": "All Goals",
  "Goal.Chart.empty": "Please select a skill to see score data...",
  "Skill.add": "Add skill to goal",
  "Skill.for": "Skills for",
  "Books.for": "Books for",
  "Profile.settings": "Profile Settings",
  "Profile.personal": "Personal Info",
  "Profile.login": "Should this user be able to log in?",
  "Profile.studentLogin": "Should this student be able to log in?",
  "Profile.helperText": "Student must have a User Name to log in",
  "Profile.helperText": "Student must have a User Name to log in",
  "Profile.StudentConfig": "Student Config",
  "Profile.backgroundMusicLevel": "Background Music Level",
  "Profile.curtainOpacity": "Curtain Opacity",
  "Profile.curtain": "Enable Curtain?",
  "Profile.addFreeTime": "Add Free Time",
  "Profile.changeFreeTime": "Change Free Time",
  "Profile.playTime": "Reward Time",
  "Profile.entertainmentTime": "Entertainment Time",
  "Profile.level": "Level till Reward",
  "Profile.levels": "Levels till Reward",
  "Profile.braille": "Braille Teacher",
  "Profile.AT": "Assistive Tech. Specialist",
  "Profile.OM": "Certified O&M",
  "Profile.TVI": "Certified TVI",
  "Profile.specialEd": "Special Ed Teacher",
  "Profile.publicSchool": "Public School District",
  "Profile.privateSchool": "Private School",
  "Profile.DOE": "State DOE",
  "Profile.NFP": "Not-for-Profit Agency",
  "Profile.FP": "For-Profit Agency",
  "Profile.self": "Self-Employed",
  "Support.createAccount": "Create Account",
  "Support.createUser": "Create Support User",
  "EditStaff.promoteDescription": "Administrators are users who can add other teachers and staff.  They gain access to the page you are currently on.  This action is not reversable",
  "EditStaff.promote": "Promote to Administrator",
  "EditStaff.cannotPromote": "This user belongs to multiple organizations and cannot be promoted to an administrator",
  "Wizard.Maps.questionsPerRound": "Questions Per Round",
  "Wizard.Maps.PinQuestion": "Pin",
  "Wizard.WordHunt.IntersectionQuestion": "Intersection",
  "Wizard.Maps.StreetQuestion": "Street",
  "Wizard.Maps.RegionQuestion": "Region",
  "Wizard.Maps.Type": "What Type of Questions Should Be Asked?",
  "Wizard.Maps.QuestionType": "Question Type",
  "Wizard.Maps.Shuffle": "Shuffle Questions?",
  "Wizard.Maps.Questions": "Questions",
  "Wizard.Maps.Question": "Question",
  "Wizard.Maps.Street": "Street",
  "Wizard.Maps.Intersection1": "First Street",
  "Wizard.Maps.Intersection2": "Second Street",
  "Wizard.Maps.Pin": "Pin",
  "Wizard.Maps.Region":"Region",
  "Wizard.Maps.title": "Map Explore",
  "Wizard.Maps.subtitle": "Map Explore is an educational tool for practicing mental mapping skills. Please provide an address in the bar below to set the center of your map. Then add markers to the map by pressing the add pin button. You can drag and name these pins to make your map more accessible or quiz the student to find certain locations. The student will track streets by running their finger over the map and pins you add. You can zoom in and out with the map but try not to zoom out too far as this makes it harder for the student to track streets and locations.",
  "Wizard.Maps.ModeType": "Game Mode",
  "Wizard.Maps.MapName": "Map Description (Announced at start of game)",
  "Wizard.Temple.title": "Temple Explorer",
  "Wizard.Temple.subtitle": "Temple Explorer is a game to help students practice both basic and advanced directionality concepts.  Students will practice 4 or 8 way directionality, and will be presented with relative, cardinal, or clock directions in up to 3 instructions at a time.",
  "Wizard.Temple.type": "How many doors will each room have",
  "Wizard.Temple.step": "How many instructions are given at a time",
  "Wizard.Temple.directionType": "What type of directions are given",
  "Wizard.title": "Creator",
  "Wizard.skillDB.title": "Skill Creator",
  "Wizard.restore.title": "Restore Students",
  "Wizard.restore.description": "You can restore or permanently delete students here.",
  "Wizard.skillDB": "Welcome our Skill Creator, this will walk you through the process of creating a new skill!",
  "Wizard.loading": "Please wait while the server handles your request.",
  "Wizard.skillDB.subtitle1": "Please enter a name, description and area for your new skill",
  "Wizard.skillDB.subtitle2": "Please select the game you wish to attach the skill to:",
  "Wizard.skillDB.levelSelect": "Please select the level you wish to use",
  "Wizard.skillDB.levelConfig": "Configure this Level",
  "Wizard.Support.title": "Account Creator",
  "Wizard.Support": "Welcome to the Account Creator! This will walk you through the process of creating a new support account.",
  "Wizard.Support.shortName": "Short Name",
  "Wizard.Support.studentCount": "Student Count",
  "Wizard.Support.parentAccount": "Parent Account Id",
  "Wizard.SupportUser.title": "Support User Creator",
  "Wizard.SupportUser": "Welcome to the Support User Creator! This will walk you through the process of creating a new support user.",
  "Wizard.Barnyard.direction": "Direction Type",
  "Wizard.Barnyard.prompt": "Always Prompt?",
  "Wizard.Barnyard.mistakes": "Number of Mistakes Before Prompting",
  "Wizard.Barnyard.animal": "Number of Animal Prompts at Start",
  "Wizard.AudioMemory.collection": "Sound Collection to Use",
  "Wizard.AudioMemory.animals": "Animals",
  "Wizard.AudioMemory.instruments": "Instruments",
  "Wizard.AudioMemory.vehicles": "Vehicles",
  "Wizard.AudioMemory.toys": "Toys",
  "Wizard.AudioMemory.indoors": "Indoors",
  "Wizard.AudioMemory.length": "Length of Game",
  "Wizard.AudioMemory.opponent": "Opponent Difficulty",
  "Wizard.AudioMemory.helperText": "Cheetah is easiest, Turtle is hardest",
  "Wizard.AudioMemory.matches": "Number of Matches on Screen",
  "Wizard.AudioMemory.error": "Must be a number between 1-8",
  "Wizard.AudioMemory.sound": "Sound to Sound",
  "Wizard.AudioMemory.tap": "Tap Control",
  "Wizard.Sounds.error": "Max number for words is 6",
  "Wizard.Sounds.numWords": "Number of Words",
  "Wizard.Sounds.unlimited": "Unlimited Boards?",
  "Wizard.Sounds.sounds": "Number of Sounds",
  "Wizard.Sounds.errorSound": "Max number for sounds is 6",
  "Wizard.Sounds.numBoards": "Number of Boards in One Round",
  "Wizard.Monster.title": "Monster Cafe",
  "Wizard.Monster.subtitle": "Monster Cafe is a game that helps students practice listening skills. Students will listen to and fulfill orders presented at varying speeds.  Students will also need to discern the order from background music and chatter in the café.",
  "Wizard.Monster.backgroundVolume" : "Background Volume",
  "Wizard.Monster.visible": " Show Orders",
  "Wizard.Monster.speed": "Speech Speed",
  "Wizard.Monster.MaxIngrediants": "Max Ingredients",
  "Wizard.Monster.MinPatience": "Minimum Patience (In Seconds)",
  "Wizard.Monster.MaxPatience": "Maximum Patience (In Seconds)",
  "Wizard.Gesture.title": "Speed Gesture",
  "Wizard.Gesture.subtitle": "Speed Gesture is a game where students are asked to perform gestures as they are announced.",
  "Wizard.Gesture.length": "Length of Round",
  "Wizard.Gesture.music": "Music Track",
  "Wizard.Gesture.japan": "Japanese",
  "Wizard.Gesture.random": "Random",
  "Wizard.Gesture.numRounds": "Number of Rounds",
  "Wizard.Gesture.startingCount": "Starting Gesture Count",
  "Wizard.Gesture.gestures": "Gestures you'd like to use",
  "Wizard.Simon.title": "Simon",
  "Wizard.Simon.subtitle": "Simon is a game that requires students to remember a list of gestures and perform them in order when prompted",
  "Wizard.BrailleQuiz.title": "Braille Quiz",
  "Wizard.BrailleQuiz.subtitle": "Braille Quiz is a game where students must answers quiz-style questions by double tapping the correct word on the braille sheet.  You can add your own questions or import questions.",
  "Wizard.BrailleQuiz.select": "Select a braille sheet to use for this skill",
  "Wizard.BrailleQuiz.empty": "No Braille Sheet Selected",
  "Wizard.BrailleQuiz.numQuestions": "Questions per Game",
  "Wizard.BrailleQuiz.error": "Questions per game must be less than total questions count!",
  "Wizard.BrailleQuiz.time": "Time per Question: (in seconds)",
  "Wizard.BrailleQuiz.shuffle": "Shuffle Questions?",
  "Wizard.BrailleQuiz.numOpponents": "Number of Opponents",
  "Wizard.BrailleQuiz.question": "Question",
  "Wizard.BrailleQuiz.answer": "Answer",
  "Wizard.BrailleQuiz.import": "Import a Question",
  "Wizard.BrailleQuiz.addQuestion": "Add a question",
  "Wizard.BrailleQuiz.importText": "Please use groups from your sheet below to search for questions",
  "Wizard.BrailleQuiz.emptyQuestions": "There are no questions for that group.",
  "Wizard.Scramble.title": "Braille Scramble",
  "Wizard.Scramble.subtitle": "Braille Scramble is a spelling and vocabulary game where students are presented with a number of letters and must use those letters to come up with as many words as possible.",
  "Wizard.Scramble.set": "Word Set",
  "Wizard.Scramble.number": "Number of Words Until Next Scramble",
  "Wizard.Scramble.seconds": "Seconds per Scramble",
  "Wizard.WordHunt.title": "Braille Hunt",
  "Wizard.WordHunt.subtitle": "Braille Hunt is a game where students must find and select words based on the questions you choose.",
  "Wizard.WordHunt.find": "The student should find",
  "Wizard.WordHunt.one": "one",
  "Wizard.WordHunt.all": "all",
  "Wizard.WordHunt.words": "words",
  "Wizard.WordHunt.word": "word",
  "Wizard.WordHunt.letters": "letters",
  "Wizard.WordHunt.letter": "letter",
  "Wizard.WordHunt.starting": "starting with",
  "Wizard.WordHunt.ending": "ending in",
  "Wizard.WordHunt.containing": "containing",
  "Wizard.WordHunt.need": "They will need",
  "Wizard.WordHunt.correct": "correct to move on",
  "Wizard.WordHunt.feedback": "How should feedback be given?",
  "Wizard.WordHunt.missed": "Read only missed answers",
  "Wizard.WordHunt.readAll": "Read all Words",
  "Wizard.WordHunt.readNum": "Read number of missed/correct",
  "Wizard.Braille.contractions": "Braille Sheets Contractions",
  "Wizard.Braille.selectContract": "Select the contractions that you would like to use",
  "Wizard.Braille.check": "Check All",
  "Wizard.Braille.uncheck": "Uncheck All",
  "Wizard.Braille.sheet": "Braille Sheet",
  "Wizard.Braille.import": "Import",
  "Wizard.Braille": "Welcome to the Braille Sheet Creator, this will walk you through the process of creating a new Braille Sheet.",
  "Wizard.Braille.page2": "On this page, please enter some information for the Braille Sheet you want to create and then select the games you wish to use with this Braille sheet.",
  "Wizard.Braille.text": "Please enter the text for your Braille Sheet",
  "Wizard.Braille.error": "The text is too long.",
  "Wizard.Braille.contract": "Include contractions?",
  "Wizard.Braille.upperCase": "Allow upper case letters?",
  "Wizard.Braille.games": "Games that use this Sheet",
  "Wizard.Goal.title": "Goal Creator",
  "Wizard.Goal": "Welcome to the Goal Creator! This will walk you through the process of creating a new Goal for a Student.",
  "Wizard.GoalDB.title": "Goal Creator",
  "Wizard.GoalDB": "Welcome to our Goal Databank Creator.  Fill out a name and description, then press submit to create a new goal.",
  "Wizard.Skill": "Add a Skill",
  "Wizard.Skill.select": "Please select a skill from the table below",
  "Wizard.Skill.different": "Select a Different Skill",
  "Wizard.GameField": "Game Field Map Wizard",
  "Wizard.Staff.invite": "Invite",
  "Wizard.Staff.inviteText": "Please enter the email to send the invite to",
  "Wizard.Staff.title": "Staff Member Wizard",
  "Wizard.Staff": "Welcome to the Staff Member Creator! This will walk you through the process of creating a new Staff Member.",
  "Wizard.Staff.sent": "Invitation Sent!",
  "Wizard.Staff.error": "There was an error sending the invite. Please try again.",
  "Wizard.Staff.select": "Please select a specialist from the table below",
  "Wizard.Staff.empty": "Don't see your specialist?",
  "Wizard.Staff.inviteLink": "Invite Them Here",
  "Wizard.Staff.role": "Enter user's Role",
  "Wizard.Staff.email": "Please enter the Staff Member's email",
  "Wizard.Staff.email2": "Please enter the Specialist's email",
  "Wizard.Staff.emailSent": "A message will be sent to the email given with instructions on how to set up a password for the account",
  "Wizard.Staff.confirm": "Make sure everything is correct before submitting!",
  "Wizard.Staff.specialist": "Looks like there is already a specialist in our system with that information! We have uploaded their account, and will attach the students you've listed to them",
  "Wizard.Staff.selectStudent": "Select the students you'd like to add",
  "Wizard.Staff.selectProduct":"Select the Products you'd like to add",
  "Wizard.Student.restore": "Restore Student",
  "Wizard.Student.title": "Student Wizard",
  "Wizard.Student": "Welcome to the Student Creator! This will walk you through the process of creating a new Student.",
  "Wizard.Student.Staff": "Select Staff For Student",
  "Wizard.Student.AddProduct.Student": "Add Products For Student",
  "Wizard.Student.AddProduct": "Add Products",
  "Wizard.Student.DeleteProduct.Student": "Delete Products For Student",
  "Wizard.Student.DeleteProduct": "Delete Products",
  "Wizard.Level": "Welcome to the Level Template Creator! This will walk you through the process of creating a new Level Template.",
  "Wizard.Level.select": "Please select the game you are creating a level for",
  "Wizard.Level.static": "Static",
  "Wizard.Level.prompt": "Prompt",
  "Wizard.Level.number": "Number",
  "Wizard.Asteroids.requiredAccuracyAngle": "Required Accuracy Angle",
  "Wizard.Asteroids.requiredAccuracyAngle.err": "Cannot exceed 180 degrees",
  "Wizard.Asteroids.spawnRadius": "Size of Asteroid Field",
  "Wizard.Asteroids.spawnRadius.err": "Must be a whole number between 8 and 20",
  "Wizard.Asteroids.spawnRadius.details": "The radius of the circle around the player where asteroids spawn",
  "Wizard.Asteroids.radiusRecArc": "We recommend a size of 10",
  "Wizard.Asteroids.radiusRecPass": "We recommend a size of 15-20",
  "Wizard.Asteroids.speed": "How fast should the Asteroids travel?",
  "Wizard.Asteroids.timeMinToMax": "Time between min and max",
  "Wizard.Asteroids.timeMinToMax.details": "The number of seconds it takes for the Asteroid to go between its min and maximum values",
  "Wizard.Asteroids.arcMovement": "Should the Asteroid sweep?",
  "Wizard.Asteroids.minMaxAngleArc": "How wide should the Asteroid sweep?",
  "Wizard.Asteroids.minMaxAngleArc.details": "The narrowest value will result in a stationary Asteroid.",
  "Wizard.Asteroids.minMaxAngleArc.err": "Must be a whole number between 0 and 180",
  "Wizard.Asteroids.leftRightLimited": "Would you like to control how Asteroids show up?",
  "Wizard.Asteroids.leftRightLimited.details": "If yes, the Asteroid location will be based on the player’s current facing direction. If no, they will appear randomly.",
  "Wizard.Asteroids.leftRatio": "Set the ratio of left/right appearances",
  "Wizard.Asteroids.angleOptions": "Options of appearance angles",
  "Wizard.Asteroids.angleOptions.details": "Please add angles separated by commas. Each angle should be between 0 and 180",
  "Wizard.Asteroids.asteroidTimeout": "How long before an Asteroid disappears",
  "Wizard.Asteroids.asteroidTimeout.details": "How long in seconds after appearing the Asteroid will disappear automatically",
  "Wizard.Asteroids.maxShotsPerAsteroid": "Max shots per Asteroid",
  "Wizard.Asteroids.maxShotsPerAsteroid.details": "Number of shots on a given Asteroid before it automatically disappears",
  "Wizard.Asteroids.gameOverType": "What Ends the Game?",
  "Wizard.Asteroids.gameOverType.timeout": "A set amount of time",
  "Wizard.Asteroids.gameOverType.limitedShots": "Total shots fired",
  "Wizard.Asteroids.gameOverType.limitedAsteroids": "The number of Asteroids seen",
  "Wizard.Asteroids.gameOverType.missed": "The number of Asteroids missed",
  "Wizard.Asteroids.gameOverCount.1": "Length of Game (in seconds)",
  "Wizard.Asteroids.gameOverCount.2": "Total Shots Fired",
  "Wizard.Asteroids.gameOverCount.3": "Total Asteroids Seen",
  "Wizard.Asteroids.gameOverCount.4": "Number of Asteroids Missed",
  "Wizard.Asteroids.gameOverCount.err": "Max shots per Asteroid cannot exceed Limited shots number",
  "Wizard.Account.billing": "Please enter your billing info below:",
  "Wizard.Game.add": "Add entertainment game",
  "Wizard.Hangman.title": "Code Breaker",
  "Wizard.Hangman.subtitle": "Code Breaker is a game where you guess braille letters/contractions in a secret phrase in order to crack the code",
  "Wizard.Hangman.numRounds": "Number of Rounds",
  "Wizard.Hangman.maxGuesses": "Number of incorrect guesses before game ends",
  "Wizard.Hangman.addPhrase": "Add a sentence",
  "Config.game": "Add Game Field",
  "Config.list": "Add List",
  "Level.title": "Level Template Wizard",
  "Level.Audio.timed": "Timed Game?",
  "Level.Audio.matches": "Number of Matches on Screen",
  "Level.Gesture.spawn": "Weighted Gesture Spawn",
  "Level.Gesture.difficulty": "Difficulty Progression",
  "Level.add": "Add Variables to a ",
  "Level.add2": "level",
  "Level.addBtn": "Add Level",
  "Level.testBtn": "Test Level",
  "Level.variables": "Variables",
  "Level.confirm": "Please confirm that all of the following is correct",
  "Share.group": "Which Group Do You Want to Share With?",
  "Config.title": "Configuration Variables",
  "Tutorial.deactivate": "Reset Tutorial",
  "MyGroups.title": "My Groups",
  "Groups.public": "Public",
  "Groups.private": "Private",
  "GroupDetail.startDate": "Start Date",
  "SocialGoals.title": "Trending Goals",
  "MemberList.title": "Members",
  "MemberList.invite": "Invite Members",
  "MemberList.admin": "Administrator",
  "MemberList.mod": "Moderator",
  "MemberList.removeAdmin": "Remove Admin Status",
  "MemberList.removeMod": "Remove Moderator Status",
  "MemberList.makeMod": "Make Moderator",
  "MemberList.makeAdmin": "Make Admin",
  "MemberList.leave": "Leave Group",
  "MemberList.remove": "Remove from Group",
  "AddPost.title": "Add a Post",
  "AddPost.addTags": "Add tags",
  "AddPost.submit": "Submit",
  "AddGroup.title": "Create New Group",
  "AddGroup.text": "Groups are great for sharing skills and staying in touch with groups of professionals just like you. Share photos and videos, have conversations, make goals and more.",
  "AddGroup.name": "Name Your Group",
  "AddGroup.description": "Give your group a Description",
  "AddGroup.invite": "Invite Members",
  "AddGroup.members": "Please enter member's name...",
  "AddGroup.privacy": "Select Privacy Level",
  "AddReply.comment": "Comment here...",
  "Announcements.title": "Announcements",
  "Unlike": "Unlike Goal",
  "Like": "Like Goal",
  "Shared": "Shared",
  "Group.image.title": "Upload Group Image",
  "Group.image.text": "Drag a photo to the box or use the button to change your group's profile picture.",
  "Group.image.change": "Change Image",
  "Group.startDate": "Start Date",
  "Group.created": "Created by",
  "GroupEdit.toggle": "Is this group private?",
  "GroupFeed.more": "See More Posts",
  "Profile.image.title": "Upload Profile Image",
  "Profile.image.text": "Drag a photo to the box or use the button to change your profile picture.",
  "Paddle.goalAdded": "Goal Added!",
  "Paddle.removeStudent": "Remove Student",
  "Paddle.error.first": "First name required",
  "Paddle.error.last": "Last name required",
  "Paddle.error.tag": "Username is required",
  "Paddle.error.unique": "Username must be unique",
  "Paddle.error.password": "Password is required",
  "Paddle.error.match": "Passwords must match",
  "Paddle.error.email":"Email is required",
  "Paddle.enroll": "Enroll Student",
  "Paddle.clear": "Clear Student Data",
  "Paddle.start": "Get Started",
  "Paddle.start.buddyBooks": "Get Started with BuddyBooks",
  "Paddle.checkout": "Checkout",
  "Paddle.email": "Subscription Notification Email",
  "Paddle.type": "Subscription Type",
  "Paddle.studentNum": "Number of Students",
  "Paddle.cost": "SUBSCRIPTION COST",
  "Paddle.studentCount.title": "Please enter the email that you would like to receive all subscription details and notifications to and enter the number of students that you would like to enroll:",
  "Paddle.terms": "Please read and accept the terms of use:",
  "Terms.decline": "I decline",
  "Terms.accept": "I accept the terms",
  "Terms.conditions": "ObjectiveEd does not store any of the information entered on this page other than the student’s name. This information is subject to your organization’s agreement with Bookshare.",
  "Bookshare.disability.text":"This form serves as proof of disability in place of sending signed documentation.",
  "purchase": "Proceed to Purchase",
  "SliderListManagement.Add": "Add Story",
  "SliderListManagement.Title": "Story List Management",
  "Wizard.SliderListManagement": "Story List Management Creator",
  "DataTable.SliderListManagement": "Story List Management ",
  "SliderSeriesManagement.Add": "Add Series",
  "SliderSeriesManagement.Title": "Story Series Management",
  "Wizard.SliderSeriesManagement": "Story Series Management Creator",
  "DataTable.SliderSeriesManagement": "Story Series Management ",
  "seriesStoryList.Add": "Add Story",
  "seriesStoryList.Title": "Story List",
  "seriesStoryList.AddList":"Add Series",
  "addStudent.grade":"Grade level required"
}