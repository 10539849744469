export const FETCH_ALL_STAFF_MEMBERS = 'FETCH_ALL_STAFF_MEMBERS';
export const GET_STAFF_MEMBER = 'GET_STAFF_MEMBER';
export const CREATE_STAFF_MEMBER = 'CREATE_STAFF_MEMBER';
export const PATCH_STAFF_MEMBER = 'PATCH_STAFF_MEMBER';
export const ADD_STAFF_STUDENT = 'ADD_STAFF_STUDENT';
export const DELETE_STAFF_MEMBER = 'DELETE_STAFF_MEMBER';
export const DELETE_STUDENT_FROM_STAFF = 'DELETE_STUDENT_FROM_STAFF';
export const GET_STAFF_PROFILE = 'GET_STAFF_PROFILE';
export const GET_STAFF_ROLES = 'GET_STAFF_ROLES';
export const GET_STAFF_DASHBOARD = 'GET_STAFF_DASHBOARD';
export const NO_STAFF_STUDENT_RECORD_FOUND = 'NO_STAFF_STUDENT_RECORD_FOUND';
export const RESET_STAFF_DASHBOARD = 'RESET_STAFF_DASHBOARD';
export const GET_STAFF_DASHBOARD_ACCURACY = 'GET_STAFF_DASHBOARD_ACCURACY';
export const GET_STAFF_DASHBOARD_READING_TIME = 'GET_STAFF_DASHBOARD_READING_TIME';