export default {
  goal: {
    padding: '10px 20px',
    marginBottom: 10,
    display: 'grid',
  },
  info: {
    padding: 15
  },
  avatar: {
    alignSelf: 'center'
  },
  joinBtn: {
    gridColumn: 3,
    alignSelf: 'center',
    justifySelf: 'center',
  },
  joined:{
    gridColumn: 3,
    alignSelf: 'center',
    justifySelf: 'center',
    color: 'green',
    width: 80,
    fontSize: 42,
  },
  liked: {
    color: '#0087b6'
  },
  footer: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '1fr auto'
  },
  details: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridColumnGap: '10px',
    gridColumn: 1,
    marginTop: 10,
    alignSelf: 'center',
    justifySelf: 'start',
    '& .pic': {
      justifySelf: 'center',
      alignSelf: 'center',
    },
    '& .text': {
      justifySelf: 'start',
      alignSelf: 'center',
    },
  },
  text: {
    display: 'grid',
    '& .text': {
      justifySelf: 'start',
      alignSelf: 'center',
      marginLeft: 20
    },
    '& .date': {
      justifySelf: 'start',
      alignSelf: 'center',
      marginLeft: 20
    },
  },
  comment: {
    display: 'grid',
    marginTop: 10,
    gridTemplateColumns: 'auto 1fr',
    '& .icon': {
      color: 'white',
      marginRight: 10,
      alignSelf: 'center',
      backgroundColor: '#45458d',
      borderRadius: '100%',
      padding: 10,
      width: 'auto'
    },
    alignItems: 'center'
  },
  games: {
    display: 'grid',
    marginTop: 10,
    gridTemplateColumns: 'auto 1fr',
    gridAutoFlow: 'column',
    '& .icon': {
      color: 'white',
      marginRight: 10,
      paddingRight: 10,
      alignSelf: 'center',
      backgroundColor: '#310073',
      borderRadius: '100%',
      padding: 10,
      width: 'auto'
    },
    '& .gameContainer': {
      display: 'grid',
      justifySelf: 'start',
      gridAutoFlow: 'column',
      alignSelf: 'center'
    },
    '& .game': {
      minWidth: 0,
      justifySelf: 'start'
    }
  },
  goalButtons: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridColumn: 2,
    justifySelf: 'end',
    marginRight: 15,
    '& .text': {
      alignSelf: 'center',
    },
    '& .icon': {
      minHeight: 0,
      alignSelf: 'center',
    }
  },
  highlight: {
    backgroundColor: '#eee',
  }
}