import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Quill from 'quill';
import {
  Paper,
  Typography,
  Button,
  withStyles,
} from '@material-ui/core';
import { SendRounded } from '@material-ui/icons';
import { createPost, searchProfiles, searchTags } from '../../redux/actions';
import baseStyle from '../../styles/addPost.js';
import 'quill-emoji/dist/quill-emoji.css';
import 'quill-emoji/dist/quill-emoji.js';
import 'quill-mention';

class AddPost extends Component {
  constructor(props){
    super(props);
    this.state = {
      post: '',
      tags: [],
      tag: false,
    };
  }

  componentDidMount() {
    // initialize our Quill instance

    const toolbarOptions = {
      container: [
        [{ 'header': [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'align': [] }],
        ['image','video','emoji'],
        ['clean'],
      ],
      handlers: {
        'emoji': () => {},
        },
    };

    const source = (searchTerm, renderList, mentionChar) => {
      if (mentionChar === '@') {
        this.props.searchProfiles(searchTerm)
          .then((profiles) => {
            renderList(profiles, searchTerm);
          })
      }
      if (mentionChar === '#'){
        this.props.searchTags(searchTerm)
          .then((tags) => {
            renderList(tags.map(tag => ({ name: tag })), searchTerm);
          })
      }
    };

    const quill = new Quill('#editor-container', {
      modules: {
        mention:{
          mentionDenotationChars: ["@", "#"],
          dataAttributes: ['name'],
          renderItem(item) {
            return `<div class="cql-list-item-inner">${item.name}</div>`
          },
          onSelect(item, insertItem){
            if (item.denotationChar === '#') {
              if (item.name[item.name.length-1] === '\n') {
                item.value = item.name.slice(1,-1);
              } else {
                item.value = item.name.slice(1);
              }
              insertItem(item);
            }
            else {
              item.value = item.name;
              insertItem(item);
            }
          },
          defaultMenuOrientation: 'bottom',
          allowedChars: /[a-zA-Z0-9_]/,
          source: source
        },
        toolbar: toolbarOptions,
        keyboard: {
          bindings: {
            submit: {
              key: 13,
              empty: false,
              shortKey: true,
              handler: () => {
                this.createPost()
              }
            }
          },
        },
        "emoji-toolbar": true,
        "emoji-textarea": true,
        "emoji-shortname": true,
      },
      placeholder: 'Share something special #ObjectiveEd',
      theme: 'snow'
    });
    quill.on('text-change', (delta, old) => {
      const text = quill.getText(0);
      const val = quill.root.innerHTML;
      if (delta.ops[1] && delta.ops[1].insert === '#'){
        quill.formatText(delta.ops[0].retain, 1, { 'bold': true, 'color': '#1DA1F2' });
        this.setState({ tag: true })
      }
      if (delta.ops[0].insert === '#'){
        quill.formatText(0, 1, { 'bold': true, 'color': '#1DA1F2' });
        this.setState({ tag: true })
      }
      if (delta.ops[1] && delta.ops[1].insert === ' ' && this.state.tag === true){
        quill.removeFormat(delta.ops[0].retain, 1);
        this.setState({ tag: false })
      }
      this.handlePostChange(val, old, text)
    });
    this.setState({ quill })
  }

  handlePostChange = (post, delta, text) => {
    const words = text.split(' ');
    const tags = words.filter(word => word[0] === '#');
    this.setState({ post, tags })
  };

  handleChange = (ev) => {
    this.setState({ [ev.target.name]: ev.target.value })
  };

  createPost = () => {
    const post = {
      likeNum: 0,
      starNum: 0,
      replies: [],
      text: this.state.post,
      tags: this.state.tags
    };
    this.props.createPost(this.props.group.key, {...post})
      .then(_post => this.props.add(_post));
    // Resets the quill instance and local state
    this.state.quill.setText('');
    this.setState({ post:'', tags: []})
  };

  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.container}>
        <Typography variant='h6' className={classes.title}>
          <FormattedMessage id='AddPost.title'/>:
        </Typography>
        <div id='editor-container'/>
        <div className={classes.postBox}>
          <Button disabled={!this.state.post.length} onClick={this.createPost} className={classes.submit} variant='contained'>
            <SendRounded /> &nbsp; <FormattedMessage id='AddPost.submit'/>
          </Button>
        </div>
      </Paper>
    );
  }
}

const mapState = ({ social: { profile, profiles, tags }}) => {
  return {
    profile,
    profiles,
    tags
  }
};

const mapDispatch = (dispatch) => {
  return {
    createPost: (groupId, post) => dispatch(createPost(groupId, post)),
    searchProfiles: (searchVal) => dispatch(searchProfiles(searchVal)),
    searchTags: (searchVal) => dispatch(searchTags(searchVal))
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(AddPost));