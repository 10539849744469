import React, {Component} from 'react';
import {connect} from 'react-redux';
import DataTable from '../Shared/DataTable';
import {FormattedMessage} from 'react-intl';
import {Button, Typography, IconButton, FormGroup, TextField, MenuItem} from '@material-ui/core';


class SpeechData extends Component {

    state = {
        category: ""
    };

    handleChange = (ev) => {
        const value = ev.target.type === 'number' ? ev.target.value > 0 ? ev.target.value * 1 : '' : ev.target.value;
        this.setState({[ev.target.name]: value})
    };

    submitTraining = () => {
        console.log(this.state.category);
    }

    render() {
        const rows = [
            {id: 'text', label: 'Text', searchable: true},
            {id: 'state', label: 'Status'},
            {id: 'category', label: 'Category'}
        ];

        return (
            <div>
            <DataTable
                type={"speech-data"}
                editable={false}
                deletable={false}
                clickable={false}
                noActions={true}
                history={this.props.history}
                rows={rows}
                rowsPerPage={[5, 10, 15]}
                orderBy={'name'}
                data={this.props.studentSpeechData}
                studentId={this.props.id}
                numCols={2}
                title={'Speech Data'}
            />
            </div>
        )
    }
}

export default connect(null)(SpeechData);