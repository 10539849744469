import { CREATE_GAME_FIELD_MAP, FETCH_ALL_GAME_FIELD_MAPS, RESET } from '../../constants';

export default function (state = [], action) {
  switch(action.type){
    case FETCH_ALL_GAME_FIELD_MAPS:
      return action.gameFieldMaps;
    case CREATE_GAME_FIELD_MAP:
      return [...state, action.gameFieldMap];
    case RESET:
      return [];
    default:
      return state;
  }
};