export default {
  container: {
    color: '#0a0a0a',
  },
  title: {
    backgroundColor: '#383871',
    marginBottom: '15px',
  },
  titleText: {
    float: 'left',
    color: '#fff'
  },
  description: {
    marginBottom: 20
  },
  divider: {
    marginBottom: 20
  },
  exitIcon: {
    float: 'right',
    color: '#fff',
  },
  select: {
    marginBottom: 20
  },
  root: {
    marginTop: '20px',
    color: '#310073',
  },
  questions:{
    marginTop: 20
  },
  question: {
    border: '1px solid black',
    borderRadius: 5,
    paddingRight: 0,
    marginBottom: 10,
    display: 'grid',
    gridTemplateColumns: '7fr 1fr',
    gridRowGap: '5px'
  },
  questionLine: {
    gridRow: 1,
    marginLeft: 10,
    marginTop: 10,
    display: 'grid',
    gridTemplateColumns: '2fr 6fr',
  },
  answerLine: {
    gridRow: 2,
    marginLeft: 10,
    marginBottom: 10,
    display: 'grid',
    gridTemplateColumns: '2fr 6fr',
  },
  brailleLine: {
    display: 'grid',
    gridTemplateColumns: '3fr 5fr',
    marginBottom: 10
  },
  delete: {
    alignSelf: 'start',
    justifySelf: 'end',
    gridRow: 1
  },
  lineText: {
    color: '#310073',
    alignSelf: 'center',
    marginRight: 10
  },
  descriptionText: {
    gridColumn: 'span 2',
    marginBottom: 15
  },
  addButton: {
    textAlign: 'center',
    margin: '0 auto'
  },
  buttons: {
    justifySelf: 'end',
    marginRight: '20px',
    marginTop: '40px',
  },
  questionBtns: {
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'center',
    gridColumnGap: '20px',
    marginTop: 20
  },
  grid: {
    width: '50%',
    margin: '0 auto',
    padding: 15
  }
}