import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Divider, Typography, Paper, Button, withStyles } from '@material-ui/core';
import AddGroup from '../components/AddGroup';
import GroupList from '../components/GroupList';
import { fetchMyGroups, myProfile } from '../../redux/actions';
import baseStyle from '../../styles/groups';
import { Link } from 'react-router-dom';

const CollisionLink = React.forwardRef((props, ref) => (
  <Link innerRef={ref} to="/groups" {...props} />
));

class MyGroups extends Component {
  state = {
    open: false
  };

  componentDidMount() {
    const { id } = this.props;
    id && this.props.fetchMyGroups(id);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (!this.props.profile.key) {
      this.props.fetchMyGroups(nextProps.profile.key)
    }
  }

  toggleWizard = () => {
    this.setState(({ open }) => ({ open: !open }))
  };

  render() {
    const { classes, myGroups, profile } = this.props;
    return (
      <Paper>
        <Typography
          align='center'
          variant='h6'
          className={classes.title}
        >
          <FormattedMessage id={'MyGroups.title'}/>
        </Typography>
        <Divider className={classes.divider}/>
        <GroupList groups={myGroups} id={profile.id} history={this.props.history}/>
        <AddGroup
          history={this.props.history}
          close={this.toggleWizard}
          open={this.state.open}
        />
        <div className={classes.buttons}>
          <Button
            className={classes.button}
            color='primary'
            variant='contained'
            component={CollisionLink}
          >
            Find A Group To Join
          </Button>
          <Button
            className={classes.button}
            onClick={this.toggleWizard}
          >
            Create New Group
          </Button>
        </div>
      </Paper>
    );
  }
}

const mapState = ({ social: { myGroups, profile }}) => {
  return {
    myGroups,
    profile
  }
};

const mapDispatch = (dispatch) => {
  return {
    fetchMyGroups: (personId) => dispatch(fetchMyGroups(personId)),
    myProfile: () => dispatch(myProfile())
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(MyGroups));