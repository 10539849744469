import { FETCH_GROUP_GOALS, FETCH_MY_GOALS } from '../../constants';

export const goals = (state = [], action) => {
  switch (action.type){
    case FETCH_GROUP_GOALS:
      return action.goals;
    default:
      return state;
  }
};

export const myGoals = (state = [], action) => {
  switch (action.type){
    case FETCH_MY_GOALS:
      return action.goals;
    default:
      return state;
  }
};