import React, { Component } from 'react';
import { connect } from 'react-redux';
import Quill from 'quill';
import {
  FormGroup,
  TextField,
  MenuItem,
  withStyles,
  Icon,
} from '@material-ui/core';
import { fetchMyGroups, shareGoal, shareBrailleSheet, searchProfiles, searchTags } from '../../../redux/actions';
import baseStyle from '../../../styles/shareButton';
import { FormattedMessage } from 'react-intl';

class ShareGoal extends Component {

  state = {
    open: false,
    comment: '',
    editor: {}
  };

  quillRef = React.createRef();

  componentDidMount() {
    if (this.props.profile) {
      this.props.fetchMyGroups(this.props.profile.key);
    }

    const source = (searchTerm, renderList, mentionChar) => {
      if (mentionChar === '#'){
        this.props.searchTags(searchTerm)
          .then((tags) => {
            renderList(tags.map(tag => ({ name: tag })), searchTerm);
          })
      }
    };

    const el = this.quillRef.current;
    const replyQuill = new Quill(el, {
      placeholder: 'Add a comment here! #ObjectiveEd',
      modules: {
        mention:{
          mentionDenotationChars: ["#"],
          dataAttributes: ['name'],
          renderItem(item) {
            return `<div class="cql-list-item-inner">${item.name}</div>`
          },
          onSelect(item, insertItem){
            if (item.denotationChar === '#') {
              if (item.name[item.name.length-1] === '\n') {
                item.value = item.name.slice(1,-1);
              } else {
                item.value = item.name.slice(1);
              }
              insertItem(item);
            }
            else {
              item.value = item.name;
              insertItem(item);
            }
          },
          defaultMenuOrientation: 'top',
          allowedChars: /[a-zA-Z0-9_\s]/,
          source: source
        },
      }
    });
    if(replyQuill) {
      replyQuill.on('text-change', (delta, old) => {
        const text = replyQuill.getText(0);
        const val = replyQuill.root.innerHTML;
        if (delta.ops[1] && delta.ops[1].insert === '#'){
          replyQuill.formatText(delta.ops[0].retain, 1, { 'bold': true, 'color': '#1DA1F2' });
          this.setState({ tag: true })
        }
        if (delta.ops[0].insert === '#'){
          replyQuill.formatText(0, 1, { 'bold': true, 'color': '#1DA1F2' });
          this.setState({ tag: true })
        }
        if (delta.ops[1] && delta.ops[1].insert === ' ' && this.state.tag === true){
          replyQuill.removeFormat(delta.ops[0].retain, 1);
          this.setState({ tag: false })
        }
        this.props.handlePostChange(val, old, text)
      });
    }
    this.setState({ replyQuill })
  }

  render() {
    const { classes } = this.props;
    return (
      <FormGroup classes={{ root: classes.root }}>
        <TextField
          disabled
          variant='outlined'
          value={this.props.name}
          classes={{ root: classes.line }}
          id='name'
          label={<FormattedMessage id='General.name'/>}
          type='text'
          name='name'
          onChange={this.props.handleChange}
        />
        <TextField
          disabled
          multiline
          variant='outlined'
          classes={{ root: classes.line }}
          id='description'
          label={<FormattedMessage id='General.description'/>}
          type='text'
          name='description'
          rows={2}
          value={this.props.description}
          onChange={this.props.handleChange}
        />
        <TextField
          variant='outlined'
          select
          id='group-key'
          name='groupKey'
          label={<FormattedMessage id='Share.group'/>}
          classes={{ root: classes.line }}
          value={this.props.groupKey}
          onChange={this.props.handleChange}
        >
          {
            this.props.myGroups.filter(group=>group.role).map(group => (
            <MenuItem key={group.key} value={group.key}>
            {group.name}
            </MenuItem>
            ))
          }
        </TextField>
        <div className={classes.comment}>
          <Icon className='far fa-comments icon'/>
          <div ref={this.quillRef} className={classes.quill} id={`#editor${this.props.id}`} aria-label={"comment"}/>
        </div>
      </FormGroup>
    );
  }
}

const mapState = ({ social: { myGroups, profile, profiles, tags }}) => {
  return {
    myGroups,
    profile,
    profiles,
    tags
  }
};

const mapDispatch = (dispatch) => {
  return {
    fetchMyGroups: (profileKey) => dispatch(fetchMyGroups(profileKey)),
    shareGoal: (goal, comment, group) => dispatch(shareGoal(goal, comment, group)),
    shareBrailleSheet: (sheet, comment, group) => dispatch(shareBrailleSheet(sheet, comment, group)),
    searchProfiles: (searchVal) => dispatch(searchProfiles(searchVal)),
    searchTags: (searchVal) => dispatch(searchTags(searchVal))
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(ShareGoal));