import React from "react";
import { connect } from "react-redux";
import { Component } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import DataTable from "../../components/Shared/DataTable";
import { Close } from "@material-ui/icons";
import StoryDataList from "./StoryDataList";
import { addSeriesToStoryList, addStoryToSeries, addStoryToStoryList } from "../../../redux/actions";
import SliderSeriesManagement from "./SliderSeriesManagement";

const title= (
  <FormattedMessage id="seriesStoryList.Title" />
)

const rows = [
  { id: 'titleImageSrc', label: <FormattedMessage id="General.image" />, searchable: true },
  { id: 'name', label: <FormattedMessage id="General.name" />, searchable: true },
  { id: 'description', label: <FormattedMessage id="General.description" />, searchable: true },
  { id: 'status', label: <FormattedMessage id="General.status" />, searchable: true },
  { id: 'source', label: <FormattedMessage id="General.source" />, searchable: true },

  { id: 'author', label: <FormattedMessage id="General.author" />, searchable: true },
  { id: 'isbn', label: <FormattedMessage id="General.isbn" />, searchable: true },
  { id: 'gradeLevel', label: <FormattedMessage id="General.gradeLevel" />, searchable: true },
];

const style={
  title:{
    backgroundColor: '#383871'
  },
  text:{
    float: 'left', 
    color: '#fff'
  },
  exitIcon:{
    float: 'right', 
    color: '#fff'
  }
}

class SeriesStoryList extends Component {

  state={
    showStoryModal:false,
    showSeriesModal: false
  }

handleAddStory=()=>{
  this.setState({showStoryModal:true})
}

closeModal=()=>{
  this.setState({showStoryModal:false})
}
addBookToSeries=(data)=>{
  if(this.props.seriesId !==""){
    if(this.props.type === "storyListManagement"){
      this.props.addStoryToStoryList(data,this.props.seriesId)
    }
    else{
      this.props.addStoryToSeries(data,this.props.seriesId)
    }
  }
}

openSeriesModal = () => {
  this.setState({showSeriesModal:true})
}

closeSeriesModal=()=>{
  this.setState({showSeriesModal:false})
}

addSeriesToList=(data)=>{
  const obj={
    name: data.name,
    description: data.description,
    id: data.id,
    order: data.order,
    titleImageSrc: data.titleImageSrc,
    type: data.type
  }
  this.props.addSeriesToStoryList(this.props.seriesId,obj)
}
  render() {

    return (
      <>
        <Dialog maxWidth="xl" open={this.props.open} onClose={this.props.closeModal}>
          <DialogTitle style={style.title}>
              <Typography variant="h4" style={style.text}>
              {this.props.seriesName}
              </Typography>
              <IconButton
                   onClick={this.props.closeModal}
                   aria-label="close dialog"
                   style={style.exitIcon}
                   >
                  <Close/>
                </IconButton> 
          </DialogTitle>
          <DialogContent>
            <DataTable
            type={'seriesStoryList'}
            rows={rows}
            rowsPerPage={[5, 10, 15]}
            orderBy={''}
            data={this.props.storyListData}
            title={title}
            noActions
            />
          </DialogContent>
          <DialogActions>
            {this.props.type ==="storyListManagement" &&
              <Button 
              variant="contained"
              style={{backgroundColor:'#0276aa',color:'white'}}
              onClick={this.openSeriesModal}>
            <FormattedMessage id="seriesStoryList.AddList"/>
          </Button>
            }
            <Button 
                variant="contained"
                style={{backgroundColor:'#0276aa',color:'white'}}
                onClick={this.handleAddStory}>
              <FormattedMessage id="seriesStoryList.Add"/>
            </Button>
          </DialogActions>
        </Dialog>
        {
          this.state.showStoryModal ? 
          <Dialog maxWidth="lg" open={this.state.showStoryModal} onClose={this.closeModal}>
            <DialogTitle style={style.title} >
            <Typography  variant='h4' style={style.text}>  Add Story
                        </Typography>
                        <IconButton
                            onClick={this.closeModal}
                            aria-label="close dialog"
                            style={style.exitIcon}>
                            <Close />
                        </IconButton>
            </DialogTitle>
            <DialogContent>
              <StoryDataList type="addStory" addBookToSeries={this.addBookToSeries} closeModal={this.closeModal}/>
            </DialogContent>
          </Dialog> 
          : ""
        }
        {
          this.state.showSeriesModal && 
          <Dialog maxWidth="lg" open={this.state.showSeriesModal} onClose={this.closeSeriesModal}>
            <DialogTitle style={{backgroundColor: '#383871'}} >
            <Typography  variant='h4' style={{ float: 'left', color: '#fff'}}>  Add Series
                        </Typography>
                        <IconButton
                            onClick={this.closeSeriesModal}
                            aria-label="close dialog"
                            style={{float: 'right', color: '#fff'}}>
                            <Close />
                        </IconButton>
            </DialogTitle>
            <DialogContent>
              <SliderSeriesManagement type="storyList" handleClick={this.addSeriesToList} />
            </DialogContent>
          </Dialog>
        }
      </>
    )
  }
}

const mapState = ({ platform: { sliderSeries, sliderList } }) => {
  return {
    sliderSeries,
    sliderList
  }
};

const mapDispatch = (dispatch) => {
  return {
    addStoryToSeries: (data,seriesId) => dispatch(addStoryToSeries(data,seriesId)),
    addStoryToStoryList: (data,listId) => dispatch(addStoryToStoryList(data,listId)),
    addSeriesToStoryList: (listId,list) => dispatch(addSeriesToStoryList(listId,list))
  }
};

export default connect(mapState, mapDispatch)(SeriesStoryList);