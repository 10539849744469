import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadCSS } from 'fg-loadcss';
import { BrowserRouter as Router, Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { getStaffUser, logout } from './redux/actions';
import './styles/app.css';
import axios from 'axios';

import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import fr from 'react-intl/locale-data/fr';

// Auth
import Password from './Platform/components/Auth/Password';
import ResetPassword from './Platform/components/Auth/ResetPassword';
import Login from './Platform/components/Auth/Login';
import RegistrationBase from './Platform/components/Wizards/Registration/RegistrationBase';
import CourseRegistration from './Platform/components/Wizards/Registration/CourseRegistration';
// import GuardianRegistration from './Platform/components/Auth/GuardianRegistrationBase';

// Shared
import Header from './Platform/containers/Shared/Header';
import Sidebar from './Platform/containers/Shared/Sidebar';
import SecondarySidebar from './Platform/containers/Shared/SecondarySidebar';
import Progress from './Platform/containers/Shared/Progress';
import ErrorBoundary from './Platform/components/Shared/ErrorBoundary';
import RouteChange from './Platform/components/Shared/PageViewCount';
import BookSharePostLogin from './Platform/components/Auth/BookSharePostLogin';

// Dashboards
// import AdminDashboard from './Platform/containers/Dashboards/AdminDashboard';
import StudentDashboard from './Platform/containers/Dashboards/StudentDashboard';
import StaffDashboard from './Platform/containers/Dashboards/StaffDashboard';
import TeacherDashboard from './Platform/containers/Dashboards/TeacherDashboard'
// import GuardianDashboard from './Platform/containers/Dashboards/GuardianDashboard';

// Admin
import Staff from './Platform/containers/Admin/Staff';
import Students from './Platform/containers/Admin/Students';
import Config from './Platform/containers/Admin/Config';
import GoalDatabank from './Platform/containers/Shared/GoalDatabank';
import AdminDashboard from './Platform/containers/Admin/AdminDashboard';

// Staff
import StaffMemberStudents from './Platform/components/Staff/StaffMemberStudents';
import StaffProfile from './Platform/containers/StaffMember/StaffProfile';
import StaffSkillDatabanks from './Platform/containers/StaffMember/StaffDatabanks';

// Student
import Goals from './Platform/containers/Student/Goals';
import Books from './Platform/containers/Student/Books';
import Goal from './Platform/containers/Student/Goal';
import Games from './Platform/containers/Student/Games';
import ProgressReport from './Platform/containers/Student/ProgressReport';
import StudentProfile from './Platform/containers/Student/StudentProfile';
import BuddyBooks from './Platform/containers/Student/BuddyBooks';
import BuddyBooksDashboard from './Platform/containers/Dashboards/BuddyBooksDashboard';

// Support
import Support from './Platform/containers/Support/Support';
import SupportHeader from './Platform/containers/Support/SupportHeader';
import SupportAccounts from './Platform/containers/Support/SupportAccounts';

// Social
import HomePage from './Social/containers/HomePage';
import SocialHeader from './Social/containers/SocialHeader';
import Group from './Social/containers/Group';
import PublicGroups from './Social/containers/PublicGroups';
import HelpConfig from './Platform/containers/Admin/HelpConfig';
import ChatBaseButton from './Platform/components/Help/ChatBaseButton';
import { AppBar } from '@material-ui/core';
import SupportDashboard from './Platform/containers/Dashboards/SupportDashboard';
import ReadingProgressReport from './Platform/containers/Student/ReadingProgressReport';

// make sure that Material UI uses the newest Typography elements
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
window.moment = require('moment-timezone');
addLocaleData([...en, ...fr, ...es]);

class App extends Component {

  componentDidMount() {
    this.props.getUser();
    loadCSS(
      'https://use.fontawesome.com/releases/v5.8.1/css/all.css',
      document.querySelector('#insertion-point-jss'),
    );
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.user.id !== nextProps.user.id) {
      this.props.getUser();
    }
  }

  render() {
    return (
      <Router onChange={() => console.log('changed')}>
        <ErrorBoundary history={this.props.history}>
          <RouteChange user={this.props.user} />
          <Switch>
            <Redirect path='/' exact to='/login' />
            <Route path='/login' component={Login} />
            <Route path='/forgot-password'
                   render={(props) => <ResetPassword props={props} />}/>
            <Route path='/password-reset'
                   render={({location, history}) => <Password location={location} history={history} />}/>
            <Route path='/admin' render={({match: {url}}) => (
              <div id='container'>
                <Route path='*' render={({history, location}) => <Header location={location} history={history}/>}/>
                <Route path='*' component={Sidebar}/>
                {/*<Route path={`${url}/home`} component={AdminDashboard}/>*/}
                <Route path={`${url}/home`} component={Students}/>
                <Route path={`${url}/students`} component={Students}/>
                <Route path={`${url}/staff`} exact component={Staff}/>
                <Route path={`${url}/goals/:id`}
                       render={({match, location, history}) => <GoalDatabank location={location} id={match.params.id} history={history} />}/>
                <Route path={`${url}/config`} render={({location, history}) => <Config location={location} history={history} />}/>
                <Route path={`${url}/help-config`} render={({location, history}) => <HelpConfig location={location} history={history} />}/>
                <Route path={`${url}/dashboard`} component={AdminDashboard} />
              </div>
            )}/>
            <Route path='/staff' render={({match: {url}}) => (
              <div id='container'>
                <Route path='*' component={Header}/>
                <Route path='*' component={Sidebar}/>
                <Route path={`${url}/:id`} exact
                       render={({match, history}) => 
                       this.props.user.products && this.props.user.products.includes("Reading") ?
                       <TeacherDashboard history={history} staffId={match.params.id} id={match.params.id} />:<StaffDashboard history={history} id={match.params.id}/>}/>
                <Route path={`${url}/:id/students`}
                       render={({match, history}) => <StaffMemberStudents id={match.params.id} history={history}/>}/>
                <Route path={`${url}/:id/profile`}
                       render={({match, history}) => <StaffProfile history={history} id={match.params.id}/>}/>
                <Route path={`${url}/:id/databanks`}
                       render={({match, history}) => <StaffSkillDatabanks history={history} id={match.params.id}/>}/>
                <Route path={`${url}/:id/goals/:goalId`}
                       render={({match, history}) => <GoalDatabank staffId={match.params.id} id={match.params.goalId} history={history} />}/>        
                <Route path={`${url}/:id/dashboard`}
                       render={({match, history}) => <TeacherDashboard staffId={match.params.id} id={match.params.goalId} history={history} />}/>
              <Route path={`${url}/:id/bvidashboard`}
                       render={({match, history}) => <StaffDashboard id={match.params.id} history={history} />}/>  
              </div>
            )}/>
            <Route path='/students' render={({match: {url}}) => (
              <div id='container'>
                <Route path='*' component={Header}/>
                <Route path='*' component={Sidebar}/>
                <Route path={`${url}/:id`} exact
                       render={({match, history}) => <StudentDashboard history={history} id={match.params.id}/>}/>
                <Route path={`${url}/:id/progress`}
                       render={({match, history}) => <ProgressReport history={history} id={match.params.id}/>}/>
                <Route path={`${url}/:id/goals`} exact
                       render={({match, history}) => <Goals history={history} id={match.params.id}/>}/>
                <Route path={`${url}/:id/goals/:goalId`}
                       render={({match, history}) => <Goal history={history} id={match.params.goalId} studentId={match.params.id}/>}/>
                <Route path={`${url}/:id/goals/:goalId`}
                       render={({match, history}) => <SecondarySidebar history={history} studentId={match.params.id} id={match.params.goalId}/>}/>
                <Route path={`${url}/:id/games`} exact
                       render={({match, history}) => <Games history={history} id={match.params.id}/>}/>
                <Route path={`${url}/:id/books`} exact
                       render={({match, history}) => <Books history={history} id={match.params.id}/>}/>
                  <Route path={`${url}/:id/dashboard`} exact
                         render={({match, history}) => <BuddyBooksDashboard history={history} id={match.params.id}/>}/>
                <Route path={`${url}/:id/profile`}
                       render={({match, history}) => <StudentProfile history={history} id={match.params.id}/>}/>
                  <Route path={`${url}/:id/buddy-books`}
                         render={({match, history}) => <BuddyBooks history={history} id={match.params.id}/>}/>
              </div>
            )}/>
              <Route path='/progress' render={({match: {url}}) => (
                  <div>
                      <Route path={`${url}/:id`}
                             render={({match, history}) => <ProgressReport history={history} id={match.params.id}/>}/>
                  </div>
              )}/>
            <Route path='/reading/progress' render={({ match: { url } }) => (
              <div>
                <Route path={`${url}/:id`}
                  render={({ match, history }) => <ReadingProgressReport history={history} id={match.params.id} />} />
              </div>
            )} />
              <Route path='/support' render={({match: {url}}) => (
              <div id='container'>
                <Route path='*' component={SupportHeader}/>
                <Route path='*' component={Sidebar}/>
                <Route path={`${url}`} exact render={({history}) => <Support history={history} />}/>
                <Route path={`${url}/accounts`} render={({history}) => <SupportAccounts history={history} />}/>
                <Route path={`${url}/metrics`} render={({history}) => <SupportDashboard user={this.props.user} history={history} />}/>
              </div>
            )}/>
            <Route path='/home' render={({match: {url}}) => (
              <div id='socialContainer'>
                <Route path='*' component={SocialHeader}/>
                <Route path={url}
                       render={({match, history}) => <HomePage history={history} id={match.params.id}/>} />
              </div>
            )}/> 
            <Route path='/groups' render={({match: {url}}) => (
              <div id='socialContainer'>
                <Route path='*' component={SocialHeader}/>
                <Route path={url} exact component={PublicGroups}/>
                <Route path={`${url}/:id`}
                       render={({match, history}) => <Group history={history} id={match.params.id}/>} />
              </div>
            )}/>
              <Route path='/bookshare' render={({match: {url}}) => (
                  <div id='booksharelogin'>
                      <Route path={`${url}/login`} exact
                             render={({location, history}) => <BookSharePostLogin location={location} history={history}/>}/>
                  </div>
              )}/>
            <Route path='/course-registration' render={({match: {url}}) => (
                <div>
                    <Route path={`${url}/:id`} exact
                           render={({match, history}) => <CourseRegistration history={history} id={match.params.id}/>}/>
                </div>
                )}/>
            <Route path='/registration' render={({match: {url}}) => (
              <>
                <Route exact path={`${url}`}
                  render={({history}) => (
                   <RegistrationBase
                     history={history}
                   />
                )}/>
                <Route exact path={`${url}/purchase`}
                 render={({history}) => (
                   <RegistrationBase
                     history={history}
                   />
                )}/>
                <Route path={`${url}/specialists`}
                  render={({history}) => (
                  <RegistrationBase
                    history={history}
                    specialist
                    id={history.location.search.split('=')[1]}
                  />
                )}/>
              </>
            )}/>
            <Redirect path='/sign-up' to='/registration'/>
            <Redirect path='*' to='/login' error={false}/>
          </Switch>
        </ErrorBoundary>
        {
          (this.props.account.id === '0' || this.props.messages.length >= 1) &&
          <ChatBaseButton messages={this.props.messages} support={this.props.account.id === '0'} show={!!this.props.messages.length}/>
        }
      </Router>
    );
  }
}

const mapState = ({ shared: { account, user, messages }}) => {
  return {
    account,
    messages,
    user
  }
};

const mapDispatch = (dispatch) => {
  return {
    logout: (history, error) => dispatch(logout(history, error)),
    getUser: () => dispatch(getStaffUser()),
  }
};


export default connect(mapState, mapDispatch)(App);
