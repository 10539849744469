import React, { Component } from 'react';
import {
  Typography,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Divider,
  Button,
  TextField,
  Switch,
  Tooltip,
  Dialog, DialogContent, DialogTitle, DialogActions, Checkbox, label, List, ListItem, Snackbar, FormControlLabel
} from '@material-ui/core';
import { Close, HelpOutline } from '@material-ui/icons';
import CancelIcon from '@material-ui/icons/Cancel';

import { connect } from 'react-redux';
import { checkForSuspension, fetchBuddyBookSubscription, fetchSubscriptionOptions, getAccount, getRolesForStaff, getStudentsForStaff, cancelSubscription, updateLicenseAmount } from '../../../redux/actions';
import { Mail, AccountCircle, Edit, Restore } from '@material-ui/icons';
import { resetDevice } from '../../../redux/actions';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../styles/account';
import { FormattedMessage } from 'react-intl';
import moment from 'moment/moment';

const feedbackArray = [
  {
    title: "Technical difficulties",
    label: "Please describe the issue you encountred (Required)",
    required:true
  },
  {
    title: "Not enough/appropriate content",
    label: "Please tell us more (Optional)",
    required:false
  },
  {
    title: "My child was not engaged",
    label: "Please tell us more (Optional)",
    required:false
  },
  {
    title: "The application was too difficult for my child",
    label: "Please tell us more (Optional)",
    required:false
  },
  {
    title: "The subscription was too expensive",
    label: "Please tell us more (Optional)",
    required:false
  },
  {
    title: "Other",
    label: "Please tell us more (Required)",
    required:true
  },
];
class StaffPersonalInfo extends Component {

  state = {
    totalLicensesCount:  this.props.account ? this.props.account.billingCount : 0,
    previousLicensesCount: this.props.account ? this.props.account.billingCount : 0,
    error: {
      email: false,
      firstName: false,
      lastName: false
    },
    checked: this.props.profile ? this.props.profile.prototype === 'Yes' : false,
    showUpdateSubscriptionModal: false,
    showCancelSubscriptionModal: false,
    showIncrementSubscriptionModal: false,
    showDecrementSubscriptionModal: false,
    showIncrementConfirmationModal: false,
    showDecrementConfirmationModal: false,
    showCancelConfirmationModal: false,
    showPauseConfirmationModal: false,
    checkedStudents:[],
    licensePrice: 0,
    renewalDate:"",
    suspensionModel:false,
    termLength:'',
    snackbar:false,
    feedbackData:[],
    feedbackError:[],
    expiryDate:"",
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.profile.prototype !== nextProps.profile.prototype) {
      const checked = nextProps.profile.prototype === 'Yes';
      this.setState({ checked })
    }
  }

  componentDidMount() {
    this.props.fetchBuddyBookSubscription();
    this.props.getRolesForStaff(this.props.id);
    this.props.getAccount();
    this.props.getStudentsForStaff(this.props.id);
  }

  componentDidUpdate(prevProps,prevState){
    if(prevProps !== this.props && prevProps.buddyBookSubscription !== this.props.buddyBookSubscription ){
      if(this.props.buddyBookSubscription.nextPayment){
        // const duration = moment.duration(moment(this.props.buddyBookSubscription.nextPayment).diff(moment(this.props.buddyBookSubscription.signup_date)));
        // const diffInMonths = duration.years() * 12 + duration.months();
        // const diffInDays = duration.days();
        // let termLength = diffInMonths < 1 ? `${diffInDays} days` : (diffInMonths ? `${diffInMonths} months` : "")
        this.setState({renewalDate:moment(new Date(this.props.buddyBookSubscription.nextPayment)).format('MM-DD-YYYY')})
      }
      if(this.props.buddyBookSubscription.state === "deleted" || this.props.buddyBookSubscription === ""){
        this.props.checkForSuspension()
      }
      fetchSubscriptionOptions()
      .then((response) => {
        if (response.error){
          console.log(response.err)
        }
        else {
          response.map((val)=>{
            if(val.id === this.props.buddyBookSubscription.plan_id){
              let term = val.billing_type === "month" ? "Monthly" : "Annual"
              this.setState({licensePrice:val.recurring_price, termLength: term})
            }
          })

        }
      })
    }
    if(prevProps.suspendSubscription !== this.props.suspendSubscription){
      this.props.fetchBuddyBookSubscription();
      this.setState({snackbar: true})
    }
    if(prevProps.checkSuspension !== this.props.checkSuspension){
      if(this.props.checkSuspension){
        const dateRegex = /\d{4}-\d{2}-\d{2}/; 
        const expirationDate = this.props.checkSuspension.match(dateRegex)
        this.setState({expiryDate: expirationDate ? expirationDate : ""})
      }
    }
  }
  
  check = (ev, type) => {
    switch (type) {
      case 'email':
        let email = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(ev.target.value);
        this.setState({ error: { ...this.state.error, email } });
        break;
      case 'firstName':
        let firstName = !ev.target.value;
        this.setState({ error: { ...this.state.error, firstName } });
        break;
      case 'lastName':
        let lastName = !ev.target.value;
        this.setState({ error: { ...this.state.error, lastName } });
        break;
      default:
        break;
    }
  };

  handleSubscriptionUpdate = () => {
    if(this.props.checkSuspension !== ""){
      this.setState({suspensionModel:true})
    }
    else{
      if (this.props.buddyBookSubscription && this.props.buddyBookSubscription.update_url) {
        window.open(this.props.buddyBookSubscription.update_url, 'popup', `width=500,height=500,left=${(window.screen.width/2)-(500/2)},top=${(window.screen.height/2)-(500/2)}`);
        this.setState({ showUpdateSubscriptionModal: false });
      }
    }
  }

  handleUpdateSubscriptionUpdate = () => {
      this.setState({ showUpdateSubscriptionModal: true });
  }

  handleCancelSubscription = () => {
    if(this.props.checkSuspension !== ""){
      this.setState({suspensionModel:true})
    }
    else{
      this.setState({ showCancelSubscriptionModal: true, showUpdateSubscriptionModal: false });
    }
  }

  updatePaymentInfo = () => {
    //this.setState({ showIncrementSubscriptionModal: true, showCancelSubscriptionModal: false })
  }

  changeNumberOfLicenses = () => {
    if(this.props.checkSuspension !== ""){
      this.setState({suspensionModel:true})
    }
    else{
      this.setState({
        showIncrementSubscriptionModal: true, showUpdateSubscriptionModal: false,
        totalLicensesCount: this.props.account ? this.props.account.billingCount : 0,
        previousLicensesCount: this.props.account ? this.props.account.billingCount : 0
      })
    }
  }

  handleSubscriptionCancel = () => {
    if (this.props.buddyBookSubscription && this.props.buddyBookSubscription.cancel_url) {
      window.open(this.props.buddyBookSubscription.cancel_url, 'popup', 'width=500,height=500');
    }
  }

  handleCheck = (ev) => {
    this.setState({ checked: ev.target.checked });
    const prototype = ev.target.checked ? 'Yes' : 'No';
    if (this.props.profile.id !== 'null') {
      this.props.updateStaffProfile(this.props.id, { ...this.props.profile, prototype })
    }
    else {
      this.props.createStaffProfile(this.props.id, { prototype });
    }
  };

  handleUpdateSubscriptionClose = () => {
    this.setState({ showUpdateSubscriptionModal: false })
  }

  handleCancelSubscriptionClose = () => {
    this.setState({ showCancelSubscriptionModal: false, showCancelPauseConfirmationModal: false })
  }

  handleIncrement = () => {
    this.setState(state => ({ totalLicensesCount: state.totalLicensesCount + 1 }));
    if (this.state.totalLicensesCount + 1 >= this.state.previousLicensesCount) {
      this.setState({
        showDecrementSubscriptionModal: false,
        showIncrementSubscriptionModal: true
      })
    }
  }

  decrement = () => {
    this.setState(state => ({ totalLicensesCount: state.totalLicensesCount - 1 }));
  }

  handleDecrement = () => {

    if (this.state.totalLicensesCount === 1)
      return;

    this.setState({
      totalLicensesCount: this.state.totalLicensesCount - 1
    });

    if (this.state.totalLicensesCount - 1 < this.state.previousLicensesCount) {
      this.setState({
        showDecrementSubscriptionModal: true,
        showIncrementSubscriptionModal: false
      })
    }
  }

  handleDecrementSubscriptionClose = () => {
    this.setState({
      showDecrementSubscriptionModal: false
    })
  }

  handleIncrementSubscriptionClose = () => {
    this.setState({
      showIncrementSubscriptionModal: false,
      showDecrementSubscriptionModal: false,
      checkedStudents:[]
    })
  }

  handleIncrementConfirmationClose = () => {
    this.setState({
      showIncrementConfirmationModal: false
    })
  }

  handleDecrementConfirmationClose = () => {
    this.setState({
      showDecrementConfirmationModal: false
    })
  }


  handleDeleteConfirmation = () => {
    this.setState({
      showDecrementConfirmationModal: true,
      showDecrementSubscriptionModal: false
    })
  }

  handleIncrementConfirmationModal = () => {
    this.setState({
      showIncrementConfirmationModal: true,
      showIncrementSubscriptionModal: false
    })
  }

  handleCancelConfirmationClose = () => {
    this.setState({
      showCancelConfirmationModal: false,
      feedbackData:[],
      cancelButtonClicked:false,
    })
  }

  confirmCancelYes = () => {
    this.setState({cancelButtonClicked:true})
    if(this.state.feedbackError.length === 0){
      if( this.state.feedbackData.length >= 1 ){
        this.props.cancelSubscription(false, this.state.feedbackData)
        this.setState({ showCancelConfirmationModal: false,feedbackData:[] })
      }
    }
  }

  confirmCancelNo = () => {
    this.setState({ showCancelConfirmationModal: false })
  }

  handlePauseConfirmationClose = () => {
    this.setState({
      showPauseSubscriptionModal: false
    })
  }

  confirmPauseYes = () => {
    this.props.cancelSubscription(false, this.state.feedbackData)
    this.setState({ showPauseConfirmationModal: false })
  }

  confirmPauseNo = () => {
    this.setState({ showPauseConfirmationModal: false })
  }

  confirmIncrement = () => {
    this.props.updateLicenseAmount(this.state.totalLicensesCount,[])
    this.setState({
      showIncrementConfirmationModal: false
    })
  }

  confirmDelete = () => {
    if(this.state.checkedStudents.length>0){
      this.props.updateLicenseAmount(this.state.totalLicensesCount,this.state.checkedStudents)  
    }
    else{
      this.props.updateLicenseAmount(this.state.totalLicensesCount,[])
    }
    this.setState({
      showDecrementConfirmationModal: false
    })
  }

  handlePause = () => { 
    this.setState({ showPauseConfirmationModal: true, showCancelSubscriptionModal: false });
  }
  
  handleCancel = () => {
    if(this.props.checkSuspension !== ""){
      this.setState({suspensionModel:true})
    }
    else{
      this.setState({ showCancelConfirmationModal: true, showUpdateSubscriptionModal: false });
    }  
  }

  handleStudentCheck=(id)=>{
    if(this.state.checkedStudents.includes(id)){
      const updatedArr=this.state.checkedStudents.filter(val=>val!==id)
      this.setState({checkedStudents:updatedArr})
    }
    else{
      this.setState({checkedStudents:[...this.state.checkedStudents,id]})
    }
  }

  handleCheckFeedback=(title,required)=>{
    if(this.state.feedbackData.find(val=>title===val.title)){
      const updatedArray = this.state.feedbackData.filter(val=>val.title!==title)
      const updateError = this.state.feedbackError.filter(val=>val!==title)
      this.setState({feedbackData:updatedArray,feedbackError:updateError})
    }
    else{
      if(required)this.handleFeedbackValidation(title,"")
      this.setState({feedbackData:[...this.state.feedbackData,{title:title,value:""}]})
    }
  }

  handleFeedbackInput=(value,title,required)=>{
    let intitalValue = this.state.feedbackData.find(val=>val.title===title)
    if(intitalValue){
      if(required)this.handleFeedbackValidation(title,value)
      intitalValue.value = value
      let array = this.state.feedbackData.filter(val=>val.title!==title)
      this.setState({feedbackData:[...array,intitalValue]})

    }
  }

  handleFeedbackValidation=(title,value)=>{
    const error = this.state.feedbackError.includes(title)
      if(value === '' && !error ){
          this.setState({feedbackError:[...this.state.feedbackError,title]})
        }
      else if(value !== '' && error){
          const updatedArr=this.state.feedbackError.filter(val=> val!== title)
          this.setState({feedbackError: updatedArr})
        }
  }

  render() {
    const { classes, staffMember: { firstName, lastName, email, type }, edit, login, admin, myself } = this.props;
    const { error } = this.state;
    const { account, staffRoles } = this.props;
    const specAdmin = staffRoles.find(role => role.id === '922331036862345812');

    return (
      <Card className={classes.card}>
        <CardHeader title={<FormattedMessage id="Profile.personal" />} action={
          !edit && (admin || myself) &&
          <Tooltip title={<FormattedMessage id="General.edit" />}>
            <IconButton
              aria-label='edit'
              onClick={() => this.props.toggleEdit('editPersonal')}
            >
              <Edit />
            </IconButton>
          </Tooltip>
        } />
        <Divider />
        <CardContent>
          <div className={classes.infoLine}>
            <AccountCircle className={classes.infoIcon} />
            {edit
              ? <>
                <TextField
                  className={classes.inputLine}
                  variant='outlined'
                  type='text'
                  name='firstName'
                  label={<FormattedMessage id="General.firstName" />}
                  value={firstName}
                  error={error.firstName}
                  helperText={error.firstName ? '*A First Name is required' : ''}
                  onChange={this.props.handleChange}
                  onBlur={(ev) => this.check(ev, 'firstName')}
                />
                <TextField
                  className={classes.infoText}
                  variant='outlined'
                  type='text'
                  name='lastName'
                  label={<FormattedMessage id="General.lastName" />}
                  value={lastName}
                  error={error.lastName}
                  helperText={error.lastName ? '*A Last Name is required' : ''}
                  onChange={this.props.handleChange}
                  onBlur={(ev) => this.check(ev, 'lastName')}
                />
              </>
              : <Typography className={classes.infoText} variant='h6'>{firstName} {lastName}</Typography>
            }
          </div>
          <div className={classes.infoLine}>
            <Mail className={classes.infoIcon} />
            {edit
              ? <TextField
                variant='outlined'
                type='email'
                name='email'
                label='Email'
                error={error.email}
                value={email || ''}
                helperText={error.email ? '*A valid email is required' : ''}
                onChange={this.props.handleChange}
                onBlur={(ev) => this.check(ev, 'email')}
              />
              : <Typography className={classes.infoText} variant='h6'>{email || 'No Email'}</Typography>
            }
          </div>
          {
            edit && type && type.toLowerCase() !== 'specialist' &&
            <div className={classes.login}>
              <Typography variant='h6' className='text'> <FormattedMessage id="Profile.login" /> </Typography>
              <Switch className='toggle' checked={login} onChange={this.props.toggleLogin} />
            </div>
          }
          {
            type && type.toLowerCase() === 'specialist' &&
            <div className={classes.infoLine}>
              <Restore className={classes.infoIcon} />
              <Button
                className={classes.infoText}
                variant='contained'
                aria-label='reset device'
                onClick={() => resetDevice(this.props.id)}
              >
                Reset my device
              </Button>
            </div>
          }
          {edit &&
            <div className={classes.buttons}>
              <Button
                variant='outlined'
                color='secondary'
                onClick={() => this.props.cancel('editPersonal')}
              >
                <FormattedMessage id="General.cancel" />
              </Button>
              <Button
                className={classes.confirm}
                variant='contained'
                color='primary'
                onClick={() => this.props.submit('personal')}
              >
                <FormattedMessage id="General.save" />
              </Button>
            </div>
          }
          {/* {
            account && specAdmin &&
            <div className={classes.subscriptionButtons} >
              <Button
                variant="contained"
                color='primary'
                style={{ marginRight: "10px" }}
                onClick={this.handleSubscriptionUpdate}>
                Update Subscription
              </Button>
              <Button
                variant="contained"
                color='secondary'
                onClick={this.handleSubscriptionCancel} >
                Cancel Subscription
              </Button>
            </div>
          } */}

          {
            account && specAdmin && ( Object.keys(this.props.buddyBookSubscription).length !== 0 &&
            <div className={classes.subscriptionButtons} >
              <Button
                variant="contained"
                color='primary'
                style={{ backgroundColor: "#3f51b5", marginRight: "10px" }}
                onClick={this.handleUpdateSubscriptionUpdate}>
                Update Subscription
              </Button>
            </div>)
          }
          {
            this.state.showUpdateSubscriptionModal && <Dialog
              maxWidth='sm'
              fullWidth
              open={this.state.showUpdateSubscriptionModal}
              onClose={this.handleUpdateSubscriptionClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle className={classes.dialogTitle} style={{margin:0}}>
              <div className={classes.headContainer}>
                <Typography className={classes.enrollTitle} variant='h4'>
                  Update Your Subscription</Typography>
                  <IconButton
                  onClick={this.handleUpdateSubscriptionClose}
                  className={classes.exitIcon}
                  aria-label="close dialog"
                >
                  <Close />
                </IconButton>
                </div>

              </DialogTitle>
              <DialogContent className={classes.dialog} style={{ minHeight: 100,padding: 0, margin: 0 }}>
              <List dense className={classes.list}>
                  <ListItem>Subscription Status: 
                    <Typography style={{color:this.props.buddyBookSubscription.state ? (this.props.buddyBookSubscription.state === 'deleted' ? 'red' : 'green' ) : ''}}>
                    {this.props.buddyBookSubscription.state ? (this.props.buddyBookSubscription.state === 'deleted' ? ( (moment(new Date(this.state.expiryDate)).isBefore(moment()) || !this.state.expiryDate) ? 'Expired' : 'Expiring' ) : 'Active' ) : 'N/A'}
                    </Typography>
                    </ListItem>
                  <ListItem>Term Length: {this.state.termLength ? this.state.termLength : 'N/A'}</ListItem>
                  <ListItem>Renewal Date: {this.state.renewalDate ? this.state.renewalDate : "N/A"}</ListItem>
                  <ListItem>Number of Licenses: {this.props.account ? this.props.account.billingCount : 'N/A'}</ListItem>
                </List>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',flexDirection:'column' }}>
                <div>
                  <Button
                    onClick={this.handleSubscriptionUpdate}
                    style={{ marginBottom: 15, color: "#fff", backgroundColor: "#3f51b5",padding:'15px 100px' }}
                  >
                    Update Payment Info
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={this.changeNumberOfLicenses}
                    style={{ marginBottom: 15, color: "#fff", backgroundColor: "#3f51b5",padding:'15px 100px' }}
                  >
                    Add/Remove Licenses
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={this.handleCancel}
                    style={{ marginBottom: 50, color: "#fff", backgroundColor: "#3f51b5",padding:'15px 100px' }}
                  >
                    Cancel Subscription
                  </Button>

                </div>
                </div>
              </DialogContent>
            </Dialog>
          }
          {/* {
            this.state.showCancelSubscriptionModal && <Dialog
              maxWidth='md'
              fullWidth
              open={this.state.showCancelSubscriptionModal}
              onClose={this.handleCancelSubscriptionClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle className={classes.dialogTitle}>
                <div className={classes.headContainer}>
              <Typography className={classes.enrollTitle} variant='h4'>
                  We're sorry to see you go. Would you like us to keep your data so you can come back any time?</Typography>
                <IconButton
                  onClick={this.handleCancelSubscriptionClose}
                  className={classes.exitIcon}
                  aria-label="close dialog"
                  style={{ float: 'right' }}
                >
                  <Close />
                </IconButton>
                </div>
              </DialogTitle>
              <DialogContent className={classes.dialog} style={{ minHeight: 100, }}>
                <div>
                  <Button
                    onClick={this.handlePause}
                    style={{ marginBottom: 10, color: "#fff", backgroundColor: "#3f51b5", left: '25%', width: '450px', height: '50px' }}
                  >
                    Pause My Subscription but keep my data
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={this.handleCancel}
                    style={{ marginBottom: 50, color: "#fff", backgroundColor: "#f50057", left: '25%', width: '450px', height: '50px' }}
                  >
                    Cancel Subscription and Delete my Data
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
          } */}
          {
            (this.state.showIncrementSubscriptionModal || this.state.showDecrementSubscriptionModal) && <Dialog
              maxWidth='md'
              fullWidth
              open={this.state.showIncrementSubscriptionModal || this.state.showDecrementSubscriptionModal}
              onClose={this.handleIncrementSubscriptionClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle className={classes.dialogTitle}>
                <div className={classes.headContainer}>
              <Typography className={classes.enrollTitle} variant='h4'>
                  You currently have {this.state.previousLicensesCount} Licenses renewing on {this.state.renewalDate}:</Typography>
                <IconButton
                  onClick={this.handleIncrementSubscriptionClose}
                  className={classes.exitIcon}
                  aria-label="close dialog"
                  style={{ float: 'right' }}
                >
                  <Close />
                </IconButton>
                </div>
              </DialogTitle>
              <DialogContent className={classes.dialog} style={{ minHeight: 100, }}>
                <div>
                  <Button aria-label="small outlined button group" style={{ marginBottom: 20, backgroundColor: '#848282', marginLeft: 420, width: 100, color: 'white' }}>
                    <Button onClick={this.handleDecrement}>-</Button>
                    {this.state.totalLicensesCount}
                    <Button onClick={this.handleIncrement}>+</Button>
                  </Button>

                </div>

                {
                  this.state.showIncrementSubscriptionModal &&
                  <>
                    <div style={{ marginLeft: 240, marginBottom: 50, color: "#fff", backgroundColor: "#848282", width: '450px', height: '50px' }}>

                      {/* <Tooltip title="New Price, will be billed prorated today"> */}

                      <Typography style={{ textAlign: 'center', paddingTop: 12 }}
                        onClick={this.updatePaymentInfo}

                      >
                        New Price: $ {this.state.licensePrice * this.state.totalLicensesCount} 
                      </Typography>

                      {/* </Tooltip> */}
                    </div>
                    <div>
                      <Button
                      disabled={this.state.totalLicensesCount===this.state.previousLicensesCount}
                        onClick={this.handleIncrementConfirmationModal}
                        style={{ marginLeft: 240, marginBottom: 10, color: "#fff", backgroundColor: "#3f51b5", width: '450px', height: '50px' }}
                      >
                        Confirm
                      </Button>
                    </div>
                  </>
                }

                {
                  this.state.showDecrementSubscriptionModal &&
                  <>
                    <div style={{ marginLeft: 240, marginBottom: 50, color: "#fff", backgroundColor: "#848282", width: '450px', height: '50px' }}>

                      {/* <Tooltip title="New Price, will be billed prorated today"> */}

                      <Typography style={{ textAlign: 'center', paddingTop: 12 }}
                        onClick={this.updatePaymentInfo}

                      >
                        New Price: $ {this.state.licensePrice * this.state.totalLicensesCount} 
                      </Typography>

                      {/* </Tooltip> */}
                    </div>
                    <div>
                      <Typography  style={{ fontSize: 24, textAlign: 'center', float: 'center', fontWeight: 'bold' }}>
                        Which, students would you like to delete?
                      <div style={{ marginTop: 15, fontSize: 24, textAlign: 'center', float: 'center',fontWeight: 'normal' }}>
                        {this.props.staffStudents.length > 0 ? 
                        this.props.staffStudents.map((val)=>{
                          return <div>
                            <Checkbox 
                              checked={this.state.checkedStudents.includes(val.id)}
                              disabled={!(this.state.totalLicensesCount<this.props.staffStudents.length-this.state.checkedStudents.length) && !this.state.checkedStudents.includes(val.id) }
                              color="primary"
                              onClick={()=>{this.handleStudentCheck(val.id)}}
                              />{val.firstName}
                              <br />
                            </div>
                        }) 
                        :''}
                        </div>
                        {this.state.totalLicensesCount<this.props.staffStudents.length-this.state.checkedStudents.length && <Typography variant='subtitle1' style={{color:'red',marginTop:10}}>The number of students exceeds the license count, either delete some students or increase the license count.  </Typography>}
                        </Typography>
                        
                      <Button
                       disabled={this.state.totalLicensesCount<this.props.staffStudents.length-this.state.checkedStudents.length}
                        onClick={this.handleDeleteConfirmation}
                        style={{ marginTop: 10, marginBottom: 10, color: "#fff", backgroundColor: "#3f51b5", left: '25%', width: '450px', height: '50px' }}
                      >
                        Confirm 
                      </Button>
                    </div>
                  </>
                }

              </DialogContent>
            </Dialog>
          }
    
          {
            this.state.showIncrementConfirmationModal &&
            <Dialog
              disableEscapeKeyDown
              fullWidth={true}
              open={this.state.showIncrementConfirmationModal}
              onClose={this.handleIncrementConfirmationClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <Typography variant='h6'>
                  Are you sure you would like to add {this.state.totalLicensesCount - this.state.previousLicensesCount} Licenses?

                  Your next renewal will be ${this.state.totalLicensesCount * this.state.licensePrice}.

                  You will be billed today a prorated amount for {this.state.totalLicensesCount - this.state.previousLicensesCount} Licenses.                 </Typography>
                <DialogActions className={classes.actions}>
                  <Button onClick={this.confirmIncrement} color='secondary'>
                    Yes
                  </Button>
                  <Button type='submit' color='primary' onClick={()=>{this.setState({showIncrementConfirmationModal: false})}}>
                    No
                  </Button>
                </DialogActions>
              </DialogContent>
            </Dialog>
          }

          {
            this.state.showDecrementConfirmationModal &&
            <Dialog
              disableEscapeKeyDown
              fullWidth={true}
              open={this.state.showDecrementConfirmationModal}
              onClose={this.handleDecrementConfirmationClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <Typography style={{ fontSize: 24,fontWeight: 'bold' }}>
                  Are you sure you would like to remove { this.state.previousLicensesCount-this.state.totalLicensesCount } Licenses?
                  <div style={{ fontSize: 24, fontWeight: 'normal' }}>
                    {/* <Checkbox defaultChecked color="primary"/>John<br />
                    <Checkbox  color="primary"/>Sara<br /> */}
                      <ul>
                        {this.props.staffStudents.map((val)=>{
                          return this.state.checkedStudents.includes(val.id)?<li>{val.firstName}</li>:''
                        })}
                      </ul>
                  </div>                  
                  </Typography>
                  <Typography >
                  {this.state.checkedStudents && this.state.checkedStudents.length ? `The students above will be deleted on ${this.state.renewalDate}.` : ""}
                 </Typography>
                <DialogActions className={classes.actions}>
                  <Button onClick={this.confirmDelete} color='secondary'>
                    Yes
                  </Button>
                  <Button type='submit' color='primary' onClick={()=>{
                    this.setState({
                      showDecrementConfirmationModal: false,
                      checkedStudents:[]
                    })
                  }}>
                    No
                  </Button>
                </DialogActions>
              </DialogContent>
            </Dialog>
          }

          {
            this.state.showCancelConfirmationModal &&
            <Dialog
              disableEscapeKeyDown
              fullWidth={true}
              open={this.state.showCancelConfirmationModal}
              onClose={this.handleCancelConfirmationClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle>
              <div className={classes.headContainer}>
              <Typography style={{ fontSize: 24, textAlign: 'center', flex:1}}>We're sorry to see you go!</Typography>
              <IconButton
                  style={{justifySelf:'flex-end',}}
                  onClick={this.handleCancelConfirmationClose}
                  aria-label="close dialog" >
                    <Close/>
                </IconButton>
                </div>
              </DialogTitle>
              <DialogContent>
              <Typography variant='subtitle1'>
                    Please let us know why you are cancelling your BuddyBooks subscription<br/>
                    so we can continue to improve BuddyBooks to help readers!
                  </Typography>
                {feedbackArray.map(val=>{
                  const checkfield = this.state.feedbackData.find(value=>value.title===val.title)
                  return <div style={{display:'grid', marginLeft:20}}>
                          <FormControlLabel 
                           control={<Checkbox checked={!!checkfield} onClick={()=>{this.handleCheckFeedback(val.title,val.required)}} />} 
                           label={val.title}
                           />
                           {checkfield && 
                           <TextField 
                           label={val.label} 
                           variant='filled'
                           value={checkfield ? this.state.feedbackData.find(value=>value.title===val.title).value : ""}
                           onChange={(e)=>{this.handleFeedbackInput(e.target.value,val.title,val.required)}}
                           error={this.state.feedbackError.includes(val.title)}
                           helperText={this.state.feedbackError.includes(val.title) ? "This field is required" : ""}/>}
                        </div>
                })}
                {(this.state.cancelButtonClicked && this.state.feedbackData.length < 1) && <Typography variant="body2" color="error" style={{ marginTop: '10px', fontStyle: 'italic' }} >Please check at least one box to proceed with cancellation.</Typography>}
              </DialogContent>
              <DialogActions style={{ justifyContent: 'center' }}>
                  <Button
                    style={{ marginBottom: 10, padding:'10px 50px' }} 
                    onClick={this.confirmCancelYes} 
                    variant='contained' 
                    color='secondary'>
                    Cancel Subscription
                  </Button>
                </DialogActions>
            </Dialog>
          }

{
            this.state.showPauseConfirmationModal &&
            <Dialog
              disableEscapeKeyDown
              fullWidth={true}
              open={this.state.showPauseConfirmationModal}
              onClose={this.handlePauseConfirmationClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <Typography style={{ fontSize: 24 }}>
                  Are you sure you would like to pause subscription?
                </Typography>
                <DialogActions className={classes.actions}>
                  <Button onClick={this.confirmPauseYes} color='secondary'>
                    Yes
                  </Button>
                  <Button type='submit' color='primary' onClick={this.confirmPauseNo}>
                    No
                  </Button>
                </DialogActions>
              </DialogContent>
            </Dialog>
          }
          {
            this.props.checkSuspension && 
            <Dialog
             open={this.state.suspensionModel}
             onClose={()=>{this.setState({suspensionModel:false})}}>
              <DialogTitle className={classes.dialogTitle}>
                <div className={classes.headContainer}>
              <Typography className={classes.enrollTitle} variant='h4'>
                  {this.state.expiryDate ? `Your Account is Set to Expire` : "Something went wrong!"}</Typography>
                <IconButton
                  onClick={()=>{this.setState({suspensionModel:false})}}
                  className={classes.exitIcon}
                  aria-label="close dialog"
                  style={{ float: 'right' }}
                >
                  <Close />
                </IconButton>
                </div>
              </DialogTitle>
              <DialogContent className={classes.Dialog}>
              <Typography style={{ fontSize: 20,textAlign:'center' }}>
                  {this.state.expiryDate ? (<p>{moment(new Date(this.state.expiryDate)).isBefore(moment()) ? `Your account has expired` : `Your account is set to expire on ${moment(new Date(this.state.expiryDate)).format('MM-DD-YYYY')}`}<br/>For additional assistance please contact,<br/>Support@ObjectiveEd.com</p>) : this.props.checkSuspension}           
                </Typography>
              </DialogContent>
            </Dialog>
          }
          {/* {this.state.snackbar && 
          <Snackbar
          ContentProps={{
            classes: {
              root: this.props.suspendSubscription.includes('successfully') ? classes.snackbarSuccess : classes.snackbarFailed
            }
          }}
          anchorOrigin={{ vertical:'top', horizontal: 'center' }}
          open={this.state.snackbar}
          onClose={()=>{this.setState({snackbar:false})}}
          autoHideDuration={3000}
          message={this.props.suspendSubscription}
          action={
            <IconButton
                  onClick={()=>{this.setState({snackbar:false})}}
                  aria-label="close dialog"
                  style={{ color: '#fff' }}
                >
                  <Close />
                </IconButton>
          }
        />
          } */}
        </CardContent>
      </Card>
    )
  }
}


const mapState = ({ platform: { staffRoles,staffStudents }, shared: { account, buddyBookSubscription,updateLicenseAmount,suspendSubscription,checkSuspension } }) => {
  return {
    account,
    buddyBookSubscription,
    staffRoles,
    staffStudents,
    updateLicenseAmount,
    suspendSubscription,
    checkSuspension
  }
};

const mapDispatch = (dispatch) => {
  return {
    fetchBuddyBookSubscription: () => dispatch(fetchBuddyBookSubscription()),
    getAccount: () => dispatch(getAccount()),
    getRolesForStaff: (id) => dispatch(getRolesForStaff(id)),
    getStudentsForStaff: (id) => dispatch(getStudentsForStaff(id)),
    updateLicenseAmount:(licenseAmount,deletionArr)=>dispatch(updateLicenseAmount(licenseAmount,deletionArr)),
    cancelSubscription:(cancel, reason)=>dispatch(cancelSubscription(cancel, reason)),
    checkForSuspension:()=>dispatch(checkForSuspension())
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(StaffPersonalInfo));