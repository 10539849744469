import { ROOT_URL, FETCH_ALL_GAMES, PATCH_STUDENT_GAME, DELETE_STUDENT_GAME, HANDLE_ERROR } from '../../constants';
import axios from 'axios';

export const fetchGames = (history) => {
  const url = `${ROOT_URL}/games`;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        if (typeof(response.data) === 'object'){
          dispatch({
            type: FETCH_ALL_GAMES,
            games: response.data
          })
        }
        else {
          history.push('/login');
          dispatch({
            type: HANDLE_ERROR,
            error: {type: 'sessionExp', msg: 'Your session has expired, please log back in'}
          })
        }
      })
      .catch(err => console.log(err))
  }
};

export const patchEntertainmentGame = (studentId, game) => {
  const url = `${ROOT_URL}/students/${studentId}/games`;
  return (dispatch) => {
    axios
      .patch(url, game)
      .then((response) => {
        dispatch({
          type: PATCH_STUDENT_GAME,
          game: response.data
        })
      })
      .catch(err => console.log(err))
  };
};

export const deleteEntertainmentGame = (studentId, game) => {
  const url =`${ROOT_URL}/students/${studentId}/games`;
  return (dispatch) => {
    axios
      .delete(url, {data: game})
      .then(() => {
        dispatch({
          type: DELETE_STUDENT_GAME,
          game
        })
      })
      .catch(err => console.log(err))
  }
};