import React from 'react';
import { connect } from 'react-redux';
import StaffWizardPage1 from './StaffWizardPage1';
import StaffWizardPage2 from './StaffWizardPage2';
import StaffWizardPage3 from './StaffWizardPage3';
import StaffWizardPage4 from './StaffWizardPage4';
import StaffWizardPage5 from './StaffWizardPage5';
import WizardToggleButton from '../WizardToggleButton';
import {
  searchStaff,
  convertStaff,
  createStaffMember,
  createStaffMemberAsUser,
  fetchStudents,
  createSpecialist,
  fetchRoles
} from '../../../../redux/actions';
import { FormattedMessage } from 'react-intl';

class StaffWizardBase extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      open: false,
      page: 1,
      firstName: '',
      lastName: '',
      email: '',
      role: '',
      students: [],
      specialist: {},
      products: ''
    }
  }

  componentDidMount() {
    this.props.fetchStudents();
    if (this.props.user.accountId){
      this.props.fetchRoles(this.props.user.accountId);
    }
    if (this.props.user && this.props.user.products) {
      let multiProdAvail = this.props.user.products.includes(',');
      if (!multiProdAvail) {
        this.setState({products: this.props.user.products});
      }
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.user.id !== nextProps.user.id) {
      this.props.fetchRoles(nextProps.user.accountId);
      this.props.fetchStudents();
    }
    if (nextProps.user !== this.props.user && nextProps.user) {
      let multiProdAvail = nextProps.user.products.includes(',');
      if (!multiProdAvail) {
        this.setState({products: nextProps.user.products});
      }
    }
  }

  openWizard = () => {
    this.setState({ open: true });
  };

  closeWizard = () => {
    this.setState({
      open: false,
      page: 1,
      firstName: '',
      lastName: '',
      email: '',
      role: '',
      students: [],
      user: {},
      products: ''
    });
  };

  next = () => {
    if (this.state.page === 1){
      searchStaff(this.state.email)
        .then(user => {
          this.setState({ user, error: '' });
          if (!this.props.staff.some(staffMember => staffMember.id === user.id)){
            switch(user.type) {
              case 'Admin':
                this.goToPage(4);
                break;
              case 'Specialist':
                this.goToPage(2);
                break;
              case 'Teacher':
                this.goToPage(2);
                break;
              case 'Guardian':
                this.goToPage(2);
                break;
              case 'Master':
                this.goToPage(4);
                break;
              default:
                this.goToPage(3);
                break;
            }
          }
          else {
            this.setState({ error: 'This person is already a member of your organization, please search for another user!'})
          }
        })
    }
  };

  back = () => {
    this.setState({ page: 1 });
  };

  goToPage = (page) => {
    this.setState({ page, error: '' });
  };

  handleChange = (ev) => {
    this.setState({ [ev.target.name]: ev.target.value })
  };

  submit = () => {
    const { firstName, lastName, email, products } = this.state;
    let productSelected = "";
    console.log(typeof products)
    if(typeof products == 'string')
      productSelected = products;
    else
      productSelected = this.state.products.map(obj => obj.value).join(',');
    const students = this.state.students.map(_student => this.props.students.find(student => student.id === _student.value));
    if (this.state.user.type === 'Teacher') {
      return convertStaff(this.state.user)
        .then((_user) => {
          this.props.registerSpecialist(this.props.account, _user, students)
          this.goToPage(5)
        })
    }
    if (this.state.user.type === 'Specialist'){
      this.props.registerSpecialist(this.props.account, this.state.user, students);
      return this.goToPage(5);
    }
    else {
      let role = this.props.roles.find(role => this.state.role.value === role.id);
      
      if(!role)
        role = this.props.roles.find(role => "-4524935518238898931" === role.id);
        const staffMember = { firstName, lastName, type: role.name, email };
      const user = { email, firstName, lastName, products: productSelected };
      this.props.createStaffMemberAsUser(staffMember, user, role, students);
      return this.goToPage(5);
    }
  };

  render(){
    const { page, open, role, error } = this.state;
    return (
      <div>
        {
          page === 1 &&
          <StaffWizardPage1
            error={error}
            open={open}
            role={role}
            close={this.closeWizard}
            next={this.next}
            goToPage={this.goToPage}
            handleChange={this.handleChange}
          />
        }
        {
          page === 2 &&
          <StaffWizardPage2
            open={open}
            role={role}
            user={this.state.user}
            students={this.props.students}
            selectedStudents={this.state.students}
            close={this.closeWizard}
            submit={this.submit}
            back={this.back}
            goToPage={this.goToPage}
            handleChange={this.handleChange}
          />
        }
        {
          page === 3 &&
          <StaffWizardPage3
            open={open}
            email={this.state.email}
            roles={this.props.roles}
            user={this.state.user}
            products={this.props.user.products}
            students={this.props.students}
            selectedStudents={this.state.students}
            close={this.closeWizard}
            submit={this.submit}
            back={this.back}
            goToPage={this.goToPage}
            handleChange={this.handleChange}
            selectedProduct={this.state.products}
          />
        }
        {
          page === 4 &&
          <StaffWizardPage4
            open={open}
            close={this.closeWizard}
          />
        }
        {
          page === 5 &&
          <StaffWizardPage5
            open={open}
            close={this.closeWizard}
          />
        }
        <WizardToggleButton
          openWizard={this.openWizard}
          text={<FormattedMessage id='StaffMembers.AddBtn'/>}
        />
      </div>

    )
  }
}

const mapState = ({ shared: { account, user }, platform: { roles, students, staff }}) => {
  return {
    user,
    account,
    roles,
    students,
    staff
  }
};

const mapDispatch = (dispatch) => {
  return {
    fetchStudents: () => dispatch(fetchStudents()),
    fetchRoles: (accountId) => dispatch(fetchRoles(accountId)),
    createStaffMember: (user) => dispatch(createStaffMember(user)),
    createStaffMemberAsUser: (staffMember, user, roles, students) => dispatch(createStaffMemberAsUser(staffMember, user, roles, students)),
    registerSpecialist: (account, specialist, students) => dispatch(createSpecialist(account, specialist, students)),
  }
};

export default connect(mapState, mapDispatch)(StaffWizardBase);