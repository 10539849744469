export default {
  title: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '55px',
    marginBottom: '20px'
  },
  text: {
    marginLeft: '20px',
    alignSelf: 'center'
  },
  icon: {
    padding: '5px',
    backgroundColor: '#0087b6',
    color: '#f9f3ff',
    fontSize: '3rem',
    alignSelf: 'center',
    borderRadius: '10px'
  },
}