import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { ROOT_URL } from '../../../../redux/constants';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Typography,
  IconButton,
  FormGroup,
  TextField,
  MenuItem,
} from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import { Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { fetchLevels } from '../../../../redux/actions';
import baseStyle from '../../../../styles/wizardBase';

class AudioAsteroidsSkillDatabank extends Component {

  state = {
    tested: false,
    error: {},
    level: this.props.level.id ? this.props.level : {},
    selectedLevel: '',
    difficulty: 'Easy',
    timeMinToMax: 40,
    arcMovement: false,
    minMaxAngleArc: 0,
    repeat: false,
    bounce: false,
    requiredAccuracyAngle: 50,
    spawnRadius: 3,
    asteroidTimeout: -1,
    maxShotsPerAsteroid: 1,
    gameOverType: 1,
    gameOverCount: 120,
    leftRightLimited: false,
    leftRatio: 0.5,
    angleOptions: '',
    speed: 2,
  };

  componentDidMount() {
    this.props.fetchLevels();
    if (this.props.level.id) {
      const variables = JSON.parse(this.props.level.levelJson);
      const spawnRadius = variables.arcMovement ? variables.spawnRadius / 2 : variables.spawnRadius / 5;
      const speed = variables.arcMovement
        ? ((variables.minMaxAngleArc / 360 ) * 2 * Math.PI * variables.spawnRadius) / variables.timeMinToMax
        : (2 * variables.spawnRadius) / variables.timeMinToMax;
      this.setState({...variables, spawnRadius, speed, difficulty: this.props.level.difficulty || 'Easy', level: this.props.level, selectedLevel: this.props.level.id})
    }
    if (this.props.levelTemplate) {
      this.setState({ selectedLevel: this.props.levelTemplate.id })
    }
  }

  draw = (angle) => {
    const size = 50;
    const center = 25;
    const radius = 12.5;
    const longArc = (angle <= 180 ? 0 : 1);
    const radCircumference = Math.PI * 2;
    const percent = angle*2 / 360;
    const arc = percent * radCircumference;

    // calculates our final point relative to first point
    const x = Math.cos(arc) * radius;
    const y = Math.sin(arc) * radius;

    // Output string for SVG
    const d = [
      `M ${center},${center}`,
      `l ${radius}, 0`,
      `a${radius},${radius}`,
      `0 ${longArc},0`,
      `${x-radius},${-(y)}`,
      'z'
    ].join(' ');

    return (
      <svg viewBox={`0 0 ${size} 30`}>
        {/*player*/}
        <circle
          r={1}
          cx={center}
          cy={27.5}
          fill={'#383871'}
        />
        {/* asteroid */}
        <circle
          r={2}
          cx={center}
          cy={7.5}
          fill={'#45458D'}
        />
        {/* hit field (rotated to always point up) */}
        <g transform={`rotate(-${90-angle*2/2} ${center} ${center})`}>
          <path d={d} fill={'#310073'} stroke="black" strokeWidth={0.5}/>
        </g>
      </svg>
    );
  };


  close = () => {
    this.props.close();
    this.setState({
      tested: false,
      error: {},
      level: this.props.level || {},
      selectedLevel: '',
      difficulty: 'Easy',
      timeMinToMax: 40,
      arcMovement: false,
      minMaxAngleArc: 0,
      repeat: false,
      bounce: false,
      requiredAccuracyAngle: 10,
      spawnRadius: 20,
      asteroidTimeout: -1,
      maxShotsPerAsteroid: 1,
      gameOverType: 1,
      gameOverCount: 120,
      leftRightLimited: false,
      leftRatio: 0.5,
      angleOptions: '',
      speed: 2
    })
  };

  runTest = () => {
    const {
      level,
      tested,
      requiredAccuracyAngle,
      arcMovement,
      minMaxAngleArc,
      repeat,
      bounce,
      asteroidTimeout,
      maxShotsPerAsteroid,
      gameOverType,
      gameOverCount,
      leftRightLimited,
      leftRatio,
      angleOptions,
      speed
    } = this.state;
    const url = this.props.type === 'staff' ? `${ROOT_URL}/staff/${this.props.id}/objective-databank/test`: `${ROOT_URL}/admin/objective-databank/test`;
    if (tested) this.clearTest();
    const spawnRadius = arcMovement ? this.state.spawnRadius * 2 : this.state.spawnRadius * 5;
    const timeMinToMax = arcMovement
      ? ((minMaxAngleArc / 360 ) * 2 * Math.PI * spawnRadius) / speed
      : (2 * spawnRadius) / speed;
    axios.post(url, { levelVariables: JSON.stringify({
        requiredAccuracyAngle,
        spawnRadius,
        timeMinToMax: Math.round(timeMinToMax),
        arcMovement,
        minMaxAngleArc,
        repeat,
        bounce,
        asteroidTimeout,
        maxShotsPerAsteroid,
        gameOverType,
        gameOverCount,
        leftRightLimited,
        leftRatio,
        angleOptions
    }), templateId: level.id })
      .catch(err => console.log(err));
    this.setState({ tested: true });
  };

  clearTest = () => {
    const url = this.props.type === 'staff' ? `${ROOT_URL}/staff/${this.props.id}/objective-databank/test`: `${ROOT_URL}/admin/objective-databank/test`;
    axios.delete(url)
      .catch(err => console.log(err));
    this.setState({ tested: false })
  };

  handleChange = (ev) => {
    if (ev.target.name === 'arcMovement'){
      this.setState({ bounce: ev.target.value, repeat:  ev.target.value})
    }
    const value = ev.target.type === 'number'
      ? ev.target.value * 1 > 0 ? ev.target.value * 1 : ''
      : ev.target.value;
    this.setState({ [ev.target.name]: value })
  };

  handleLevelChange = (ev) => {
    const level = this.props.levels.find(level => level.id === ev.target.value);
    const variables = JSON.parse(level.levelVariables);
    this.setState({
      ...variables,
      level,
      selectedLevel: ev.target.value
    })
  };

  handleSliderChange = (ev, newVal, name) => {
    this.setState({ [name]: newVal })
  };

  checkForErrors = (ev) => {
    if (!ev.target.value){
      this.setState({ error: {...this.state.error, [ev.target.name]: true }})
    }
    else {
      let error = this.state.error;
      delete error[ev.target.name];
      this.setState({ error })
    }
  };

  gameOverSwitch = (classes) => {
    switch(this.state.gameOverType){
      case 1:
        return <Typography className={classes.configTitle} variant='h6'><FormattedMessage id='Wizard.Asteroids.gameOverCount.1' />: </Typography>;
      case 2:
        return (
          <Typography className={classes.configTitle} variant='h6'><FormattedMessage id='Wizard.Asteroids.gameOverCount.2' />: </Typography>
        );
      case 3:
        return <Typography className={classes.configTitle} variant='h6'><FormattedMessage id='Wizard.Asteroids.gameOverCount.3' />: </Typography>;
      case 4:
        return <Typography className={classes.configTitle} variant='h6'><FormattedMessage id='Wizard.Asteroids.gameOverCount.4' />: </Typography>;
      default:
        return;
    }
  };

  handleSubmit = (ev) => {
    ev.preventDefault();
    const {
      level,
      difficulty,
      requiredAccuracyAngle,
      arcMovement,
      minMaxAngleArc,
      repeat,
      bounce,
      asteroidTimeout,
      maxShotsPerAsteroid,
      gameOverType,
      gameOverCount,
      leftRightLimited,
      leftRatio,
      angleOptions,
      speed
    } = this.state;
    const spawnRadius = arcMovement ? this.state.spawnRadius * 3 : this.state.spawnRadius * 5;
    const timeMinToMax = arcMovement
      ? ((minMaxAngleArc / 360 ) * 2 * Math.PI * spawnRadius) / speed
      : (2 * spawnRadius) / speed;
    const levelJson = JSON.stringify({
      requiredAccuracyAngle,
      spawnRadius,
      timeMinToMax: Math.round(timeMinToMax),
      arcMovement,
      minMaxAngleArc,
      repeat,
      bounce,
      asteroidTimeout,
      maxShotsPerAsteroid,
      gameOverType,
      gameOverCount,
      leftRightLimited,
      leftRatio,
      angleOptions
    });
    this.props.onSubmit({ levelTemplateId: level.id, levelJson, difficulty });
  };

  render()  {
    const { classes, open, close, back, levels, game } = this.props;
    const { tested, level, error } = this.state;
    const gameLevels = levels.filter(level => level.gameId === game.id);
    const disabled = Object.keys(error).length > 0 || !this.state.level.id;
    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        open={open}
        onClose={close}
        aria-labelledby='skill-dialog-title'
        scroll='paper'
        PaperProps={{ classes:{ root: classes.container }}}
      >
        <DialogTitle disableTypography={true} className={ classes.title }>
          <Typography className={ classes.titleText } variant='h4' id='skill-dialog-title'><FormattedMessage id='Wizard.skillDB.title'/></Typography>
          <IconButton
            onClick={this.close}
            className={ classes.exitIcon }
            aria-label='close dialog button'
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant='h6'><FormattedMessage id='Wizard.skillDB.levelSelect'/>:</Typography>
          <FormGroup className={ classes.root }>
            <TextField
              select
              variant='outlined'
              label='Levels'
              name='level'
              onChange={this.handleLevelChange}
              value={this.state.selectedLevel}
            >
              {
                gameLevels.map(level => (<MenuItem key={level.id} value={level.id}> {level.name} </MenuItem>))
              }
            </TextField>
          </FormGroup>
          {level.id &&
          <div className={classes.level}>
            <FormGroup className={classes.configureLine}>
              <Typography className={classes.configTitle} variant='h6'><FormattedMessage id='General.description' />: </Typography>
              <br />
              <Typography className={classes.configHelp} variant='subtitle2'>{level.description}</Typography>
            </FormGroup>
            <FormGroup className={classes.arc}>
              <Typography className={classes.configTitle} variant='h6'><FormattedMessage id='Wizard.Asteroids.requiredAccuracyAngle' />: </Typography>
              <div className={classes.arcChart}>
                { this.draw(this.state.requiredAccuracyAngle) }
              </div>
              <Slider
                className={classes.arcSlider}
                value={this.state.requiredAccuracyAngle}
                onChange={(ev, newVal) => this.handleSliderChange(ev, newVal, 'requiredAccuracyAngle')}
                defaultValue={50}
                step={5}
                min={10}
                max={90}
              />
            </FormGroup>
            <FormGroup className={classes.arc}>
              <Typography gutterBottom className={classes.configTitle} variant='h6'><FormattedMessage id='Wizard.Asteroids.spawnRadius' />: </Typography>
              <div className={classes.sliderContainer}>
                <Typography variant='subtitle2'>
                  Smallest
                </Typography>
                <Slider
                  className={classes.slider}
                  value={this.state.spawnRadius}
                  onChange={(ev, newVal) => this.handleSliderChange(ev, newVal, 'spawnRadius')}
                  step={0.5}
                  min={2}
                  max={4}
                />
                <Typography variant='subtitle2'>
                  Largest
                </Typography>
              </div>
              <Typography variant='caption' className={classes.configHelp}>
                <FormattedMessage id='Wizard.Asteroids.spawnRadius.details'/>
              </Typography>
            </FormGroup>
            <FormGroup className={classes.arc}>
              <Typography gutterBottom className={classes.configTitle} variant='h6'><FormattedMessage id='Wizard.Asteroids.speed' />: </Typography>
              <div className={classes.sliderContainer}>
                <Typography variant='subtitle2'>
                  Slowest
                </Typography>
                <Slider
                  className={classes.slider}
                  value={this.state.speed}
                  onChange={(ev, newVal) => this.handleSliderChange(ev, newVal, 'speed')}
                  step={0.5}
                  min={0.5}
                  max={4}
                />
                <Typography variant='subtitle2'>
                  Fastest
                </Typography>
              </div>
            </FormGroup>
            {/*<FormGroup className={classes.configureLine}>*/}
            {/*  <Typography className={classes.configTitle} variant='h6'><FormattedMessage id='Wizard.Asteroids.arcMovement' />: </Typography>*/}
            {/*  <TextField*/}
            {/*    select*/}
            {/*    variant='outlined'*/}
            {/*    className={classes.configComponent}*/}
            {/*    name='arcMovement'*/}
            {/*    type='select'*/}
            {/*    value={this.state.arcMovement}*/}
            {/*    onChange={this.handleChange}*/}
            {/*  >*/}
            {/*    <MenuItem value={true}> <FormattedMessage id='General.yes'/> </MenuItem>*/}
            {/*    <MenuItem value={false}> <FormattedMessage id='General.no'/> </MenuItem>*/}
            {/*  </TextField>*/}
            {/*</FormGroup>*/}
            {/*{*/}
            {/*  this.state.arcMovement &&*/}
            {/*  <FormGroup className={classes.arc}>*/}
            {/*    <Typography gutterBottom className={classes.configTitle} variant='h6'><FormattedMessage id='Wizard.Asteroids.minMaxAngleArc' />: </Typography>*/}
            {/*    <div className={classes.sliderContainer}>*/}
            {/*      <Typography variant='subtitle2'>*/}
            {/*        Narrowest*/}
            {/*      </Typography>*/}
            {/*      <Slider*/}
            {/*        className={classes.slider}*/}
            {/*        value={this.state.minMaxAngleArc}*/}
            {/*        onChange={(ev, newVal) => this.handleSliderChange(ev, newVal, 'minMaxAngleArc')}*/}
            {/*        step={10}*/}
            {/*        min={0}*/}
            {/*        max={180}*/}
            {/*      />*/}
            {/*      <Typography variant='subtitle2'>*/}
            {/*        Widest*/}
            {/*      </Typography>*/}
            {/*    </div>*/}
            {/*    <Typography variant='caption' className={classes.configHelp}>*/}
            {/*      <FormattedMessage id='Wizard.Asteroids.minMaxAngleArc.details'/>*/}
            {/*    </Typography>*/}
            {/*  </FormGroup>*/}
            {/*}*/}
            <FormGroup className={classes.configureLine}>
              <Typography className={classes.configTitle} variant='h6'><FormattedMessage id='Wizard.Asteroids.maxShotsPerAsteroid' />: </Typography>
              <TextField
                variant='outlined'
                className={classes.configComponent}
                name='maxShotsPerAsteroid'
                type='number'
                error={error.maxShotsPerAsteroid}
                helperText={error.maxShotsPerAsteroid ? '* required' : ''}
                min={1}
                value={this.state.maxShotsPerAsteroid}
                onChange={this.handleChange}
                onBlur={this.checkForErrors}
              />
              <Typography variant='caption' className={classes.configHelp}>
                <FormattedMessage id='Wizard.Asteroids.maxShotsPerAsteroid.details' />
              </Typography>
            </FormGroup>
            <FormGroup className={classes.configureLine}>
              <Typography className={classes.configTitle} variant='h6'><FormattedMessage id='Wizard.Asteroids.gameOverType' />: </Typography>
              <TextField
                select
                variant='outlined'
                className={classes.configComponent}
                name='gameOverType'
                type='select'
                value={this.state.gameOverType}
                onChange={this.handleChange}
              >
                <MenuItem value={1}> <FormattedMessage id='Wizard.Asteroids.gameOverType.timeout'/> </MenuItem>
                <MenuItem value={2}> <FormattedMessage id='Wizard.Asteroids.gameOverType.limitedShots'/> </MenuItem>
                <MenuItem value={3}> <FormattedMessage id='Wizard.Asteroids.gameOverType.limitedAsteroids'/> </MenuItem>
                <MenuItem value={4}> <FormattedMessage id='Wizard.Asteroids.gameOverType.missed'/> </MenuItem>
              </TextField>
            </FormGroup>
            {
              this.state.gameOverType &&
              <FormGroup className={classes.configureLine}>
                {
                  this.gameOverSwitch(classes)
                }
                <TextField
                  variant='outlined'
                  className={classes.configComponent}
                  name='gameOverCount'
                  type='number'
                  helperText={error.gameOverCount ? '* required' : ''}
                  error={(this.state.gameOverType === 2 && this.state.maxShotsPerAsteroid > this.state.gameOverCount) || error.gameOverCount}
                  value={this.state.gameOverCount}
                  onChange={this.handleChange}
                  onBlur={this.checkForErrors}
                />
                {
                  this.state.gameOverType === 2 && this.state.maxShotsPerAsteroid > this.state.gameOverCount &&
                  <Typography variant='subtitle2' color='secondary' className={classes.configHelp}>
                    <FormattedMessage id='Wizard.Asteroids.gameOverCount.err'/>
                  </Typography>
                }
              </FormGroup>
            }
            <FormGroup className={classes.configureLine}>
              <Typography className={classes.configTitle} variant='h6'><FormattedMessage id='General.difficulty' />: </Typography>
              <TextField
                select
                variant='outlined'
                className={classes.configComponent}
                name='difficulty'
                value={this.state.difficulty}
                onChange={this.handleChange}
              >
                {
                  [
                    { value: 'Easy', text: <FormattedMessage id='General.easy' /> },
                    { value: 'Medium', text: <FormattedMessage id='General.medium' /> },
                    { value: 'Hard', text: <FormattedMessage id='General.hard' /> }
                  ].map(difficulty => (<MenuItem key={difficulty.value} value={difficulty.value}> {difficulty.text} </MenuItem>))
                }
              </TextField>
            </FormGroup>
          </div>
          }
        </DialogContent>
        <DialogActions className={classes.buttons}>
          <Button onClick={back} color='secondary'>
            <FormattedMessage id='General.back' />
          </Button>
          <Button
            onClick={ this.runTest }
            variant={ tested ? 'outlined' : 'text' }
            color={ tested ? 'secondary' : 'primary' }
          >
            { tested ? <FormattedMessage id='General.retest' /> : <FormattedMessage id='General.test' /> }
          </Button>
          <Button onClick={this.handleSubmit} disabled={disabled} color='primary'>
            <FormattedMessage id='General.next' />
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const  mapState = ({ platform: { levels }}) => {
  return {
    levels
  }
};

const mapDispatch = (dispatch) => {
  return {
    fetchLevels: () => dispatch(fetchLevels())
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(AudioAsteroidsSkillDatabank));