import {GET_ANALYSIS, GET_STORY, HANDLE_ERROR, ROOT_URL, GAME_URL} from '../../constants';


export const analysis = (state = [], action) => {
    switch (action.type) {
        case GET_ANALYSIS:
            return JSON.parse(action.speechData.analysis);
        default:
            return state;
    }
};

export const story = (state = [], action) => {
    switch (action.type) {
        case GET_STORY:
            return action.story;
        default:
            return state;
    }
}