export default {
  placeholder: {
    textAlign: 'center',
    margin: '20px 0',
    padding: '40px 0'
  },
  dashboard: {
    marginTop: '20px',
  },
  description: {
    marginBottom: '40px'
  },
  title: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '55px',
    marginBottom: '20px'
  },
  text: {
    marginLeft: '20px',
    alignSelf: 'center'
  },
  icon: {
    padding: '5px',
    backgroundColor: '#0087b6',
    color: '#f9f3ff',
    fontSize: '3rem',
    alignSelf: 'center',
    borderRadius: '10px'
  },
  spacing: {
    marginBottom: 20,
    marginTop: 10
  },
  loading: {
    margin: '0 auto',
    display: 'block',
    width: '75%',
  },
  container: {
    color: '#0a0a0a',
    overflow: 'wrap',
  },
  dialogTitle: {
    backgroundColor: '#383871',
    marginBottom: 15,
  },
  exitIcon: {
    float: 'right',
    color: '#fff',
  },
  dialog: {
    zIndex: 0,
    overflow: 'auto',
  },
  enrollTitle:{
    color:'#fff'
  }
}