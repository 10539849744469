import React, { Component } from 'react';
import { connect } from 'react-redux';
import SkillList from '../../components/Student/SkillList';
import {
  Typography,
  Divider,
  DialogContent,
  Grid,
  Slider,
  Switch,
  DialogActions,
  Button,
  DialogContentText, Dialog
} from '@material-ui/core';
import { CheckCircleOutlined } from '@material-ui/icons';
import SkillWizard from '../../components/Wizards/Skill/SkillWizardBase';
import { getGoalById, getSkillsForGoal, patchSkill } from '../../../redux/actions';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../styles/goal';

class GoalDatabank extends Component {

  state = {
    status: true,
    page: 1,
    open: false
  };

  componentDidMount(){
    this.props.getGoalById(this.props.id);
    this.props.getSkillsForGoal(this.props.id);
    if (this.props.skillDatabank) {
      const open = !!this.props.skillDatabank.id && this.props.history.location.search === '?guided';
      this.setState({ open })
    }
  }

  componentWillReceiveProps(nextProps, nextContext){
    if (nextProps.id !== this.props.id) {
      this.props.getGoalById(nextProps.id);
      this.props.getSkillsForGoal(nextProps.id);
    }
  }

  toggle = () => {

  };

  update = () => {
    const status = this.state.status ? 'Active' : 'Inactive';
    this.props.patchSkill(this.props.id, {...this.props.skillDatabank, status, targetPercentage: this.state.percentage });
    this.setState({ page: this.state.page+1})
  };

  close = () => {
    this.setState({ open: false });
    this.props.history.push(this.props.history.location.pathname.split('?')[0])
  };

  render(){
    const { goal, skills, id, classes } = this.props;
    return (
      <div style={{margin: '20px'}}>
        <>
          <div className={classes.title}>
            <CheckCircleOutlined className={classes.icon} fontSize='inherit'/>
            <Typography variant='h2' className={classes.text}><strong>{goal.name}</strong></Typography>
          </div>
          <Typography variant='h4' gutterBottom className={classes.description}>{goal.description}</Typography>
          <Divider />
          <br />
        </>
        <SkillList clickable={false} test={false} history={this.props.history} goal={goal} toggle={this.toggle} skills={skills}/>
        <SkillWizard id={id} goal={goal} isStaff={true}/>
        <Dialog open={this.state.open}>
          {
            this.state.page === 1 &&
            <>
              <DialogContent>
                <Typography gutterBottom align='center' variant='h6'>
                  We added that skill. Let's set a target percentage to master!
                </Typography>
                <Grid className={classes.spacing} container spacing={2}>
                  <Grid item>
                    <Typography> 0% </Typography>
                  </Grid>
                  <Grid item xs>
                    <Slider
                      align='center'
                      aria-label="target percentage"
                      value={this.state.percentage}
                      onChange={(ev, newVal) => this.setState({ percentage: newVal })}
                      defaultValue={30}
                      step={5}
                      min={0}
                      max={100}
                    />
                  </Grid>
                  <Grid item>
                    <Typography> 100% </Typography>
                  </Grid>
                </Grid>
                <Grid justify="center" container spacing={2}>
                  <Grid item>
                    <Typography variant='h6'>Would you like to activate this skill?</Typography>
                  </Grid>
                  <Grid item>
                    <Switch
                      checked={!!this.state.status}
                      onChange={() => this.setState({status: !this.state.status})}
                      name='status'
                      aria-label='toggle active or inactive'
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.update}>
                  Confirm
                </Button>
              </DialogActions>
            </>
          }
          {
            this.state.page === 2 &&
            <>
              <DialogContent>
                <DialogContentText align='center'>
                  We're all done! From this page you can reorder, edit and activate skills on this goal.
                  If you would like to add more skills just click the home button in the header.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.close}>
                  ok
                </Button>
              </DialogActions>
            </>
          }
        </Dialog>
      </div>
    )
  }
}

const mapState = ({ platform: { goal, skills, skillDatabank }}) => {
  return {
    goal,
    skills,
    skillDatabank
  }
};

const mapDispatch = (dispatch) => {
  return {
    getGoalById: (goalId) => dispatch(getGoalById(goalId)),
    getSkillsForGoal: (goalId) => dispatch(getSkillsForGoal(goalId)),
    patchSkill: (goalId, skill) => dispatch(patchSkill(goalId, skill))
  }
};

export default withStyles(baseStyle)(connect(mapState, mapDispatch)(GoalDatabank));