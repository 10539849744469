import {
  GET_GROUP,
  FETCH_GROUPS_FOR_PROFILE,
  FETCH_ALL_GROUPS,
  CREATE_GROUP,
  JOIN_GROUP,
  UPDATE_GROUP,
  CLEAR_GROUP
} from '../../constants';

export const groups = (state = [], action) => {
  switch (action.type){
    case FETCH_ALL_GROUPS:
      return action.groups;
    case CREATE_GROUP:
      return [...state, action.group];
    case UPDATE_GROUP:
      return state.map(group => group.key === action.group.key ? action.group : group);
    default:
      return state;
  }
};

export const myGroups = (state = [], action) => {
  switch (action.type){
    case FETCH_GROUPS_FOR_PROFILE:
      return action.groups;
    case JOIN_GROUP:
      return [...state, action.group];
    case CREATE_GROUP:
      return [...state, action.group];
    default:
      return state;
  }
};

export const group = (state = {}, action) => {
  switch (action.type){
    case GET_GROUP:
      return action.group;
    case UPDATE_GROUP:
      return action.group;
    case CLEAR_GROUP:
      return {};
    default:
      return state;
  }
};