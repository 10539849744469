import React, { Component } from 'react';
import { connect } from 'react-redux';
import SkillList from '../../components/Student/SkillList';
import {
  Typography,
  Card,
  Divider,
  Dialog,
  DialogContentText,
  DialogContent,
  Slider,
  DialogActions,
  Button, Switch,
  Grid
} from '@material-ui/core';
import { CheckCircleOutlined } from '@material-ui/icons';
import {
  getGoalById,
  getSkillsForGoal,
  getScoreCharts,
  fetchStaffBrailleSheets,
  patchSkill,
  getStudentProfile
} from '../../../redux/actions';
import SkillWizard from '../../components/Wizards/Skill/SkillWizardBase';
import SkillDashboard from '../Dashboards/SkillDashboard';
import { withStyles } from '@material-ui/core/styles/index';
import baseStyle from '../../../styles/goal';
import { FormattedMessage } from 'react-intl';
import GoalBrailleSheetsList from '../../components/Student/GoalBrailleSheetsList';

class Goal extends Component {
  constructor(props){
    super(props);
    this.state={
      toggleCharts: false,
      skill: this.props.skills[0] || {},
      percentage: 50,
      status: true,
      page: 1,
      open: false
    };
  }

  componentDidMount(){
    if (this.props.user.id) {
      this.props.fetchStaffBrailleSheets(this.props.user.staffId);
    }
    this.props.getSkillsForGoal(this.props.id);
    this.props.getGoalById(this.props.id);
    this.props.getStudentProfile(this.props.id);
    this.props.getStudentProfile(this.props.studentId);
    if (this.props.skills[0]) this.props.getScoreCharts(this.props.skills[0], this.props.id);
    if (this.props.skillDatabank) {
      const open = !!this.props.skillDatabank.id && this.props.history.location.search === '?guided';
      this.setState({ open })
    }
  }

  componentWillReceiveProps(nextProps, nextContext){
    if (this.props.user.id !== nextProps.user.id) {
      this.props.fetchStaffBrailleSheets(nextProps.user.staffId);
    }
    if (nextProps.id !== this.props.id) {
      this.props.getGoalById(nextProps.id);
      this.props.getSkillsForGoal(nextProps.id);
      this.setState({ toggleCharts: false });
      this.props.getStudentProfile(nextProps.id);
      this.props.getScoreCharts(this.state.skill, nextProps.student.id);
    }
  }

  toggle = (skill) => {
    if (skill) {
      this.props.getScoreCharts(skill, this.props.student.id);
      this.setState({ skill, toggleCharts: skill.id === this.state.skill.id ? !this.state.toggleCharts : true  })
    }
  };

  update = () => {
    const status = this.state.status ? 'Active' : 'Inactive';
    this.props.patchSkill(this.props.id, {...this.props.skillDatabank, status, targetPercentage: this.state.percentage });
    this.setState({ page: this.state.page+1})
  };

  close = () => {
    this.setState({ open: false })
    this.props.history.push(this.props.history.location.pathname.split('?')[0])
  };

  render(){
    const { goal, skills, id, student, charts, classes, staffBrailleSheets } = this.props;
    const { toggleCharts } = this.state;
    return (
        <div style={{marginLeft: '60px', marginRight: '20px'}}>
          <>
            <div className={classes.title}>
              <CheckCircleOutlined className={classes.icon} fontSize='inherit'/>
              <Typography variant='h2' className={classes.text}><strong>{goal.name}</strong></Typography>
            </div>
            <Typography variant='h4' gutterBottom className={classes.description}>{goal.description}</Typography>
            <Divider />
            <br />
          </>
          {
            toggleCharts
                ? <SkillDashboard className={classes.dashboard} charts={this.props.charts} id={student.id}/>
                : <Card className={classes.placeholder}><Typography variant='h6'> <FormattedMessage id='Goal.Chart.empty'/></Typography></Card>
          }
          <SkillList history={this.props.history} test toggled={this.state.toggleCharts} toggle={this.toggle} goal={goal} skills={skills}/>
          <SkillWizard studentProfile={this.props.studentProfile} id={id} goal={goal}/>
          <GoalBrailleSheetsList skills={skills} brailleSheets={staffBrailleSheets}/>
          <Dialog open={this.state.open}>
            {
              this.state.page === 1 &&
              <>
                <DialogContent>
                  <Typography gutterBottom align='center' variant='h6'>
                    We added that skill. Let's set a target percentage to master!
                  </Typography>
                  <Grid className={classes.spacing} container spacing={2}>
                    <Grid item>
                      <Typography> 0% </Typography>
                    </Grid>
                    <Grid item xs>
                      <Slider
                          align='center'
                          aria-label="target percentage"
                          value={this.state.percentage}
                          onChange={(ev, newVal) => this.setState({ percentage: newVal })}
                          defaultValue={30}
                          step={5}
                          min={0}
                          max={100}
                      />
                    </Grid>
                    <Grid item>
                      <Typography> 100% </Typography>
                    </Grid>
                  </Grid>
                  <Grid justify="center" container spacing={2}>
                    <Grid item>
                      <Typography variant='h6'>Would you like to activate this skill?</Typography>
                    </Grid>
                    <Grid item>
                      <Switch
                          checked={!!this.state.status}
                          onChange={() => this.setState({status: !this.state.status})}
                          name='status'
                          aria-label='toggle active or inactive'
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.update}>
                    Confirm
                  </Button>
                </DialogActions>
              </>
            }
            {
              this.state.page === 2 &&
              <>
                <DialogContent>
                  <DialogContentText align='center'>
                    We're all done! From this page you can reorder, edit and activate skills on this goal.
                    If you would like to add more skills just click the home button in the header.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.close}>
                    ok
                  </Button>
                </DialogActions>
              </>
            }
          </Dialog>
        </div>
    )
  }
}

const mapState = ({ platform: { goal, skills, student, charts, staffBrailleSheets, skillDatabank, studentProfile }, shared: { user }}) => {
  return {
    goal,
    skills,
    student,
    charts,
    staffBrailleSheets,
    skillDatabank,
    studentProfile,
    user,
  }
};

const mapDispatch = (dispatch, { history }) => {
  return {
    getGoalById: (goalId) => dispatch(getGoalById(goalId)),
    getSkillsForGoal: (goalId) => dispatch(getSkillsForGoal(goalId, history)),
    getScoreCharts: (skill, studentId) => dispatch(getScoreCharts(skill, studentId)),
    fetchStaffBrailleSheets: (staffId) => dispatch(fetchStaffBrailleSheets(staffId)),
    patchSkill: (goalId, skill) => dispatch(patchSkill(goalId, skill)),
    getStudentProfile: (studentId) => dispatch(getStudentProfile(studentId))
  }
};

export default withStyles(baseStyle)(connect(mapState, mapDispatch)(Goal));
