import React, { Component } from 'react';
import moment from 'moment';
import { Description, CheckCircle, Cancel, RadioButtonUnchecked, ExpandMore } from '@material-ui/icons';
import { Card, CardContent, Divider, Typography, ExpansionPanelDetails, ExpansionPanelSummary, ExpansionPanel, withStyles } from '@material-ui/core';
import baseStyle from '../../../styles/brailleChart';

class BrailleSheetsChart extends Component {
  render() {
    const { data, classes } = this.props;
    return (
      <Card className={classes.brailleChartCard} aria-label='braille-sheets-chart'>
        <div className={classes.header}>
          <Description className={classes.icon}/>
          <Typography className={classes.chartTitle} variant='h4'>Braille Sheets Playthroughs</Typography>
        </div>
        <Divider />
        <CardContent className={classes.brailleChart}>
          {
            data.length > 0
            ? <div>
              {
                data.map(chart => (
                  <ExpansionPanel className={classes.card}>
                    <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                      <div className={classes.info}>
                        <Typography variant='h6'>Game:</Typography> <Typography variant='body1'>{chart.game}</Typography>
                        <Typography variant='h6'>Skill:</Typography> <Typography variant='body1'>{chart.skill}</Typography>
                        <Typography variant='h6'>Date:</Typography> <Typography variant='body1'>{moment.unix(chart.time.epochSecond).format('M/D h:MM')}</Typography>
                        <Typography variant='h6'>Score:</Typography> <Typography variant='body1'> {chart.score} </Typography>
                      </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.questions}>
                      <Typography gutterBottom variant='h5'>Questions: </Typography>
                      {chart.questionList.map((question, index) => (
                        <div>
                          <Typography variant='h6'>
                            {index+1}. {question.question[question.question.length-1] === '?' ? question.question : `${question.question}?` }
                          </Typography>
                          <div>
                            {
                              question.possibleAnswers.map(answer => {
                                return (
                                  <div className={classes.answers}>
                                    {
                                      answer === question.answer
                                        ? question.correct
                                          ? <Typography className={classes.answer} variant='subtitle2'>
                                              <CheckCircle htmlColor={'green'}/> {answer}
                                            </Typography>
                                          : <Typography className={classes.answer} variant='subtitle2'>
                                              <Cancel color='secondary'/> {answer}
                                            </Typography>
                                        : <Typography className={classes.answer} variant='subtitle2'>
                                            <RadioButtonUnchecked /> {answer}
                                          </Typography>
                                    }
                                  </div>
                                )
                              })
                            }
                            {
                              !question.possibleAnswers.includes(question.answer) &&
                              <div className={classes.answers}>
                                <Typography  className={classes.answer} variant='subtitle2'>
                                  <Cancel color='secondary'/> {question.answer ? `Student Answered: ${question.answer}` : 'Student did not answer'}
                                </Typography>
                              </div>
                            }
                          </div>
                        </div>
                      ))}
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                ))
              }
              </div>
            : <Typography className={classes.placeholder} variant='h6'> There is not enough data yet, please play more games in this skill...</Typography>
          }
        </CardContent>
      </Card>
    );
  }
}


export default withStyles(baseStyle)(BrailleSheetsChart);