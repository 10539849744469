import {
  FETCH_BRAILLE_SHEETS,
  CREATE_BRAILLE_SHEET,
  DELETE_BRAILLE_SHEET,
  UPDATE_BRAILLE_SHEET,
  FETCH_QUESTIONS,
  FETCH_STAFF_BRAILLE_SHEEETS,
  CREATE_STAFF_BRAILLE_SHEET,
  DELETE_STAFF_BRAILLE_SHEET
} from '../../constants';

export const brailleSheets = (state = [], action) => {
  switch (action.type){
    case FETCH_BRAILLE_SHEETS:
      return action.brailleSheets;
    case CREATE_BRAILLE_SHEET:
      return [...state, action.brailleSheet];
    case UPDATE_BRAILLE_SHEET:
      return state.map(sheet => sheet.id === action.brailleSheet.id ? action.brailleSheet : sheet);
    case DELETE_BRAILLE_SHEET:
      return state.filter(sheet => sheet.id !== action.brailleSheet.id);
    default:
      return state;
  }
};

export const questions = (state = [], action) => {
  switch (action.type){
    case FETCH_QUESTIONS:
      return action.questions;
    default:
      return state;
  }
};

export const staffBrailleSheets = (state = [], action) => {
  switch (action.type){
    case FETCH_STAFF_BRAILLE_SHEEETS:
      return action.brailleSheets;
    case CREATE_STAFF_BRAILLE_SHEET:
      return [...state, action.brailleSheet];
    case DELETE_STAFF_BRAILLE_SHEET:
      return state.filter(sheet => sheet.id !== action.brailleSheet.id);
    default:
      return state;
  }
};