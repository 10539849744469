import React, { useState, useEffect } from 'react';
import { Box, Button, Dialog, IconButton, Paper, withStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import styles from '../../../styles/importSkill.js';
import { FormattedMessage } from 'react-intl';

function LoadingModal({ classes, openLoading, type, exitFunc }) {
    const [h1Text, setH1Text] = useState('');
    const [h4Text, setH4Text] = useState('');

    useEffect(() => {
        switch (type) {
            case 'Loading':
                setH1Text('Your book is being imported now.');
                setH4Text('This may take a few minutes. You can close this window at any time.');
                break;
            case 'Downloading':
                setH4Text('We have started importing your book.  This may take 24-48 hours to finish.  Once the book has been processed it will appear on your student\'s books page, you do not need to complete any other actions');
                setH1Text('');
                break;
            case 'DownloadFail':
                setH4Text('Looks like you have an import request already in progress.  We will send you a message when it is complete.');
                setH1Text('');
                break;

            default:
                setH1Text('Your book is being imported now.');
                setH4Text('This may take a few minutes. You can close this window at any time.');
                break;
        }
    }, [type]);

    return (
        <Dialog open={openLoading} maxWidth='xl'>
            <Paper className={classes.importLoadingPage}>
                <IconButton
                    onClick={() => { exitFunc() }}
                    className={classes.exitIcon}
                    aria-label="close dialog"
                >
                    <Close />
                </IconButton>
                <div className={classes.loadingText}>
                    {h1Text && (
                        <h1>
                            {h1Text}
                        </h1>
                    )}
                    {h4Text && (
                        <h4>
                            {h4Text}
                        </h4>
                    )}
                </div>
                <div className={classes.loadingBtnContainer} >
                    <Button color='primary' variant='contained' onClick={()=>{exitFunc()}}>
                        <FormattedMessage id='General.done' />
                    </Button>
                </div>
            </Paper>
        </Dialog>
    )
}

export default withStyles(styles)(LoadingModal);