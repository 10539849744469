import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import {
  Button,
  FormGroup,
  Typography,
  IconButton,
  TextField,
  MenuItem,
  Divider,
  withStyles,
} from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Cancel, Close } from '@material-ui/icons';
import { ROOT_URL } from '../../../../redux/constants';
import baseStyle from '../../../../styles/wordHunt';

class CellTutorPROTO extends Component {

  state = {
    roundCount: 1,
    maxGuesses: 1,
    shuffle: false,
    difficulty: 'Easy',
    tested: false,
    puzzles: [{text: ['', '', '']}],
    error: {}
  };

  componentDidMount() {
    if (this.props.level) {
      const variables = JSON.parse(this.props.level.levelJson);
      this.setState({
        ...variables,
        difficulty: this.props.level.difficulty,
      })
    }
  };

  close = () => {
    this.props.close();
  };

  handleChange = (ev) => {
    const value = ev.target.type === 'number' ? ev.target.value > 0 ? ev.target.value * 1 : '' : ev.target.value;
    this.setState({ [ev.target.name]: value })
  };

  addPuzzle = () => {
    this.setState({ puzzles: [...this.state.puzzles, {text: ['', '', '']}]})
  };

  changePuzzle = (ev, index, puzzIndex) => {
    const puzzles = this.state.puzzles;
    puzzles[index].text[puzzIndex] = ev.target.value;
    this.setState({ puzzles })
  };

  removePuzzle = (index) => {
    let puzzles = this.state.puzzles;
    if (puzzles.length > 1) {
      puzzles.splice(index, 1);
    }
    else {
      puzzles = [{text: ['', '', '']}];
    }
    this.setState({ puzzles })
  };

  runTest = () => {
    const { tested, roundCount, shuffle, maxGuesses } = this.state;
    const puzzleMap = this.state.puzzles.map(puzzle => ({ text: puzzle.text.filter(character => !!character)}));
    const puzzles = puzzleMap.filter(puzzle => puzzle.text.length );
    const url = this.props.type === 'staff'
      ? `${ROOT_URL}/staff/${this.props.id}/objective-databank/test`
      : `${ROOT_URL}/admin/objective-databank/test`;
    if (tested) this.clearTest();
    axios.post(url, {
      levelVariables: JSON.stringify({
        roundCount,
        shuffle,
        puzzles,
        maxGuesses
      }),
      templateId: '-1818879770568822984'
    }).catch(err => console.log(err));
    this.setState({ tested: true });
  };

  clearTest = () => {
    const url = this.props.type === 'staff'
      ? `${ROOT_URL}/staff/${this.props.id}/objective-databank/test`
      : `${ROOT_URL}/admin/objective-databank/test`;
    axios.delete(url)
      .catch(err => console.log(err));
    this.setState({ tested: false })
  };

  onSubmit = () => {
    const { difficulty, roundCount, shuffle, maxGuesses } = this.state;
    const puzzleMap = this.state.puzzles.map(puzzle => ({ text: puzzle.text.filter(character => !!character)}));
    const puzzles = puzzleMap.filter(puzzle => puzzle.text.length );
    const levelJson = JSON.stringify({
      maxGuesses,
      roundCount,
      shuffle,
      puzzles
    });
    const skill = { levelJson, difficulty, levelTemplateId: '-1818879770568822984' };
    this.props.onSubmit(skill);
  };

  checkPhrase = (index) => {
    if (this.state.puzzles[index].text === '') {
      this.setState({ error: {...this.state.error, [index]: true }})
    }
    else {
      let error = this.state.error;
      delete error[index];
      this.setState({ error })
    }
  };

  render() {
    const { classes } = this.props;
    const { tested } = this.state;
    return (
      <>
        <Dialog
          maxWidth='md'
          fullWidth
          disableEscapeKeyDown
          open={this.props.open}
          onClose={this.props.close}
          aria-labelledby='form-dialog-title'
          PaperProps={{ classes: { root: classes.container } }}
        >
          <DialogTitle disableTypography={true} className={classes.title} id='form-dialog-title'>
            <Typography className={classes.titleText} variant='h4'>
              <FormattedMessage id='Wizard.skillDB.title'/>
            </Typography>
            <IconButton
              onClick={this.close}
              className={classes.exitIcon}
              aria-label="close dialog"
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography gutterBottom variant='h3'>
              Cell Tutor
            </Typography>
            <Typography className={classes.description} variant='subtitle1'>
              A game using braille displays to teach individual braille cells.
            </Typography>
            <Divider className={classes.divider}/>
            <FormGroup className={classes.configureLine}>
              <Typography variant='h6'>Number of Rounds: </Typography>
              <TextField
                variant='outlined'
                className={classes.configComponent}
                name='roundCount'
                type='number'
                value={this.state.roundCount}
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup className={classes.configureLine}>
              <Typography variant='h6'>Max Guesses: </Typography>
              <TextField
                variant='outlined'
                className={classes.configComponent}
                name='maxGuesses'
                type='number'
                value={this.state.maxGuesses}
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup className={classes.configureLine}>
              <Typography variant='h6'>Should they be shuffled? </Typography>
              <TextField
                select
                variant='outlined'
                className={classes.configComponent}
                name='shuffle'
                value={this.state.shuffle}
                onChange={this.handleChange}
              >
                <MenuItem value={true}> Yes </MenuItem>
                <MenuItem value={false}> No </MenuItem>
              </TextField>
            </FormGroup>
            <FormGroup className={classes.configureLine}>
              <Typography variant='h6'>
                <FormattedMessage id='General.difficulty'/>:
              </Typography>
              <TextField
                select
                variant='outlined'
                id='difficulty-select'
                name='difficulty'
                value={this.state.difficulty}
                onChange={this.handleChange}
              >
                {
                  [
                    { value: 'Easy', text: <FormattedMessage id='General.easy'/> },
                    { value: 'Medium', text: <FormattedMessage id='General.medium'/> },
                    { value: 'Hard', text: <FormattedMessage id='General.hard'/> }
                  ].map((option) => <MenuItem key={option.value} value={option.value}>{option.text}</MenuItem>)
                }
              </TextField>
            </FormGroup>
            <FormGroup>
              <Typography className={classes.phraseTitle} variant='h6'>Puzzles to use in game (up to three characters per puzzle, one per box): </Typography>
              {
                this.state.puzzles.map((puzzle,index) => (
                  <FormGroup key={`${index}`} className={classes.configureLine}>
                    <div className={classes.puzzle}>
                      <IconButton className={classes.phraseBtn} onClick={() => this.removePuzzle(index)}>
                        <Cancel color='secondary'/>
                      </IconButton>
                      <TextField
                        maxLength={1}
                        variant='outlined'
                        error={this.state.error[index]}
                        helperText={this.state.error[index] ? 'Character cannot be blank' : ''}
                        className={classes.configComponent}
                        name='puzzles'
                        value={this.state.puzzles[index].text[0]}
                        onChange={(ev) => this.changePuzzle(ev, index, 0)}
                        onBlur={() => this.checkPhrase(index)}
                      />
                      <TextField
                        maxLength={1}
                        variant='outlined'
                        className={classes.configComponent}
                        name='puzzles'
                        value={this.state.puzzles[index].text[1]}
                        onChange={(ev) => this.changePuzzle(ev, index, 1)}
                      />
                      <TextField
                        maxLength={1}
                        variant='outlined'
                        className={classes.configComponent}
                        name='puzzles'
                        value={this.state.puzzles[index].text[2]}
                        onChange={(ev) => this.changePuzzle(ev, index, 2)}
                      />
                    </div>
                  </FormGroup>
                ))
              }
            </FormGroup>
            <div className={classes.questionBtns}>
              <Button
                variant='contained'
                id='add-question'
                color='primary'
                classes={{ root: classes.addButton }}
                onClick={this.addPuzzle}
              >
                Add Puzzle
              </Button>
            </div>
          </DialogContent>
          <DialogActions className={classes.buttons}>
            <Button id='back' onClick={this.props.back} color='secondary'>
              <FormattedMessage id='General.back'/>
            </Button>
            <Button
              onClick={this.runTest}
              variant={tested ? 'outlined' : 'text'}
              color={tested ? 'secondary' : 'primary'}
            >
              {tested ? <FormattedMessage id='General.retest'/> : <FormattedMessage id='General.test'/>}
            </Button>
            <Button
              disabled={Object.keys(this.state.error).length > 0 || this.state.puzzles.some(_puzzle => _puzzle.text.every(puzzle => puzzle === ''))}
              aria-label='go to final page of skill creator'
              onClick={this.onSubmit}
              color='primary'
            >
              <FormattedMessage id='General.next'/>
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default withStyles(baseStyle)(CellTutorPROTO);