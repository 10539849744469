export const FETCH_ALL_ROLES = 'FETCH_ALL_ROLES';
export const GET_ROLE = 'GET_ROLE';
export const CREATE_ROLE = 'CREATE_ROLE';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const DELETE_ROLE = 'DELETE_ROLE';
export const FETCH_ALL_LIST_CONFIGS = 'FETCH_ALL_LIST_CONFIGS';
export const FETCH_ALL_LIST_GROUPS = 'FETCH_ALL_LIST_GROUPS';
export const FETCH_ALL_GAME_GROUPS = 'FETCH_ALL_GAME_GROUPS';
export const CREATE_LIST_CONFIG = 'CREATE_LIST_CONFIG';
export const CREATE_GAME_FIELD_MAP = 'CREATE_GAME_FIELD_MAP';
export const FETCH_ALL_GAME_FIELD_MAPS = 'FETCH_ALL_GAME_FIELD_MAPS';