import { Card, CardContent, CardHeader, Dialog, IconButton, Paper, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";
const BookShareRequestModal = ({ open, close }) => {
    return (
        <>
            <Dialog open={open} onClose={close}>
                <Paper>
                    <Card>
                        <CardHeader style={{ backgroundColor: '#00729b', marginBottom: '20px' }} action={
                            <IconButton onClick={close}>
                                <Close />
                            </IconButton>}
                            title={<Typography variant='h5' className='header'>Bookshare Request</Typography>}
                        >
                        </CardHeader>
                        <CardContent>
                            <div style={{ textAlign: 'center', fontFamily: "Calibri, sans-serif", fontSize: "large" }}>
                                Looks like you already have two requests for new books from Bookshare.
                                Please wait for us to finish processing these requests before making more.
                                <br />
                                We generally process requests in 24-48 hours.
                            </div>
                        </CardContent>
                    </Card>
                </Paper>
            </Dialog>
        </>
    )
}

export default BookShareRequestModal;