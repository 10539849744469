import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

// Shared
import { supportUsers, user, importBooks, bookShareIdentity } from './reducers/user';
import error from './reducers/errors';
import { account, accounts, billing, buddyBookSubscription, updateLicenseAmount, suspendSubscription, checkSuspension } from './reducers/account';
import { helpPages, onlineUsers, messages } from './reducers/help';

// Platform
import { staff, staffMember, staffStudents, staffProfile, staffRoles, staffDashboard, staffDashboardReadingTime, staffDashboardAccuracy } from './reducers/platform/staff';
import specialists from './reducers/platform/specialists';
import districts from './reducers/platform/districts';
import { books, bookList, storyList, bookShareList, downloadBook, databank, booksImport, bookShareUsers, bookShareRequestBooks } from './reducers/platform/books';
import speech from "./reducers/platform/speech";
import schools from './reducers/platform/schools';
import { analysis, story } from './reducers/platform/buddyBooks';
import { students, student, deletedStudents, studentProfile, studentTutorials, studentStaff, studentBooksDetails } from './reducers/platform/students';
import { guardians, guardian } from './reducers/platform/guardians';
import roles from './reducers/platform/roles';
import { forms, form } from './reducers/platform/forms';
import { goals, goal } from './reducers/platform/goals';
import { skillDatabanks, staffSkillDatabanks, skillDatabank, storyDataConfig, storyDataSections } from './reducers/platform/skillDatabanks';
import { goalDatabanks, staffGoalDatabanks } from './reducers/platform/goalDatabanks';
import skills from './reducers/platform/skills';
import levels from './reducers/platform/levels';
import { games, studentGames } from './reducers/platform/games';
import { charts, weeklySummaries } from './reducers/platform/charts';
import listConfigs from './reducers/platform/listConfigs';
import gameFieldMaps from './reducers/platform/gameFieldMaps';
import { brailleSheets, questions, staffBrailleSheets } from './reducers/platform/brailleSheets';
import { listGroups, gameGroups } from './reducers/platform/groups';
import { speechData, models, model, trainingTypes } from './reducers/platform/speech';
import sliderList from './reducers/platform/sliderList';
import sliderSeries from './reducers/platform/sliderSeries';

// Social
import { groups, group, myGroups } from './reducers/social/groups';
import { profile, profiles } from './reducers/social/profiles';
import { post, posts, feed, tags } from './reducers/social/posts';
import { notifications } from './reducers/social/notifications';
import { goals as socialGoals, myGoals } from './reducers/social/goals';
import { myBrailleSheets } from './reducers/social/brailleSheets';
import { course } from './reducers/social/course';

const platform = combineReducers({
    speechData,
    models,
    model,
    trainingTypes,
    staff,
    course,
    staffMember,
    staffProfile,
    staffStudents,
    staffRoles,
    staffDashboard,
    staffDashboardAccuracy,
    staffDashboardReadingTime,
    students,
    analysis,
    story,
    deletedStudents,
    studentProfile,
    studentTutorials,
    studentStaff,
    studentBooksDetails,
    student,
    guardians,
    guardian,
    specialists,
    supportUsers,
    districts,
    schools,
    roles,
    books,
    booksImport,
    bookShareUsers,
    bookShareRequestBooks,
    bookList,
    storyList,
    bookShareList,
    databank,
    downloadBook,
    goals,
    goal,
    goalDatabanks,
    staffGoalDatabanks,
    skillDatabanks,
    skillDatabank,
    staffSkillDatabanks,
    storyDataConfig,
    storyDataSections,
    skills,
    levels,
    brailleSheets,
    staffBrailleSheets,
    questions,
    games,
    studentGames,
    charts,
    weeklySummaries,
    forms,
    singleForm: form,
    listConfigs,
    listGroups,
    gameFieldMaps,
    gameGroups,
    importBooks,
    sliderList,
    sliderSeries
});

const social = combineReducers({
    group,
    groups,
    goals: socialGoals,
    myGoals,
    myGroups,
    profile,
    profiles,
    post,
    posts,
    tags,
    feed,
    notifications,
    myBrailleSheets
});

const shared = combineReducers({
    account,
    accounts,
    billing,
    user,
    bookShareIdentity,
    error,
    helpPages,
    onlineUsers,
    messages,
    buddyBookSubscription,
    updateLicenseAmount,
    suspendSubscription,
    checkSuspension
});

const rootReducer = combineReducers({ platform, shared, social });

const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

const store = reduxDevTools
    ? createStore(rootReducer, reduxDevTools(applyMiddleware(thunk)))
    : createStore(rootReducer, applyMiddleware(thunk));

export default store;