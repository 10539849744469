import { FETCH_NOTIFICATIONS, CREATE_NOTIFICATION, DELETE_NOTIFICATION, UPDATE_NOTIFICATION } from '../../constants';

export const notifications = (state = [], action) => {
  switch (action.type){
    case FETCH_NOTIFICATIONS:
      return action.notifications;
    case CREATE_NOTIFICATION:
      return [...state, action.notification];
    case DELETE_NOTIFICATION:
      return state.filter(notification => notification.id !== action.notification.id);
    case UPDATE_NOTIFICATION:
      return state.map(notification => notification.id === action.notification.id ? action.notification : notification);
    default:
      return state;
  }
};