import { FETCH_NOTIFICATIONS, CREATE_NOTIFICATION, UPDATE_NOTIFICATION } from '../../constants';

export const fetchNotifications = (notifications) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_NOTIFICATIONS,
      notifications
    })
  }
};

export const createNotification = (notification) => {
  return (dispatch) => {
    dispatch({
      type: CREATE_NOTIFICATION,
      notification
    })
  }
};

export const updateNotification = (notification) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_NOTIFICATION,
      notification
    })
  }
};