import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import EntertainmentGameWizardPage1 from './EntertainmentGameWizardPage1';
import WizardToggleButton from "../WizardToggleButton";
import { addGameToStudent } from '../../../../redux/actions';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../../styles/wizardBase';

class EntertainmentGameWizardBase extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      open: false,
      page: 1,
      game: ''
    }
  }

  openWizard = () => {
    this.setState({ open: true })
  };

  closeWizard = () => {
    this.setState({ open: false, page: 1, game: '' });
    if (this.props.close) this.props.close();
  };

  next = () => {
    this.setState({ page: this.state.page+1 });
  };

  back = () => {
    this.setState({ page: this.state.page-1 });
  };

  goToPage = (page) => {
    this.setState({ page });
  };

  submit = () => {
    const game = this.props.games.find(game => game.id === this.state.game);
    this.props.addGameToStudent(game, this.props.student.id);
    this.closeWizard();
  };

  handleChange = (ev) => {
    this.setState({ [ev.target.name]: ev.target.value })
  };

  render(){
    const { page } = this.state;
    const open = this.props.open ? this.props.open : this.state.open;
    const game = this.props.games.find(game => game.id === this.state.game);
    return (
      <div id='goal-wizard'>
        {
          page === 1 &&
          <EntertainmentGameWizardPage1
            open={open}
            games={this.props.games}
            game={game}
            student={this.props.student}
            close={this.closeWizard}
            next={this.next}
            submit={this.submit}
            handleChange={this.handleChange}
          />
        }
        {
          !this.props.edit &&
          <WizardToggleButton
            openWizard={this.openWizard}
            text={<FormattedMessage id='Wizard.Game.add'/>}
          />
        }
      </div>
    )
  }
}

const mapState = ({ platform: { student, games }}) => {
  return {
    student,
    games
  }
};

const mapDispatch = (dispatch, { history }) => {
  return {
    addGameToStudent: (game, studentId) => dispatch(addGameToStudent(game, studentId, history))
  }
};

export default connect(mapState, mapDispatch)(withStyles(styles)(EntertainmentGameWizardBase));