import {
  ROOT_URL,
  FETCH_ALL_GOALS,
  CREATE_GOAL,
  GET_GOAL,
  GET_SKILLS_FOR_GOAL,
  DELETE_GOAL,
  HANDLE_ERROR,
  CREATE_STAFF_GOAL_DATABANK, UPDATE_GOAL
} from '../../constants';
import axios from 'axios';

export const fetchGoals = (formId, history) => {
  const url =`${ROOT_URL}/goals?formId=${formId}`;
  return (dispatch) => {
    axios
      .get(url)
      .then(response => {
        if (typeof(response.data) === 'object'){
          dispatch({
            type: FETCH_ALL_GOALS,
            goals: response.data
          })
        }
        else {
          history.push('/login');
          dispatch({
            type: HANDLE_ERROR,
            error: {type: 'sessionExp', msg: 'Your session has expired, please log back in'}
          })
        }
      })
      .catch(err => console.log(err))
  }
};

export const getGoalById = (goalId) => {
  const url = `${ROOT_URL}/goals/${goalId}`;
  return (dispatch) => {
    axios
      .get(url)
      .then(response => {
        dispatch({
          type: GET_GOAL,
          goal: response.data
        })
      })
      .catch(err => console.log(err))
  }
};

export const getSkillsForGoal = (goalId, history) => {
  const url =`${ROOT_URL}/goals/${goalId}/skills`;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        if (typeof(response.data) === 'object'){
          dispatch({
            type: GET_SKILLS_FOR_GOAL,
            skills: response.data
          })
        }
        else {
          history.push('/login')
        }
      })
      .catch(err => console.log(err))
  }
};

export const createGoal = (studentId, goal, history, guided) => {
  const url = `${ROOT_URL}/students/${studentId}/goals`;
  return (dispatch) => {
    return axios
      .post(url, {...goal, type: 'goal'})
      .then(response => {
        dispatch({
          type: CREATE_GOAL,
          goal: response.data
        });
        guided
          ? history.push(`/students/${studentId}/goals/${response.data.id}?guided`)
          : history.push(`/students/${studentId}/goals/${response.data.id}`);
        return response.data
      })
      .catch(err => console.log(err))
  }
};

export const updateGoal = (goal) => {
  const url = `${ROOT_URL}/goals`;
  return (dispatch) => {
    axios
      .patch(url, goal)
      .then(response => {
        console.log(response.data)
        dispatch({
          type: UPDATE_GOAL,
          goal: response.data
        });
      })
      .catch(err => console.log(err))
  }
};

export const deleteGoal = (goal, studentId) => {
  const url = `${ROOT_URL}/students/${studentId}/goals`;
  return (dispatch) => {
    axios
      .delete(url, {data: goal})
      .then(() => {
        dispatch({
          type: DELETE_GOAL,
          goal
        })
      })
      .catch(err => console.log(err))
  }
};

export const checkImport = (id, goalDto, shared) => {
  const checkUrl = `${ROOT_URL}/students/${id}/goals/import/${shared ? 'shared/check' : 'check'}`;
  return axios
    .post(checkUrl, goalDto)
    .then(response => response.data)
    .catch(err => console.log(err))
};

export const importGoalStudent = (id, goalDto, key, shared, type) => {
  // TODO: REFACTOR TO HAVE STAFF not student
  const url = `${ROOT_URL}/students/${id}/goals/import/${shared ? 'shared' : ''}`;
  return (dispatch) => {
    axios
      .post(url, goalDto)
      .then((response) => {
        type === 'staff'
        ? dispatch({
          type: CREATE_STAFF_GOAL_DATABANK,
          goalDatabank: response.data
        })
        : dispatch({
          type: CREATE_GOAL,
          goal: response.data
        })
      })
      .catch(({ response }) => {
        dispatch({
          type: HANDLE_ERROR,
          error: {type: response.status, msg: response.data, id: key}
        })
      })
  };
};
