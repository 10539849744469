export default {
  container: {
    color: '#0a0a0a',
    overflow: 'wrap',
  },
  confirmDialog: {
    minWidth: '600px',
  },
  dialog: {
    zIndex: 0,
    overflow: 'auto',
  },
  staffDialog: {
    overflow: 'visible',
    color: '#0a0a0a',
  },
  root: {
    marginTop: '20px',
    maxWidth: 550,
  },
  title: {
    backgroundColor: '#383871',
    marginBottom: 15,
  },
  description: {
    marginBottom: 20
  },
  divider: {
    marginBottom: 20
  },
  dialogTitle: {
    marginBottom: 15
  },
  titleText: {
    float: 'left',
    color: '#fff'
  },
  exitIcon: {
    float: 'right',
    color: '#fff',
  },
  subtitle: {
    marginLeft: 20,
    marginBottom: 8
  },
  confirmation: {
    margin: '50px 0'
  },
  buttons: {
    justifySelf: 'end',
    marginRight: '20px',
    marginTop: '40px',
  },
  confirmButtons: {
    margin: '20px auto',
    textAlign: 'center'
  },
  confirmLine: {
    display: 'grid',
    gridTemplateColumns: '1fr 11fr',
    padding: '10px 0'
  },
  icon: {
    gridColumn: 1,
    alignSelf: 'center'
  },
  name: {
    gridColumn: 2,
    marginLeft: '15px'
  },
  firstName: {
    marginBottom: '10px',
  },
  lastName: {
    marginBottom: '30px',
  },
  fullName: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignContent: 'center',
    gridColumnGap: '10px',
    marginBottom: 10,
    marginTop: 20
  },
  select: {
    marginTop: '20px',
    marginBottom: '10px',
    position: 'relative',
  },
  brailleRoot: {
    maxWidth: 550,
  },
  brailleSelect: {
    marginBottom: '10px',
    position: 'relative',
  },
  checkbox: {
    color: 'rgba(72, 76, 160, 1)',
    '&$checked': {
      color: '#310073'
    }
  },
  goalButton: {
    marginTop: '50px',
    margin: '0 auto'
  },
  checked: {},
  valueContainer: {
    height: '34px',
    minHeight: '34px',
  },
  configureInfo: {
    marginTop: 10
  },
  configureLine: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    marginBottom: '15px'
  },
  configureSkillLine: {
    marginBottom: '15px'
  },
  configTitle: {
    alignSelf: 'center',
    marginRight: 10,
    color: '#310073'
  },
  configHelp: {
    justifySelf: 'center',
    gridColumn: 'span 2',
    marginTop: 10,
    marginBottom: 10
  },
  configComponent: {

  },
  multiline: {
    marginBottom: '60px'
  },
  level: {
    marginTop: '20px',
  },
  safariFix: {
    flexWrap: 'nowrap'
  },
  inputLine: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr 2fr',
    gridGap: '5px',
    paddingBottom: '10px',
    width: '100%',
  },
  error: {
    backgroundColor: '#b2102f'
  },
  checkboxGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    justifySelf: 'center'
  },
  gridContainer: {
    marginTop: 40,
  },
  staffBase: {
    height: '80%',
  },
  skillWizardBtn: {
    marginBottom: 10,
    marginTop: 40,
    backgroundColor: '#45458D',
    color: "#fff",
    '&:hover': {
      backgroundColor: '#383871',
    }
},
  skillName: {
    marginTop: 5,
    marginBottom: 20
  },
  skillDescription: {
    marginBottom: 10
  },
  skillInputs: {
    marginTop: 20
  },
  notFound: {
    marginTop: 30,
    marginBottom: 15,
    display: 'grid'
  },
  btn: {
    justifySelf:'center'
  },
  invite: {
    width: '100%'
  },
  content: {
    overflow: 'visible',
  },
  subtext: {
    marginBottom: 20
  },
  login: {
    display: 'grid',
    gridAutoFlow: 'column',
    marginTop: 20,
    '& .text': {
      alignSelf: 'center',
      justifySelf: 'center'
    },
    '& .toggle':{
      alignSelf: 'center',
    }
  },
  scroll: {
    marginTop: '20px',
    overflow: 'visible',
  },
  arc: {
    display: 'grid',
    marginBottom: 25,
    marginTop: 15
  },
  arcChart: {
    width: '50%',
    justifySelf: 'center',
  },
  arcSlider: {
    width: '33%',
    justifySelf: 'center',
  },
  sliderContainer: {
    gridRow: 2,
    justifySelf: 'center',
    display: 'grid',
    gridColumn: 'span 2',
    gridTemplateColumns: '1fr 4fr 1fr',
    justifyContent: 'space-between',
    marginTop: 10
  },
  slider: {
    gridColumn: 2,
    justifySelf: 'center',
    width: 150
  },
  addressLine: {
    display: 'grid',
    gridTemplateColumns: '3fr 4fr 3fr',
    gridColumnGap: 10
  },
  priceLine: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: 10
  },
  upload: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateRows: '1fr 1fr',
    marginBottom: 10,
    marginTop: 20
  },
  studentSelect: {
    marginBottom: 10
  },
  staffFullName: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignContent: 'center',
    gridColumnGap: '10px',
    marginBottom: 10,
    marginTop: 10
  },
  personalInfo: {
    marginBottom: 10
  },
  dots: {
    width: '3.5em',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& div': {
    width: '0.8em',
    height: '0.8em',
    borderRadius: '50%',
    backgroundColor: '#fff',
    animation: '$fade 0.8s ease-in-out alternate infinite'
    },
    '& div:nth-of-type(1)': {
      animationDelay: '-0.4s'
    },
    '& div:nth-of-type(2)': {
      animationDelay: '-0.2s'
    },
  },
  '@keyframes fade': {
    from: {
      opacity: 1
    },
    to: {
      opacity: 0
    }
  },
  options: {
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'space-around'
  },
  downloadButton: {
    marginBottom: 20
  },
  selectButtons: {
    marginBottom: 10
  },
  buttonCenter: {
    margin: '0 auto',
    marginBottom: 40
  },
  gestureError: {
    gridRow: 11,
    gridColumn: 'span 2'
  },
  storyDataElement: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignContent: 'center',
    gridColumnGap: '10px',
    marginTop: 5
  },
  elementMargin: {
    marginBottom: 10
  },
}