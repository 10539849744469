import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import { Button, FormGroup, Typography, IconButton, TextField, MenuItem, Divider, withStyles } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Cancel, Close } from '@material-ui/icons';
import { fetchLevels } from '../../../../redux/actions';
import { ROOT_URL } from '../../../../redux/constants';
import baseStyle from '../../../../styles/wordHunt';

class ReadAloudPROTO extends Component {

  state = {
    text: [''],
    roundCount: 1,
    maxGuesses: 1,
    shuffle: false,
    difficulty: 'Easy',
    tested: false,
    level: this.props.level.id ? this.props.level : {},
    error: {},
    selectedLevel: ''
  };

  componentDidMount() {
    if (this.props.level) {
      const variables = JSON.parse(this.props.level.levelJson);
      console.log(this.props.level.id);
      console.log(variables);
      this.setState({
        ...variables,
        level: this.props.level,
        difficulty: this.props.level.difficulty,
        selectedLevel: this.props.level.id
      })
      if (this.props.levelTemplate) {
        this.setState({ selectedLevel: this.props.levelTemplate.id})
      }
    }
  };

  close = () => {
    this.props.close();
  };

  handleChange = (ev) => {
    const value = ev.target.type === 'number' ? ev.target.value > 0 ? ev.target.value * 1 : '' : ev.target.value;
    this.setState({ [ev.target.name]: value })
  };

  addText = () => {
    this.setState({ text: [...this.state.text, '']})
  };

  changeText = (ev, index) => {
    const text = this.state.text;
    text[index] = ev.target.value;
    this.setState({ text })
  };

  handleLevelChange = (ev) => {
    const level = this.props.levels.find(level => level.id === ev.target.value);
    this.setState({ variables: JSON.parse(level.levelVariables), level, selectedLevel: ev.target.value })
  };

  removeText = (index) => {
    let text = this.state.text;
    if (text.length > 1) {
      text.splice(index, 1);
    }
    else {
      text = [''];
    }
    this.setState({ text })
  };

  runTest = () => {
    const { tested, roundCount, shuffle, level,maxGuesses, selectedLevel } = this.state;
    var interenalRoundCount = roundCount;
    const text = this.state.text.filter(entry => !!entry);
    const url = this.props.type === 'staff'
      ? `${ROOT_URL}/staff/${this.props.id}/objective-databank/test`
      : `${ROOT_URL}/admin/objective-databank/test`;
    if (tested) this.clearTest();
    if(selectedLevel === "-4977869515354965953") {
      interenalRoundCount= text.length;
    }
    axios.post(url, {
      levelVariables: JSON.stringify({
        text,
        roundCount: interenalRoundCount,
        maxGuesses,
        shuffle
      }),
      templateId: selectedLevel
    }).catch(err => console.log(err));
    this.setState({ tested: true });
  };

  clearTest = () => {
    const url = this.props.type === 'staff'
      ? `${ROOT_URL}/staff/${this.props.id}/objective-databank/test`
      : `${ROOT_URL}/admin/objective-databank/test`;
    axios.delete(url)
      .catch(err => console.log(err));
    this.setState({ tested: false })
  };

  onSubmit = () => {
    const { difficulty, roundCount, shuffle, level, maxGuesses, selectedLevel } = this.state;
    var interenalRoundCount = roundCount;
    const text = this.state.text.filter(entry => !!entry);
    if(selectedLevel === "-4977869515354965953") {
      interenalRoundCount = text.length;
    }
    const levelJson = JSON.stringify({
      text,
      roundCount: interenalRoundCount,
      maxGuesses,
      shuffle
    });
    const skill = { levelJson, difficulty, levelTemplateId: selectedLevel };
    this.props.onSubmit(skill);
  };

  checkPhrase = (index) => {
    if (this.state.text[index] === '') {
      this.setState({ error: {...this.state.error, [index]: true }})
    }
    else {
      let error = this.state.error;
      delete error[index];
      this.setState({ error })
    }
  };

  render() {
    const { classes, open, close, back, levels, levelTemplate } = this.props;
    const { tested, level, error, selectedLevel } = this.state;
    const gameId = this.props.selectedGame.id;
    const gameLevels = levels.filter(level => level.gameId === gameId);
    return (
      <>
        <Dialog
          maxWidth='md'
          fullWidth
          disableEscapeKeyDown
          open={this.props.open}
          onClose={this.props.close}
          aria-labelledby='form-dialog-title'
          PaperProps={{ classes: { root: classes.container } }}
        >
          <DialogTitle disableTypography={true} className={classes.title} id='form-dialog-title'>
            <Typography className={classes.titleText} variant='h4'>
              <FormattedMessage id='Wizard.skillDB.title'/>
            </Typography>
            <IconButton
              onClick={this.close}
              className={classes.exitIcon}
              aria-label="close dialog"
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography gutterBottom variant='h6'><FormattedMessage id='Wizard.skillDB.levelSelect'/>:</Typography>
            <FormGroup>
              <TextField
                  disabled={this.props.edit}
                  select
                  variant='outlined'
                  label={<FormattedMessage id='General.levels' />}
                  name='level'
                  onChange={this.handleLevelChange}
                  value={this.state.selectedLevel}
              >
                {
                  gameLevels.map(level => (<MenuItem key={level.id} value={level.id}> {level.name} </MenuItem>))
                }
              </TextField>
            </FormGroup>
            <Divider className={classes.divider}/>
            {
              level.id &&
              <FormGroup classes={{root: classes.safariFix}} className={classes.level}>
                <Typography variant='h6'><FormattedMessage id='General.description'/>:</Typography> {level.description}
                <div className={classes.configureInfo}>
                  <Typography variant='h6'><FormattedMessage id='Wizard.skillDB.levelConfig'/>:</Typography>
                </div>
                <Divider className={classes.divider}/>
                {selectedLevel && selectedLevel !== "-4977869515354965953" &&
                <FormGroup className={classes.configureLine}>
                  <Typography variant='h6'>Number of rounds: </Typography>
                  <TextField
                      variant='outlined'
                      className={classes.configComponent}
                      name='roundCount'
                      type='number'
                      value={this.state.roundCount}
                      onChange={this.handleChange}
                  />
                </FormGroup>
                      }
                <FormGroup className={classes.configureLine}>
                  <Typography variant='h6'>Max Guesses: </Typography>
                  <TextField
                      variant='outlined'
                      className={classes.configComponent}
                      name='maxGuesses'
                      type='number'
                      value={this.state.maxGuesses}
                      onChange={this.handleChange}
                  />
                </FormGroup>
                {selectedLevel && selectedLevel !== "-4977869515354965953" &&
                <FormGroup className={classes.configureLine}>
                  <Typography variant='h6'>Should they be shuffled? </Typography>
                  <TextField
                      select
                      variant='outlined'
                      className={classes.configComponent}
                      name='shuffle'
                      value={this.state.shuffle}
                      onChange={this.handleChange}
                  >
                    <MenuItem value={true}> Yes </MenuItem>
                    <MenuItem value={false}> No </MenuItem>
                  </TextField>
                </FormGroup>
                }
                <FormGroup className={classes.configureLine}>
                  <Typography variant='h6'>
                    <FormattedMessage id='General.difficulty'/>:
                  </Typography>
                  <TextField
                      select
                      variant='outlined'
                      id='difficulty-select'
                      name='difficulty'
                      value={this.state.difficulty}
                      onChange={this.handleChange}
                  >
                    {
                      [
                        {value: 'Easy', text: <FormattedMessage id='General.easy'/>},
                        {value: 'Medium', text: <FormattedMessage id='General.medium'/>},
                        {value: 'Hard', text: <FormattedMessage id='General.hard'/>}
                      ].map((option) => <MenuItem key={option.value} value={option.value}>{option.text}</MenuItem>)
                    }
                  </TextField>
                </FormGroup>
                <Typography className={classes.phraseTitle} variant='h6'>Sentences to use in game: </Typography>
                {
                  this.state.text.map((text, index) => (
                      <FormGroup key={`${index}`} className={classes.configureLine}>
                        <div className={classes.phrase}>
                          <IconButton className={classes.phraseBtn} onClick={() => this.removeText(index)}>
                            <Cancel color='secondary'/>
                          </IconButton>
                          <TextField
                              variant='outlined'
                              error={this.state.error[index]}
                              helperText={this.state.error[index] ? 'Sentence cannot be blank' : ''}
                              className={classes.configComponent}
                              name='texts'
                              value={this.state.text[index]}
                              onChange={(ev) => this.changeText(ev, index)}
                              onBlur={() => this.checkPhrase(index)}
                          />
                        </div>
                      </FormGroup>
                  ))
                }
                <div className={classes.questionBtns}>
                  <Button
                      variant='contained'
                      id='add-question'
                      color='primary'
                      classes={{root: classes.addButton}}
                      onClick={this.addText}
                  >
                    <FormattedMessage id='Wizard.Hangman.addPhrase'/>
                  </Button>
                </div>
              </FormGroup>
            }
          </DialogContent>
          <DialogActions className={classes.buttons}>
            <Button id='back' onClick={this.props.back} color='secondary'>
              <FormattedMessage id='General.back'/>
            </Button>
            <Button
              onClick={this.runTest}
              variant={tested ? 'outlined' : 'text'}
              color={tested ? 'secondary' : 'primary'}
            >
              {tested ? <FormattedMessage id='General.retest'/> : <FormattedMessage id='General.test'/>}
            </Button>
            <Button
              disabled={Object.keys(this.state.error).length > 0 || this.state.text.some(text => text === '')}
              aria-label='go to final page of skill creator'
              onClick={this.onSubmit}
              color='primary'
            >
              <FormattedMessage id='General.next'/>
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}
const  mapState = ({ platform: { levels }}) => {
  return {
    levels,
  }
};

const mapDispatch = (dispatch) => {
  return {
    fetchLevels: () => dispatch(fetchLevels())
  }
};
export default connect(mapState, mapDispatch)(withStyles(baseStyle)(ReadAloudPROTO));
