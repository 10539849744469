import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import Dropzone from 'react-dropzone';
import AvatarEditor from 'react-avatar-editor';
import GroupEditWizard from './GroupEditWizard';
import { Slider } from '@material-ui/lab';
import {
  Divider,
  Typography,
  Paper,
  withStyles,
  Icon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton, DialogContentText, Grid
} from '@material-ui/core';
import { AddCircleOutlined, CameraAlt, Public, RemoveCircleOutlined, Edit } from '@material-ui/icons';
import { uploadGroupImage, clearGroup } from '../../redux/actions';
import pic from '../../assets/SocialMockups/GroupPics/group.png';
import baseStyle from '../../styles/group';


class GroupDetail extends Component {

  state = {
    open: false,
    image: this.props.group.imgSrc || pic,
    width: 300,
    height: 200,
    borderRadius: 0,
    position: { x: 0.5, y: 0.5 },
    scale: 1,
    preview: null,
    img: '',
    editing: false
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.group.imgSrc !== nextProps.group.imgSrc){
      this.setState({ image: nextProps.group.imgSrc })
    }
  };

  componentWillUnmount() {
    this.props.clearGroup();
  }

  setEditorRef = editor => {
    if (editor) this.editor = editor;
    this.setState({ img: this.props.group.imgSrc })
  };

  openFileUpload = () => {
    this.setState({ open: true })
  };

  closeFileUpload = () => {
    this.setState({ open: false })
  };

  handleScale = (ev, val) => {
    const scale = parseFloat(val);
    this.setState({ scale })
  };

  handlePositionChange = (position) => {
    this.setState({ position })
  };

  handleFileUpload = () => {
    const img = this.editor.getImageScaledToCanvas();
    this.props.uploadGroupImage(this.props.group, img);
    this.setState({ open: false, image: img.toDataURL() })
  };

  handleDrop = (dropped) => {
    this.setState({ img: dropped[0] })
  };

  handleFileChange = (ev) => {
    this.setState({ img: ev.target.files[0] })
  };

  toggleEdit = () => {
    this.setState(({ editing }) => ({ editing: !editing }));
  };

  close = () => {
    this.setState({ editing: false })
  };

  handleEmptyImg = () => {
    this.setState({ image: pic })
  };

  render() {
    const { classes, group, isAdmin } = this.props;
    const { open, image } = this.state;
    const date = new Date(group.startDate) || { epochSecond: 684062672 };
    const timeZone = moment.tz.guess();
    const _date = date ? moment(new Date(date)).tz(timeZone) : moment(new Date(date));
    return (
      <Paper className={classes.detail}>
        <Dialog
          fullWidth
          open={open}
          onClose={this.closeFileUpload}
          onEscapeKeyDown={this.closeFileUpload}
        >
          <DialogTitle>
            <FormattedMessage id='Group.image.title'/>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <FormattedMessage id='Group.image.text'/>
            </DialogContentText>
            <section className={classes.dropzone}>
              <Dropzone
                onDrop={this.handleDrop}
                disableClick
                multiple={false}
                style={{ width: this.state.width, height: this.state.height, marginBottom:'35px' }}
              >
                {({getRootProps}) => (
                  <div>
                    <AvatarEditor
                      {...getRootProps()}
                      ref={this.setEditorRef}
                      width={this.state.width}
                      height={this.state.height}
                      scale={parseFloat(this.state.scale)}
                      position={this.state.position}
                      borderRadius={this.state.width / (100 / this.state.borderRadius)}
                      onPositionChange={this.handlePositionChange}
                      image={this.state.img}
                      crossOrigin='use-credentials'
                    />
                  </div>
                )}
              </Dropzone>
              <div className={classes.slider}>
                <Grid container spacing={2}>
                  <Grid item>
                    <RemoveCircleOutlined />
                  </Grid>
                  <Grid item xs>
                    <Slider
                      width={120}
                      aria-labelledby="continuous-slider"
                      name='scale'
                      onChange={this.handleScale}
                      min={0.1}
                      max={2}
                      defaultValue={1}
                      step={0.1}
                      value={this.state.scale}
                    />
                  </Grid>
                  <Grid item>
                    <AddCircleOutlined />
                  </Grid>
                </Grid>
              </div>
              <input
                accept="image/*"
                className={classes.input}
                style={{ display: 'none' }}
                id="raised-button-file"
                multiple
                type="file"
                onChange={this.handleFileChange}
              />
              <label className={classes.addImage} htmlFor="raised-button-file">
                <Button variant="contained" component="span">
                  <FormattedMessage id='Group.image.change'/>
                </Button>
              </label>
            </section>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.closeFileUpload}
              color='secondary'
            >
              <FormattedMessage id='General.cancel'/>
            </Button>
            <Button
              onClick={this.handleFileUpload}
              color='primary'
            >
              <FormattedMessage id='General.save'/>
            </Button>
          </DialogActions>
        </Dialog>
        <div className={classes.title}>
          <Typography
            align='center'
            variant='h5'
          >
            {group.name}
          </Typography>
          <div className={classes.subtitle}>
            {
              group.isPrivate
                ?
                  <div className={classes.private}>
                    <Icon className={classNames('fas fa-key', classes.icon)}/>
                    <Typography className={classes.text} variant='body1'>
                      <FormattedMessage id={'Groups.private'}/>
                    </Typography>
                  </div>
                :
                  <div className={classes.private}>
                    <Public className={classes.icon}/>
                    <Typography className={classes.text} variant='body1'>
                      <FormattedMessage id={'Groups.public'}/>
                    </Typography>
                  </div>
            }
            {
              isAdmin &&
              <div className={classes.editBtn}>
                <GroupEditWizard
                  open={this.state.editing}
                  close={this.close}
                  group={group}
                />
                <IconButton
                  href=''
                  aria-label='edit group'
                  onClick={this.toggleEdit}
                >
                  <Edit />
                </IconButton>
              </div>
            }
          </div>
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.pictureContainer}>
          <img alt={group.name} src={image} onError={this.handleEmptyImg} className={classes.avatar}/>
          {
            isAdmin &&
            <IconButton
              onClick={this.openFileUpload}
              className={classes.editButton}
            >
              <CameraAlt />
            </IconButton>
          }
        </div>
        <Divider className={classes.secondaryDivider}/>
        <Typography gutterBottom align='center' variant='subtitle1'> {group.description} </Typography>
        <Typography align='center' variant='subtitle2'>
          <strong><FormattedMessage id='Group.startDate'/>: </strong>{_date.format('ll')}
        </Typography>
      </Paper>
    );
  }
}

const mapState = ({ social: { group }}) => {
  return {
    group
  }
};

const mapDispatch = (dispatch) => {
  return {
    uploadGroupImage: (group, file) => dispatch(uploadGroupImage(group, file)),
    clearGroup: () => dispatch(clearGroup())
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(GroupDetail));