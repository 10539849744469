import React, {Component} from 'react';
import {connect} from 'react-redux';
import {fetchDeletedStudents} from "../../../redux/actions";
import DataTable from "../Shared/DataTable";
import {FormattedMessage} from 'react-intl';
import {Button, Typography, IconButton, FormGroup} from '@material-ui/core';
import { Paper, TextField, Toolbar, Tooltip, Icon, Switch } from '@material-ui/core';
import RestoreStudentsTable from  "../Admin/RestoreStudentsTable";
import {Dialog, DialogContent, DialogTitle, DialogContentText,DialogActions} from '@material-ui/core';
import { stableSort, getSorting } from '../../../helpers';
import {Close} from '@material-ui/icons';
import {withStyles} from '@material-ui/core/styles';
import styles from '../../../styles/dataTable.js';
import WizardToggleButton from '../Wizards/WizardToggleButton';
import baseStyle from '../../../styles/wizardBase';
import {fetchStudents, deleteStudentPernament, deleteStudentRestore} from "../../../redux/actions";
import axios from 'axios';
import { Delete, PlayCircleFilled, Search, CloudDownload } from '@material-ui/icons';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    TableSortLabel,
    InputAdornment,
} from '@material-ui/core';
import {
    ROOT_URL
} from '../../../redux/constants';

const rows = [
    {id: 'firstName', label: <FormattedMessage id='General.firstName'/>, searchable: true},
    {id: 'lastName', label: <FormattedMessage id='General.lastName'/>, searchable: true},
    {id: 'gamerTag', label: 'User Name', searchable: true}
];

class RestoreStudents extends Component {

    state = {
        order: 'asc',
        orderBy: 'name',
        rowsPerPage: 5,
        page: 0,
        filteredData: [],
        searchValue: '',
        checked: {},
        open: false
    };

    componentDidMount() {
        const {fetchDeletedStudents, user} = this.props;
        if (user) fetchDeletedStudents()
    }

    openWizard = () => {
        this.props.fetchDeletedStudents();
        this.setState({open: true});
    }

    closeWizard = () => {
        this.props.fetchStudents();
        this.setState({open: false});
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.data && nextProps.data !== this.props.data) {
            this.handleSearch(this.state.searchValue, nextProps.data)
        }
    }

    render() {
        const { classes, title } = this.props;
        const {
            rowsPerPage,
            page,
            orderBy,
            order,
            searchValue,
            filteredData,
        } = this.state;
        const data = filteredData.length || searchValue ? filteredData : this.props.deletedStudents;
        console.log(data);

        return (
            <div>
                <Dialog
                    disableEscapeKeyDown
                    fullWidth={true}
                    open={this.state.open}
                    onClose={this.props.close}
                    aria-labelledby='form-dialog-title'
                    PaperProps={{classes: {root: classes.container}}}
                    scroll='paper'
                >
                    <DialogTitle disableTypography={true} className={classes.title} id='form-dialog-title'>
                        <Typography className={classes.titleText} variant='h4'>
                            <FormattedMessage id="Wizard.restore.title"/>
                        </Typography>
                        <IconButton
                            onClick={this.closeWizard}
                            className={classes.exitIcon}
                            aria-label="close dialog"
                        >
                            <Close/>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className={classes.container}>
                            <DialogContentText align='center' variant='h6'>
                                <FormattedMessage id="Wizard.restore.description"/>
                            </DialogContentText>
                            <RestoreStudentsTable
                            data={this.props.deletedStudents}
                            restore={this.props.deleteStudentRestore}
                            delete={this.props.deleteStudentPernament}/>
                        <DialogActions className={classes.actions}>
                            <Button onClick={this.closeWizard} color='secondary'>
                                <FormattedMessage id="General.close"/>
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
                <WizardToggleButton
                    openWizard={this.openWizard}
                    type='student'
                    text={<FormattedMessage id='Students.DeletedStudents'/>}
                />
            </div>
        )
    }
}

const mapState = ({platform: {deletedStudents}, shared: {user}}) => {
    return {
        deletedStudents,
        user
    }
};

const mapDispatch = (dispatch, {history}) => {
    return {
        fetchStudents: () => dispatch(fetchStudents(history)),
        fetchDeletedStudents: () => dispatch(fetchDeletedStudents(history)),
        deleteStudentRestore: (student) => dispatch(deleteStudentRestore(student)),
        deleteStudentPernament: (student) => dispatch(deleteStudentPernament(student))
    }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle,styles)(RestoreStudents));