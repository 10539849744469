import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, FormGroup, Typography, TextField, IconButton, MenuItem } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { patchSliderSeries } from '../../../../redux/actions';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../../styles/wizardBase';
import { FormattedMessage } from 'react-intl';

class SliderSeriesManagementEdit extends Component {
  state = {
    ...this.props.data
  };

  handleChange = (ev) => {
    this.setState({[ev.target.name]: ev.target.value })
  };

  submit = (ev) => {
    ev.preventDefault();
    this.props.patchSliderSeries(this.state);
    this.props.close();
  };

  render()  {
    const { classes, data } = this.props;
    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        open={this.props.open}
        onClose={this.props.close}
        aria-labelledby='form-dialog-title'
        scroll='paper'
        PaperProps={{ classes:{ root: classes.container }}}
      >
        <DialogTitle disableTypography={true} className={ classes.title } id='form-dialog-title'>
          <Typography className={ classes.titleText } variant='h4'>{data.name}</Typography>
          <IconButton
            onClick={this.props.close}
            className={ classes.exitIcon }
            aria-label="close dialog"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormGroup classes={{ root: classes.root }}>
            <TextField
              variant='outlined'
              classes={{ root: classes.lastName }}
              id='level-edit-id'
              label='ID'
              name='id'
              type='text'
              disabled
              value={this.state.id}
            />
            <TextField
              variant='outlined'
              classes={{ root: classes.lastName }}
              id='level-edit-name'
              label={<FormattedMessage id='General.name'/>}
              name='name'
              type='text'
              value={this.state.name}
              onChange={this.handleChange}
            />
            <TextField
              multiline
              variant='outlined'
              id='level-edit-description'
              classes={{ root: classes.lastName }}
              label={<FormattedMessage id='General.description'/>}
              name='description'
              type='text'
              value={this.state.description}
              onChange={this.handleChange}
            />
          </FormGroup>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button onClick={this.props.close} color='secondary'>
            <FormattedMessage id='General.cancel'/>
          </Button>
          <Button onClick={this.submit} color='primary'>
            <FormattedMessage id='General.edit'/>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapState = ({ platform: {  }}) => {
  return {
  }
};

const mapDispatch = (dispatch) => {
  return {
    patchSliderSeries: (level) => dispatch(patchSliderSeries(level))
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(SliderSeriesManagementEdit));