import { FETCH_ALL_GOALS, CREATE_GOAL, DELETE_GOAL, GET_GOAL, UPDATE_GOAL } from '../../constants';

export const goals = (state = [], action) => {
  switch(action.type){
    case FETCH_ALL_GOALS:
      return action.goals;
    case CREATE_GOAL:
      return [...state, action.goal];
    case UPDATE_GOAL:
      return state.map(goal => goal.id === action.goal.id ? action.goal : goal);
    case DELETE_GOAL:
      return state.filter(goal => goal.id !== action.goal.id);
    default:
      return state;
  }
};

export const goal = (state = {}, action) => {
  switch(action.type){
    case GET_GOAL:
      return action.goal;
    default:
      return state;
  }
};