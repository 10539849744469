import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import WizardToggleButton from "../WizardToggleButton";
import GoalDatabankWizardPage1 from './GoalDatabankWizardPage1';
import { addSkillToGoal, createGoalDatabank, updateGoalDatabank } from '../../../../redux/actions';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../../styles/wizardBase';

class GoalDatabankWizardBase extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      open: this.props.open || false,
      name: this.props.data ? this.props.data.name : '',
      description: this.props.data ? this.props.data.description : '',
      page: 1,
    }
  }

  openWizard = () => {
    this.setState({ open: true })
  };

  closeWizard = () => {
    this.setState({ open: false, page: 1, name: '', description: '' });
    if (this.props.close) this.props.close();
  };

  next = () => {
    this.setState({ page: this.state.page+1 });
  };

  back = () => {
    this.setState({ page: this.state.page-1 });
  };

  goToPage = (page) => {
    this.setState({ page });
  };

  submit = () => {
    const { name, description } = this.state;
    const goal = { name, description };
    if (this.props.edit) {
      this.props.updateGoalDatabank({ ...goal, id: this.props.data.id})
    }
    else {
      if (this.props.guided) {
        this.props.createGoalDatabank(this.props.type, this.props.id, goal, true)
          .then(goal => {
            this.props.addSkillToGoal(goal.id, this.props.skillDatabank)
          })
      }
      else {
        this.props.createGoalDatabank(this.props.type, this.props.id, goal, false)
      }
    }
    this.closeWizard();
  };

  handleChange = (ev) => {
    this.setState({ [ev.target.name]: ev.target.value })
  };

  render(){
    const { page } = this.state;
    const open = this.props.open ? this.props.open : this.state.open;
    return (
      <div id='goal-wizard'>
        {
          page === 1 &&
          <GoalDatabankWizardPage1
            open={open}
            close={this.closeWizard}
            next={this.next}
            submit={this.submit}
            handleChange={this.handleChange}
            goalDB={{ name: this.state.name, description: this.state.description }}
          />
        }
        {
          !this.props.edit &&
          <WizardToggleButton
            openWizard={this.openWizard}
            text={<FormattedMessage id='Databanks.addGoalDB'/>}
          />
        }
      </div>
    )
  }
}

const mapState = ({ platform: { goalDatabanks, skillDatabank }}) => {
  return {
    goalDatabanks,
    skillDatabank
  }
};

const mapDispatch = (dispatch, { history }) => {
  return {
    createGoalDatabank: (type, formId, goal, guided) => dispatch(createGoalDatabank(type, formId, goal, history, guided)),
    updateGoalDatabank: (goal, id) => dispatch(updateGoalDatabank(goal ,id)),
    addSkillToGoal: (goalId, skill) => dispatch(addSkillToGoal(goalId, skill))
  }
};

export default connect(mapState, mapDispatch)(withStyles(styles)(GoalDatabankWizardBase));