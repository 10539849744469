import React, { Component } from 'react';
import { connect } from 'react-redux';
import ellipsis from '../../assets/Loading/Ellipsis.svg';
import { getGroup, myProfile, acceptInvite, removeFromGroup, fetchGames, loadMore } from '../../redux/actions';
import { Snackbar, Button, withStyles } from '@material-ui/core';
import GroupDetail from '../components/GroupDetail';
import MemberList from '../components/MemberList';
import Announcements from '../components/Announcements';
import GoalsList from '../components/GoalsList';
import baseStyle from '../../styles/group';
import GroupFeed from '../components/GroupFeed';

class Group extends Component {
  constructor(props){
    super(props);
    this.state = {
      selected: '',
      showMore: false,
      invited: false,
      mod: false,
      admin: false,
      myself: false,
      loading: !this.props.group.key,
      posts: this.props.posts
    };
    this.goalListRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.group.members) {
      this.isInvited(this.props);
      this.isAdmin(this.props);
    }
    this.props.getGroup(this.props.id);
    this.props.myProfile();
    this.props.fetchGames();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.group.key !== nextProps.group.key){
      this.props.getGroup(nextProps.id);
      this.props.myProfile();
      this.isInvited(nextProps);
      this.isAdmin(nextProps);
      this.setState({ loading: false })
    }
    if (this.props.posts !== nextProps.posts){
      this.setState({ posts: nextProps.posts })
    }
  }

  selectTrendingGroup = (id) => {
    this.setState({ selected: id, showMore: true });
    window.scrollTo(0, this.goalListRef.current.offsetTop-100)
  };

  isAdmin = (props) => {
    const { group, profile } = props;
    const myself = group.members && group.members.find(member => member.key === profile.key);
    const admin = myself && myself.role === 'Administrates';
    const mod = myself && myself.role === 'Moderates';
    this.setState({ admin, mod, myself })
  };

  isInvited = (props) => {
    const { group, profile } = props;
    const myself = group.members && group.members.find(member => member.key === profile.key);
    const invited = myself && myself.role === 'Invited';
    this.setState({ invited })
  };

  acceptInvite = () => {
    const { group, profile } = this.props;
    this.props.acceptInvite(group, profile.key);
    this.setState({ invited: false })
  };

  declineInvite = () => {
    const { group, profile } = this.props;
    removeFromGroup(group, profile.key);
  };

  addPost = ( _post) => {
    const filteredPosts = this.state.posts.filter(post => post.key !== _post.key);
    const posts = [...filteredPosts, _post];
    this.setState({ posts })
  };

  render() {
    const { group, classes, profile } = this.props;
    const { loading, admin, mod, myself } = this.state;
    const announcements = group.pinnedPosts;
    const goals = group.goals || [];
    const sheets = group.sheets || [];
    const isLocked = group.isLocked;
    return (
        <div className={classes.container}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={this.state.invited}
            onClose={this.handleClose}
            ContentProps={{
              'aria-describedby': 'message-id',
              classes: { root: classes.snackbar }
            }}
            message={<span id="message-id">You are viewing this group as a guest</span>}
            action={
              <>
                <Button color="primary" size="small" onClick={this.acceptInvite}>
                  Join group
                </Button>
                <Button color="secondary" size="small" onClick={this.declineInvite}>
                  Ignore request
                </Button>
              </>
            }
          />
          <section className={classes.left}>
            <GroupDetail isAdmin={admin} group={group}/>
            {/*{*/}
            {/*  goals.length > 0 &&*/}
            {/*  <TrendingGoals selectTrendingGroup={this.selectTrendingGroup} goals={goals}/>*/}
            {/*}*/}
            <MemberList isAdmin={admin} isMod={mod} myself={myself} group={group}/>
          </section>
          <main className={classes.center}>
            {
              loading
                ? <img className={classes.loading} src={ellipsis} alt='loading...'/>
                :
                <>
                  {
                    announcements && announcements.length > 0 &&
                    <Announcements isAdmin={admin} posts={announcements}/>
                  }
                  {
                    (goals.length > 0 || sheets.length > 0) &&
                    <GoalsList
                      refProp={this.goalListRef}
                      showMore={this.state.showMore}
                      selected={this.state.selected}
                      profile={this.props.profile}
                      group={group}
                      goals={goals}
                      sheets={sheets}
                    />
                  }
                  <GroupFeed
                    className={classes.feed}
                    isAdmin={admin}
                    isLocked={isLocked}
                    isModerator={mod}
                    group={group}
                    selectGoal={this.selectTrendingGroup}
                    goals={goals}
                    posts={this.props.posts}
                    profile={profile}
                    loadMore={this.loadMore}
                  />
                </>
            }
          </main>
      </div>
    );
  }
}

const mapState = ({ social: { group, posts, goals, profile }}) => {
  return {
    group,
    posts,
    goals,
    profile
  }
};

const mapDispatch = (dispatch, { history }) => {
  return {
    getGroup: (groupId) => dispatch(getGroup(groupId)),
    myProfile: () => dispatch(myProfile(history)),
    acceptInvite: (group, profileKey) => dispatch(acceptInvite(group, profileKey)),
    fetchGames: () => dispatch(fetchGames()),
    loadMore: (posts, batchPosts, page, pages) => dispatch(loadMore(posts, batchPosts, page, pages))
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(Group));