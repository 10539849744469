export default {
  title: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '55px',
    marginBottom: '20px'
  },
  text: {
    marginLeft: '20px',
    alignSelf: 'center'
  },
  icon: {
    padding: '5px',
    backgroundColor: '#0087b6',
    color: '#fff',
    fontSize: '3rem',
    alignSelf: 'center',
    borderRadius: '10px'
  },
  manageBtn: {
    marginTop: 20,
    color: '#fff',
    backgroundColor:'#00729b',
    '&:hover': {
      backgroundColor:'#005C87'
    },
    '&:focus': {
      backgroundColor:'#005C87'
    },
  },
}