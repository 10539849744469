export default {
  header: {
    gridColumn: 1,
    gridRow: 1,
    flex: '0 0 auto',
    fontSize: '24px',
    color: '#fff',
    marginLeft: '20px',
  },
  toolbar: {
    display: 'grid',
    gridTemplateColumns: '4fr 5fr auto',
    gridTemplateRows: '1fr',
    backgroundColor: '#00729b',
    boxShadow: ' 0px 3px 3px #6b6b6b',
  },
  actions: {
    gridColumn: 2,
    gridRow: 1,
    justifySelf: 'end',
    marginRight: '20px',
  },
  searchBar: {
    color: '#000',
    fontWeight: 600,
    width: '320px',
    marginLeft: '10px',
    backgroundColor: 'white',
    borderRadius: '4px',
    height: 40
  },
  close: {
    gridColumn: 3,
    color: '#fff'
  },
  icon: {
    color: '#fff',
    fontWeight: 800,
  },
  underline: {
    '&:before': {
      borderBottom: '1px solid #fff',
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: '1px solid #fff',
    },
    '&:hover': {
      borderBottom: '1px solid #fff',
    },
    '&:after': {
      borderBottom: '1px solid #fff',
    }
  },
  searchIcon: {
    color: '#fff',
  },
  list: {
    borderRadius: '5px',
    width: 1200,
  },
  tableBookShare: {
    height: '330px',
  },
  buttons: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr'
  },
  delete: {
    gridColumn: 1
  },
  edit: {
    gridColumn: 2
  },
  selected: {
    backgroundColor: '#c7c2cd',
  },
  import: {
    paddingRight: 20
  },
  disabled: {},
  focused: {},
  error: {},
  post: {
    padding: '10px 20px',
    margin: 20,
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    '& .name':{
      gridRow: 1
    },
    '& .description': {
      gridRow: 2
    },
    '& .import': {
      gridRow: '1/ span 4',
      gridColumn: 2,
      alignSelf: 'center'
    }
  },
  footer: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridColumn: '1',
    alignSelf: 'center',
    justifySelf: 'start',
    borderRadius: 15,
    marginTop: 10,
    padding: '5px 20px'
  },
  pagination: {
    alignSelf: 'end'
  },
  comment: {
    display: 'grid',
    margin: '15px 0',
    gridTemplateColumns: 'auto 1fr',
    '& .icon': {
      color: 'black',
      marginRight: 10,
      paddingRight: 10,
      alignSelf: 'center'
    }
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#fff !important'
  },
  cssOutlinedInput: {
    color: '#fff',
    '&$cssFocused $notchedOutline': {
      borderColor: `#fff !important`,
    }
  },
  cssFocused: {},
  tabs: {
    margin: '0 10px',
  },
  finalizeBtn: {
    display: 'grid',
    alignItems: 'end',
    margin: '0 40%',
  },
  setup: {
    display: 'grid',
    justifyContent: 'center'
  },
  goalName: {
    display: 'grid',
    marginBottom: 20,
    gridTemplateColumns: 'auto 1fr',
    gridColumnGap: 10,
    '& .title': {
      gridColumn: 1,
      alignSelf: 'center',
    },
    '& .input': {
      gridColumn: 2
    }
  },
  searchField: {
    padding: 11,
    background: 'white'
  },
  brailleButton: {
    gridRow: 2,
    gridColumn: 2,
    borderRadius: '4px',
    height: 39,
    maxHeight: 39
  },
  loading: {
    margin: '0 auto',
    display: 'block',
    width: '75%',
  },
  importLoadingPage: {
    borderRadius: '5px',
    overflowX: 'auto',
  },
  exitIcon: {
    float: 'right',
    width: '48px'
  },
  exitBookShareIcon: {
    position: 'absolute',
    right: '10px',
    width: '48px'
  },
  loadingText: {
    padding: 40,
    textAlign: 'center'
  },
  loadingBtnContainer: {
    padding: '10px 40px',
    display: 'flex',
    justifyContent: 'right'
  }
}