import { FETCH_MY_BRAILLE_SHEETS, CREATE_MY_BRAILLE_SHEET } from '../../constants';

export const myBrailleSheets = (state = [], action) => {
  switch (action.type){
    case FETCH_MY_BRAILLE_SHEETS:
      return action.brailleSheets;
    case CREATE_MY_BRAILLE_SHEET:
      return [...state, action.brailleSheet];
    default:
      return state;
  }
};