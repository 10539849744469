export const FETCH_ALL_SKILL_DATABANKS = 'FETCH_ALL_SKILL_DATABANKS';
export const CREATE_SKILL_DATABANK = 'CREATE_SKILL_DATABANK';
export const GET_SKILL_DATABANK = 'GET_SKILL_DATABANK';
export const PATCH_SKILL_DATABANK = 'PATCH_SKILL_DATABANK';
export const DELETE_SKILL_DATABANK = 'DELETE_SKILL_DATABANK';
export const FETCH_STAFF_SKILL_DATABANKS = 'FETCH_STAFF_SKILL_DATABANKS';
export const CREATE_STAFF_SKILL_DATABANK = 'CREATE_STAFF_SKILL_DATABANK';
export const PATCH_STAFF_SKILL_DATABANK = 'PATCH_STAFF_SKILL_DATABANK';
export const DELETE_STAFF_SKILL_DATABANK = 'DELETE_STAFF_SKILL_DATABANK';
export const CLEAR_DATABANK = 'CLEAR_DATABANK';
export const FETCH_STORY_DATA = 'FETCH_STORY_DATA';
export const UPDATE_STORY_DATA = 'UPDATE_STORY_DATA';
export const FETCH_STORY_DATA_SECTION = 'FETCH_STORY_DATA_SECTION';
export const UPDATE_STORY_DATA_SECTION = 'UPDATE_STORY_DATA_SECTION';
export const RESET_STORY_DATA_SECTION = 'RESET_STORY_DATA_SECTION';