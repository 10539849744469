import { CLEAR_ERRORS, HANDLE_ERROR } from '../constants';

export const clearErrors = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ERRORS
    })
  }
};

export const handleError = (err) => {
  return (dispatch) => {
    dispatch({
      type: HANDLE_ERROR,
      error: err.response
    })
  }
};