import {
    FETCH_ALL_MODELS,
    FETCH_MODEL_BY_ID,
    FETCH_SPEECH_DATA,
    FETCH_TRAINING_TYPES,
    CREATE_TRAINING_DATA,
    ROOT_URL
} from '../../constants';
import axios from 'axios';

axios.defaults.withCredentials = true;

export const fetchAllModels = () => {
    const url = `${ROOT_URL}/students/1/speech-model`;
    return (dispatch) => {
        axios
            .get(url)
            .then((response) => {
                console.log(response.data);
                dispatch({
                    type: FETCH_ALL_MODELS,
                    models: response.data
                })
            })
            .catch(err => console.error(err))
    }
};

export const fetchModelById = (id) => {
    const url = `${ROOT_URL}/students/1/speech-model/${id}`;
    return (dispatch) => {
        axios
            .get(url)
            .then((response) => {
                dispatch({
                    type: FETCH_MODEL_BY_ID,
                    model: response.data
                })
            })
            .catch(err => console.error(err))
    }
};

export const fetchSpeechDataForStudent = (studentId) => {
    const url = `${ROOT_URL}/students/${studentId}/speech-data`;
    return (dispatch) => {
        axios
            .get(url)
            .then((response) => {
                dispatch({
                    type: FETCH_SPEECH_DATA,
                    speechData: response.data
                })
            })
            .catch(err => console.error(err))
    }
};

export const fetchTrainingTypes = (studentId) => {
    const url = `${ROOT_URL}/students/${studentId}/training-types`;
    return (dispatch) => {
        console.log('training-types');
        axios
            .get(url)
            .then((response) => {
                console.log(response.data);
                dispatch({
                    type: FETCH_TRAINING_TYPES,
                    trainingTypes: response.data
                })
            })
            .catch(err => console.error(err))
    }
};

export const createTrainingData = (studentId, modelId) => {
    const url = `${ROOT_URL}/students/${studentId}/train-speech/${modelId}`;
    axios
        .post(url)
        .catch(err => console.log(err))
}