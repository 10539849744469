import {
    FETCH_ALL_STUDENTS,
    CREATE_STUDENT,
    PATCH_STUDENT,
    GET_STUDENT,
    FETCH_STUDENT_TUTORIALS,
    DELETE_STUDENT_TUTORIAL,
    REMOVE_DELETED_STUDENT,
    REMOVE_STUDENT,
    RESET,
    GET_STUDENT_PROFILE, PATCH_STUDENT_PROFILE, CREATE_STUDENT_PROFILE,
    GET_STUDENTS_STAFF,
    FETCH_DELETED_STUDENTS,
    FETCH_INFO
} from '../../constants';

export const students = (state = [], action) => {
    switch (action.type) {
        case FETCH_ALL_STUDENTS:
            return action.students;
        case CREATE_STUDENT:
            return [...state, action.student];
        case REMOVE_STUDENT:
            return state.filter(student => student.id !== action.student.id)
        case PATCH_STUDENT:
            return state.map(student => student.id === action.student.id ? action.student : student);
        default:
            return state;
    }
};

export const deletedStudents = (state = [], action) => {
    switch (action.type) {
        case FETCH_DELETED_STUDENTS:
            return action.deletedStudents;
        case REMOVE_DELETED_STUDENT:
            return state.filter(student => student.id !== action.student.id)
        default:
            return state;
    }
};

export const student = (state = {}, action) => {
    switch (action.type) {
        case GET_STUDENT:
            return action.student;
        case CREATE_STUDENT:
            return action.student;
        case PATCH_STUDENT:
            return action.student;
        case RESET:
            return {};
        default:
            return state;
    }
};

export const studentProfile = (state = {}, action) => {
    switch (action.type) {
        case GET_STUDENT_PROFILE:
            return action.studentProfile;
        case CREATE_STUDENT_PROFILE:
            return action.studentProfile;
        case PATCH_STUDENT_PROFILE:
            return action.studentProfile;
        default:
            return state;
    }
};

export const studentTutorials = (state = [], action) => {
    switch (action.type) {
        case FETCH_STUDENT_TUTORIALS:
            return action.tutorials;
        case DELETE_STUDENT_TUTORIAL:
            return state.filter(tutorial => tutorial.id !== action.tutorial.id);
        default:
            return state;
    }
};

export const studentStaff = (state = [], action) => {
    switch (action.type) {
        case GET_STUDENTS_STAFF:
            return action.studentStaff;
        default:
            return state;
    }
}

export const studentBooksDetails = (state = {}, action) => {
    switch (action.type) {
        case FETCH_INFO:
            return action.payload;
        default:
            return state;
    }
}