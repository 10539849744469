import {
  CREATE_HELP_QUESTION,
  UPDATE_HELP_QUESTION,
  DELETE_HELP_PAGE,
  CREATE_HELP_PAGE,
  UPDATE_HELP_PAGE,
  FETCH_HELP_PAGES,
  CREATE_HELP_GUIDE,
  UPDATE_HELP_GUIDE,
  DELETE_HELP_GUIDE,
  DELETE_HELP_QUESTION,
  FETCH_ONLINE_USERS,
  FETCH_MESSAGES,
  ADD_MESSAGE
} from '../constants/help';

export const helpPages = (state = [], action) => {
  switch(action.type){
    case FETCH_HELP_PAGES:
      return action.helpPages;
    case CREATE_HELP_PAGE:
      return [...state, action.helpPage];
    case CREATE_HELP_QUESTION:
      return state.map(helpPage => {
        return helpPage._id === action.helpPage.id
          ? {...helpPage, questions: [...helpPage.questions, action.helpPage.question]}
          : helpPage
      });
    case CREATE_HELP_GUIDE:
      return state.map(helpPage => {
        return helpPage._id === action.helpPage.id
          ? {...helpPage, guides: [...helpPage.guides, action.helpPage.guide]}
          : helpPage
      });
    case UPDATE_HELP_QUESTION:
      return state.map(helpPage => (
        helpPage._id === action.question.pageId
          ? {...helpPage, questions: helpPage.questions.map(question => question._id === action.question._id ? action.question : question)}
          : helpPage
      ));
    case UPDATE_HELP_GUIDE:
      return state.map(helpPage => (
        helpPage._id === action.guide.pageId
          ? {...helpPage, guides: helpPage.guides.map(guide => guide._id === action.guide._id ? action.guide : guide)}
          : helpPage
      ));
    case UPDATE_HELP_PAGE:
      return state.map(helpPage => helpPage._id === action.helpPage._id ? action.helpPage : helpPage );
    case DELETE_HELP_PAGE:
      return state.filter(helpPage => helpPage._id !== action.helpPage);
    case DELETE_HELP_GUIDE:
      return state.map(helpPage => (
        helpPage._id === action.guide.pageId
          ? {...helpPage, guides: helpPage.guides.filter(guide => guide._id !== action.guide._id)}
          : helpPage
      ));
    case DELETE_HELP_QUESTION:
      return state.map(helpPage => (
        helpPage._id === action.question.pageId
          ? {...helpPage, questions: helpPage.questions.filter(question => question._id !== action.question._id)}
          : helpPage
      ));
    default:
      return state;
  }
};

export const onlineUsers = (state = [], action) => {
  switch(action.type){
    case FETCH_ONLINE_USERS:
      return action.onlineUsers;
    default:
      return state
  }
};

export const messages = (state = [], action) => {
  switch(action.type){
    case FETCH_MESSAGES:
      return action.messages;
    case ADD_MESSAGE:
      return [...state, action.message];
    default:
      return state;
  }
}