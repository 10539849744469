import {
    IconButton,
    Paper, Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel, Toolbar, Tooltip, Typography, withStyles
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { getSorting, stableSort } from '../../../helpers';
import { deleteBookshareRequestedBook } from '../../../redux/actions/platform/books';
import styles from '../../../styles/browseStory.js';

const rows = [
    { id: 'title', label: <FormattedMessage id='General.title' /> },
    { id: 'author', label: <FormattedMessage id='General.author' /> },
    { id: 'synopsis', label: <FormattedMessage id='General.synopsis' /> },
    { id: 'isbn', label: <FormattedMessage id='General.isbn' /> }
];
class BookShareRequestUserTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            order: 'asc',
            orderBy: 'difficulty',
            page: 0,
            rowsPerPage: 5
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.data != prevProps.data) {
            this.setState({
                page: 0
            });
        }
    }
    handleSort = (_event, property) => {
        const orderBy = property;
        let order = 'desc';
        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }
        this.setState({ order, orderBy });
    };

    handleChangePage = (_event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };
    handleDelete = (data) => {
        this.props.dispatch(deleteBookshareRequestedBook(this.props.studentId, data.storyRequestId));
    }
    render() {
        const { classes, data, noResultFound } = this.props;
        const { orderBy, order, page, rowsPerPage } = this.state;
        let uniqueSet = new Set(data.map(JSON.stringify));
        let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
        return (
            <>
                <br />
                <Paper>
                    <Toolbar classes={{ root: classes.toolbarBookshare }}>
                        <Typography style={{
                            // gridColumn: 1,
                            // gridRow: 1,
                            flex: '0 0 auto',
                            fontSize: '20px',
                            color: '#fff',
                            marginLeft: "20px"
                        }}>
                            <strong>Bookshare Requests (24-48 Hour Processing Time)</strong>
                        </Typography>
                    </Toolbar>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    key={'titleImg'}
                                >
                                    Image
                                </TableCell>
                                {rows.length &&
                                    rows.map(row => {
                                        return (
                                            <TableCell
                                                key={row.id}
                                                sortDirection={orderBy === row.id ? order : false}
                                            >
                                                <Tooltip
                                                    title={<FormattedMessage id='General.sort' />}
                                                    placement='bottom-start'
                                                    enterDelay={300}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === row.id}
                                                        direction={order}
                                                        onClick={(ev) => this.handleSort(ev, row.id)}
                                                    >
                                                        {row.label}
                                                    </TableSortLabel>
                                                </Tooltip>
                                            </TableCell>
                                        )
                                    })
                                }
                                <TableCell align='center'> Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {uniqueArray && uniqueArray.length > 0 ?
                                stableSort(uniqueArray, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map(_data => {
                                        return (
                                            <TableRow
                                                hover
                                                key={_data.id}
                                            >
                                                <TableCell>
                                                    {
                                                        _data.titleImage && _data.titleImage !== ' '
                                                            ? <img src={_data.titleImage} width={100} height={150} alt={_data.name} />
                                                            : 'NA'
                                                    }
                                                </TableCell>
                                                {rows.length &&
                                                    rows.map((row, index) => {
                                                        let cellData = _data[row.id] && _data[row.id] !== ' ' && _data[row.id] != 'null'
                                                            ? _data[row.id]
                                                            : '';
                                                        return (
                                                            <TableCell
                                                                key={index}
                                                                dangerouslySetInnerHTML={{ __html: cellData }}
                                                            >
                                                            </TableCell>
                                                        )
                                                    })
                                                }
                                                <TableCell>
                                                    <Tooltip title={<FormattedMessage id="General.delete" />}>
                                                        <IconButton aria-label='delete' onClick={() => this.handleDelete(_data)}>
                                                            <Delete color='secondary' />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                : noResultFound &&
                                <TableRow>
                                    <TableCell />
                                    <TableCell>
                                        <h4>No Results Found</h4>
                                    </TableCell>
                                    <TableCell />
                                    <TableCell />
                                    <TableCell />
                                </TableRow>
                            }

                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15]}
                        component='div'
                        count={uniqueArray.length}
                        labelRowsPerPage={<FormattedMessage id='Datatable.rowsPerPage' />}
                        rowsPerPage={this.state.rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': 'Previous Page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Next Page',
                        }}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />
                </Paper>
            </>
        )
    }
}

const mapState = ({ platform: { books, bookShareList, storyList, skillDatabanks, student, bookShareUsers, importBooks, booksImport }, shared: { user } }) => {
    return {
        books,
        bookShareList,
        storyList,
        skillDatabanks,
        user,
        booksImport,
        bookShareUsers,
        student,
        importBooks,


    }
};

export default connect(mapState)(withStyles(styles)(BookShareRequestUserTable));