import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  Snackbar,
  withStyles, InputAdornment
} from '@material-ui/core';
import { fetchSupportUsers, loginAsSupport } from '../../../redux/actions';
import { getSorting, stableSort } from '../../../helpers';
import { ROOT_URL } from '../../../redux/constants';
import baseStyle from '../../../styles/skillList.js';
import { Search } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

class SupportUserList extends Component {

  state = {
    order: 'asc',
    orderBy: this.props.orderBy,
    rowsPerPage: 10,
    page: 0,
    searchCategory: 'firstName',
    filteredData: [],
    searchValue: '',
    rows: [
      { id:'firstName', label: 'First Name', searchable: true },
      { id:'lastName', label: 'Last Name', searchable: true },
      { id: 'type', label: 'Type', searchable: true},
      { id: 'email', label: 'Email', searchable: true},
    ],
    link: {},
    user: {},
    open: false
  };

  componentDidMount() {
    this.props.fetchSupportUsers();
  }

  handleSearch = (searchValue) => {
    const { supportUsers } = this.props;
    const filtered = supportUsers.filter(item => {
      const searchableKeys = Object.keys(item).filter(key => [...this.state.rows, {id: 'name'}].some(row => row.id === key));
      return searchableKeys.some(key => {
        if (item[key]){
          return item[key].toLowerCase().includes(searchValue.toLowerCase())
        }
        else {
          return false
        }
      })
    });
    this.setState({ filteredData: filtered, page: 0 })
  };

  handleSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    this.setState({ order, orderBy });
  };

  handleClick = (data) => {
    this.props.loginAsSupport(data);
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleClose = () => {
    this.setState({ open: false })
  };

  supportPasswordReset = (user) => {
    const url = `${ROOT_URL}/support/reset-password`;
    axios
      .post(url, user)
      .then((response) => {
        this.setState({ link: response.data, user, open: true })
      });
  };

  reset = () => {
    this.setState({ redirect: true })
  };

  render() {
    const { classes } = this.props;
    const { rows, orderBy, order, rowsPerPage, page, searchValue, filteredData } = this.state;
    const data = filteredData.length || searchValue ? filteredData : this.props.supportUsers;
    return (
      <Paper className={ classes.list }>
        <Toolbar classes={{ root: classes.toolbar }}>
          <Typography noWrap className={ classes.header }>
            <strong><FormattedMessage id='Datatable.support'/></strong>
          </Typography>
          <div className={classes.actions}>
            <TextField
              className={ classes.search }
              variant='outlined'
              id='table-search'
              aria-label='search input'
              type={'search'}
              InputProps={{
                inputProps: {
                  'aria-label':'search input'
                },
                className: classes.searchBar,
                endAdornment: (
                  <InputAdornment position='end'>
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={(ev) => {
                this.handleSearch(ev.target.value);
                this.setState({ searchValue: ev.target.value });
              }}
            />
          </div>
        </Toolbar>
        {
          this.state.link && this.state.user.id &&
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.open}
            autoHideDuration={6000}
            onClose={this.handleClose}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={
              <span id="message-id">
                <FormattedMessage id='Datatable.resetConfirm'/> {this.state.user.firstName} {this.state.user.lastName}<FormattedMessage id='Datatable.resetConfirm2'/>
              </span>
              }
            action={[
              <Button key="yes" color="primary" size="small">
                <a href={this.state.link}><FormattedMessage id='General.yes'/></a>
              </Button>,
              <Button key="no" color="secondary" size="small" onClick={this.handleClose}>
                <FormattedMessage id='General.no'/>
              </Button>,
            ]}
          />
        }
        <div className={classes.tableWrapper}>
          <Table>
            <TableHead>
              <TableRow>
                { rows.length &&
                rows.map(row => {
                  return (
                    <TableCell
                      key={row.id}
                      sortDirection={ orderBy === row.id ? order : false }
                    >
                      <Tooltip
                        title={<FormattedMessage id='General.sort'/>}
                        placement='bottom-start'
                        enterDelay={300}
                      >
                        <TableSortLabel
                          active={orderBy === row.id}
                          direction={order}
                          onClick={(ev) => this.handleSort(ev, row.id)}
                        >
                          {row.label}
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                  )
                })
                }
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(data, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(_data => {
                  return (
                    <TableRow
                      hover
                      key={_data.id}
                    >
                      {rows.length &&
                      rows.map((row, index) => {
                        return (
                          <TableCell
                            onClick={() => this.handleClick(_data)}
                            key={index}
                          >
                            {
                              _data[row.id] && _data[row.id] !== ' '
                                ? _data[row.id]
                                : 'N/A'
                            }
                          </TableCell>
                        )
                      })
                      }
                      <TableCell>
                        <Button variant='contained' onClick={()=> this.supportPasswordReset(_data)}>
                          <FormattedMessage id='Datatable.resetPW'/>
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={this.props.rowsPerPage}
          component='div'
          labelRowsPerPage={<FormattedMessage id='Datatable.rowsPerPage'/>}
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

const mapState = ({ platform: { supportUsers }}) => {
  return {
    supportUsers
  }
};

const mapDispatch = (dispatch, {history}) => {
  return {
    fetchSupportUsers: () => dispatch(fetchSupportUsers()),
    loginAsSupport: (user) => dispatch(loginAsSupport(user, history)),
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(SupportUserList));