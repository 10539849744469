import React, { Component } from 'react';
import { IconButton, withStyles } from '@material-ui/core';
import { ContactSupport } from '@material-ui/icons';
import styles from '../../../styles/chatbox';
import ChatBox from './ChatBox';
import { fetchOnlineUsers } from '../../../socket-client';
import { connect } from 'react-redux';

class ChatBaseButton extends Component {

  state = {
    show: this.props.show,
    support: false
  };

  componentDidMount() {
    // uncomment for chat (super buggy right now)
    // this.props.fetchOnlineUsers();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    // if (this.props.onlineUsers.length !== nextProps.onlineUsers.length){
    //   this.props.fetchOnlineUsers();
    // }
  }

  openChatWindow = () => {
    this.setState({ show: true, support: true })
  };

  closeChatWindow = () => {
    this.setState({ show: false })
  };

  render() {
    const {classes} = this.props;
    return (
      <div className={classes.root}>
        {
          this.state.show &&
          <ChatBox support={this.state.support} room={this.props.messages.length > 0 ? this.props.messages[0].room : undefined} closeChatWindow={this.closeChatWindow}/>
        }
        {
          !this.state.show && this.props.support &&
          <IconButton onClick={this.openChatWindow}>
            <ContactSupport className={classes.chatBtn}/>
          </IconButton>
        }
      </div>
    );
  }
}

const mapState = ({ shared: { onlineUsers }}) => {
  return {
    onlineUsers
  }
};

const mapDispatch = (dispatch) => {
  return {
    fetchOnlineUsers: () => dispatch(fetchOnlineUsers()),
  }
};

export default connect(mapState, mapDispatch)(withStyles(styles)(ChatBaseButton));