import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Select from 'react-select';
import { Button, Typography, IconButton, FormGroup, TextField, MenuItem } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { fetchLevels } from '../../../../redux/actions';
import baseStyle from '../../../../styles/wizardBase';
import { ROOT_URL } from '../../../../redux/constants';
import { FormattedMessage } from 'react-intl';

class SkillDatabaseWizardPage2 extends Component {

  state = {
    tested: false,
    variables: {},
    level: {},
    selectedLevel: '',
    difficulty: ''
  };

  componentDidMount() {
    this.props.fetchLevels();
  }

  close = () => {
    this.props.close();
  };

  formatName = (name) => {
    return name
      .replace(/([A-Z])/g, ` $1`)
      .replace(/^./, (str) => str.toUpperCase())
  };

  parseVariables = () => {
    const { classes } = this.props;
    const { level } = this.state;
    const variables = level.levelVariables ? JSON.parse(level.levelVariables) : [];
    return (
      variables.length && variables.map(variable => {
        const name = variable.name;
        // TODO: not good practice, but if I call setstate, it will infinite loop
        if (variable.type === 'static') this.state.variables[name] = variable.value; // eslint-disable-line
        switch(variable.type) {
          case 'prompt':
            const options = variable.value.map(option => ({ value: option, label: option }));
            return (
              <div className={classes.configureLine} key={name}>
                <strong className={classes.configTitle}>{this.formatName(name)}</strong>
                <Select
                  className={classes.configComponent}
                  options={options}
                  onChange={(ev) => this.setState({ variables: {...this.state.variables, [name]: ev.value}  })}
                />
              </div>
            );
          case 'static':
            return null;
          case 'number':
            return (
              <div className={classes.configureLine} key={name}>
                <strong className={classes.configTitle}>{this.formatName(name)} </strong>
                <TextField
                  variant='outlined'
                  className={classes.configComponent}
                  placeholder={`Enter ${this.formatName(name)}`}
                  type='number'
                  onChange={(ev) => this.setState({ variables: {...this.state.variables, [name]: ev.target.value*1  }})}
                />
              </div>
            );
          default:
            return <div key={'no variable'}>There are no variables to configure</div>
        }
      })
    )
  };

  handleSubmit = (ev) => {
    ev.preventDefault();
    const { level, difficulty, variables } = this.state;
    const levelJson = JSON.stringify(variables);
    this.props.onSubmit({ levelTemplateId: level.id, levelJson, difficulty });
  };

  runTest = () => {
    const { variables, tested, level } = this.state;
    const url = this.props.type === 'staff' ? `${ROOT_URL}/staff/${this.props.id}/objective-databank/test`: `${ROOT_URL}/admin/objective-databank/test`;
    if (tested) this.clearTest();
    axios.post(url, { levelVariables: JSON.stringify(variables), templateId: level.id })
      .catch(err => console.log(err));
    this.setState({ tested: true });
  };

  clearTest = () => {
    const url = this.props.type === 'staff' ? `${ROOT_URL}/staff/${this.props.id}/objective-databank/test`: `${ROOT_URL}/admin/objective-databank/test`;
    axios.delete(url)
      .catch(err => console.log(err));
    this.setState({ tested: false })
  };

  handleChange = (ev) => {
    ev.target.type === 'number'
      ? this.setState({ [ev.target.name]: ev.target.value * 1 })
      : this.setState({ [ev.target.name]: ev.target.value })
  };

  handleLevelChange = (ev) => {
    const level = this.props.levels.find(level => level.id === ev.target.value);
    this.setState({ variables: JSON.parse(level.levelVariables), level, selectedLevel: ev.target.value })
  };

  render()  {
    const { classes, open, close, back, levels } = this.props;
    const { tested, level } = this.state;
    const gameId = this.props.selectedGame.id;
    const gameLevels = levels.filter(level => level.gameId === gameId);
    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        open={open}
        onClose={close}
        aria-labelledby='skill-dialog-title'
        scroll='paper'
        PaperProps={{ classes:{ root: classes.container }}}
      >
        <DialogTitle disableTypography={true} className={ classes.title }>
          <Typography className={ classes.titleText } variant='h4' id='skill-dialog-title'> <FormattedMessage id='Wizard.skillDB.title'/></Typography>
          <IconButton
            onClick={this.close}
            className={ classes.exitIcon }
            aria-label="close dialog button"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant='h6'><FormattedMessage id='Wizard.skillDB.levelSelect'/></Typography>
          <FormGroup className={ classes.root }>
            <TextField
              select
              variant='outlined'
              label='Levels'
              name='level'
              onChange={this.handleLevelChange}
              value={this.state.selectedLevel}
            >
              {
                gameLevels.map(level => (<MenuItem key={level.id} value={level.id}> {level.name} </MenuItem>))
              }
            </TextField>
          </FormGroup>
          {level.id &&
          <FormGroup classes={{ root: classes.safariFix }} className={classes.level}>
            <Typography variant='h6'><FormattedMessage id='General.description' />:</Typography> {level.description}
            <div className={classes.configureInfo}>
              <Typography variant='h6'><FormattedMessage id='Wizard.skillDB.levelConfig' />:</Typography>
            </div>
            {
              JSON.parse(level.levelVariables) &&
              this.parseVariables()
            }
            <div className={classes.configureLine}>
              <strong className={classes.configTitle}><FormattedMessage id='General.difficulty'/></strong>
              <TextField
                select
                variant='outlined'
                className={classes.configComponent}
                name='difficulty'
                value={this.state.difficulty}
                onChange={this.handleChange}
              >
                {
                  [
                    { value: 'Easy', text: <FormattedMessage id='General.easy' /> },
                    { value: 'Medium', text: <FormattedMessage id='General.medium' /> },
                    { value: 'Hard', text: <FormattedMessage id='General.hard' /> }
                  ].map(difficulty => (<MenuItem key={difficulty.value} value={difficulty.value}> {difficulty.text} </MenuItem>))
                }
              </TextField>
            </div>
          </FormGroup>
          }
        </DialogContent>
        <DialogActions className={classes.buttons}>
          <Button onClick={back} color='secondary'>
            <FormattedMessage id='General.back'/>
          </Button>
          <Button
            onClick={ this.runTest }
            variant={ tested ? 'outlined' : 'text' }
            color={ tested ? 'secondary' : 'primary' }
          >
            { tested ? <FormattedMessage id='General.retest' /> : <FormattedMessage id='General.test' /> }
          </Button>
          <Button onClick={this.handleSubmit} variant='contained' color='primary'>
            <FormattedMessage id='General.submit'/>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const  mapState = ({ platform: { levels }}) => {
  return {
    levels
  }
};

const mapDispatch = (dispatch) => {
  return {
    fetchLevels: () => dispatch(fetchLevels())
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(SkillDatabaseWizardPage2));