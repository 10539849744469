import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Logo from '../../../assets/BrandAssets/Logo/OBJECTIVEED-Logo-Vertical-2.png';
import { Drawer, Typography, List, ListItem, Button, Divider } from '@material-ui/core';
import GoalWizard from '../../components/Wizards/Goal/GoalWizardBase';
import { withStyles } from '@material-ui/core/styles';
import { getStudentGoals, getStudent } from '../../../redux/actions';
import baseStyle from '../../../styles/goalDrawer';
import { FormattedMessage } from 'react-intl';

class SecondarySidebar extends Component {
  componentDidMount() {
    this.props.getStudentGoals(this.props.studentId);
    this.props.getStudent(this.props.studentId);
  }

  render(){
    const { classes, history, goals, student, id } = this.props;
    const url = history.location.pathname.split('/').slice(0,-2).join('/');
    return (
      <nav>
      <Drawer classes={{root: classes.margin, paper: classes.drawer}} align='center' variant='permanent'>
        <img className={classes.logo} alt='logo' src={Logo}/>
        <Divider classes={{root: classes.divider}}/>
        <Typography classes={{root: classes.role}} variant='h6'>
          {student.firstName}'s <FormattedMessage id='Goals.title'/>
        </Typography>
        <Divider classes={{root: classes.divider}}/>
        <List classes={{ root: classes.list }}>
          {
            Array.isArray(goals) &&
            goals.sort((a,b) => a.name >= b.name ? 1 : -1).map(goal => {
              return (
                <Button
                  component={Link}
                  key={goal.id}
                  className={ id === goal.id ? classes.active : classes.link }
                  to={`${url}/goals/${goal.id}`}
                  classes={{ root: classes.linkButton }}
                >
                  <ListItem>
                    <Typography className={classes.listItem}>
                      {goal.name}
                    </Typography>
                  </ListItem>
                </Button>
              )
            })
          }
          <GoalWizard id={this.props.studentId} drawer={true}/>
          <Button
            onClick={() => history.push(url)}
            variant='outlined'
            className={ classes.formButton }
          >
            <FormattedMessage id='Sidebar.backToStudents' />
          </Button>
        </List>
      </Drawer>
      </nav>
    )
  }
}

const mapState = ({ platform: { goals, student }}) => {
  return {
    goals,
    student
  }
};

const mapDispatch = (dispatch, {history}) => {
  return {
    getStudentGoals: (studentId) => dispatch(getStudentGoals(studentId, history)),
    getStudent: (studentId) => dispatch(getStudent(studentId))
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(SecondarySidebar));