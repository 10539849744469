import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
  AppBar,
  IconButton,
  MenuItem,
  Typography,
  Menu,
  Dialog,
  DialogContent,
  DialogContentText, DialogActions, Button
} from '@material-ui/core';
import { AccountCircle, Apps, People, Poll, Home } from '@material-ui/icons';
// import NotificationList from '../components/Notifications';
import IdleTimer from "react-idle-timer";
import { withStyles } from '@material-ui/core/styles';
import { fetchNotifications, logout, clearErrors } from '../../redux/actions';
import Logo from '../../assets/BrandAssets/Logo/OBJECTIVEED-Logo-Horizontal-2.png';
import pic3 from '../../assets/SocialMockups/ProfilePictures/pic3.jpg';
import styles from '../../styles/socialHeader';

const sampleNotifications = [
  {
    id: 0,
    groupName: 'NYS TVIs/O&Ms',
    groupId: '1',
    read: false,
    type: 'invite'
  },
  {
    id: 1,
    person: {
      avatar: pic3,
      name: 'Sarah Sonny',
    },
    groupName: 'Newbies',
    groupId: '2',
    type: 'invite',
    read: false
  },
];

const CollisionLink = (url) => React.forwardRef((props, ref) => (
  <Link innerRef={ref} to={url} {...props} />
));

class SocialHeader extends Component {
  constructor(props){
    super(props);
    this.state = {
      account: false,
      notification: false,
      apps: false,
      warning: false,
      time: ''
    };
    this.idleTimer = null;
  }

  componentDidMount(){
    document.body.style.backgroundColor = '#d3e6ff';
    this.props.fetchNotifications(sampleNotifications);
  }

  handleToggle = (name) => {
    this.setState(state => ({ [name]: !state[name] }));
  };

  handleClose = (name) => {
    this.setState(state => ({ [name]: !state[name] }));
  };

  clearError = () => {
    this.props.clearErrors();
  };

  onIdle = () => {
    this.props.logout(this.props.history, {type: 'sessionExp', msg: 'Your session has expired, please log back in'});
    this.props.history.push('/login')
  };

  getTime = () => {
    const milliseconds = this.idleTimer && this.idleTimer.getRemainingTime();
    this.props.getUser();
    if (milliseconds <= 60000 * 5) {
      return this.setState({ warning: true, time: milliseconds })
    }
    if (this.state.warning === true) {
      this.setState(prevState => ({ time: prevState.time - 1000 }));
      if (this.state.time >= 1000) {
        this.onIdle();
      }
    }
  };

  resetTimer = () => {
    const milliseconds = this.idleTimer && this.idleTimer.getRemainingTime();
    this.setState({ warning: false, time: milliseconds })
  };

  render() {
    const { classes, error } = this.props;
    const { account, apps } = this.state;
    return (
      <AppBar classes={{ root: classes.header }}>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          onIdle={this.onIdle}
          debounce={250}
          timeout={60000 * 20}
        />
        <Dialog open={this.state.warning}>
          <DialogContent>
            <DialogContentText>
              Looks like you've been inactive for a bit! You will automatically be logged out in <strong>{moment(this.state.time).format('m:ss')}</strong>.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button aria-label='log out' color='secondary' onClick={this.onIdle}>log out</Button>
            <Button aria-label='stay logged in' color='primary' variant='contained' onClick={this.resetTimer}>stay logged in</Button>
          </DialogActions>
        </Dialog>
        <Link to='/home'>
          <img className={classes.logo} alt='logo' src={Logo}/>
        </Link>
        <div className={classes.buttons}>
          <IconButton
            onClick={this.clearError}
            color='inherit'
            component={CollisionLink('/home')}
          >
            <Home />
          </IconButton>
          {
            !error &&
            <>
              <IconButton
                aria-label='app-switcher'
                color='inherit'
                buttonRef={node => {
                  this.appsAnchor = node;
                }}
                onClick={() => this.handleToggle('apps')}
              >
                <Apps />
                <Menu
                  open={apps}
                  anchorEl={this.appsAnchor}
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  transformOrigin={{ vertical: "top", horizontal: "center" }}
                >
                  <MenuItem component={CollisionLink('/home')} className={classes.app}>
                    <People className={classes.appIcon}/>
                    <Typography variant='subtitle2' className={classes.appText}><FormattedMessage id='Header.network'/></Typography>
                  </MenuItem>
                  <MenuItem component={CollisionLink(this.props.user.homePage)}  className={classes.app}>
                    <Poll className={classes.appIcon} />
                    <Typography variant='subtitle2' className={classes.appText}><FormattedMessage id='Header.platform'/></Typography>
                  </MenuItem>
                </Menu>
              </IconButton>
            </>
          }
          <IconButton
            aria-label='account'
            color='inherit'
            buttonRef={node => {
              this.anchorEl = node;
            }}
            onClick={() => this.handleToggle('account')}
          >
            <AccountCircle />
            <Menu
              open={account}
              anchorEl={this.anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <MenuItem component={CollisionLink('/home')}><FormattedMessage id='Header.profile'/></MenuItem>
              <MenuItem component={CollisionLink('/login')} onClick={this.props.logout}><FormattedMessage id='Header.logout'/></MenuItem>
            </Menu>
          </IconButton>
        </div>
      </AppBar>
    )
  }
}

const mapState = ({ shared: { user }, social: { notifications }}) => {
  return {
    user,
    notifications
  }
};

const mapDispatch = (dispatch, { history }) => {
  return {
    logout: () => dispatch(logout(history)),
    fetchNotifications: (notifications) => dispatch(fetchNotifications(notifications)),
    clearErrors: () => dispatch(clearErrors())
  }
};

export default connect(mapState, mapDispatch)(withStyles(styles)(SocialHeader));