import React, { Component } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { Card, CardContent, Divider, Typography } from '@material-ui/core';
import { BarChart as BarIcon } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../styles/barChart';

const sampleData = [
  {
    name: 'George Washington',
    percent: 80,
    remainder: 20
  },
  {
    name: 'Jim Hawkins',
    percent: 80,
    remainder: 20
  },
  {
    name: 'Steve Martin',
    percent: 80,
    remainder: 20
  },
  {
    name: 'Laurel Hardy',
    percent: 90,
    remainder: 10
  },
];

class MFATime extends Component {

  formatName = (name) => {
    return name
      .replace(/([A-Z0-9])/g, ` $1`)
      .replace(/^./, (str) => str.toUpperCase())
  };

  render(){
    const { classes, title, chart } = this.props;
    const data = chart.data
      ? chart.data.map(student => {
        return {
          name: student.student.name,
          timeUsed: student.timeUsed / 60,
          remainder: (chart.billedTime - student.timeUsed) / 60
        }
      })
      : [];
    return (
      <Card className={classes.barChartCard}>
        <div className={classes.header}>
          <BarIcon className={classes.icon}/>
          <Typography className={classes.chartTitle} variant='h4'>{title}</Typography>
        </div>
        <Divider />
        <CardContent className={classes.barChart}>
          {
            data.length > 0 &&
            <ResponsiveBar
              data={data}
              keys={["timeUsed", "remainder"]}
              indexBy={'name'}
              layout='horizontal'
              margin={{
                top: 20,
                right: 60,
                bottom: 50,
                left: 180
              }}
              defs={[
                {
                  id: 'lines',
                  type: 'patternLines',
                  background: 'inherit',
                  color: '#0087b6',
                  rotation: -45,
                  lineWidth: 2,
                  spacing: 5
                }
              ]}
              fill={[
                { match: { id: 'remainder' }, id: 'lines'}
              ]}
              padding={0.1}
              colorBy='id'
              colors={['#0087b6', '#fff']}
              borderWidth={1}
              borderColor='inherit:darker(1)'
              axisBottom={{
                tickValues: [0, ((chart.billedTime/60)/2), (chart.billedTime/60)],
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor='#000'
              animate={true}
              motionStiffness={90}
              motionDamping={15}
              label={d => `${d.id === 'percent' ? `${d.value}` : ''}`}
              theme={{
                labels: {
                  text: { fontSize: 14, fontWeight: 800 }
                },
                axis: {
                  ticks: {
                    text: { fontSize: 16, fontWeight: 800 }
                  }
                }
              }}
              isInteractive={false}
            />
          }
        </CardContent>
      </Card>
    )
  }
}

export default withStyles(baseStyle)(MFATime);