import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../styles/wizardButton';
import translation from '../../../translations/data'


const WizardToggleButton = (props) => {
  const { classes, intl } = props;
    const usersLocale = (navigator.languages && navigator.languages[0]) || navigator.language;
    const messages = translation[usersLocale] || translation.en;
    return (
    <div>
      <Button
        variant='contained'
        classes={{root: classes.wizardButton}}
        id='wizard-toggle'
        aria-label={props.text}
        onClick={props.openWizard}
        disabled={props.disabled}
      >
        { props.disabled ? <CircularProgress style={{ margin:'1.5px 35px'}} size={20}/> : props.text }
      </Button>
    </div>
  )
};

export default withStyles(styles)(WizardToggleButton);