import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { ROOT_URL } from '../../../../redux/constants';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Typography,
  IconButton,
  FormGroup,
  TextField,
  MenuItem,
} from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { fetchLevels } from '../../../../redux/actions';
import baseStyle from '../../../../styles/wizardBase';

class AudioMemorySkillDatabank extends Component {

  state = {
    tested: false,
    error: {},
    level: this.props.level.id ? this.props.level : {},
    selectedLevel: '',
    difficulty: 'Easy',
    collection: '',
    timedGame: 0,
    lengthOfGame: 120,
    aiOpponent: 0,
    numberOfMatches: 8,
    soundToSound: 0,
    tapControl: 0,
  };

  componentDidMount() {
    this.props.fetchLevels();
    if (this.props.level.id) {
      const variables = JSON.parse(this.props.level.levelJson);
      this.setState({...variables, difficulty: this.props.level.difficulty, level: this.props.level, selectedLevel: this.props.level.id})
    }
    if (this.props.levelTemplate) {
      this.setState({ selectedLevel: this.props.levelTemplate.id})
    }
  }

  close = () => {
    this.props.close();
  };

  runTest = () => {
    const { tested, level, collection, timedGame, lengthOfGame, aiOpponent, numberOfMatches, soundToSound, tapControl } = this.state;
    const url = this.props.type === 'staff' ? `${ROOT_URL}/staff/${this.props.id}/objective-databank/test`: `${ROOT_URL}/admin/objective-databank/test`;
    if (tested) this.clearTest();
    axios.post(url, { levelVariables: JSON.stringify({ collection, timedGame, lengthOfGame, aiOpponent, numberOfMatches, soundToSound, tapControl }), templateId: level.id })
      .catch(err => console.log(err));
    this.setState({ tested: true });
  };

  clearTest = () => {
    const url = this.props.type === 'staff' ? `${ROOT_URL}/staff/${this.props.id}/objective-databank/test`: `${ROOT_URL}/admin/objective-databank/test`;
    axios.delete(url)
      .catch(err => console.log(err));
    this.setState({ tested: false })
  };

  handleChange = (ev) => {
    const value = ev.target.type === 'number'
      ? ev.target.value * 1 > 0 ? ev.target.value * 1 : ''
      : ev.target.value;
    this.setState({ [ev.target.name]: value })
  };

  handleLevelChange = (ev) => {
    const level = this.props.levels.find(level => level.id === ev.target.value);
    const { collection, timedGame, lengthOfGame, aiOpponent, numberOfMatches, soundToSound, tapControl } = JSON.parse(level.levelVariables);
    this.setState({
      collection: collection || '',
      timedGame: timedGame || 0,
      lengthOfGame: lengthOfGame || 0,
      aiOpponent: aiOpponent || 0,
      numberOfMatches: numberOfMatches || 0,
      soundToSound: soundToSound || 0,
      tapControl: tapControl || 0,
      level,
      selectedLevel: ev.target.value
    })
  };

  handleSubmit = (ev) => {
    ev.preventDefault();
    const { level, difficulty, collection, timedGame, lengthOfGame, aiOpponent, numberOfMatches, soundToSound, tapControl } = this.state;
    const levelJson = JSON.stringify({ collection, timedGame, lengthOfGame, aiOpponent, numberOfMatches, soundToSound, tapControl });
    this.props.onSubmit({ levelTemplateId: level.id, levelJson, difficulty });
  };

  checkForErrors = (ev) => {
    if (!ev.target.value){
      this.setState({ error: {...this.state.error, [ev.target.name]: true }})
    }
    else {
      let error = this.state.error;
      delete error[ev.target.name];
      this.setState({ error })
    }
  };

  render()  {
    const { classes, open, close, back, levels, game } = this.props;
    const { tested, level, timedGame, error } = this.state;
    const disabled = this.state.numberOfMatches > 8 || Object.keys(error).length > 0 || this.state.collection === '';
    const gameLevels = levels.filter(level => level.gameId === game.id);
    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        open={open}
        onClose={close}
        aria-labelledby='skill-dialog-title'
        scroll='paper'
        PaperProps={{ classes:{ root: classes.container }}}
      >
        <DialogTitle disableTypography={true} className={ classes.title }>
          <Typography className={ classes.titleText } variant='h4' id='skill-dialog-title'><FormattedMessage id='Wizard.skillDB.title'/></Typography>
          <IconButton
            onClick={this.close}
            className={ classes.exitIcon }
            aria-label="close dialog button"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant='h6'><FormattedMessage id='Wizard.skillDB.levelSelect'/>:</Typography>
          <FormGroup className={ classes.root }>
            <TextField
              select
              variant='outlined'
              label='Levels'
              name='level'
              onChange={this.handleLevelChange}
              value={this.state.selectedLevel}
            >
              {
                gameLevels.map(level => (<MenuItem key={level.id} value={level.id}> {level.name} </MenuItem>))
              }
            </TextField>
          </FormGroup>
          {level.id &&
          <div className={classes.level}>
            <FormGroup className={classes.configureLine}>
              <Typography className={classes.configTitle} variant='h6'><FormattedMessage id='General.description' />: </Typography>
              <br />
              <Typography variant='subtitle2'>{level.description}</Typography>
            </FormGroup>
            <FormGroup className={classes.configureLine}>
              <Typography className={classes.configTitle} variant='h6'><FormattedMessage id='Wizard.AudioMemory.collection' />: </Typography>
              <TextField
                select
                variant='outlined'
                className={classes.configComponent}
                name='collection'
                SelectProps={{
                  type: 'select'
                }}
                value={this.state.collection}
                onChange={this.handleChange}
              >
                <MenuItem value={0}> <FormattedMessage id='Wizard.AudioMemory.animals'/> </MenuItem>
                <MenuItem value={1}> <FormattedMessage id='Wizard.AudioMemory.instruments'/> </MenuItem>
                <MenuItem value={2}> <FormattedMessage id='Wizard.AudioMemory.vehicles'/> </MenuItem>
                <MenuItem value={3}> <FormattedMessage id='Wizard.AudioMemory.indoors'/> </MenuItem>
                <MenuItem value={4}> <FormattedMessage id='Wizard.AudioMemory.toys'/> </MenuItem>
              </TextField>
            </FormGroup>
            {
              timedGame
                ? <FormGroup className={classes.configureLine}>
                  <Typography className={classes.configTitle} variant='h6'><FormattedMessage id='Wizard.AudioMemory.length' />: </Typography>
                  <TextField
                    variant='outlined'
                    className={classes.configComponent}
                    name='lengthOfGame'
                    type='number'
                    error={error.lengthOfGame}
                    helperText={error.lengthOfGame ? '* required' : ''}
                    value={this.state.lengthOfGame}
                    onChange={this.handleChange}
                    onBlur={this.checkForErrors}
                  />
                </FormGroup>
                : <FormGroup className={classes.configureLine}>
                  <Typography className={classes.configTitle} variant='h6'><FormattedMessage id='Wizard.AudioMemory.opponent' />: </Typography>
                  <TextField
                    select
                    variant='outlined'
                    className={classes.configComponent}
                    name='aiOpponent'
                    type='text'
                    helperText={<FormattedMessage id='Wizard.AudioMemory.helperText'/>}
                    value={this.state.aiOpponent}
                    onChange={this.handleChange}
                  >
                    <MenuItem value={0}> Cheetah - 0 </MenuItem>
                    <MenuItem value={1}> Rabbit - 1 </MenuItem>
                    <MenuItem value={2}> Squirrel - 2 </MenuItem>
                    <MenuItem value={3}> Horse - 3 </MenuItem>
                    <MenuItem value={4}> Cat - 4 </MenuItem>
                    <MenuItem value={5}> Dog - 5 </MenuItem>
                    <MenuItem value={6}> Owl - 6 </MenuItem>
                    <MenuItem value={7}> Elephant - 7 </MenuItem>
                    <MenuItem value={8}> Turtle - 8 </MenuItem>
                  </TextField>
                </FormGroup>
            }
            <FormGroup className={classes.configureLine}>
              <Typography className={classes.configTitle} variant='h6'><FormattedMessage id='Wizard.AudioMemory.matches' />: </Typography>
              <TextField
                variant='outlined'
                className={classes.configComponent}
                name='numberOfMatches'
                type='number'
                error={this.state.numberOfMatches > 8 || error.numberOfMatches}
                helperText={error.numberOfMatches ? '* required' : this.state.numberOfMatches > 8 && <FormattedMessage id='Wizard.AudioMemory.error'/>}
                value={this.state.numberOfMatches}
                onBlur={this.checkForErrors}
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup className={classes.configureLine}>
              <Typography className={classes.configTitle} variant='h6'><FormattedMessage id='General.difficulty' />: </Typography>
              <TextField
                select
                variant='outlined'
                className={classes.configComponent}
                name='difficulty'
                value={this.state.difficulty}
                onChange={this.handleChange}
              >
                {
                  [
                    { value: 'Easy', text: <FormattedMessage id='General.easy' /> },
                    { value: 'Medium', text: <FormattedMessage id='General.medium' /> },
                    { value: 'Hard', text: <FormattedMessage id='General.hard' /> }
                  ].map(difficulty => (<MenuItem key={difficulty.value} value={difficulty.value}> {difficulty.text} </MenuItem>))
                }
              </TextField>
            </FormGroup>
          </div>
          }
        </DialogContent>
        <DialogActions className={classes.buttons}>
          <Button onClick={back} color='secondary'>
            <FormattedMessage id='General.back' />
          </Button>
          <Button
            onClick={ this.runTest }
            variant={ tested ? 'outlined' : 'text' }
            color={ tested ? 'secondary' : 'primary' }
          >
            { tested ? <FormattedMessage id='General.retest' /> : <FormattedMessage id='General.test' /> }
          </Button>
          <Button onClick={this.handleSubmit} disabled={disabled} color='primary'>
            <FormattedMessage id='General.next' />
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const  mapState = ({ platform: { levels }}) => {
  return {
    levels
  }
};

const mapDispatch = (dispatch) => {
  return {
    fetchLevels: () => dispatch(fetchLevels())
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(AudioMemorySkillDatabank));