import {
  ROOT_URL, HANDLE_ERROR, FETCH_SLIDER_MANAGEMENT_LIST,
  DELETE_SLIDER_MANAGEMENT_LIST, CREATE_SLIDER_MANAGEMENT_LIST,
  PATCH_SLIDER_MANAGEMENT_LIST,
  ADD_STORY_TO_STORY_LIST
} from '../../constants';
import axios from 'axios';

export const fetchSliderList = (history) => {
    const url = `${ROOT_URL}/buddy-books/admin/story-lists`;
    return (dispatch) => {
      axios
        .get(url)
        .then((response) => {
          if (typeof(response.data) === 'object'){
            dispatch({
              type: FETCH_SLIDER_MANAGEMENT_LIST,
              sliderList: response.data
            })
          }
          else {
           history.push('/login');
            dispatch({
              type: HANDLE_ERROR,
              error: {type: 'sessionExp', msg: 'Your session has expired, please log back in'}
            })
          }
        })
        .catch(err => console.log(err))
    }
}
  
export const createSliderList = (slider) => {
  const url = `${ROOT_URL}/buddy-books/admin/story-lists`;
  return (dispatch) => {
    axios
      .post(url, slider)
      .then((response) => {
        dispatch({
          type: CREATE_SLIDER_MANAGEMENT_LIST,
          sliderList: response.data
        })
      })
      .catch(err => console.log({err}))
  }
};

export const patchSliderList = (slider) => {
  const url =`${ROOT_URL}/buddy-books/admin/story-lists`;
  return (dispatch) => {
    axios
      .put(url, slider)
      .then(() => {
        dispatch({
          type: PATCH_SLIDER_MANAGEMENT_LIST,
          slider
        })
      })
      .catch(err => console.log(err))
  }
};

export const deleteSliderList = (slider) => {
  const url =`${ROOT_URL}/buddy-books/admin/story-lists`;
  return (dispatch) => {
    axios
      .delete(url, { data: slider })
      .then(() => {
        dispatch({
          type: DELETE_SLIDER_MANAGEMENT_LIST,
          slider
        })
      })
      .catch(err => console.log(err))
  }
};

export const addStoryToStoryList = (data, listId) => {
  const url = `${ROOT_URL}/buddy-books/admin/story-lists/${listId}/story-data`;
  return (dispatch) => {
    axios
      .post(url, data)
      .then((response) => {
        if(response && response.status===200){
          dispatch({
            type: ADD_STORY_TO_STORY_LIST,
            slider: response.data
          })
        }
      })
      .catch(err => console.log({ err }))
  }
}

export const addSeriesToStoryList = (listId,data) => {
  const url = `${ROOT_URL}/buddy-books/admin/story-lists/${listId}/series`;
  return (dispatch) => {
    axios
      .post(url,data)
      .then((response) => {
        console.log('response',response)
        if(response && response.status===200){
          dispatch({
            type: ADD_STORY_TO_STORY_LIST,
            slider: response.data
          })
        }
      })
      .catch(err => console.log({ err }))
  }
}