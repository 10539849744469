import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  IconButton, TextField,
  Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../../styles/wizardBase';
import { updateAccount } from '../../../../redux/actions';
import { FormattedMessage } from 'react-intl';
import { Close } from '@material-ui/icons';

class AccountEdit extends React.Component {
  state = {
    name: this.props.data ? this.props.data.name : '',
    billingCount: this.props.data ? this.props.data.billingCount : '',
    brailleAIBillingCount: this.props.data ? this.props.data.brailleAIBillingCount : '',
    domain: this.props.data ? this.props.data.domain : '',
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.open !== nextProps.open) {
      this.setState({ open: nextProps.open })
    }
  }

  openWizard = () => {
    this.setState({ open: true })
  };

  closeWizard = () => {
    this.props.close();
  };

  submit = () => {
    const {  name, billingCount, brailleAIBillingCount, domain } = this.state;
    this.props.updateAccount({id: this.props.data.id, name, billingCount, brailleAIBillingCount, domain});
    this.closeWizard();
  };


  handleChange = (ev) => {
    this.setState({ [ev.target.name]: ev.target.value })
  };

  render(){
    const { classes, data } = this.props;
    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        open={this.props.open}
        onClose={this.props.close}
        aria-labelledby='form-dialog-title'
        PaperProps={{ classes:{ root: classes.container }}}
      >
        <DialogTitle disableTypography={true} className={ classes.title } id='form-dialog-title'>
          <Typography className={ classes.titleText } variant='h4'>
            Edit Account
          </Typography>
          <IconButton
            onClick={this.props.close}
            className={ classes.exitIcon }
            aria-label="close dialog"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormGroup classes={{ root: classes.root }}>
            <TextField
              classes={{ root: classes.firstName }}
              variant='outlined'
              id='account-name'
              label={<FormattedMessage id='General.name'/>}
              type='text'
              name='name'
              value={this.state.name}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup classes={{ root: classes.root }}>
            <TextField
              classes={{ root: classes.firstName }}
              variant='outlined'
              id='billing-count'
              label='Student Billing Count'
              type='text'
              name='billingCount'
              value={this.state.billingCount}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup classes={{ root: classes.root }}>
            <TextField
              classes={{ root: classes.firstName }}
              variant='outlined'
              id='braille-ai-billing-count'
              label='Braille AI Billing Count'
              type='text'
              name='brailleAIBillingCount'
              value={this.state.brailleAIBillingCount}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup classes={{ root: classes.root }}>
            <TextField
                classes={{ root: classes.firstName }}
                variant='outlined'
                id='domain'
                label='Domain'
                type='text'
                name='domain'
                value={this.state.domain}
                onChange={this.handleChange}
            />
          </FormGroup>
        </DialogContent>
        <DialogActions className={classes.buttons}>
          <Button onClick={this.props.close} color='secondary'>
            <FormattedMessage id='General.cancel'/>
          </Button>
          <Button onClick={this.submit} variant='contained' color='primary'>
            <FormattedMessage id='General.save'/>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapState = ({ platform: {  }}) => {
  return {
  }
};

const mapDispatch = (dispatch) => {
  return {
    updateAccount: (account) => dispatch(updateAccount(account))
  }
};

export default connect(mapState, mapDispatch)(withStyles(styles)(AccountEdit));