import React, {Component} from 'react';
import {connect} from 'react-redux';
import {ResponsiveBar, Bar} from '@nivo/bar';
import {Button, Card, CardContent, Divider, Typography} from '@material-ui/core';
import {BarChart as BarIcon} from '@material-ui/icons';
import {getSkillsForGoal} from '../../../redux/actions';
import {withStyles} from '@material-ui/core/styles';
import baseStyle from '../../../styles/barChart';
import {saveAs} from 'file-saver';


const percentage = (value) => {
    return `${Math.round(value.toFixed(3) * 100)}%`
};

const drawInlineSVG = (svgElement, ctx, callback) => {
    let svgURL = new XMLSerializer().serializeToString(svgElement);
    let img = new Image();
    img.onload = function () {
        ctx.drawImage(this, 0, 0);
        callback();
    };
    img.src = 'data:image/svg+xml; charset=utf8, ' + encodeURIComponent(svgURL);
};

class PercentToTargetBar extends Component {

    goToSkill = (value) => {
        const {goalId, id} = value;
        this.props.getSkillsForGoal(goalId);
        this.props.history.push(`/students/${this.props.id}/goals/${goalId}`, id)
    };

    handleClick = () => {
        let myCanvas = document.getElementById(`percentToBarCanvas`);
        let ctxt = myCanvas.getContext("2d");
        let chart = document.querySelector(`#percentToBar svg[role="img"]`);
        drawInlineSVG(chart, ctxt, function () {
            myCanvas.toBlob(blob => saveAs(blob, 'Percent To Target.png'))
        })
    };

    render() {
        const {classes, title, data, suppressStyle} = this.props;
        const Title = ({ width, height }) => {
            // You can console.log(data) to see what other properties are available
            const style = {fontWeight: 'bold'}

            return (
                <text
                    x="44%" dominantBaseline="middle" textAnchor="middle"
                    y={-15}
                    style={style}
                >
                    Percent to Target
                </text>
            )
        };
        const width = suppressStyle ? 90 : 1500
        return (
            <ConditionalWrapper
                condition={!this.props.suppressStyle}
                wrapper={children => (
                    <Card aria-label='Percent To Target Bar Chart'>
                        <div className={classes.header}>
                            <BarIcon className={classes.icon}/>
                            <Typography className={classes.chartTitle} variant='h5'>{title}</Typography>
                        </div>
                        <Divider/>
                        <CardContent id='percentToBar' className={classes.barChart}>
                            {children}
                        </CardContent>
                    </Card>
                )}>
                    <canvas style={{display: 'none'}} height={420} width={width} id='percentToBarCanvas'/>
                {!this.props.suppressStyle && <Button align='right' variant='contained' onClick={(title) => {this.handleClick(title)}}>Download Chart</Button>}
                    <Bar
                        data={data}
                        keys={[
                            'Percent to Target',
                        ]}
                        layers={['grid', 'axes', 'bars', 'markers', 'legends', 'annotations', Title]}
                        indexBy='skill'
                        margin={{
                            top: 50,
                            right: 60,
                            bottom: 50,
                            left: 60
                        }}
                        padding={0.05}
                        groupMode='grouped'
                        colorBy='time'
                        colors={['#0087b6']}
                        borderWidth={1}
                        borderColor='inherit:darker(1)'
                        axisLeft={{
                            tickValues: 5,
                            tickSize: 0,
                            tickPadding: 5,
                            tickRotation: 0,
                            format: percentage
                        }}
                        height={330}
                        width={1080}
                        enableGridY={true}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        labelTextColor='#000'
                        animate={true}
                        motionStiffness={90}
                        motionDamping={15}
                        labelFormat={percentage}
                        minValue={0}
                        maxValue={1}
                        onClick={(value) => this.goToSkill(value.data)}
                        tooltip={(d) => (
                            <>
                                <Typography variant='h6'>{d.data.skill}</Typography>
                                <Typography variant='subtitle2'><strong>Percent to
                                    Target:</strong> {d.value.toFixed(3) * 100}%</Typography>
                            </>
                        )}
                        theme={{
                            labels: {
                                text: {fontSize: 18, fontWeight: 800}
                            },
                            axis: {
                                ticks: {
                                    text: {fontSize: 12, fontWeight: 800}
                                }
                            }
                        }}
                    />
                </ConditionalWrapper>
        )
    }
}


const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children;

const mapDispatch = (dispatch, {history}) => {
    return {
        getSkillsForGoal: (goalId) => dispatch(getSkillsForGoal(goalId, history))
    }
};

export default connect(null, mapDispatch)(withStyles(baseStyle)(PercentToTargetBar));