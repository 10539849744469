import React, { Component } from 'react';
import {
  Typography,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Divider,
  Button,
  TextField,
  Tooltip,
  MenuItem
} from '@material-ui/core';
import { AccountCircle, Edit, Phone, Business, BusinessCenter } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../styles/account';
import { FormattedMessage } from 'react-intl';

class StaffEmploymentInfo extends Component {

  render() {
    const { classes, staffProfile: { role, phone, employerName, employerType }, edit, myself, admin,type } = this.props;
    return (
      <Card className={classes.card}>
        <CardHeader title='Profile' action={
          !edit && (admin || myself) &&
          <Tooltip title={<FormattedMessage id="General.edit"/>}>
            <IconButton
              aria-label='edit'
              onClick={() => this.props.toggleEdit('editEmployment')}
            >
              <Edit />
            </IconButton>
          </Tooltip>
        }/>
        <Divider/>
        <CardContent>
          <div className={classes.infoLine}>
            <AccountCircle className={classes.infoIcon}/>

            {
              edit ?
                <TextField
                  select
                  name='role'
                  label='Role'
                  value={role || ''}
                  onChange={this.props.handleChange}
                >
                  {
                    [
                      'Braille Teacher',
                      'Assistive Tech. Specialist',
                      'Certified O&M',
                      'Certified TVI',
                      'Special Ed Teacher'
                    ].map(item => (
                      <MenuItem key={item} value={item}>{item}</MenuItem>
                    ))
                  }
                </TextField>
                : <Typography className={classes.infoText} variant='h6'>{role || 'No Role Set'}</Typography>
            }
          </div>
          <div className={classes.infoLine}>
            <Phone className={classes.infoIcon}/>
            { edit
              ? <TextField
                type='phone'
                name='phone'
                label='Phone Number'
                value={phone || ''}
                onChange={this.props.handleChange}
              />
              : <Typography className={classes.infoText} variant='h6'>{phone || 'No Phone Number'}</Typography>
            }
          </div>
          <div className={classes.infoLine}>
            {type==='Individual' ? '' :<Business className={classes.infoIcon}/>}
            {type==='Individual' ? '' : (edit
              ? <TextField
                select
                name='employerType'
                label='Which of the following describes your primary employer?'
                value={employerType || ''}
                onChange={this.props.handleChange}
              >
                {
                  [
                    'Public School District',
                    'Private School',
                    'State DOE',
                    'Not-for-Profit Agency',
                    'For-Profit Agency',
                    'Self-Employed'
                  ].map(item => (
                    <MenuItem key={item} value={item}>{item}</MenuItem>
                  ))
                }
              </TextField>
              : <Typography className={classes.infoText} variant='h6'>{employerType || 'No Employer Set'}</Typography>)
            }
          </div>
          {
            employerType && employerType !== 'Self-Employed' &&
            <div className={classes.infoLine}>
              <BusinessCenter className={classes.infoIcon}/>
              { edit
                ? <TextField
                  name='employerName'
                  label={`What is the name for your ${employerType}?`}
                  value={employerName || ''}
                  onChange={this.props.handleChange}
                />
                : <Typography className={classes.infoText} variant='h6'>{employerName || 'No Employer Set'}</Typography>
              }
            </div>
          }
          { edit &&
          <div className={classes.buttons}>
            <Button
              variant='outlined'
              color='secondary'
              onClick={() => this.props.cancel('editEmployment')}
            >
              <FormattedMessage id="General.cancel"/>
            </Button>
            <Button
              className={classes.confirm}
              variant='contained'
              color='primary'
              onClick={() => this.props.submit('employment')}
            >
              <FormattedMessage id="General.save"/>
            </Button>
          </div>
          }
        </CardContent>
      </Card>
    )
  }
}

export default withStyles(baseStyle)(StaffEmploymentInfo);