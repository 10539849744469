import React, { Component } from 'react';
import { Button, FormGroup, Typography, TextField, IconButton, MenuItem } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../../styles/wizardBase';
import { FormattedMessage } from 'react-intl';

class EntertainmentGameWizardPage1 extends Component {

  close = () => {
    this.props.close();
  };

  submit = () => {
    this.props.submit()
  };

  render()  {
    const { classes, game } = this.props;
    const games = this.props.games.filter(game => game.type === 'Entertainment');
    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        open={this.props.open}
        onClose={this.props.close}
        aria-labelledby='form-dialog-title'
        PaperProps={{ classes:{ root: classes.container }}}
      >
        <DialogTitle disableTypography={true} className={ classes.title } id='form-dialog-title'>
          <Typography className={ classes.titleText } variant='h4'><FormattedMessage id='Sidebar.games.entertainment'/></Typography>
          <IconButton
            onClick={this.close}
            className={ classes.exitIcon }
            aria-label="close dialog"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <br />
          <DialogContentText>
            <FormattedMessage id='Sidebar.games.entertainment'/>
          </DialogContentText>
          <FormGroup classes={{ root: classes.root }}>
            <TextField
              select
              variant='outlined'
              id='game-select'
              label={<FormattedMessage id='General.game'/>}
              type='text'
              name='game'
              onChange={this.props.handleChange}
              value={this.props.game ? this.props.game.id : ''}
            >
              {
                games.map(game => <MenuItem key={game.id} value={game.id}> {game.name} </MenuItem>)
              }
            </TextField>
          </FormGroup>
          <FormGroup classes={{ root: classes.root }}>
            {
              game &&
                <Typography>{game.description}</Typography>
            }
          </FormGroup>
        </DialogContent>
        <DialogActions className={classes.buttons}>
          <Button onClick={this.close} color='secondary'>
            <FormattedMessage id='General.cancel'/>
          </Button>
          <Button onClick={this.submit} color='primary'>
            <FormattedMessage id='General.submit'/>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(baseStyle)(EntertainmentGameWizardPage1);