import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, DialogActions, DialogContent, DialogTitle, MenuItem, TextField, withStyles } from '@material-ui/core';
import { updateGuide, updateQuestion, updatePage } from '../../../redux/actions';
import { FormattedMessage } from 'react-intl';
import { capitalize } from '../../../helpers';
import baseStyle from '../../../styles/helpConfig';

class HelpEdit extends Component {

  state = {
    ...this.props.data,
    url: this.props.data.type && this.props.data.type === 'video'
      ? `https://www.youtube.com/watch?v=${this.props.data.url}`
      : this.props.data.url
  };

  handleChange = ({target: { value, name }}) => {
    this.setState({ [name]: value })
  };

  handleEdit = () => {
    const { type, pageId } = this.props;
    switch (type){
      case 'guide':
        this.props.close();
        return this.props.updateGuide(this.state, pageId);
      case 'question':
        this.props.close();
        return this.props.updateQuestion(this.state, pageId);
      case 'page':
        this.props.close();
        return this.props.updatePage(this.state);
      default:
        break;
    }
  };

  renderEdit = () => {
    const { classes, type } = this.props;
    switch(type){
      case 'question':
        return (
          <>
            <TextField
              className={classes.line}
              name='question'
              fullWidth
              label='Question'
              variant='outlined'
              value={this.state.question}
              onChange={this.handleChange}
            />
            <TextField
              className={classes.line}
              name='answer'
              multiline
              rows={3}
              fullWidth
              label='Answer'
              variant='outlined'
              value={this.state.answer}
              onChange={this.handleChange}
            />
          </>
        );
      case 'guide':
        return (
          <>
            <TextField
              className={classes.line}
              fullWidth
              variant='outlined'
              name='description'
              label='Description'
              value={this.state.description}
              onChange={this.handleChange}
            />
            <TextField
              select
              className={classes.line}
              fullWidth
              variant='outlined'
              name='type'
              label='Type'
              value={this.state.type}
              onChange={this.handleChange}
            >
              <MenuItem value='video'> Video </MenuItem>
              <MenuItem value='pdf'> PDF </MenuItem>
            </TextField>
            <TextField
              className={classes.line}
              fullWidth
              variant='outlined'
              name='url'
              label='URL'
              value={this.state.url}
              onChange={this.handleChange}
            />
          </>
        );
      case 'page':
        return (
          <>
            <TextField
              className={classes.line}
              name='name'
              fullWidth
              label='Page Name'
              variant='outlined'
              value={this.state.name}
              onChange={this.handleChange}
            />
          </>
        );
      default:
        return 'Something went wrong, please try again!'
    }
  };

  render() {
    return (
      <>
        <DialogTitle> Edit {capitalize(this.props.type)} </DialogTitle>
        <DialogContent>
          {this.renderEdit()}
        </DialogContent>
        <DialogActions>
          <Button
            color='secondary'
            onClick={this.props.close}
          >
            <FormattedMessage id='General.cancel'/>
          </Button>
          <Button
            color='primary'
            variant='contained'
            onClick={this.handleEdit}
          >
            <FormattedMessage id='General.submit'/>
          </Button>
        </DialogActions>
      </>
    );
  }
}

const mapState = () => {
  return {}
};

const mapDispatch = (dispatch) => {
  return {
    updateQuestion: (question, pageId) => dispatch(updateQuestion(question, pageId)),
    updateGuide: (guide, pageId) => dispatch(updateGuide(guide, pageId)),
    updatePage: (page) => dispatch(updatePage(page))
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(HelpEdit));