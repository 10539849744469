import React, { Component } from 'react';
import {
  Button,
  FormGroup,
  Typography,
  IconButton,
  Checkbox,
  FormControlLabel, Divider
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../../styles/wizardBase';
import { capitalize } from '../../../../helpers';

class BrailleSheetsContractions extends Component {

  state={};

  componentDidMount() {
    const { sheet } = this.props;
    sheet.contractionSet.forEach(contraction => {
      this.setState({ [contraction]: true })
    });
    this.setState({ checked: true })
  }

  close = () => {
    this.props.close();
  };

  handleChecked = (contraction) => (event) => {
    if (contraction === 'checked') {
      for  (const [key] of Object.entries(this.state)) {
        this.setState({ [key]: !this.state.checked })
      }
    }
    this.setState({ [contraction]: event.target.checked })
  };

  next = () => {
    this.props.contraction(this.state)
  };

  render()  {
    const { classes, sheet } = this.props;
    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        open={this.props.open}
        onClose={this.props.close}
        aria-labelledby='form-dialog-title'
        scroll='paper'
        PaperProps={{classes:{ root: classes.container }}}
      >
        <DialogTitle disableTypography={true} className={classes.title} id='form-dialog-title'>
          <Typography className={classes.titleText} variant='h4'> <FormattedMessage id='Wizard.Braille.contractions'/> </Typography>
          <IconButton
            onClick={this.close}
            className={ classes.exitIcon }
            aria-label="close dialog"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant='h6'><FormattedMessage id='Wizard.Braille.selectContract'/>: </Typography>
          <FormGroup classes={{root: classes.root}}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.checked}
                  onChange={this.handleChecked('checked')}
                  value='checked'
                  color="primary"
                />
              }
              label={this.state.checked ? <FormattedMessage id='Wizard.Braille.uncheck'/> : <FormattedMessage id='Wizard.Braille.check'/>}
            />
            <Divider />
            <div className={classes.checkboxGrid}>
            {
              sheet.contractionSet.sort().map(contraction=> {
                return (
                  <FormControlLabel
                    key={contraction}
                    control={
                      <Checkbox
                        checked={this.state[contraction]}
                        onChange={this.handleChecked(contraction)}
                        value={contraction}
                        color="primary"
                      />
                    }
                    label={capitalize(contraction)}
                  />
                )
              })
            }
            </div>
          </FormGroup>
        </DialogContent>
        <DialogActions className={classes.buttons}>
          <Button onClick={this.props.back} color='secondary'>
            <FormattedMessage id='General.back'/>
          </Button>
          <Button onClick={this.next} color='primary'>
            <FormattedMessage id='General.next'/>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(baseStyle)(BrailleSheetsContractions);