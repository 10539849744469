import React, { Component } from 'react';
import { connect } from 'react-redux';
import DataTable from '../Shared/DataTable';
import { Dialog, Divider, Typography, Button } from '@material-ui/core';
import { Face } from '@material-ui/icons';
import { getStaffById, getStudentsForStaff, getAccount } from '../../../redux/actions';
import { getRolesForStaff } from '../../../redux/actions';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../styles/students';
import { FormattedMessage } from 'react-intl';
import StudentEnrollmentWizard from '../Wizards/Registration/StudentEnrollmentWizard';

class StaffMemberStudents extends Component {
  state={
    studentCount: 1,
    open: false
  };

  componentDidMount(){
    this.props.getRolesForStaff(this.props.id);
    this.props.getStaffById(this.props.id);
    this.props.getStudentsForStaff(this.props.id);
    this.props.getAccount();
  };

  open = () => {
    this.setState({ open: true })
  };

  close = () => {
    this.setState({ open: false })
  };

  render() {
    const { staffMember, staffRoles, staffStudents, classes, account, user } = this.props;
    const specAdmin = staffRoles.find(role => role.id === '922331036862345812');
    const rows = [
      { id:'firstName', label: <FormattedMessage id="General.firstName"/>, searchable: true },
      { id:'lastName', label: <FormattedMessage id="General.lastName"/>, searchable: true },
      { id:'gamerTag', label: 'User Name', searchable: true }
    ];
    return (
      <>
        <div className={classes.title}>
          <Face className={classes.icon} fontSize='inherit'/>
          <Typography className={classes.text} variant='h3'>
            {staffMember.firstName}<FormattedMessage id="Sidebar.student's"/>
          </Typography>
        </div>
        <Divider />
        <br />
        <DataTable
          staffId={this.props.id}
          type='staffStudent'
          clickable
          history={this.props.history}
          rows={rows}
          rowsPerPage={[5,10,15]}
          orderBy='firstName'
          data={staffStudents}
          noActions={true}
          numCols={2}
          title={<> {staffMember.firstName}<FormattedMessage id="Sidebar.student's"/> </>}
        />
        {
          account && specAdmin &&
          <Button
            classes={{root: classes.manageBtn}}
            variant='contained'
            onClick={this.open}
          >
            Manage Students
          </Button>
        }
        <Dialog
          maxWidth='md'
          fullWidth
          open={this.state.open}
          onClose={this.close}
        >
          <StudentEnrollmentWizard
            start
            studentCount={staffStudents.length}
            enrolled={staffStudents}
            close={this.close}
            students={account ? account.billingCount : 0}
          />
        </Dialog>
      </>
    )
  }
}

const mapState = ({ platform: { staffMember, staffRoles, staffStudents }, shared: { account,user }}) => {
  return {
    staffMember,
    staffRoles,
    staffStudents,
    account,
    user
  }
};

const mapDispatch = (dispatch) => {
  return {
    getStaffById: (id) => dispatch(getStaffById(id)),
    getStudentsForStaff: (id) => dispatch(getStudentsForStaff(id)),
    getRolesForStaff: (id) => dispatch(getRolesForStaff(id)),
    getAccount: () => dispatch(getAccount())
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(StaffMemberStudents));