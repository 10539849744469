import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import BrailleSheet from '../BrailleSheets/BrailleSheetWizardGrid';
import BrailleSheetsImportQuestion from '../BrailleSheets/BrailleSheetsImportQuestion';
import ImportBrailleSheet from '../../Staff/ImportBrailleSheet';
import { Button, FormGroup, Typography, IconButton, TextField, MenuItem, Divider, Paper, withStyles } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { ROOT_URL } from '../../../../redux/constants';
import {
  fetchMyBrailleSheets,
  fetchStaffBrailleSheets,
  fetchStaffBrailleSheetsForGame,
  getBrailleSheetById
} from '../../../../redux/actions';
import baseStyle from '../../../../styles/wordHunt';

class WordHunt extends Component {

  state={
    error: {},
    noAnswers: {},
    selected: '',
    selectedSheet: {},
    questions: [{
      question: '',
      letterToFind: 'A',
      findAll: false,
      findLetter: true,
      continuationThreshold: 80,
      answerEvaluation: 'allNumbers',
      type: 'word',
      prefix: 'starting with'
    }],
    difficulty: 'Easy',
    maxSecondsPerQuestion: '30',
    tested: false,
    autoSubmit: true,
    import: false,
    grid: [],
  };

  componentDidMount() {
    // this.props.fetchStaffBrailleSheets(this.props.id);
    // this.props.fetchStaffBrailleSheetsForGame(this.props.id, this.props.game);
    this.props.fetchMyBrailleSheets(this.props.game);
    if (this.props.level.id) {
      const variables = JSON.parse(this.props.level.levelJson);
      this.setState({
        ...variables,
        questions: variables.questions.map(question => {
          return {
            ...question,
            prefix: `${question.question.split(' ')[3]} ${question.question.split(' ')[4]}`,
            type: question.question.split(' ')[2]
          }
        }),
        difficulty: this.props.level.difficulty,
        selected: variables.brailleSheet.id,
        selectedSheet: variables.brailleSheet,
        grid: JSON.parse(variables.brailleSheet.grid)
      })
    }
  };

  close = () => {
    this.props.close();
  };

  addQuestion = () => {
    this.setState({
      questions: [
        ...this.state.questions,
        {
          question: '',
          letterToFind: '',
          findAll: false,
          findLetter: false,
          continuationThreshold: 80,
          answerEvaluation: 'allNumbers',
          type: 'letter',
          prefix: ''
        }
      ]
    })
  };

  importQuestion = (question) => {
    this.setState(state => ({ questions: [...state.questions, question] }));
  };

  toggleImport = () => {
    this.setState(state => ({ import: !state.import }));
  };

  removeQuestion = (idx) => {
    const questions = this.state.questions.filter((question, index) => index !== idx);
    this.setState({ questions })
  };

  handleChange = (ev) => {
    const value = ev.target.type === 'number' ? ev.target.value > 0 ? ev.target.value * 1 : '' : ev.target.value;
    this.setState({ [ev.target.name]: value })
  };

  handleSheetChange = (sheet, show) => {
    if (show) {
      getBrailleSheetById(sheet.brailleSheetId)
        .then((_sheet) => {
          const grid = JSON.parse(_sheet.grid);
          this.setState({ selectedSheet: _sheet, grid });
        });
    } else {
      if (sheet.key) {
        getBrailleSheetById(sheet.brailleSheetId)
          .then((_sheet) => {
            const selected = sheet.key;
            const grid = JSON.parse(_sheet.grid);
            this.setState({ selected, selectedSheet: _sheet, grid });
          });
      } else {
        const selectedSheet = sheet;
        const selected = sheet.id;
        const grid = JSON.parse(selectedSheet.grid);
        this.setState({ selected, selectedSheet, grid });
      }
    }
  };

  handleChangeQuestion = (ev, index) => {
    const value = ev.target.value;
    const name = ev.target.name;
    this.setState(
      { questions: this.state.questions.map(
          obj => this.state.questions.indexOf(obj) === index ? {...obj, [name]: value, index } : obj
        )
      }
    )
  };

  runTest = () => {
    const {
      questions,
      numberOfQuestionsPerGame,
      maxSecondsPerQuestion,
      selectedSheet,
      tested,
    } = this.state;
    const { groups } = this.state.grid;
    const url = this.props.type === 'staff'
      ? `${ROOT_URL}/staff/${this.props.id}/objective-databank/test`
      : `${ROOT_URL}/admin/objective-databank/test`;
    if (tested) this.clearTest();
    const questionArr = questions.map(_question => {
      const { answerEvaluation, continuationThreshold, findAll, letterToFind, prefix, type } = _question;
      let acceptableAnswerIds = [];
      let regex;
      const question = `${findAll ? 'Find all' : 'Find one'} ${type} ${prefix} ${letterToFind.toUpperCase().split('').join('. ')}. `;
      switch (prefix){
        case 'starting with':
          regex = RegExp(`\\b${letterToFind}`, 'gi');
          break;
        case 'containing':
          regex = RegExp(letterToFind, 'gi');
          break;
        case 'ending in':
          regex = RegExp(`${letterToFind}\\b`, 'gi');
          break;
        default:
          regex = RegExp(letterToFind, 'gi');
          break;
      }
      groups.forEach(group => group.text.match(regex) ? acceptableAnswerIds.push(group.id) : null);
      return { answerEvaluation, continuationThreshold, findAll, findLetter: type.includes('letter'), letterToFind, acceptableAnswerIds, question};
    });
    axios.post(url, {
      levelVariables: JSON.stringify({
        questions: questionArr,
        numberOfQuestionsPerGame,
        maxSecondsPerQuestion,
        brailleSheet: selectedSheet,
      }),
      templateId: '-3485104053016878161'
    }).catch(err => console.log(err));
    this.setState({ tested: true });
  };

  clearTest = () => {
    const url = this.props.type === 'staff'
      ? `${ROOT_URL}/staff/${this.props.id}/objective-databank/test`
      : `${ROOT_URL}/admin/objective-databank/test`;
    axios.delete(url)
      .catch(err => console.log(err));
    this.setState({ tested: false })
  };

  onSubmit = () => {
    const { groups } = this.state.grid;
    const { questions, maxSecondsPerQuestion, autoSubmit, selectedSheet, difficulty } = this.state;
    let error = false;
    const questionArr = questions.map(_question => {
      const { answerEvaluation, continuationThreshold, findAll, letterToFind, type, prefix, index } = _question;
      let acceptableAnswerIds = [];
      let regex;
      const question = `${findAll ? 'Find all' : 'Find one'} ${findAll ? type : `${type}s`} ${prefix} ${letterToFind.toUpperCase().split('').join('. ')}. `;
      switch (prefix){
        case 'starting with':
          regex = RegExp(`\\b${letterToFind}`, 'gi');
          break;
        case 'containing':
          regex = RegExp(letterToFind, 'gi');
          break;
        case 'ending in':
          regex = RegExp(`${letterToFind}\\b`, 'gi');
          break;
        default:
          regex = RegExp(letterToFind, 'gi');
          break;
        }
      groups.forEach(group => group.text.match(regex) ? acceptableAnswerIds.push(group.id) : null);
      console.log(acceptableAnswerIds)
      if (acceptableAnswerIds.length < 1){
        this.setState({ noAnswers: {...this.state.noAnswers, [index]: true }})
        error = true
      }
      else {
        let noAnswers = this.state.noAnswers;
        if (this.state.noAnswers[index]){
          delete noAnswers[index];
          this.setState({ noAnswers });
          error = false;
        }
      }
      return { answerEvaluation, continuationThreshold, findAll, findLetter: type.includes('letter'), letterToFind, acceptableAnswerIds, question };
    });
    const levelJson = JSON.stringify({
      questions: questionArr,
      numberOfQuestionsPerGame: questionArr.length,
      brailleSheet: selectedSheet,
      maxSecondsPerQuestion,
      autoSubmit,
    });
    const skill = { levelJson, difficulty, levelTemplateId: '-3485104053016878161' };
    if (!error){
      this.props.onSubmit(skill, selectedSheet);
    }
  };

  checkForErrors = (ev, index) => {
    if (!ev.target.value){
      if (index !== null) {
        this.setState({ error: {...this.state.error, [ev.target.name]: {...this.state.error[ev.target.name], [index]: true }}})
      }
      else {
        this.setState({ error: {...this.state.error, [ev.target.name]: true }})
      }
    }
    else {
      let error = this.state.error;
      if (index !== null) {
        if (Object(error[ev.target.name]).length > 1){
          delete error[ev.target.name][index];
        }
        else {
          delete error[ev.target.name];
        }
      }
      else {
        delete error[ev.target.name];
      }
      this.setState({ error })
    }
  };

  render()  {
    const { classes, staffBrailleSheets } = this.props;
    const { selectedSheet, grid, tested, error } = this.state;
    const questions = this.state.questions.map(_question => {
      const { acceptableAnswerIds, answers } = _question;
      const _answers = acceptableAnswerIds
        ? acceptableAnswerIds.map(answer => grid.groups[answer].text)
        : answers;
      return { ..._question, answers: _answers }
    });
    const empty = this.state.questions.some(question => question.letterToFind === '');
    const disabled = this.state.numberOfQuestionsPerGame > questions.length || (Object.keys(error).length > 0) || empty;
    return (
      <>
        <Dialog
          maxWidth='xl'
          disableEscapeKeyDown
          fullWidth={true}
          open={this.props.open}
          onClose={this.props.close}
          aria-labelledby='form-dialog-title'
          PaperProps={{classes:{ root: classes.container }}}
        >
          <DialogTitle disableTypography={true} className={classes.title} id='form-dialog-title'>
            <Typography className={classes.titleText} variant='h4'> <FormattedMessage id='Wizard.skillDB.title' /></Typography>
            <IconButton
              onClick={this.close}
              className={ classes.exitIcon }
              aria-label="close dialog"
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography gutterBottom variant='h3'>
              <FormattedMessage id='Wizard.WordHunt.title'/>
            </Typography>
            <Typography className={classes.description} variant='subtitle1'>
              <FormattedMessage id='Wizard.WordHunt.subtitle'/>
            </Typography>
            <Divider className={classes.divider}/>
            <Typography variant='h6'><FormattedMessage id='Wizard.BrailleQuiz.select'/></Typography>
            <ImportBrailleSheet
              grid={this.state.grid}
              selected={this.state.selected}
              selectSheet={this.handleSheetChange}
              game={this.props.game}
              id={this.props.id}
              brailleSheets={staffBrailleSheets}
            />
            {
              !this.state.selectedSheet.id || !this.state.selected
                ? <Typography align='center' variant='h6'><FormattedMessage id='Wizard.BrailleQuiz.empty'/></Typography>
                : <Paper className={classes.grid}>
                  <BrailleSheet sheet={selectedSheet} grid={grid}/>
                </Paper>
            }
            {
              this.state.selectedSheet.id && this.state.selected &&
              <div className={classes.questions}>
                <FormGroup className={classes.brailleLine}>
                  <Typography className={classes.lineText} variant='h6'><FormattedMessage id='General.description'/>: </Typography>
                  <br />
                  <Typography className={classes.descriptionText} variant='subtitle2'>{selectedSheet.description}</Typography>
                </FormGroup>
                <FormGroup className={classes.brailleLine}>
                  <Typography className={classes.lineText} variant='h6'><FormattedMessage id='General.difficulty'/>: </Typography>
                  <TextField
                    select
                    variant='outlined'
                    id='difficulty-select'
                    name='difficulty'
                    value={this.state.difficulty}
                    onChange={this.handleChange}
                  >
                    {
                      [
                        { value: 'Easy', text: <FormattedMessage id='General.easy' /> },
                        { value: 'Medium', text: <FormattedMessage id='General.medium' /> },
                        { value: 'Hard', text: <FormattedMessage id='General.hard' /> }
                      ].map((option) => <MenuItem key={option.value} value={option.value}>{option.text}</MenuItem>)
                    }
                  </TextField>
                </FormGroup>
                <FormGroup className={classes.brailleLine}>
                  <Typography className={classes.lineText} variant='h6'><FormattedMessage id='Wizard.BrailleQuiz.time'/>: </Typography>
                  <TextField
                    variant='outlined'
                    id='time-per-question'
                    name='maxSecondsPerQuestion'
                    type='number'
                    value={this.state.maxSecondsPerQuestion}
                    onChange={this.handleChange}
                    onBlur={this.checkForErrors}
                    error={error.maxSecondsPerQuestion}
                    helperText={error.maxSecondsPerQuestion ? '* required' : ''}
                  />
                </FormGroup>
                {
                  questions.map((question, index) => {
                    return (
                      <div className={classes.question} key={index}>
                        <FormGroup className={classes.lastLine}>
                          <Typography color='inherit' className={classes.inlineText} variant='h6'><FormattedMessage id='Wizard.WordHunt.find'/></Typography>
                          <TextField
                            select
                            className={classes.inlineSelect}
                            id='question-select'
                            name='findAll'
                            value={question.findAll}
                            onChange={(ev) => this.handleChangeQuestion(ev, index)}
                          >
                            {
                              [
                                {text: <FormattedMessage id='Wizard.WordHunt.one'/>, value: false},
                                {text: <FormattedMessage id='Wizard.WordHunt.all'/>, value: true},
                              ].map((option) => (
                                <MenuItem key={option.value} value={option.value}>{option.text}</MenuItem>
                              ))
                            }
                          </TextField>
                          <TextField
                            select
                            className={classes.inlineSelect}
                            id='question-select'
                            name='type'
                            aria-label='type to find'
                            value={question.type}
                            onChange={(ev) => this.handleChangeQuestion(ev, index)}
                          >
                            {
                              question.findAll
                              ? [
                                {text: <FormattedMessage id='Wizard.WordHunt.words'/>, value: 'word'},
                                {text: <FormattedMessage id='Wizard.WordHunt.letters'/>, value: 'letter'},
                              ].map((option) => (
                                <MenuItem key={option.value} value={option.value}>{option.text}</MenuItem>
                              ))
                              :[
                                {text: <FormattedMessage id='Wizard.WordHunt.word'/>, value: 'word'},
                                {text: <FormattedMessage id='Wizard.WordHunt.letter'/>, value: 'letter'},
                              ].map((option) => (
                                <MenuItem key={option.value} value={option.value}>{option.text}</MenuItem>
                              ))
                            }
                          </TextField>
                          {
                            question.type.includes('word') &&
                            <TextField
                              className={classes.inlineSelect}
                              select
                              id='prefix-select'
                              name='prefix'
                              aria-label='part of word'
                              value={question.prefix}
                              onChange={(ev) => this.handleChangeQuestion(ev, index)}
                            >
                              {
                                [
                                  {text: <FormattedMessage id='Wizard.WordHunt.starting'/>, value: 'starting with'},
                                  {text: <FormattedMessage id='Wizard.WordHunt.ending'/>, value: 'ending in'},
                                  {text: <FormattedMessage id='Wizard.WordHunt.containing'/>, value: 'containing'}
                                ].map((option) => (
                                  <MenuItem key={option.value} value={option.value}>{option.text}</MenuItem>
                                ))
                              }
                            </TextField>
                          }
                          <TextField
                            className={classes.letterInput}
                            id='letter'
                            aria-label='Letter to find'
                            name='letterToFind'
                            value={question.letterToFind}
                            onChange={(ev) => this.handleChangeQuestion(ev, index)}
                          />
                        </FormGroup>
                        {
                          question.findAll &&
                          <FormGroup className={classes.lastLine}>
                            <Typography className={classes.inlineText} variant='h6'><FormattedMessage id='Wizard.WordHunt.need'/></Typography>
                            <TextField
                              type='number'
                              id='continuationThreshold'
                              name='continuationThreshold'
                              value={question.continuationThreshold}
                              onChange={(ev) => this.handleChangeQuestion(ev, index)}
                              onBlur={(ev) => this.checkForErrors(ev, index)}
                              error={error.continuationThreshold && error.continuationThreshold[index]}
                              helperText={error.continuationThreshold && error.continuationThreshold[index] ? '* required' : ''}
                            />
                            <Typography className={classes.inlineText} variant='h6'>% <FormattedMessage id='Wizard.WordHunt.correct'/></Typography>
                          </FormGroup>
                        }
                        <FormGroup className={classes.questionLine}>
                          <Typography className={classes.lineText} variant='h6'><FormattedMessage id='Wizard.WordHunt.feedback'/></Typography>
                          <TextField
                            select
                            variant='outlined'
                            id='answer-evaluation-select'
                            name='answerEvaluation'
                            value={'allNumbers'}
                            onChange={(ev) => this.handleChangeQuestion(ev, index)}
                            error={error.question && error.question[question.answerEvaluation]}
                            helperText={error.question && error.question[question.answerEvaluation] ? 'Looks like there isn\'t a correct answer, please enter a different search' : ''}
                          >
                            {
                              [
                                {text:  <FormattedMessage id='Wizard.WordHunt.readNum'/>, value: 'allNumbers'}
                              ].map((option) => (
                                <MenuItem selected={true===true} key={option.value} value={option.value}>{option.text}</MenuItem>
                              ))
                            }
                          </TextField>
                          {
                            this.state.noAnswers[index] &&
                            <Typography className={classes.error} variant='subtitle2' color='secondary'>
                              * Looks like there isn't a correct answer to this search on this sheet! Please change the search and try again.
                            </Typography>
                          }
                        </FormGroup>
                        <IconButton onClick={() => this.removeQuestion(index)} classes={{root:classes.delete}}>
                          <Close color='secondary'/>
                        </IconButton>
                      </div>
                    )
                  })
                }
                <div className={classes.questionBtns}>
                  <Button
                    variant='contained'
                    id='add-question'
                    color='primary'
                    classes={{ root: classes.addButton }}
                    onClick={this.addQuestion}
                  >
                    <FormattedMessage id='Wizard.BrailleQuiz.addQuestion'/>
                  </Button>
                  {/*<Button*/}
                  {/*  variant='contained'*/}
                  {/*  id='import-question'*/}
                  {/*  classes={{ root: classes.addButton }}*/}
                  {/*  onClick={this.toggleImport}*/}
                  {/*>*/}
                  {/*  <FormattedMessage id='Wizard.BrailleQuiz.import'/>*/}
                  {/*</Button>*/}
                </div>
              </div>
            }
          </DialogContent>
          <DialogActions className={classes.buttons}>
            <Button id='back' onClick={this.props.back} color='secondary'>
              <FormattedMessage id='General.back'/>
            </Button>
            <Button
              onClick={ this.runTest }
              variant={ tested ? 'outlined' : 'text' }
              color={ tested ? 'secondary' : 'primary' }
            >
              { tested ? <FormattedMessage id='General.retest' /> : <FormattedMessage id='General.test' />}
            </Button>
            <Button
              disabled={disabled}
              id='submit'
              onClick={this.onSubmit}
              color='primary'
            >
              <FormattedMessage id='General.next'/>
            </Button>
          </DialogActions>
        </Dialog>
        { this.state.selectedSheet.id &&
        <BrailleSheetsImportQuestion
          open={this.state.import}
          toggleImport={this.toggleImport}
          importQuestion={this.importQuestion}
          groups={grid.groups}
        />
        }
      </>
    )
  }
}

const mapState = ({ platform: { staffBrailleSheets }, shared: { account }, social: { myBrailleSheets }}) => {
  return {
    staffBrailleSheets,
    account,
    myBrailleSheets,
  }
};

const mapDispatch = (dispatch) => {
  return {
    fetchStaffBrailleSheets: (staffId) => dispatch(fetchStaffBrailleSheets(staffId)),
    fetchStaffBrailleSheetsForGame: (staffId, game) => dispatch(fetchStaffBrailleSheetsForGame(staffId, game)),
    fetchMyBrailleSheets: (gameId) => dispatch(fetchMyBrailleSheets(gameId)),
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(WordHunt));