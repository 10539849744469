import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Tooltip,
  Icon,
  IconButton,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  Dialog,
  withStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { fetchMyGroups, shareGoal, shareBrailleSheet } from '../../../redux/actions';
import baseStyle from '../../../styles/shareButton';
import { FormattedMessage } from 'react-intl';
import ShareGoal from './ShareGoal';

class ShareButton extends Component {

  state = {
    open: false,
    name: this.props.data.name || '',
    description: this.props.data.description || '',
    comment: '',
    groupKey: '',
    editor: {}
  };

  componentDidMount() {
    if (this.props.profile && this.props.myGroups === []) {
      this.props.fetchMyGroups(this.props.profile.key);
    }
  }

  handlePostChange = (comment, delta, text) => {
    const words = text.split(' ');
    const tags = words.filter(word => word[0] === '#');
    this.setState({ comment, tags })
  };

  open = () => {
    this.setState({ open: true });
  };

  close = () => {
    this.setState({ open: false });
  };

  handleChange = (ev) => {
    this.setState({ [ev.target.name]: ev.target.value })
  };

  share = () => {
    const group = this.props.myGroups.find(group => group.key === this.state.groupKey);
    const { comment } = this.state;
    const { data } = this.props;
    if (this.props.type === 'staffGoalDatabank') {
      this.props.shareGoal(data, comment, group);
    }
    if (this.props.type === 'staffBrailleSheet'){
      this.props.shareBrailleSheet(data, comment, group);
    }
    this.setState({ open: false })
  };

  render() {
    const { classes } = this.props;
    const myGroups = this.props.myGroups && this.props.myGroups.filter(group => group.role !== null && group.role !== 'Invited');
    return (
      <div>
        <Dialog
          disableEscapeKeyDown
          fullWidth={true}
          open={this.state.open}
          onClose={this.close}
          aria-labelledby='form-dialog-title'
          PaperProps={{ classes:{ root: classes.container }}}
        >
          <DialogTitle disableTypography={true} className={ classes.title } id='form-dialog-title'>
            <Typography className={ classes.titleText } variant='h4'>Share This!</Typography>
            <IconButton
              onClick={this.close}
              className={ classes.exitIcon }
              aria-label="close dialog"
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <ShareGoal
              id={this.props.id}
              open={this.state.open}
              name={this.state.name}
              description={this.state.description}
              groupKey={this.state.groupKey}
              handleChange={this.handleChange}
              handlePostChange={this.handlePostChange}
              myGroups={myGroups}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.close} color='secondary'>
              <FormattedMessage id='General.cancel'/>
            </Button>
            <Button onClick={this.share} color='primary'>
              <FormattedMessage id='General.share'/>
            </Button>
          </DialogActions>
        </Dialog>
        <Tooltip title='share to network'>
          <IconButton
            className={classes.shareIcon}
            onClick={this.open}
          >
            <Icon fontSize='inherit' className="fas fa-share"/>
          </IconButton>
        </Tooltip>
      </div>

    );
  }
}

const mapState = ({ social: { myGroups, profile }}) => {
  return {
    myGroups,
    profile
  }
};

const mapDispatch = (dispatch) => {
  return {
    fetchMyGroups: (profileKey) => dispatch(fetchMyGroups(profileKey)),
    shareGoal: (goal, comment, group) => dispatch(shareGoal(goal, comment, group)),
    shareBrailleSheet: (sheet, comment, group) => dispatch(shareBrailleSheet(sheet, comment, group))
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(ShareButton));