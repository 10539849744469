import { FETCH_ALL_LIST_GROUPS, FETCH_ALL_GAME_GROUPS } from '../../constants';

export const listGroups = (state = [], action) => {
  switch(action.type){
    case FETCH_ALL_LIST_GROUPS:
      return action.listGroups;
    default:
      return state;
  }
};

export const gameGroups = (state = [], action) => {
  switch(action.type){
    case FETCH_ALL_GAME_GROUPS:
      return action.gameGroups;
    default:
      return state;
  }
};