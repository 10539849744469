export const CREATE_HELP_PAGE = 'CREATE_HELP_PAGE';
export const UPDATE_HELP_PAGE = 'UPDATE_HELP_PAGE';
export const DELETE_HELP_PAGE = 'DELETE_HELP_PAGE';
export const FETCH_HELP_PAGES = 'FETCH_HELP_PAGES';

export const CREATE_HELP_QUESTION = 'CREATE_HELP_QUESTION';
export const UPDATE_HELP_QUESTION = 'UPDATE_HELP_QUESTION';
export const DELETE_HELP_QUESTION = 'DELETE_HELP_QUESTION';

export const CREATE_HELP_GUIDE = 'CREATE_HELP_GUIDE';
export const UPDATE_HELP_GUIDE = 'UPDATE_HELP_GUIDE';
export const DELETE_HELP_GUIDE = 'DELETE_HELP_GUIDE';

export const FETCH_ONLINE_USERS = 'FETCH_ONLINE_USERS';
export const FETCH_MESSAGES = 'FETCH_MESSAGES';
export const ADD_MESSAGE = 'ADD_MESSAGE';