import React, { Component } from 'react';
import { Button, FormGroup, Typography, IconButton, TextField } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../../styles/wizardBase';
import { FormattedMessage } from 'react-intl';

class SkillDatabankWizardPage2 extends Component {

  close = () => {
    this.props.close();
  };

  render()  {
    const { classes, open, close, skill } = this.props;
    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        open={open}
        onClose={close}
        aria-labelledby='skill-dialog-title'
        PaperProps={{ classes:{ root: classes.container }}}
      >
        <DialogTitle disableTypography={true} className={ classes.title } id='skill-dialog-title'>
          <Typography className={ classes.titleText } variant='h4'>
            <FormattedMessage id="Wizard.skillDB.title"/>
          </Typography>
          <IconButton
            onClick={this.close}
            className={ classes.exitIcon }
            aria-label="close dialog"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className={ classes.root }>
            <Typography gutterBottom variant='h6'><FormattedMessage id='Wizard.skillDB.subtitle1' />:</Typography>
            <FormGroup>
              <TextField
                classes={{ root: classes.firstName }}
                variant='outlined'
                id='skill-name'
                label={<FormattedMessage id='General.name' />}
                type='text'
                name='name'
                value={skill.name}
                onChange={this.props.handleChange}
              />
              <TextField
                multiline
                classes={{ root: classes.firstName }}
                variant='outlined'
                id='skill-description'
                label={<FormattedMessage id='General.description' />}
                type='text'
                name='description'
                value={skill.description}
                onChange={this.props.handleChange}
              />
              <TextField
                classes={{ root: classes.firstName }}
                variant='outlined'
                id='area'
                label={<FormattedMessage id='General.area' />}
                type='text'
                name='area'
                value={skill.area}
                onChange={this.props.handleChange}
              />
            </FormGroup>
            <DialogActions className={classes.buttons}>
              <Button onClick={this.props.back} color='secondary'>
                <FormattedMessage id='General.back' />
              </Button>
              <Button onClick={this.props.submit} variant='contained' color='primary'>
                <FormattedMessage id='General.submit' />
              </Button>
            </DialogActions>
          </div>
        </DialogContent>
      </Dialog>
    )
  }
}

export default withStyles(baseStyle)(SkillDatabankWizardPage2);