import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PercentToTarget from '../../components/Charts/PercentToTargetBar';
import { InsertChartOutlined } from '@material-ui/icons';
import { Divider, Typography,Button } from '@material-ui/core';
import { getStudent, hoursPlayedPerGame, hoursPlayedPerSkill, percentToTargetLine, percentToTarget, brailleAIChart, brailleSheetsChart, getAIAudioFile } from '../../../redux/actions';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../styles/dashboard';
import HoursPlayedPie from '../../components/Charts/HoursPlayedPie';
import PercentToTargetLine from '../../components/Charts/PercentToTargetLine';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import BrailleAIChart from '../../components/Charts/BrailleAIChart';
import BrailleSheetsChart from '../../components/Charts/BrailleSheetsChart';

const CollisionLink = (url) => React.forwardRef((props, ref) => (
    <Link innerRef={ref} to={url} {...props} />
));

class StudentDashboard extends Component {

  state = {
    hoursPlayed: [],
    percentToTargetBar: [],
    percentToTarget: [],
    brailleAIChart: [],
    brailleSheetsChart: [],
    time: 7,
    clicked: false,
    skill: {}
  };

  componentDidMount() {
    this.props.getStudent(this.props.id);
    this.hoursPlayedPerGame('student', this.props.id, 7);
    percentToTarget(this.props.id).then((response) => {
      const data = response || [];
        var dataSets = [];
        while (data.length > 3) {
            dataSets.push(data.splice(0, 3));
        }
        dataSets.push(data);
        this.setState({percentToTargetBar: dataSets})
    });
    percentToTargetLine(this.props.id).then(data => {
      this.setState({ percentToTarget: data })
    });
    brailleAIChart(this.props.id).then(chart => {
      this.setState({ brailleAIChart: chart })
    });
    brailleSheetsChart(this.props.id).then(chart => {
      this.setState({ brailleSheetsChart: chart })
    })
  }

  toggleSkill = (skill) => {
    !this.state.clicked
      ? this.hoursPlayedPerSkill(skill, this.props.id, this.state.time, true)
      : this.hoursPlayedPerGame('student', this.props.id, this.state.time, true);
    this.setState({skill})
  };

  changeTime = (time) => {
    this.state.clicked
      ? this.hoursPlayedPerSkill(this.state.skill, this.props.id, time)
      : this.hoursPlayedPerGame('student', this.props.id, time);
    this.setState({ time })
  };

  handleFileClick = (fileId) => {
    getAIAudioFile(this.props.id, fileId)
      .then(() => this.setState({ downloaded: { [fileId]: true }}))
  };

  hoursPlayedPerSkill = (skill, id, time, toggle) => {
    hoursPlayedPerSkill(skill, id, time).then(response => {
      const chart = Array.isArray(response) ? response.map(data => {
        const time = moment(data.time).format('M/D/YYY');
          return {
          id: data.skillName,
          label: data.skillName,
          value: data.value,
          time,
          skillId: data.skillId,
        }
      }): [];
      this.setState(({ clicked }) => ({ hoursPlayed: chart, clicked: toggle ? !clicked : clicked }))
    })
  };

  hoursPlayedPerGame = (type, id, time, toggle) => {
    hoursPlayedPerGame(type, id, time).then(response => {
      const chart = response.map(data => {
        const time = moment(data.time).format('M/D/YYY');
          return {
          id: data.gameName,
          label: data.gameName,
          value: data.value,
          time,
          skillId: data.skillId,
        }
      });
      this.setState(({ clicked }) => ({ hoursPlayed: chart, clicked: toggle ? !clicked : clicked }))
    });
  };

    render() {
        const {classes, student, history} = this.props;
        return (
            <div className={classes.container}>
                <div className={classes.title}>
                    <InsertChartOutlined className={classes.icon} fontSize='inherit'/>
                    <Typography className={classes.text} variant='h3'>
                        {student.firstName}'s <FormattedMessage id='Dashboard.title'/>
                    </Typography>
                </div>
                <Divider/>
                <div style={{paddingTop:"10px", paddingBottom:"10px"}}>
                <Button component={CollisionLink('/progress/'+student.id)} variant='contained'>
                    Progress Report
                </Button>
                </div>
                <div className={classes.pieChart}>
                    <HoursPlayedPie
                        toggleSkill={this.toggleSkill}
                        time={this.state.time}
                        changeTime={this.changeTime}
                        data={this.state.hoursPlayed}
                        title={<FormattedMessage id='Chart.hoursPlayed.title'/>}
                    />
                </div>
                <div className={classes.chart}>
                    <PercentToTargetLine
                        title={<FormattedMessage id='Chart.percentLine.title'/>}
                        data={this.state.percentToTarget}
                    />
                </div>
                {
                    this.state.percentToTargetBar.map((_data) => (
                        <div className={classes.chart}>
                        {/*<PercentToTarget*/}
                        {/*    id={this.props.id}*/}
                        {/*    data={_data}*/}
                        {/*    title={<FormattedMessage id='Chart.percentBar.title'/>}*/}
                        {/*    history={history}*/}
                        {/*/>*/}
                        </div>
                        ))
                }
                {
                    this.state.brailleAIChart && this.state.brailleAIChart.length > 0 &&
                    <BrailleAIChart handleFileClick={this.handleFileClick} data={this.state.brailleAIChart}/>
                }
                {
                    this.state.brailleSheetsChart && this.state.brailleSheetsChart.length > 0 &&
                    <BrailleSheetsChart data={this.state.brailleSheetsChart}/>
                }
            </div>
        )
    }
}

const mapState = ({platform: {skills, student}}) => {
    return {
        skills,
        student
    }
};

const mapDispatch = (dispatch) => {
    return {
        getStudent: (id) => dispatch(getStudent(id)),
    }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(StudentDashboard));