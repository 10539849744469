import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
  TextField,
  Typography,
  Button,
  FormGroup,
  Link,
  withStyles,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle,
  FormLabel,
  FormControl,
  FormHelperText,
} from '@material-ui/core/index';
import logo from '../../../../assets/BrandAssets/Logo/OBJECTIVEED-Logo-Vertical-1.png';
import buddyBooksLogo from '../../../../assets/BrandAssets/Logo/BB-logo-oed.png';
import styles from '../../../../styles/registration';
import { Close } from '@material-ui/icons';

const CollisionLink = React.forwardRef((props, ref) => (
  <RouterLink innerRef={ref} to="/login" {...props} />
));

class SpecialistRegistrationPage1 extends Component {

  state = {
    error: {
      email: false,
      firstName: false,
      lastName: false,
      password:false,
      confirmPassword: false,
      showPassword: false
    },
    showTermPage: false
  };

  componentDidMount(){
    // document.body.style.backgroundColor = '#310073';
  }

  handleClose = () => {
    this.props.clearErrors();
  };

  check = (ev, type) => {
    this.setState({email: false,
      firstName: false,
      lastName: false,
      password:false,
      confirmPassword: false,
      showPassword: false});
    switch(type){
      case 'email':
        let email = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(ev.target.value);
        this.setState({ error: { ...this.state.error, email }});
        break;
      case 'firstName':
        let firstName = !ev.target.value;
        this.setState({ error: {...this.state.error, firstName }});
        break;
      case 'lastName':
        let lastName = !ev.target.value;
        this.setState({ error: { ...this.state.error, lastName }});
        break;
      case 'password':
        let password = !ev.target.value;
        this.setState({ error: {...this.state.error, password }});
        break;
      case 'confirmPassword':
        let confirmPassword = !ev.target.value;
        this.setState({ error: {...this.state.error, confirmPassword }});
        break;
      default:
        break;
    }
  };

  handleTermPage = (e) => {
    e.preventDefault();
    this.setState({ showTermPage: true })
  }
  handleTermClose = () => {
    this.setState({ showTermPage: false })
  }
  checkScrolled = ({ target }) => {
    if (target.scrollHeight - target.scrollTop <= 440) {
      this.setState({ scrolled: true })
    }
  };
  render(){
    const { classes } = this.props;
    const { showPassword } = this.state;
    const errorCheck = this.props.error;
    const disabled = this.state.error.email || this.state.error.password || this.state.error.confirmPassword ||this.state.error.firstName|| this.state.error.lastName;
    const error =this.props.stateVal.termAndCond && !this.props.stateVal.acceptedTerms;
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    return (
      <>
      <div className={ classes.logo }>
        <Link component={CollisionLink}>
          <img
            className={classes.objectiveEdLogo}
            alt='objective ed logo'
            src={logo}
          />
        </Link>
        <div>
          <img className={classes.buddyLogo} alt='buddyBooks logo' src={buddyBooksLogo}/>
        </div>
        </div>
        <Typography gutterBottom variant={isMobile ? 'subtitle2' : 'h6'}>Welcome to ObjectiveEd! Please fill out this form to register:</Typography>
        <FormGroup className={classes.name}>
          <TextField
            id='firstName'
            name='firstName'
            variant='outlined'
            label='First Name'
            required
            error={this.state.error.firstName}
            helperText={this.state.error.firstName ? <FormattedMessage id='Paddle.error.first'/> : ''}
            onChange={this.props.handleChange}
            onBlur={(ev) => this.check(ev, 'firstName')}
          />
          <TextField
            id='lastName'
            name='lastName'
            variant='outlined'
            label='Last Name'
            error={this.state.error.lastName}
            helperText={this.state.error.lastName ? <FormattedMessage id='Paddle.error.last'/> : ''}
            onChange={this.props.handleChange}
            onBlur={(ev) => this.check(ev, 'lastName')}
          />
        </FormGroup>
        <FormGroup>
          <TextField
            required
            id='email'
            name='email'
            variant='outlined'
            label='Email'
            error={this.state.error.email || (errorCheck.field == 'email' && errorCheck.msg)}
            helperText={this.state.error.email ? <FormattedMessage id='Paddle.error.email'/> : (errorCheck.field == 'email' && errorCheck.msg)}
            onChange={this.props.handleChange}
            onBlur={(ev) => this.check(ev, 'email')}
          />
        </FormGroup>
        <FormGroup className={classes.name}>
            <TextField
              required
              id='password'
              variant='outlined'
              helperText={this.state.error.password ? <FormattedMessage id='Paddle.error.password' /> : ''}
              type={showPassword ? 'text' : 'password'}
              error={this.state.error.password}
              label={<FormattedMessage id='Login.password' />}
              value={this.props.password}
              name="password"
              onChange={this.props.handleChange}
              onBlur={(ev) => this.check(ev, 'password')}
            />
            <TextField
              required
              id='confirmPassword'
              helperText={this.state.error.confirmPassword ? <FormattedMessage id='Paddle.error.match' /> : (errorCheck.field == 'confirmPassword' && errorCheck.msg)} 
              variant='outlined'
              type={showPassword ? 'text' : 'password'}
              error={this.state.error.confirmPassword || (errorCheck.field == 'confirmPassword' && errorCheck.msg)}
              name="confirmPassword"
              label={<FormattedMessage id='Login.confirmPassword' />}
              value={this.props.confirmPassword}
              onChange={this.props.handleChange}
              onBlur={(ev) => this.check(ev, 'confirmPassword')}
            />
          </FormGroup>
        <FormControl required error={error} component="fieldset" className={classes.formControl}>
          <FormControlLabel control={<Checkbox
            required
            onChange={this.props.handleChange}
            checked={this.props.stateVal.acceptedTerms}
            name="checked"
            color="primary" />}
            label={
              <FormLabel style={{color:'grey'}}>I have read and agree to the <a href=""
              style={{ textDecoration: 'none' }}
              onClick={this.handleTermPage}>terms and conditions</a> </FormLabel>
            } />
          {error && <FormHelperText>Terms and condition is required</FormHelperText>}
        </FormControl>
        <Button
          id='confirm-registration-button'
          disabled={this.props.stateVal && (this.props.stateVal.password && this.props.stateVal.confirmPassword && this.props.stateVal.email && this.props.stateVal.firstName && this.props.stateVal.lastName && this.props.stateVal.acceptedTerms) ? false : true}
          className={ classes.confirmBtn }
          variant='contained'
          onClick={this.props.next}
        >
          Confirm Registration and Continue
        </Button>
        <Typography gutterBottom align='center' variant='caption'>
          Already have an account? <RouterLink to='/login'>Go to login page</RouterLink>.
        </Typography>
        {this.state.showTermPage &&
          <Dialog
            open={this.state.showTermPage}
            close={this.handleTermClose}
            scroll='paper'
            maxWidth="md">
            <DialogTitle disableTypography={true} id='form-dialog-title'>
              <Typography variant='h6' style={{ float: 'left' }}> <FormattedMessage id='Paddle.terms' /> </Typography>
              <IconButton
                onClick={this.handleTermClose}
                style={{ float: 'right' }}
                aria-label="close dialog"
              >
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <iframe
                id='toi'
                title='Terms of Use'
                width={800}
                height={600}
                onScroll={this.checkScrolled}
                src='https://docs.google.com/document/d/e/2PACX-1vRgiqiZ2EZ0jHECJ_bY972X3rhK1m722J19V7Rt3nTPnQLdzhMIMwQAH3_Iq1UM11Ask9bbs_l-ebB1/pub?embedded=true'
              />
            </DialogContent>
          </Dialog>}
      </>
    )
  }
}

export default withStyles(styles)(SpecialistRegistrationPage1);