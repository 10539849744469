import {
  ROOT_URL,
  FETCH_ALL_SUPPORT_USERS,
  CREATE_SUPPORT_USER,
  FETCH_USER,
  RESET_USER,
  FETCH_ACCOUNT,
  RESET_ACCOUNT,
  HANDLE_ERROR
} from '../../constants';
import axios from 'axios';
import { fetchOnlineUsers, registerChatUser } from '../../../socket-client';

export const fetchSupportUsers = () => {
  const url = `${ROOT_URL}/support/users`;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: FETCH_ALL_SUPPORT_USERS,
          users: response.data
        })
      })
      .then(() => {
        dispatch({
          type: RESET_USER,
        })
      })
      .catch(err => console.log(err))
  }
};

export const loginAsSupport = (user, history) => {
  const url = `${ROOT_URL}/support`;
  return (dispatch) => {
    axios
      .post(url, user)
      .then((response) => {
        dispatch({
          type: FETCH_USER,
          user: response.data
        });
        dispatch({
          type: RESET_ACCOUNT,
        });
        if (response.data.homePage) {
          return history.push(response.data.homePage);
        }
        else {
          dispatch({
            type: HANDLE_ERROR,
            error: {msg: 'Cannot log in as student!'}
          })
        }
        registerChatUser(user);
        fetchOnlineUsers()
      })
      .catch(err => console.log(err))
    axios
      .get(`${ROOT_URL}/admin`)
      .then((response) => {
        dispatch({
          type: FETCH_ACCOUNT,
          account: response.data
        })
      })
  }
};

export const createSupportUser = (user) => {
  const url = `${ROOT_URL}/support/users`;
  return (dispatch) => {
    axios
      .post(url, user)
      .then((response) => {
        dispatch({
          type: CREATE_SUPPORT_USER,
          user: response.data
        })
      })
      .catch(err => console.log(err))
  }
};