import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { createGuide } from '../../../redux/actions';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, MenuItem, withStyles } from '@material-ui/core';
import baseStyle from '../../../styles/helpConfig';

class AddGuide extends Component {

  state = {
    open: false,
    description: '',
    type: '',
    url: ''
  };

  toggleDialog = () => {
    this.setState({ open: !this.state.open })
  };

  handleChange = ({ target: { name, value }}) => {
    this.setState({ [name]: value })
  };

  submit = () => {
    const { description, type } = this.state;
    const url = type === 'video' ? this.state.url.split('=')[1] : this.state.url;
    this.props.createGuide({ description, type, url }, this.props.pageId);
    this.toggleDialog();
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <Dialog
          fullWidth
          open={this.state.open}
          aria-label='New page creator'
          onClose={this.toggleDialog}
        >
          <DialogTitle> Add A New Guide </DialogTitle>
          <DialogContent>
            <TextField
              className={classes.line}
              fullWidth
              variant='outlined'
              name='description'
              label='Description'
              value={this.state.description}
              onChange={this.handleChange}
            />
            <TextField
              select
              className={classes.line}
              fullWidth
              variant='outlined'
              name='type'
              label='Type'
              value={this.state.type}
              onChange={this.handleChange}
            >
              <MenuItem value='video'> Video </MenuItem>
              <MenuItem value='pdf'> PDF </MenuItem>
            </TextField>
            <TextField
              className={classes.line}
              fullWidth
              variant='outlined'
              name='url'
              label='Video ID (letters at end of url)'
              value={this.state.url}
              onChange={this.handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button
              color='secondary'
              onClick={this.toggleDialog}
            >
              <FormattedMessage id='General.cancel'/>
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={this.submit}
            >
              <FormattedMessage id='General.submit'/>
            </Button>
          </DialogActions>
        </Dialog>
        <Button
          className={classes.addBtn}
          variant='contained'
          aria-label='toggle page creator'
          onClick={this.toggleDialog}
        >
          Add Guide
        </Button>
      </>
    );
  }
}

const mapDispatch = (dispatch) => {
  return {
    createGuide: (guide, pageId) => dispatch(createGuide(guide, pageId))
  }
};

export default connect(null, mapDispatch)(withStyles(baseStyle)(AddGuide));