import React, { Component } from 'react';
import { connect } from 'react-redux';
import ellipsis from '../../../assets/Loading/Ellipsis.svg';
import { FormattedMessage } from 'react-intl';
import styles from '../../../styles/browseStory.js';
import WizardToggleButton from '../Wizards/WizardToggleButton';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Grid,
    Paper,
    Typography,
    Toolbar,
    Tooltip,
    InputAdornment,
    TextField,
    withStyles,
    Tab,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Button,
    Box,
    AppBar,
} from '@material-ui/core';
import { CheckCircle, Close, CloudDownload, CodeSharp, Search } from '@material-ui/icons';
import { addStoryToStudent, clearBookShareLogin, downloadBook, fetchUserSkillDatabanks, getAllBooks, getDatabank, getStoryList, searchForBooks, getBookShareIdentity } from '../../../redux/actions/index.js';
import { stableSort, getSorting } from '../../../helpers';
import Slider from '../../../Social/NetflixSlider';
import BookShareTable from '../Shared/BookShareTable';
import DisplayStoryList from './DisplayStoryList';
import { RESET_IMPORT_MODAL } from '../../../redux/constants';
import BookShareCredentialsLogin from './BookShareCredentialsLogin';
import LoadingModal from './LoadingModal';

const rows = [
    { id: 'title', label: <FormattedMessage id='General.title' /> },
    { id: 'author', label: <FormattedMessage id='General.author' /> },
    { id: 'synopsis', label: <FormattedMessage id='General.synopsis' /> },
    { id: 'isbn', label: <FormattedMessage id='General.isbn' /> }
];

const dBRows = [
    // { id: 'gradeLevel', label: <FormattedMessage id='General.gradeLevel' /> },
    { id: 'name', label: <FormattedMessage id='General.title' /> },
    { id: 'author', label: <FormattedMessage id='General.author' /> },
    { id: 'isbn', label: <FormattedMessage id='General.isbn' /> },
    { id: 'source', label: <FormattedMessage id='General.source' /> }
];

export class BrowseBook extends Component {

    state = {
        selected: {},
        open: false,
        searchValue: '',
        filteredData: [],
        browseTabClicked: true,
        recomendTabClicked: true,
        lexileTabClicked: false,
        readingTabClicked: false,
        searchTabClicked: false,
        searchFromBookShare: false,
        searchField: 'title',
        searchCategory: 'name',
        imported: [],
        openModal: false,
        modalType: '',
        noResultFound: false,
        filteredDB: null,
        loginRequired: false
    };

    componentDidMount() {
        this.props.dispatch(getAllBooks(this.props.id));
        this.props.dispatch(getStoryList(this.props.id));
        this.props.dispatch(fetchUserSkillDatabanks(false));
        this.props.dispatch(getDatabank(this.props.id));
        this.props.dispatch(getBookShareIdentity());
    };

    componentDidUpdate(prevProps){
        if(this.props.user !== prevProps.user){
            if(this.state.searchValue !=="" && this.props.user.hasBookShareCredentials){
                this.props.dispatch(searchForBooks(this.state.searchValue, this.state.searchField));
            }
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.storyList !== this.props.storyList && nextProps.storyList) {
            console.log('storyList browse', nextProps.storyList);
        }

        if (nextProps.downloadBook !== this.props.downloadBook && nextProps.downloadBook.isBookDownloaded) {
            const { isBookDownloaded } = nextProps.downloadBook;
            if (!isBookDownloaded)
                this.setState({ openModal: true, modalType: 'DownloadFail' });
        }

        if (nextProps.bookShareList !== this.props.bookShareList && !nextProps.bookShareList.length) {
            this.setState({ noResultFound: true });
        }
        if (this.props.user && nextProps.user && nextProps.user != this.props.user && nextProps.user.hasBookShareCredentials) {
            if (this.state.searchFromBookShare && this.state.searchValue) {
                this.handleSearch();
            }
        }
    }

    loginBookshare = () => {
        this.setState({
            loginRequired: true
        });
    }

    logOutBookshare = () => {
        this.props.dispatch(clearBookShareLogin());
        this.props.dispatch(getBookShareIdentity());
    }

    openBookshareAccess = () => {
        this.setState({
            showBookshareAccess: true
        })
    }

    closeBookshareAccess = () => {
        this.setState({
            showBookshareAccess: false,
        })
    }

    browseStoryList = () => {
        this.setState({
            browseTabClicked: true,
            recomendTabClicked: true,
            lexileTabClicked: false,
            readingTabClicked: false,
            searchTabClicked: false,
            searchFromBookShare: false,
            searchValue: ''
        })
        this.props.dispatch(getStoryList(this.props.id));
    };

    browseLexileStoryList = () => {
        this.setState({
            searchTabClicked: false,
            recomendTabClicked: false,
            lexileTabClicked: true,
            readingTabClicked: false,
            searchValue: '',
            includeTitleImage: false
        });
        this.props.dispatch({ type: RESET_IMPORT_MODAL })
    };

    browseStudentStoryList = () => {
        this.setState({
            searchTabClicked: false,
            recomendTabClicked: false,
            lexileTabClicked: false,
            readingTabClicked: true,
            searchValue: ''
        })
    };

    clickSearchTab = (text, storyList) => {
        this.setState({
            searchTabClicked: true,
            browseTabClicked: false,
            recomendTabClicked: false,
            lexileTabClicked: false,
            readingTabClicked: false,
        });
        this.handleSearch("first",text, storyList);
    };

    // importSkill = (book) => {
    //     console.log('download from import skill');
    //     if(this.state.searchFromBookShare) {
    //         this.props.dispatch(downloadBook(book.id, this.props.id));
    //     } else {
    //         this.props.dispatch(addStoryToStudent(this.props.id, book.id));
    //     }
    //     this.setState(state => ({ imported: [...state.imported, book.id], openModal: true, modalType: 'Downloading' }))
    // };

    handleSearch = (...args) => {
        if (this.state.searchFromBookShare ) {
            if(this.props.user.hasBookShareCredentials){
                this.props.dispatch(searchForBooks(this.state.searchValue, this.state.searchField));
                this.setState({
                    noResultFound: false,
                    searchTabClicked: true,
                    browseTabClicked: false,
                    recomendTabClicked: false,
                    lexileTabClicked: false,
                    readingTabClicked: false,
                    includeTitleImage: true,
                });}
                else{
                    this.setState({
                        loginRequired:true,
                        noResultFound: true,})
                }
        }
        else if(args && args[0] == "first"){
            this.setState({
                noResultFound:  false,
                filteredDB: this.props.databank,
                searchTabClicked: true,
                browseTabClicked: false,
                recomendTabClicked: false,
                readingTabClicked: false,
            });
        }
        else {
            let filteredDB = this.state.searchValue && this.props.databank.filter(item => {
                const searchableKeys = [{ id: 'name' }, { id: 'author' }]
            return searchableKeys.some(key => {
                if (item[key.id]) {
                    return item[key.id].toLowerCase().includes(this.state.searchValue.toLowerCase())
                }
                else {
                    return false
                }
            })
            });
            let isFiltered = filteredDB && filteredDB.length > 0;
            this.setState({
                noResultFound: isFiltered ? false : true,
                filteredDB: isFiltered ? filteredDB : [],
                searchTabClicked: true,
                browseTabClicked: false,
                recomendTabClicked: false,
                lexileTabClicked: true,
                readingTabClicked: false,
                includeTitleImage: false
            });
        }
    };

    openWizard = () => {
        this.setState({ open: true })
    };

    closeWizard = () => {
        this.setState({
            selected: {},
            open: false,
            searchValue: '',
            filteredData: [],
            browseTabClicked: true,
            recomendTabClicked: true,
            lexileTabClicked: false,
            readingTabClicked: false,
            searchTabClicked: false,
            searchFromBookShare: false,
            imported: [],
            openModal: false,
            modalType: '',
            noResultFound: false,
            filteredDB: null
        });
    };

    changeSearchData = (toggle) => {
        this.setState({ searchFromBookShare: toggle });
        setTimeout(() => {
            if (this.props.user && !this.props.user.hasBookShareCredentials) {
                this.setState({ loginRequired: true });
            }
            else if (this.state.searchFromBookShare && this.state.searchValue) {
                console.log("Book toggle api call", this.state);
                this.handleSearch();
            }
        }, 200)
    }
    closeLoginModal = () => {
        this.setState({ loginRequired: false });
    }
    openModal=()=>{
        this.setState(state => ({ openModal: true, modalType: 'Downloading' }))
    }

    render() {
        const { classes, storyList, bookShareList, databank, user } = this.props;
        console.log('storyList', storyList);
        const { open, filteredDB, loginRequired, showBookshareAccess } = this.state;
        let data = this.state.searchFromBookShare ? bookShareList : filteredDB;
        const objData = data && data.filter(_data => _data.source == 'ObjectiveEd');
        if (user && user.type == 'Individual' && !user.hasBookShareCredentials) {
            data = objData;
        }
        return (
            <>
                <Dialog open={open} classes={{ paper: classes.browseDialog }} maxWidth='lg' fullWidth={true} onClose={this.closeWizard}>
                    <Paper style={{ "width": "100%", "overflow-y":"hidden"}}>
                        <AppBar position='sticky'>
                            <Toolbar classes={{ root: classes.toolbar }}>
                                <Tab
                                    label="Browse"
                                    className={this.state.browseTabClicked ? classes.clicked : classes.unClicked}
                                    onClick={this.browseStoryList}
                                />
                                <div className={classes.actions}>
                                    <Tab
                                        label="Search"
                                        className={this.state.searchTabClicked ? classes.clicked : classes.unClicked}
                                        onClick={() => { this.clickSearchTab(this.state.searchValue, bookShareList) }}
                                    />
                                    <TextField
                                        variant='outlined'
                                        id='table-search'
                                        aria-label='search input'
                                        type='search'
                                        placeholder='Search our Books!'
                                        InputProps={{
                                            inputProps: {
                                                'aria-label': 'search input'
                                            },
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <Search style={{ cursor: 'pointer' }} onClick={this.handleSearch} />
                                                </InputAdornment>
                                            ),
                                            className: classes.searchBar,
                                        }}
                                        onChange={(ev) => {
                                            this.setState({ searchValue: ev.target.value });
                                        }}
                                        value={this.state.searchValue}

                                        onKeyUp={(event) => {
                                            if (event.key == 'Enter')
                                                this.handleSearch();
                                        }}
                                    />
                                </div>
                                <IconButton onClick={this.closeWizard} className={classes.close}>
                                    <Close />
                                </IconButton>
                            </Toolbar>
                            {this.state.browseTabClicked &&
                                <div className={classes.browseBtnWrapper} style={{ backgroundColor: "white", color: "grey" }}>
                                    <Tab
                                        label="Recommendations"
                                        className={this.state.recomendTabClicked ? classes.innerTabClicked : classes.innerTabUnclicked}
                                        onClick={this.browseStoryList}
                                    />
                                    <Tab
                                        label="Books by Grade Level"
                                        className={this.state.lexileTabClicked ? classes.innerTabClicked : classes.innerTabUnclicked}
                                        onClick={this.browseLexileStoryList}
                                    />
                                    <Tab
                                        label="Your Reading Lists"
                                        className={this.state.readingTabClicked ? classes.innerTabClicked : classes.innerTabUnclicked}
                                        onClick={this.browseStudentStoryList}
                                    />
                                </div>
                            }
                        </AppBar>
                        <div style={{ display: 'flex', flexDirection: 'column', height: this.state.searchTabClicked ? '74vh' : '65vh' }}>
                        <DialogContent style={{ flex: '1', overflowY: 'auto' }}>

                        {this.state.searchTabClicked &&
                            <BookShareTable
                                rows={this.state.searchFromBookShare ? rows : dBRows}
                                data={data}
                                imported={this.state.imported}
                                noResultFound={this.state.noResultFound}
                                changeSearchData={this.changeSearchData}
                                includeTitleImage={this.state.searchFromBookShare}
                                bookShareSearchToggle={this.state.searchFromBookShare}
                                lexileTabClicked={this.state.searchFromBookShare ? false : true}
                                storyId={this.props.id}
                                toggleSearch={this.state.searchFromBookShare}
                                searchFromBookShare={this.state.searchFromBookShare}
                                closeWizard={this.closeWizard}
                                hasBookShareCredentials={this.props.user.hasBookShareCredentials}
                                openModal={this.openModal}
                            />
                        }
                        {this.state.browseTabClicked &&
                            <DisplayStoryList
                                id={this.props.id}
                                type={
                                    this.state.recomendTabClicked
                                        ? 'Recomendations'
                                        : this.state.lexileTabClicked
                                            ? 'LexileStory'
                                            : 'StudentStory'
                                }
                                storyList={storyList}
                                imported={this.state.imported}
                                storyId={this.props.id}
                                closeWizard={this.closeWizard}
                            />
                        }
                        </DialogContent>
                            {this.props.user && !this.props.user.hasBookShareCredentials &&
                                <DialogActions position='fixed' bottom='0'>
                                    <div className={classes.browseBtnWrapper} style={{
                                        align: "center",
                                        backgroundColor: "white",
                                        color: "grey",
                                        width: "100%"
                                    }}>
                                        <Tab
                                            label="Login to Bookshare"
                                            className={classes.innerTabClicked}
                                            onClick={this.loginBookshare}
                                        />
                                        { this.props.user.type=="Individual" &&
                                            <Tab onClick={this.openBookshareAccess}
                                                 className={classes.innerTabClicked}
                                                 label="Click here to get access to Bookshare"/>
                                        }
                                    </div>
                                </DialogActions>
                            }
                            {this.props.user && this.props.user.hasBookShareCredentials &&
                                <DialogActions position='fixed' bottom='0'>
                                    {/*<div className={classes.browseBtnWrapper} style={{*/}
                                    {/*    align: "center",*/}
                                    {/*    backgroundColor: "white",*/}
                                    {/*    color: "grey",*/}
                                    {/*    width: "100%"*/}
                                    {/*}}>*/}
                                    {/*    <Tab*/}
                                    {/*        label="Logout from Bookshare"*/}
                                    {/*        className={classes.innerTabClicked}*/}
                                    {/*        onClick={this.logOutBookshare}*/}
                                    {/*    />*/}
                                    {/*    <div style={{*/}
                                    {/*        paddingTop: "15px"*/}
                                    {/*    }}>Logged in As: {this.props.bookShareIdentity}</div>*/}
                                    {/*</div>*/}
                                </DialogActions>
                            }
                        </div>
                    </Paper>
                </Dialog>
                <WizardToggleButton
                    openWizard={this.openWizard}
                    text={<FormattedMessage id='Databanks.browseStory' />}
                    disabled={!this.props.databank}
                />
                {loginRequired && <BookShareCredentialsLogin
                    openLoading={loginRequired}
                    exitFunc={this.closeLoginModal} />}
                {
                    this.state.openModal &&
                    <LoadingModal openLoading={this.state.openModal} type={this.state.modalType} exitFunc={()=>{this.setState({ openModal: false, modalType: '' })}} />
                }
                {showBookshareAccess &&
                    <Dialog open={showBookshareAccess} onClose={this.closeBookshareAccess} maxWidth="md" fullWidth>
                        <DialogContent style={{ overflowY: 'auto' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'  }}>
                                    <img
                                        src="https://www.bookshare.org/cms/sites/default/files/styles/panopoly_image_original/public/images/media/current_version_bookshare_logo_transparent_background_with_tagline_horizontal_color.png?itok=2lZD6Hcw"
                                        alt="Image 1"
                                        style={{ width: '66%', height: 'auto' }}
                                    />
                                </Grid>
                                <Grid item xs={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'  }}>
                                    <img
                                        src="https://s3.us-east-2.amazonaws.com/oe-ui/BB-logo-oed.png"
                                        alt="Image 2"
                                        style={{ width: '66%', height: 'auto' }}
                                    />
                                </Grid>
                            </Grid>
                            <div>
                                Bookshare is an ebook library of 1 million+ books that are available to qualifying individuals.  BuddyBooks partnership with Bookshare allows those with a Bookshare account to link their accounts and read books from Bookshare’s catalogue within BuddyBooks.  For more information on how to qualify for a Bookshare account, click here: <a target={"_blank"} href='https://www.objectiveed.com/bookshare-test'>Bookshare Qualification</a>
                            </div>
                        </DialogContent>
                    </Dialog>
                }
            </>
        )
    }
}

const mapState = ({ platform: { books, bookShareList, storyList, databank, skillDatabanks }, shared: { user,bookShareIdentity } }) => {
    return {
        books,
        bookShareList,
        storyList,
        databank,
        skillDatabanks,
        user,
        bookShareIdentity,
    }
};

export default connect(mapState)(withStyles(styles)(BrowseBook));