import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {
    Button,
    Dialog,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    MenuItem,
    TextField,
    withStyles
} from '@material-ui/core';
import { Paper, Typography, Toolbar, Tooltip } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { CloudDownload, Close, CheckCircle, Search } from '@material-ui/icons';
import { getAllBooks, addStoryToStudent, searchForBooks, downloadBook, clearBookShareLogin } from '../../../redux/actions';
import { stableSort, getSorting } from '../../../helpers';
import WizardToggleButton from '../Wizards/WizardToggleButton';
import styles from '../../../styles/importSkill.js';
import BookShareCredentialsLogin from './BookShareCredentialsLogin';
import LoadingModal from './LoadingModal';

const rows = [
    { id: 'title', label: <FormattedMessage id='General.title' /> },
    { id: 'author', label: <FormattedMessage id='General.author' /> },
    { id: 'synopsis', label: <FormattedMessage id='General.synopsis' /> },
    { id: 'isbn', label: <FormattedMessage id='General.isbn' /> }
];


class ImportBook extends Component {

    state = {
        order: 'asc',
        orderBy: 'difficulty',
        selected: {},
        open: false,
        page: 0,
        rowsPerPage: 5,
        filteredData: [],
        searchValue: '',
        searchField: 'title',
        searchCategory: 'name',
        imported: [],
        loginRequired: false,
        openModal: false,
        modalType: '',
        noResultFound: false
    };

    componentDidMount() {
        this.props.getAllBooks(this.props.id);
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.user.hasBookShareCredentials && this.state.loginRequired) {
            this.setState({ loginRequired: false, open: true })
        }

        if (nextProps.downloadBook !== this.props.downloadBook && nextProps.downloadBook.isBookDownloaded) {
            const { isBookDownloaded } = nextProps.downloadBook;
            if (!isBookDownloaded)
                this.setState({ openModal: true, modalType: 'DownloadFail' });
        }

        if (nextProps.bookShareList !== this.props.bookShareList && !nextProps.bookShareList.length) {
            this.setState({ noResultFound: true });
        }
    }

    handleSearch = () => {
        this.props.searchForBooks(this.state.searchValue, this.state.searchField);
        this.setState({ noResultFound: false });
    };

    handleChange = ({ target: { name, value } }) => {
        this.setState({ [name]: value })
    };


    handleSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';
        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }
        this.setState({ order, orderBy });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    openWizard = () => {
        if (this.props.user.hasBookShareCredentials) {
            this.setState({ open: true });
        } else {
            this.setState({ loginRequired: true });
        }
    };

    closeModal = () => {
        this.setState({ openModal: false, modalType: '' })
    }

    closeWizard = () => {
        this.setState({ open: false, loginRequired: false });
    };

    importSkill = (book) => {
        console.log('download from import skill');
        this.props.downloadBook(book.id, this.props.id);
        this.setState(state => ({ imported: [...state.imported, book.id], openModal: true, modalType: 'Downloading' }))
    };

    render() {
        const { classes, bookShareList } = this.props;
        const { orderBy, order, open, page, rowsPerPage, searchValue, filteredData } = this.state;
        const data = bookShareList;
        return (
            <>
                <Dialog open={open} maxWidth='lg' onClose={this.closeWizard}>
                    <Paper className={classes.list}>
                        <Toolbar classes={{ root: classes.toolbar }}>
                            <Typography noWrap className={classes.header}>
                                <strong><FormattedMessage id='Databanks.importBookShareHeader' /></strong>
                            </Typography>
                            <div className={classes.actions} style={{ width: '100%' }}>
                                <TextField
                                    select
                                    variant='outlined'
                                    name='searchField'
                                    style={{ width: '30%', background: 'white', borderRadius: '4px' }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{
                                        className: classes.searchField,
                                    }}
                                    value={this.state.searchField}
                                    onChange={(ev) => {
                                        this.setState({ searchField: ev.target.value })
                                    }}>
                                    <MenuItem value={"title"}>Title</MenuItem>
                                    <MenuItem value={"grade"}>Grade</MenuItem>
                                    <MenuItem value={"author"}>Author</MenuItem>
                                    <MenuItem value={"isbn"}>ISBN</MenuItem>
                                </TextField>
                                <TextField
                                    variant='outlined'
                                    id='table-search'
                                    aria-label='search input'
                                    type='search'
                                    placeholder='Search Bookshare'
                                    InputProps={{
                                        inputProps: {
                                            'aria-label': 'search input'
                                        },
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <Search />
                                            </InputAdornment>
                                        ),
                                        className: classes.searchBar,
                                    }}
                                    onChange={(ev) => {
                                        this.setState({ searchValue: ev.target.value });
                                    }}
                                    value={this.state.searchValue}

                                    onKeyUp={(event) => {
                                        if (event.key == 'Enter')
                                            this.handleSearch();
                                    }}
                                />
                                <Button
                                    color='primary'
                                    variant='contained'
                                    className={classes.brailleButton}
                                    onClick={() => {
                                        this.handleSearch();
                                    }}>Search</Button>
                            </div>
                            <IconButton onClick={this.closeWizard} className={classes.close}>
                                <Close />
                            </IconButton>
                        </Toolbar>
                        <Table className={classes.tableBookShare}>
                            <TableHead>
                                <TableRow>
                                    {rows.length &&
                                        rows.map(row => {
                                            return (
                                                <TableCell
                                                    key={row.id}
                                                    sortDirection={orderBy === row.id ? order : false}
                                                >
                                                    <Tooltip
                                                        title={<FormattedMessage id='General.sort' />}
                                                        placement='bottom-start'
                                                        enterDelay={300}
                                                    >
                                                        <TableSortLabel
                                                            active={orderBy === row.id}
                                                            direction={order}
                                                            onClick={(ev) => this.handleSort(ev, row.id)}
                                                        >
                                                            {row.label}
                                                        </TableSortLabel>
                                                    </Tooltip>
                                                </TableCell>
                                            )
                                        })
                                    }
                                    <TableCell key='status'>
                                        <FormattedMessage id='Wizard.Braille.import' />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {stableSort(data, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map(_data => {
                                        return (
                                            <TableRow
                                                hover
                                                key={_data.id}
                                            >
                                                {rows.length &&
                                                    rows.map((row, index) => {
                                                        let cellData = _data[row.id] && _data[row.id] !== ' '
                                                            ? _data[row.id]
                                                            : 'N/A';
                                                        return (
                                                            <TableCell
                                                                key={index}
                                                                dangerouslySetInnerHTML={{ __html: cellData }}
                                                            >
                                                            </TableCell>
                                                        )
                                                    })
                                                }
                                                <TableCell align='right'>
                                                    <IconButton onClick={() => this.importSkill(_data)}>
                                                        {
                                                            this.state.imported.includes(_data.id)
                                                                ? <CheckCircle />
                                                                : <CloudDownload />
                                                        }
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                }
                                {
                                    !data.length && this.state.noResultFound &&
                                    <TableRow>
                                        <TableCell colSpan={5} align={"center"}>
                                            <h4>No Results Found</h4>
                                        </TableCell>
                                    </TableRow>
                                }
                                {
                                    this.state.imported.length > 0
                                        ? <TableRow>
                                            <TableCell />
                                            <TableCell />
                                            <TableCell align='right'>
                                                <Button color='primary' variant='contained' onClick={this.closeWizard}>
                                                    <FormattedMessage id='General.done' />
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                        : <TableRow />
                                }
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15]}
                            component='div'
                            count={bookShareList ? bookShareList.length : 0}
                            labelRowsPerPage={<FormattedMessage id='Datatable.rowsPerPage' />}
                            rowsPerPage={this.state.rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                                'aria-label': 'Previous Page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next Page',
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </Paper>
                </Dialog>
                {
                    this.state.openModal &&
                    <LoadingModal openLoading={this.state.openModal} type={this.state.modalType} exitFunc={this.closeModal} />
                }
                {
                    this.state.loginRequired &&
                    <BookShareCredentialsLogin openLoading={this.state.loginRequired} exitFunc={this.closeWizard} />
                }
                <WizardToggleButton
                    openWizard={this.openWizard}
                    text={<FormattedMessage id='Databanks.importBookShareBtn' />}
                />
                <button
                    style={{ textAlign: 'center' }}
                    onClick={() => { this.props.clearBookShareLogin({ username: "Buddy-Luke@noone.com", password: "BuddyBooks" }) }}
                >clear</button>
            </>
        )
    }
}

const mapState = ({ platform: { bookList, bookShareList, downloadBook }, shared: { user } }) => {
    return {
        bookList,
        bookShareList,
        user,
        downloadBook
    }
};

const mapDispatch = (dispatch, { history }) => {
    return {
        getAllBooks: (studentId) => dispatch(getAllBooks(studentId)),
        searchForBooks: (searchTerm, searchField) => dispatch(searchForBooks(searchTerm, searchField)),
        addStoryToStudent: (studentId, bookId) => dispatch(addStoryToStudent(studentId, bookId)),
        clearBookShareLogin: (credentials, courseKey) => dispatch(clearBookShareLogin(credentials, courseKey)),
        downloadBook: (bookId, studentId) => dispatch(downloadBook(bookId, studentId)),
    }
};

export default connect(mapState, mapDispatch)(withStyles(styles)(ImportBook));