import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, FormGroup, Typography, TextField, IconButton, Switch, DialogContentText, Grid } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Select from 'react-select';
import { patchStudent, toggleStudentLogin, clearErrors, checkStudentCount, changeStudentPassword,getStaffForStudent, fetchStaff } from '../../../../redux/actions';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../../styles/wizardBase';
import { FormattedMessage } from 'react-intl';
import { removeProduct, addProduct } from '../../../../redux/actions';

const productOptions = [
  { value: 'Reading', label: 'Reading' },
  { value: 'OM', label: 'O&M' },
  { value: 'TVI', label: 'TVI' },
];
class StudentEdit extends Component {
  state = {
    ...this.props.data,
    login: !!this.props.data.userId && (this.props.data.userId !== 'null'),
    userName: this.props.data.gamerTag || '',
    confirmPassword: '',
    password: '',
    confirm: false,
    agree: false,
    ignoreWarning: false,
    checked: false,
    admin: false,
    studentStaff: [],
    addProduct: null,
    deleteProduct: null
  };

  componentDidMount() {
    this.props.fetchStaff();
    const studentStaff = this.props.studentStaff ? this.props.studentStaff.map(student => ({label: student.name, value: student.id })) : [];
    this.setState({ studentStaff })
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.studentStaff !== nextProps.studentStaff){
      this.setState({ studentStaff: nextProps.studentStaff.map(student => ({ label: student.name, value: student.id }))})
      this.setState({"products": this.generateProduct(this.props.data.products)})
    }
  }

  handleChange = (ev) => {
    this.setState({[ev.target.name]: ev.target.value })
  };

  toggleLogin = () => {
    if ( this.state.login === false ){
      checkStudentCount()
        .then((warn) => {
          this.setState({...warn})
        });
    }
    this.setState(state => ({ login: !state.login, checked: !state.checked }))
  };

  confirm = () => {
    this.setState({ agree: true, confirm: false, ignoreWarning: true })
  };

  closeConfirm = () => {
    this.setState({  agree: false, confirm: false, login: false, ignoreWarning: false  });
  };

  close = () => {
    this.props.close();
    this.setState({  password: '', confirmPassword: '', confirm: false, ignoreWarning: false, agree: false });
    this.props.clearErrors();
  };

  submit = () => {
    const { ignoreWarning, firstName, lastName, userName, password, id } = this.state;
    const student = { firstName, lastName, gamerTag:userName.trim(), id,  userId: this.props.data.userId } ;
    const studentStaff = this.props.staff.filter(student => this.state.studentStaff.some(_student => _student.value === student.id));
    this.handleProductsChange();
    this.props.patchStudent(student, studentStaff)
      .then((res) => {
        if (!this.state.login && this.state.checked) {
          this.props.toggleStudentLogin(this.props.data.id, ignoreWarning, password.trim() );
        }
        else if (this.props.data.userId && !this.state.checked){
          if (password.trim()) this.props.changeStudentPassword(this.props.data.id, password.trim());
        }
        else if (this.props.data.userId && this.state.checked) {
          this.props.toggleStudentLogin(this.props.data.id, ignoreWarning, password.trim());
        }
        if (res && res.error) {
          this.setState({ error: res.error })
        }
        else {
          this.props.close();
        }
      });
    this.props.clearErrors();
  };

  handleProductsChange = () => {
    var oldProducts = this.props.data.products;
    var newProducts = this.state.products.map(p => (p.value));
    if(oldProducts && newProducts) {
      var toAdd = newProducts.filter(e => oldProducts.indexOf(e) < 0);
      var toDelete = oldProducts.filter(e => newProducts.indexOf(e) < 0);
      console.log("toAdd: ", toAdd);
      console.log("toDelete: ", toDelete);
      for (let add of toAdd) {
        this.props.addProduct(this.props.data.id, add);
      }
      for (let del of toDelete) {
        this.props.removeProduct(this.props.data.id, del);
      }
    }
  }

  generateProduct(products){
    let obj=[];
    if(products)
    {
      for(let p of products) {
        var prod = productOptions.filter(o => (o.value == p));
        var label = prod.length == 1 ? prod[0].label : p;
        obj.push({"value": p, "label": label});
      }
    }
    return obj;
  }

  render() {
    const { classes, data, staff, studentStaff } = this.props;
    let products=this.props.user.products && this.props.user.products.split(',');
    let obj=this.generateProduct(products);
    // const error = !!this.state.confirmPassword && (this.state.password !== this.state.confirmPassword);
    const disabled = this.state.login
      ? !this.state.userName
      : this.state.confirm && !this.state.agree && !this.state.userName;
    const needUserName = !this.state.userName && this.state.login;
    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        open={this.props.open}
        onClose={this.props.close}
        aria-labelledby='form-dialog-title'
        PaperProps={{ classes:{ root: classes.container }}}
      >
        <DialogTitle disableTypography={true} className={ classes.title } id='form-dialog-title'>
          <Typography className={ classes.titleText } variant='h4'><FormattedMessage id="General.edit"/> {data.firstName} {data.lastName}</Typography>
          <IconButton
            onClick={this.props.close}
            className={ classes.exitIcon }
            aria-label="close dialog"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormGroup classes={{ root: classes.fullName }}>
            <TextField
              id='student-edit-first-name'
              label={<FormattedMessage id="General.firstName"/>}
              name='firstName'
              type='text'
              variant='outlined'
              value={this.state.firstName}
              onChange={this.handleChange}
            />
            <TextField
              id='student-edit-last-name'
              label={<FormattedMessage id="General.lastName"/>}
              name='lastName'
              type='text'
              variant='outlined'
              value={this.state.lastName}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup>
            <TextField
              classes={{ root: classes.firstName }}
              id='student-edit-userName'
              label='userName'
              type='text'
              name='userName'
              variant='outlined'
              error={needUserName || !!this.state.error}
              helperText={needUserName ? 'You must have a gamer tag to log in!' : this.state.error ? this.state.error : '' }
              value={this.state.userName}
              onChange={(ev) => this.setState({ userName: ev.target.value })}
            />
            {
              this.props.error.type === 'gamerTag' &&
              <Typography color='secondary' variant='subtitle2'> {this.props.error.msg} </Typography>
            }
            <div> {/* additional div container to avoid styling bugs on safari */}
            <div className={classes.login}>
              <Typography variant='h6' className='text'> <FormattedMessage id="Profile.studentLogin"/> </Typography>
              <Switch className='toggle' checked={this.state.login} onChange={this.toggleLogin}/>
            </div>
            </div>
            {
              this.state.login &&
              <div> {/* additional div container to avoid styling bugs on safari */}
              <div className={classes.fullName}>
                <TextField
                  variant='outlined'
                  type='password'
                  label={<FormattedMessage id='Login.password'/>}
                  name='password'
                  error={this.state.passwordError}
                  helperText={this.state.passwordError ? "Password cannot be empty" : ''}
                  value={this.state.password}
                  onChange={(ev) => this.setState({ password: ev.target.value, passwordError: !ev.target.value, confirmPasswordError: ev.target.value !== this.state.confirmPassword })}
                />
                <TextField
                  variant='outlined'
                  type='password'
                  label={<FormattedMessage id='Login.confirmPassword'/>}
                  name='confirmPassword'
                  error={this.state.confirmPasswordError}
                  helperText={this.state.confirmPasswordError ? <FormattedMessage id='Login.mustMatch'/> : ''}
                  value={this.state.confirmPassword}
                  onChange={(ev) => this.setState({ confirmPassword: ev.target.value, confirmPasswordError: ev.target.value !== this.state.password })}
                />
              </div>
              </div>
            }
          </FormGroup>
          <Typography gutterBottom variant='subtitle2'>
            <FormattedMessage id="Wizard.Student.Staff"/>
          </Typography>
          <Select
              isMulti
              id='student-select'
              maxMenuHeight={180}
              styles={{
                control: (styles) => ({...styles, minHeight: 56}),
                multiValueLabel: (styles) => ({
                  ...styles,
                  display: 'grid',
                  fontFamily: 'Roboto',
                  alignContent: 'center',
                  fontSize: '14px',
                  minHeight: 40,
                  fontWeight: 500
                }),
              }}
              menuPlacement='top'
              onChange={(ev) => this.setState({ studentStaff: ev })}
              value={this.state.studentStaff}
              options={staff.map(student => ({label: `${student.firstName} ${student.lastName}`, value: student.id }))}
          />
          <br />
          {products&& products.length>1?
              <FormGroup >
                <Typography gutterBottom variant='h6'> <FormattedMessage id='Wizard.Staff.selectProduct'/>:</Typography>
                <Select
                    styles={{
                      control: (styles) => ({...styles, minHeight: 56}),
                      multiValueLabel: (styles) => ({
                        ...styles,
                        display: 'grid',
                        fontFamily: 'Roboto',
                        alignContent: 'center',
                        fontSize: '14px',
                        minHeight: 40,
                        fontWeight: 500
                      }),
                    }}
                    isMulti
                   value = {this.state.products}
                    menuPlacement='top'
                    maxMenuHeight={180}
                    placeholder='Product'
                    options={obj && obj.map(p => ({ value: p.value, label: p.label }))}
                    onChange={(ev) => this.handleChange({ target: { name: 'products', value: ev }})}
                /></FormGroup>:null}
          <br/>
                  </DialogContent>
        <DialogActions className={classes.actions}>
          <Button onClick={this.props.close} color='secondary'>
            <FormattedMessage id="General.cancel"/>
          </Button>
          <Button onClick={this.submit} disabled={disabled || !this.state.userName ||
          (this.state.login && ( !this.state.password || !this.state.confirmPassword || this.state.password !== this.state.confirmPassword) )
           || (this.state.products.length>0 ? false: true)} color='primary'>
            <FormattedMessage id="General.save"/>
          </Button>
        </DialogActions>
        <Dialog
          open={this.state.confirm}
          onClose={this.closeConfirm}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Confirm Addition to Account</DialogTitle>
          {
            this.state.admin
              ? <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Adding another student to this account will exceed your original billed amount of {this.props.account.studentCount} and you may be billed accordingly.
                  Do you agree to these terms?
                </DialogContentText>
                <div className={classes.confirmButtons}>
                  <Button onClick={this.confirm} color='primary' variant='contained' autoFocus>
                    Yes, increase my billable student count!
                  </Button>
                  <Button onClick={this.closeConfirm} color='secondary'>
                    No, I will continue without login.
                  </Button>
                </div>
              </DialogContent>
              : <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Your account is over the allotted {this.props.account.studentCount} students. Please contact your administrator to add more students.
                </DialogContentText>
                <div className={classes.confirmButtons}>
                  <Button onClick={this.closeConfirm} variant='contained' color='secondary'>
                    Continue without login.
                  </Button>
                </div>
              </DialogContent>
          }
        </Dialog>
      </Dialog>
    )
  }
}

const mapState = ({platform: {studentStaff, staff}, shared: { error, account, user }}) => {
  return {
    error,
    studentStaff,
    account,
    staff,
    user
  }
};

const mapDispatch = (dispatch) => {
  return {
    getStaffForStudent: (id) => dispatch(getStaffForStudent(id)),
    patchStudent: (student, staff) => dispatch(patchStudent(student, staff)),
    toggleStudentLogin: (id, ignoreWarning, password) => dispatch(toggleStudentLogin(id, ignoreWarning, password)),
    changeStudentPassword: (id, password) => dispatch(changeStudentPassword(id, password)),
    clearErrors: () => dispatch(clearErrors()),
    fetchStaff: () => dispatch(fetchStaff()),
    addProduct: (id,product) =>dispatch(addProduct(id,product)),
    removeProduct: (id,product) =>dispatch(removeProduct(id,product)),
  }
};


export default connect(mapState, mapDispatch)(withStyles(baseStyle)(StudentEdit));