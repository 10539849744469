import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Typography,
  Button,
  withStyles,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  TextField,
  IconButton,
  Tooltip,
  Dialog,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText
} from '@material-ui/core/index';
import { Save, Clear, AddCircle, CheckCircle, EditOutlined, EditSharp } from '@material-ui/icons/index';
import {
  checkGamerTag,
  createStudents,
  deleteStudentFromStaff,
  addStudentsToStaff,
  importGoalStudent,
  changeStudentPassword,
  patchStudent
} from '../../../../redux/actions';
import styles from '../../../../styles/registration';
import { FormattedMessage } from 'react-intl';
import RegistrationGoal from './RegistrationGoal';
import uuid from "uuid";

class StudentEnrollmentWizard extends Component {
  state = {
    students: this.props.studentCount || 0,
    enrolled: this.props.enrolled || [],
    goals: [],
    selected: [],
    student: '',
    start: this.props.start || false,
    createGoal: false,
    firstName: '',
    lastName: '',
    defaultTag: true,
    userName: '',
    error: {},
    password: '',
    confirmPassword: '',
    gradeLevel: '',
    enrollStudent: [],
    subscriptionCancel: false,
    editRowPointer: null,
    editRowData: {
      firstname: "",
      lastname: "",
      gamerTag: "",
      password: "",
      confirmPassword: "",
      gradeLevel: "",
      errors: {
        passwordsDifferent: false,
        gamerTagError: false
      }
    }
  };

  componentDidMount() {
    if (this.props.studentCount && this.props.studentCount != this.props.students)
      this.addSubscription();
  }
  start = () => {
    this.setState({ start: true })
  };

  handleChange = (event, student) => {
    let idx = this.state.enrollStudent.find(std => std.id == student.id);
    idx[event.target.name] = event.target.value;
    let updatedStudent = this.state.enrollStudent.map(std => {
      if (std.id == event.target.name) return idx;
      else return std;
    })
    this.setState({ enrollStudent: updatedStudent });
  };

  enroll = async () => {
    let closeDialog = true;
    let array = [];
    for (let i = 0; i < this.state.enrollStudent.length; i++) {
      const { firstName, lastName, userName, confirmPassword, password, gradeLevel } = this.state.enrollStudent[i];
      const tempStudent = this.state.enrollStudent[i];
      let isUserNameUnique = (this.state.enrollStudent[i].error && this.state.enrollStudent[i].error.userNameError ? false : true)
      isUserNameUnique = await this.testGamerTag(tempStudent.userName, tempStudent);
      const student = { firstName, lastName, gamerTag: userName, password: confirmPassword, products: ['Reading'],gradeLevel };
      if (firstName && lastName && userName && password && confirmPassword === password && isUserNameUnique && gradeLevel) {
        array.push(student)
      }
      else {
        const firstNameError = !firstName;
        const lastNameError = !lastName;
        const passwordError = !confirmPassword;
        const userNameError = !isUserNameUnique || tempStudent.userName == '' ? true : false;
        const passwordsDifferent = confirmPassword !== password;
        const gradeLevelError = !gradeLevel
        const error = { userNameError, firstNameError, lastNameError, passwordError, passwordsDifferent,gradeLevelError }
        let updatedStudent = this.state.enrollStudent.map(std => {
          if (std.id == tempStudent.id) {
            std['error'] = error;
            return { ...std }
          }
          else return std;
        });
        this.setState({ enrollStudent: updatedStudent });
        closeDialog = false;
      }
    }
    this.props.createStudents(array,this.props.user.staffId).then(()=>{
      if(closeDialog){
        this.props.close();
      }
    })
  };

  remove = (student) => {
    const newEnrolled = this.state.enrolled.filter(_student => _student.id !== student.id);
    this.setState({ enrolled: newEnrolled, students: this.state.students - 1 });
    this.props.deleteStudentFromStaff(student, this.props.user.staffId)
    this.props.handleDeletedStudent()
  };

  addGoal = (goal, type) => {
    const shared = type === 0;
    const goalDto = shared
      ? { key: goal.key, goalId: goal.goalId }
      : goal;
    this.props.importGoal(this.state.student, goalDto, goal.key, shared);
    this.setState({ goals: [...this.state.goals, this.state.student], createGoal: false })
  };

  testGamerTag = async (value, student, editGamerTag) => {
    let res = await checkGamerTag(-1, value)
      .then(unique => {
        if (!unique && editGamerTag && value != student.gamerTag) {
          let obj = this.state.editRowData;
          obj['error'] = { ...obj['error'], gamerTagError: true }
          this.setState({
            editRowData: obj
          });
        }
        if (!unique) {
          let updatedStudent = this.state.enrollStudent.map(std => {
            if (std.id == student.id) {
              std['error'] = { ...std['error'], userNameError: true }
              return { ...std, }
            }
            else return std;
          });
          this.setState({ enrollStudent: updatedStudent });
          return false;
        }
        else {
          if(editGamerTag){
            let obj = this.state.editRowData;
          obj['error'] = { ...obj['error'], gamerTagError: false }
          this.setState({
            editRowData: obj
          });
          }
          let updatedStudent = this.state.enrollStudent.map(std => {
            if (std.id == student.id) {
              std['error'] = { ...std['error'], userNameError: false }
              return { ...std, }
            }
            else return std;
          });
          this.setState({ enrollStudent: updatedStudent });
          return true;
        }
      })
    return res;
  };

  generateRandomGamerTag = () => {
    const tag = `player${Math.floor(Math.random() * 10000)}`;
    checkGamerTag(-1, tag)
      .then(unique => {
        if (unique) {
          return this.setState({ userName: '' })
        }
        else {
          this.generateRandomGamerTag()
        }
      })
  };

  checkPasswords = (student) => {
    if (student.confirmPassword && (student.password !== student.confirmPassword)) {
      let updatedStudent = this.state.enrollStudent.map(std => {
        if (std.id == student.id) {
          std['error'] = { ...std['error'], passwordsDifferent: true }
          return { ...std, }
        }
        else return std;
      });
      this.setState({ enrollStudent: updatedStudent });
    }
    else {
      let updatedStudent = this.state.enrollStudent.map(std => {
        if (std.id == student.id) {
          std['error'] = { ...std['error'], passwordsDifferent: false }
          return { ...std, }
        }
        else return std;
      });
      this.setState({ enrollStudent: updatedStudent });
    }
  };

  goalDialog = (open, student) => {
    open
      ? this.setState({
        createGoal: open,
        student: student.id
      })
      : this.setState({
        createGoal: open,
        student: ''
      })
  };

  close = () => {
    this.setState({
      enrolled: [],
      goals: {},
      selected: [],
      student: '',
      start: false,
      createGoal: false,
      firstName: '',
      lastName: '',
      userName: '',
      password: '',
      confirmPassword: '',
      error: {},
      gradeLevel:''
    });
    this.props.close()
  };

  clear = () => {
    this.setState({ firstName: '', lastName: '', userName: '' })
  };
  addSubscription = () => {
    this.setState({
      enrollStudent: [...this.state.enrollStudent, {
        id: uuid.v4(),
        firstName: '',
        lastName: '',
        userName: '',
        password: '',
        confirmPassword: '',
        error: {},
        gradeLevel:'' 
      }]
    });
  }
  clearSubscription = (targetStudent) => {
    let newStudentList = this.state.enrollStudent.filter(student => student.id != targetStudent.id);
    this.setState({ enrollStudent: newStudentList });
  }
  edit = (student) => {
    // Make student row editable
    this.setState((prevState, props) => {
      return { ...prevState, editRowPointer: student.id }
    })
    this.setState((prevState, props) => {
      return { ...prevState, editRowData: { firstName: student.firstName, lastName: student.lastName, gamerTag: student.gamerTag } }
    })
  };
  updateEnrolledStudent = (studentToUpdate) => {
    const updatedFields = this.state.editRowData;
    const { firstName, lastName, email, gamerTag, password, confirmPassword } = updatedFields;
    const student = {
      firstName, lastName, email, id: studentToUpdate.id, gamerTag: gamerTag,
      userId: studentToUpdate.userId
    };
    if (password && confirmPassword != password) {
      let obj = updatedFields;
      obj['error'] = { ...obj['error'], passwordsDifferent: true }
      this.setState({
        editRowData: obj
      });
    }
    else if (updatedFields.error && (updatedFields.error.gamerTagError || updatedFields.error.passwordsDifferent)) {
      return;
    }
    else {
      let obj = updatedFields;
      obj['error'] = { ...obj['error'], passwordsDifferent: false, gamerTagError: false }
      this.setState({
        editRowData: obj
      });
      this.props.patchStudent(student).then((res) => {
          this.props.handleUpdatedStudent(student);
        if (password && password == confirmPassword)
          this.props.changeStudentPassword(studentToUpdate.id, password);
        this.props.close();
      });
    }
  }
  handleUpdateEnrolledStudentChange = (e) => {
    this.setState({ ...this.state, editRowData: { ...this.state.editRowData, [e.target.name]: e.target.value } })
  }
  render() {
    const { classes } = this.props;
    const customerType = (this.props.customerType == "Individual") ? true : false;
    return (
      <>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <DialogTitle>Enroll Students</DialogTitle>
        </div>
        <DialogContent>
          <div className={this.state.start ? classes.fullHeight : classes.enroll}>
            <Typography className={classes.enrollTitle} variant='h5'>
              You have enrolled {this.state.students}/{this.props.students} of your available students
            </Typography>
            {
              this.state.enrolled.map((student, index) => (
                <Card key={`${student.gamerTag}-${index}`} className={classes.student}>
                  <div className={classes.studentDetails}>
                    <Typography variant='h6' className={classes.titleLine}><FormattedMessage id='General.firstName' /></Typography>
                    {
                      this.state.editRowPointer === student.id ?
                        <TextField onChange={this.handleUpdateEnrolledStudentChange} name="firstName" variant='outlined' className={classes.studentLine} value={this.state.editRowData.firstName} /> :
                        <Typography variant='subtitle1' className={classes.studentLine}>{student.firstName}</Typography>
                    }
                    <Typography variant='h6' className={classes.titleLine}><FormattedMessage id='General.lastName' /></Typography>
                    {
                      this.state.editRowPointer === student.id ?
                        <TextField onChange={this.handleUpdateEnrolledStudentChange} name="lastName" variant='outlined' className={classes.studentLine} value={this.state.editRowData.lastName} /> :
                        <Typography variant='subtitle1' className={classes.studentLine}>{student.lastName}</Typography>
                    }
                    <Typography variant='h6' className={classes.titleLine}>User Name</Typography>
                    {
                      this.state.editRowPointer === student.id ?
                        <TextField onChange={this.handleUpdateEnrolledStudentChange} name="gamerTag" variant='outlined'
                          className={classes.studentLine} value={this.state.editRowData.gamerTag}
                          error={this.state.editRowData && this.state.editRowData.error && this.state.editRowData.error.gamerTagError}
                          helperText={this.state.editRowData && this.state.editRowData.error && this.state.editRowData.error.gamerTagError ? <FormattedMessage id='Paddle.error.unique' /> : ''}
                          onBlur={(e) => this.testGamerTag(e.target.value, student, true)}
                        /> :
                        <Typography variant='subtitle1' className={classes.studentLine}>{student.gamerTag}</Typography>
                    }
                    <div style={{ marginTop: '5px' }}>
                      {this.state.editRowPointer === student.id ?
                        (<><Typography variant='h6' className={classes.titleLine}>Password</Typography>
                          <TextField onChange={this.handleUpdateEnrolledStudentChange} name="password" type='password' variant='outlined'
                            fullWidth className={classes.studentLine} value={this.state.editRowData.password} /></>) :
                        <Typography variant='subtitle1' className={classes.studentLine}>{student.password}</Typography>
                      }
                    </div>
                    <div style={{ marginTop: '5px' }}>
                      {this.state.editRowPointer === student.id ?
                        (<>
                          <Typography variant='h6' className={classes.titleLine}> Confirm Password</Typography>
                          <TextField onChange={this.handleUpdateEnrolledStudentChange} name="confirmPassword"
                            type='password' variant='outlined' fullWidth className={classes.studentLine}
                            error={this.state.editRowData && this.state.editRowData.error && this.state.editRowData.error.passwordsDifferent}
                            helperText={this.state.editRowData && this.state.editRowData.error && this.state.editRowData.error.passwordsDifferent ? <FormattedMessage id='Paddle.error.match' /> : ''}
                            value={this.state.editRowData.confirmPassword} /></>) : null
                      }
                    </div>
                  </div>
                  <div className={classes.studentBtns}>
                    {
                      this.state.goals.includes(student.id)
                        ? <Tooltip title={<FormattedMessage id='Paddle.goalAdded' />}>
                          <IconButton disabled>
                            <CheckCircle color='primary' />
                          </IconButton>
                        </Tooltip>
                        : customerType == false ? <Tooltip title={<FormattedMessage id='Databanks.addGoalDB' />}>
                          <IconButton
                            color='primary'
                            aria-label='Add a goal to student'
                            onClick={() => this.goalDialog(true, student)}
                          >
                            <AddCircle />
                          </IconButton>
                        </Tooltip> : null
                    }
                    {
                      this.state.editRowPointer === student.id ? <Tooltip title='Update Student'>
                        <IconButton
                          color='secondary'
                          aria-label='Remove student'
                          onClick={() => this.updateEnrolledStudent(student)}
                        >
                          <CheckCircle color='primary' />
                        </IconButton>
                      </Tooltip> : <Tooltip title='Edit Student'>
                        <IconButton
                          color='secondary'
                          aria-label='Edit student'
                          onClick={() => this.edit(student)}
                        >
                          <EditSharp color="primary" />
                        </IconButton>
                      </Tooltip>
                    }
                    <Tooltip title='Remove Student'>
                      <IconButton
                        color='secondary'
                        aria-label='Remove student'
                        onClick={() => this.remove(student)}
                      >
                        <Clear />
                      </IconButton>
                    </Tooltip>
                  </div>
                </Card>
              ))
            }
            {
              this.state.enrollStudent &&
              this.state.enrollStudent.map((stud, idx) => {
                return (
                  <Card className={classes.student} key={stud.id}>
                    <div className={classes.names}>
                      <TextField
                        variant='outlined'
                        name='firstName'
                        value={this.state.enrollStudent[idx].firstName}
                        label={<>Student <FormattedMessage id='General.firstName' /></>}
                        onChange={(event) => this.handleChange(event, stud)}
                        error={this.state.enrollStudent[idx].error.firstNameError}
                        helperText={this.state.enrollStudent[idx].error.firstNameError ? <FormattedMessage id='Paddle.error.first' /> : ''}
                      />
                      <TextField
                        variant='outlined'
                        name='lastName'
                        value={this.state.enrollStudent[idx].lastName}
                        label={<>Student <FormattedMessage id='General.lastName' /></>}
                        onChange={(event) => this.handleChange(event, stud)}
                        error={this.state.enrollStudent[idx].error.firstNameError}
                        helperText={this.state.enrollStudent[idx].error.firstNameError ? <FormattedMessage id='Paddle.error.last' /> : ''}
                      />
                      <TextField
                        type='text'
                        variant='outlined'
                        name='userName'
                        error={this.state.enrollStudent[idx].error.userNameError}
                        helperText={this.state.enrollStudent[idx].error.userNameError ? <FormattedMessage id='Paddle.error.unique' /> : ''}
                        label=' Student User Name'
                        value={this.state.enrollStudent[idx].userName}
                        onBlur={(e) => this.testGamerTag(e.target.value, this.state.enrollStudent[idx])}
                        onChange={(event) => this.handleChange(event, stud)}
                      />
                    </div>
                    <div className={classes.password}>
                      <TextField
                        value={this.state.enrollStudent[idx].password}
                        type='password'
                        autoComplete="new-password"
                        variant='outlined'
                        name='password'
                        label={<>Student <FormattedMessage id='Login.password' /> </>}
                        onChange={(event) => this.handleChange(event, stud)}
                        error={this.state.enrollStudent[idx].error.passwordError}
                        helperText={this.state.enrollStudent[idx].error.passwordError ? <FormattedMessage id='Paddle.error.password' /> : ''}
                      />
                      <TextField
                        value={this.state.enrollStudent[idx].confirmPassword}
                        type='password'
                        variant='outlined'
                        name='confirmPassword'
                        error={this.state.enrollStudent[idx].error.passwordsDifferent || this.state.enrollStudent[idx].error.passwordError}
                        helperText={this.state.enrollStudent[idx].error.passwordsDifferent ? <FormattedMessage id='Paddle.error.match' /> : ''}
                        label={<>Student <FormattedMessage id='Login.confirmPassword' /></>}
                        onChange={(event) => this.handleChange(event, stud)}
                        onBlur={() => this.checkPasswords(this.state.enrollStudent[idx])}
                      />
                      <FormControl required variant='outlined'>
                        <InputLabel error={this.state.enrollStudent[idx].error.gradeLevelError}>Grade level</InputLabel>
                        <Select
                          value={this.state.enrollStudent[idx].gradeLevel}
                          label="Student grade level"
                          name='gradeLevel'
                          onChange={(event) => this.handleChange(event, stud)}
                          error={this.state.enrollStudent[idx].error.gradeLevelError}
                        >
                          <MenuItem value={"k"}>k</MenuItem>
                          <MenuItem value={"1"}>1</MenuItem>
                          <MenuItem value={"2"}>2</MenuItem>
                          <MenuItem value={"3"}>3</MenuItem>
                          <MenuItem value={"4"}>4</MenuItem>
                          <MenuItem value={"5"}>5</MenuItem>
                          <MenuItem value={"6"}>6</MenuItem>
                          <MenuItem value={"7"}>7</MenuItem>
                          <MenuItem value={"8"}>8</MenuItem>
                          <MenuItem value={"9+"}>9+</MenuItem>
                        </Select>
                        <FormHelperText style={{color:"red"}}>{this.state.enrollStudent[idx].error.gradeLevelError ? <FormattedMessage id='addStudent.grade' /> : ''}</FormHelperText>
                      </FormControl>
                    </div>
                    <div className={classes.studentBtns}>
                      <Tooltip title='Clear Student Data'>
                        <IconButton
                          color='secondary'
                          aria-label='Clear Student Data'
                          onClick={() => this.clearSubscription(stud)}
                        >
                          <Clear />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </Card>)
              })
            }
            {
              !this.state.start &&
              <Button
                variant='contained'
                color='primary'
                className={classes.enrollBtn}
                onClick={this.start}
              >
                <FormattedMessage id='Paddle.start' />
              </Button>
            }
          </div>
        </DialogContent>
        <DialogActions>
          <div style={{ left: '-30%', position: 'relative' }}>
            <Button
              onClick={this.addSubscription}
              color='primary'
              disabled={this.props.account && this.state.enrollStudent.length + this.state.students >= this.props.students ? true : false}
            >Add Students</Button>
          </div>
          <Button
            onClick={this.props.close}
            color='secondary'
          >
            <FormattedMessage id='General.cancel' />
          </Button>
          <Button
            onClick={this.enroll}
            color='primary'
            disabled={this.props.students == this.props.studentCount ? true : false}
          >
            <FormattedMessage id='General.done' />
          </Button>
        </DialogActions>
        {/* <RegistrationGoal
          generateRandomGamerTag={this.generateRandomGamerTag}
          addGoal={this.addGoal}
          student={this.state.student}
          selected={this.state.selected}
          goalDialog={this.goalDialog}
          createGoal={this.state.createGoal}
        /> */}
      </>
    );
  }
}

const mapState = ({ shared: { user, account } }) => {
  return {
    user,
    account,
  }
};

const mapDispatch = (dispatch) => {
  return {
    createStudents: (students, staffId) => dispatch(createStudents(students, staffId)),
    addStudentsToStaff: (staffMember, students) => dispatch(addStudentsToStaff(staffMember, students)),
    deleteStudentFromStaff: (student, staffId) => dispatch(deleteStudentFromStaff(student, staffId)),
    importGoal: (studentId, goalDatabank, key, shared) => dispatch(importGoalStudent(studentId, goalDatabank, key, shared)),
    changeStudentPassword: (id, password) => dispatch(changeStudentPassword(id, password)),
    patchStudent: (student, password) => dispatch(patchStudent(student, password)),
  }
};

export default connect(mapState, mapDispatch)(withStyles(styles)(StudentEnrollmentWizard));