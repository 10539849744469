export default {
  header: {
    gridColumn: 1,
    gridRow: 1,
    flex: '0 0 auto',
    fontSize: '24px',
    color: '#fff',
    marginLeft: '20px',
  },
  toolbar: {
    display: 'grid',
    gridTemplateColumns: '4fr 5fr auto',
    gridTemplateRows: '1fr',
    backgroundColor: '#00729b',
    boxShadow: ' 0px 3px 3px #6b6b6b',
  },
  searchBar: {
    color: '#000',
    fontWeight: 600,
    width: '320px',
    marginLeft: '10px',
    backgroundColor: 'white',
    borderRadius: '4px',
    height: 40
  },
  list: {
    borderRadius: '5px',
    width: '90%',
    margin: '20px auto'
  },
  error: {},
  tabs: {
    margin: '0 10px',
  },
  post: {
    padding: '10px 20px',
    margin: 20,
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridRowGap: 20,
    '& .name':{
      gridRow: 1
    },
    '& .description': {
      gridRow: 2
    },
    '& .import': {
      gridRow: '1/ span 4',
      gridColumn: 2,
      alignSelf: 'center'
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.07)',
      cursor: 'pointer'
    }
  },
  grid: {
    gridColumn: 'span 2',
    justifySelf: 'start'
  },
  show: {
    gridColumn: '2',
    alignSelf: 'center',
  },
  comment: {
    display: 'grid',
    marginTop: 10,
    gridColumn: 1,
    gridTemplateColumns: 'auto 1fr',
    '& .icon': {
      color: 'black',
      marginRight: 10,
      paddingRight: 10
    }
  },
  footer: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridColumn: '1',
    alignSelf: 'center',
    justifySelf: 'start',
    borderRadius: 15,
    marginTop: 10,
    padding: '5px 20px'
  },
  pagination: {
    alignSelf: 'end'
  },
  selected: {
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
  },
  actions: {
    gridColumn: 2,
    gridRow: 1,
    justifySelf: 'end',
    marginRight: '20px',
  },
  icon: {
    color: '#fff',
    fontWeight: 800,
  },
}