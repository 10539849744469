import { FETCH_PROFILES, GET_PROFILE } from '../../constants';

export const profile = (state = {}, action) => {
  switch (action.type){
    case GET_PROFILE:
      return action.profile;
    default:
      return state;
  }
};

export const profiles = (state = [], action) => {
  switch (action.type){
    case FETCH_PROFILES:
      return action.profiles;
    default:
      return state;
  }
};