import React from 'react';
import { connect } from 'react-redux';
import WizardToggleButton from "../WizardToggleButton";
import LevelWizardPage1 from './LevelWizardPage1';
import LevelWizardPage2 from './LevelWizardPage2';
import { withStyles } from '@material-ui/core/styles';
import { fetchGames, createLevel } from '../../../../redux/actions';
import styles from '../../../../styles/wizardBase';
import BarnyardLevelTemplate from './BarnyardLevelTemplate';
import BrailleSheetsLevelTemplate from '../SkillDatabank/BrailleQuizSkillDatabank';
import SimonLevelTemplate from './SimonLevelTemplate';
import BopGestureLevelTemplate from './GestureLevelTemplate';
import SoundSearchLevelTemplate from './SoundSearchLevelTemplate';
import AudioMemoryLevelTemplate from './AudioMemoryLevelTemplate';
import { FormattedMessage } from 'react-intl';

class LevelWizardBase extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      open: false,
      page: 1,
      selected: '',
      game: {},
      name: '',
      description: ''
    }
  }

  componentDidMount() {
    this.props.fetchGames();
  }

  openWizard = () => {
    this.setState({ open: true })
  };

  closeWizard = () => {
    this.setState({ open: false, page: 1, selected: '', game: {}, name: '', description: '' });
  };

  next = () => {
    this.setState({ page: this.state.page+1 });
  };

  back = () => {
    this.setState({ game: {}, page: this.state.page > 1 ? this.state.page-1 : 1 });
  };

  goToGame = () => {
    const game = this.props.games.find(game => game.id === this.state.selected);
    this.setState({ game, page: this.state.page+1 });
  };

  handleChange = (ev) => {
    this.setState({ [ev.target.name]: ev.target.value })
  };

  submit = (level) => {
    const { name, description, selected } = this.state;
    this.props.createLevel({...level, name, description, gameId: selected });
    this.closeWizard();
  };

  renderGameScreen = () => {
    const { page, open, selected, game } = this.state;
    const filteredGame = Array.isArray(this.props.games) ? this.props.games.find(game => game.id === selected) : {};
    if (game) {
      switch (game.name) {
        case 'Barn Yard Animals':
          return (
            <BarnyardLevelTemplate
              open={open}
              close={this.closeWizard}
              back={this.back}
              game={game}
              onSubmit={this.submit}
            />
          );
        case 'Braille Sheets':
          return (
            <BrailleSheetsLevelTemplate
              open={open}
              close={this.closeWizard}
              back={this.back}
              game={game}
              onSubmit={this.submit}
            />
          );
        case 'Simon':
          return (
            <SimonLevelTemplate
              open={open}
              close={this.closeWizard}
              back={this.back}
              game={game}
              onSubmit={this.submit}
            />
          );
        case 'Bop Gesture':
          return (
            <BopGestureLevelTemplate
              open={open}
              close={this.closeWizard}
              back={this.back}
              game={game}
              onSubmit={this.submit}
            />
          );
        case 'Sound Search':
          return (
            <SoundSearchLevelTemplate
              open={open}
              close={this.closeWizard}
              back={this.back}
              game={game}
              onSubmit={this.submit}
            />
          );
        case 'Audio Memory':
          return (
            <AudioMemoryLevelTemplate
              open={open}
              close={this.closeWizard}
              back={this.back}
              game={game}
              onSubmit={this.submit}
            />
          );
        default:
          return (
            page === 2 && (
              <LevelWizardPage2
                open={open}
                close={this.closeWizard}
                back={this.back}
                onSubmit={this.submit}
                game={filteredGame}
              />
            )
          );
      }
    }
  };

  render(){
    const { page, open } = this.state;
    return (
      <div>
        {
          page === 1 &&
          <LevelWizardPage1
            open={open}
            close={this.closeWizard}
            next={this.goToGame}
            selected={this.state.selected}
            handleChange={this.handleChange}
            games={this.props.games}
          />
        }
        {
          this.renderGameScreen()
        }
        <WizardToggleButton
          openWizard={this.openWizard}
          text={<FormattedMessage id='Level.addBtn'/>}
        />
      </div>
    )
  }
}

const mapState = ({ platform: { games }}) => {
  return {
    games
  }
};

const mapDispatch = (dispatch, { history }) => {
  return {
    fetchGames: () => dispatch(fetchGames(history)),
    createLevel: (level) => dispatch(createLevel(level))
  }
};

export default connect(mapState, mapDispatch)(withStyles(styles)(LevelWizardBase));