import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';
import Avatar from 'react-avatar';
import { ThumbUpAlt, ThumbUpAltOutlined, CloudDownload, CloudDownloadOutlined } from '@material-ui/icons';
import {
  IconButton,
  Paper,
  Typography,
  Icon,
  withStyles,
  Tooltip,
  Chip
} from '@material-ui/core';
import { toggleBrailleSheetLike } from '../../redux/actions';
import baseStyle from '../../styles/goalDetail';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';

class SheetsDetail extends Component {
  state = {
    liked: this.props.sheet.likes && this.props.sheet.likes.find(like => like.key === this.props.profile.key),
    likeNum: this.props.sheet.likes ? this.props.sheet.likes.length : 0,
    downloaded: false,
    downloadNum: this.props.starNum || 0,
  };

  onDownloadClick = () => {
    const { downloaded, downloadNum } = this.state;
    this.setState({ downloaded: true, downloadNum: downloaded ? downloadNum : downloadNum + 1 })
  };

  onLikeClick = () => {
    const { liked, likeNum } = this.state;
    this.props.toggleBrailleSheetLike(this.props.sheet, this.state.liked);
    this.setState({ liked: !liked, likeNum: liked ? likeNum - 1 : likeNum + 1 })
  };

  // transform method for reactHtmlParser. Will take our tags and make them links
  transform = (node, index) => {
    if (node.type === 'tag' && node.attribs && node.attribs['data-denotation-char'] === "#"){
      return (
        <strong key={node.children[0].data+index} style={{'color': 'rgb(29, 161, 242)', 'textDecoration': 'none'}}>#{node.children[1].children[1].data}</strong>
      )}
    if (node.attribs && node.attribs.contenteditable) {
      node.attribs = {};
      return convertNodeToElement(node, index, this.transform)
    }
  };

  render() {
    const { classes, sheet, selected, group } = this.props;
    const { liked, likeNum, downloaded, downloadNum } = this.state;
    const timeZone = moment.tz.guess();
    const date = moment.unix(sheet.createdDate || '1560451550').tz(timeZone);
    const formattedDate =
      // Check to see if date is within 24 of today. If so, use relative str. If not, use date str
      date.isBetween(moment(), moment().add(1, 'week'))
        ? date.fromNow()
        : `${date.format("MMMM D YYYY")} at ${date.format('h:mm a')}`;
    const author = group.members.find(member => member.key === sheet.authorKey) || { name: 'Objective Ed' };
    const games = this.props.games.filter(game => sheet.games.includes(game.id));
    return (
      <Paper className={classNames(classes.goal, selected === sheet.key ? classes.highlight : '')}>
        <Typography variant='h4'>{sheet.name}</Typography>
        <Typography variant='h6'>{sheet.description}</Typography>
        <div className={classes.comment}>
          <Icon className='fas fa-comments icon'/>
          <Typography variant='subtitle1'>
            {ReactHtmlParser(sheet.comment, { transform: this.transform })}
          </Typography>
        </div>
        <div className={classes.games}>
          <Icon className='fas fa-gamepad icon'/>
          <div className='gameContainer'>
          {
            games.map(game =>
              <Chip className='game' key={game.id} label={game.name}/>
            )
          }
          </div>
        </div>
        <div className={classes.footer}>
          <div className={classes.details}>
            <Avatar round size={50} className='pic' src={author.imgSrc} name={author.name} alt={author.name}/>
            <div className={classes.text}>
              <Typography className='text' variant='h6'>{author.name}</Typography>
              <Typography className='date' variant='caption'>{formattedDate}</Typography>
            </div>
          </div>
          <div className={classes.goalButtons}>
            <IconButton className='icon' onClick={this.onLikeClick}>
              {
                liked
                  ? <Tooltip title='Unlike Sheet'>
                    <ThumbUpAlt className={selected === sheet.key ? '' : classes.liked}/>
                  </Tooltip>
                  : <Tooltip title='Like Sheet'>
                    <ThumbUpAltOutlined />
                  </Tooltip>
              }
            </IconButton>
            <Typography className='text'>{likeNum}</Typography>
            <IconButton className='icon' onClick={this.onDownloadClick}>
              <Tooltip title='Add Sheet to Account'>
                {
                  downloaded
                    ?  <CloudDownload />
                    :  <CloudDownloadOutlined />
                }
              </Tooltip>
            </IconButton>
            <Typography className='text'>{downloadNum}</Typography>
          </div>
        </div>
      </Paper>
    );
  }
}

const mapState = ({ platform: { games }}) => {
  return {
    games
  }
};

const mapDispatch = (dispatch) => {
  return {
    toggleBrailleSheetLike: (sheet, liked) => dispatch(toggleBrailleSheetLike(sheet, liked))
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(SheetsDetail));