import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, FormGroup, TextField, Typography } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../../styles/wizardBase';

class StaffWizardPage1 extends Component {
  constructor(props){
    super(props);
    this.state = {
      checked: false
    }
  }

  close = () => {
    this.props.close();
  };

  next = () => {
    this.props.next();
  };

  render()  {
    const { classes } = this.props;
    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        open={this.props.open}
        onClose={this.props.close}
        aria-labelledby="form-dialog-title"
        PaperProps={{ classes:{ root: classes.container }}}
      >
        <DialogTitle disableTypography={true} className={ classes.title } id="form-dialog-title">
          <Typography className={ classes.titleText } variant='h4'><FormattedMessage id='Wizard.Staff.title'/></Typography>
          <IconButton
            onClick={this.close}
            className={ classes.exitIcon }
            aria-label="close dialog"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialog}>
          <DialogContentText>
            <FormattedMessage id='Wizard.Staff'/>
          </DialogContentText>
          <FormGroup classes={{ root: classes.root }}>
            <Typography gutterBottom variant='h6'><FormattedMessage id='Wizard.Staff.email'/>:</Typography>
            <TextField
              variant='outlined'
              onChange={this.props.handleChange}
              classes={{ root: classes.firstName }}
              id='user-email'
              type='email'
              name='email'
              error={!!this.props.error}
              helperText={this.props.error}
              placeholder='staff.member@example.com'
            />
          </FormGroup>
        </DialogContent>
        <DialogActions className={classes.buttons}>
          <Button onClick={this.close} color="secondary">
            <FormattedMessage id='General.cancel'/>
          </Button>
          <Button onClick={this.next} color="primary">
            <FormattedMessage id='General.continue'/>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(baseStyle)(StaffWizardPage1);