export default {
  goalSheetsTable: {
    marginTop: 20,
    borderRadius: '5px',
    overflowX: 'auto',
  },
  header: {
    gridColumn: 1,
    gridRow: 1,
    flex: '0 0 auto',
    fontSize: '24px',
    color: '#fff',
    marginLeft: '20px',
  },
  toolbar: {
    display: 'grid',
    gridTemplateColumns: '4fr 6fr',
    gridTemplateRows: '1fr',
    borderRadius: '5px 5px 0 0',
    backgroundColor: '#00729b',
    boxShadow: ' 0px 3px 3px #6b6b6b',
  },
  actions: {
    gridColumn: 2,
    gridRow: 1,
    justifySelf: 'end',
    marginRight: '20px',
  },
  searchBar: {
    color: '#000',
    fontWeight: 600,
    width: '320px',
    marginLeft: '10px',
    backgroundColor: 'white',
    borderRadius: '4px',
    height: 40
  },
  empty: {
    textAlign: 'center'
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  sheet: {
    display: 'grid',
    gridTemplateColumns: '3fr 6fr 2fr 2fr 1fr',
    gridColumnGap: 10,
    margin: [[ 10, 5 ]],
    padding: [[ 5, 20 ]],
    '& .name': {
      gridColumn: 1,
      alignSelf: 'center'
    },
    '& .description': {
      gridColumn: 2,
      alignSelf: 'center',
      justifySelf: 'center'
    },
    '& .code': {
      gridColumn: 3,
      alignSelf: 'center',
      justifySelf: 'center'
    },
    '& .download': {
      gridColumn: 4,
      alignSelf: 'center',
      justifySelf: 'center'
    },
    '& .show': {
      gridColumn: 5,
      alignSelf: 'center',
      justifySelf: 'center'
    }
  },
  grid: {
    marginTop: 10,
    alignContent: 'center',
    gridColumn: '1 / 3'
  }
}