import { Button, Card, CardContent, Divider, IconButton, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ArrowBack, Print } from '@material-ui/icons';
import { Line } from '@nivo/line';
import classNames from 'classnames';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Logo from '../../../assets/BrandAssets/Logo/OBJECTIVEED-Logo-Vertical-1.png';
import { getAccuracy, getReviewData, getStudent, getWPMBooks, getWordsPerMinute } from '../../../redux/actions';
import baseStyle from '../../../styles/dashboard';
import DataTable from '../../components/Shared/DataTable';
import PhonemesReview from '../../components/Shared/ReviewModal';
import moment from 'moment';

const rows = [
    { id: 'text', label: <FormattedMessage id='BuddyBooks.text' /> },
    { id: 'firstTime', label: <FormattedMessage id='BuddyBooks.firstTime' /> },
    { id: 'firstScore', label: <FormattedMessage id={'BuddyBooks.firstScore'} /> },
    { id: 'secondTime', label: <FormattedMessage id={'BuddyBooks.secondTime'} /> },
    { id: 'secondScore', label: <FormattedMessage id={'BuddyBooks.secondScore'} /> }];

    const rows2 = [
        { id: 'titleImageSrc', label: "" },
        { id: 'title', label: <FormattedMessage id='BuddyBooks.title' /> },
        { id: 'author', label: <FormattedMessage id='BuddyBooks.author' /> },
        { id: 'date', label: <FormattedMessage id='BuddyBooks.firstRead' /> },
        { id: 'gradeLevel', label: <FormattedMessage id={'BuddyBooks.gradeLevel'} /> }
    ]

const pageBreakStyle = {
    pageBreakBefore: 'always',
    pageBreakInside: 'avoid'
}

const customTheme = {
    axis: {
        ticks: {
            text: { fontSize: 16, fontWeight: 800 }
        }
    },
    grid: {
        line: {
          stroke: "black",
          strokeWidth: 0.5,
        }
      }
}
const CollisionLink = (url) => React.forwardRef((props, ref) => (
    <Link innerRef={ref} to={url} {...props} />
));

const percentage = (value) => {
    return `${value}%`;
}

class ReadingProgressReport extends Component {

    state = {
        accuracy: [],
        wordsPerMinute: [],
        reviewData: [],
        bookInfoData: [],
    };

    componentDidMount() {

        this.props.getStudent(this.props.id);
        getAccuracy(this.props.id).then(data => {
            if (data) {
                this.setState({ accuracy: data });
            }
        });
        getWordsPerMinute(this.props.id).then(data => {
            if (data) {
                let dataAltered = data.map(d => {
                    return { "x": d.date, "y": d.wordsPerMinute > 200 ? 200 : d.wordsPerMinute.toFixed(0) , "bookId": d.bookId };
                })
                this.setState({ wordsPerMinute: dataAltered });
            }
        });
        getReviewData(this.props.id).then(data => {
            if (data) {
                console.log('getReview dashboard response', data);
                let dataAltered = data.map(d => {
                    var firstDate = new Date(d.firstTime);
                    var secondDate = new Date(d.secondTime);
                    var firstAnalysis = JSON.parse(d.firstAnalysis);
                    var secondAnalysis = JSON.parse(d.secondAnalysis);
                    return {
                        "text": d.text,
                        "firstTime": firstDate.toLocaleDateString("en-US"),
                        "firstScore": d.firstScore.toFixed(0),
                        "firstAnalysis": firstAnalysis,
                        "firstFileId": d.firstFileId,
                        "secondTime": secondDate.toLocaleDateString("en-US"),
                        "secondScore": d.secondScore.toFixed(0),
                        "secondAnalysis": secondAnalysis,
                        "secondFileId": d.secondFileId,
                    };
                });
                this.setState({ reviewData: dataAltered });
            }
        })
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.wordsPerMinute !== this.state.wordsPerMinute) {
          if (this.state.wordsPerMinute.length > 0) {
            let uniqueBookId = new Set();
            let bookListWPM = [];
            let bookId = "";
            let bookDetail = {};
            let bookInfoData = [];
            this.state.wordsPerMinute.map((record) => uniqueBookId.add(record.bookId));
            getWPMBooks(this.props.id, [...uniqueBookId]).then((res) => {
              bookListWPM = res;
              this.state.wordsPerMinute.map((val) => {
                bookId = val.bookId;
                bookDetail = this.props.books.filter((book) => book.id === bookId);
                let bookData = bookListWPM.filter((book) => book.id === bookId);
                bookDetail = bookDetail.length === 0 ? bookData : bookDetail;
                if (bookDetail.length > 0 && bookDetail[0].name) {
                  bookInfoData.push({
                    titleImageSrc: bookDetail[0].titleImageSrc,
                    title: bookDetail[0].name,
                    author: bookDetail[0].author,
                    date: val.x,
                    gradeLevel: bookDetail[0].gradeLevel,
                  });
                }
              });
              const recentBooks = this.getRecentBooks(bookInfoData);
              this.setState({ bookInfoData: recentBooks });
            });
          }
        }
      }

getRecentBooks(books) {
    const sortedBooks = books.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  
    const uniqueBooks = [];
    const uniqueBookTitles = new Set();
  
    for (let i = 0; i < sortedBooks.length; i++) {
      const book = sortedBooks[i];
      if (!uniqueBookTitles.has(book.title)) {
        uniqueBookTitles.add(book.title);
        uniqueBooks.push(book);
      }
      if (uniqueBooks.length === 5) {
        break;
      }
    }
    return uniqueBooks;
  }
      
    TitleWord = () => {
        const style = { fontWeight: 'bold' }
        return (
            <text
                x="44%" dominantBaseline="middle" textAnchor="middle"
                y={-15}
                style={style}
            > Words Correct Per Minute
            </text>
        )
    };
    tooltip = (slice, label) => {
        const options = { weekday: 'long', month: 'numeric', day: 'numeric' };
        return (
            <div style={{
                background: 'white',
                padding: '9px 12px',
                border: '1px solid #ccc',
            }}>
                <div><strong>{slice.slice.points[0].data.x.toLocaleDateString('en-US', options)}</strong></div>
                <div>
                    {
                        label === 'Accuracy'
                            ? <>Accuracy: <strong>{Math.round(slice.slice.points[0].data.y)}%</strong></>
                            : <>{label}: <strong>{slice.slice.points[0].data.y >= 200 ? "200+" : Math.round(slice.slice.points[0].data.y.toFixed(0))}</strong></>
                    }
                </div>
            </div>
        )
    };
    printPage = () => {
        return (
            <Button onClick={window.print}>Print</Button>
        );
    }

    calculateMinuteTickValue = () => {
        let startMinuteRecord = this.state.wordsPerMinute && this.state.wordsPerMinute[0] && this.state.wordsPerMinute[0].x;
        let endMinuteRecord = this.state.wordsPerMinute && this.state.wordsPerMinute[this.state.wordsPerMinute.length - 1] && this.state.wordsPerMinute[this.state.wordsPerMinute.length - 1].x;
        startMinuteRecord = moment(startMinuteRecord);
        endMinuteRecord = moment(endMinuteRecord);
        return endMinuteRecord.diff(startMinuteRecord, 'days')
    }
    calculateAccuracyTickValue = () => {
        let startAccuracyRecord = this.state.accuracy && this.state.accuracy[0] && this.state.accuracy[0].x;
        let endAccuracyRecord = this.state.accuracy && this.state.accuracy[this.state.accuracy.length - 1] && this.state.accuracy[this.state.accuracy.length - 1].x;
        startAccuracyRecord = moment(startAccuracyRecord);
        endAccuracyRecord = moment(endAccuracyRecord);
        return endAccuracyRecord.diff(startAccuracyRecord, 'days');   
    }

    render() {
        const { classes, student } = this.props;
        const TitleAcc = () => {
            const style = { fontWeight: 'bold' }

            return (
                <text
                    x="44%" dominantBaseline="middle" textAnchor="middle"
                    y={-15}
                    style={style}
                > Total Accuracy
                </text>
            )
        };

        let specificTickValueAcc = this.state.accuracy.length > 8 ? 5 : 'every day';
        let specificTickValue = this.state.wordsPerMinute.length > 8 ? 5 : 'every day';
        let diffMinuteRecord = this.calculateMinuteTickValue();
        let diffAccuracyRecord = this.calculateAccuracyTickValue();

        if (diffMinuteRecord >= 14) {
            specificTickValue = 7;
        }
        if (diffAccuracyRecord >= 14) {
            specificTickValueAcc = 7;
        }
        const getColors = (chart) => {
            if (chart.id == 'Words Correct Per Minute')
                return "#0087b6";
            else if (chart.id == 'Under50')
                return "rgba(255, 0, 0,0.1)";
            else if (chart.id == 'Under150')
                return "rgba(255, 255, 0,0.3)";
            else
                return 'rgba(0, 255, 0,0.15)'
        }
        let under50Data = {};
        let under150Data = {};
        let under200Data = {};
        let bookChange = [];
        if (this.state.wordsPerMinute && this.state.wordsPerMinute.length > 0) {
            let length = this.state.wordsPerMinute.length;
            let bookChangePoints = [];
            for (let i = 1; i < length; i++) {
                if (this.state.wordsPerMinute[i].bookId !== this.state.wordsPerMinute[i - 1].bookId) {
                    bookChangePoints.push(
                        {
                            axis: 'x',
                            value: new Date(this.state.wordsPerMinute[i].x),
                            lineStyle: {
                                stroke: 'black',
                                strokeWidth: 1.5,
                                opacity: 0.8,
                            },
                        })
                }
            }
            under50Data = {
                "id": 'Challenge', color: 'hsl(301, 70%, 50%)',
                "data": [{ x: this.state.wordsPerMinute[0].x, y: '50' },
                { x: this.state.wordsPerMinute[length - 1].x, y: '50' }]
            };
            under150Data = {
                "id": 'Instructional', color: 'hsl(1, 83%, 69%)',
                "data": [{ x: this.state.wordsPerMinute[0].x, y: '150' },
                { x: this.state.wordsPerMinute[length - 1].x, y: '150' }]
            };
            under200Data = {
                "id": 'Fluent', color: 'green',
                "data": [{ x: this.state.wordsPerMinute[0].x, y: '200' },
                { x: this.state.wordsPerMinute[length - 1].x, y: '200' }]
            };
            bookChange.push(...bookChangePoints);
        }
        const dataSourceWPM = this.state.wordsPerMinute.length > 0 ? [under50Data, under150Data, under200Data,
            { "id": "Words Correct Per Minute", "data": this.state.wordsPerMinute }]
            : [{ "id": "Words Correct Per Minute", "data": this.state.wordsPerMinute }];

        return (
            <div className={classes.progressReportContainer}>
                <div style={{ display: "flex", width: "100%" }}>
                    <div style={{
                        position: "absolute",
                        right: "0px",
                        top: "0px"
                    }}>
                        <IconButton
                            aria-label='home'
                            color='inherit'
                            component={CollisionLink('/students/' + this.props.id + '/dashboard')}
                        >
                            <ArrowBack />
                        </IconButton>
                        <IconButton
                            aria-label='home'
                            color='inherit'
                            onClick={() => window.print()}
                        > <Print />
                        </IconButton>
                    </div>
                    <div style={{ marginLeft: "auto" }}>
                        <img style={{ marginLeft: "auto" }} className={classNames(classes.logo, { [classes.hidden]: false })} alt='logo' src={Logo} />
                    </div>
                </div>

                <Typography variant='h5'>
                    <FormattedMessage id='Progress.Title' />
                </Typography>
                <Typography variant='h5'>
                    <FormattedMessage id='Progress.Student' /> {student.firstName} {student.lastName}
                </Typography>
                <br />
                <Divider />
                <br />
                {this.state.accuracy &&
                    <div className={classes.chart}>
                        <Typography variant='h5'>Total Accuracy</Typography>
                        <br />
                        <div>
                            <Line
                                data={[{ "id": "Accuracy", "data": this.state.accuracy }]}
                                enableSlices={"x"}
                                layers={['grid', 'markers', 'axes', 'areas', 'crosshair', 'lines', 'points', 'slices', 'mesh', 'legends', TitleAcc]}
                                margin={{
                                    top: 30,
                                    right: 80,
                                    bottom: 60,
                                    left: 60
                                }}
                                sliceTooltip={(slice) => this.tooltip(slice, "Accuracy")}
                                height={330}
                                width={1000}
                                xScale={{
                                    type: 'time',
                                    format: '%Y-%m-%d',
                                    precision: 'day',
                                    useUTC: false
                                }}
                                yScale={{
                                    type: 'linear',
                                    stacked: false,
                                    min: 0,
                                    max: 100
                                }}
                                axisTop={null}
                                axisRight={null}
                                axisBottom={{
                                    tickValues: specificTickValueAcc,
                                    orient: 'bottom',
                                    tickSize: 10,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    format: '%-m/%-d'
                                }}
                                axisLeft={{
                                    tickValues: [0,25,50,75,100],
                                    orient: 'left',
                                    tickSize: 0,
                                    tickPadding: 15,
                                    tickRotation: 0,
                                    format: percentage
                                }}
                                colors={['#0087b6']}
                                dotSize={10}
                                dotColor='inherit:darker(0.3)'
                                dotBorderWidth={1}
                                dotBorderColor='inherit:darker(1)'
                                animate={true}
                                motionStiffness={90}
                                motionDamping={15}
                                theme={customTheme}
                            />
                        </div>
                    </div>
                }
                {this.state.wordsPerMinute &&
                    <div className={classes.chart} style={{breakInside:'avoid'}}>
                        <Typography variant='h5'>Words Correct Per Minute </Typography>
                        <br />
                        <div>
                            <Line
                                legends={[
                                    {
                                      anchor: "bottom",
                                      data: dataSourceWPM.slice(0, 3).map((item) => {
                                        const color = item.id === "Fluent" ? "#D1E8D1" : item.id === "Instructional" ? "#DCEE9F" : "#E4B579"
                                        return {
                                          color: color,
                                          id: item.id,
                                          label: item.id
                                        };
                                      }),
                                      direction: "row",
                                      itemHeight: 24,
                                      itemWidth: 120,
                                      translateY: 60
                                    }
                                  ]}
                                  data={dataSourceWPM}
                                enableSlices={"x"}
                                layers={['grid', 'markers', 'axes', 'areas', 'crosshair', 'lines', 'points', 'slices', 'mesh', 'legends', this.TitleWord]}
                                margin={{
                                    top: 30,
                                    right: 80,
                                    bottom: 60,
                                    left: 60
                                }}
                                sliceTooltip={(slice) => this.tooltip(slice, "Words Per Minute")}
                                height={330}
                                width={1000}
                                xScale={{
                                    type: 'time',
                                    format: '%Y-%m-%d',
                                    precision: 'day',
                                    useUTC: false
                                }}
                                yScale={{
                                    type: 'linear',
                                    stacked: false,
                                    min: 0,
                                    max: 200
                                }}
                                axisTop={null}
                                axisRight={null}
                                axisBottom={{
                                    tickValues: specificTickValue,
                                    orient: 'bottom',
                                    tickSize: 10,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    format: '%-m/%-d'
                                }}
                                colors={getColors}
                                dotSize={10}
                                dotColor='inherit:darker(0.3)'
                                dotBorderWidth={1}
                                dotBorderColor='inherit:darker(1)'
                                animate={true}
                                motionStiffness={90}
                                motionDamping={15}
                                theme={customTheme}
                                defs={[{
                                    id: 'gradientA',
                                    type: 'linearGradient',
                                    colors: [
                                        { color: 'red', opacity: 0.4 },
                                    ],
                                },
                                {
                                    id: 'gradientB',
                                    type: 'linearGradient',
                                    colors: [
                                        { color: 'yellow', opacity: 0.4 },
                                    ],
                                },
                                {
                                    id: 'gradientC',
                                    type: 'linearGradient',
                                    colors: [
                                        { color: 'green', opacity: 0.3 },
                                    ],
                                }, {
                                    id: 'gradientD',
                                    type: 'linearGradient',
                                    colors: [
                                        { color: 'white', opacity: 0 },
                                    ],
                                }]}
                                fill={[
                                    { match: d => d.id === 'Challenge', id: 'gradientA' },
                                    { match: d => d.id === 'Instructional', id: 'gradientB' },
                                    { match: d => d.id === 'Fluent', id: 'gradientC' },
                                    { match: '*', id: 'gradientD' },
                                ]}
                                enableArea={true}
                                areaOpacity={0.6}
                                markers={[...bookChange]}
                            />
                        </div>
                    </div>
                }
                {
                    this.state.bookInfoData && 
                            <div style={{marginBottom:'4rem', breakBefore:'page'}}>
                        <Typography variant='h5'>Recent Books
                        </Typography> <br />
                        <Divider />
                                <DataTable
                                    type="RecentBooks"
                                    history={this.props.history}
                                    rows={rows2}
                                    rowsPerPage={[5, 10, 15]}
                                    noActions={true}
                                    noSearch={true}
                                    noPagination={true}
                                    data={this.state.bookInfoData}
                                    numCols={5}
                                    progressReport={true}
                                    title={<FormattedMessage id='BuddyBooks.recentBooks' />}
                                />
                            </div>
                }
                {this.state.reviewData &&
                    <div style={pageBreakStyle}>
                        <Typography variant='h5'>Review Data
                        </Typography> <br />
                        <Divider />
                        <div>
                            {/* <DataTable
                                type="ReviewMode"
                                history={this.props.history}
                                rows={rows}
                                rowsPerPage={[5, 10, 15]}
                                orderBy={'score'}
                                noActions={true}
                                noSearch={true}
                                noPagination={false}
                                data={this.state.reviewData}
                                numCols={5}
                                clickable={false}
                                title={<FormattedMessage id='BuddyBooks.ReviewMode' />}
                                progressReport={true}
                            /> */}
                            {this.state.reviewData.map((val,i)=>{
                                if(i<=4){
                                    return<Card style={{marginTop:16, breakInside:'avoid'}}>
                                        <CardContent>
                                            <PhonemesReview 
                                            type="progressReport"
                                            data={val}
                                            studentId={this.props.id}/>
                                        </CardContent>
                                        </Card> 
                                    }
                                })
                            }
                        </div>
                        <br /><br />
                    </div>
                }
            </div>
        )
    }
}

const mapState = ({ platform: { student, books } }) => {
    return {
        student,
        books
    }
};

const mapDispatch = (dispatch) => {
    return {
        getStudent: (id) => dispatch(getStudent(id))
    }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(ReadingProgressReport));