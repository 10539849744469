import {
  FETCH_ALL_STAFF_MEMBERS,
  CREATE_STAFF_MEMBER,
  PATCH_STAFF_MEMBER,
  GET_STAFF_MEMBER,
  ADD_STAFF_STUDENT,
  RESET,
  GET_STAFF_STUDENTS,
  DELETE_STUDENT_FROM_STAFF, DELETE_STAFF_MEMBER, GET_STAFF_PROFILE, GET_STAFF_ROLES,
  GET_STAFF_DASHBOARD,
  NO_STAFF_STUDENT_RECORD_FOUND,
  RESET_STAFF_DASHBOARD,
  GET_STAFF_DASHBOARD_ACCURACY,
  GET_STAFF_DASHBOARD_READING_TIME
} from '../../constants';

export const staff = (state = [], action) => {
  switch(action.type){
    case FETCH_ALL_STAFF_MEMBERS:
      return action.staff;
    case CREATE_STAFF_MEMBER:
      return [...state, action.staffMember];
    case PATCH_STAFF_MEMBER:
      return state.map(staffMember => staffMember.id === action.staffMember.id ? action.staffMember : staffMember );
    case DELETE_STAFF_MEMBER:
      return state.filter(staffMember => staffMember.id !== action.staffMember.id);
    default:
      return state;
  }
};

export const staffMember =  (state = {}, action) => {
  switch(action.type){
    case GET_STAFF_MEMBER:
      return action.staffMember;
    case RESET:
      return {};
    default:
      return state;
  }
};

export const staffStudents = (state = [], action) => {
  switch(action.type){
    case GET_STAFF_STUDENTS:
      return action.students;
    case ADD_STAFF_STUDENT:
      return [...state, ...action.students];
    case DELETE_STUDENT_FROM_STAFF:
      return state.filter(student => student.id !== action.student.id);
    default:
      return state;
  }
};


export const staffRoles = (state = [], action) => {
  switch(action.type){
    case GET_STAFF_ROLES:
      return action.staffRoles;
    default:
      return state;
  }
};

export const staffProfile = (state = {}, action) => {
  switch(action.type){
    case GET_STAFF_PROFILE:
      return action.staffProfile;
    default:
      return state;
  }
};
export const staffDashboard = (state = {}, action) => {
  switch (action.type) {
    case GET_STAFF_DASHBOARD:
      return action.staffDashboard;
    case NO_STAFF_STUDENT_RECORD_FOUND:
      return action.staffDashboard;
    case RESET_STAFF_DASHBOARD:
      return {};
    default:
      return state;
  }
};
export const staffDashboardAccuracy = (state = {}, action) => {
  switch (action.type) {
    case GET_STAFF_DASHBOARD_ACCURACY:
      return action.data;
    default:
      return state;
  }
};

export const staffDashboardReadingTime = (state = {}, action) => {
  switch (action.type) {
    case GET_STAFF_DASHBOARD_READING_TIME:
      return action.data;
    default:
      return state;
  }
};