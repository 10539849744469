import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, FormGroup, Typography, IconButton, TextField } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { patchBrailleSheet } from '../../../../redux/actions';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../../styles/wizardBase';
import BrailleSheetWizardGrid from './BrailleSheetWizardGrid';
import { FormattedMessage } from 'react-intl';

class BrailleSheetsEditWizard extends Component {

  state={
    ...this.props.data
  };

  close = () => {
    this.props.close();
  };

  handleSubmit = () => {
    const { patchBrailleSheet, account, close } = this.props;
    patchBrailleSheet(this.state, account);
    close();
  };

  render()  {
    const { classes, data } = this.props;
    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        open={this.props.open}
        onClose={this.props.close}
        aria-labelledby='form-dialog-title'
        scroll='paper'
        PaperProps={{classes:{ root: classes.container }}}
      >
        <DialogTitle disableTypography={true} className={classes.title} id='form-dialog-title'>
          <Typography className={classes.titleText} variant='h4'> {data.name} </Typography>
          <IconButton
            onClick={this.close}
            className={ classes.exitIcon }
            aria-label="close dialog"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormGroup classes={{root: classes.root}}>
            <Typography gutterBottom variant='h6'> ID: </Typography>
            <TextField
              disabled
              classes={{root: classes.firstName}}
              variant='outlined'
              id='braille-sheets-name'
              type='id'
              name='id'
              value={this.state.id}
            />
            <Typography gutterBottom variant='h6'> <FormattedMessage id='General.name'/>: </Typography>
            <TextField
              classes={{root: classes.firstName}}
              variant='outlined'
              id='braille-sheets-name'
              type='name'
              name='name'
              value={this.state.name}
              onChange={(ev) => this.setState({ name: ev.target.value })}
            />
            <Typography gutterBottom variant='h6'> <FormattedMessage id='General.description'/>:  </Typography>
            <TextField
              multiline
              classes={{root: classes.firstName}}
              variant='outlined'
              id='braille-sheets-description'
              type='description'
              name='description'
              value={this.state.description}
              onChange={(ev) => this.setState({ description: ev.target.value })}
            />
            <Typography gutterBottom variant='h6'> <FormattedMessage id='General.area'/>:  </Typography>
            <TextField
              classes={{root: classes.firstName}}
              variant='outlined'
              id='braille-sheets-area'
              type='area'
              name='area'
              value={this.state.area}
              onChange={(ev) => this.setState({ area: ev.target.value })}
            />
            <Typography gutterBottom variant='h6'> <FormattedMessage id='General.games'/>:  </Typography>
            <TextField
              disabled
              classes={{root: classes.firstName}}
              variant='outlined'
              id='braille-sheets-area'
              type='area'
              name='area'
              value={this.props.data.games.map(game => game.name.split(', '))}
            />
            { data.grid &&
              <>
                <Typography gutterBottom variant='h6'> <FormattedMessage id='Wizard.Braille.sheet'/>:  </Typography>
                <BrailleSheetWizardGrid sheet={data} grid={JSON.parse(data.grid)}/>
              </>
            }
          </FormGroup>
        </DialogContent>
        <DialogActions className={classes.buttons}>
          <Button onClick={this.close} color='secondary'>
            <FormattedMessage id='General.cancel'/>
          </Button>
          <Button onClick={this.handleSubmit} variant='contained' color='primary'>
            <FormattedMessage id='General.submit'/>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapState = ({ shared: { account }}) => {
  return {
    account
  }
};

const mapDispatch = (dispatch) => {
  return {
    patchBrailleSheet: (sheet, account) => dispatch(patchBrailleSheet(sheet, account))
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(BrailleSheetsEditWizard));