import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Avatar from 'react-avatar';
import { Button, Paper, Typography, withStyles } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import { joinGroup } from '../../redux/actions';
import baseStyle from '../../styles/goalDetail';
import { FormattedMessage } from 'react-intl';

const CollisionLink = (id) => React.forwardRef((props, ref) => (
  <Link innerRef={ref} to={`/groups/${id}`} {...props} />
));

class GroupListItem extends Component {

  state = {
    joined: false
  };

  componentDidMount() {
    if (this.props.myGroups.some(group => group.key === this.props.group.key)){
      this.setState({ joined: true })
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.myGroups.some(group => group.key === nextProps.group.key)){
      this.setState({ joined: true })
    }
  }

  joinGroup = () => {
    const { group, profile } = this.props;
    this.props.joinGroup(group, profile.key);
    this.setState({ joined: true })
  };

  render() {
    const { classes, group } = this.props;
    return (
      <Paper className={classes.goal}>
        <img width='150px' className={classes.avatar} src={group.imgSrc} alt={group.name}/>
        <div className={classes.info}>
          <Typography
            component={CollisionLink(group.key)}
            aria-label={`click to go to ${group.name}`}
            variant='h4'
          >
            {group.name}
          </Typography>
          <Typography variant='h6'>{group.description}</Typography>
          <div className={classes.footer}>
            <div className={classes.details}>
              <Typography variant='subtitle1' className='text'><FormattedMessage id='Group.created'/>: </Typography>
              <Avatar size='30px' className='pic' round src={group.creator && group.creator.imgSrc} name={group.creator || "Objective Ed"} alt={group.creator}/>
              <Typography className='text' variant='subtitle1'>{group.creator || "Objective Ed"}</Typography>
            </div>
          </div>
        </div>
        {
          this.state.joined
            ? <CheckCircle className={classes.joined}/>
            : <Button
                variant='contained'
                aria-label={`join ${group}`}
                className={classes.joinBtn}
                onClick={this.joinGroup}
              >
              <FormattedMessage id='General.join'/>
              </Button>
        }
      </Paper>
    );
  }
}

const mapState = ({ social: { profile, myGroups }}) => {
  return {
    profile,
    myGroups
  }
};

const mapDispatch = (dispatch) => {
  return {
    joinGroup: (group, profileKey) => dispatch(joinGroup(group, profileKey))
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(GroupListItem));