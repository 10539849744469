import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  FormGroup,
  Typography,
  TextField,
  IconButton,
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { updateGroup } from '../../redux/actions';
import baseStyle from '../../styles/wizardBase';
import { FormattedMessage } from 'react-intl';

class GroupEditWizard extends Component {
  state = {
    ...this.props.group,
  };

  handleChange = (ev) => {
    this.setState({[ev.target.name]: ev.target.value })
  };

  togglePrivate = () => {
    this.setState(state => ({ isPrivate: !state.isPrivate }))
  };

  submit = () => {
    this.props.updateGroup(this.state);
    this.props.close();
  };

  render()  {
    const { classes, group } = this.props;
    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        open={this.props.open}
        onClose={this.props.close}
        aria-labelledby='form-dialog-title'
        PaperProps={{ classes:{ root: classes.container }}}
      >
        <DialogTitle disableTypography={true} className={ classes.title } id='form-dialog-title'>
          <Typography className={ classes.titleText } variant='h4'><FormattedMessage id='General.edit'/> {group.name}</Typography>
          <IconButton
            onClick={this.props.close}
            className={ classes.exitIcon }
            aria-label="close dialog"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormGroup>
            <TextField
              disabled
              id='group-edit-name'
              classes={{ root: classes.firstName }}
              label={<FormattedMessage id='General.name'/>}
              name='name'
              type='text'
              variant='outlined'
              value={this.state.name}
              onChange={this.handleChange}
            />
            <TextField
              multiline
              classes={{ root: classes.firstName }}
              id='group-edit-description'
              label={<FormattedMessage id='General.description'/>}
              type='text'
              name='description'
              variant='outlined'
              value={this.state.description}
              onChange={this.handleChange}
            />
            <div className={classes.login}>
              <Typography variant='h6' className='text'> <FormattedMessage id='GroupEdit.toggle'/> </Typography>
              <Switch
                disabled
                aria-label={`make group ${this.state.isPrivate ? <FormattedMessage id='General.public'/> : <FormattedMessage id='General.private'/>}`}
                className='toggle'
                checked={this.state.isPrivate}
                onChange={this.togglePrivate}/>
            </div>
          </FormGroup>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button onClick={this.props.close} color='secondary'>
            <FormattedMessage id='General.cancel'/>
          </Button>
          <Button onClick={this.submit} color='primary'>
            <FormattedMessage id='General.save'/>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapDispatch = (dispatch) => {
  return {
    updateGroup: (group) => dispatch(updateGroup(group))
  }
};


export default connect(null, mapDispatch)(withStyles(baseStyle)(GroupEditWizard));