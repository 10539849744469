export default {
  container: {
    marginBottom: 10,
    paddingBottom: 10,
    textAlign: 'center',
  },
  title:{
    padding: 10
  },
  text: {
    alignSelf: 'center'
  },
  divider: {
    marginBottom: 15
  },
  secondaryDivider: {
    marginBottom: 10,
    margin: '0 auto',
    width: '40%'
  },
  pictureContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'center'
  },
  avatar: {
    marginBottom: 10,
    marginLeft: 10,
    width: 120,
    height: 120,
    zIndex: 1
  },
  editButton: {
    gridColumn: 3,
    minHeight: 0,
    alignSelf: 'end',
    zIndex: 2,
    marginLeft: -15,
  },
  dropzone:{
    display: 'grid',
    justifyContent: 'center',
    gridRowGap: '20px',
    margin: 20,
  },
  slider: {
    width: '100%'
  },
  addImage: {
    justifySelf: 'center',
    margin: '0 auto'
  }
}