import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { fetchStoryData, fetchStoryDataSections, refreshStoryData } from "../../../redux/actions";
import baseStyle from "../../../styles/dashboard";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Toolbar, Tooltip, Typography } from "@material-ui/core";
import { Component } from "react";
import { Edit, Search } from "@material-ui/icons";
import RefreshIcon from '@material-ui/icons/Refresh';
import { getSorting, stableSort } from "../../../helpers";
import StudentStoryEdit from "../../components/Wizards/Student/StudentStoryEdit";
import StoryDataSectionList from "./StoryDataSectionList";

const rows = [
    { id: 'titleImageSrc', label: <FormattedMessage id="General.image" />, searchable: true },
    { id: 'name', label: <FormattedMessage id="General.name" />, searchable: true },
    { id: 'description', label: <FormattedMessage id="General.description" />, searchable: true },
    { id: 'status', label: <FormattedMessage id="General.status" />, searchable: true },
    { id: 'importSource', label: <FormattedMessage id="General.source" />, searchable: true },

    { id: 'author', label: <FormattedMessage id="General.author" />, searchable: true },
    { id: 'isbn', label: <FormattedMessage id="General.isbn" />, searchable: true },
    { id: 'gradeLevel', label: <FormattedMessage id="General.gradeLevel" />, searchable: true },
    { id: 'offset', label: <FormattedMessage id="General.offset" />, searchable: true },
    { id: 'ending', label: <FormattedMessage id="General.ending" />, searchable: true },
];
class StoryDataList extends Component {
    state = {
        order: 'asc',
        orderBy: 'name',
        rowsPerPage: 5,
        page: 0,
        filteredData: [],
        searchValue: '',
        open: false,
        showSelectedBook: false,
        selectedBook: null,
        showSelectedBookSection: false,
        confirm: false
    };
    componentDidMount() {
        this.props.fetchStoryData();
    }
    handleSearch = (searchValue, data) => {
        let _data = [];
        if (this.props.storyDataConfig)
            _data = this.props.storyDataConfig.data;

        const searchableData = data ? data : _data ? _data : [];
        const filtered = Array.isArray(searchableData) && searchableData.filter(item => {
            const searchableKeys = [{ id: 'name' }, { id: 'author' }]
            return searchableKeys.some(key => {
                if (item[key.id]) {
                    return item[key.id].toLowerCase().includes(searchValue.toLowerCase())
                }
                else {
                    return false
                }
            })
        });
        Array.isArray(searchableData) && this.setState({ filteredData: filtered, page: 0 })
    }
    handleSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';
        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }
        this.setState({ order, orderBy });
    };
    handleChangePage = (_event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };
    handleStoryDataEdit = (e, data) => {
        e.stopPropagation();
        this.setState({
            selectedBook: data,
            showSelectedBook: true
        })
    }
    handleStoryDataRefresh = (e, data) => {
        e.stopPropagation();
        this.setState({
            confirm: true, okFunc: () => {
                this.props.refreshStoryData(data.id);
                this.setState({ confirm: false });
            }
        })
    }
    handleClose = () => {
        this.setState({
            showSelectedBook: false
        })

    }
    handleRowClick = (e, data) => {
        e.stopPropagation();
        if(this.props.type === "addStory"){
            this.props.addBookToSeries(data)
        }
        else{
            this.props.fetchStoryDataSections(data.id);
            this.setState({ showSelectedBookSection: true });
        }
    }
    closeSectionDialog = () => {
        this.setState({ showSelectedBookSection: false });
    }
    cancelConfirm = () => {
        this.setState({ confirm: false });
    }

    render() {
        const { orderBy, order, filteredData, page, searchValue, rowsPerPage, showSelectedBook, selectedBook
            , showSelectedBookSection } = this.state;

        const { classes, storyDataConfig } = this.props;
        const data = (filteredData.length > 0 || searchValue) ? filteredData : storyDataConfig ? storyDataConfig.data : [];
        return (
            <>
                <Paper>
                    <Toolbar classes={{ root: classes.toolbar }}>
                        <Typography className={classes.staffHeader} noWrap>
                            Story Data
                        </Typography>
                        <div className={classes.actions}>
                            {(
                                <TextField
                                    variant='outlined'
                                    id='table-search'
                                    aria-label='search input'
                                    type={'search'}
                                    InputProps={{
                                        inputProps: {
                                            'aria-label': 'search input'
                                        },
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <Search />
                                            </InputAdornment>
                                        ),
                                        className: classes.searchBarBar,
                                    }}
                                    onChange={(ev) => {
                                        this.handleSearch(ev.target.value);
                                        this.setState({ searchValue: ev.target.value });
                                    }}
                                    value={this.state.searchValue}
                                />
                            )}
                        </div>
                    </Toolbar>
                    <div className={classes.tableWrapper}>
                        <TableContainer>
                            <Table >
                                <TableHead>
                                    <TableRow style={{ display: 'flex', justifyContent: 'space-evenly' }} >
                                        {
                                            rows.length &&
                                            rows.map((row, index) => {
                                                return (
                                                    <TableCell
                                                        aria-label={row.id}
                                                        key={row.id + index}
                                                        style={row.id == 'description' ? { width: '350px', textAlign: 'center' } : { width: '80px' }}
                                                        align={(row.id == "titleImageSrc") ? "left" : "right"}
                                                        sortDirection={orderBy === row.id ? order : false}
                                                    >
                                                        {row.id != "titleImageSrc" ? <Tooltip
                                                            title='Sort'
                                                            placement='bottom-start'
                                                            enterDelay={300}>
                                                            <TableSortLabel
                                                                active={orderBy === row.id}
                                                                direction={order}
                                                                onClick={(ev) => this.handleSort(ev, row.id)}>
                                                                {row.label}
                                                            </TableSortLabel>
                                                        </Tooltip> :
                                                            row.label
                                                        }
                                                    </TableCell>
                                                )
                                            })
                                        }
                                        { this.props.type !=="addStory" && <TableCell>
                                            <Tooltip
                                                title='Action'
                                                placement='bottom-start'
                                                enterDelay={300}>
                                                <TableSortLabel
                                                >Action
                                                </TableSortLabel>
                                            </Tooltip>
                                        </TableCell>}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {stableSort(data, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((_data, index) => {
                                            return (
                                                <TableRow className={classes.hoverRow} tabIndex={0} hover key={_data.id + index}
                                                    onClick={(e) => this.handleRowClick(e, _data)}
                                                    style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                                    {rows.length &&
                                                        rows.map((row, index) => {
                                                            return (
                                                                row.id === "titleImageSrc" && _data[row.id] != null ?
                                                                    <TableCell key={index} style={{ textAlign: 'center' }}>
                                                                        <img src={_data[row.id]} height="150" width="100" ></img>
                                                                    </TableCell> :
                                                                    <TableCell key={index}
                                                                        align={"center"}
                                                                        style={row.id == 'description' ? { width: '350px', } : row.id === 'titleImageSrc' ? { width: '100px', textAlign: 'center' } : { width: '80px', textAlign: 'center' }}
                                                                    >
                                                                        { (row.id === 'gradeLevel' && _data[row.id] === 0) ? 'K' : _data[row.id]}
                                                                    </TableCell>
                                                            )
                                                        })
                                                    }
                                                    { this.props.type !== 'addStory' && <TableCell>
                                                        <div className={classes.buttons}>
                                                            <Tooltip title={<FormattedMessage id='General.edit' />}>
                                                                <IconButton
                                                                    size="medium"
                                                                    onClick={(e) => this.handleStoryDataEdit(e, _data)}>
                                                                    <Edit />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title={<FormattedMessage id='General.refresh' />}>
                                                                <IconButton
                                                                    size="medium"
                                                                    onClick={(e) => this.handleStoryDataRefresh(e, _data)}>
                                                                    <RefreshIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                    </TableCell>}
                                                </TableRow>
                                            );
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15]}
                            component='div'
                            count={data && data.length}
                            labelRowsPerPage={<FormattedMessage id='Datatable.rowsPerPage' />}
                            rowsPerPage={this.state.rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                                'aria-label': 'Previous Page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next Page',
                            }}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                    </div>
                </Paper>
                {showSelectedBook && <StudentStoryEdit open={showSelectedBook} data={selectedBook}
                    close={this.handleClose} />}
                {showSelectedBookSection && <StoryDataSectionList open={showSelectedBookSection} close={this.closeSectionDialog} />}
                {this.state.confirm && (
                    <Dialog
                        disableEscapeKeyDown
                        open={this.state.confirm}
                        onClose={this.cancelConfirm}
                        aria-labelledby='skill-dialog-title'
                        scroll='paper'
                        PaperProps={{ classes: { root: classes.container } }}
                    >
                        <DialogTitle>Are you sure?</DialogTitle>
                        <DialogContent>
                        </DialogContent>
                        <DialogActions className={classes.buttons}>
                            <Button onClick={this.cancelConfirm} color='secondary'>
                                <FormattedMessage id='General.cancel' />
                            </Button>
                            <Button onClick={this.state.okFunc} color='primary'>
                                <FormattedMessage id='General.ok' />
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}
            </>)
    }
}
const mapState = ({ platform: { storyDataConfig } }) => {
    return {
        storyDataConfig
    }
};

const mapDispatch = (dispatch, { history }) => {
    return {
        fetchStoryData: () => dispatch(fetchStoryData()),
        fetchStoryDataSections: (id) => dispatch(fetchStoryDataSections(id)),
        refreshStoryData: (id) => dispatch(refreshStoryData(id))
    }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(StoryDataList));