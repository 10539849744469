import React, { Component } from 'react';
import { Typography, IconButton, FormGroup } from '@material-ui/core';
import { Dialog, DialogContent, DialogTitle, DialogContentText } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../../styles/wizardBase';
import { FormattedMessage } from 'react-intl';
import DataTable from '../../Shared/DataTable';
import GoalDatabankWizard from '../GoalDatabank/GoalDatabankWizardBase';

class SelfGuidedPage3 extends Component {

  render()  {
    const rows = [
      { id: 'name', label: <FormattedMessage id='General.name'/>, searchable: true },
      { id: 'description', label: <FormattedMessage id='General.description'/> },
    ];
    const { classes } = this.props;
    return (
      <>
        <Dialog
          disableEscapeKeyDown
          fullWidth={true}
          open={this.props.open}
          onClose={this.props.close}
          aria-labelledby='form-dialog-title'
          PaperProps={{classes:{ root: classes.container }}}
          scroll='paper'
        >
          <DialogTitle disableTypography={true} className={classes.title} id='form-dialog-title'>
            <Typography className={classes.titleText} variant='h4'>
              <FormattedMessage id="Wizard.skillDB.title"/>
            </Typography>
            <IconButton
              onClick={this.props.close}
              className={ classes.exitIcon }
              aria-label="close dialog"
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.container}>
            <FormGroup classes={{root: classes.brailleRoot}}>
              <DialogContentText align='center' variant='h6'>Which goal should we add the skill to?</DialogContentText>
              {
                this.props.goals.length > 0
                ? <DataTable
                    clickable
                    type='guidedGoal'
                    history={this.props.history}
                    skill={this.props.skill}
                    rows={rows}
                    rowsPerPage={[5,10,15]}
                    orderBy={'name'}
                    data={this.props.goals}
                    studentId={this.props.id}
                    numCols={2}
                    title={<FormattedMessage id='Goals.allGoals'/>}
                  />
                  : <>
                      <Typography align='center' gutterBottom variant={'subtitle1'}>Looks like you don't have any goals yet, click below to add one!</Typography>
                      <div className={classes.buttonCenter}>
                        <GoalDatabankWizard type='staff' guided history={this.props.history} id={this.props.staffId}/>
                      </div>
                    </>
              }
            </FormGroup>
          </DialogContent>
        </Dialog>
      </>
    )
  }
}

export default withStyles(baseStyle)(SelfGuidedPage3);