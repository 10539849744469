export default {
  container: {
    margin: '0px 5px 20px 5px',
    padding: 15,
    '& #editor-container': {
      color: 'black',
      '& .ql-video': {
        margin: '0 auto',
        width: 560,
        height: 315
      }
    },
    '& .ql-editing': {
      left: '16px !important',
      top: '0px !important'
    },
    '& #emoji-palette': {
      top: '8px !important'
    },
    '& .mention': {
      fontWeight: 800
    },
    '& .ql-mention-list-container': {
      overflow: 'scroll',
      maxHeight: 480
    }
  },
  postBox: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridAutoFlow: 'column',
    marginTop: 10,
    marginRight: 5
  },
  title: {
    gridRow: 1,
    marginBottom: 10
  },
  textInput: {
    height: 120,
  },
  buttons: {
    gridColumn: 1,
    justifySelf: 'start',
    alignSelf: 'center',
    minHeight: 0
  },
  submit: {
    gridColumn: 2,
    justifySelf: 'end',
    alignSelf: 'center',
    minHeight: 0
  },
  inline: {
    display: 'inline-block'
  },
}