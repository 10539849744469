import { Box, Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Modal, Paper, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, Typography } from '@material-ui/core';
import { CheckCircle, Close, CloudDownload } from '@material-ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import BookShareCredentialsLogin from '../../Platform/components/Student/BookShareCredentialsLogin';
import { addStoryToStudent, clearBookShareLogin, downloadBook, fetchBookPreview, getBooksForStudent, getBookshareRequestedBooks, getBookShareUsers, getStaffUser } from '../../redux/actions';
import { CLEAR_IMPORTED_BOOK, RESET_IMPORT_MODAL, RESET_STORY_DATA_SECTION } from '../../redux/constants';
import BookShareUserTable from './BookShareUserTable';
import './Content.css';
import BookShareRequestModal from '../../Platform/components/Shared/BookShareRequestModal';

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}
const styles = {
    dialogTitle: {
        backgroundColor: '#383871',
      },
      exitIcon: {
        float: 'right',
        color: '#fff',
      },
      dialog: {
        zIndex: 0,
        overflow: 'auto',
        backgroundColor:'#ddf0ff',
        paddingTop: 15,
      },
      enrollTitle:{
        color:'#fff',
        fontSize: 24,
        textAlign: 'center',
        flex:1
      },
      headContainer: {
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center'
    },
}
const DetailsWizard = ({ user, storyId, story, open, exitFunc, booksImport, importBooks,
    bookShareUsers, student, books, bookShareList, searchToggle, bookShareRequestBooks, fetchBookPreview, addStoryToStudent, storyDataSections, resetStoryDataSections }) => {
    const effectDispatch = useDispatch();

    const [imported, setImported] = useState([]);
    const [loginRequired, setLoginRequired] = useState(null);
    const [showImportMessage, setshowImportMessage] = useState(false);
    const [ImportErrorMessage, setImportErrorMessage] = useState('');
    const [importSlide, setImportSlide] = useState();
    const [messageLink, setMessageLink] = useState('');
    const [bookShareUser, setBookShareUser] = useState([]);
    const prevImportMessage = usePrevious(showImportMessage);
    const prevBookShareUser = usePrevious(bookShareUser);
    const [showStudent, setShowStudent] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [showRequestModal, setShowRequestModal] = useState(false);
    const [showBookPreview,setShowBookPreview] = useState(false);
    const [BookData,setBookData] = useState([])
    // useEffect(() => {
    //     if (user.hasBookShareCredentials && loginRequired) {
    //         setLoginRequired(false);
    //     }
    // }, [user]);

    useEffect(() => {
        if (bookShareUsers && prevBookShareUser !== bookShareUsers.data) {
            setBookShareUser(bookShareUsers.data);
        }
    }, [bookShareUsers]);

    useEffect(() => {
        if (booksImport && prevImportMessage == showImportMessage && booksImport.message === "Cannot retrieve organization members") {
            setshowImportMessage(true);
            setImportErrorMessage("Your Bookshare organization does not have any members");
            setLoginRequired(false);
            setMessageLink('');
            effectDispatch({
                type: RESET_IMPORT_MODAL
            });

        }
        else if ((booksImport.message === "User is Not Logged into bookshare") ||
            (booksImport && booksImport.message === "Authentication has expired") && booksImport && booksImport.needToLogin) {
            setLoginRequired(true);
            setshowImportMessage(false);
            effectDispatch({
                type: RESET_IMPORT_MODAL
            });
        }
        else if (booksImport && prevImportMessage == showImportMessage && booksImport.message === "Cannot find Student Match") {
            setshowImportMessage(true);
            setImportErrorMessage("We can't find this student in your Bookshare account, please check the list for the correct student and press the (+) icon.");
            setLoginRequired(false);
            setMessageLink('https://www.bookshare.org/orgAccountMembers');
           effectDispatch(getBookShareUsers());
            effectDispatch({
                type: RESET_IMPORT_MODAL
            });
        }
        else if (booksImport && prevImportMessage == showImportMessage && booksImport.message === "Too Many Requests") {
            setShowLoading(false);
            setLoginRequired(false);
            setShowRequestModal(true);
            effectDispatch({
                type: RESET_IMPORT_MODAL
            });
        }
        if (booksImport && booksImport.needToLogin == "false") {
            setLoginRequired(false);
        }
        if (booksImport && booksImport.studentBookImported) {
            effectDispatch(getBooksForStudent(storyId));
            effectDispatch(getBookshareRequestedBooks(storyId));
            setShowLoading(false);
        }
        if (booksImport && prevImportMessage == showImportMessage && booksImport.message === "Cannot retrieve File") {
            setShowLoading(false);
        }
    }, [booksImport, effectDispatch]);

    useEffect(() => {
        // After redirecting to Bookshare login and it was successfull now making the import book call 
        if (importBooks && importBooks.bookImported == true) {
            setLoginRequired(false);
            effectDispatch({ type: CLEAR_IMPORTED_BOOK })
            effectDispatch(getStaffUser());
            if (storyId && importSlide) {
                if (searchToggle) {
                    const filteredBook = bookShareList && bookShareList.filter((book) => book.id == story.id);
                    if (filteredBook && filteredBook[0].storyDataId && filteredBook[0].storyDataId != null)
                        addStoryToStudent(storyId, filteredBook[0].storyDataId);
                    else
                        filteredBook && effectDispatch(downloadBook(story.id, storyId, filteredBook[0]));
                }
                else {
                    addStoryToStudent(storyId, importSlide.id);
                }
                if (importSlide && !imported.includes(importSlide.id)) {
                    setImported([...imported, importSlide.id]);
                }
                effectDispatch({
                    type: RESET_IMPORT_MODAL
                });
            }
        }
    }, [importBooks,effectDispatch]);

    useEffect(()=>{
        if(Object.keys(storyDataSections).length !== 0){
            setBookData(storyDataSections.data)
        }
    },[storyDataSections])

    const closeLoginModal = () => {
        setLoginRequired(false);
        setShowLoading(false);
    }

    const closeImportMessageModal = () => {
        setshowImportMessage(false);
        setLoginRequired(false);
        setShowLoading(false);
    }
    const importSkill = (currentSlide) => {
        setShowLoading(true);
        if (searchToggle) {
            const filteredBook = bookShareList && bookShareList.filter((book) => book.id == story.id);
            if (filteredBook && filteredBook[0].storyDataId && filteredBook[0].storyDataId != null)
                addStoryToStudent(storyId, filteredBook[0].storyDataId);
            else
                filteredBook && effectDispatch(downloadBook(story.id, storyId, filteredBook[0]));
        }
        else {
            addStoryToStudent(storyId, currentSlide.id);
        }
        if (!imported.includes(currentSlide.id)) {
            setImported([...imported, currentSlide.id]);
            setImportSlide(currentSlide);
        }
    };

    const handleUnmount = () => {
        setshowImportMessage(false);
        effectDispatch({
            type: RESET_IMPORT_MODAL
        });

    }
    const handleWizardClose = () => {
        setLoginRequired(false);
        exitFunc();
    }
    const addStudent = () => {
        setShowStudent(true);
    }
    const handleStudentWizardClose = () => {
        setShowStudent(false);
    }
    const handleRequestClose = () => {
        setShowRequestModal(false);
        exitFunc();
    }
    const handlePreviewClose = ()=>{
        setShowBookPreview(false)
        setBookData([])
        resetStoryDataSections()  
    }
    const BookPreviewModal = (book) => {

        const textArray = groupText(book)
        return (
          <Dialog maxWidth='md' fullWidth open={showBookPreview} onClose={handlePreviewClose}>
            <DialogTitle style={styles.dialogTitle}>
                <div style={styles.headContainer}>
                  <Typography style={styles.enrollTitle} variant="h4">{story.name}</Typography>
                 <IconButton
                      onClick={handlePreviewClose} 
                      style={styles.exitIcon}
                      aria-label="close dialog"
                  >
                    <Close />
                  </IconButton>  
                </div>
                 
            </DialogTitle>
            <DialogContent style={styles.dialog}>
                {textArray.map((text, index) => {
                    if(index < 50){
                      return <>
                        <Typography style={{lineHeight:'20px',color:'#00000077'}} key={index}>{text}</Typography>
                        <br />
                        </>  
                    }   
                }
                )}
            </DialogContent>
          </Dialog>
        );
      };
      const handleBookPreview = () => {
        fetchBookPreview(story.id);
        setShowBookPreview(true)
      }
      const groupText = (book) => {
        if (!book || !Array.isArray(book)) {
          throw new Error('Invalid book input');
        }
      
        const groupedSegments = book.reduce((groups, segment) => {
          const group = groups.find((group) => group.groupNumber === segment.groupNumber);
      
          if (group) {
            group.segments.push(segment.text);
          } else {
            groups.push({
              groupNumber: segment.groupNumber,
              segments: [segment.text],
            });
          }
          return groups;
        }, []);
      
        const groupedTextArray = groupedSegments.map((group) => group.segments.join(' '));
      
        return groupedTextArray;
      };   
      
    return (
        <>
            <Dialog open={open} maxWidth='lg' onClose={handleWizardClose}>
                <Paper className='list'>
                    {story &&
                        <Toolbar className='toolbar'>
                            <Typography noWrap className='header'>
                                {story.name}
                            </Typography>
                            <Button variant='contained' onClick={handleBookPreview} >Preview Book</Button>
                            <Box>
                                    {
                                        (bookShareRequestBooks && bookShareRequestBooks.data.some((b) => (b.id == story.id))) || (books && books.some((b) => b.name == story.name && story.name != null))
                                            ? <IconButton><CheckCircle /></IconButton>
                                            : showLoading ? <CircularProgress size={30} /> : 
                                               <IconButton onClick={(e) => importSkill(story, e)}><CloudDownload /></IconButton>}    
                            </Box>
                            <IconButton onClick={handleWizardClose} className='close'>
                                <Close />
                            </IconButton>
                        </Toolbar>
                    }
                    <Table style={{ minHeight: 250 }}>
                        <TableHead>

                        </TableHead>
                        <TableBody>
                            {story ?
                                <TableRow>
                                    <TableCell>
                                        <img src={story.titleImageSrc || story.titleImage} alt={story.name} width={150} height={200} />
                                    </TableCell>
                                    <TableCell>
                                        { (story.gradeLevel || story.gradeLevel === 0) &&
                                        <Typography><b>Grade Level:</b> {story.gradeLevel === 0 ? 'K' : story.gradeLevel }</Typography>
                                        }                                       
                                        <Box className='synopsisBox'>
                                            <Typography component='div' variant='h6'>
                                                Synopsis
                                            </Typography>
                                            <Typography component='div'>
                                                <b>By :</b> {story.author}
                                            </Typography>
                                        </Box>
                                        <Typography component='p' className='synopsis_description' variant='body2'>
                                            <span dangerouslySetInnerHTML={{ __html: story.description || story.synopsis }}></span>
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                :
                                <TableRow>
                                    <TableCell />
                                    <TableCell colSpan={5} align={"center"}>
                                        <h4>No Results Found</h4>
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </Paper>
                <Dialog open={showImportMessage} maxWidth='lg' onClose={() => closeImportMessageModal()}>
                    <Paper className='list'>
                        <Card aria-label="Message" >
                            <CardHeader style={{ backgroundColor: '#00729b', marginBottom: '30px' }} action={
                                <IconButton onClick={closeImportMessageModal}>
                                    <Close />
                                </IconButton>}
                                title={<Typography variant='h5' className='header'>{bookShareUser && bookShareUser.length > 0 ? "Link Student" : "Something went wrong"}</Typography>}
                            >
                            </CardHeader>
                            <CardContent className='synopsis_description' style={{ textAlign: 'center', fontFamily: "Calibri, sans-serif" }} >
                                {ImportErrorMessage}
                                {bookShareUser && bookShareUser.length > 0
                                    ?
                                    <>
                                        <br />
                                        <BookShareUserTable users={bookShareUser} student={student}
                                            closeImportMessageModal={closeImportMessageModal}
                                            importSlide={importSlide}
                                            handleUnmount={handleUnmount}
                                            storyId={storyId}
                                            messageLink={messageLink}
                                            showStudent={showStudent}
                                            addStudent={addStudent}
                                            handleStudentWizardClose={handleStudentWizardClose}
                                            searchToggle={searchToggle}
                                        />
                                    </>
                                    : null
                                }
                            </CardContent>
                        </Card>
                    </Paper>
                </Dialog>
            </Dialog>
            {
                (loginRequired && booksImport && booksImport.needToLogin == "true")
                    ? <BookShareCredentialsLogin
                        openLoading={loginRequired}
                        exitFunc={closeLoginModal} /> : null
            }
            {
                showRequestModal &&
                <BookShareRequestModal open={showRequestModal} close={handleRequestClose} />
            }
            {
                showBookPreview && 
                BookPreviewModal(BookData)
            }
        </>
    )
}

const mapState = ({ shared: { user }, platform: { booksImport, importBooks, bookShareUsers, student, books, bookShareList, bookShareRequestBooks, storyDataSections } }) => {
    return {
        user,
        booksImport,
        importBooks,
        bookShareUsers,
        student,
        books,
        bookShareList,
        bookShareRequestBooks,
        storyDataSections
    }
};
const mapDispatch = (dispatch) => {
    return {
        fetchBookPreview: (id) => dispatch(fetchBookPreview(id)),
        resetStoryDataSections: () => dispatch({type: RESET_STORY_DATA_SECTION}),
        addStoryToStudent: (id, bookId) => dispatch(addStoryToStudent(id, bookId)),
    }
};

export default connect(mapState,mapDispatch)(DetailsWizard);