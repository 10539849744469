import {GET_SCORE_CHART, GET_WEEKLY_SUMMARIES} from '../../constants';


export const charts = (state = [], action)  => {
    switch (action.type) {
        case GET_SCORE_CHART:
            return action.charts;
        default:
            return state;
    }
}

export const weeklySummaries = (state = [], action) =>
{
    switch (action.type) {
        case GET_WEEKLY_SUMMARIES:
            return action.weeklySummaries;
        default:
            return state;

    }
}