import React, { Component } from 'react';
import {
  TextField,
  Button,
  MenuItem,
  withStyles,
  Typography,
  DialogActions,
  DialogContent,
} from '@material-ui/core/index';
import { FormattedMessage } from 'react-intl';
import styles from '../../../../styles/registration';
import { fetchSubscriptionOptions } from '../../../../redux/actions';
import planCouponDict from "./planDictionary";

class RegistrationStudentCount extends Component {

  state = {
    products: [],
    plans:[],
  };

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const sourceParam = urlParams.get("source");
    let ids = [];
    if(sourceParam){
    planCouponDict.map((val)=>{
      if(val.value === sourceParam){
        ids = val.plans.map(plan=>plan.id)
        this.setState({plans:val.plans})
      }
    })
    }
    fetchSubscriptionOptions()
      .then((response) => {
        if (response.error){
          console.log(response.err)
        }
        else {
          this.setState({ products: response });
          let plan = "";
          const buddyBookYearly = response && response.filter(product => {
            if(ids.length !== 0){
              return ids.includes(product.id)
            }
          else{
            return product.id == 818051 
          }
          });
          plan = this.state.plans.find(plan => plan.id === buddyBookYearly[0].id)
          this.props.setDefaultProduct(buddyBookYearly[0],plan ? plan.coupon : "");
        }
      })
  }

  next = () => {
    this.props.registered
      ? this.props.goToPage(2)
      : this.props.next();
  };

  render(){
    const { classes, product } = this.props;
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    return (
        this.props.user.type==="Admin" ?
            <DialogContent className={classes.purchase}>
              <Typography className={classes.title} gutterBottom variant='subtitle1'>
                You are currently Registered as the administrator of a district.  If you wish to purchase for that district please contact sales at sales@objectiveed.com.  If you wish to purchase for a seperate set of students please use a different email.
              </Typography>
                <DialogActions>
                    <Button color='secondary' onClick={this.props.close}>
                        <FormattedMessage id='General.close'/>
                    </Button>
                </DialogActions>
            </DialogContent>
              :
      <>
        <DialogContent className={classes.purchase}>
            <Typography className={classes.title} gutterBottom variant='subtitle1'>
              Select your preferred subscription term, and the number of student licenses you’d like to
              purchase. 
            </Typography>
          <TextField
            select
            className={classes.inputLine}
            variant='outlined'
            name='product'
            label='Subscription Type'
            onChange={(e)=>{this.props.handleChange(e,this.state.plans)}}
            value={this.props.product}
          >
            {
              this.state.products.filter(_product =>{ 
                let ids = this.state.plans.map(plan=>plan.id)
                var plansById = this.state.plans.reduce(function(map, obj) {
                  map[obj.id] = obj;
                  return map;
                }, {});
                if( ids.length !== 0){
                  _product.trial = false;
                  console.log(plansById[_product.id])
                  if(plansById.hasOwnProperty(_product.id)) {
                    _product.message = plansById[_product.id].message;
                  }
                  return ids.includes(_product.id)
                }
                else { 
                  _product.trial =  true;
                  //return (_product.id==818052 || _product.id==818051)
                  return (_product.id==794071 || _product.id==795046)
                }
              }).map(_product => (
                <MenuItem key={_product.id} value={_product}>{_product.name}</MenuItem>
              ))
            }
          </TextField>
          <TextField
            className={classes.inputLine}
            type='number'
            variant='outlined'
            name='studentCount'
            inputProps={{ min:1 }}
            label='Number of Students'
            defaultValue={1}
            onChange={this.props.handleChange}
          />
          <div className={classes.pricing}>
            <Typography className='title' variant={isMobile ? 'h6' : 'h4' }>SUBSCRIPTION COST</Typography>
            {
              product.id &&

                <div>
                  {product.trial != false &&
                    <Typography className='math' variant='h6'>
                    If you have a coupon your may apply it at checkout.
                    </Typography>
                  }
                  {product.trial==false &&
                      <Typography className='math' variant='h6'>
                        {product.message}
                      </Typography>
                  }

              <Typography className='math' variant={isMobile ? 'subtitle2' : 'h6' }>
                {this.props.students}
                {this.props.students > 1 ? ' Students' : ' Student'} x <span className='price'>${(product.recurring_price)}</span> =
                <span className='total'> ${(this.props.students * product.recurring_price).toFixed(2)}/{product.billing_type}</span>
              </Typography>
                </div>
            }
          </div>
        </DialogContent>
        <DialogActions>
          <Button color='secondary' onClick={this.props.close}>
            <FormattedMessage id='General.cancel'/>
          </Button>
          <Button color='primary' variant='contained' onClick={this.next}>
            {this.props.registered ? 'Checkout' : 'Next' }
          </Button>
        </DialogActions>
      </>
    )
  }
}

export default withStyles(styles)(RegistrationStudentCount);