import React, { Component } from 'react';
import { connect } from 'react-redux';
import GroupListItem from '../components/GroupListItem';
import { Card, IconButton, TextField, Typography, Button, withStyles, Icon, InputAdornment } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { myProfile, getUser, fetchPosts, fetchAllGroups, fetchMyGroups } from '../../redux/actions';
import baseStyle from '../../styles/publicGroups';

class PublicGroups extends Component {

  state = {
    search: '',
    groups: this.props.groups || []
  };

  componentDidMount() {
    this.props.myProfile();
    this.props.fetchAllGroups();
    if (this.props.profile.key) {
      this.props.fetchMyGroups(this.props.profile.key);
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (!this.props.profile.key) {
      this.props.myProfile();
      this.props.fetchMyGroups(nextProps.profile.key);
    }
    if (this.state.groups.length !== nextProps.groups.length){
      this.setState({groups: nextProps.groups})
    }
    if (this.props.myGroups.length !== nextProps.myGroups.length){
      this.props.fetchMyGroups(this.props.profile.key);
    }
  }

  handleChange = (ev) => {
    this.setState({[ev.target.name]: ev.target.value })
  };

  onKeyPress = (ev) => {
    if (ev.key === 'Enter') {
      this.handleSearch();
    }
  };

  handleSearch = () => {
    const { groups } = this.props;
    const { search } = this.state;
    const filteredGroups = groups.filter(group => {
      const keys = Object.keys(group);
      return keys.some(key => {
        return group[key] ? group[key].toLowerCase().includes(search.toLowerCase()) : false
      })
    });
    this.setState({ groups: filteredGroups })
  };

  render() {
    const { classes } = this.props;
    const { groups } = this.state;
    return (
      <main className={classes.container}>
        <Card className={classes.searchContainer}>
          <Typography
            variant='h4'
            className={classes.searchText}
          >
            Search all Public Groups:
          </Typography>
          <TextField
            className={classes.searchBar}
            variant='outlined'
            name='search'
            onChange={this.handleChange}
            value={this.state.search}
            onKeyPress={this.onKeyPress}
            InputProps={{
             endAdornment: (
               <InputAdornment position='end'>
                 <IconButton>
                   <Icon className='fas fa-search button' />
                 </IconButton>
               </InputAdornment>
             )
            }}
          />
        </Card>
        {
          groups.map(group => <GroupListItem key={group.key} group={group} />)
        }
        <Button
          aria-label='go back to feed'
          className={classes.backBtn}
          href='/home'
        >
          <ArrowBack/> &nbsp; back to feed
        </Button>
      </main>
    );
  }
}

const mapState = ({ social: { profile, posts, groups, myGroups }}) => {
  return {
    profile,
    posts,
    groups,
    myGroups
  }
};

const mapDispatch = (dispatch, { history }) => {
  return {
    myProfile: () => dispatch(myProfile(history)),
    fetchPosts: (posts) => dispatch(fetchPosts(posts)),
    getUser: () => dispatch(getUser()),
    fetchAllGroups: () => dispatch(fetchAllGroups()),
    fetchMyGroups: (profileKey) => dispatch(fetchMyGroups(profileKey))
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(PublicGroups));