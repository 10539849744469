import React, {Component} from 'react';
import { connect } from 'react-redux';
import {Card, CardContent,Typography, Divider} from '@material-ui/core';
import {ListAlt} from '@material-ui/icons';
import { Timeline } from '@material-ui/icons';
import {withStyles} from '@material-ui/core/styles';
import baseStyle from '../../../styles/dashboard';
import Carousel from 'nuka-carousel';
import {percentToTargetLine, getStudentsForStaff} from '../../../redux/actions';
import PercentToTargetLine from '../../components/Charts/PercentToTargetLine';
import {FormattedMessage} from 'react-intl';


class WeeklySummary extends Component {

    state = {
        percentToTarget: {}
    };

    componentWillReceiveProps(nextProps, nextContext) {
        const staffStudents = nextProps.staffStudents;
        var startDate;
        var endDate;
        console.log("weekly");
        console.log(nextProps.weeklySummaries);
        if(this.props.weeklySummaries && nextProps.weeklySummaries.length>0) {
            const summary = nextProps.weeklySummaries[0];
            startDate = summary.startDate;
            endDate = summary.endDate;
        }
        console.log(startDate);
        console.log(endDate);
        staffStudents.map(student =>
            (
                percentToTargetLine(student.id, startDate, endDate).then(data => {
                    const percentToTarget = this.state.percentToTarget;
                    percentToTarget[student.id] = data;
                    this.setState({percentToTarget})
                })
            ));
    }

    renderCards = (staffStudents) => {
        const charts = staffStudents.filter(student => this.state.percentToTarget[student.id]).map((student, index) => {
            return <PercentToTargetLine
                title={student.name}
                subText={this.renderSubText(student.id)}
                data={this.state.percentToTarget[student.id]}
            />
        });
        return charts;
    }

    renderSubText = (id) => {
        console.log("Weekly Summaries");
        console.log(this.props.weeklySummaries);
        const summaries = this.props.weeklySummaries.filter(w => w.studentId == id);
        console.log(summaries);
        return (
        <div>
            {summaries.map((s) => (
                <Typography>{s.skillName}: {s.status}</Typography>
            ))}
        </div>);
}

    render() {
        const {classes, title, chart, staffStudents} = this.props;
        const data = chart ? chart.dataSet : this.props.data ? this.props.data : [];
        const int_charts = this.renderCards(staffStudents);
        console.log("PercentToTargets");
        console.log(this.state.percentToTarget);
        console.log("Staff Students");
        console.log(staffStudents);
        return (
            <Card aria-label='line-chart' className={classes.lineCharts}>


                    <Carousel>
                        {int_charts}
                </Carousel>
            </Card>
        )
    }
}

const mapState = ({platform: {staffStudents}}) => {
    return {
        staffStudents,
    }
};

const mapDispatch = (dispatch) => {
    return {
        getStudentsForStaff: (id) => dispatch(getStudentsForStaff(id)),
    }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(WeeklySummary));
