export default {
  container: {
    margin: '80px auto',
    padding: 20,
    maxWidth: 600,
    // height: 600,
    boxShadow: '0px 1px 5px 0px rgba(107, 107, 107, 1), 0px 2px 2px 0px rgba(107, 107, 107, 0.9), 0px 3px 1px -2px rgba(107, 107, 107, 0.8)',
    display: 'grid',
    gridRowGap: 10,
    overflow: 'auto',
    '@media screen and (max-width: 600px)': {
      display:'flex',
      'flex-direction': 'column',
      margin: '15% auto 0 auto',  
      }
  },
  customContainer:{
    padding:'none',
    boxShadow:'none'
  },
  register: {
    margin: '5% auto 0 auto',
    maxWidth: '800px',
    height: '85vh',
    padding: '0 20px',
    boxShadow: '0px 1px 5px 0px rgba(107, 107, 107, 1), 0px 2px 2px 0px rgba(107, 107, 107, 0.9), 0px 3px 1px -2px rgba(107, 107, 107, 0.8)',
    display: 'grid',
    gridTemplateRows: '50px auto'
  },
  confirmBtn: {
    justifySelf: 'center',
    alignSelf: 'center',
    margin: '20px auto',
  },
  logo: {
    display: 'flex',
    justifyContent:'center',
    alignItems: 'center',
  },
  objectiveEdLogo: {
    width:'300px',
    maxHeight:"150px",
    '@media screen and (max-width: 600px)': {
      width:'50vw',
      maxHeight:"30vw",
      }
  },
  buddyLogo:{
    width:'200px',
    height:"200px",
    '@media screen and (max-width: 600px)': {
      width:'40vw',
      height:"40vw",
      }
  },
  welcome: {
    justifySelf: 'center',
    display: 'grid',
    gridRowGap: '20px'
  },
  personalInfo: {
    marginTop: 40,
    display: 'grid',
  },
  info: {
    marginTop: 40,
    justifySelf: 'center',
    display: 'grid'
  },
  subtitle: {
    margin: '0 50px'
  },
  payments: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: '40px',
    margin: '20px 50px 0 50px',
    justifySelf: 'space-between'
  },
  priceCard: {
    '& .title':{
      textAlign: 'center',
      margin: 15
    },
    '& .divider': {
      margin: '0 40px 10px 40px'
    },
    '& .price': {
      textAlign: 'center',
      marginTop: 20
    },
    '& .priceSub': {
      textAlign: 'center',
      marginBottom: 20
    },
    '& .list': {
      marginLeft: 30
    }
  },
  name: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: '10px'
  },
  steps: {
    alignSelf: 'start'
  },
  buttons: {
    alignSelf: 'end',
    marginBottom: 25,
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'space-between'
  },
  back: {
    alignSelf: 'center',
  },
  learnMore: {
    alignSelf: 'center',
    margin: '0 auto'
  },
  skip: {
    alignSelf: 'center',
  },
  next: {
    alignSelf: 'center',
  },
  login: {
    alignSelf: 'end',
    marginBottom: 25,
    display: 'grid',
    gridAutoFlow: 'column',
    margin: '0 auto'
  },
  completed: {
    alignSelf: 'end'
  },
  error: {
    backgroundColor: '#f44336'
  },
  success: {
    backgroundColor: '#43a047'
  },
  link: {
    textDecoration: 'none',
    color: 'inherit'
  },
  pic: {
    justifySelf:'center'
  },
  loading: {
    alignSelf: 'end',
    justifySelf: 'center'
  },
  child: {
    display: 'grid',
    gridTemplateColumns: '8fr 8fr 1fr',
    gridColumnGap: '10px',
    alignItems: 'center'
  },
  terms: {
    padding: 20,
    maxHeight: 400,
    overflow: 'scroll',
    alignSelf: 'center',
    border: '1px solid black'
  },
  toiButtons: {
    display: 'grid',
    gridAutoFlow: 'row',
    justifySelf: 'center',
    alignItems: 'center',
    marginTop: 20
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  table: {
    width: '100%'
  },
  iFrame: {
    '& .c21': {
      padding: '20 60'
    }
  },
  studentEnroll: {
    margin: '15vh auto',
    padding: 20,
    maxWidth: 1200,
    boxShadow: '0px 1px 5px 0px rgba(107, 107, 107, 1), 0px 2px 2px 0px rgba(107, 107, 107, 0.9), 0px 3px 1px -2px rgba(107, 107, 107, 0.8)',
    display: 'grid',
    textAlign: 'center'
  },
  fullHeight: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    gridRowGap: 10,
  },
  enroll: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
  },
  enrollTitle: {
    justifySelf: 'center'
  },
  enrollBtn: {
    marginTop: 20,
    alignSelf: 'end',
    justifySelf: 'center'
  },
  pricing: {
    display: 'grid',
    border: '2px solid #310073',
    width: '80%',
    margin: '0 auto',
    padding: 20,
    marginTop: 20,
    borderRadius: 10,
    '& .title': {
      gridRow: 1,
      justifySelf: 'center',
      fontWeight: 'bold',
      color: '#310073',
    },
    '& .math': {
      gridRow: 2,
      justifySelf: 'center',
      color: '#310073'
    },
    '& .price': {
      color: '#00b2e1',
},
    '& .total': {
      color: '#00b455'
    }
  },
  inputLine: {
    marginBottom: 20
  },
  title: {
    marginBottom: 20
  },
  purchase: {
    padding: 20,
    display: 'grid'
  },
  loadingGif: {
    width: 0,
  },
  center: {
    textAlign: 'center'
  },
  student: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridAutoFlow: 'column',
    padding: 20,
    gridColumnGap: 10,
    gridRowGap: 10,
  },
  names: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridColumnGap: 10,
    gridRow: 1
  },
  password: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridColumnGap: 10,
    gridRow: 2
  },
  studentDetails: {
    display: 'grid',
    gridRow: '1 /span 2',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridColumnGap: 10,
  },
  titleLine: {
    gridRow: 1,
    alignSelf: 'center',
  },
  studentLine: {
    gridRow: 2,
    alignSelf: 'center',
  },
  studentBtns: {
    display: 'grid',
    gridRow: '1 /span 2',
    gridAutoFlow: 'row',
    alignSelf: 'center',
    justifySelf: 'end'
  },
  nextBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row'
  }
}