import { FETCH_POSTS, CREATE_POST, DELETE_POST, UPDATE_POST, GET_POST, GET_FEED, GET_TAGS } from '../../constants';

export const posts = (state = [], action) => {
  switch (action.type){
    case FETCH_POSTS:
      return action.posts;
    case CREATE_POST:
      return [...state, action.post];
    case DELETE_POST:
      return state.filter(post => post.key !== action.post.key);
    case UPDATE_POST:
      return state.map(post =>  post.key === action.post.key ? action.post : post);
    default:
      return state;
  }
};

export const post = (state = {}, action) => {
  switch (action.type){
    case GET_POST:
      return action.post;
    default:
      return state;
  }
};

export const feed = (state = [], action) => {
  switch (action.type){
    case GET_FEED:
      return action.feed;
    case UPDATE_POST:
      return state.map(post =>  post.key === action.post.key ? action.post : post);
    default:
      return state;
  }
};

export const tags = (state = [], action) => {
  switch (action.type){
    case GET_TAGS:
      return action.tags;
    default:
      return state;
  }
};