import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import styles from '../../../styles/browseStory.js';
import WizardToggleButton from '../Wizards/WizardToggleButton';
import uuid from "uuid";
import {
    Tooltip,
    withStyles,
    Tab,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Button,
    Box,
    Dialog,
    Paper,
    Card,
    CardHeader,
    CardContent,
    Typography,
    CircularProgress,
} from '@material-ui/core';
import { CheckCircle, Close, CloudDownload, Search } from '@material-ui/icons';
import { stableSort, getSorting } from '../../../helpers';
import ImportBooks from './ImportBooks.jsx';
import LoadingModal from '../Student/LoadingModal';
import DetailsWizard from '../../../Social/NetflixSlider/DetailsWizard.js';

class BookShareTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            order: 'asc',
            orderBy: 'difficulty',
            page: 0,
            rowsPerPage: 5,
            imported: [],
            bookShareUser: [],
            selectedBook: null,
            allReadyImportedBooks: [],
            importKey: '',
            showSelectedRow: null,
            openModal: false,
            modalType: '',
            showLoading: false
        }
    }

    closeModal = () => {
        this.setState({ openModal: false, modalType: '' })
    }
    componentDidUpdate(prevProps) {
        if (this.props.data != prevProps.data) {
            this.setState({
                page: 0
            });
        }
        if (this.props.booksImport && prevProps.booksImport != this.props.booksImport &&
            this.props.booksImport.studentBookImported) {
                this.props.closeWizard()
            if ((this.state.selectedBook && this.state.selectedBook.storyDataId == null)
                || (this.state.showSelectedRow && this.state.showSelectedRow.storyDataId == null))
                this.handleBookshareImportSuccess();
        }
        if (this.props.downloadBook && this.props.downloadBook != prevProps.downloadBook && this.props.downloadBook.isBookDowloaded)
            this.handleBookshareImportSuccess();
    }
    handleChildUnmount = () => {
        this.setState({ selectedBook: null, showSelectedRow: null });
    }
    handleSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';
        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }
        this.setState({ order, orderBy });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleSelectedBook = (event, currentBook) => {
        event.stopPropagation();
        this.setState({
            selectedBook: currentBook,
            importKey: uuid.v4(),
            showLoading: true
        });
    }
    handleBookshareImportSuccess = () => {
        if (this.props.bookShareSearchToggle) {
            this.props.openModal()
            // this.setState(state => ({ openModal: true, modalType: 'Downloading', showLoading: false }));
        }
    }
    handleImportedBook = (currentBook) => {
        if (!this.state.allReadyImportedBooks.includes(currentBook.id)) {
            {
                this.setState({
                    allReadyImportedBooks: [...this.state.allReadyImportedBooks, currentBook.title]
                });
            }
        }
    }
    handleRowClick = (currentBook) => {
        this.setState({
            showSelectedRow: currentBook
        })
    }
    closeDetailsWizard = () => {
        this.setState({ showSelectedRow: false });
    }
    closeLoading = () => {
        this.setState({ showLoading: false });
    }

    render() {
        const { classes, rows, data, imported, noResultFound, changeSearchData,
            storyId, books, toggleSearch, bookShareSearchToggle, searchFromBookShare, bookShareRequestBooks, hasBookShareCredentials } = this.props;
        const { orderBy, order, page, rowsPerPage, showSelectedRow, showLoading, selectedBook } = this.state;
        return (
            <>
                <Table>
                    <TableHead>
                        <TableRow>
                            {this.props.includeTitleImage ?
                                <TableCell
                                    key={'titleImg'}
                                >
                                    Title Image
                                </TableCell> : <div></div>
                            }
                            {rows.length &&
                                rows.map(row => {
                                    return (
                                        <TableCell
                                            key={row.id}
                                            sortDirection={orderBy === row.id ? order : false}
                                        >
                                            <Tooltip
                                                title={<FormattedMessage id='General.sort' />}
                                                placement='bottom-start'
                                                enterDelay={300}
                                            >
                                                <TableSortLabel
                                                    active={orderBy === row.id}
                                                    direction={order}
                                                    onClick={(ev) => this.handleSort(ev, row.id)}
                                                >
                                                    {row.label}
                                                </TableSortLabel>
                                            </Tooltip>
                                        </TableCell>
                                    )
                                })
                            }
                            <TableCell key='status'>
                                <FormattedMessage id='Wizard.Braille.import' />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data && data.length > 0 ?
                            stableSort(data, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map(_data => {
                                    return (
                                        <TableRow
                                            hover
                                            key={_data.id}
                                            onClick={() => this.handleRowClick(_data)}
                                        >
                                            {this.props.includeTitleImage ?
                                                <TableCell>
                                                    {
                                                        _data.titleImage && _data.titleImage !== ' '
                                                            ? <img src={_data.titleImage} width={100} height={150} alt={_data.name} />
                                                            : 'NA'
                                                    }
                                                </TableCell>
                                                : this.props.lexileTabClicked ?
                                                    <TableCell>
                                                        {
                                                            _data.titleImageSrc && _data.titleImageSrc !== ' '
                                                                ? <img src={_data.titleImageSrc} width={100} height={150} alt={_data.name} />
                                                                : 'N/A'
                                                        }
                                                    </TableCell>
                                                    : null}
                                            {rows.length &&
                                                rows.map((row, index) => {
                                                    let cellData = _data[row.id] && _data[row.id] !== ' '
                                                        ? _data[row.id]
                                                        : 'N/A';
                                                    return (
                                                        <TableCell
                                                            key={index}
                                                            dangerouslySetInnerHTML={{ __html: cellData }}
                                                        >
                                                        </TableCell>
                                                    )
                                                })
                                            }
                                            <TableCell align='left'>
                                                    {
                                                        (bookShareRequestBooks && bookShareRequestBooks.data.some((b) => (b.id == _data.id)))
                                                            || (books && books.some((b) => (b.name == _data.name || b.name == _data.title) && _data.name != null))
                                                            ? <IconButton><CheckCircle /></IconButton>
                                                            : showLoading && selectedBook == _data ?
                                                                <CircularProgress size={30} /> : 
                                                                <IconButton onClick={(e) => this.handleSelectedBook(e, _data)}>
                                                                    <CloudDownload />
                                                                </IconButton>
                                                    }
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            : noResultFound &&
                            <TableRow>
                                <TableCell />
                                <TableCell>
                                    {(!hasBookShareCredentials && searchFromBookShare) ? <h4>Bookshare login required before search</h4> :  <h4>No Results Found</h4>}
                                </TableCell>
                                <TableCell />
                                <TableCell />
                                <TableCell />
                            </TableRow>
                        }
                        {
                             changeSearchData && !bookShareSearchToggle && 
                            <TableRow>
                                <TableCell colSpan={rows.length + 2} align='center'>
                                    <WizardToggleButton
                                        openWizard={() => { changeSearchData(true) }}
                                        text={<FormattedMessage id='Databanks.importBookShareBtn' />}
                                    />
                                </TableCell>
                            </TableRow>
                        }
                        {
                            imported && imported.length > 0
                                ? <TableRow>
                                    <TableCell />
                                    <TableCell />
                                    <TableCell align='right'>
                                        <Button color='primary' variant='contained' onClick={this.closeWizard}>
                                            <FormattedMessage id='General.done' />
                                        </Button>
                                    </TableCell>
                                </TableRow>
                                : <TableRow />
                        }
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15]}
                    component='div'
                    count={data.length}
                    labelRowsPerPage={<FormattedMessage id='Datatable.rowsPerPage' />}
                    rowsPerPage={this.state.rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                    }}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                />

                {
                    this.state.selectedBook && <ImportBooks storyId={storyId} story={this.state.selectedBook}
                        handleImportedBook={this.handleImportedBook}
                        key={this.state.importKey}
                        handleUnmount={this.handleChildUnmount}
                        searchFromBookShare={searchFromBookShare}
                        closeLoading={this.closeLoading} />
                }
                {showSelectedRow &&
                    <DetailsWizard
                        storyId={storyId}
                        story={this.state.showSelectedRow}
                        open={true}
                        searchToggle={toggleSearch}
                        exitFunc={this.closeDetailsWizard}
                    />}
                {
                    this.state.openModal &&
                    <LoadingModal openLoading={this.state.openModal} type={this.state.modalType} exitFunc={this.closeModal} />
                }
            </>
        )
    }
}

const mapState = ({ platform: { books, bookShareList, storyList, skillDatabanks, student, bookShareUsers, importBooks, booksImport, downloadBook, bookShareRequestBooks }, shared: { user } }) => {
    return {
        books,
        bookShareList,
        storyList,
        skillDatabanks,
        user,
        booksImport,
        bookShareUsers,
        student,
        importBooks,
        downloadBook,
        bookShareRequestBooks

    }
};

export default connect(mapState)(withStyles(styles)(BookShareTable));