export default {
  header: {
    gridColumn: 1,
    gridRow: 1,
    flex: '0 0 auto',
    fontSize: '24px',
    color: '#fff',
    marginLeft: '20px',
  },
  toolbar: {
    display: 'grid',
    gridTemplateColumns: '8fr 2fr',
    gridTemplateRows: '1fr',
    borderRadius: '5px 5px 0 0',
    backgroundColor: '#00729b',
    boxShadow: ' 0px 3px 3px #6b6b6b',
  },
  list: {
    borderRadius: '5px',
    overflowX: 'auto',
  },
  gameList: {
    borderRadius: '5px',
    overflowX: 'auto',
    gridColumn: '1/ span 2'
  },
  tutorialList: {
    borderRadius: '5px',
    overflowX: 'auto',
    gridColumn: '1/ span 2'
  },
  actions: {
    gridColumn: 2,
    gridRow: 1,
    justifySelf: 'end',
    marginRight: '20px',
  },
  searchBar: {
    color: '#000',
    fontWeight: 600,
    width: '320px',
    marginLeft: '10px',
    backgroundColor: 'white',
    borderRadius: '4px',
    height: 40
  },
  select: {
    color: '#fff',
    fontWeight: 800,
    width: '120px',
    textAlign: 'center',
  },
  icon: {
    color: '#000',
    fontWeight: 800,
  },
  searchIcon: {
    color: '#fff',
  },
  empty: {
    textAlign: 'center'
  },
  hoverRow: {
    '&:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0.07)'
    },
    cursor: 'pointer'
  },
  cell: {
    maxWidth: 250,
    wordWrap: 'break-word'
  },
  tableWrapper: {
    overflowX: 'auto',
    // width: 800
  },
  buttons: {
    // maxWidth: 250,
    wordWrap: 'break-word',
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'end',
    alignContent: 'center'
  },
  disabled: {},
  error: {},
  focused: {},
  hidden: {
    maxWidth: 0,
    height: 0
  },
  speedDial: {
    overflow: 'auto'
  },
  exitIcon: {
    float: 'right',
    color: '#fff',
    width: '48px'
  },
}