export default {
  "General.yes": "Si",
  "General.no": "No",
  "General.easy": "Fácil",
  "General.medium": "Medio",
  "General.hard": "Duro",
  "General.firstName": "Nombre de pila",
  "General.lastName": "Apellido",
  "General.role": "Papel",
  "General.name": "Nombre",
  "General.id": "Id",
  "General.area": "Zona",
  "General.timed": "Cronometrado?",
  "General.description": "Descripción",
  "General.difficulty": "Dificultad",
  "General.email": "Email",
  "General.edit": "Modifier",
  "General.add": "Añadir",
  "General.delete": "Borrar",
  "General.save": "Guardar",
  "General.cancel": "Anular",
  "General.close": "Cerca",
  "General.targetPercentage": "Porcentaje Objetivo",
  "General.targetPrompts": "Indicaciones de Objetivo",
  "General.active": "¿Activo?",
  "General.active2": "Activo",
  "General.inactive": "Inactivo",
  "General.sort": "Ordenar",
  "General.select": "Seleccionar",
  "General.games": "Juegos",
  "General.game": "Juego",
  "General.next": "Siguiente",
  "General.levels": "Niveles",
  "General.back": "Volver",
  "General.submit": "Enviar",
  "General.test": "Probar",
  "General.retest": "Volver a Probar",
  "General.type": "Tipo",
  "General.search": "Buscar",
  "General.text": "Texto",
  "General.comment": "Comentario",
  "General.done": "Terminado",
  "General.status": "Estado",
  "General.studentCount": "Recuento de Estudiantes",
  "General.studentsEnabled": "Estudiantes habilitados para iniciar sesión",
  "General.activate": "Activar",
  "General.suspend": "Suspender",
  "General.levelVars": "Variables de Nivel",
  "General.value": "Valor",
  "General.share": "Compartir",
  "General.internal": "Interno",
  "General.customer": "Cliente",
  "General.pilot": "Piloto",
  "General.sales": "Sales",
  "General.viewLess": "Mostrar Menos",
  "General.viewMore": "Mostrar más",
  "General.view": "Mostrar",
  "General.join": "inscribirse",
  "General.download": "download",
  "General.continue": "Continue",
  "General.primaryEmail": "Correo electrónico principal",
  "General.secondaryEmail": "Email secundario",
  "General.street1": "Dirección",
  "General.street2": "Dirección 2",
  "General.state": "Provincia del estado",
  "General.postalCode": "Código postal",
  "General.country": "País",
  "General.city": "Ciudad",
  "General.rate": "Tarifa",
  "General.startDate": "Fecha de inicio",
  "General.cycle": "Ciclo de facturación",
  "General.ok": "Bueno",
  "General.my": "Mi",
  "General.billableStudent": "Recuento facturable de estudiantes",
  "General.studentsBillable": "Recuento de facturación del estudiante",
  "General.required": "Todos los campos son obligatorios",
  "404.1": "Uh oh, algo salió mal.",
  "404.2": "Parece que te has encontrado con un error.",
  "404.3": "Vuelva a intentarlo o use el botón de inicio en el encabezado para volver a su página de inicio.",
  "Datatable.rowsPerPage": "Filas por página:",
  "Datatable.showEdit": "Mostrar opciones",
  "Datatable.hideEdit": "Ocultar opciones",
  "Datatable.support": "Usuarios de Soporte",
  "Datatable.resetPW": "Reiniciar",
  "Datatable.resetConfirm": "¿Estás seguro de que quieres restablecer",
  "Datatable.resetConfirm2": "la contraseña?",
  "Datatable.levels": "Plantillas de Nivel",
  "Header.platform": "Aplicación",
  "Header.network": "Red profesional",
  "Header.profile": "Mi perfil",
  "Header.logout": "Desconectar",
  "Header.support": "Cuenta de Soporte",
  "Sidebar.title": "Administrador",
  "Sidebar.title.home": "Página Principal",
  "Sidebar.dashboard": "Tablero",
  "Sidebar.students": "Estudiantes",
  "Sidebar.progress": "Progreso",
  "Sidebar.staff": "Personal",
  "Sidebar.schools": "Escuelas",
  "Sidebar.databanks": "Datos",
  "Sidebar.config": "Config",
  "Sidebar.profile": "Perfil",
  "Sidebar.account": "Cuenta",
  "Sidebar.accounts": "Cuentas",
  "Sidebar.users": "Usuarios",
  "Sidebar.guardians": "Guardianes",
  "Sidebar.goals": "Objetivo",
  "Sidebar.books": "Libros",
  "Sidebar.games": "Juegos",
  "Sidebar.help": "ayuda",
  "Sidebar.games.entertainment": "Juego de Entretenimiento",
  "Sidebar.backToStudents": "Volver a los estudiantes",
  "Sidebar.backToStaff": "Volver al personal",
  "Sidebar.backTo": "Volver a",
  "Sidebar.student's": "estudiantes",
  "Login.button": "Iniciar Sesión",
  "Login.username": "Nombre de Usuario",
  "Login.password": "Contraseña",
  "Login.confirmPassword": "Confirmar contraseña",
  "Login.mustMatch": "Las contraseñas deben coincidir",
  "Login.resetText": "¿Se te olvidó tu contraseña?",
  "Login.resetLink": "Pincha aquí para restaurar tu contraseña",
  "Dashboard.title": "Tablero",
  "Dashboard.possessive": "Tablero",
  "StaffMembers.title": "Los miembros del personal",
  "StaffMembers.AddBtn": "Añadir personal",
  "Students.title": "Estudiantes",
  "Students.DeletedStudents": "Estudiantes eliminados",
  "Students.AddBtn": "Añadir estudiante",
  "Databanks.title": "Datos",
  "Databanks.subtitle": "En esta página encontrará una lista de todos sus objetivos, habilidades y hojas en braille. Puede agregar sus propios objetivos, habilidades y hojas en braille utilizando nuestros creadores paso a paso, o usted puede importar objetivos y habilidades de sus grupos ",
  "Databanks.goalDB": "Objetivo Datos",
  "Databanks.addGoalDB": "Añadir Objetivo Datos",
  "Databanks.importGoal": "importar Objetivo",
  "Databanks.sampleGoal": "muestra objetivos de muestra",
  "Databanks.skillDB": "Habilidad Datos",
  "Databanks.addSkillDB": "Añadir habilidad Datos",
  "Databanks.importSkill": "importar habilidad",
  "Databanks.brailleSheets": "Braille Páginas",
  "Databanks.importSheet": "importar Braille Página",
  "Databanks.addBrailleSheets": "Añadir Braille página",
  "Databanks.advanced": "Configuración Avanzada",
  "Chart.hoursPlayed.title": "Horas Jugadas",
  "Chart.hoursPlayed.bar": "Progreso por Estudiante",
  "Chart.percentLine.title": "Porcentaje al Objetivo",
  "Chart.percentBar.title": "Porcentaje al Objetivo de Habilidad Activa",
  "Chart.empty": "No hay datos de estudiantes para mostrar",
  "Chart.empty.noStudents": "Una vez que su administrador haya asignado estudiantes a su cuenta, verá su progreso aquí.",
  "Chart.allTime": "todos",
  "Chart.days": "dias",
  "Goals.title": "Objetivo",
  "Goals.for": "Objetivo para",
  "Goals.add": "Añadir Objetivo",
  "Goals.allGoals": "Todos los Objetivos",
  "Goal.Chart.empty": "Por favor, seleccione una habilidad para ver los datos de puntuación...",
  "Skill.add": "añadir habilidad a la objetivo",
  "Skill.for": "Habilidades para",
  "Profile.settings": "Configuración de Perfil",
  "Profile.personal": "Información Personal",
  "Profile.login": "¿Debería este usuario poder iniciar sesión?",
  "Profile.studentLogin": "¿Debería este estudiante poder iniciar sesión?",
  "Profile.helperText": "El estudiante debe tener un Gamertag para iniciar sesión",
  "Profile.StudentConfig": "Configuración del estudiante",
  "Profile.backgroundMusicLevel": "Nivel de música de fondo",
  "Profile.curtainOpacity": "Cortina de opacidad",
  "Profile.curtain": "Cortina?",
  "Profile.addFreeTime": "añadir tiempo libre",
  "Profile.changeFreeTime": "cambiar tiempo libre",
  "Profile.playTime": "Tiempo de Juego",
  "Profile.entertainmentTime": "Tiempo de Entretenimiento",
  "Profile.level": "Nivel Hasta Recompensa",
  "Profile.levels": "Niveles Hasta Recompensa",
  "Profile.braille": "Maestro braille",
  "Profile.AT": "Tecnología de asistencia. Especialista",
  "Profile.OM": "O&M certificado",
  "Profile.TVI": "TVI certificada",
  "Profile.specialEd": "Profesor de educación especial",
  "Profile.publicSchool": "Distrito de escuelas públicas",
  "Profile.privateSchool": "Escuela privada",
  "Profile.DOE": "DOE estatal",
  "Profile.NFP": "Agencia sin fines de lucro",
  "Profile.FP": "Agencia con fines de lucro",
  "Profile.self": "Trabajadores por cuenta propia",
  "Support.createAccount": "Crear cuenta",
  "Support.createUser": "Crear usuario",
  "EditStaff.promoteDescription": "Los administradores son usuarios que pueden agregar otros maestros y personal. Obtienen acceso a la página en la que se encuentra actualmente. Esta acción no es reversible.",
  "EditStaff.promote": "Ascender a administrador",
  "EditStaff.cannotPromote": "Este usuario pertenece a varias organizaciones y no puede ser promovido a administrador",
  "Wizard.title": "Creador",
  "Wizard.skillDB.title": "Datos de Habilidades Creador",
  "Wizard.restore.title": "Restaurar estudiantes",
  "Wizard.restore.description": "Puede restaurar o eliminar permanentemente a los estudiantes aquí.",
  "Wizard.skillDB": "¡Bienvenido al creador del banco de datos de habilidades! Esto lo guiará a través del proceso de creación de un nuevo banco de datos de habilidades para un juego.",
  "Wizard.loading": "Please wait while the server handles your request.",
  "Wizard.skillDB.subtitle1": "Por favor ingrese el nombre y descripción de la base de datos de habilidades",
  "Wizard.skillDB.subtitle2": "Por favor, seleccione el juego para adjuntar este banco de habilidades a",
  "Wizard.skillDB.levelSelect": "Seleccione la plantilla de nivel que desea utilizar",
  "Wizard.skillDB.levelConfig": "Configurar este nivel",
  "Wizard.Support.title": "Creador de Cuenta",
  "Wizard.Support": "¡Bienvenido al creador de cuentas! Esto lo guiará a través del proceso de creación de una nueva cuenta.",
  "Wizard.Support.shortName": "Nombre corto",
  "Wizard.Support.studentCount": "Recuento de estudiantes",
  "Wizard.Support.parentAccount": "ID de la cuenta de los padres",
  "Wizard.SupportUser.title": "Creador de usuarios de soporte",
  "Wizard.SupportUser": "¡Bienvenido al creador de usuarios de soporte! Esto lo guiará a través del proceso de creación de un nuevo usuario de soporte.",
  "Wizard.Barnyard.direction": "Tipo de direccion",
  "Wizard.Barnyard.prompt": "¿Siempre preguntar?",
  "Wizard.Barnyard.mistakes": "Número de errores antes de incitar",
  "Wizard.Barnyard.animal": "Número de solicitudes de animales al inicio",
  "Wizard.AudioMemory.collection": "Colección de sonidos para usar",
  "Wizard.AudioMemory.animals": "Animales",
  "Wizard.AudioMemory.instruments": "Instrumentos",
  "Wizard.AudioMemory.vehicles": "Vehículos",
  "Wizard.AudioMemory.toys": "Toys",
  "Wizard.AudioMemory.indoors": "Dentro",
  "Wizard.AudioMemory.length": "Duración del juego",
  "Wizard.AudioMemory.opponent": "Dificultad del oponente",
  "Wizard.AudioMemory.helperText": "El guepardo es más fácil, la tortuga es más difícil",
  "Wizard.AudioMemory.matches": "Número de partidos en pantalla",
  "Wizard.AudioMemory.error": "Debe ser un número entre 1-8",
  "Wizard.AudioMemory.sound": "Sonido a sonido",
  "Wizard.AudioMemory.tap": "Control del grifo",
  "Wizard.Sounds.error": "El número máximo de palabras es 6",
  "Wizard.Sounds.numWords": "Número de palabras",
  "Wizard.Sounds.unlimited": "¿Juntas ilimitadas?",
  "Wizard.Sounds.sounds": "Numero de sonidos",
  "Wizard.Sounds.errorSound": "El número máximo de sonidos es 6",
  "Wizard.Sounds.numBoards": "Número de tableros en una ronda",
  "Wizard.Gesture.title": "Speed Gesture",
  "Wizard.Gesture.subtitle": "Speed Gesture is a game where students are asked to perform gestures as they are announced.",
  "Wizard.Gesture.length": "Longitud de la Ronda",
  "Wizard.Gesture.music": "Música",
  "Wizard.Gesture.japan": "Japonés",
  "Wizard.Gesture.random": "Aleatorio",
  "Wizard.Gesture.numRounds": "Número de rondas",
  "Wizard.Gesture.startingCount": "Conteo de gestos iniciales",
  "Wizard.Gesture.gestures": "Gestos que te gustaría usar.",
  "Wizard.Simon.title": "Simon",
  "Wizard.Simon.subtitle": "Simon es un juego que requiere que los estudiantes recuerden una lista de gestos y los ejecuten en orden cuando se les solicite",
  "Wizard.BrailleQuiz.title": "Braille Quiz",
  "Wizard.BrailleQuiz.subtitle": "Braille Quiz es un juego donde los estudiantes deben responder preguntas de estilo cuestionario tocando dos veces la palabra correcta en la hoja de braille. Puede agregar sus propias preguntas o importar preguntas.",
  "Wizard.BrailleQuiz.select": "Seleccione una hoja braille para usar",
  "Wizard.BrailleQuiz.empty": "No se ha seleccionado ninguna hoja de Braille",
  "Wizard.BrailleQuiz.numQuestions": "Preguntas por juego",
  "Wizard.BrailleQuiz.error": "¡Las preguntas por juego deben ser menos que el total de preguntas!",
  "Wizard.BrailleQuiz.time": "Tiempo por pregunta: (en segundos)",
  "Wizard.BrailleQuiz.shuffle": "¿Aleatorizar el orden de las preguntas?",
  "Wizard.BrailleQuiz.numOpponents": "Número de oponentes",
  "Wizard.BrailleQuiz.question": "Pregunta",
  "Wizard.BrailleQuiz.answer": "Solución",
  "Wizard.BrailleQuiz.import": "Importa una pregunta",
  "Wizard.BrailleQuiz.addQuestion": "Añadir una pregunta",
  "Wizard.BrailleQuiz.importText": "Utilice los grupos de su hoja a continuación para buscar preguntas.",
  "Wizard.BrailleQuiz.emptyQuestions": "Il n'y a pas de questions pour ce groupe.",
  "Wizard.Scramble.title": "Braille Scramble",
  "Wizard.Scramble.subtitle": "Braille Scramble es un juego de ortografía y vocabulario donde a los estudiantes se les presentan varias letras y deben usar esas letras para encontrar tantas palabras como sea posible.",
  "Wizard.Scramble.set": "Conjunto de palabras",
  "Wizard.Scramble.number": "Número de palabras hasta la próxima revuelta",
  "Wizard.Scramble.seconds": "Segundos por Scramble",
  "Wizard.WordHunt.title": "Braille Hunt",
  "Wizard.WordHunt.subtitle": "Braille Hunt es un juego donde los estudiantes deben encontrar y seleccionar palabras en función de las preguntas que elija.",
  "Wizard.WordHunt.find": "El estudiante debe encontrar",
  "Wizard.WordHunt.one": "uno",
  "Wizard.WordHunt.all": "todos",
  "Wizard.WordHunt.words": "palabras",
  "Wizard.WordHunt.word": "palabra",
  "Wizard.WordHunt.letters": "letras",
  "Wizard.WordHunt.letter": "letra",
  "Wizard.WordHunt.starting": "empezando con",
  "Wizard.WordHunt.ending": "terminando en",
  "Wizard.WordHunt.containing": "que contiene",
  "Wizard.WordHunt.need": "Necesitarán",
  "Wizard.WordHunt.correct": "correcto para seguir adelante",
  "Wizard.WordHunt.feedback": "¿Cómo se debe dar retroalimentación?",
  "Wizard.WordHunt.missed": "Lee solo las respuestas perdidas",
  "Wizard.WordHunt.readAll": "Todas las palabras leen",
  "Wizard.WordHunt.readNum": "Leer el número de perdidas / correctas",
  "Wizard.Braille.contractions": "Braille Sheets Contracciones",
  "Wizard.Braille.upperCase": "¿Permitir letras mayúsculas?",
  "Wizard.Braille.selectContract": "Seleccione las contracciones que le gustaría usar",
  "Wizard.Braille.check": "elegir todos",
  "Wizard.Braille.uncheck": "El escogido",
  "Wizard.Braille.sheet": "Braille Página",
  "Wizard.Braille.import": "Importar",
  "Wizard.Braille": "¡Bienvenido al creador de la hoja de Braille! Esto lo guiará a través del proceso de creación de una nueva Hoja Braille.",
  "Wizard.Braille.page2": "En esta página, ingrese información para la hoja en braille que desea crear y luego seleccione los juegos que desea usar con esta hoja en braille.",
  "Wizard.Braille.text": "Por favor ingrese el texto para su hoja de Braille",
  "Wizard.Braille.error": "El texto es demasiado largo.",
  "Wizard.Braille.contract": "Incluir contracciones",
  "Wizard.Braille.games": "Juegos que utilizan esta página",
  "Wizard.Goal.title": "Creador de Objetivo",
  "Wizard.Goal": "¡Bienvenido al creador de objetivos! Esto lo guiará a través del proceso de creación de una nueva objetivo para un estudiante.",
  "Wizard.GoalDB.title": "Creador de Objetivo Datos",
  "Wizard.GoalDB": "¡Bienvenido al creador de objetivo datos! Esto lo guiará a través del proceso de creación de una nueva objetivo dato.",
  "Wizard.Skill": "Añadir una Habilidad",
  "Wizard.Skill.select": "Por favor, seleccione una habilidad de la tabla de abajo",
  "Wizard.Skill.different": "Seleccione una habilidad diferente",
  "Wizard.GameField": "Game Field Map Wizard",
  "Wizard.Staff.invite": "Invitar",
  "Wizard.Staff.inviteText": "Por favor ingrese el correo electrónico para enviar la invitación a",
  "Wizard.Staff.title": "Personal Creador",
  "Wizard.Staff": "¡Bienvenido al creador de miembros del personal! Esto lo guiará a través del proceso de creación de un nuevo miembro del personal.",
  "Wizard.Staff.sent": "¡Invitación enviada!",
  "Wizard.Staff.error": "Se ha producido un error al enviar la invitación. Inténtalo de nuevo.",
  "Wizard.Staff.select": "Por favor seleccione un especialista de la tabla de abajo",
  "Wizard.Staff.empty": "¿No ves a tu especialista?",
  "Wizard.Staff.inviteLink": "Invítalos aquí",
  "Wizard.Staff.role": "ingresa el rol del usuario",
  "Wizard.Staff.email": "Por favor ingrese el correo electrónico del personal",
  "Wizard.Staff.email2": "Por favor ingrese el correo electrónico del especialista",
  "Wizard.Staff.emailSent": "Se enviará un mensaje al correo electrónico dado con instrucciones sobre cómo configurar una contraseña para la cuenta.",
  "Wizard.Staff.confirm": "¡Asegúrese de que todo esté correcto antes de enviarlo!",
  "Wizard.Staff.specialist": "¡Parece que ya hay un especialista en nuestro sistema con esa información! Hemos subido su cuenta, y adjuntaremos a los estudiantes que ha enumerado para ellos.",
  "Wizard.Staff.selectStudent": "Seleccione los estudiantes que desea agregar",
  "Wizard.Maps.questionsPerRound": "Questions Per Round",
  "Wizard.Maps.PinQuestion": "Pin",
  "Wizard.WordHunt.IntersectionQuestion": "Intersection",
  "Wizard.Maps.StreetQuestion": "Street",
  "Wizard.Maps.RegionQuestion": "Region",
  "Wizard.Maps.Type": "What Type of Questions Should Be Asked?",
  "Wizard.Maps.QuestionType": "Question Type",
  "Wizard.Maps.Shuffle": "Shuffle Questions?",
  "Wizard.Maps.Questions": "Questions",
  "Wizard.Maps.Question": "Question",
  "Wizard.Maps.Street": "Street",
  "Wizard.Maps.Intersection1": "First Street",
  "Wizard.Maps.Intersection2": "Second Street",
  "Wizard.Maps.Pin": "Pin",
  "Wizard.Maps.Region":"Region",
  "Wizard.Maps.title": "Map Explore",
  "Wizard.Maps.subtitle": "Map Explore is an educational tool for practicing mental mapping skills. Please provide an address in the bar below to set the center of your map. Then add markers to the map by pressing the add pin button. You can drag and name these pins to make your map more accessible or quiz the student to find certain locations. The student will track streets by running their finger over the map and pins you add. You can zoom in and out with the map but try not to zoom out too far as this makes it harder for the student to track streets and locations.",
  "Wizard.Maps.ModeType": "Game Mode",
  "Wizard.Maps.MapName": "Name of Map",
  "Wizard.Student.restore": "Restaurar estudiante",
  "Wizard.Student.title": "Creador de Estudiantes",
  "Wizard.Student": "¡Bienvenido al Creador de Estudiantes! Esto lo guiará a través del proceso de creación de un nuevo Estudiante.",
  "Wizard.Student.Staff": "Seleccionar personal para la estudiante",
  "Wizard.Level": "¡Bienvenido al creador de plantillas de nivel! Esto lo guiará a través del proceso de creación de una nueva plantilla de nivel.",
  "Wizard.Level.select": "Selecciona el juego para el que estás creando un nivel",
  "Wizard.Level.static": "Estático",
  "Wizard.Level.prompt": "Prompt",
  "Wizard.Level.number": "Número",
  "Wizard.Asteroids.requiredAccuracyAngle": "Ángulo de precisión requerido",
  "Wizard.Asteroids.requiredAccuracyAngle.err": "No puede superar los 180 grados",
  "Wizard.Asteroids.spawnRadius": "Tamaño del campo de asteroides",
  "Wizard.Asteroids.spawnRadius.err": "Debe ser un número entero entre 8 y 20",
  "Wizard.Asteroids.spawnRadius.details": "El radio del círculo alrededor del jugador donde se generan los asteroides",
  "Wizard.Asteroids.radiusRecArc": "Recomendamos un tamaño de 10",
  "Wizard.Asteroids.radiusRecPass": "Recomendamos un tamaño de 15-20",
  "Wizard.Asteroids.speed": "How fast should the Asteroids travel?",
  "Wizard.Asteroids.timeMinToMax": "Tiempo entre min y max",
  "Wizard.Asteroids.timeMinToMax.details": "El número de segundos que tarda el asteroide en pasar entre sus valores mínimo y máximo",
  "Wizard.Asteroids.arcMovement": "¿Usar un movimiento de arco?",
  "Wizard.Asteroids.minMaxAngleArc": "¿Usar un movimiento de arco?",
  "Wizard.Asteroids.minMaxAngleArc.details": "Un valor de 0 dará como resultado un asteroide estacionario. Valor máximo de 180.",
  "Wizard.Asteroids.minMaxAngleArc.err": "Debe ser un número entero entre 0 y 180",
  "Wizard.Asteroids.leftRightLimited": "¿Te gustaría controlar cómo se muestran los asteroides?",
  "Wizard.Asteroids.leftRightLimited.details": "En caso afirmativo, la ubicación del asteroide se basará en la dirección de orientación actual del jugador. Si no, aparecerán al azar",
  "Wizard.Asteroids.leftRatio": "Establecer la proporción de apariencias izquierda / derecha",
  "Wizard.Asteroids.angleOptions": "Opciones de ángulos de apariencia",
  "Wizard.Asteroids.angleOptions.details": "Agregue ángulos separados por comas. Cada ángulo debe estar entre 0 y 180",
  "Wizard.Asteroids.asteroidTimeout": "Cuánto tiempo antes de que desaparezca un asteroide",
  "Wizard.Asteroids.asteroidTimeout.details": "Cuánto tiempo en segundos después de aparecer el asteroide desaparecerá automáticamente",
  "Wizard.Asteroids.maxShotsPerAsteroid": "Disparos máximos por asteroide",
  "Wizard.Asteroids.maxShotsPerAsteroid.details": "Número de disparos en un asteroide determinado antes de que desaparezca automáticamente",
  "Wizard.Asteroids.gameOverType": "¿Qué hace que el juego termine?",
  "Wizard.Asteroids.gameOverType.timeout": "Un tiempo establecido",
  "Wizard.Asteroids.gameOverType.limitedShots": "Una cantidad establecida de disparos",
  "Wizard.Asteroids.gameOverType.limitedAsteroids": "Un número determinado de asteroides",
  "Wizard.Asteroids.gameOverType.missed": "Se perdió un número determinado de asteroides",
  "Wizard.Asteroids.gameOverCount.1": "Duración del juego (en segundos)",
  "Wizard.Asteroids.gameOverCount.2": "Nombre total de coups de feu",
  "Wizard.Asteroids.gameOverCount.3": "Total des astéroïdes vus",
  "Wizard.Asteroids.gameOverCount.4": "Nombre d'astéroïdes manqués",
  "Wizard.Asteroids.gameOverCount.err": "Le nombre maximal de coups par astéroïde ne peut pas dépasser le nombre de coups limité.",
  "Wizard.Account.billing": "Por favor ingrese su información de facturación",
  "Wizard.Game.add": "Agregar juego de entretenimiento",
  "Config.game": "Añadir campo de juego",
  "Config.list": "Añadir lista",
  "Level.title": "Asistente de plantillas de nivel",
  "Level.Audio.timed": "¿Juego a tiempo?",
  "Level.Audio.matches": "Número de partidos en pantalla",
  "Level.Gesture.spawn": "Gesto ponderado engendro",
  "Level.Gesture.difficulty": "Dificultad de progresión",
  "Level.add": "Añadir variables a un",
  "Level.add2": "nivel",
  "Level.addBtn": "añadir nivel",
  "Level.testBtn": "Nivel de Prueba",
  "Level.variables": "Variables",
  "Level.confirm": "Por favor, confirme que todo lo siguiente es correcto",
  "Share.group": "¿Con qué grupo quieres compartir?",
  "Config.title": "Variables de configuración",
  "Tutorial.deactivate": "Restablecer Tutorial",
  "MyGroups.title": "Mis Grupos",
  "Groups.public": "Público",
  "Groups.private": "Privado",
  "GroupDetail.startDate": "Fecha de inicio",
  "SocialGoals.title": "Tendencias Objetivos",
  "MemberList.title": "Miembros",
  "MemberList.invite": "Invitar a los miembros",
  "MemberList.admin": "Administrador",
  "MemberList.mod": "Moderador",
  "MemberList.removeAdmin": "Eliminar estado de administrador",
  "MemberList.removeMod": "Eliminar estado de moderador",
  "MemberList.makeMod": "Hacer moderador",
  "MemberList.makeAdmin": "Hacer Administrador",
  "MemberList.leave": "Dejar grupo",
  "MemberList.remove": "Sacar del grupo",
  "AddPost.title": "Añadir una publicación",
  "AddPost.addTags": "Agregar etiquetas",
  "AddPost.submit": "Enviar",
  "AddGroup.title": "Crear Nuevo Grupo",
  "AddGroup.text": "Los grupos son excelentes para compartir habilidades y estar en contacto con grupos de profesionales como tú. Comparte fotos y videos, conversa, haz metas y más.",
  "AddGroup.name": "Nombra tu grupo",
  "AddGroup.description": "Dale a tu grupo una descripción",
  "AddGroup.invite": "Invitar a los miembros",
  "AddGroup.members": "Ingrese el nombre del miembro...",
  "AddGroup.privacy": "Seleccione el nivel de privacidad",
  "AddReply.comment": "Comenta aqui...",
  "Announcements.title": "Anuncio",
  "Unlike": "A diferencia del objetivo",
  "Like": "Como objetivo",
  "Shared": "Compartido",
  "Group.image.title": "Subir Imagen de Grupo",
  "Group.image.text": "Arrastre una foto al cuadro o use el botón para cambiar la imagen de perfil de su grupo.",
  "Group.image.change": "Cambiar Imagen",
  "Group.startDate": "Fecha de inicio",
  "Group.created": "Creado por",
  "GroupEdit.toggle": "¿Este grupo es privado?",
  "GroupFeed.more": "mostrar más mensajes",
  "Profile.image.title": "Subir imagen de perfil",
  "Profile.image.text": "Arrastre una foto al cuadro o use el botón para cambiar su foto de perfil.",
  "Paddle.goalAdded": "Goal Added!",
  "Paddle.removeStudent": "Remove Student",
  "Paddle.error.first": "First name required",
  "Paddle.error.last": "Last name required",
  "Paddle.error.tag": "User Name required",
  "Paddle.error.unique": "Username must be unique",
  "Paddle.error.password": "Password is required",
  "Paddle.error.match": "Passwords must match",
  "Paddle.enroll": "Enroll Student",
  "Paddle.clear": "Clear Student Data",
  "Paddle.start": "Get Started",
  "Paddle.checkout": "Checkout",
  "Paddle.email": "Subscription Notification Email",
  "Paddle.type": "Subscription Type",
  "Paddle.studentNum": "Number of Students",
  "Paddle.cost": "SUBSCRIPTION COST",
  "Paddle.studentCount.title": "Please enter the email that you would like to receive all subscription details and notifications to and enter the number of students that you would like to enroll:",
  "Paddle.terms": "Please read and accept the terms of use:",
  "Terms.decline": "I decline",
  "Terms.accept": "I accept the terms",
  "SliderListManagement.Add": "Add Story",
  "SliderListManagement.Title": "Story List Management",
  "Wizard.SliderListManagement": "Story List Management Creator",
  "DataTable.SliderListManagement": "Story List Management ",
  "SliderSeriesManagement.Add": "Add Series",
  "SliderSeriesManagement.Title": "Story Series Management",
  "Wizard.SliderSeriesManagement": "Story Series Management Creator",
  "DataTable.SliderSeriesManagement": "Story Series Management ",
  "seriesStoryList.Add": "Add Story",
  "seriesStoryList.Title": "Story List",
}