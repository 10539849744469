import React, { Component } from 'react';
import IdleTimer from 'react-idle-timer';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import HelpMenu from '../../components/Help/HelpMenu';
import {
  AppBar,
  IconButton,
  MenuItem,
  Menu,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  DialogContentText,
} from '@material-ui/core';
import { AccountCircle, Home, Apps, People, Poll, HelpOutline, SupervisorAccount, Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { registerChatUser } from '../../../socket-client';
import { logout, myProfile, fetchPages, fetchSpecialistAccounts } from '../../../redux/actions';
import { fetchOnlineUsers } from '../../../socket-client';
import styles from '../../../styles/header';
import baseStyle from '../../../styles/wizardBase';
import { ROOT_URL } from '../../../redux/constants';
import { getStaffUser, getAccount } from '../../../redux/actions'
import axios from 'axios';
import YouTube from 'react-youtube-embed';
import PaymentDialog from '../../components/Wizards/Registration/PaymentDialog';
import SpecialistRegistrationPage4 from '../../components/Wizards/Specialist/SpecialistRegistrationPage4';

const CollisionLink = (url) => React.forwardRef((props, ref) => (
  <Link innerRef={ref} to={url} {...props} />
));

const DashboardLink = (url) => React.forwardRef((props, ref) => (
  <Link innerRef={ref} to={url} {...props} />
));
class Header extends Component {
  constructor(props){
    super(props);
    this.state = {
      account: false,
      apps: false,
      help: false,
      warning: false,
      setup: false,
      buyNow: false,
      purchase: false,
      time: 60000*5,
      updateModal: false,
      closed: false,
      proceedToPurchase: false,
      openTrainingWizard: false,
      showTraining: false
    };
    this.idleTimer = null;
  }

  componentDidMount(){
    this.props.myProfile();
    this.props.fetchPages();
    this.props.getAccount();
    // uncomment for chat (super buggy right now)
    // registerChatUser(this.props.user);
    document.body.style.backgroundColor = '#d3e6ff';
    setInterval(this.getTime, 1000);
    const setup = this.props.history.location.search === '?setup';
    if (setup) {
      this.setState({ setup: true });
    }
    if (this.props.user.type === 'Specialist'){
      this.props.fetchSpecialistAccounts(this.props.user.staffId)
        .then((response) => {
          const buyNow = response.length && response.length <= 1;
          this.setState({ buyNow })
        })
    }
    if (this.props.account === '0'){
      // uncomment for chat (super buggy right now)
      // this.props.fetchOnlineUsers();
    }
    if (!this.state.closed) {
      this.setState({updateModal: this.props.user.updateFlag === "Yes"});
    }
    if(this.props.location.state && this.props.location.state.isBuyNow){
      this.setState({purchase: true})
    }
    // if (this.props.location.state && this.props.location.state.openTrainingWizard) {
    //   this.setState({ openTrainingWizard: true })
    // }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.user.type !== nextProps.user.type) {
      if (nextProps.user.type === 'Specialist'){
        this.props.fetchSpecialistAccounts(nextProps.user.staffId)
          .then((response) => {
            const buyNow = response.length <= 1;
            this.setState({ buyNow })
          })
      }
    }
    if (this.props.user.id !== nextProps.user.id){
      // this.props.registerChatUser(nextProps.user);
    }
    this.setState({ updateModal: this.props.user.updateFlag === "Yes" });

    if (this.props.booksImport.needToLogin != nextProps.booksImport.needToLogin && nextProps.booksImport.needToLogin == "false") {
      this.props.getStaffUser();
    }
  }

  handleToggle = (name) => {
    this.setState(state => ({ [name]: !state[name] }));
  };

  handleClose = (name) => {
    this.setState(state => ({ [name]: !state[name] }));
  };

  onIdle = () => {
    this.props.logout(this.props.history, {type: 'sessionExp', msg: 'Your session has expired, please log back in'});
    this.props.history.push('/login')
  };

  getTime = () => {
    const milliseconds = this.idleTimer && this.idleTimer.getRemainingTime();
    if (milliseconds <= 60000 * 5) {
      return this.setState({ warning: true, time: milliseconds })
    }
    if (this.state.warning === true) {
      this.setState(prevState => ({ time: prevState.time - 1000, timeout: 1000 }));
      if (this.state.time <= 1000) {
        this.onIdle();
      }
    }
  };

  resetTimer = () => {
    const milliseconds = this.idleTimer && this.idleTimer.getRemainingTime();
    this.setState({ warning: false, time: milliseconds })
  };

  renderPage = (type) => {
    const page = type === 'admin'
      ? this.props.location.pathname.split('/')[2]
      : this.props.location.pathname.split('/')[3]
        ? this.props.location.pathname.split('/')[3]
        : 'dashboard';
    const _type = type === 'admin'
      ? ''
      : type === 'students'
        ? 'student'
        : type;
    const helpPage = this.props.helpPages.find(_page => _page.name.toLowerCase() === `${_type} ${page}`.trim());
    return helpPage ? helpPage : null
  };

  closeDialog = () => {
    this.setState({ proceedToPurchase: false })
  };

  closePurchaseDialog = () => {
    this.setState({ setup: false, purchase: false })
  };
  closeUpdateDialog = () => {
    const url = `${ROOT_URL}/staff/0/updateFlag`;
    this.setState({updateModal: false});
    this.setState({closed: true});
    axios
        .post(url)
        .then((response) => {
          this.props.getStaffUser(this.props.history);
        })
        .catch(err => console.log(err));


  }

  close = () => {
    this.setState(({ setup: false }))
  };

  handleTraining = () => {
    if(this.props.user && this.props.user.type == "Individual") {
      this.setState({showTraining: true})
    } else {
      this.handleToggle('help')
    }
  }
  handleCloseTraining = () => {
    this.setState({ showTraining: false })
  }
  render() {
    const { classes } = this.props;
    const { account, apps, help, buyNow } = this.state;
    const type = this.props.location.pathname.split('/')[1];
    const page = this.renderPage(type);
    const billing=this.props.account.billingCount;
    console.log(this.props.account.type);
    return (
      <AppBar classes={{ root: classes.header }}>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          onIdle={this.onIdle}
          debounce={250}
          timeout={60000 * 20}
        />
        {this.state.updateModal && !this.state.closed &&
        <Dialog
            disableEscapeKeyDown
            fullWidth={true}
            open={this.state.updateModal}
            onClose={this.closeUpdateDialog}
            aria-labelledby='form-dialog-title'
            PaperProps={{ classes:{ root: classes.container }}}
        >
          <DialogTitle id={"alert-dialog-slide-title"} >
            <FormattedMessage id="General.updatedSystem"/>
            <IconButton
              onClick={this.closeUpdateDialog}
              className={ classes.exitIcon }
              aria-label="close dialog">
            <Close />
          </IconButton>
          </DialogTitle>
          <DialogContent classes={{ root: classes.scroll }} style={{whiteSpace: 'pre-line'}}>
            Hi Everyone,{"\n"}
            We have made some changes to how admin's see our system.  Please watch the video below for more info.
            {"\n"}
            {"\n"}
            <YouTube id='mjZ9tbyXOHQ'/>
          </DialogContent>
        </Dialog>
        }
        <Dialog open={this.state.warning}>
         <DialogContent>
           <DialogContentText>
            Looks like you've been inactive for a bit! You will automatically be logged out in <strong>{moment(this.state.time).format('m:ss')}</strong>.
           </DialogContentText>
         </DialogContent>
          <DialogActions>
            <Button aria-label='log out' color='secondary' onClick={this.onIdle}>log out</Button>
            <Button aria-label='stay logged in' color='primary' variant='contained' onClick={this.resetTimer}>stay logged in</Button>
          </DialogActions>
        </Dialog>
        {(this.state.setup || this.state.purchase) &&
          <PaymentDialog close={this.closePurchaseDialog}
            registered={this.state.setup}
            purchase user={this.props.user} 
            open={this.state.showPaymentPage || this.state.purchase}
            isLoggedIn={true} />
        }
        <div className={classes.buttons}>
          {
            (process.env.NODE_ENV === 'development' || process.env.REACT_APP_VERSION >= '1.6.0') && (buyNow || this.props.user && this.props.user.type == 'Individual') &&
            !billing && <Button onClick={() => this.setState({ purchase: true })} variant='contained'>
              BUY NOW!
            </Button>
          }
          {
            (this.props.user.type === 'Admin') &&
                <IconButton aria-label='admin home' color='inherit' component={CollisionLink('/admin/home')}>
                  <SupervisorAccount/>
                </IconButton>
          }
          {(this.props.user.products && this.props.user.products.includes('Reading')) ?
            <IconButton
              aria-label='home'
              color='inherit'
              component={DashboardLink(this.props.user.homePage+'/dashboard')}
            >
              <Home />
            </IconButton>
            : <IconButton
              aria-label='home'
              color='inherit'
              component={CollisionLink(this.props.user.homePage || '/')}
            >
              <Home />
            </IconButton>}
          {(this.props.account.type != 'SALES' && this.props.user.type != 'Individual') &&

          <IconButton
              aria-label='app-switcher'
              color='inherit'
              buttonRef={node => {
                this.appsAnchor = node;
              }}
              onClick={() => this.handleToggle('apps')}
          >
            <Apps/>
            <Menu
                open={apps}
                anchorEl={this.appsAnchor}
                getContentAnchorEl={null}
                anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                transformOrigin={{vertical: "top", horizontal: "center"}}
            >
              <MenuItem component={CollisionLink('/home')} className={classes.app}>
                <People className={classes.appIcon}/>
                <Typography variant='subtitle2' className={classes.appText}><FormattedMessage
                    id='Header.network'/></Typography>
              </MenuItem>
              <MenuItem component={CollisionLink(this.props.user.homePage)} className={classes.app}>
                <Poll className={classes.appIcon}/>
                <Typography variant='subtitle2' className={classes.appText}><FormattedMessage
                    id='Header.platform'/></Typography>
              </MenuItem>
            </Menu>
          </IconButton>
          }
          {
            process.env.REACT_APP_VERSION >= '1.6.0' &&
            <>
              <IconButton
                aria-label='help menu'
                color='inherit'
                buttonRef={node => {
                  this.helpAnchor = node;
                }}
                onClick={() => this.handleTraining()}
              >
                <HelpOutline />
              </IconButton>
              { <HelpMenu
                open={help}
                anchor={this.helpAnchor}
                handleClose={this.handleClose}
                page={page}
                user={this.props.user}
                handleTraining={this.handleTraining}
              /> }
            </>
          }
          <IconButton
            aria-label='account'
            color='inherit'
            buttonRef={node => {
              this.anchorEl = node;
            }}
            onClick={() => this.handleToggle('account')}
          >
            <AccountCircle />
            <Menu
              open={account}
              anchorEl={this.anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <MenuItem onClick={() => this.props.logout(null, null, this.props.user)} component={CollisionLink('/login')}><FormattedMessage id='Header.logout'/></MenuItem>
            </Menu>
          </IconButton>
          {
            this.state.showTraining && this.props.user && this.props.user.type == "Individual" &&
            <Dialog
            open={this.state.showTraining}
            maxWidth='md'
            fullWidth
            close={this.handleCloseTraining}
              >  <DialogTitle>
                  <Typography className={classes.enrollTitle} variant='h4' style={{ float: 'left' }}>
                  BuddyBooks Training Video</Typography>
                  <IconButton
                    onClick={this.handleCloseTraining}
                    className={classes.exitIcon}
                    aria-label="close dialog"
                    style={{ float: 'right' }}
                  >
                    <Close />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <div style={{ padding: '50px', paddingTop: '5px' }}>
                    <iframe
                      title='getting started video'
                      width="800"
                      height="415"
                      src="https://www.youtube.com/embed/Fg5Qk-p_2A8"
                      frameBorder="0"
                      allowFullScreen
                    />
                    <Typography>Still need help? Email us at &nbsp;
                <a href='mailto: support@objectiveed.com'>Support@ObjectiveEd.com</a></Typography>
                  </div>
                </DialogContent>
              </Dialog>
          }
        </div>
      </AppBar>
    )
  }
}

const mapState = ({ shared: { user, account, helpPages }, platform: { booksImport } }) => {
  return {
    user,
    account,
    helpPages,
    booksImport
  }
};

const mapDispatch = (dispatch, { history }) => {
  return {
    logout: (history, err, user) => dispatch(logout(history, err, user)),
    getAccount: () => dispatch(getAccount(history)),
    myProfile: () => dispatch(myProfile(history)),
    getStaffUser: () => dispatch(getStaffUser(history)),
    fetchPages: () => dispatch(fetchPages()),
    fetchSpecialistAccounts: (staffId) => dispatch(fetchSpecialistAccounts(staffId)),
    registerChatUser: (user) => dispatch(registerChatUser(user)),
    fetchOnlineUsers: () => dispatch(fetchOnlineUsers())
  }
};

export default connect(mapState, mapDispatch)(withStyles(styles)(Header));