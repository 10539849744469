import React, { Component } from 'react';
import { withRouter } from 'react-router'
import { logPageView } from '../../../redux/actions';

class RouteChange extends Component {

  componentDidMount () {
    this.routeChanged()
  }

  componentDidUpdate (prevProps) {
    let { location: { pathname } } = this.props;
    if (prevProps.location.pathname !== pathname){
      this.routeChanged()
    }
  }

  routeChanged () {
    let { location: { pathname }, user } = this.props;
    if (this.props.user.id) {
      logPageView(user.id, user.accountId, pathname)
    }
  }

  render () {
    return null
  }
}

export default withRouter(RouteChange);