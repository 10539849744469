import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {
  Button, IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  withStyles
} from '@material-ui/core';
import { Edit, Search } from '@material-ui/icons';
import { getSorting, stableSort } from '../../../helpers';
import { fetchAccounts, suspendAccount, activateAccount, fetchBillingData, updateBillingData } from '../../../redux/actions';
import baseStyle from '../../../styles/skillList.js';
import AccountDetails from '../Wizards/Account/AccountDetails';
import EditWizardButton from '../Wizards/Edit/EditWizardButton';

class SupportAccountsList extends Component {

  state = {
    order: 'asc',
    orderBy: this.props.orderBy,
    rowsPerPage: 10,
    page: 0,
    searchCategory: 'firstName',
    filteredData: [],
    searchValue: '',
    rows: [
      { id: 'name', label: <FormattedMessage id='General.name'/>, searchable: true },
      { id: 'status', label: <FormattedMessage id='General.status'/>, searchable: true},
      { id: 'type', label: <FormattedMessage id='General.type'/>, searchable: true},
      { id: 'billingCount', label: 'Billing Count'},
      { id: 'studentCount', label: <FormattedMessage id='General.studentCount'/>},
      { id: 'brailleAIBillingCount', label: 'Billing Braille AI'},
      { id: 'brailleAIStudentCount', label: 'Braille AI'},
      { id: 'startDate', label: 'Start Date'},
      { id: 'cycle', label: 'Billing Cycle'},
    ],
    link: {},
    user: {},
    account: {},
    name: '',
    open: false
  };

  componentDidMount() {
    this.props.fetchAccounts();
    this.setState({ account: this.props.billing })
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.accounts !== this.props.accounts){
      this.handleSearch(this.state.searchValue, nextProps.accounts)
    }
    if (nextProps.billing.id !== this.props.billing.id){
      this.setState({ account: nextProps.billing })
    }
  }

  handleSearch = (searchValue, data) => {
    const accounts = data ? data : this.props.accounts;
    const filtered = accounts.filter(item => {
      const searchableKeys = Object.keys(item).filter(key => this.state.rows.some(row => row.id === key));
      return searchableKeys.some(key => {
        if (typeof (item[key]) === 'string'){
          return item[key].toLowerCase().includes(searchValue.toLowerCase())
        }
        else {
          return false
        }
      })
    });
    this.setState({ filteredData: filtered, page: 0 })
  };

  handleSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleBillingEdit = ({ target: { name, value }}) => {
    this.setState(({account}) => ({ account: {...account, [name]: value }}));
  };

  toggleEdit = () => {
    this.setState(({ edit }) => ({ edit: !edit }));
  };

  openAccount = (account) => {
    this.setState({ open: true, name: account.name, accountId: account.id });
    this.props.fetchBillingData(account.id);
  };

  closeAccount = () => {
    this.setState({
      open: false,
      edit: false,
      account: this.props.billing
    });
  };

  save = () => {
    const {
      city,
      country,
      id,
      postalCode,
      primaryEmail,
      state,
      street1,
      street2,
      rate,
      cycle
    } = this.state.account;
    this.props.updateBillingData(this.state.account.id, {
      city,
      country,
      id,
      postalCode,
      primaryEmail,
      state,
      street1,
      street2,
      rate,
      cycle
    });
    this.setState({ edit: false, account: {...this.state.account, city, country, id, postalCode, primaryEmail, state, street1, street2}})
  };

  render() {
    const { classes } = this.props;
    const { rows, orderBy, order, rowsPerPage, page, searchValue, filteredData, account } = this.state;
    const data = searchValue ? filteredData : this.props.accounts;
    return (
      <>
        <Paper className={ classes.list }>
          <Toolbar classes={{ root: classes.toolbar }}>
            <Typography noWrap className={ classes.header }>
              <strong><FormattedMessage id='Sidebar.accounts'/></strong>
            </Typography>
            <div className={classes.actions}>
              <TextField
                className={ classes.search }
                variant='outlined'
                id='table-search'
                aria-label='search input'
                type={'search'}
                InputProps={{
                  inputProps: {
                    'aria-label':'search input'
                  },
                  className: classes.searchBar,
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Search />
                    </InputAdornment>
                  ),
                }}
                onChange={(ev) => {
                  this.handleSearch(ev.target.value);
                  this.setState({ searchValue: ev.target.value });
                }}
              />
            </div>
          </Toolbar>
          <div className={classes.tableWrapper}>
            <Table>
              <TableHead>
                <TableRow>
                  { rows.length &&
                  rows.map(row => {
                    return (
                      <TableCell
                        key={row.id}
                        sortDirection={ orderBy === row.id ? order : false }
                      >
                        <Tooltip
                          title='Sort'
                          placement='bottom-start'
                          enterDelay={300}
                        >
                          <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={(ev) => this.handleSort(ev, row.id)}
                          >
                            {row.label}
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                    )
                  })
                  }
                  <TableCell>Billing Data</TableCell>
                  <TableCell/>
                  <TableCell/>
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(data, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(_data => {
                    return (
                      <TableRow
                        hover
                        key={_data.id}
                      >
                        {
                          rows.length &&
                          rows.map((row, index) => {
                           return (
                              <TableCell key={index}>
                                {
                                  typeof _data[row.id] === 'object'
                                    ? _data[row.id] ? `${_data[row.id].monthValue}/${ _data[row.id].dayOfMonth}/${ _data[row.id].year}` : 'N/A'
                                    : _data[row.id]
                                }
                              </TableCell>
                            )
                          })
                        }
                        <TableCell align='center'>
                          {
                            _data.type !== 'INTERNAL' &&
                            <Button
                              variant='contained'
                              aria-label='view account'
                              onClick={()=> this.openAccount(_data)}
                            >
                              <FormattedMessage id='General.view' />
                            </Button>
                          }
                        </TableCell>
                        {
                          _data.status !== 'ACTIVE'
                          ? <TableCell align='right'>
                              <Button
                                variant='contained'
                                style={{ backgroundColor: '#66bb6a', color: 'white' }}
                                aria-label='activate account'
                                onClick={()=> this.props.activateAccount(_data)}
                              >
                                <FormattedMessage id='General.activate' />
                              </Button>
                            </TableCell>
                          : <TableCell align='right'>
                              <Button
                                variant='contained'
                                style={{ backgroundColor: '#ef5350', color: 'white' }}
                                aria-label='suspend account'
                                onClick={()=> this.props.suspendAccount(_data)}
                              >
                                <FormattedMessage id='General.suspend' />
                              </Button>
                            </TableCell>
                        }
                        <TableCell>
                         <EditWizardButton
                           onClick={() => console.log(_data.id)}
                           data={_data}
                           type='account'
                         />
                        </TableCell>
                      </TableRow>
                    );
                  })
                }
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[5,10,25]}
            component='div'
            labelRowsPerPage={<FormattedMessage id='Datatable.rowsPerPage'/>}
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
        <AccountDetails
          account={account}
          open={this.state.open}
          edit={this.state.edit}
          name={this.state.name}
          id={this.state.accountId}
          downloadTerms={this.downloadTerms}
          closeAccount={this.closeAccount}
          save={this.save}
          handleBillingEdit={this.handleBillingEdit}
          toggleEdit={this.toggleEdit}
        />
      </>
  );
  }
}

const mapState = ({ shared: { billing, accounts }}) => {
  return {
    billing,
    accounts,
  }
};

const mapDispatch = (dispatch, { history }) => {
  return {
    fetchAccounts: () => dispatch(fetchAccounts(history)),
    suspendAccount: (account) => dispatch(suspendAccount(account)),
    activateAccount: (account) => dispatch(activateAccount(account)),
    fetchBillingData: (accountId) => dispatch(fetchBillingData(accountId)),
    updateBillingData: (accountId, billing) => dispatch(updateBillingData(accountId, billing))
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(SupportAccountsList));