import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { AppBar, IconButton, Typography } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { logout, getUser } from '../../../redux/actions';
import styles from '../../../styles/header';

const CollisionLink = React.forwardRef((props, ref) => (
  <Link innerRef={ref} to="/login" {...props} />
));

class SupportHeader extends Component {
  constructor(props){
    super(props);
    this.state = {
      open: false
    }
  }

  componentDidMount(){
    // if (!this.props.user.id){
    //   this.props.getUser();
    // }
    document.body.style.backgroundColor = '#d3e6ff';
  }

  // componentWillReceiveProps(nextProps, nextContext) {
  //   if (nextProps.user.id !== this.props.user.id){
  //     this.props.getUser();
  //   }
  // }

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, user } = this.props;
    return (
      <AppBar classes={{ root: classes.header }}>
        <Typography className={classes.search} color='inherit' align='center' variant='h6'>
          <FormattedMessage id='Header.support'/> {user ? user.name ? user.name.toUpperCase() : '' : ''}
        </Typography>
        <div className={classes.buttons}>
          <IconButton
            component={CollisionLink}
            aria-label='logout'
            color='inherit'
            onClick={() => this.props.logout(this.props.user)}
          >
            <AccountCircle/>
            <Typography color='inherit' variant='subtitle2'>
              &nbsp; <FormattedMessage id='Header.logout'/>
            </Typography>
          </IconButton>
        </div>
      </AppBar>
    )
  }
}

const mapState = ({ shared: { user }}) => {
  return {
    user
  }
};

const mapDispatch = (dispatch, {history}) => {
  return {
    logout: (user) => dispatch(logout(history, null, user)),
    getUser: () => dispatch(getUser())
  }
};

export default connect(mapState, mapDispatch)(withStyles(styles)(SupportHeader));