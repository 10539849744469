export default {
  title: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '55px',
    marginBottom: '20px'
  },
  text: {
    marginLeft: '20px',
    alignSelf: 'center'
  },
  icon: {
    padding: '5px',
    backgroundColor: '#0087b6',
    color: '#f9f3ff',
    fontSize: '3rem',
    alignSelf: 'center',
    borderRadius: '10px'
  },
  lineGroup: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    minHeight: 0,
    gridColumnGap: '15px',
    gridRowGap: '15px',
    marginBottom: '20px',
    justifyContent: 'center'
  },
  barChart: {
    width: '80%',
    height: '450px',
    margin: '0 auto'
  },
  chartTitle: {
    marginTop: '20px',
    textAlign: 'center'
  },
  numbers: {
    display: 'grid',
    alignContent: 'space-between',
    marginBottom: '20px',
  },
  numIcon: {
    padding: '5px',
    backgroundColor: '#310073',
    color: '#fff',
    justifySelf: 'center',
    alignSelf: 'center',
    borderRadius: '5px'
  },
  trendUp: {
    marginLeft: 10,
    alignSelf: 'center',
    color: 'green',
    fontSize: 28,
    paddingBottom: 20
  },
  trendDown: {
    marginLeft: 10,
    alignSelf: 'center',
    color: 'red',
    fontSize: 28,
    fontWeight: 800,
    paddingBottom: 20
  },
  carousel: {
    padding: '0 60px',
    marginBottom: 20,
  },
  empty: {
    height: 450
  },
  charts: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    height: 450,
    marginBottom: 20,
    gridColumnGap: '20px'
  },
  pieChart: {
    display: 'grid',
    height: 450,
    marginBottom: 20,
    gridColumnGap: '20px'
  },
  chart: {
    height: 450,
    marginBottom: 20
  },
  staffChart: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    height: 450,
    marginBottom: 20,
    gridColumnGap: '20px'
  },
  staffChartTall: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    height: 550,
    marginBottom: 20,
    gridColumnGap: '20px'
  },
  icon: {
    padding: '5px',
    backgroundColor: '#310073',
    color: '#fff',
    justifySelf: 'center',
    alignSelf: 'center',
    borderRadius: '5px'
  },
  header: {
    backgroundColor: '#0087b6',
    display: 'flex',
    alignItems: 'center',
    padding: '15px',
  },
  chartTitle: {
    color: '#fff',
    alignSelf: 'center',
    marginLeft: '20px',
    flex: 1,
  },
  placeholder: {
    textAlign: 'center',
    margin: '20px 0',
    padding: '40px 0'
  },
  weekDate: {
    marginRight: '5px',
    textTransform: 'capitalize'
  },
  actions: {
    gridColumn: 2,
    gridRow: 1,
    justifySelf: 'end',
    marginRight: '20px',
  },
  searchBarBar: {
    color: '#000',
    fontWeight: 600,
    width: '320px',
    marginLeft: '10px',
    backgroundColor: 'white',
    borderRadius: '4px',
    height: 40
  },
  tableWrapper: {
    overflowX: 'auto',
    // width: 800
  },
  toolbar: {
    display: 'grid',
    gridTemplateColumns: '8fr 2fr',
    gridTemplateRows: '1fr',
    borderRadius: '5px 5px 0 0',
    backgroundColor: '#00729b',
    boxShadow: ' 0px 3px 3px #6b6b6b',
  },
  staffHeader: {
    gridColumn: 1,
    gridRow: 1,
    flex: '0 0 auto',
    fontSize: '24px',
    color: '#fff',
    marginLeft: '20px',
  },
  staffReport: {
    display: 'flex',
    justifyContent: 'center'
  },
  reportContainer: {
    "height": "550px",
    overflow: 'hidden'
  },
  staffTimeReport: {
    paddingBottom: "45px",
    paddingTop: "45px",
    width: "100%",
    display: 'flex',
    justifyContent: 'center'
  },
  ellipsis: {
    maxWidth: 200, 
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  wizardButton: {
    color: '#fff',
    marginTop:"5px",
    backgroundColor:'#00729b',
    '&:hover': {
      backgroundColor:'#005C87'
    },
    '&:focus': {
      backgroundColor:'#005C87'
    },
  },
  searchToolbar: {
    display: 'grid',
    gridTemplateRows: '1fr',
    borderRadius: '5px 5px 0 0',
    backgroundColor: '#00729b',
    boxShadow: ' 0px 3px 3px #6b6b6b',
  },
  buttons: {
    wordWrap: 'break-word',
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'end',
    alignContent: 'center'
  },
  progressReportContainer:{
    backgroundColor:'rgb(211, 230, 255)',
    padding:'24px'
  },
  container: {
    color: '#0a0a0a',
    overflow: 'wrap',
  },
  dialogTitle: {
    backgroundColor: '#383871',
    marginBottom: 15,
  },
  exitIcon: {
    float: 'right',
    color: '#fff',
  },
  dialog: {
    zIndex: 0,
    overflow: 'auto',
  },
  enrollTitle:{
    color:'#fff'
  },
  selectContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  selectStyles: {
      appearance: 'none',
      padding: '8px',
      border: '1px solid #ddd',
      borderRadius: '4px',
      fontSize: '14px',
      color: '#333',
      width: '150px',
      backgroundColor: '#fff',
      transition: 'border-color 0.2s ease-in-out',
      ':hover': {
        borderColor: '#777',
      },
      ':focus': {
        outline: 'none',
        borderColor: '#777',
      },
    },
    optionStyles: {
      fontSize: '14px',
      color: '#333',
    },
    labelStyles: {
      color: '#fff',
      fontWeight: 'bold',
      marginRight:'10px'
    },
}