import {
  Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, TextField, Toolbar, Tooltip, Typography
} from '@material-ui/core';
import { green, yellow } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import { Close, Face, HelpOutline, Search } from '@material-ui/icons';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

// Import react-circular-progressbar module and styles
import {
  buildStyles, CircularProgressbar
} from "react-circular-progressbar";
import { getSorting, stableSort } from '../../../helpers';
import { fetchStaffDashboard, getStudentsForStaff, fetchStaffReadingTime, getRolesForStaff, getAccount, getStaffById } from '../../../redux/actions';
import baseStyle from '../../../styles/dashboard';
import StaffRecord from '../../components/Wizards/Staff/StaffRecord';
import { RESET_BOOKLIST_TEMP, RESET_STAFF_DASHBOARD } from '../../../redux/constants';
import LastSevenDay from '../../components/Shared/LastSevenDay';
import StudentEnrollmentWizard from '../../components/Wizards/Registration/StudentEnrollmentWizard';
import manageStudentsButton from '../../../assets/ScreenShots/manageStudentsButton.png';
import MobileSupportModal from '../../components/Help/MobileSupportModal';
class TeacherDashboard extends Component {

  state = {
    order: 'asc',
    orderBy: 'firstName',
    rowsPerPage: 5,
    page: 0,
    filteredData: [],
    searchValue: '',
    checked: {},
    open: false,
    openAccuracy: false,
    studentId: '',
    manageStudent: false,
    showNoUserModal: false,
    showNoActiveModal: false,
    showNoLoginModal: false,
    updatedStudentData:null,
    downloadComplete: false,
    studentIsDeleted:false,
  };

  componentDidMount() {
    this.props.fetchStaffDashboard(this.props.staffId);
    this.props.getStudentsForStaff(this.props.staffId);
    this.props.dispatch({ type: RESET_BOOKLIST_TEMP });
    this.props.fetchStaffReadingTime(this.props.staffId);
    this.props.getRolesForStaff(this.props.staffId);
    this.props.getStaffById(this.props.staffId);
    this.props.getAccount();
    if (this.props.history.location.state && this.props.history.location.state.manageStudent) {
      this.setState({ manageStudent: true })
    }
  }
  componentWillUnmount() {
    this.props.dispatch({ type: RESET_STAFF_DASHBOARD });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.staffId != this.props.staffId) {
      this.props.fetchStaffDashboard(this.props.staffId);
      this.props.getStudentsForStaff(this.props.staffId);
      this.props.fetchStaffReadingTime(this.props.staffId);
      this.props.getRolesForStaff(this.props.staffId);
      this.props.getStaffById(this.props.staffId);
    }
    if (prevProps.staffDashboardReadingTime != this.props.staffDashboardReadingTime) {
      this.setState({
        downloadComplete: true
      })
    }
    if (this.props.staffStudents && this.props.staffStudents.length != prevProps.staffStudents.length) {
      this.props.fetchStaffDashboard(this.props.staffId);
    }
    if (this.props.staffDashboard && this.props.staffDashboard != prevProps.staffDashboard) {
      this.props.getStudentsForStaff(this.props.staffId);
      if (this.props.staffDashboard && this.props.staffDashboard.length == 0) {
        if(!this.state.studentIsDeleted && this.props.user.type == "Individual"){
          this.setState({ showNoUserModal: true });
        }
      }
      else {
        if(Object.keys(this.props.staffDashboard).length !== 0){
        const filteredLoginStudent = this.props.staffDashboard.filter(std => std.studentLogin == 'Yes');
        const filteredActiveStudent = this.props.staffDashboard.filter(std => std.activeBook == 'Yes');

        if (filteredLoginStudent.length == 0) {
          this.setState({ showNoLoginModal: true });
        }
        if (filteredActiveStudent.length == 0 && filteredLoginStudent.length !== 0 ) {
          this.setState({ showNoActiveModal: false });
        }}
      }
    }
  }

  openWizard = (e, studentId) => {
    e.stopPropagation();
    this.setState({
      open: true,
      studentId: studentId
    })
  };

  closeWizard = () => {
    this.setState({ open: false });

  }
  
  handleClick = (e, destination, studentId) => {
    e.stopPropagation();
    let fromPath = "dashboard";
    if (this.props.currentPath == "admindashboard")
      fromPath = "admindashboard";

    this.props.history.push(`/students/${studentId}/${destination}`, { from: fromPath });
  }

  handleSearch = (searchValue, data) => {
    let _data = [];
    if (this.props.staffDashboard)
      _data = this.props.staffDashboard;
    else {
      this.props.fetchStaffDashboard(this.props.staffId);
      return;
    }
    const searchableData = data ? data : _data ? _data : [];
    const filtered = Array.isArray(searchableData) && searchableData.filter(item => {
      const searchableKeys = [{ id: 'firstName' }, { id: 'lastName' }]
      return searchableKeys.some(key => {
        if (item[key.id]) {
          return item[key.id].toLowerCase().includes(searchValue.toLowerCase())
        }
        else {
          return false
        }
      })
    });
    Array.isArray(searchableData) && this.setState({ filteredData: filtered, page: 0 })
  }

  handleSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    this.setState({ order, orderBy });
  };
  open = () => {
    this.setState({ manageStudent: true })
  };

  close = () => {
    this.setState({ manageStudent: false })
  };
  handleNoUserClose = () => {
    this.setState({ showNoUserModal: false });
  };
  handleNoLoginClose = () => {
    this.setState({ showNoLoginModal: false })
  }
  handleNoActiveBookClose = () => {
    this.setState({ showNoActiveModal: false })
  }
  handleUpdatedStudent = (student) => {
    this.setState({ updatedStudentData: student });
  }
  handleDeletedStudent=()=>{
    this.setState({studentIsDeleted:true})
  }
  render() {
    const { classes, staffDashboard, staffDashboardReadingTime, staffStudents, account, staffRoles } = this.props;
    const {
      rowsPerPage,
      page,
      orderBy,
      order,
      open,
      filteredData,
      searchValue,
      updatedStudentData
    } = this.state;
    const rows = [
      { id: 'firstName', label: <FormattedMessage id="General.firstName" />, searchable: true, colSpan: 1 },
      { id: 'lastName', label: <FormattedMessage id="General.lastName" />, searchable: true, colSpan: 1 },
      { id: 'studentLogin', label: 'Can Login', colSpan: 1 },
      { id: 'activeBook', label: 'Active Book', colSpan: 1 },
      { id: 'progress', label: 'Progress on Active Book', colSpan: 1 },
      { id: 'readingTime', label: 'Reading Time', colSpan: 7 },
    ];
    const data = (filteredData.length > 0 || searchValue) ? filteredData : staffDashboard
        ? staffDashboard : [];
    const UpdatedData = updatedStudentData ? data.map(std => {
      if (std.studentId == updatedStudentData.id) {
        let firstName = updatedStudentData.firstName;
        let lastName = updatedStudentData.lastName;
        return { ...std, firstName, lastName }
      }
      return std;
    }) : data;
    const specAdmin = staffRoles.find(role => role.id === '922331036862345812');
    const customerType = this.props.user.type;
    return (
        <>
          <div className={classes.title}>
            <Face style={{
              padding: '5px',
              backgroundColor: '#0087b6',
              color: '#f9f3ff',
              fontSize: '3rem',
              alignSelf: 'center',
              borderRadius: '10px'
            }}
                  fontSize='inherit' />
            <Typography className={classes.text} variant='h3'>
              {this.props.staffMember.firstName}<FormattedMessage id='Dashboard.possessive'/>
            </Typography>
            {
                account && specAdmin &&
                <Button
                    onClick={this.open}
                    style={{ color: "#fff", backgroundColor: "#00729b", width: '300px','justify-self': 'end' }}
                >
                  Add/Manage Students
                </Button>
            }
            <Dialog
                maxWidth='md'
                fullWidth
                open={this.state.manageStudent}
                onClose={this.close}
            >
              <StudentEnrollmentWizard
                  start
                  studentCount={staffStudents.length}
                  enrolled={staffStudents}
                  close={this.close}
                  students={account ? account.billingCount : 0}
                  customerType={customerType}
                  handleUpdatedStudent={this.handleUpdatedStudent}
                  handleDeletedStudent={this.handleDeletedStudent}
              />
            </Dialog>
          </div>
          <Divider />
          <Dialog open={open} classes={{ paper: classes.browseDialog }} maxWidth='lg'
                  fullWidth={true} onClose={this.closeWizard}>
            <StaffRecord {...this.props}
                         studentId={this.state.studentId}
                         closeWizard={this.closeWizard}>
            </StaffRecord>
          </Dialog>
          <Paper  >
            <Toolbar classes={{ root: classes.toolbar }}>
              <Typography className={classes.staffHeader} noWrap>
              {this.props.staffMember.firstName}<FormattedMessage id="Sidebar.student's"/>
              </Typography>
              <div className={classes.actions}>
                {!this.props.noSearch && (
                    <TextField
                        variant='outlined'
                        id='table-search'
                        aria-label='search input'
                        type={'search'}
                        InputProps={{
                          inputProps: {
                            'aria-label': 'search input'
                          },
                          endAdornment: (
                              <InputAdornment position='end'>
                                <Search />
                              </InputAdornment>
                          ),
                          className: classes.searchBarBar,
                        }}
                        onChange={(ev) => {
                          this.handleSearch(ev.target.value);
                          this.setState({ searchValue: ev.target.value });
                        }}
                        value={this.state.searchValue}
                    />
                )}
              </div>
            </Toolbar>
            <div className={classes.tableWrapper}>
              <Table >
                <TableHead>
                  <TableRow>
                    {
                        rows.length &&
                        rows.map((row, index) => {
                          return (
                              <TableCell
                                  aria-label={row.id}
                                  key={row.id + index}
                                  colSpan={2}
                                  align={(row.id == "firstName" || row.id == "lastName") ? "left" : "center"}
                                  sortDirection={orderBy === row.id ? order : false}
                              >
                                <Tooltip
                                    title='Sort'
                                    placement='bottom-start'
                                    enterDelay={300}>
                                  <TableSortLabel
                                      active={orderBy === row.id}
                                      direction={order}
                                      onClick={(ev) => this.handleSort(ev, row.id)}>
                                    {row.label}
                                  </TableSortLabel>
                                </Tooltip>
                              </TableCell>
                          )
                        })
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stableSort(UpdatedData, getSorting(order, orderBy, {staffDashboardReadingTime }))
                      .map((_data, index) => {
                        return (
                            <TableRow className={classes.hoverRow} tabIndex={0} hover key={_data.id + index}
                                      onClick={(e) => this.handleClick(e, "dashboard", _data["studentId"])}>
                              {rows.length &&
                                  rows.map((row, index) => {
                                    return (
                                        row.id == "readingTime" ?
                                            <TableCell>
                                              <LastSevenDay
                                                  fromTeaher={false}
                                                  data={_data['readingTime']
                                                  || staffDashboardReadingTime.length > 0
                                                  && staffDashboardReadingTime.find(obj => obj.studentId == _data['studentId']) ? staffDashboardReadingTime.find(obj => obj.studentId == _data['studentId']).readingTime : []
                                                  }
                                                  downloadComplete={this.state.downloadComplete}
                                                  targetReadingTime={_data['readingTarget']}
                                                  teacherStyle={true}
                                              />
                                            </TableCell> :
                                            row.id == 'activeBook'
                                                ? <TableCell
                                                    align={"center"} colSpan={2} aria-label={row.id}
                                                    className={classes.cell} key={index + row.id}
                                                    onClick={(e) => this.handleClick(e, "books", _data["studentId"])}>
                                                  {row.id === 'activeBook' && _data[row.id] == "Yes" ?
                                                      <IconButton color="primary" aria-label="b1">
                                                        <CheckCircleIcon style={{ color: green[500] }} />
                                                      </IconButton>
                                                      : <IconButton aria-label="b2">
                                                        <CancelIcon color="secondary" />
                                                      </IconButton>}
                                                </TableCell>
                                                : row.id === 'studentLogin' ?
                                                    <TableCell colSpan={2}
                                                               align={"center"} aria-label={row.id} className={classes.cell}
                                                               key={index + row.id} onClick={(e) => this.handleClick(e, "profile", _data["studentId"])}>
                                                      {row.id === 'studentLogin' && _data[row.id] == "Yes" ?
                                                          <IconButton color="primary" aria-label="b3">
                                                            <CheckCircleIcon style={{ color: green[500] }} />
                                                          </IconButton> :
                                                          <IconButton aria-label="b4">
                                                            <CancelIcon color="secondary" />
                                                          </IconButton>}
                                                    </TableCell>
                                                    :
                                                    (row.id == "firstName" || row.id == "lastName") ?
                                                        <TableCell aria-label={row.id}
                                                                   align={"left"} colSpan={2} className={classes.cell} key={index + row.id}>
                                                          {_data[row.id]}
                                                        </TableCell>
                                                        :
                                                        (row.id == "accuracy") ?
                                                            <TableCell colSpan={2}
                                                                       align={"center"} aria-label={row.id} className={classes.cell}
                                                                       key={index + row.id} >
                                                            </TableCell>
                                                            :
                                                            <TableCell aria-label={row.id}
                                                                       align={"center"} colSpan={2} className={classes.cell} key={index + row.id}>
                                                              {
                                                                _data[row.id] && _data[row.id] !== ' '
                                                                    ? row.id == "progress" ?
                                                                        <div style={{ width: 80, height: 75, marginLeft:"auto", marginRight:"auto" }} onClick={(e) => this.handleClick(e, "books", _data["studentId"])}>
                                                                          <Button>
                                                                            <CircularProgressbar
                                                                                circleRatio={0.75}
                                                                                value={(_data["progress"] * 100)}
                                                                                text={`${(_data["progress"] * 100).toFixed(2)}%`}
                                                                                styles={buildStyles({
                                                                                  rotation: 1 / 2 + 1 / 8,
                                                                                  strokeLinecap: "butt",
                                                                                  trailColor: "#eee",
                                                                                  textSize: '20px',
                                                                                })}
                                                                            />
                                                                          </Button>
                                                                        </div>
                                                                        : '' : ''
                                                              }
                                                            </TableCell>)
                                  })
                              }
                            </TableRow>
                        );
                      })
                  }
                </TableBody>
              </Table>
            </div>
          </Paper>
          {
              this.state.showNoUserModal && <Dialog
                  maxWidth='md'
                  fullWidth
                  open={this.state.showNoUserModal}
                  onClose={this.handleNoUserClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  PaperProps={{ classes:{ root: classes.container }}}
              >
                <DialogTitle className={ classes.dialogTitle }>
                  <Typography className={classes.enrollTitle} variant='h4' style={{ float: 'left' }}>
                  Let’s add your students!
                  </Typography>
                  <IconButton
                      onClick={this.handleNoUserClose}
                      className={classes.exitIcon}
                      aria-label="close dialog"
                      style={{ float: 'right' }}
                  >
                    <Close />
                  </IconButton>
                </DialogTitle>
                <DialogContent style={{ minHeight: 100, }} className={classes.dialog}>
                  <Typography variant='h5'> To add your students, click on the Add/Manage Students button
                  <img style={{ marginLeft: '10px', top: '10px', position: 'relative', height:50 }} src={manageStudentsButton} alt='manage button'/>
                  </Typography>
                </DialogContent>
                <Typography variant='h6' style={{ padding: '10px' }}>For additional help, please click on the help icon in the top right
                  <HelpOutline style={{ marginLeft: '10px', top: '5px', position: 'relative' }} /></Typography>
              </Dialog>
          }
          {
              (this.state.showNoLoginModal && this.props.user.type !== 'Admin') && <Dialog
                  maxWidth='md'
                  fullWidth
                  open={this.state.showNoLoginModal}
                  onClose={this.handleNoLoginClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  PaperProps={{ classes:{ root: classes.container }}}
              >
                <DialogTitle className={ classes.dialogTitle }>
                  <Typography className={classes.enrollTitle} variant='h4' style={{ float: 'left' }}>
                    Let's assign a login!</Typography>
                  <IconButton
                      onClick={this.handleNoLoginClose}
                      className={classes.exitIcon}
                      aria-label="close dialog"
                      style={{ float: 'right' }}
                  >
                    <Close />
                  </IconButton>
                </DialogTitle>
                <DialogContent style={{ minHeight: 100, }} className={classes.dialog}>
                  <Typography variant='h6'>
                    You need to give your students login information to begin reading.
                    Click on the red
                    <CancelIcon style={{ marginLeft: '10px', marginRight:'10px', top: '5px', position: 'relative' }}  color="secondary" /> under Can Login on your dashboard </Typography>
                </DialogContent>
                <Typography variant='h6' style={{ padding: '10px' }}>To see a complete training guide click the help icon in the header
                  <HelpOutline style={{ marginLeft: '10px', top: '5px', position: 'relative' }} /></Typography>
              </Dialog>
          }
          {
              (this.state.showNoActiveModal && this.props.user.type !== 'Admin') &&
              <Dialog
                  maxWidth='md'
                  fullWidth
                  open={this.state.showNoActiveModal}
                  onClose={this.handleNoActiveBookClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  hideBackdrop={this.state.showNoLoginModal}
                  PaperProps={{ classes:{ root: classes.container }}}
              >
                <DialogTitle className={ classes.dialogTitle }>
                  <Typography className={classes.enrollTitle} variant='h4' style={{ float: 'left' }}>
                  Let’s assign a book!</Typography>
                  <IconButton
                      onClick={this.handleNoActiveBookClose}
                      className={classes.exitIcon}
                      aria-label="close dialog"
                      style={{ float: 'right' }}
                  >
                    <Close />
                  </IconButton>
                </DialogTitle>
                <DialogContent style={{ minHeight: 100, }} className={classes.dialog}>
                  <Typography variant='h6'>
                  Your students don’t have any books assigned to them yet! Click on the red
                      <CancelIcon color="secondary" style={{ marginLeft: '10px', marginRight:'10px', top: '5px', position: 'relative' }} />
                     under Active Book on your dashboard to assign a book.
                  </Typography>
                </DialogContent>
                <Typography variant='h6' style={{ padding: '10px' }}>For additional help, please click on the help icon in the top right
                  <HelpOutline style={{ marginLeft: '10px', top: '5px', position: 'relative' }} /></Typography>
              </Dialog>
          }
          <MobileSupportModal />
        </>

    )
  }
}

const mapState = ({ platform: { staffMember, games, staffStudents, weeklySummaries, staffDashboard, staffDashboardReadingTime, staffRoles }, shared: { user, account } }) => {
  return {
    staffMember,
    games,
    staffStudents,
    user,
    weeklySummaries,
    staffDashboard,
    staffDashboardReadingTime,
    staffRoles,
    account,
  }
};

const mapDispatch = (dispatch) => {
  return {
    fetchStaffDashboard: (id) => dispatch(fetchStaffDashboard(id)),
    getStudentsForStaff: (id) => dispatch(getStudentsForStaff(id)),
    fetchStaffReadingTime: (id) => dispatch(fetchStaffReadingTime(id)),
    getRolesForStaff: (id) => dispatch(getRolesForStaff(id)),
    getStaffById: (id) => dispatch(getStaffById(id)),
    getAccount: () => dispatch(getAccount()),
    dispatch
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(TeacherDashboard));