import React, {Component} from 'react';
import {connect} from 'react-redux';
import StoryText from '../../components/Student/StoryText';
import {Divider, Typography} from '@material-ui/core/index';
import {InsertChartOutlined} from '@material-ui/icons/index';
import {getStudent, getStudentGames, fetchGames} from '../../../redux/actions';
import {withStyles} from '@material-ui/core/styles/index';
import baseStyle from '../../../styles/games.js';
import {FormattedMessage} from 'react-intl';
import EntertainmentGameList from '../../components/Student/EntertainmentGameList';
import EntertainmentGameWizardBase from '../../components/Wizards/Games/EntertainmentGameWizardBase';
import {runAnalysis, getStory} from '../../../redux/actions/platform/buddybooks'
import { GAME_URL } from '../../../redux/constants';
import {MediaRecorder, register }  from 'extendable-media-recorder';
import { connect as connectEncoder } from 'extendable-media-recorder-wav-encoder';
import axios from 'axios';
import { saveAs } from 'file-saver';
import JSONPretty from 'react-json-pretty';



let chunks = [];

class BuddyBooks extends Component {

    constructor(props) {
        super(props);
        let data = new Blob([]);
        this.state = { data: data, text: "Test", index: 0, start: false, url:""};
    }

    onSuccess = (stream) => {
        const options = {
            mimeType: 'audio/wav'
        }
        const mediaRecorder = new MediaRecorder(stream, options);

        // visualize(stream);
        let record = function() {
            mediaRecorder.start();
            console.log(mediaRecorder.state);
            console.log("recorder started");
        }

        let stop = function() {
            mediaRecorder.stop();
            console.log(mediaRecorder.state);
            console.log("recorder stopped");
        }

        mediaRecorder.onstop = this.onStop;

        mediaRecorder.ondataavailable = function(e) {
            chunks.push(e.data);
        }
        this.setState({record, stop});
    }

    onStop = (e) => {
        console.log("data available after MediaRecorder.stop() called.");
        const blob = new Blob(chunks, { 'type' : 'audio/wav; codecs=MS_PCM' });
        chunks = [];
        this.setState({"data": blob});
        console.log("recorder stopped");
    }

    onSubmit() {
        var text = this.state.story.storySegmentDto[this.state.index].text;
        const url =`${GAME_URL}/speech/${this.props.id}/upload`;
        var data = {"possibleCorrect": [text], "possibleIncorrect": []}
        console.log(data);
        var id = this.props.id;
        var blob = this.state.data;
        //0207cb2d-2ee5-4645-98cc-821d57ed26b7
        const config = {
            headers: {
                'access-token': '0207cb2d-2ee5-4645-98cc-821d57ed26b7'
            }
        };
        axios.post(url, data, config).then((response)=> {
            console.log(response);
            var speechId = response.data.id;
            this.props.runAnalysis(speechId, id, blob);
        })
    }

     onError = (err)  => {
        console.log('The following error occured: ' + err);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.story) {
            if((!this.props.story || this.props.story.length==0) || this.props.story.storyDto.id != nextProps.story.storyDto.id) {
                this.setState({story: nextProps.story});
                this.setState({index: 0});
            }
        }
    }

    onNext = () => {
        this.setState({index: this.state.index+1, url: ""});
    }

    async componentDidMount() {
        this.props.getStory(1);
        console.log(MediaRecorder.isTypeSupported("audio/wav;codecs=MS_PCM"));
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            console.log('getUserMedia supported.');
            const constraints = { audio: true };
            await register(await connectEncoder());
            navigator.mediaDevices.getUserMedia(constraints).then(this.onSuccess, this.onError);

        } else {
            console.log('getUserMedia not supported on your browser!');
        }
    }

    playSegment = (storySegment) => {
        var text = storySegment.text;
        const url =`${GAME_URL}/tts`;
        var data = {text}
        const config = {
            responseType: 'blob',
            headers: {
                'access-token': '0207cb2d-2ee5-4645-98cc-821d57ed26b7'
            }
        };
        axios.post(url, data, config).then((response)=> {
        //    saveAs(response.data, 'speech.mp3')
            this.setState({url: URL.createObjectURL(response.data)});
        })
    }

    render() {
        console.log(this.state.story)
        const submit = this.onSubmit.bind(this);
        const analysis = this.props.analysis;
        const analysisStr = JSON.stringify(analysis,null,2);
        if(this.state.story && this.state.url.length==0) {
            const storySegment = this.state.story.storySegmentDto[this.state.index];
            if ((storySegment && storySegment.type == "Computer")) {
                this.playSegment(storySegment);
            }
        }
        return (
            <div className="App">
                    {this.state.story && (
                        <StoryText storySegment={this.state.story.storySegmentDto[this.state.index]} analysis={analysis}></StoryText>
                    )}
                    <div>
                        {this.state.url.length>0 && (
                            <div style={{visibility: 'hidden'}}>
                                <audio controls autoPlay>
                                    <source src={this.state.url} type={"audio/mpeg"}/>
                                </audio>
                            </div>
                        )}
                        <button onClick={this.state.record}>Start Recording</button>
                        <button onClick={this.state.stop}>Stop Recording</button>
                        <button onClick={submit}>Submit Recording</button>
                        {this.state.data && this.state.data.length >0  && (
                            <button onClick={URL.createObjectURL(this.state.data)}>Download</button>
                        )}
                        {analysis && (
                        <button onClick={this.onNext}>Next</button>
                        )}
                        {analysis && (
                            <JSONPretty id="json-pretty" data={analysis}></JSONPretty>
                        )}
                    </div>
            </div>
        )
    }
}


const mapState = ({platform: {student, analysis, story}}) => {
    return {
        student,
        analysis,
        story
    }
};

const mapDispatch = (dispatch) => {
    return {
        getStudent: (studentId) => dispatch(getStudent(studentId)),
        runAnalysis: (text, id, data) => dispatch(runAnalysis(text, id, data)),
        getStory: (storyId) => dispatch(getStory(storyId)),
    }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(BuddyBooks));