import { CREATE_LIST_CONFIG, FETCH_ALL_LIST_CONFIGS, RESET } from '../../constants';

export default function (state = [], action) {
  switch(action.type){
    case FETCH_ALL_LIST_CONFIGS:
      return action.listConfigs;
    case CREATE_LIST_CONFIG:
      return [...state, action.listConfig];
    case RESET:
      return [];
    default:
      return state;
  }
};