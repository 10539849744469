export default {
  lineChart: {
    height: '340px',
  },
  header: {
    backgroundColor: '#0087b6',
    display: 'grid',
    gridTemplateColumns: '1fr 7fr',
    padding: '15px'
  },
  icon: {
    padding: '5px',
    backgroundColor: '#310073',
    color: '#fff',
    justifySelf: 'center',
    alignSelf: 'center',
    borderRadius: '5px'
  },
  chartTitle: {
    color: '#fff',
    alignSelf: 'center',
    marginLeft: '10px'
  },
  empty: {
    marginTop: 150
  },
  placeholder: {
    textAlign: 'center',
    margin: '20px 0',
    padding: '40px 0'
  },
  button: {
    marginBottom: 15
  }
}