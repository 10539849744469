export default {
  replyWrapper: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    gridAutoFlow: 'column',
    alignSelf: 'center',
    margin: '10px 15px',
    gridColumn: 'span 2',
    '& .ql-mention-list-container': {
      overflow: 'scroll',
      maxHeight: 480
    },
    '& .mention': {
      fontWeight: 800
    },
  },
  reply: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridAutoFlow: 'column',
    alignSelf: 'center',
    padding: '8px 24px',
  },
  avatar: {
    gridRow: 1,
    alignSelf: 'start',
    marginTop: 10,
    marginRight: 20
  },
  replyContent:{
    gridRow: 1,
    marginTop: 10,
    alignSelf: 'center',
    color: 'black'
  },
  input: {
    width: '100%'
  },
  replyLike: {
    gridColumn: 2,
    justifySelf: 'end',
    alignSelf: 'end'
  },
  child: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    gridAutoFlow: 'column',
    alignSelf: 'center',
    margin: '10px 15px 10px 85px'
  },
  replyBtn: {
    display: 'grid',
    gridAutoFlow: 'column'
  }
}