import React, { Component } from 'react';
import { connect } from 'react-redux';
import ellipsis from '../../../assets/Loading/Ellipsis.svg';
import { FormattedMessage } from 'react-intl';
import {
    Button,
    Dialog,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    withStyles
} from '@material-ui/core';
import { Paper, Typography, Toolbar, Tooltip } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { CloudDownload, Close, CheckCircle, Search } from '@material-ui/icons';
import { getAllBooks, addStoryToStudent, fetchUserSkillDatabanks } from '../../../redux/actions';
import { stableSort, getSorting } from '../../../helpers';
import WizardToggleButton from '../Wizards/WizardToggleButton';
import styles from '../../../styles/importSkill.js';
import BookShare from './BookShare';
import BookShareCredentialsLogin from './BookShareCredentialsLogin';
import LoadingModal from './LoadingModal'

const rows = [
    { id: 'name', label: <FormattedMessage id='General.name' /> },
    { id: 'description', label: <FormattedMessage id='General.description' /> },
];

class ImportBook extends Component {

    state = {
        order: 'asc',
        orderBy: 'difficulty',
        selected: {},
        open: false,
        page: 0,
        rowsPerPage: 5,
        filteredData: [],
        searchValue: '',
        searchCategory: 'name',
        imported: [],
        loadingImportBook: false,
        importBookId: [],
        openModal: false,
        modalType: '',
        loginRequired: false
    };

    componentDidMount() {
        this.props.getAllBooks(this.props.id);
        this.props.fetchUserSkillDatabanks(false);
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.books !== this.props.books && this.state.loadingImportBook) {
            console.log('bookId, books, booklist ', this.state.importBookId, nextProps.books, nextProps.bookList);
            let newArr = this.state.importBookId.filter(item => {
                let bool = true;
                nextProps.books.forEach(({name}) => {
                    if (item === name) {
                        bool = false;
                    }
                });
                return bool;
            });
            console.log('newArr ', newArr);
            this.setState({ loadingImportBook: false, importBookId: newArr});
        }

        if (nextProps.user.hasBookShareCredentials && this.state.loginRequired) {
            this.setState({  loginRequired: false });
        }
    }

    handleSearch = (searchValue, data) => {
        const _data = this.props.skillDatabanks;
        const searchableData = data ? data : _data;
        console.log('searchableData', searchableData);
        const filtered = searchableData.filter(item => {
            const searchableKeys = Object.keys(item).filter(key => [...rows].some(row => row.id === key));
            return searchableKeys.some(key => {
                if (item[key]) {
                    return item[key].toLowerCase().includes(searchValue.toLowerCase())
                }
                else {
                    return false
                }
            })
        });
        console.log('searchValue ', this.state.searchValue);
        this.setState({ filteredData: filtered, page: 0 })
    };

    handleSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';
        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }
        this.setState({ order, orderBy });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    openWizard = () => {
        this.setState({ open: true })
    };

    closeWizard = () => {
        this.setState({ open: false });
    };

    importSkill = (book) => {
        if ((this.props.user.hasBookShareCredentials && book.source === "Bookshare") || book.source === "ObjectiveEd") {
            this.props.addStoryToStudent(this.props.id, book.id);
            if (!this.state.imported.includes(book.id)) {
                this.setState({ loadingImportBook: true, openModal: true, modalType: 'Loading', importBookId: [...this.state.importBookId, book.name] });
                this.setState(state => ({ imported: [...state.imported, book.id] }));
            }
            console.log('addImport ', book);
        } else {
            this.setState({ loginRequired: true });
        }
    };

    closeLoginModal =() => {
        this.setState({ loginRequired: false, modalType: '' });
    }

    closeLoadingPage = () => {
        this.setState({ openModal: false, modalType: '' });
    }

    render() {
        const { classes, bookList } = this.props;
        console.log('bookList', bookList);
        const { orderBy, order, open, page, rowsPerPage, searchValue, filteredData } = this.state;
        const data = filteredData.length || searchValue ? filteredData : bookList;
        return (
            <>
                <Dialog open={open} maxWidth='lg' onClose={this.closeWizard}>
                    <Paper className={classes.list}>
                        <Toolbar classes={{ root: classes.toolbar }}>
                            <Typography noWrap className={classes.header}>
                                <strong><FormattedMessage id='Databanks.importBook' /></strong>
                            </Typography>
                            <div className={classes.actions}>
                                <TextField
                                    variant='outlined'
                                    id='table-search'
                                    aria-label='search input'
                                    type='search'
                                    InputProps={{
                                        inputProps: {
                                            'aria-label': 'search input'
                                        },
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <Search />
                                            </InputAdornment>
                                        ),
                                        className: classes.searchBar,
                                    }}
                                    onChange={(ev) => {
                                        this.handleSearch(ev.target.value, bookList);
                                        this.setState({ searchValue: ev.target.value });
                                    }}
                                    value={this.state.searchValue}
                                />
                            </div>
                            <IconButton onClick={this.closeWizard} className={classes.close}>
                                <Close />
                            </IconButton>
                        </Toolbar>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {rows.length &&
                                        rows.map(row => {
                                            return (
                                                <TableCell
                                                    key={row.id}
                                                    sortDirection={orderBy === row.id ? order : false}
                                                >
                                                    <Tooltip
                                                        title={<FormattedMessage id='General.sort' />}
                                                        placement='bottom-start'
                                                        enterDelay={300}
                                                    >
                                                        <TableSortLabel
                                                            active={orderBy === row.id}
                                                            direction={order}
                                                            onClick={(ev) => this.handleSort(ev, row.id)}
                                                        >
                                                            {row.label}
                                                        </TableSortLabel>
                                                    </Tooltip>
                                                </TableCell>
                                            )
                                        })
                                    }
                                    <TableCell key='status'>
                                        <FormattedMessage id='Wizard.Braille.import' />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {stableSort(data, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map(_data => {
                                        return (
                                            <TableRow
                                                hover
                                                key={_data.id}
                                            >
                                                {rows.length &&
                                                    rows.map((row, index) => {
                                                        return (
                                                            <TableCell
                                                                key={index}
                                                            >
                                                                {
                                                                    _data[row.id] && _data[row.id] !== ' '
                                                                        ? _data[row.id]
                                                                        : 'N/A'
                                                                }
                                                            </TableCell>
                                                        )
                                                    })
                                                }
                                                <TableCell>
                                                    <IconButton>
                                                        {
                                                            this.state.imported.includes(_data.id)
                                                                ? this.state.loadingImportBook && this.state.importBookId.includes(_data.name) ? 
                                                                    <>
                                                                        <img className={classes.loading} src={ellipsis} height={25} width={25} alt='loading...' />
                                                                        <LoadingModal openLoading={this.state.openModal} type={this.state.modalType} exitFunc={this.closeLoadingPage} />
                                                                    </> :
                                                                    <CheckCircle />
                                                                : <CloudDownload onClick={() => this.importSkill(_data)} />
                                                        }
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                }
                                {
                                    <TableRow>
                                        <TableCell colSpan={3} align='center'>
                                            <BookShare history={this.props.history} id={this.props.id} />
                                        </TableCell>
                                    </TableRow>
                                }
                                {
                                    this.state.imported.length > 0
                                        ? <TableRow>
                                            <TableCell />
                                            <TableCell />
                                            <TableCell align='right'>
                                                <Button color='primary' variant='contained' onClick={this.closeWizard}>
                                                    <FormattedMessage id='General.done' />
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                        : <TableRow />
                                }
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15]}
                            component='div'
                            count={bookList.length}
                            labelRowsPerPage={<FormattedMessage id='Datatable.rowsPerPage' />}
                            rowsPerPage={this.state.rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                                'aria-label': 'Previous Page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next Page',
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </Paper>
                </Dialog>

                {
                    this.state.loginRequired &&
                    <BookShareCredentialsLogin openLoading={this.state.loginRequired}  exitFunc={this.closeLoginModal} />
                }

                <WizardToggleButton
                    openWizard={this.openWizard}
                    text={<FormattedMessage id='Databanks.importBook' />}
                />
            </>
        )
    }
}

const mapState = ({ platform: { books, bookList, skillDatabanks }, shared: { user } }) => {
    return {
        books,
        bookList,
        skillDatabanks,
        user
    }
};

const mapDispatch = (dispatch, { history }) => {
    return {
        getAllBooks: (studentId) => dispatch(getAllBooks(studentId)),
        fetchUserSkillDatabanks: (includeSelf) => dispatch(fetchUserSkillDatabanks(includeSelf, history)),
        addStoryToStudent: (studentId, bookId) => dispatch(addStoryToStudent(studentId, bookId))
    }
};

export default connect(mapState, mapDispatch)(withStyles(styles)(ImportBook));