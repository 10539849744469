export default {
  container: {
    marginTop: 40,
    display: 'grid'
  },
  card: {
    padding: '0 40px',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    marginBottom: 20,
  },
  searchContainer: {
    padding: '0 40px',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    marginBottom: 20,
    backgroundColor: '#45458d',
  },
  searchText: {
    gridColumn: 1,
    alignSelf: 'center',
    marginRight: 10,
    color: '#fff'
  },
  searchBar: {
    gridColumn: 2,
    margin: '10px auto',
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: 6
  },
  backBtn: {
    justifySelf: 'start'
  }
}