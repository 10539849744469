import React from 'react';
import { connect } from 'react-redux';
import WizardToggleButton from "../WizardToggleButton";
import GoalWizardPage1 from './GoalWizardPage1';
import { createGoal, updateGoal, addSkillToGoal } from '../../../../redux/actions';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../../styles/wizardBase';
import { FormattedMessage } from 'react-intl';

class GoalWizardBase extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      open: this.props.open || false,
      name: this.props.data ? this.props.data.name : '',
      description: this.props.data ? this.props.data.description : '',
      id: this.props.data ? this.props.data.id : '',
      status: this.props.data ? this.props.data.status : 'Active',
      page: 1
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.open !== nextProps.open) {
      this.setState({ open: nextProps.open })
    }
  }

  openWizard = () => {
    this.setState({ open: true })
  };

  closeWizard = () => {
    this.setState({ open: false, page: 1 });
    if (this.props.close) this.props.close();
  };

  next = () => {
    this.setState({ page: this.state.page+1 });
  };

  back = () => {
    this.setState({ page: this.state.page-1 });
  };

  goToPage = (page) => {
    this.setState({ page });
  };

  submit = (goal) => {
    if (this.props.guided) {
      this.props.createGoal(this.props.id, goal, true)
        .then(goal => {
          this.props.addSkillToGoal(goal.id, this.props.skillDatabank)
        })
    }
    else {
      this.props.createGoal(this.props.id, goal);
    }
    this.closeWizard();
  };

  save = (goal) => {
    this.props.updateGoal(goal);
    this.closeWizard();
  };

  handleChange = (ev) => {
    this.setState({ [ev.target.name]: ev.target.value })
  };

  render(){
    const { page, name, description, id, status, open } = this.state;
    const { classes, drawer, edit } = this.props;
    const goal = { name, description, id, status };
    return (
      <div id='goal-wizard'>
      {
        page === 1 &&
        <GoalWizardPage1
          goal={goal}
          open={open}
          edit={edit}
          close={this.closeWizard}
          next={this.next}
          save={this.save}
          goToPage={this.goToPage}
          onSubmit={this.submit}
          handleChange={this.handleChange}
        />
      }
      {
        drawer ?
          <Button
            onClick={this.openWizard}
            variant='contained'
            className={classes.goalButton}
            onSubmit={this.submit}
          >
            <FormattedMessage id='Goals.add'/>
          </Button>
        : !this.props.edit && <WizardToggleButton
          openWizard={this.openWizard}
          text={<FormattedMessage id='Goals.add'/>}
        />
      }
      </div>
    )
  }
}

const mapState = ({ platform: { skillDatabank }}) => {
  return {
    skillDatabank
  }
};

const mapDispatch = (dispatch, { history }) => {
  return {
    createGoal: (studentId, goal, guided) => dispatch(createGoal(studentId, goal, history, guided)),
    updateGoal: (goal) => dispatch(updateGoal(goal)),
    addSkillToGoal: (goalId, skill) => dispatch(addSkillToGoal(goalId, skill))
  }
};

export default connect(mapState, mapDispatch)(withStyles(styles)(GoalWizardBase));