import React, {Component} from 'react';
import {connect} from 'react-redux';
import {fetchDeletedStudents} from "../../../redux/actions";
import DataTable from "../Shared/DataTable";
import {FormattedMessage} from 'react-intl';
import {Button, Typography, IconButton, FormGroup} from '@material-ui/core';
import { Paper, TextField, Toolbar, Tooltip, Icon, Switch } from '@material-ui/core';
import {Dialog, DialogContent, DialogTitle, DialogContentText,DialogActions} from '@material-ui/core';
import { stableSort, getSorting } from '../../../helpers';
import {Close} from '@material-ui/icons';
import {withStyles} from '@material-ui/core/styles';
import styles from '../../../styles/dataTable.js';
import WizardToggleButton from '../Wizards/WizardToggleButton';
import axios from 'axios';
import { Delete, PlayCircleFilled, Search, CloudDownload } from '@material-ui/icons';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    TableSortLabel,
    InputAdornment,
} from '@material-ui/core';
import {
    ROOT_URL
} from '../../../redux/constants';

const rows = [
    {id: 'firstName', label: <FormattedMessage id='General.firstName'/>, searchable: true},
    {id: 'lastName', label: <FormattedMessage id='General.lastName'/>, searchable: true},
    {id: 'gamerTag', label: 'UserName', searchable: true}
];

class RestoreStudentsTable extends Component {
    state = {
        order: 'asc',
        orderBy: 'name',
        rowsPerPage: 5,
        page: 0,
        filteredData: [],
        searchValue: '',
        checked: {},
        open: false
    };

    handleSearch = (searchValue) => {
        const searchableData = this.props.studentTutorials;
        const filtered = searchableData.filter(item => {
            const searchableKeys = rows.map(row => row.id);
            return searchableKeys.some(key => {
                if (item[key]){
                    return item[key].toLowerCase().includes(searchValue.toLowerCase())
                }
                else {
                    return false;
                }
            })
        });
        this.setState({ filteredData: filtered, page: 0 })
    };

    handleSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';
        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }
        this.setState({ order, orderBy });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleToggle = (data) => {

    };

    render() {
        const { classes, title } = this.props;
        const {
            rowsPerPage,
            page,
            orderBy,
            order,
            searchValue,
            filteredData,
        } = this.state;
        const data = filteredData.length || searchValue ? filteredData : this.props.data;
        return(
        <div className={classes.tableWrapper}>
            <Table>
                <TableHead>
                    <TableRow>
                        {
                            rows.length &&
                            rows.map((row, index) => {
                                return (
                                    <TableCell
                                        aria-label={row.id}
                                        key={row.id+index}
                                        sortDirection={ orderBy === row.id ? order : false }
                                    >
                                        <Tooltip
                                            title='Sort'
                                            placement='bottom-start'
                                            enterDelay={300}
                                        >
                                            <TableSortLabel
                                                active={orderBy === row.id}
                                                direction={order}
                                                onClick={(ev) => this.handleSort(ev, row.id)}
                                            >
                                                {row.label}
                                            </TableSortLabel>
                                        </Tooltip>
                                    </TableCell>
                                )
                            })
                        }
                        <TableCell align='center'>   {this.props.noActions ? "": "Actions"}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {stableSort(data, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((_data, index) => {
                            return (
                                <TableRow className={classes.hoverRow} tabIndex={0} hover key={_data.id+index}>
                                    {rows.length &&
                                    rows.map((row, index) => {
                                        return row.id === 'timed'
                                            ? <TableCell aria-label={row.id} className={classes.cell} key={index+row.id}>
                                                <Switch
                                                    checked={_data.timed}
                                                    onChange={() => this.handleToggle(_data)}
                                                    inputProps={{ 'aria-label': 'toggle timed' }}
                                                />
                                            </TableCell>
                                            :  <TableCell aria-label={row.id} className={classes.cell} key={index+row.id}>
                                                {
                                                    _data[row.id] && _data[row.id] !== ' '
                                                        ? _data[row.id]
                                                        : 'N/A'
                                                }
                                            </TableCell>
                                    })
                                    }
                                    <TableCell align='right'>
                                        <div className={classes.buttons}>
                                            <Tooltip title={<FormattedMessage id="Wizard.Student.restore"/>}>
                                                <IconButton aria-label='delete' onClick={() => this.props.restore(_data)}>
                                                    <Icon className='fas fa-sync-alt' />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={<FormattedMessage id="General.delete"/>}>
                                                <IconButton aria-label='delete' onClick={() => this.props.delete(_data)}>
                                                    <Delete color='secondary'/>
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            );
                        })
                    }
                </TableBody>
            </Table>
        </div>);
    }

}



const mapState = ({platform: {deletedStudents}, shared: {user}}) => {
    return {
        deletedStudents,
        user
    }
};

const mapDispatch = (dispatch, {history}) => {
    return {
        fetchDeletedStudents: () => dispatch(fetchDeletedStudents(history))
    }
};

export default connect(mapState, mapDispatch)(withStyles(styles)(RestoreStudentsTable));