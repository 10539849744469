import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { FormattedMessage } from 'react-intl';
import Avatar from 'react-avatar';
import {
  Paper,
  List,
  ListItem,
  withStyles,
  Divider,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Button, Icon, Tooltip
} from '@material-ui/core';
import { AddBox, VerifiedUserTwoTone, MoreVert } from '@material-ui/icons';
import { createNotification, changeMemberPrivileges, removeFromGroup, searchProfiles, joinGroup } from '../../redux/actions';
import baseStyle from '../../styles/group';
import StaffInviteWizard from '../../Platform/components/Wizards/Staff/StaffInviteWizard';

class MemberList extends Component {
  state = {
    members: this.props.group.members,
    users: [],
    open: false,
    search: false,
    page: 1
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.group !== nextProps.group){
      this.setState({ members: nextProps.group.members })
    }
  }

  addMemberToGroup = () => {
    this.state.users.forEach(user => {
      const member = this.props.profiles.find(_user => user.value === _user.key);
      if (member.key) {
        this.props.joinGroup(this.props.group, member.key)
      }
    });
    const newMembers = this.state.users.map(user => this.props.profiles.find(_user => user.value === _user.key));
    this.setState(({ members }) => ({ members: [...newMembers, ...members], users: [] }))
  };

  handleSelect = (ev) => {
    this.setState({ users: ev, search: false })
  };

  handleMenu = (name) => {
    this.setState((state) => ({ [name]: !state[name] }))
  };

  viewMore = () => {
    this.setState(({page}) => ({ page: page+1 }))
  };

  changePermission = (memberKey, role) => {
    this.props.changeMemberPrivileges(this.props.group.key, memberKey, role);
  };

  search = (val) => {
    if (val) this.props.searchProfiles(val)
    this.setState({ search: true })
  };

  openSpecialistCreator = () => {
    this.setState({ open: true })
  };

  close = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, isAdmin, isMod, group, profile, profiles, myself } = this.props;
    const { members, page } = this.state;
    const searchable = [];
    members && profiles.forEach(user => members.some(member => user.key === member.key)
      ? null
      : searchable.push({ label: user.name+" ("+user.email+")", value: user.key })
    );
    return (
      <Paper>
        <Typography align='center' variant='h5' className={classes.memberTitle}>
          <FormattedMessage id='MemberList.title'/>
        </Typography>
        <Divider className={classes.divider}/>
        {
          ((!group.isPrivate && group.type !== 'GLOBAL' ) || isAdmin ) &&
          <>
            <div className={classes.inviteMember}>
              <Typography gutterBottom variant='subtitle1'> <FormattedMessage id='MemberList.invite'/></Typography>
              <div className={classes.inviteInput}>
                <Select
                  isMulti
                  components={{
                    DropdownIndicator: null,
                  }}
                  placeholder={<FormattedMessage id='AddGroup.members'/>}
                  options={searchable}
                  className={classes.inviteSelect}
                  onChange={this.handleSelect}
                  value={this.state.users}
                  onInputChange={this.search}
                  noOptionsMessage={() => this.state.search ? 'No members found...' : 'Type a name to search'}
                />
                <IconButton
                  className={classes.inviteBtn}
                  onClick={this.addMemberToGroup}
                >
                  <AddBox/>
                </IconButton>
              </div>
            </div>
            <div className={classes.inviteSpecialist}>
              <Typography variant='caption'>Can't find a specialist in the search above?</Typography>
              <Button size='small' onClick={this.openSpecialistCreator}> invite them here </Button>
              <StaffInviteWizard close={this.close} type='group' open={this.state.open}/>
            </div>
          </>
        }
        <List aria-label='groupList'>
          {
            members &&
            members.slice(0,(page*10)).map(member => (
              <ListItem
                key={member.key}
                aria-label='item'
                className={classes.listItem}
              >
                <Avatar round size='50' name={member.name} src={member.imgSrc}/>
                <Typography variant='subtitle2' className={classes.member}>
                  {member.name}
                </Typography>
                {
                  member.role === 'Administrates'
                  ? <Tooltip title={<FormattedMessage id='MemberList.admin'/>}>
                      <Icon className='fa fa-crown admin'/>
                    </Tooltip>
                  : member.role === 'Moderates' && (
                    <Tooltip title={<FormattedMessage id='MemberList.mod'/>}>
                      <VerifiedUserTwoTone />
                    </Tooltip>
                  )
                }
                {
                  isAdmin || isMod
                  ? <IconButton
                    aria-label='admin-menu'
                    color='inherit'
                    buttonRef={node => {
                      this[member.name] = node;
                    }}
                    onClick={() => this.handleMenu(member.name)}
                  >
                    <MoreVert/>
                    <Menu
                      open={this.state[member.name] || false}
                      anchorEl={this[member.name]}
                      getContentAnchorEl={null}
                    >
                      {
                        member.role === 'Administrates'
                        ? <MenuItem
                            aria-label='click to make admin'
                            onClick={() => this.changePermission(member.key, 'Belongs')}
                          >
                            <Typography variant='subtitle2'><FormattedMessage id='MemberList.removeAdmin'/></Typography>
                          </MenuItem>
                        : <MenuItem
                            aria-label='click to make admin'
                            onClick={() => this.changePermission(member.key, 'Administrates')}
                          >
                            <Typography variant='subtitle2'><FormattedMessage id='MemberList.makeAdmin'/></Typography>
                          </MenuItem>
                        }
                        {
                          member.role === 'Moderates'
                          ? <MenuItem
                              aria-label='click to make moderator'
                              onClick={() => this.changePermission(member.key, 'Belongs')}
                            >
                              <Typography variant='subtitle2'><FormattedMessage id='MemberList.removeMod'/></Typography>
                            </MenuItem>
                          : <MenuItem
                              aria-label='click to make moderator'
                              onClick={() => this.changePermission(member.key, 'Moderates')}
                            >
                              <Typography variant='subtitle2'><FormattedMessage id='MemberList.makeMod'/></Typography>
                            </MenuItem>
                        }
                      <MenuItem
                        aria-label='click to make moderator'
                        onClick={() => removeFromGroup(group, member.key)}
                      >
                        <Typography variant='subtitle2'>
                          {
                            member.key === profile.key
                            ? <FormattedMessage id='MemberList.leave'/>
                            : <FormattedMessage id='MemberList.remove'/>
                          }
                        </Typography>
                      </MenuItem>
                    </Menu>
                  </IconButton>
                  : member.key === profile.key && myself.role !== 'Invited' &&
                    <IconButton
                      aria-label='admin-menu'
                      color='inherit'
                      buttonRef={node => {
                        this[member.name] = node;
                      }}
                      onClick={() => this.handleMenu(member.name)}
                    >
                      <MoreVert/>
                      <Menu
                        open={this.state[member.name] || false}
                        anchorEl={this[member.name]}
                        getContentAnchorEl={null}
                      >
                        <MenuItem
                          aria-label='click to leaveGroup'
                          onClick={() => removeFromGroup(group, member.key)}
                        >
                          <Typography variant='subtitle2'>
                            <FormattedMessage id='MemberList.leave'/>
                          </Typography>
                        </MenuItem>
                      </Menu>
                    </IconButton>
                }
              </ListItem>
            ))
          }
        </List>
        {
          members && members.slice(0,(page*10)).length < members.length &&
          <Button
            className={classes.viewMore}
            onClick={this.viewMore}
          >
            <FormattedMessage id='General.viewMore'/>
          </Button>
        }
      </Paper>
    );
  }
}

const mapState = ({ social: { group, profile, profiles }}) => {
  return {
    group,
    profile,
    profiles
  }
};

const mapDispatch = (dispatch) => {
  return {
    createNotification: (notification) => dispatch(createNotification(notification)),
    changeMemberPrivileges: (groupKey, profileKey, role) => dispatch(changeMemberPrivileges(groupKey, profileKey, role)),
    searchProfiles: (searchVal) => dispatch(searchProfiles(searchVal)),
    joinGroup: (group, profileKey) => dispatch(joinGroup(group, profileKey))
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(MemberList));