export default {
  wizardButton: {
    // width: '180px',
    marginTop: '20px',
    color: '#fff',
    backgroundColor:'#00729b',
    '&:hover': {
      backgroundColor:'#005C87'
    },
    '&:focus': {
      backgroundColor:'#005C87'
    },
  },
}