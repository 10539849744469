export default {
  container: {
    margin: '0 5px',
  },
  inline: {
    display: 'inline'
  },
  searchContainer: {
    backgroundColor: '#45458d',
    marginBottom: 10,
    padding: '10px 20px',
    '& .searchBar': {
      backgroundColor: '#fff',
      width: '100%',
      borderRadius: 6
    },
  },
  post: {
    marginBottom: 10
  },
  textPost: {
    padding: '8px 16px',
    marginBottom: 10,
    display: 'grid',
  },
  pinnedPost: {
    padding: '8px 16px',
    marginBottom: 10,
    display: 'grid',
  },
  title: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridAutoFlow: 'column',
    alignSelf: 'center',
    marginTop: 10,
    marginLeft: 15,
    '& .text': {
      display: 'grid',
      gridAutoFlow: 'column',
      gridTemplateColumns: 'auto',
      gridColumnGap: '5px',
      alignSelf: 'center',
      justifyContent: 'start',
      marginLeft: 20,
      marginBottom: 10,
    },
    '& .subtext': {
      alignSelf: 'end'
    },
    '& .chip': {
      alignSelf: 'center'
    }
  },
  content: {
    gridRow: 2,
    gridColumn: 'span 2',
    '& .post-title': {
      margin: 20,
    },
    '& .text': {
      margin: 20,
      '& .ql-video': {
        marginTop: 20,
        display: 'block',
        margin: '0 auto',
        width: 560,
        height: 315
      }
    }
  },
  timestamp: {
    marginLeft: 15,
    justifySelf: 'start',
    alignSelf: 'center'
  },
  divider: {
    gridColumn: 'span 2',
    margin: '5px 20px'
  },
  replyBtn: {
    gridRow: 2,
    justifySelf: 'end',
    margin: 5,
    marginLeft: 20
  },
  buttons: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridColumn: 2,
    gridRow: 4,
    justifySelf: 'end',
    marginRight: 25,
    '& .text': {
      alignSelf: 'center',
    },
  },
  goalButtons: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridColumn: 2,
    gridRow: 4,
    justifySelf: 'end',
    marginRight: 15,
    '& .text': {
      alignSelf: 'center',
      marginLeft: 10
    },
  },
  replyContainer: {
    gridColumn: 'span 2',
  },
  replyWrapper: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    gridAutoFlow: 'column',
    alignSelf: 'center',
    margin: '10px 15px'
  },
  reply: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    gridAutoFlow: 'column',
    alignSelf: 'center',
    padding: '8px 24px',
  },
  child: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    gridAutoFlow: 'column',
    alignSelf: 'center',
    margin: '10px 15px 10px 85px'
  },
  avatar: {
    gridRow: 1,
    alignSelf: 'start',
    marginTop: 20,
    marginRight: 20
  },
  replyContent:{
    gridRow: 1,
    alignSelf: 'center',
    marginTop: 10,
  },
  replyLike: {
    display: 'grid',
    gridAutoFlow: 'column',
    justifySelf: 'start',
    alignSelf: 'center',
    gridRow: 2,
    '& .text': {
      alignSelf: 'center',
    },
    '& .btn': {
      gridColumn: 1,
      alignSelf: 'center',
    },
    '& .time': {
      gridColumn: 4,
      marginLeft: 15,
      alignSelf: 'center'
    },
    marginRight: 20
  },
  img: {
    display: 'block',
    margin: '0 auto',
    maxWidth: '80%',
  },
  star: {
    color: 'gold'
  },
  liked: {
    color: '#0087b6'
  },
  locked: {
    gridColumn: 2,
    gridRow: 1,
    alignSelf: 'center',
    justifySelf: 'end',
    marginRight: 15
  },
  cancel: {
    gridColumn: 3,
    gridRow: 1,
    alignSelf: 'center',
    justifySelf: 'end'
  },
  replies: {
    justifyContent: 'start',
    marginLeft: 85,
  },
  replyViewMore: {
    justifyContent: 'center',
    marginLeft: '50%',
  },
  viewMore: {
    gridColumn: 'span 2'
  },
  announcements:{
    padding: 10,
    marginBottom: 20,
    borderRadius: 4,
    backgroundColor: '#45458d',
    color: 'white',
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
    '& .title':{
      marginLeft: 5,
      color: 'white'
    }
  },
  footer: {
    display: 'grid',
    justifyContent: 'center'
  },
  likes: {
    '&:hover': {
      cursor: 'pointer',
      '&.text': {
        fontWeight: 600
      }
    },
  },
  noPosts: {
    marginTop: 20,
    padding: 20,
    textAlign: 'center'
  },
  '@media (max-width: 500px)': {
    textPost: {
      padding: '8px 16px',
      marginBottom: 10,
      display: 'grid',
      gridTemplateColumns: '1fr'
    },
    content: {
      gridRow: 2,
      width: '100%',
      '& .post-title': {
        margin: 20,
      },
      '& .text': {
        margin: 20,
      }
    },
    locked: {
      display: 'none'
    },
    buttons: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridColumn: 1,
      gridRow: 4,
      justifySelf: 'end',
      marginRight: 25,
      '& .text': {
        alignSelf: 'center',
      },
    },
  }
}