import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import { Button, FormGroup, Typography, IconButton, TextField, MenuItem, Divider, withStyles } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { ROOT_URL } from '../../../../redux/constants';
import baseStyle from '../../../../styles/wordHunt';

class DisplayQuizPROTO extends Component {

  state = {
    questions: [{ answer: '', questionText: '' }],
    brailleText: '',
    time: 1,
    roundCount: 1,
    shuffle: false,
    difficulty: 'Easy',
    tested: false,
    error: {}
  };

  componentDidMount() {
    if (this.props.level) {
      const variables = JSON.parse(this.props.level.levelJson);
      const questions = variables.questions.map(question => ({ answer: question.answers.join(','), questionText: question.questionText }));
      this.setState({
        ...variables,
        questions,
        difficulty: this.props.level.difficulty,
      })
    }
  };

  close = () => {
    this.props.close();
  };

  handleChange = (ev) => {
    const value = ev.target.type === 'number' ? ev.target.value > 0 ? ev.target.value * 1 : '' : ev.target.value;
    this.setState({ [ev.target.name]: value })
  };

  adQuestion = () => {
    this.setState({ questions: [...this.state.questions, { answer: '', questionText: '' }]})
  };

  changeQuestion = (ev, index) => {
    const questions = this.state.questions;
    questions[index].questionText = ev.target.value;
    this.setState({ questions })
  };

  changeAnswer = (ev, index) => {
    const questions = this.state.questions;
    questions[index].answer = ev.target.value;
    this.setState({ questions })
  };

  removeQuestion = (index) => {
    let questions = this.state.questions;
    if (questions.length > 1) {
      questions.splice(index, 1);
    }
    else {
      questions = [{ answer: '', questionText: '' }];
    }
    this.setState({ questions })
  };

  runTest = () => {
    const { tested, roundCount, shuffle, brailleText, time } = this.state;
    const questions = this.state.questions.filter(entry => !!entry.questionText);
    const url = this.props.type === 'staff'
      ? `${ROOT_URL}/staff/${this.props.id}/objective-databank/test`
      : `${ROOT_URL}/admin/objective-databank/test`;
    if (tested) this.clearTest();
    axios.post(url, {
      levelVariables: JSON.stringify({
        questions: questions.map(question => ({ questionText: question.questionText, answers: question.answer.split(',')})),
        roundCount,
        shuffle,
        brailleText,
        time: time*60,
      }),
      templateId: '-4450872151129777706'
    }).catch(err => console.log(err));
    this.setState({ tested: true });
  };

  clearTest = () => {
    const url = this.props.type === 'staff'
      ? `${ROOT_URL}/staff/${this.props.id}/objective-databank/test`
      : `${ROOT_URL}/admin/objective-databank/test`;
    axios.delete(url)
      .catch(err => console.log(err));
    this.setState({ tested: false })
  };

  onSubmit = () => {
    const { difficulty, roundCount, shuffle, brailleText, time } = this.state;
    const questions = this.state.questions.filter(entry => !!entry.questionText);
    const levelJson = JSON.stringify({
      questions: questions.map(question => ({ questionText: question.questionText, answers: question.answer.split(',')})),
      roundCount,
      shuffle,
      time: time*60,
      brailleText
    });
    const skill = { levelJson, difficulty, levelTemplateId: '-4450872151129777706' };
    this.props.onSubmit(skill);
  };

  checkPhrase = (index) => {
    if (this.state.questions[index].questionText === '') {
      this.setState({ error: {...this.state.error, [index]: true }})
    }
    else {
      let error = this.state.error;
      delete error[index];
      this.setState({ error })
    }
  };

  checkText = () => {
    if (this.state.brailleText === '') {
      this.setState({ error: {...this.state.error, text: true }})
    }
    else {
      let error = this.state.error;
      delete error.text;
      this.setState({ error })
    }
  };

  checkTime = () => {
    if (this.state.time <= 0) {
      this.setState({ error: {...this.state.error, time: true }})
    }
    else {
      let error = this.state.error;
      delete error.time;
      this.setState({ error })
    }
  };

  render() {
    const { classes } = this.props;
    const { tested } = this.state;
    return (
      <>
        <Dialog
          maxWidth='md'
          fullWidth
          disableEscapeKeyDown
          open={this.props.open}
          onClose={this.props.close}
          aria-labelledby='form-dialog-title'
          PaperProps={{ classes: { root: classes.container } }}
        >
          <DialogTitle disableTypography={true} className={classes.title} id='form-dialog-title'>
            <Typography className={classes.titleText} variant='h4'>
              <FormattedMessage id='Wizard.skillDB.title'/>
            </Typography>
            <IconButton
              onClick={this.close}
              className={classes.exitIcon}
              aria-label="close dialog"
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography gutterBottom variant='h3'>
              Display Quiz
            </Typography>
            <Typography className={classes.description} variant='subtitle1'>
              A game using braille displays to teach students to read large amounts of text and answer questions about them.
            </Typography>
            <Divider className={classes.divider}/>
            <FormGroup className={classes.configureLine}>
              <Typography variant='h6'>Number of rounds: </Typography>
              <TextField
                variant='outlined'
                className={classes.configComponent}
                name='roundCount'
                type='number'
                value={this.state.roundCount}
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup className={classes.configureLine}>
              <Typography variant='h6'>Enter text for braille here: </Typography>
              <TextField
                variant='outlined'
                className={classes.configComponent}
                name='brailleText'
                error={this.state.error.text}
                helperText={this.state.error.text ? 'Text cannot be blank' : ''}
                multiline
                rows={5}
                type='text'
                onBlur={this.checkText}
                value={this.state.brailleText}
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup className={classes.configureLine}>
              <Typography variant='h6'>How long should the student have to take the quiz (in minutes)? </Typography>
              <TextField
                variant='outlined'
                className={classes.configComponent}
                name='time'
                error={this.state.error.time}
                helperText={this.state.error.time ? 'Time cannot be 0' : ''}
                type='number'
                onBlur={this.checkTime}
                value={this.state.time}
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup className={classes.configureLine}>
              <Typography variant='h6'>Should they be shuffled? </Typography>
              <TextField
                select
                variant='outlined'
                className={classes.configComponent}
                name='shuffle'
                value={this.state.shuffle}
                onChange={this.handleChange}
              >
                <MenuItem value={true}> Yes </MenuItem>
                <MenuItem value={false}> No </MenuItem>
              </TextField>
            </FormGroup>
            <FormGroup className={classes.configureLine}>
              <Typography variant='h6'>
                <FormattedMessage id='General.difficulty'/>:
              </Typography>
              <TextField
                select
                variant='outlined'
                id='difficulty-select'
                name='difficulty'
                value={this.state.difficulty}
                onChange={this.handleChange}
              >
                {
                  [
                    { value: 'Easy', text: <FormattedMessage id='General.easy'/> },
                    { value: 'Medium', text: <FormattedMessage id='General.medium'/> },
                    { value: 'Hard', text: <FormattedMessage id='General.hard'/> }
                  ].map((option) => <MenuItem key={option.value} value={option.value}>{option.text}</MenuItem>)
                }
              </TextField>
            </FormGroup>
            <Typography className={classes.phraseTitle} variant='h6'>Phrases to use in game: </Typography>
            {
              this.state.questions.map((question,index) => (
                <div className={classes.question} key={index}>
                  <FormGroup className={classes.questionLine}>
                    <Typography className={classes.lineText} variant='h6'><FormattedMessage id='Wizard.BrailleQuiz.question' />: </Typography>
                    <TextField
                      variant='outlined'
                      error={this.state.error[index]}
                      helperText={this.state.error[index] ? 'Question cannot be blank' : ''}
                      className={classes.configComponent}
                      name='question'
                      value={this.state.questions[index].questionText}
                      onChange={(ev) => this.changeQuestion(ev, index)}
                      onBlur={() => this.checkPhrase(index)}
                    />
                  </FormGroup>
                  <FormGroup className={classes.questionLine}>
                    {/*<Typography className={classes.lineText}  variant='h6'><FormattedMessage id='Wizard.BrailleQuiz.answer' /> (if multiple answers, separate each with a comma): </Typography>*/}
                    <Typography className={classes.lineText}  variant='h6'><FormattedMessage id='Wizard.BrailleQuiz.answer' />: </Typography>
                    <TextField
                      variant='outlined'
                      error={this.state.error[index]}
                      helperText={this.state.error[index] ? 'Answer cannot be blank' : ''}
                      className={classes.configComponent}
                      name='answer'
                      value={this.state.questions[index].answer}
                      onChange={(ev) => this.changeAnswer(ev, index)}
                      onBlur={() => this.checkPhrase(index)}
                    />
                  </FormGroup>
                  <IconButton onClick={() => this.removeQuestion(index)} classes={{root:classes.delete}}>
                    <Close color='secondary'/>
                  </IconButton>
                </div>
              ))
            }
            <div className={classes.questionBtns}>
              <Button
                variant='contained'
                id='add-question'
                color='primary'
                classes={{ root: classes.addButton }}
                onClick={this.adQuestion}
              >
                Add a Question
              </Button>
            </div>
          </DialogContent>
          <DialogActions className={classes.buttons}>
            <Button id='back' onClick={this.props.back} color='secondary'>
              <FormattedMessage id='General.back'/>
            </Button>
            <Button
              onClick={this.runTest}
              variant={tested ? 'outlined' : 'text'}
              color={tested ? 'secondary' : 'primary'}
            >
              {tested ? <FormattedMessage id='General.retest'/> : <FormattedMessage id='General.test'/>}
            </Button>
            <Button
              disabled={Object.keys(this.state.error).length > 0}
              aria-label='go to final page of skill creator'
              onClick={this.onSubmit}
              color='primary'
            >
              <FormattedMessage id='General.next'/>
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default withStyles(baseStyle)(DisplayQuizPROTO);