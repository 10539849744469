import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { createQuestion } from '../../../redux/actions';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, withStyles } from '@material-ui/core';
import baseStyle from '../../../styles/helpConfig';

class AddQuestion extends Component {

  state = {
    open: false,
    question: '',
    answer: ''
  };

  toggleDialog = () => {
    this.setState({ open: !this.state.open })
  };

  handleChange = ({ target: { name, value }}) => {
    this.setState({ [name]: value })
  };

  submit = () => {
    const { question, answer } = this.state;
    this.props.createQuestion({ question, answer }, this.props.pageId)
    this.toggleDialog()
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <Dialog
          fullWidth
          open={this.state.open}
          aria-label='New page creator'
          onClose={this.toggleDialog}
        >
          <DialogTitle> Add A New Question </DialogTitle>
          <DialogContent>
            <TextField
              className={classes.line}
              fullWidth
              variant='outlined'
              name='question'
              label='Question'
              value={this.state.question}
              onChange={this.handleChange}
            />
            <TextField
              className={classes.line}
              fullWidth
              multiline
              rows={3}
              variant='outlined'
              name='answer'
              label='Answer'
              onChange={this.handleChange}
              value={this.state.answer}
            />
          </DialogContent>
          <DialogActions>
            <Button
              color='secondary'
              onClick={this.toggleDialog}
            >
              <FormattedMessage id='General.cancel'/>
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={this.submit}
            >
              <FormattedMessage id='General.submit'/>
            </Button>
          </DialogActions>
        </Dialog>
        <Button
          className={classes.addBtn}
          variant='contained'
          aria-label='toggle page creator'
          onClick={this.toggleDialog}
        >
          Add Question
        </Button>
      </>
    );
  }
}

const mapDispatch = (dispatch) => {
  return {
    createQuestion: (question, pageId) => dispatch(createQuestion(question, pageId))
  }
};

export default connect(null, mapDispatch)(withStyles(baseStyle)(AddQuestion));