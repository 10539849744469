export default {
  root: {
    position: 'fixed',
    bottom: 10,
    right: 10
  },
  chatBox: {
    position: 'absolute',
    right: '30%',
    bottom: '100%',
    height: 480,
    width: 320
  },
  title: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    color: '#f9f3ff',
    paddingRight: 12,
    backgroundColor: '#484ca0',
    borderRadius: '4px 4px 0px 0px',
    boxShadow: '0px 1px 1px #6b6b6b'
  },
  chat: {
    padding: '10px 25px',
    height: 320,
    overflow: 'scroll'
  },
  input: {
    position: 'absolute',
    bottom: 0,
    padding: 5,
    width: 310
  },
  chatBtn: {
    fontSize: 40
  },
  chatLine: {
    width: 'fit-content',
  },
  chatLineSpace: {
    paddingBottom: 0
  },
  bubble: {
    height: 'auto',
    backgroundColor: 'blue',
    borderRadius: 30,
    color: 'white',
    padding: '5px 15px'
  }
}