export default {
  container: {
    display: 'grid',
    gridTemplateColumns: `repeat(24, 1fr)`,
    gridRowGap: '5px',
    justifySelf: 'center',
    width: 'fit-content'
  },
  tooltip: {
    fontSize: 24
  },
  cell: {
    display: 'grid',
    cursor: 'pointer',
    width: 20
  },
  brailleChar: {
    fontSize: 32,
    justifySelf: 'center',
  },
  standardChar: {
    alignSelf: 'start',
    justifySelf: 'center',
    fontSize: 18,
  },
  row: {
    display: 'none'
  },
  group: {
    backgroundColor: '#c3c3c3',
  },
  select: {
    marginBottom: 20
  }
};