import React, { Component } from 'react';
import { connect } from 'react-redux';
import SupportAccountsList from '../../components/Support/SupportAccountsList';
import AccountWizardBase from '../../components/Wizards/Account/AccountWizardBase';

class SupportAccounts extends Component {
  render() {
    return (
      <div>
        <SupportAccountsList history={this.props.history}/>
        <AccountWizardBase history={this.props.history}/>
      </div>
    );
  }
}

const mapState = () => {
  return {

  }
};

export default connect(mapState)(SupportAccounts);