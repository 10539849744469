import {
  FETCH_ACCOUNT,
  FETCH_ACCOUNTS,
  RESET_ACCOUNT,
  CREATE_ACCOUNT,
  UPDATE_ACCOUNT,
  FETCH_BILLING,
  UPDATE_BILLING,
  FETCH_BUDDYBOOKS_SUBSCRIPTION,
  UPDATE_LICENSE_AMOUNT,
  SUSPEND_SUBSCRIPTION,
  CHECK_FOR_SUSPENSION
} from '../constants';

export const account = (state = {}, action) => {
  switch (action.type) {
    case CREATE_ACCOUNT:
      return action.account;
    case FETCH_ACCOUNT:
      return action.account;
    case RESET_ACCOUNT:
      return {};
    default:
      return state;
  }
};

export const accounts = (state = [], action) => {
  switch (action.type) {
    case FETCH_ACCOUNTS:
      return action.accounts;
    case CREATE_ACCOUNT:
      return [...state, action.account.accountDto];
    case UPDATE_ACCOUNT:
      return state.map(account => account.id === action.account.id ? action.account : account);
    default:
      return state;
  }
};

export const billing = (state = {}, action) => {
  switch (action.type) {
    case FETCH_BILLING:
      return action.billing;
    case UPDATE_BILLING:
      return action.billing;
    default:
      return state;
  }
};
export const buddyBookSubscription = (state = {}, action) => {
  switch (action.type) {
    case FETCH_BUDDYBOOKS_SUBSCRIPTION:
      return action.data;
    default:
      return state;
  }
};
export const updateLicenseAmount = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_LICENSE_AMOUNT:
      return action.data;
    default:
      return state
  }
}
export const suspendSubscription = (state = "", action) => {
  switch (action.type) {
    case SUSPEND_SUBSCRIPTION:
      return action.data;
    default:
      return state
  }
}
export const checkSuspension = (state = "", action) => {
  switch (action.type) {
    case CHECK_FOR_SUSPENSION:
      return action.data;
    default:
      return state
  }
}