import React, { Component } from 'react';
import { Button, FormGroup, Typography, IconButton, TextField, MenuItem } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import baseStyle from '../../../../styles/wizardBase';
import { FormattedMessage } from 'react-intl';

class SkillDatabankWizardPage1 extends Component {

  close = () => {
    this.props.close();
  };

  render()  {
    const { classes, next, open, close } = this.props;
    const games = this.props.games;
    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        open={open}
        onClose={close}
        aria-labelledby='skill-dialog-title'
        PaperProps={{ classes:{ root: classes.container }}}
      >
        <DialogTitle disableTypography={true} className={ classes.title } id='skill-dialog-title'>
          <Typography className={ classes.titleText } variant='h4'>
            <FormattedMessage id="Wizard.skillDB.title"/>
          </Typography>
          <IconButton
            onClick={this.close}
            className={ classes.exitIcon }
            aria-label="close dialog"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage id='Wizard.skillDB' />
          </DialogContentText>
          <form className={ classes.root }>
            <Typography gutterBottom variant='h6'><FormattedMessage id='Wizard.skillDB.subtitle2'/></Typography>
            <FormGroup>
              <TextField
                select
                variant='outlined'
                label={<FormattedMessage id='General.games' />}
                name='game'
                disabled={this.props.edit}
                onChange={this.props.handleGameChange}
                value={this.props.selectedGame}
              >
                {
                  games.map(game => (<MenuItem key={game.id} value={game.id}> {game.name} </MenuItem>))
                }
              </TextField>
            </FormGroup>
            <DialogActions className={classes.buttons}>
              <Button onClick={this.close} color='secondary'>
                <FormattedMessage id='General.cancel' />
              </Button>
              <Button disabled={!this.props.selectedGame} onClick={next} color='primary'>
                <FormattedMessage id='General.next' />
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    )
  }
}

export default withStyles(baseStyle)(SkillDatabankWizardPage1);