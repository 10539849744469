import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { FormattedMessage } from 'react-intl';
import { InsertChartOutlined, Search } from '@material-ui/icons';
import { Card, CardContent, Divider, InputAdornment, Paper, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import baseStyle from '../../../styles/dashboard';
import { fetchStaff, fetchStudentsBookInfo, fetchStudents } from "../../../redux/actions";
import { getSorting, stableSort } from '../../../helpers';
import TeacherDashboard from '../Dashboards/TeacherDashboard';
import BuddyBooksDashboard from '../Dashboards/BuddyBooksDashboard';
const selectType = [{ label: "Student", id: "student" }, { label: "Staff", id: "staff" }];

class AdminDashboard extends Component {
    state = {
        searchValue: '',
        selectedType: selectType[1],
        selectedStaff: null,
        selectedStudent: null,
        staff: [],
        students: [],
        filteredData: [],
        orderBy: 'firstName',
        order: 'asc',
        rowsPerPage: 5,
        page: 0
    }
    componentDidMount() {
        if (this.props.user) {
            this.props.dispatch(fetchStaff());
            this.props.dispatch(fetchStudents());
            this.props.dispatch(fetchStudentsBookInfo());
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.students != this.props.students) {
            this.setState({
                students: this.props.students
            });
        }
        if (prevProps.staff != this.props.staff) {
            this.setState({
                staff: this.props.staff
            });
        }
    }

    handleSearch = (searchValue, data) => {
        this.setState({ searchValue: searchValue });
        let _data = [];
        if (this.state.selectedType.id == "staff" || this.state.selectedType.value == "staff")
            _data = this.state.staff;
        else
            _data = this.state.students;

        const filtered = Array.isArray(_data) && _data.filter(item => {
            const searchableKeys = Object.keys(item).filter(key => [{ id: 'name' }].some(row => row.id === key));
            return searchableKeys.some(key => {
                if (item[key]) {
                    return item[key].toLowerCase().includes(searchValue.toLowerCase())
                }
                else {
                    return false
                }
            })
        });
        Array.isArray(_data) && this.setState({ filteredData: filtered, page: 0 })
    }

    handleSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';
        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }
        this.setState({ order, orderBy });
    }

    handleChangePage = (event, page) => {
        this.setState({ page });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    }

    handleClick = (data) => {
        if (this.state.selectedType.id == "staff" || this.state.selectedType.value == "staff") {
            this.setState({ selectedStaff: data.id });
        }
        else {
            this.setState({
                selectedStudent: data.id
            })
        }

    }

    getReadingTime = (data) => {
        if (data == null) return '0 Seconds';
        if (data < 60)
            return data + " Seconds";
        else if (data >= 60 && data < 3600)
            return parseFloat(data / 60).toFixed(1) + " Minutes";
        else
            return parseFloat(data / 3600).toFixed(1) + " Hours";
    }
    render() {
        const { classes, studentBooksDetails } = this.props;
        const { searchValue, filteredData, orderBy, order, rowsPerPage, page, selectedType } = this.state;
        let rows = [
            { id: 'firstName', label: <FormattedMessage id='General.firstName' />, searchable: true },
            { id: 'lastName', label: <FormattedMessage id='General.lastName' />, searchable: true },
            { id: 'email', label: <FormattedMessage id='General.email' />, searchable: true },
            { id: 'type', label: <FormattedMessage id={'General.role'} />, searchable: true }
        ];

        if (selectedType && selectedType.value == "student") {
            rows.splice(2, 2, { id: 'gamerTag', label: <FormattedMessage id='Login.username' />, searchable: true })
        }
        let data = filteredData;
        if (filteredData.length < 1) {
            if (selectedType.id == "staff" || selectedType.value == "staff")
                data = this.props.staff;
            else
                data = this.props.students;

        }

        return (
            <>
                <div className={classes.container}>
                    <div className={classes.title}>
                        <InsertChartOutlined className={classes.icon} />
                        <Typography className={classes.text} variant='h3'>
                            Admin <FormattedMessage id='Sidebar.dashboard' />
                        </Typography>
                    </div>
                </div>
                <Card>
                    <CardContent>
                        <Typography >
                            <FormattedMessage id="BuddyBooks.activeLogin" />: {studentBooksDetails.numberOfLogins}
                        </Typography>
                        <Typography >
                            <FormattedMessage id="BuddyBooks.activeBooks" />: {studentBooksDetails.numberOfActiveBooks}
                        </Typography>
                        <Typography >
                            <FormattedMessage id="BuddyBooks.pastWeek" />: {studentBooksDetails.numberOfReaders}
                        </Typography>
                        <Typography >
                            <FormattedMessage id="BuddyBooks.averageReadingTime" />: {this.getReadingTime(studentBooksDetails.averageReadingTime)}
                        </Typography>
                    </CardContent>
                </Card>
                <br />
                <Divider />
                <Toolbar classes={{ root: classes.searchToolbar }} style={{ marginTop: "10px" }}>
                    <Select
                        id='student-select'
                        styles={{
                            control: (styles) => ({ ...styles, width: 500 }),
                            multiValueLabel: (styles) => ({
                                ...styles,
                                display: 'grid',
                                fontFamily: 'Roboto',
                                alignContent: 'center',
                                fontSize: '10px',
                                minHeight: 20,
                                fontWeight: 500,
                            }),
                        }}
                        defaultValue={{ label: "Staff", id: 'staff' }}
                        menuPlacement='top'
                        onChange={(ev) => { this.setState({ selectedType: ev, filteredData: [], searchValue: "", selectedStaff: null, selectedStudent: null }) }}
                        //   value={this.state.selectedType}
                        options={selectType.map(type => ({ label: type.label, value: type.id }))}
                    />
                    <div className={classes.actions}>
                        {(
                            <TextField
                                variant='outlined'
                                id='table-search'
                                aria-label='search input'
                                type={'search'}
                                placeholder="Search by Name"
                                InputProps={{
                                    inputProps: {
                                        'aria-label': 'search input'
                                    },
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <Search />
                                        </InputAdornment>
                                    ),
                                    className: classes.searchBarBar,
                                }}
                                onChange={(ev) => {
                                    this.handleSearch(ev.target.value);
                                }}
                                value={searchValue}
                            />
                        )}
                    </div>
                </Toolbar>
                {
                    filteredData ?
                        <Paper className={classes.list}>
                            <div className={classes.tableWrapper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {rows.length &&
                                                rows.map((row, index) => {
                                                    return (
                                                        <TableCell
                                                            aria-label={row.id}
                                                            key={row.id + index}
                                                            sortDirection={orderBy === row.id ? order : false}
                                                        >
                                                            <Tooltip
                                                                title='Sort'
                                                                placement='bottom-start'
                                                                enterDelay={300}
                                                            >
                                                                <TableSortLabel
                                                                    active={orderBy === row.id}
                                                                    direction={order}
                                                                    onClick={(ev) => this.handleSort(ev, row.id)}
                                                                >
                                                                    {row.label}
                                                                </TableSortLabel>
                                                            </Tooltip>
                                                        </TableCell>
                                                    )
                                                })
                                            }
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {stableSort(data, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((_data, index) => {
                                                return (
                                                    <TableRow tabIndex={0} hover key={_data.id + index}
                                                        onClick={() => this.handleClick(_data)}>
                                                        {
                                                            rows.length && rows.map((row, index) => {
                                                                return (
                                                                    <TableCell key={index + row.id}>{_data[row.id]}</TableCell>
                                                                )
                                                            })
                                                        }

                                                    </TableRow>);
                                            })
                                        }
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10]}
                                    component='div'
                                    count={data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    labelRowsPerPage={<FormattedMessage id='Datatable.rowsPerPage' />}
                                    backIconButtonProps={{
                                        'aria-label': 'Previous Page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'Next Page',
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </div>

                        </Paper> : null
                }<br />
                {this.state.selectedStaff && <TeacherDashboard staffId={this.state.selectedStaff} history={this.props.history} currentPath="admindashboard" />}
                {this.state.selectedStudent && <BuddyBooksDashboard history={this.props.history} id={this.state.selectedStudent} key={this.state.selectedStudent} />}
            </>
        )

    }
}

const mapState = ({ platform: { students, studentBooksDetails, staff }, shared: { account, user } }) => {
    return {
        account,
        user,
        students,
        studentBooksDetails,
        staff
    }
};
export default connect(mapState, null)(withStyles(baseStyle)(AdminDashboard));