import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { ROOT_URL } from '../../redux/constants';
import moment from 'moment';
import Reply from './Reply';
import AddReply from './AddReply';
import Avatar from 'react-avatar';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import { ThumbUpAlt, ThumbUpAltOutlined, Cancel } from '@material-ui/icons';
import { Button, Divider, IconButton, Paper, Icon, Tooltip, Typography, withStyles, Chip, List, ListItem } from '@material-ui/core';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { deletePost, toggleLike, togglePin, loadMore } from '../../redux/actions';
import { getRandomColor } from '../../helpers';
import baseStyle from '../../styles/feed';

const CollisionLink = (url) => React.forwardRef((props, ref) => (
  <Link innerRef={ref} to={url} {...props} />
));

class TextPost extends Component {
  state = {
    liked: this.props.post.likes && this.props.post.likes.find(like => like.key === this.props.profile.key),
    likeNum: this.props.post.likes ? this.props.post.likes.length : 0,
    pinned: this.props.pinned || false,
    page: 1,
    confirm: false,
    tags: this.props.post.tags || [],
    replies: this.props.post.replies || [],
    replied: false
  };

  onLikeClick = () => {
    const { liked, likeNum } = this.state;
    this.props.toggleLike(this.props.post, this.state.liked);
    this.setState({ liked: !liked, likeNum: liked ? likeNum - 1 : likeNum + 1 })
  };

  togglePin = () => {
    this.props.togglePin(this.props.post, this.state.pinned);
    this.setState(({ pinned }) => ({ pinned: !pinned }))
  };

  viewMore = () => {
    const { page } = this.state;
    // this gets the unfilled replies and adds them to the state
    this.setState((state) => ({
      page: state.page+1
    }))
    // this.props.loadMore(this.props.post.replies, replies, page, 3)
    // this.setState({ page: page+1 })
  };

  confirmDelete = () => {
    this.setState({ confirm: true })
  };

  handleDelete = () => {
    this.props.deletePost(this.props.post);
    this.setState({ confirm: false })
  };

  handleClose = () => {
    this.setState({ confirm: false })
  };

  // transform method for reactHtmlParser. Will take our tags and make them links
  transform = (node, index) => {
    if (node.type === 'tag' && node.attribs && node.attribs['data-denotation-char'] === "#"){
      return (
        <strong key={node.children[0].data+index} style={{'color': 'rgb(29, 161, 242)', 'textDecoration': 'none'}}>#{node.children[1].children[1].data}</strong>
    )}
    if (node.attribs && node.attribs.contenteditable) {
      node.attribs = {};
      return convertNodeToElement(node, index, this.transform)
    }
  };

  addReply = (key, _reply) => {
    const filteredReplies = this.state.replies.filter(reply => reply.key !== key);
    const replies = [...filteredReplies, _reply];
    this.setState({ replies, replied: true })
  };

  render() {
    const { classes, feed, post: { author, text, date, group, likes }, profile: { name, key } } = this.props;
    const dateobj = new Date(date);
    const { liked, likeNum, pinned, page, replies } = this.state;
    const self = key === author.key;
    const sortedReplies = replies ? replies.sort((a, b) => moment(b.date ? new Date(b.date) : '').isBefore(moment(a.date ? new Date(a.date) : '')) ? 1 : -1) : [];
    const currentReplies = sortedReplies.slice(Math.max(0, sortedReplies.length-(5*page)));
    const timeZone = moment.tz.guess();
    const isLocked = this.props.isLocked;
    const _date = moment(dateobj).tz(timeZone);
    console.log(_date);
    return (
      <Paper suppressContentEditableWarning={true} aria-label={text && text.replace(/<[^>]*>?/gm, '')} className={this.props.post.pinned ? classes.pinnedPost : classes.textPost}>
        <Dialog
          open={this.state.confirm}
          onClose={this.handleClose}
          aria-label="confirm delete"
        >
          <DialogTitle>
            Confirm Delete
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this post?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button aria-label='do not delete' onClick={this.handleClose} color="secondary">
              <FormattedMessage id='General.no' />
            </Button>
            <Button aria-label='confirm delete' onClick={this.handleDelete} color="primary">
              <FormattedMessage id='General.yes' />
            </Button>
          </DialogActions>
        </Dialog>
        <div className={classes.title}>
          <Avatar round size={'50px'} name={author.name} src={author.imgSrc} alt={author.name}/>
          <div>
            <div className='text'>
              <Typography variant='h6'>
                { author ? author.name : name }
              </Typography>
              { feed &&
                <>
                  <Typography className='subtext' variant='subtitle1'>in </Typography>
                  <Chip
                    size='small'
                    style={{backgroundColor: getRandomColor(group.key)}}
                    className='chip'
                    clickable
                    aria-label={`see post in group ${group.name}`}
                    component={feed ? CollisionLink(`/groups/${group.key}`): ''}
                    label={group.name}
                  />
                </>
              }
            </div>
            <Typography variant='caption' className={classes.timestamp}>
              {
              // Check to see if date is within 24 of today. If so, use relative str. If not, use date str
                _date.isBetween(moment(), moment().add(1, 'week'))
                ?_date.fromNow()
                : `${dateobj.toLocaleDateString("en-US")}`
              }
            </Typography>
          </div>
        </div>
        {
          (this.props.isAdmin || this.props.isModerator || self) && !feed &&
          <div className={classes.locked}>
            {
              this.props.group.type !== 'GLOBAL' && (this.props.isAdmin || this.props.isModerator) &&
              <IconButton
                aria-label='pin post'
                onClick={this.togglePin}
              >
                {
                  pinned
                    ? <Tooltip title='Un-Pin Post'>
                      <Icon color='primary' className='fas fa-thumbtack'/>
                    </Tooltip>
                    : <Tooltip title='Pin Post'>
                      <Icon className='fas fa-thumbtack'/>
                    </Tooltip>
                }
              </IconButton>
            }
            <IconButton
              onClick={this.confirmDelete}
              className={classes.cancel}
              color='secondary'
            >
              <Tooltip title='Delete Post'>
                <Cancel/>
              </Tooltip>
            </IconButton>
          </div>
        }
        <div className={classes.content}>
          <Typography component='div' variant='body2' className='text'>
            {ReactHtmlParser(text, { transform: this.transform })}
          </Typography>
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.buttons}>
          <Tooltip title='Like Post'>
            <IconButton onClick={this.onLikeClick}>
              {
                liked
                  ? <ThumbUpAlt className={classes.liked} />
                  : <ThumbUpAltOutlined />
              }
            </IconButton>
          </Tooltip>
          {
            likes.length > 0
            ? <Tooltip
                placement='top'
                className={classes.likes}
                interactive
                title={
                  <List>{ this.props.post.likes.map((like, index) => <ListItem key={like.key+index}> {like.key} </ListItem>) }</List>
                }
              >
              <Typography className='text'>{likeNum}</Typography>
            </Tooltip>
            : <Typography className='text'>{likeNum}</Typography>

          }
        </div>
        <Divider className={classes.divider}/>
        <div className={classes.replyContainer}>
          {
            5*page < sortedReplies.length &&
            <Button
                aria-label='view more replies'
                className={classes.viewMore}
                onClick={this.viewMore}
            >
              View Older Comments
            </Button>
          }
        {
          currentReplies.map((reply) => (
            reply.author &&
            <Reply
              post={reply}
              date={reply.date}
              key={reply.key}
              picture={reply.author ? reply.author.imgSrc : ''}
              name={reply.author ? reply.author.name : ''}
              replies={reply.replies}
              text={reply.text}
              isLocked={isLocked}
              loadMore={this.loadMore}
              add={this.addReply}
            />
          ))
        }
        </div>

        {
          !feed && !isLocked &&
          <AddReply add={this.addReply} post={this.props.post} placeholder={"Write a Comment"}/>
        }
      </Paper>
    );
  }
}

const mapState = ({social: { group, profile, profiles }}) => {
 return {
    group,
    profile,
    profiles
 }
};

const mapDispatch = (dispatch) => {
  return {
    deletePost: (post) => dispatch(deletePost(post)),
    togglePin: (post, pinned) => dispatch(togglePin(post, pinned)),
    toggleLike: (post, liked) => dispatch(toggleLike(post, liked)),
    loadMore: (posts, batchPosts, page, pages) => dispatch(loadMore(posts, batchPosts, page, pages))
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(TextPost));