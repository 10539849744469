import { ROOT_URL, FETCH_PROFILES, GET_PROFILE, GET_FEED } from '../../constants';
import axios from 'axios';

export const myProfile = (history) => {
  const url = `${ROOT_URL}/profiles/my-profile`;
  return (dispatch) => {
    axios
      .get(url, {withCredentials: true})
      .then((response) => {
        if (typeof response.data === 'object'){
          dispatch({
            type: GET_PROFILE,
            profile: response.data
          })
        }
        else {
          history.push('/login')
        }
      })
      .catch(err => console.log(err))
  }
};

export const getMyFeed = (history) => {
  const url = `${ROOT_URL}/profiles/my-feed`;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        if (typeof response.data === 'object'){
          dispatch({
            type: GET_FEED,
            feed: response.data
          })
        }
        else {
          history.push('/login')
        }
      })
      .catch(err => console.log(err))
  }
};

export const searchProfiles = (searchVal) => {
  const url = `${ROOT_URL}/profiles/search`;
  return (dispatch) => {
    return axios
      .post(url, searchVal, { headers: {'content-type': 'text/plain' }})
      .then((response) => {
        dispatch({
          type: FETCH_PROFILES,
          profiles: response.data
        });
        return response.data;
      })
      .catch(err => console.log(err))
  }
};

export const uploadProfileImage = (profile, file) => {
  const url =`${ROOT_URL}/profiles/${profile.key}/image`;
  return (dispatch) => {
    file.toBlob(blob => {
      const data = new FormData();
      data.append("file", blob, "filename.png");
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };
      axios
        .post(url, data, config)
        .then(() => {
          dispatch(myProfile())
        })
        .catch(err => console.log(err))
    })
  }
};