import React, { Component } from 'react';
import { connect } from 'react-redux';
import Avatar from 'react-avatar';
import moment from 'moment';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import AddReply from './AddReply';
import { SubdirectoryArrowRight, ThumbUpAlt, ThumbUpAltOutlined } from '@material-ui/icons';
import { Button, IconButton, Paper, Typography, withStyles } from '@material-ui/core';
import { toggleLike } from '../../redux/actions';
import baseStyle from '../../styles/feed';
import { ROOT_URL } from '../../redux/constants';
import axios from 'axios';

class Reply extends Component {
  state = {
    liked: this.props.post.likes.find(like => like.key === this.props.profile.key),
    likeNum: this.props.post.likes.length || 0,
    replying: false,
    showReplies: false,
    replies: this.props.replies,
    page: 1,
    replied: false
  };

  onLikeClick = () => {
    const { liked, likeNum } = this.state;
    this.props.toggleLike(this.props.post, this.state.liked);
    this.setState({ liked: !liked, likeNum: liked ? likeNum - 1 : likeNum + 1 })
  };

  addReply = (author) => {
    this.setState({ replying: true, showReplies: true, replyAuthor: this.props.post.author })
  };

  add = (key, _reply) => {
    const filteredReplies = this.state.replies.filter(reply => reply.key !== key);
    const replies = [_reply,...filteredReplies];
    this.setState({ replies, replied: true })
  };

  transform = (node, index) => {
    if (node.type === 'tag' && node.attribs && node.attribs['data-denotation-char'] === "#"){
      return (
        <strong key={node.children[0].data+index} style={{'color': 'rgb(29, 161, 242)', 'textDecoration': 'none'}}>#{node.children[1].children[1].data}</strong>
      )}
    if (node.attribs && node.attribs.contenteditable) {
      node.attribs = {};
      return convertNodeToElement(node, index, this.transform)
    }
  };

  showReplies = () => {
    this.setState({ showReplies: true })
  };

  viewMore = () => {
    const { page, replied } = this.state;
    // this gets the unfilled replies and adds them to the state
    const replies = this.state.replies.slice(replied ? (page*3)+1 : page*3, (page+1)*3);
    const url = `${ROOT_URL}/posts/batch`;
    axios
      .post(url, replies)
      .then(response => {
        this.setState((state) => ({
          page: state.page+1,
          replies:  [...this.state.replies.slice(0, page*3), ...response.data, ...this.state.replies.slice((page+1)*3)],
          replying: true
        }))
      })
  };

  setChildReplyAuthor = (replyAuthor) => {
    this.setState({replyAuthor})
  }

  childAddReply = () => {
    this.props.childAddReply(this.props.post.author);
  }

  render() {
    const { classes, picture, name, text, child, date, post, isLocked } = this.props;
    const { liked, likeNum, replying, showReplies, replies, page } = this.state;
    const timeZone = moment.tz.guess();
    const sortedReplies = replies ? replies.sort((a, b) => moment(b.date ? new Date(b.date) : '').isBefore(moment(a.date ? new Date(a.date) : '')) ? 1 : -1) : [];
    const _date = moment(new Date(date)).tz(timeZone);
    return (
      <>
        <div className={child ? classes.child : classes.replyWrapper}>
          <div className={classes.avatar}>
            <Avatar round size={'50px'} src={picture} name={name} alt={name}/>
          </div>
          <Paper suppressContentEditableWarning={true} aria-label={text.replace(/<[^>]*>?/gm, '')} className={classes.reply}>
            <div className={classes.replyContent}>
              <Typography component='div' variant='body1'>
                <strong>{name} </strong> {ReactHtmlParser(text, { transform: this.transform })}
              </Typography>
            </div>
            <div className={classes.replyLike}>
              <IconButton label='like post' onClick={() => this.onLikeClick()}>
                {
                  liked
                    ? <ThumbUpAlt className={classes.liked}/>
                    : <ThumbUpAltOutlined />
                }
              </IconButton>
              <Typography variant='body2' className='text'>{likeNum}</Typography>
              <Typography variant='subtitle2' className='time'> {new Date(date).toLocaleDateString("en-US")} </Typography>
            </div>
            {
              !child ?
              <Button
                label='submit reply'
                variant='text'
                className={classes.replyBtn}
                onClick={this.addReply}
              >
                reply
              </Button> :
              <Button
              label='submit reply'
              variant='text'
              className={classes.replyBtn}
              onClick={this.childAddReply}>
                reply
              </Button>
            }
          </Paper>
        </div>
        {
          !showReplies && !child && replies.length > 0
          ? <Button variant='text' label='see more replies' className={classes.replies} onClick={this.showReplies}>
                <SubdirectoryArrowRight /> {replies.length} {replies.length > 1 ? 'replies' : 'reply'}
            </Button>
          : sortedReplies && sortedReplies.map((reply) => (
            reply.author &&
            <Reply
              child
              toggleLike={this.props.toggleLike}
              classes={classes}
              post={reply}
              date={reply.date}
              key={reply.key}
              isLocked={isLocked}
              picture={reply.author.imgSrc}
              name={reply.author.name}
              replies={reply.replies}
              text={reply.text}
              childAddReply={this.setChildReplyAuthor}
            />
          ))
        }
        {
          (replying || showReplies) && !isLocked &&
          <AddReply add={this.add} post={this.props.post} placeholder={"Leave a Reply"} author={this.state.replyAuthor} child />
        }
      </>
    );
  }
}

const mapState = ({ social: { profile }}) => {
  return {
    profile
  }
};

const mapDispatch = (dispatch) => {
  return {
    toggleLike: (post, liked) => dispatch(toggleLike(post, liked))
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(Reply));