import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { ROOT_URL } from '../../../../redux/constants';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Typography,
  IconButton,
  FormGroup,
  TextField,
  MenuItem, Divider,
} from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { fetchBrailleSheets, getAccount } from '../../../../redux/actions';
import baseStyle from '../../../../styles/wizardBase';
import { getBrailleSheetById } from '../../../../redux/actions';

class BrailleScramble extends Component {

  state = {
    tested: false,
    error: {},
    sheet: {},
    grid: [],
    selected: '',
    numberOfWordsNeeded: 1,
    numberOfSeconds: 120,
    difficulty: 'Easy',
    dictionary: '1',
    sheets: []
  };

  componentDidMount() {
    this.props.getAccount();
    getBrailleSheetById("-7564333283354631829")
        .then(sheet => this.setState({sheets: [...this.state.sheets,sheet]}));
    getBrailleSheetById("798116426902211493")
        .then(sheet => this.setState({sheets: [...this.state.sheets,sheet]}));
    if (this.props.level.id) {
      const variables = this.props.edit ? JSON.parse(this.props.level.levelJson) : this.props.level.levelJson
      this.setState({...variables, difficulty: this.props.level.difficulty, level: this.props.level, sheet: variables.brailleSheet, selected: variables.brailleSheet.id})
    }
  }

  close = () => {
    this.props.close();
  };

  runTest = () => {
    const { tested, level } = this.state;
    const url = this.props.type === 'staff' ? `${ROOT_URL}/staff/${this.props.id}/objective-databank/test`: `${ROOT_URL}/admin/objective-databank/test`;
    if (tested) this.clearTest();
    axios.post(url, { levelVariables: JSON.stringify({  }), templateId: level.id })
      .catch(err => console.log(err));
    this.setState({ tested: true });
  };

  clearTest = () => {
    const url = this.props.type === 'staff' ? `${ROOT_URL}/staff/${this.props.id}/objective-databank/test`: `${ROOT_URL}/admin/objective-databank/test`;
    axios.delete(url)
      .catch(err => console.log(err));
    this.setState({ tested: false })
  };

  handleChange = (ev) => {
    const value = ev.target.type === 'number' ? ev.target.value > 0 ? ev.target.value * 1 : '' : ev.target.value;
    this.setState({ [ev.target.name]: value })
  };

  handleSheetChange = (ev) => {
    const sheet = this.state.sheets.find(sheet => sheet.id === ev.target.value);
    this.setState({ selected: ev.target.value, sheet })
  };

  handleSubmit = () => {
    const { difficulty, sheet, numberOfWordsNeeded, numberOfSeconds } = this.state;
    const levelJson = { brailleSheet: sheet, numberOfWordsNeeded, numberOfSeconds };
    this.props.onSubmit({ levelTemplateId: '1287143117397242570', levelJson, difficulty }, sheet, this.state.dictionary);
  };

  checkForErrors = (ev) => {
    if (!ev.target.value){
      this.setState({ error: {...this.state.error, [ev.target.name]: true }})
    }
    else {
      let error = this.state.error;
      delete error[ev.target.name];
      this.setState({ error })
    }
  };

  render()  {
    const { classes, open, close, back } = this.props;
    const { tested, error } = this.state;
    const disabled = Object.keys(error).length > 0 || this.state.selected === '';
    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        open={open}
        onClose={close}
        aria-labelledby='skill-dialog-title'
        scroll='paper'
        PaperProps={{ classes:{ root: classes.container }}}
      >
        <DialogTitle disableTypography={true} className={ classes.title }>
          <Typography className={ classes.titleText } variant='h4' id='skill-dialog-title'><FormattedMessage id='Wizard.skillDB.title'/></Typography>
          <IconButton
            onClick={this.close}
            className={ classes.exitIcon }
            aria-label="close dialog button"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography gutterBottom variant='h3'>
            <FormattedMessage id='Wizard.Scramble.title'/>
          </Typography>
          <Typography className={classes.description} variant='subtitle1'>
            <FormattedMessage id='Wizard.Scramble.subtitle'/>
          </Typography>
          <Divider className={classes.divider}/>
          <Typography variant='h6'><FormattedMessage id='Wizard.BrailleQuiz.select'/>:</Typography>
          <FormGroup className={ classes.root }>
            <TextField
              select
              variant='outlined'
              label='Layout Braille Sheet'
              name='sheet'
              onChange={this.handleSheetChange}
              value={this.state.selected}
            >
              <MenuItem value='-7564333283354631829'> QWERTY Code: 85 </MenuItem>
              <MenuItem value='798116426902211493'> Alphabetic Code: 75</MenuItem>
            </TextField>
          </FormGroup>
            <div className={classes.level}>
              <FormGroup className={classes.configureLine}>
                <Typography className={classes.configTitle} variant='h6'><FormattedMessage id='Wizard.Scramble.set'/>: </Typography>
                  <TextField
                    select
                    variant='outlined'
                    name='dictionary'
                    onChange={this.handleChange}
                    value={this.state.dictionary}
                  >
                    <MenuItem value='1'> All Words </MenuItem>
                  </TextField>
              </FormGroup>
              <FormGroup className={classes.configureLine}>
                <Typography className={classes.configTitle} variant='h6'><FormattedMessage id='Wizard.Scramble.number'/>: </Typography>
                <TextField
                  variant='outlined'
                  className={classes.configComponent}
                  name='numberOfWordsNeeded'
                  type='number'
                  error={error.numberOfWordsNeeded}
                  helperText={error.numberOfWordsNeeded ? '* required' : ''}
                  onBlur={this.checkForErrors}
                  value={this.state.numberOfWordsNeeded}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup className={classes.configureLine}>
                <Typography className={classes.configTitle} variant='h6'><FormattedMessage id='Wizard.Scramble.seconds'/>: </Typography>
                <TextField
                  variant='outlined'
                  className={classes.configComponent}
                  name='numberOfSeconds'
                  type='number'
                  error={error.numberOfSeconds}
                  helperText={error.numberOfSeconds ? '* required' : ''}
                  onBlur={this.checkForErrors}
                  value={this.state.numberOfSeconds}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup className={classes.configureLine}>
                <Typography className={classes.configTitle} variant='h6'><FormattedMessage id='General.difficulty'/>: </Typography>
                <TextField
                  select
                  variant='outlined'
                  className={classes.configComponent}
                  name='difficulty'
                  value={this.state.difficulty}
                  onChange={this.handleChange}
                >
                  {
                    [
                      { value: 'Easy', text: <FormattedMessage id='General.easy' /> },
                      { value: 'Medium', text: <FormattedMessage id='General.medium' /> },
                      { value: 'Hard', text: <FormattedMessage id='General.hard' /> }
                    ].map(difficulty => (<MenuItem key={difficulty.value} value={difficulty.value}> {difficulty.text} </MenuItem>))
                  }
                </TextField>
              </FormGroup>
            </div>
        </DialogContent>
        <DialogActions className={classes.buttons}>
          <Button onClick={back} color='secondary'>
            <FormattedMessage id='General.back'/>
          </Button>
          <Button
            onClick={ this.runTest }
            variant={ tested ? 'outlined' : 'text' }
            color={ tested ? 'secondary' : 'primary' }
          >
            { tested ? <FormattedMessage id='General.retest' /> : <FormattedMessage id='General.test' />}
          </Button>
          <Button onClick={this.handleSubmit} disabled={disabled} color='primary'>
            <FormattedMessage id='General.next'/>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const  mapState = ({ platform: { brailleSheets },  shared: { account }}) => {
  return {
    brailleSheets,
    account
  }
};

const mapDispatch = (dispatch) => {
  return {
    fetchBrailleSheets: (accountId) => dispatch(fetchBrailleSheets(accountId)),
    getAccount: () => dispatch(getAccount())
  }
};

export default connect(mapState, mapDispatch)(withStyles(baseStyle)(BrailleScramble));