export default {
    container: {
        color: '#0a0a0a',
        overflow: 'wrap',
        fontSize: '20px'
    },
    root: {
        marginTop: '20px',
        maxWidth: 550,
    },
    title: {
        backgroundColor: '#383871',
        marginBottom: 15,
    },
    titleText: {
        float: 'left',
        color: '#fff'
    },
    exitIcon: {
        float: 'right',
        color: '#fff',
    },
    anchorTag: {
        textDecoration: 'none',
    },
    bookButton: {
        marginBottom: '10px',
        marginTop: '10px'
    },
    actions: {
        gridColumn: 2,
        gridRow: 1,
        justifySelf: 'end',
        marginRight: '20px',
    },
}