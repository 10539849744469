export default {
  loginContainer: {
    textAlign: 'center',
    margin: '120px auto 0 auto',
    padding: '20px',
    maxWidth: '600px',
    boxShadow: '0px 1px 5px 0px rgba(107, 107, 107, 1), 0px 2px 2px 0px rgba(107, 107, 107, 0.9), 0px 3px 1px -2px rgba(107, 107, 107, 0.8)',
    '@media (min-width: 750px)':{
      width: '100%'
    }
  },

  bookShareLoginCont: {
    margin: '0 auto',
    textAlign: 'center',
    padding: '20px',
    maxWidth: '600px',
  },

  loginForm: {
    display: 'grid',
    gridGap: '15px',
    padding: '0 50px',
  },

  loginBtns: {
    justifySelf: 'center',
    display: 'grid',
    gridAutoFlow: 'column',
    gridColumnGap: 20,
    margin: '40px 0',
  },
  loginLogo: {
    gridRow: '1',
    width: '100%',
    '@media (min-width: 750px)':{
      width: '500px',
    }
  },
  error: {
    backgroundColor: '#b2102f'
  }
}