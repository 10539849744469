import { CREATE_GUARDIAN, DELETE_GUARDIAN, PATCH_GUARDIAN, FETCH_ALL_GUARDIANS, GET_GUARDIAN } from '../../constants';

export const guardians = (state = [], action) => {
  switch(action.type){
    case FETCH_ALL_GUARDIANS:
      return action.guardians;
    case CREATE_GUARDIAN:
      return [...state, action.guardian];
    case PATCH_GUARDIAN:
      return state.map(guardian => guardian.id === action.guardian.id ? action.guardian : guardian );
    case DELETE_GUARDIAN:
      return state.filter(guardian => guardian.id !== action.guardian.id);
    default:
      return state;
  }
};

export const guardian = (state = {}, action) => {
  switch(action.type){
    case GET_GUARDIAN:
      return action.guardian;
    default:
      return state;
  }
};