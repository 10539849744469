import React from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../styles/sidebar';
import { FormattedMessage } from 'react-intl';

const SidebarTitle = (props) => {

  const { classes, location, staffMember, student, guardian } = props;
  const pathArr = location.pathname.split('/');
  const type = pathArr[1];
  const goal = pathArr.includes('goals') && pathArr.indexOf('goals') !== pathArr.length-1 && type !== 'staff';
  // ternary works as a de-bouncer and makes the transition from titles smoother
  const titleRender = (param) => {
    if (goal) return <strong className={ classes.hidden }><FormattedMessage id='Sidebar.title'/></strong>;
    switch (param) {
      case 'staff':
        return staffMember.name
          ? <strong>{staffMember.name}</strong>
          : <strong><FormattedMessage id='Sidebar.title'/></strong>;
      case 'students':
        return student.firstName
          ? <strong> {student.firstName} {student.lastName}  </strong>
          : <strong><FormattedMessage id='Sidebar.title'/></strong>;
      case 'guardians':
        return guardian.firstName
          ? <strong> {guardian.firstName} {guardian.lastName}  </strong>
          : <strong><FormattedMessage id='Sidebar.title.home'/></strong>;
      default:
        return <strong><FormattedMessage id='Sidebar.title'/></strong>;
    }
  };
    return (
      <Typography classes={{root: classes.role}} variant='h6'>
        {titleRender(type)}
      </Typography>
    )
  };

const mapState = ({ platform: { staffMember, student, guardian }}) => {
  return {
    staffMember,
    student,
    guardian
  }
};

export default connect(mapState)(withStyles(styles)(SidebarTitle));