import React, {Component} from 'react';
import {connect} from 'react-redux';
import {fetchStudents} from "../../../redux/actions";
import {deleteStudent} from "../../../redux/actions";
import DataTable from "../Shared/DataTable";
import RestoreStudents from "../Admin/RestoreStudents";
import {FormattedMessage} from 'react-intl';
import axios from 'axios';
import {
    ROOT_URL
} from '../../../redux/constants';

class StudentList extends Component {

    componentDidMount() {
        const {fetchStudents, user} = this.props;
        if (user) fetchStudents()
    }

    internalDeleteStudent = (student) => {
      return this.props.deleteStudent(student);
    }


    render() {
        const rows = [
            {id: 'firstName', label: <FormattedMessage id='General.firstName'/>, searchable: true},
            {id: 'lastName', label: <FormattedMessage id='General.lastName'/>, searchable: true},
            {id: 'gamerTag', label: 'UserName', searchable: true}
        ];
        return (
                <DataTable
                    editable
                    type={'student'}
                    clickable
                    deletable
                    deleteFunction={this.internalDeleteStudent}
                    location={this.props.location}
                    history={this.props.history}
                    rows={rows}
                    rowsPerPage={[5, 10, 15]}
                    orderBy={'firstName'}
                    data={this.props.students}
                    numCols={2}
                    title={<FormattedMessage id='Students.title'/>}
                />
        )
    }
}

const mapState = ({platform: {students}, shared: {user}}) => {
    return {
        students,
        user
    }
};

const mapDispatch = (dispatch, {history}) => {
    return {
        fetchStudents: () => dispatch(fetchStudents(history)),
        deleteStudent: (student) => dispatch(deleteStudent(student, history))
    }
};

export default connect(mapState, mapDispatch)(StudentList);